import { RootAction } from "../rootReducer";
import {
  REMOVE_FILE_REFERENCE,
  SET_APPLICATION_DRAFT,
  UPDATE_APPLICATION_DRAFT
} from "./applicationDraftActions";
import store from "../store";
import { ApplicationDraft } from "../../api_client/medre_api";
import { APPLICATION_DRAFT } from "../Constants";
import { ApplicationCertificateDraft } from "../Dto/ApplicationCertificate/ApplicationCertificate";

const initialState: ApplicationDraft =
  sessionStorage.getItem(APPLICATION_DRAFT) !== null
    ? JSON.parse(sessionStorage.getItem(APPLICATION_DRAFT) as string)
    : {};

let currentValue: ApplicationDraft;

export function handleApplicationDraftChange() {
  let previousValue = currentValue;
  currentValue = store.getState().applicationDraft as ApplicationDraft;

  if (previousValue !== currentValue) {
    sessionStorage.setItem(APPLICATION_DRAFT, JSON.stringify(currentValue));
  }
}

export const applicationDraftReducer = (
  state: ApplicationDraft | ApplicationCertificateDraft = initialState,
  action: RootAction
) => {
  switch (action.type) {
    case SET_APPLICATION_DRAFT:
      return action.payload;
    case UPDATE_APPLICATION_DRAFT:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.field
      };
    case REMOVE_FILE_REFERENCE: {
      const references = state.fileReferences?.filter(
        (ref) => ref.minioFileName !== action.payload.minioFileName
      );
      return {
        ...state,
        fileReferences: references
      };
    }
    default:
      return state;
  }
};
