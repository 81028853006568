import React, {
  Fragment,
  ReactChild,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  ReactElement,
  ReactText,
  ReactNode,
  useMemo
} from "react";
import {
  TableProps,
  TableInstance,
  TableHeaderProps,
  HeaderGroup,
  TableBodyProps,
  TableBodyPropGetter,
  TablePropGetter,
  Row,
  TableRowProps,
  Cell,
  TableCellProps,
  Column,
  CellProps,
  TableExpandedToggleProps,
  UseExpandedInstanceProps
} from "react-table";
import classNames from "classnames";
import { Collapse } from "reactstrap";

import { useWindowWidthSize } from "../../Hook/useWindowsSize";
import { useDebounce } from "../../Hook/useDebounce";
import { MinusIcon } from "../Icon/MinusIcon";
import { PlusIcon } from "../Icon/PlusIcon";
import { useMobile } from "../../Hook/useMobile";

type CustomColumn = {
  sticky?: boolean | string;
  collapse?: boolean;
};

export type ReactTableColumn<D extends object> = Column<D> & CustomColumn;
export type ReactTableHeaderGroup<D extends object> = HeaderGroup<D> &
  CustomColumn;
export type ReactTableCell<D extends object> = Cell<D> & {
  column: ReactTableColumn<D>;
};

interface ReactTableProps<D extends object> {
  table: TableInstance<D>;
  getTableProps?: (propGetter?: TablePropGetter<D>) => TableProps;
  getHeaderGroupProps?: (
    headerGroup: HeaderGroup<D> & CustomColumn
  ) => TableHeaderProps;
  getHeaderProps?: (
    headerGroup: HeaderGroup<D> & CustomColumn
  ) => TableHeaderProps;
  getTableBodyProps?: (propGetter?: TableBodyPropGetter<D>) => TableBodyProps;
  rows: Array<Row<D>>;
  getRowProps?: (row: Row<D>) => TableRowProps;
  getCellProps?: (cell: Cell<D> & CustomColumn) => TableCellProps;
  className?: string;
  square?: boolean;
}

export function ReactTable<D extends object>({
  table: reactTable,
  getTableProps = () => reactTable.getTableProps(),
  getHeaderGroupProps = (headerGroup: HeaderGroup<D>) =>
    headerGroup.getHeaderGroupProps(),
  getHeaderProps = (column: HeaderGroup<D>) => column.getHeaderProps(),
  getTableBodyProps = () => reactTable.getTableBodyProps(),
  rows,
  getRowProps = (row: Row<D>) => row.getRowProps(),
  getCellProps = (cell: Cell<D>) => cell.getCellProps(),
  className,
  square
}: ReactTableProps<D>) {
  const cardRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const stickyFirstHeaderRef = useRef<HTMLTableCellElement>(null);
  const stickyLastHeaderRef = useRef<HTMLTableCellElement>(null);
  const stickyFirstCellRef = useRef<HTMLTableCellElement>(null);
  const stickyLastCellRef = useRef<HTMLTableCellElement>(null);
  const [stickyFirstWidth, setStickyFirstWidth] = useState(0);
  const [stickyLastWidth, setStickyLastWidth] = useState(0);

  const windowWidth = useDebounce<number>(useWindowWidthSize(), 100);

  useEffect(() => {
    const { current: card } = cardRef;
    const { current: table } = tableRef;
    if (!card || !table) {
      return;
    }

    const { current: stickyFirstHeader } = stickyFirstHeaderRef;
    const { current: stickyLastHeader } = stickyLastHeaderRef;
    const { current: stickyFirstCell } = stickyFirstCellRef;
    const { current: stickyLastCell } = stickyLastCellRef;

    const stickyFirstHeaderWidth = stickyFirstHeader?.clientWidth ?? 0;
    const stickyLastHeaderWidth = stickyLastHeader?.clientWidth ?? 0;
    const stickyFirstCellWidth = stickyFirstCell?.clientWidth ?? 0;
    const stickyLastCellWidth = stickyLastCell?.clientWidth ?? 0;

    setStickyFirstWidth(
      Math.max(
        Math.round(stickyFirstHeaderWidth),
        Math.round(stickyFirstCellWidth)
      )
    );
    setStickyLastWidth(
      Math.max(
        Math.round(stickyLastHeaderWidth),
        Math.round(stickyLastCellWidth)
      )
    );

    if (table.clientWidth > card.clientWidth) {
      table.classList.add("shadow");
    } else {
      table.classList.remove("shadow");
    }
  }, [windowWidth, reactTable.state.selectedRowIds, cardRef, tableRef, rows]);

  const hasRows = useMemo(() => Boolean(rows.length), [rows]);
  const isMobile = useMobile();

  const { role: tableAriaRole, ...tableProps } = getTableProps();
  const { role: tableBodyAriaRole, ...tableBodyProps } = getTableBodyProps();
  return (
    <div
      className={classNames("react-table-container", className, {
        "react-table-container-square": square
      })}
    >
      <div ref={cardRef} className="overflow-auto">
        <table
          ref={tableRef}
          className="react-table border-0 m-0"
          {...tableProps}
        >
          <thead>
            {reactTable.headerGroups.map((headerGroup, rowIndex) => {
              const {
                key: trKey,
                role: headerGroupAriaRole,
                ...headerGroupProps
              } = getHeaderGroupProps(headerGroup);
              return (
                <tr key={trKey} className="table-row" {...headerGroupProps}>
                  {headerGroup.headers.map(
                    (column: ReactTableHeaderGroup<D>, cellIndex) => {
                      const { sticky, canSort, width } = column;
                      const isSticky = !isMobile && sticky;
                      const {
                        key: thKey,
                        role: headerAriaRole,
                        ...headerProps
                      } = getHeaderProps(column);
                      const cellClassName = classNames("table-cell", {
                        sortable: canSort,
                        desc: column.isSorted && column.isSortedDesc,
                        asc: column.isSorted && !column.isSortedDesc
                      });
                      const content = column.render("Header");
                      const hasHeader = content !== "";
                      return (
                        <Fragment key={thKey}>
                          <th
                            className={cellClassName}
                            {...headerProps}
                            style={{
                              ...headerProps.style,
                              ...(width && { width })
                            }}
                            {...(isSticky &&
                              rowIndex === 0 && {
                                ref:
                                  cellIndex === 0
                                    ? stickyFirstHeaderRef
                                    : stickyLastHeaderRef
                              })}
                            colSpan={isSticky && !hasHeader && !hasRows ? 2 : 1}
                          >
                            {canSort && <span className="sort" />}
                            {content}
                          </th>
                          {isSticky && (hasHeader || hasRows) && (
                            <th
                              className={classNames(
                                cellClassName,
                                "sticky-absolute"
                              )}
                              {...headerProps}
                              style={{
                                ...headerProps.style,
                                width:
                                  cellIndex === 0
                                    ? stickyFirstWidth
                                    : stickyLastWidth
                              }}
                            >
                              {canSort && <span className="sort" />}
                              {content}
                            </th>
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </tr>
              );
            })}
          </thead>
          <tbody {...tableBodyProps}>
            {rows.map((row, rowIndex) => {
              reactTable.prepareRow(row);
              const {
                key: trKey,
                className: rowClassName,
                role: rowAriaRole,
                ...rowProps
              } = getRowProps(row);
              const collapsed: ReactElement[] = [];
              return (
                <tr
                  key={trKey}
                  className={classNames("table-row", rowClassName, {
                    expanded: row.isExpanded
                  })}
                  {...rowProps}
                >
                  {row.cells.map((cell: ReactTableCell<D>, cellIndex) => {
                    const {
                      key,
                      role: cellAriaRole,
                      ...cellProps
                    } = getCellProps(cell);
                    const { sticky, collapse, canSort, id } = cell.column;
                    const { values } = cell.row;
                    const isSticky = !isMobile && sticky;
                    const cellClassName = classNames("table-cell", {
                      sortable: canSort
                    });
                    const content = cell.render("Cell");
                    const tableCell = (
                      <Fragment key={key}>
                        <td
                          className={classNames(cellClassName, {
                            sticky: isSticky
                          })}
                          {...cellProps}
                          {...(isSticky &&
                            rowIndex === 0 && {
                              ref:
                                cellIndex === 0
                                  ? stickyFirstCellRef
                                  : stickyLastCellRef
                            })}
                        >
                          {content}
                        </td>
                        {isSticky && (
                          <td
                            className={classNames(
                              cellClassName,
                              "sticky-absolute"
                            )}
                            {...cellProps}
                            style={{
                              width:
                                cellIndex === 0
                                  ? stickyFirstWidth
                                  : stickyLastWidth
                            }}
                          >
                            {content}
                          </td>
                        )}
                      </Fragment>
                    );
                    if (isMobile && !values[id]) {
                      return null;
                    }
                    if (collapse) {
                      collapsed.push(tableCell);
                      return null;
                    }
                    return tableCell;
                  })}
                  {Boolean(collapsed.length) && (
                    <Collapse
                      isOpen={row.isExpanded}
                      tag="td"
                      className="table-cell table-cell-collapse"
                    >
                      <table className="border-0">
                        <tbody>
                          <tr className="table-row">{collapsed}</tr>
                        </tbody>
                      </table>
                    </Collapse>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export type ReactTableExpanderProps<D extends object> = {
  accordion?: boolean;
  children: ReactChild | Array<ReactChild> | ReactText | ReactNode;
  cell: CellProps<D> & UseExpandedInstanceProps<D>;
};
type ToggleRowExpandedProps = TableExpandedToggleProps & {
  onClick: (e: MouseEvent) => void;
};

export function ReactTableExpander<D extends object>({
  accordion = true,
  children,
  cell
}: ReactTableExpanderProps<D>) {
  const { state, row, toggleRowExpanded } = cell;
  const { onClick: handleToggleRowExpand } =
    row.getToggleRowExpandedProps() as ToggleRowExpandedProps;

  const handleClick = useCallback(
    (...args: Parameters<ToggleRowExpandedProps["onClick"]>) => {
      if (accordion) {
        const collapse: string[] = [];
        for (const id in state.expanded) {
          if (state.expanded.hasOwnProperty(id) && row.id !== id) {
            collapse.push(id);
          }
        }
        toggleRowExpanded(collapse, false);
      }
      handleToggleRowExpand(...args);
    },
    [accordion, state, row, toggleRowExpanded, handleToggleRowExpand]
  );

  useEffect(() => {
    return () => toggleRowExpanded([row.id], false);
  }, [toggleRowExpanded, row]);

  return (
    <div
      className="d-flex align-items-center cursor-pointer"
      role="button"
      onClick={handleClick}
    >
      <span className="ml-n3 p-3">
        {row.isExpanded ? <MinusIcon /> : <PlusIcon />}
      </span>
      <div>{children}</div>
    </div>
  );
}
