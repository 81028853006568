import React, { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { Option } from "../../../../Component/Select/SingleSelect";
import fetchApplicantOptions from "../../../Shared/GeneralPractitioners/fetchApplicantOptions";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  PerearstiNimistudTeenusApiFactory as doctorDirectoryAPI,
  GeneralPractitionerListSubstituteDoctor
} from "../../../../../api_client/medre_api";
import { GeneralPractitionerListDoctorField } from "../../../Shared/GeneralPractitioners/GeneralPractitionerListDoctor";

interface Props {
  open: boolean;
  onClose: () => void;
}

export interface PersonOption extends Option {
  id: string;
  idCode: string;
  occupationCode: string;
  firstName: string;
  lastName: string;
}

interface FormValues {
  person: PersonOption;
  personId: string;
}

export const PortalSubstituteDoctorModal: FC<Props> = ({ open, onClose }) => {
  const { list, fetchGPList } = useGeneralPractitionerList();

  const { id } = list;
  const { control, handleSubmit, formState, setValue, watch } =
    useForm<FormValues>({
      mode: "onChange",
      defaultValues: {
        person: {}
      }
    });

  const { isSubmitting, isValid } = formState;

  const loadSubstituteOptions = useCallback(
    async (occupationCode: string) =>
      await fetchApplicantOptions({ occupationCode }),
    []
  );

  const handleDeletePerson = useCallback(() => {
    setValue("person", {});
  }, [setValue]);

  const { person } = watch();
  useEffect(() => {
    setValue("personId", person.id ?? "", { shouldValidate: true });
  }, [person, setValue]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async ({ personId }: FormValues) => {
      try {
        await doctorDirectoryAPI(
          undefined,
          getBaseUrl(),
          API
        ).addSubstituteDoctor(
          id,
          {
            personId
          } as GeneralPractitionerListSubstituteDoctor,
          {
            withCredentials: true
          }
        );

        await fetchGPList(id);
        onClose();
      } catch (e) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }
    },
    [id, fetchGPList, onClose, dispatch]
  );

  return (
    <Modal isOpen={open} size="md" unmountOnClose={true}>
      <ModalHeader>
        <FormattedMessage
          id="GPListSubstituteDoctorModal.header"
          defaultMessage="Lisa asendusarst"
        />
      </ModalHeader>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gp-contact-information-form"
      >
        <ModalBody>
          <Controller
            name="id"
            defaultValue=""
            control={control}
            as={<input type="hidden" />}
          />

          <Controller
            name="personId"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            as={<input type="hidden" />}
          />

          <Card className="mt-3 mb-3">
            <CardHeader className="p-3 border-0 position-relative">
              <Controller
                name="person"
                defaultValue=""
                control={control}
                render={(renderProps) => (
                  <GeneralPractitionerListDoctorField
                    id="gpListSubstituteDoctorModalPerson"
                    {...renderProps}
                    handleDelete={handleDeletePerson}
                    loadOptions={loadSubstituteOptions}
                  />
                )}
              />
            </CardHeader>
          </Card>
        </ModalBody>

        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton
            id="submitSubstituteDoctor"
            type="submit"
            disabled={!isValid || isSubmitting}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
