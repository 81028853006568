import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const OpenDataLinkMessages = defineMessages({
  authorities: {
    id: "OpenDataLinkFM.authorities",
    defaultMessage: "Asutused",
  },
  lists: {
    id: "OpenDataLinkFM.lists",
    defaultMessage: "Nimistud",
  },
  pharmacists: {
    id: "OpenDataLinkFM.pharmacists",
    defaultMessage: "Apteekrid",
  },
  specialties: {
    id: "OpenDataLinkFM.specialties",
    defaultMessage: "Erialad",
  },
  invitations: {
    id: "OpenDataLinkFM.invitations",
    defaultMessage: "Kutsed",
  },
  services: {
    id: "OpenDataLinkFM.services",
    defaultMessage: "Teenused",
  },
});

const OpenDataMessages = defineMessages({
  title: {
    id: "OpenDataFM.title",
    defaultMessage: "Avaandmed",
  },
  subtitle: {
    id: "OpenDataFM.subtitle",
    defaultMessage:
      "Registrite avaandmed on digitaalsel ja masinloetaval kujul kättesaadavad vastavalt Avaliku teabe seaduse § 28 lg1 p30 ja § 29 lg4",
  },
  cardTitle: {
    id: "OpenDataFM.cardTitle",
    defaultMessage:
      "Terviseameti registrite avaandmete hulka kuuluvad järgmised andmekomplektid:",
  },
  classificationsTitle: {
    id: "OpenDataFM.classificationsTitle",
    defaultMessage: "ja järgmised abistavad andmed (klassifikaatorid):",
  },
});

export interface OpenDataLinkFMProps {
  id: keyof typeof OpenDataLinkMessages;
}

interface OpenDataFMProps {
  id: keyof typeof OpenDataMessages;
}

export const OpenDataLinkFM = ({ id }: OpenDataLinkFMProps) => (
  <FormattedMessage {...OpenDataLinkMessages[id]} />
);

export const OpenDataFM = ({ id }: OpenDataFMProps) => (
  <FormattedMessage {...OpenDataMessages[id]} />
);
