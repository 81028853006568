import { RootAction } from "../rootReducer";
import {
  ADD_ACTIVITY_LICENSE_FILE_REFERENCE,
  REMOVE_ACTIVITY_LICENSE_FILE_REFERENCE,
  REMOVE_ACTIVITY_LICENSE_SERVICE,
  SET_ACTIVITY_LICENSE,
  SET_INITIAL_ACTIVITY_LOCATION_APPLICATION,
  SET_MOBILE_ACTIVITY_LOCATION,
  UPDATE_ACTIVITY_LICENSE,
  UPDATE_ACTIVITY_LICENSE_SERVICE,
  UPDATE_COMPANY_CONTACT,
  UPDATE_LOCATION_INFO
} from "./activityLicenseApplicationActions";
import { ACTIVITY_LICENSE } from "../Constants";
import store from "../store";
import {
  ActivityLicenseApplication,
  ActivityLicenseApplicationProceedingTypeEnum
} from "../../api_client/medre_api";

const initial: ActivityLicenseApplication =
  sessionStorage.getItem(ACTIVITY_LICENSE) !== null
    ? JSON.parse(sessionStorage.getItem(ACTIVITY_LICENSE) as string)
    : {};

let currentValue: ActivityLicenseApplication;

export function handleActivityLicenseChange() {
  let previousValue = currentValue;
  currentValue = store.getState().activityLicenseApplication;

  if (previousValue !== currentValue) {
    sessionStorage.setItem(ACTIVITY_LICENSE, JSON.stringify(currentValue));
  }
}

export const activityLicenseApplicationReducer = (
  state: ActivityLicenseApplication = initial,
  action: RootAction
) => {
  switch (action.type) {
    case SET_ACTIVITY_LICENSE:
      return action.payload;
    case UPDATE_ACTIVITY_LICENSE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.field
      };
    case UPDATE_LOCATION_INFO:
      const locations = state.locations || [{}];
      let changedLocations = locations.map((location, index) => {
        if (index === action.payload.index) {
          return {
            ...location,
            [action.payload.fieldName]: action.payload.value
          };
        } else {
          return location;
        }
      });
      return { ...state, locations: changedLocations };
    case SET_MOBILE_ACTIVITY_LOCATION:
      return {
        ...state,
        locations: action.payload
      };
    case UPDATE_COMPANY_CONTACT:
      return {
        ...state,
        company: {
          ...state.company,
          [action.payload.fieldName]: action.payload.value
        }
      };
    case UPDATE_ACTIVITY_LICENSE_SERVICE:
      return {
        ...state,
        locations: state.locations?.map((location, index) => {
          if (index === action.payload.locationInfoIndex) {
            return {
              ...location,
              services: location.services?.map((service) => {
                if (service.service?.id === action.payload.serviceId) {
                  return action.payload.service;
                }
                return service;
              })
            };
          }
          return location;
        })
      };
    case REMOVE_ACTIVITY_LICENSE_SERVICE:
      return {
        ...state,
        locations: state.locations?.map((location, index) => {
          if (index === action.payload.locationInfoIndex) {
            return {
              ...location,
              services: location.services?.filter(
                (s) => s.service?.id !== action.payload.serviceId
              )
            };
          }
          return location;
        })
      };
    case ADD_ACTIVITY_LICENSE_FILE_REFERENCE:
      const newReferences = state.fileReferences
        ? [...state.fileReferences, ...action.payload.files]
        : action.payload.files;
      return {
        ...state,
        fileReferences: newReferences
      };
    case REMOVE_ACTIVITY_LICENSE_FILE_REFERENCE:
      const references = state.fileReferences?.filter(
        (ref) => ref.minioFileName !== action.payload.minioFileName
      );
      return {
        ...state,
        fileReferences: references
      };
    case SET_INITIAL_ACTIVITY_LOCATION_APPLICATION:
      return {
        proceedingType:
          ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation,
        activityLicense: action.payload,
        locations: [{}],
        currentStep: 0,
        businessArea: action.payload.businessArea
      };
    default:
      return state;
  }
};
