import {ActionButtonClasses} from "../../../../../../Component/Button/Button";
import {SecondaryFormattedButton} from "../../../../../../Component/Button/SecondaryFormattedButton";
import React from "react";
import {useHistory} from "react-router-dom";

interface Props {
}

export const CompleteApplicationActions = ({}: Props) => {
    const history = useHistory();


    const handleCompleteApplicationButton = () => {
        history.push("/update-activity-license-application-services");
    }

    return (
        <SecondaryFormattedButton
            id="renewApplication"
            className={ActionButtonClasses}
            onClick={handleCompleteApplicationButton}
        />
    );
}