import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";

import { ApplicationDiscardConfirmationModal } from "../../Shared/Application/Footer/ApplicationDiscardConfirmationModal";

interface Props {
  when: boolean;
}

export const ApplicationCertificatePrompt = ({ when }: Props) => (
  <NavigationPrompt when={when}>
    {({ onConfirm, onCancel }) => (
      <ApplicationDiscardConfirmationModal
        isOpen={true}
        onClose={onCancel}
        onDiscard={onConfirm}
      />
    )}
  </NavigationPrompt>
);
