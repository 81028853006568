import React from "react";
import { FormattedMessage } from "react-intl";

import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  modalMessage: JSX.Element;
}

export const ApplicationSaveConfirmationModal = ({
  isOpen,
  onClose,
  onSave,
  modalMessage
}: Props) => (
  <ConfirmationModal
    isOpen={isOpen}
    title={
      <FormattedMessage
        id="applicationSaveConfirmationModal.header"
        defaultMessage="Oled sa kindel, et soovid taotluse täitmist lõpetada?"
      />
    }
    onClose={onClose}
    onSave={onSave}
  >
    {modalMessage}
  </ConfirmationModal>
);
