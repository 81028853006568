import React, { useState } from "react";
import { FileObjectType, FileReference } from "../../../../../../api_client/medre_api";
import { AttachmentContainer } from "../Manual/Attachment/AttachmentContainer";
import { areFileNamesUnique } from "../../../../../Util/FileUtils";
import { displayAlert } from "../../../../../Util/AlertUtil";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { useDispatch } from "react-redux";

interface Props {
  fileReferences: FileReference[];
  saveFiles: (files: File[], fileType: FileObjectType) => void;
  handleFileDelete: (fileName: string, fileType: FileObjectType) => void;
  objectId: string;
}

export const AutomaticAttachmentFields = ({
  fileReferences,
  saveFiles,
  handleFileDelete
}: Props) => {
  const dispatch = useDispatch();

  const [activeAttachmentType, setActiveAttachmentType] = useState<FileObjectType | null>(null);

  const filterFiles = (type: FileObjectType) => {
    return fileReferences?.filter(
      (fileReference) => fileReference.fileObjectType === type
    );
  };

  const validateAndSave = (files: File[], objectType: FileObjectType): void => {
    if (fileReferences && !areFileNamesUnique(files, fileReferences)) {
      displayAlert("fileNameNotUnique", AlertType.Danger, dispatch);
      return;
    }
    saveFiles(files, objectType);
  };

  return (
    <div className="application-fields mt-4">
      <AttachmentContainer
        type={FileObjectType.ProceedingAdditionalDocument}
        isActive={activeAttachmentType === FileObjectType.ProceedingAdditionalDocument}
        onClick={(): void =>
          setActiveAttachmentType(FileObjectType.ProceedingAdditionalDocument)
        }
        fileReferences={filterFiles(FileObjectType.ProceedingAdditionalDocument)}
        maxFilesCount={50}
        saveFiles={(files): void =>
          validateAndSave(files, FileObjectType.ProceedingAdditionalDocument)
        }
        handleFileDelete={(fileName): void =>
          handleFileDelete(fileName, FileObjectType.ProceedingAdditionalDocument)
        }
      />
      <AttachmentContainer
        type={FileObjectType.ProceedingNameChangeDocument}
        isActive={activeAttachmentType === FileObjectType.ProceedingNameChangeDocument}
        onClick={(): void =>
          setActiveAttachmentType(FileObjectType.ProceedingNameChangeDocument)
        }
        fileReferences={filterFiles(FileObjectType.ProceedingNameChangeDocument)}
        maxFilesCount={50}
        saveFiles={(files): void =>
          validateAndSave(files, FileObjectType.ProceedingNameChangeDocument)
        }
        handleFileDelete={(fileName): void =>
          handleFileDelete(fileName, FileObjectType.ProceedingNameChangeDocument)
        }
      />
    </div>
  );
};