import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { BusinessRegistryInfoCard } from "./BusinessRegistryInfoCard";
import { ActivityLicenseContactInfoForm } from "./ActivityLicenseContactInfoForm";
import { SubmittingPersonForm } from "./SubmittingPersonForm";
import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { RootState } from "../../../../rootReducer";
import { getBaseUrl, API } from "../../../../api";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { Loader } from "../../../../Component/Loader/Loader";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { isLocationAddressField } from "../../../../Dto/ActivityLicense/ActivityLicense";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLocation,
  ActivityLicenseApplication,
  FirmadeAndmeteTeenusApiFactory as companyDataServiceAPI,
  EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivityAPI
} from "../../../../../api_client/medre_api";

interface Props {
  registryCode: string;
  isContactInfoDisabled?: boolean;
}

export const ActivityLicenseBusinessData = ({
  registryCode,
  isContactInfoDisabled
}: Props) => {
  const dispatch = useDispatch();
  const activityLicense = useSelector(
    (state: RootState) => state.activityLicenseApplication
  );
  const [validContactInfo, setValidContactInfo] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!activityLicense.id) {
      companyDataServiceAPI(undefined, getBaseUrl(), API)
        .getDetailedBusinessInfo(registryCode, {
          withCredentials: true
        })
        .then((result) => {
          dispatch(
            activityLicenseApplicationActions.updateActivityLicense(
              "company",
              result.data.company
            )
          );
          return result;
        })
        .then((res) => {
          corporateDataActivityAPI(undefined, getBaseUrl(), API)
            .getCompanyByRegistryCode(registryCode, {
              withCredentials: true
            })
            .then((result) => {
              dispatch(
                activityLicenseApplicationActions.updateActivityLicense(
                  "company",
                  {
                    ...res.data.company,
                    email: result.data.email,
                    phone: result.data.phone
                  }
                )
              );
            })
            .finally(() => setIsLoading(false));
        })
        .catch(() => {
          const alertMessage = (
            <AlertMessage id="fetchDetailedBusinessInfoFailed" />
          );
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, registryCode, activityLicense.id]);

  useEffect(() => {
    if (validContactInfo) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          true
        )
      );
    } else {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          false
        )
      );
    }
  }, [dispatch, validContactInfo]);

  const updateAddress = useCallback(
    (field: keyof ContactInfoForm, value?: string | boolean) => {
      if (isLocationAddressField(field)) {
        dispatch(
          activityLicenseApplicationActions.updateLocationInfo(
            0,
            field as keyof ActivityLocation,
            value
          )
        );
      }
    },
    [dispatch]
  );

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      if (field === "email" || field === "phone") {
        dispatch(
          activityLicenseApplicationActions.updateCompanyContacts(field, value)
        );
      }
    },
    [dispatch]
  );

  const setValidContactForm = useCallback((isAllowed: boolean) => {
    setValidContactInfo(isAllowed);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="main-column">
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.businessData"
          defaultMessage="Ettevõtte andmed"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicense.checkBusinessData"
          defaultMessage="Kontrolli ettevõtte andmeid, määra tegevuskoha info ning klõpsa “Edasi”."
        />
      </p>
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.businessRegisterData"
          defaultMessage="Äriregistri andmed"
        />
      </h4>
      <BusinessRegistryInfoCard
        name={activityLicense.company?.name}
        address={activityLicense.company?.address}
        registryCode={activityLicense.company?.registryCode}
      />
      <ActivityLicenseContactInfoForm
        isContactInfoDisabled={!!isContactInfoDisabled}
        updateContactInfo={updateContactInfo}
        email={activityLicense.company?.email}
        phone={activityLicense.company?.phone}
        location={activityLicense.locations ? activityLicense.locations[0] : {}}
        updateAddress={updateAddress}
        setIsFormValid={setValidContactForm}
        isActivityLocationApplication={
          activityLicense.proceedingType ===
          ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation
        }
      />
      <SubmittingPersonForm />
    </div>
  );
};
