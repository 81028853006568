import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { API, getBaseUrl } from "../../../../api";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import {
  ApplicationDeleteConfirmationModal
} from "../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { SendToRenewalModal } from "./SendToRenewalModal";
import { EnterExamCertificateModal } from "./Exam/EnterExamCertificateModal";
import { RenewalModal } from "../Renewal/RenewalModal";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { SigningModal } from "../../../../Signing/SigningModal";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import { ActionButtonClasses } from "../../../../Component/Button/Button";
import { DecisionActions } from "../../ActivityLicense/Application/Details/Decision/DecisionActions";
import {
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialApplicationDataAPI,
  Application,
  ApplicationDraft,
  DecisionType,
  DetailedApplication,
  DetailedApplicationTypeEnum,
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  ProceedingStatusStatusEnum
} from "../../../../../api_client/medre_api";
import { SuccessFormattedButton } from "../../../../Component/Button/SuccessFormattedButton";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AutomaticAcceptanceModal } from "./AutomaticAcceptanceModal";

interface Props {
  updateApplication: (application: Application | ApplicationDraft) => void;
  setIsLoading: (isLoading: boolean) => void;
  isDraft: boolean;
  applicationId: string;
  applicationNumber: number;
  applicationDraft?: ApplicationDraft;
  application?: Application;
  status?: ProceedingStatusStatusEnum;
  isStateFeePaid?: boolean;
  personId?: string;
  refreshApplication: () => void;
}

const PHARMACIST_ID = "a2a69157-11bf-4cff-bd27-ac13243df6de";

export const ApplicationDetailsActions = ({
  isDraft,
  setIsLoading,
  applicationId,
  applicationDraft,
  application,
  applicationNumber,
  status,
  isStateFeePaid,
  refreshApplication,
  updateApplication
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isSendToRenewalModalOpen, setIsSendToRenewalModalOpen] = useState(false);
  const [isEnterComplianceExamModalOpen, setIsEnterComplianceExamModalOpen] = useState(false);
  const [isEnterAptitudeTestModalOpen, setIsEnterAptitudeTestModalOpen] = useState(false);
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [isAutomaticAcceptanceModalOpen, setIsAutomaticAcceptanceModalOpen] = useState(false);

  const continueDraft = (): void | boolean => {
    dispatch(applicationDraftActions.setApplicationDraft(applicationDraft!));
    switch (applicationDraft?.type) {
      case DetailedApplicationTypeEnum.Occupation:
        return history.push(
          `/new-application/${ applicationDraft!.type!.toLowerCase() }`
        );
      case DetailedApplicationTypeEnum.Specialization:
        return history.push("/new-application/speciality");
      case DetailedApplicationTypeEnum.ApplicationCertificate:
        return history.push("/new-application/certificate");
      default:
        return false;
    }
  };

  const deleteApplication = (): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .deleteApplicationDraft(applicationId, {
        withCredentials: true
      })
      .then((res) => {
        displayAlert("applicationDraftDeleteSuccess", AlertType.Success, dispatch);
        history.push("/search");
      })
      .catch((error) => {
        displayAlert("applicationDraftDeleteFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const changeStatusTo = (status: ProceedingStatusStatusEnum): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .changeApplicationStatus(
        applicationId,
        { status },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        updateApplication(response.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
      })
      .catch((e) => {
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const initiateProceeding = (): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .initiateApplicationProceeding(applicationId, {
        withCredentials: true
      })
      .then((response) => {
        updateApplication(response.data);
        displayAlert("initiateProceedingSuccess", AlertType.Success, dispatch);
      })
      .catch((e) => {
        displayAlert("initiateProceedingFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const continueProceeding = (): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .continueApplicationProceeding(applicationId, {
        withCredentials: true
      })
      .then((response) => {
        updateApplication(response.data);
        displayAlert("continueProceedingSuccess", AlertType.Success, dispatch);
      })
      .catch((e) => {
        displayAlert("continueProceedingFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const sendToRenewal = (notes: string) => {
    setIsLoading(true);
    return officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .pauseApplicationProceeding1(
        applicationId,
        {
          noteText: notes
        },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        updateApplication(response.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        setIsSendToRenewalModalOpen(false);
        return Promise.resolve();
      })
      .catch((error) => {
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  };

  const acceptApplicationAutomatically = (): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .acceptApplicationAutomatically(applicationId, { withCredentials: true })
      .then((res) => {
        updateApplication(res.data);
        displayAlert("applicationAcceptedAutomatically", AlertType.Success, dispatch);
        setIsAutomaticAcceptanceModalOpen(false);
      })
      .catch((e) => {
        displayAlert("automaticAcceptingFailed", AlertType.Danger, dispatch);
        setIsAutomaticAcceptanceModalOpen(false);
      })
      .finally(() => setIsLoading(false));
  };

  const renderSignButtons = (
    signButtonId: ButtonName,
    newStatus: ProceedingStatusStatusEnum
  ) => {
    return (
      <>
        <ProtectedFieldWrapper allowedRoles={[OfficialUserPrivilegesEnum.Sign]}>
          <PrimaryFormattedButton
            id={signButtonId}
            className={ActionButtonClasses}
            onClick={(): void => setIsSignModalOpen(true)}
          />
        </ProtectedFieldWrapper>
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <SecondaryFormattedButton
            id="revertWaitingForSignatureStatus"
            className={ActionButtonClasses}
            onClick={(): void => changeStatusTo(newStatus)}
          />
          <SigningModal
            isOpen={isSignModalOpen}
            onClose={(): void => setIsSignModalOpen(false)}
            decisionId={application?.decisions?.find((d) => !d.signed)?.id!}
            onSuccess={(): void => {
              setIsSignModalOpen(false);
              refreshApplication();
            }}
          />
        </ProtectedFieldWrapper>
      </>
    );
  };

  const renderApplicationActionButtons = () => {
    if (isDraft) {
      return;
    }

    switch (status) {
      case ProceedingStatusStatusEnum.Submitted:
        return isStateFeePaid ?
          (
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <PrimaryFormattedButton
                id="initiateProceeding"
                className={ActionButtonClasses}
                onClick={initiateProceeding}
              />
              <SecondaryFormattedButton
                id="sendToRenewal"
                className={ActionButtonClasses}
                onClick={(): void => setIsSendToRenewalModalOpen(true)}
              />
              <SecondaryFormattedButton
                id="finishProceeding"
                className={ActionButtonClasses}
                onClick={(): void =>
                  changeStatusTo(ProceedingStatusStatusEnum.Finished)
                }
              />
            </ProtectedFieldWrapper>
          ) :
          (
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <SecondaryFormattedButton
                id="finishProceeding"
                className={ActionButtonClasses}
                onClick={(): void =>
                  changeStatusTo(ProceedingStatusStatusEnum.Finished)
                }
              />
            </ProtectedFieldWrapper>
          );
      case ProceedingStatusStatusEnum.InProceeding:
        return (
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <PrimaryFormattedButton
              id="acceptApplication"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(
                  ProceedingStatusStatusEnum.UploadAcceptedDecision
                )
              }
            />
            <SecondaryFormattedButton
              id="renewApplication"
              className={ActionButtonClasses}
              onClick={(): void => setIsRenewalModalOpen(true)}
            />
            <SecondaryFormattedButton
              id="decline"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(
                  ProceedingStatusStatusEnum.UploadDeclinedDecision
                )
              }
            />
            <SecondaryFormattedButton
              id="sendToRenewal"
              className={ActionButtonClasses}
              onClick={(): void => setIsSendToRenewalModalOpen(true)}
            />
            <SecondaryFormattedButton
              id="sendToComplianceExam"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(
                  ProceedingStatusStatusEnum.UploadComplianceExamDecision
                )
              }
            />
            { application?.occupation?.id === PHARMACIST_ID && (
              <SecondaryFormattedButton
                id="sendToAptitudeTest"
                className={ActionButtonClasses}
                onClick={(): void =>
                  changeStatusTo(
                    ProceedingStatusStatusEnum.UploadAptitudeTestDecision
                  )
                }
              />
            ) }
            <SecondaryFormattedButton
              id="finishProceeding"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(ProceedingStatusStatusEnum.Finished)
              }
            />
            { application?.educationInfo?.fromEHIS === true &&
                <SuccessFormattedButton
                  id="acceptAutomatically"
                  className={ActionButtonClasses}
                  onClick={(): void => setIsAutomaticAcceptanceModalOpen(true)}
                />
            }
            { isAutomaticAcceptanceModalOpen &&
                <AutomaticAcceptanceModal
                  isOpen={isAutomaticAcceptanceModalOpen}
                  onClose={(): void => setIsAutomaticAcceptanceModalOpen(false)}
                  onAccept={acceptApplicationAutomatically}
                />
            }
          </ProtectedFieldWrapper>
        );
      case ProceedingStatusStatusEnum.UploadAcceptedDecision:
        return (
          <DecisionActions
            fileObjectType={FileObjectType.ApplicationAcceptanceDecision}
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.UploadDeclinedDecision:
        return (
          <DecisionActions
            fileObjectType={FileObjectType.ApplicationDeclinationDecision}
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.UploadAptitudeTestDecision:
        return (
          <DecisionActions
            fileObjectType={
              FileObjectType.ApplicationSendToAptitudeTestDecision
            }
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.UploadAptitudeTestResult:
        return (
          <DecisionActions
            fileObjectType={
              FileObjectType.ApplicationAptitudeTestResultDecision
            }
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.UploadComplianceExamDecision:
        return (
          <DecisionActions
            fileObjectType={
              FileObjectType.ApplicationSendToComplianceExamDecision
            }
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.UploadComplianceExamResult:
        return (
          <DecisionActions
            fileObjectType={
              FileObjectType.ApplicationComplianceExamResultDecision
            }
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refreshApplication}
            decision={{
              id: applicationId,
              type: DecisionType.Application
            }}
            changeStatusTo={changeStatusTo}
            isFinishProceedingAllowed
          />
        );
      case ProceedingStatusStatusEnum.SignAcceptedDecision:
        return renderSignButtons(
          "signApplicationAcceptanceDecision",
          ProceedingStatusStatusEnum.InProceeding
        );
      case ProceedingStatusStatusEnum.SignDeclinedDecision:
        return renderSignButtons(
          "signApplicationDeclinationDecision",
          ProceedingStatusStatusEnum.InProceeding
        );
      case ProceedingStatusStatusEnum.SignComplianceExamDecision:
        return renderSignButtons(
          "signComplianceExamDecision",
          ProceedingStatusStatusEnum.InProceeding
        );
      case ProceedingStatusStatusEnum.SignAptitudeTestDecision:
        return renderSignButtons(
          "signAptitudeTestDecision",
          ProceedingStatusStatusEnum.InProceeding
        );
      case ProceedingStatusStatusEnum.SignComplianceExamResult:
        return renderSignButtons(
          "signDecision",
          ProceedingStatusStatusEnum.PausedDueToComplianceExam
        );
      case ProceedingStatusStatusEnum.SignAptitudeTestResult:
        return renderSignButtons(
          "signDecision",
          ProceedingStatusStatusEnum.PausedDueToAptitudeTest
        );
      case ProceedingStatusStatusEnum.PausedDueToAptitudeTest:
        return (
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <PrimaryFormattedButton
              id="enterAptitudeTest"
              className={ActionButtonClasses}
              onClick={(): void => setIsEnterAptitudeTestModalOpen(true)}
            />
            <SecondaryFormattedButton
              id="finishProceeding"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(ProceedingStatusStatusEnum.Finished)
              }
            />
          </ProtectedFieldWrapper>
        );
      case ProceedingStatusStatusEnum.PausedDueToComplianceExam:
        return (
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <PrimaryFormattedButton
              id="enterComplianceExam"
              className={ActionButtonClasses}
              onClick={(): void => setIsEnterComplianceExamModalOpen(true)}
            />
            <SecondaryFormattedButton
              id="finishProceeding"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(ProceedingStatusStatusEnum.Finished)
              }
            />
          </ProtectedFieldWrapper>
        );
      case ProceedingStatusStatusEnum.Paused:
        return (
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <SecondaryFormattedButton
              id="continueProceeding"
              className={ActionButtonClasses}
              onClick={(): void => continueProceeding()}
            />
            <SecondaryFormattedButton
              id="renewApplication"
              className={ActionButtonClasses}
              onClick={(): void => setIsRenewalModalOpen(true)}
            />
            <SecondaryFormattedButton
              id="finishProceeding"
              className={ActionButtonClasses}
              onClick={(): void =>
                changeStatusTo(ProceedingStatusStatusEnum.Finished)
              }
            />
          </ProtectedFieldWrapper>
        );
      default:
        return;
    }
  };

  const renderDraftActionButtons = () => {
    if (!isDraft) {
      return;
    }
    return (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="continueWithSavedDraft"
          className={ActionButtonClasses}
          onClick={continueDraft}
        />
        <DangerFormattedButton
          id="deleteApplication"
          className={ActionButtonClasses}
          onClick={(): void => setDeleteModalIsOpen(true)}
        />
        <ApplicationDeleteConfirmationModal
          applicationNumber={applicationNumber}
          isOpen={deleteModalIsOpen}
          onClose={(): void => setDeleteModalIsOpen(false)}
          onDelete={deleteApplication}
        />
      </ProtectedFieldWrapper>
    );
  };

  const handleCertificateDeletion = (minioFileName: string): void => {
    const references = application?.fileReferences?.filter(
      (ref) => ref.minioFileName !== minioFileName
    );
    updateApplication({
      ...application,
      fileReferences: references
    });
  };

  const handleCertificateUpload = (file: FileReference[]): void => {
    if (application?.fileReferences) {
      updateApplication({
        ...application,
        fileReferences: [...application?.fileReferences, ...file]
      });
    } else {
      updateApplication({
        ...application,
        fileReferences: file
      });
    }
  };

  const handleRenewal = (updatedApplication: DetailedApplication): void => {
    setIsRenewalModalOpen(false);
    updateApplication(updatedApplication);
  };

  const getCertificatesOfType = (fileObjectType: FileObjectType) => {
    return application?.fileReferences?.filter(
      (file) => file.fileObjectType === fileObjectType
    );
  };

  return (
    <>
      <h2>
        <span id="actions" /> Tegevused
      </h2>
      <div className="mt-1 mb-4">
        { renderDraftActionButtons() }
        { renderApplicationActionButtons() }
      </div>
      <SendToRenewalModal
        isOpen={isSendToRenewalModalOpen}
        onClose={(): void => setIsSendToRenewalModalOpen(false)}
        onSubmit={sendToRenewal}
      />
      <EnterExamCertificateModal
        isOpen={isEnterComplianceExamModalOpen}
        onClose={(): void => setIsEnterComplianceExamModalOpen(false)}
        applicationId={applicationId}
        certificates={
          getCertificatesOfType(FileObjectType.ProceedingComplianceExamCert) || []
        }
        fileUploaded={handleCertificateUpload}
        fileDeleted={handleCertificateDeletion}
        updateApplication={updateApplication}
        type={FileObjectType.ProceedingComplianceExamCert}
      />
      <EnterExamCertificateModal
        isOpen={isEnterAptitudeTestModalOpen}
        onClose={(): void => setIsEnterAptitudeTestModalOpen(false)}
        applicationId={applicationId}
        certificates={
          getCertificatesOfType(FileObjectType.ProceedingAptitudeTestCert) || []
        }
        fileUploaded={handleCertificateUpload}
        fileDeleted={handleCertificateDeletion}
        updateApplication={updateApplication}
        type={FileObjectType.ProceedingAptitudeTestCert}
      />
      { !isDraft && (
        <RenewalModal
          isOpen={isRenewalModalOpen}
          onClose={(): void => setIsRenewalModalOpen(false)}
          personId={application?.personId!}
          applicationId={applicationId}
          educationInfo={application?.educationInfo!}
          fileReferences={application?.fileReferences || []}
          occupationCode={application?.occupation?.code}
          onRenewalSuccess={handleRenewal}
        />
      ) }
    </>
  );
};
