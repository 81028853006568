import React from "react";

import "./SuspensionModal.scss";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => Promise<void>;
}

export const SuspensionCancellationConfirmationModal = ({
  isOpen,
  onClose,
  onConfirmation
}: Props) => (
  <ConfirmationModal
    isOpen={isOpen}
    title="Olete Te kindel, et soovite peatamisõiguse tegevuse tühistada?"
    onClose={onClose}
    onAsyncSave={onConfirmation}
    saveButtonId="confirm"
    closeButtonId="backToDetailView"
    footerClassName="justify-content-between"
  >
    Peatamisõiguse tegevuse tühistamisel eemaldatakse süsteemist peatamisõiguse
    tühistamise info koos otsusega.
  </ConfirmationModal>
);
