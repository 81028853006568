import React from "react";
import { components } from "react-select";
import { NoticeProps } from "react-select/src/components/Menu";
import { TypedOption } from "./SelectTypes";
import { FormattedMessage } from "react-intl";

export const NoOptionsMessage: React.ComponentType<NoticeProps<TypedOption<any>, boolean>> = (props) => (
    <components.NoOptionsMessage {...props}>
        <FormattedMessage
            id="singleSelect.NoOptions"
            defaultMessage="Valikuid ei leitud"
        />
    </components.NoOptionsMessage>
);

let asyncSelectTimeOut: ReturnType<typeof setTimeout>;

export const load = (
    loadMethod: (inputValue: string) => Promise<{ data: any }>,
    filterOption: (option: any) => boolean,
    toOption: (option: any) => any
) => (inputValue: string) => {
    if (asyncSelectTimeOut) {
        clearTimeout(asyncSelectTimeOut);
    }
    return new Promise((resolve) => {
        if (inputValue.length > 2) {
            asyncSelectTimeOut = setTimeout(async () => {
                const { data } = await loadMethod(inputValue);
                resolve(data?.filter(filterOption).map(toOption));
            }, 1000);
        } else {
            resolve([]);
        }
    });
};
