import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { AxiosError } from "axios";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { addDays } from "date-fns";

import { RootState } from "../../../../rootReducer";
import {
  RadioButtonElement,
  RadioGroup
} from "../../../../Component/Radio/RadioGroup";
import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import info_icon from "../../../../../assets/images/information.svg";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  TegevuslubadePeatamisteTaotlusteTeenusApiFactory as suspensionActivityPermitAPI,
  Suspension,
  ServiceSuspension,
  AmetnikuTegevuslubadePeatamiseTeenusApiFactory as activityPermitSuspensionAPI,
  TegevuslubadeTeenusApiFactory as activityPermitAPI,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI,
  ActivityLocationStatusEnum,
  LocationWithSuspensionInfo,
  ActivityLicenseSuspensionInfo,
  ActivityLicenseStatusEnum,
  SuspensionSuspensionReasonEnum, ServiceSuspensionInfo, ActivityLicenseServiceServiceStatusEnum
} from "../../../../../api_client/medre_api";
import { dateToString } from "../../../../Util/DateUtils";
import useModal from "../../../../Hook/useModal";
import { ErrorResponseData } from "../../../../Dto/Error/Error";

interface Props {
  isOpen: boolean;
  onClose: (
    suspensionType?: ActivityLicenseApplicationProceedingTypeEnum
  ) => void;
  modalTitle: JSX.Element;
  suspensionType: ActivityLicenseApplicationProceedingTypeEnum;
  idToSuspend: string;
  onSubmitEmit?: () => void;

  //License specific:
  activityLicenseSuspensionInfo?: ActivityLicenseSuspensionInfo;
  //Location specific:
  locationSuspensionInfo?: LocationWithSuspensionInfo;
  //Service specific:
  serviceIdsToSuspend?: string[];
  serviceSuspensionInfo?: ServiceSuspensionInfo;
}

type SuspensionForm = {
  suspensionReason?: SuspensionSuspensionReasonEnum;
  startDate?: Date;
  endDate?: Date;
  explanationNotes?: string;
};

export const SuspensionModalContainer = ({
  isOpen,
  onClose,
  modalTitle,
  suspensionType,
  idToSuspend,
  serviceIdsToSuspend,
  locationSuspensionInfo,
  activityLicenseSuspensionInfo,
  serviceSuspensionInfo,
  onSubmitEmit
}: Props) => {
  const dispatch = useDispatch();
  const isPortal = useSelector((state: RootState) => state.config.isPortal);
  const maxSuspensionDuration = useSelector(
    (state: RootState) => state.config.maxSuspensionDuration
  );
  const maxSuspensionStartDate = useSelector(
    (state: RootState) => state.config.maxSuspensionStartDate
  );
  const [suspendedDays, setSuspendedDays] =
    useState<number>(0);

  const defaultValues: SuspensionForm = {
    startDate: undefined,
    endDate: undefined,
    suspensionReason: SuspensionSuspensionReasonEnum.Personnel,
    explanationNotes: ""
  };
  const {
    handleSubmit,
    register,
    control,
    errors,
    reset,
    formState,
    trigger,
    setValue
  } = useForm<SuspensionForm>({
    defaultValues,
    mode: "onBlur"
  });

  const [suspensionReason, setSuspensionReason] =
    useState<SuspensionSuspensionReasonEnum>(
        SuspensionSuspensionReasonEnum.Personnel
    );
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { touched, isSubmitted, isSubmitSuccessful } = formState;
  const discardModal = useModal();

  useEffect(() => {
    if (startDate) {
      trigger("endDate");
    }

  }, [trigger, startDate]);

  useEffect(() => {
    setCorrectStartDateIfLastSuspensionAvailable()
    setSuspensionDaysBasedOnSuspensionType()

  }, [activityLicenseSuspensionInfo, locationSuspensionInfo]);

  const setSuspensionDaysBasedOnSuspensionType = () => {
    let days = 0;

    switch (suspensionType) {
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
        days = activityLicenseSuspensionInfo?.licenseSuspendedDays!;
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
        days = locationSuspensionInfo?.locationSuspendedDays!;
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
        days = serviceSuspensionInfo?.serviceSuspendedDays!;
        break;
      default:
        days = 0;
        break;
    }
    setSuspendedDays(days);
  }

  const setCorrectStartDateIfLastSuspensionAvailable = () => {
    if (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension) {
      if (activityLicenseSuspensionInfo?.lastLicenseSuspensionEndDate) {
        const startDate: Date = new Date(activityLicenseSuspensionInfo.lastLicenseSuspensionEndDate);
        startDate.setDate(startDate.getDate() + 1);
        handleStartDateChange(startDate);
      }
    } else if (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension) {
      if (locationSuspensionInfo?.lastLocationSuspensionEndDate) {
        const startDate: Date = new Date(locationSuspensionInfo.lastLocationSuspensionEndDate);
        startDate.setDate(startDate.getDate() + 1);
        handleStartDateChange(startDate);
      }
    } else if (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension) {
      if (serviceSuspensionInfo?.lastServiceSuspensionEndDate) {
        const startDate: Date = new Date(serviceSuspensionInfo.lastServiceSuspensionEndDate);
        startDate.setDate(startDate.getDate() + 1);
        handleStartDateChange(startDate);
      }
    }
  }

  const radioButtons: RadioButtonElement[] = [
    {
      id: "personnel",
      value: SuspensionSuspensionReasonEnum.Personnel,
      labelText: (
        <FormattedMessage
          id="suspendActivityLicenseModal.personnel"
          defaultMessage="Personal"
        />
      )
    },
    {
      id: "rooms",
      value: SuspensionSuspensionReasonEnum.Rooms,
      labelText: (
        <FormattedMessage
          id="suspendActivityLicenseModal.rooms"
          defaultMessage="Ruumid/vahendid"
        />
      )
    }
  ];

  const handleSuspensionReasonChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reason = e.target
      .value as SuspensionSuspensionReasonEnum;
    setSuspensionReason(reason);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setValue("startDate", date, { shouldValidate: true });
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setValue("endDate", date, { shouldValidate: true });
  };

  const handleClose = (
    serviceSuspensionType?: ActivityLicenseApplicationProceedingTypeEnum
  ) => {
    reset(defaultValues);
    discardModal.handleClose();
    onClose(serviceSuspensionType);
  };

  const validateEndDate = (value: Date) => {
    if (startDate && value) {
      let endMoment = moment(value, "DD.MM.YYYY").startOf("day");
      let startMoment = moment(startDate).startOf("day");
      let duration = endMoment.diff(startMoment, "days") + 1; // Adding +1 because endDate is not included by default
      return (
        !endMoment.isBefore(startMoment) &&
        duration <= maxSuspensionDuration! - suspendedDays
      );
    }
    return true;
  };

  const submitData = (formData: SuspensionForm) => {
    let data: Suspension | ServiceSuspension = {
      startDate: dateToString(formData.startDate),
      endDate: dateToString(formData.endDate),
      suspensionReason: suspensionReason,
      explanationNotes: formData.explanationNotes
    };

    switch (suspensionType) {
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
        return isPortal
          ? activityPermitAPI(
              undefined,
              getBaseUrl(),
              API
            ).suspendActivityLocation(idToSuspend, data as Suspension, {
              withCredentials: true
            })
          : officialActivityPermitAPI(
              undefined,
              getBaseUrl(),
              API
            ).suspendActivityLicenseLocation(idToSuspend, data as Suspension, {
              withCredentials: true
            });
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
        data = {
          startDate: dateToString(formData.startDate),
          endDate: dateToString(formData.endDate),
          suspensionReason: suspensionReason,
          explanationNotes: formData.explanationNotes,
          serviceIdsToSuspend: serviceIdsToSuspend
        };
        return isPortal
          ? activityPermitAPI(
              undefined,
              getBaseUrl(),
              API
            ).suspendActivityLicenseService1(
              idToSuspend,
              data as ServiceSuspension,
              {
                withCredentials: true
              }
            )
          : officialActivityPermitAPI(
              undefined,
              getBaseUrl(),
              API
            ).suspendActivityLicenseService(
              idToSuspend,
              data as ServiceSuspension,
              {
                withCredentials: true
              }
            );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
      default:
        return isPortal
          ? suspensionActivityPermitAPI(
              undefined,
              getBaseUrl(),
              API
            ).initiateActivityLicenseSuspension1(idToSuspend, data, {
              withCredentials: true
            })
          : activityPermitSuspensionAPI(
              undefined,
              getBaseUrl(),
              API
            ).initiateActivityLicenseSuspension(idToSuspend, data, {
              withCredentials: true
            });
    }
  };

  const onSubmit = (form: SuspensionForm) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    // Manually set startDate to ensure it's sent correctly even if field is disabled
    const finalFormData = {
      ...form,
      startDate: startDate
    };

    submitData(finalFormData)
      .then((res) => {
        const alertMessage = <AlertMessage id="applicationSubmitted" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setSuspendedDays((prevState) => prevState + (res.data?.duration || 0));
        handleClose(suspensionType);

        if (onSubmitEmit) {
          onSubmitEmit()
        }
      })
      .catch((error: AxiosError) => {
        let responseData = error.response?.data as ErrorResponseData;
        let alertMessage, alert;
        if (responseData.error === "InvalidSuspensionDurationException") {
          alertMessage = (
            <AlertMessage id="invalidSuspensionDurationException" />
          );
        } else {
          alertMessage = <AlertMessage id="activityLicenseSubmitFailed" />;
        }
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => setIsSubmitting(false));
  };

  const getTooltipMessage = () => {
    return (
      <FormattedMessage
        id="suspendActivityLicenseModal.infoIconTooltip"
        defaultMessage="Alguskuupäev maksimaalselt 1 kuu tulevikus. Maksimaalne peatamise periood on 2 aastat üle kõigi järjestikuste peatamiste"
      />
    );
  };

  const renderDiscardModal = () => {
    return (
      <ConfirmationModal
        isOpen={discardModal.isOpen}
        onClose={discardModal.handleClose}
        title={
          <FormattedMessage
            id="suspendActivityLicenseModal.confirmCancel"
            defaultMessage="Kas olete kindel, et soovite peatamise taotlusest loobuda?"
          />
        }
        onSave={handleClose}
        closeButtonId="backToApplication"
        saveButtonId="confirm"
      />
    );
  };

  const isActivityLocationSuspended = suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension && (ActivityLocationStatusEnum.Valid !== locationSuspensionInfo?.locationStatus);
  const isActivityLicenseSuspended = (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension && (ActivityLicenseStatusEnum.Valid !== activityLicenseSuspensionInfo?.licenseStatus));
  const isServiceSuspended = (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension && (ActivityLicenseServiceServiceStatusEnum.Active !== serviceSuspensionInfo?.serviceStatus));

  const isActivityLocationPausedOrInvalid = suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension && (locationSuspensionInfo?.locationStatus === "PAUSED" || locationSuspensionInfo?.locationStatus === "INVALID");
  const isActivityLicensePausedOrInvalid = suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension && (activityLicenseSuspensionInfo?.licenseStatus === "PAUSED" || activityLicenseSuspensionInfo?.licenseStatus === "INVALID");
  const isServiceSuspendedOrDiscarded = suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension && (serviceSuspensionInfo?.serviceStatus === "SUSPENDED" || serviceSuspensionInfo?.serviceStatus === "DISCARDED");

  const renderContent = () => {
    return (
      <Modal isOpen={isOpen} id="suspend-activity-license-modal">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader tag="h4">{modalTitle}</ModalHeader>
          <ModalBody className="pt-0">
            {
              (isActivityLocationSuspended ||
                 isActivityLicenseSuspended ||
                  isServiceSuspended
              )
                && <div>
              <FormattedMessage
                  id="suspendActivityLicenseModal.currentSuspendedDays"
                  defaultMessage="Esitatud peatamisi päevades: {totalSuspendedDays}"
                  values={{totalSuspendedDays: suspendedDays}}
              />
            </div>
            }
            <div>
              <FormattedMessage
                  id="suspendActivityLicenseModal.totalSuspendedDays"
                  defaultMessage="Kokku võimalik peatamisi päevades esitada: {maxSuspensionDuration}"
                  values={{maxSuspensionDuration: maxSuspensionDuration}}
              />
            </div>
            <div className="d-flex">
              <div className="label">
                <FormattedMessage
                    id="suspendActivityLicenseModal.suspensionReason"
                    defaultMessage="Peatamise põhjus"
                />
              </div>
              <RadioGroup
                  options={radioButtons}
                  value={suspensionReason}
                  name="suspensionReason"
                  className="d-flex"
                  onChange={handleSuspensionReasonChange}
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="label d-inline-flex">
                <FormattedMessage
                    id="suspendActivityLicenseModal.suspensionPeriod"
                    defaultMessage="Peatamise periood"
                />
                <FormattedToolTip
                    placement="top"
                    target="suspension-period-info-icon"
                    message={getTooltipMessage()}
                />
                <img
                    id="suspension-period-info-icon"
                    src={info_icon}
                    alt="icon"
                    className="pl-1"
                />
              </div>
              <div className="d-flex align-items-center">
                <Controller
                    control={control}
                    name="startDate"
                    rules={(() => {
                      switch (suspensionType) {
                        case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
                          return {
                            required: !(locationSuspensionInfo?.locationStatus === "PAUSED" || locationSuspensionInfo?.locationStatus === "INVALID"),
                          };
                        case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
                          return {
                            required: !(activityLicenseSuspensionInfo?.licenseStatus === "PAUSED" || activityLicenseSuspensionInfo?.licenseStatus === "INVALID"),
                          };
                        case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
                          return {
                            required: !(serviceSuspensionInfo?.serviceStatus === "SUSPENDED" || serviceSuspensionInfo?.serviceStatus === "DISCARDED"),
                          };
                        default:
                          return {};
                      }
                    })()}
                    defaultValue=""
                    placeholder="pp.kk.aaaa"
                    as={
                      <DatePickerComponent
                          onDateChange={handleStartDateChange}
                          minDate={new Date()}
                          maxDate={addDays(new Date(), maxSuspensionStartDate!)}
                          disabled={
                              isActivityLocationPausedOrInvalid ||
                              isActivityLicensePausedOrInvalid ||
                              isServiceSuspendedOrDiscarded
                      }
                      />
                    }
                    selectedDate={startDate}
                    valid={!!startDate && !errors.startDate}
                    invalid={!!errors.startDate}
                />
                <span>–</span>
                <Controller
                    control={control}
                    name="endDate"
                    rules={{required: true, validate: validateEndDate}}
                    defaultValue=""
                    placeholder="pp.kk.aaaa"
                    as={
                      <DatePickerComponent onDateChange={handleEndDateChange}/>
                    }
                    selectedDate={endDate}
                    valid={!!endDate && !errors.endDate}
                    invalid={!!errors.endDate}
                />
              </div>
            </div>

            <div className="d-flex">
              <div className="label">
                <FormattedMessage
                    id="suspendActivityLicenseModal.suspensionExplanation"
                    defaultMessage="Peatamise põhjendus"
                />
              </div>
              <Input
                  type="textarea"
                  name="explanationNotes"
                  valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                          touched.explanationNotes) &&
                      !errors.explanationNotes
                  }
                  invalid={!!errors.explanationNotes}
                  innerRef={register({required: true})}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <SecondaryFormattedButton
                id="back"
                type="reset"
                onClick={discardModal.handleOpen}
            />
            <PrimaryFormattedButton id="sendApplication" type="submit"/>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {renderContent()}
      {discardModal.isOpen && renderDiscardModal()}
    </>
  );
};
