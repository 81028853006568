import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { Loader } from "../../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { DetailsCard } from "./DetailsCard";
import { ApplicationNotes } from "../../../Application/Details/ApplicationNotes";
import { LocationAccordion } from "../../Details/LocationAccordion";
import { ActivityLicenseDetailsHistory } from "../../Application/Details/ActivityLicenseDetailsHistory";
import "../../../../Shared/Application/Application.scss";
import "../../../../Shared/ActivityLicense/ActivityLicense.scss";
import { ActivityLicenseSuspensionDetailsActions } from "./ActivityLicenseSuspensionDetailsActions";
import { ApplicationDecisions } from "../../../Application/Details/ApplicationDecisions";
import {
  DetailedActivityLicenseSuspension,
  AmetnikuTegevuslubadePeatamiseTeenusApiFactory as officialActivityPermitAPI
} from "../../../../../../api_client/medre_api";
import { useDetailedApplication } from "../../Details/ActivityLicenseApplicationDetailsHook";
import { getBaseUrl, API } from "../../../../../api";

export const ActivityLicenseSuspensionDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { details, setDetails, isLoading, setIsLoading, refresh } =
    useDetailedApplication<DetailedActivityLicenseSuspension>(
      officialActivityPermitAPI(undefined, getBaseUrl(), API)
        .getDetailedActivityLicenseSuspension,
      id
    );

  if (!details || isLoading) {
    return <Loader />;
  }

  return (
    <div className="application-container application-detail">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details?.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details?.currentStatus}
          isDetailed={false}
        />
      </div>
      <div className="ml-3">
        <div className="mb-2 font-size-18">Hüppa kiirelt:</div>
        <ul className="quick-links list-group">
          <li className="d-block my-2">
            <a href="#actions">Tegevused</a>
          </li>
          <li className="d-block my-2">
            <a href="#details">Taotluse detailid</a>
          </li>
          <li className="d-block my-2">
            <a href="#notes">Menetluse märkmed</a>
          </li>
          <li className="d-block my-2">
            <a href="#history">Menetluse ajalugu</a>
          </li>
        </ul>
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <ActivityLicenseSuspensionDetailsActions
          refresh={refresh}
          setDetails={setDetails}
          details={details}
          setIsLoading={setIsLoading}
        />
      </Card>
      <DetailsCard
        details={details!}
        title="Tegevusloa peatamise taotluse detailid"
      />
      <ApplicationNotes
        notes={details.notes || ""}
        setDetails={setDetails}
        detailsId={details.id}
        endpoint={
          officialActivityPermitAPI(undefined, getBaseUrl(), API)
            .updateActivityLicenseSuspensionNote
        }
      />
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="locations" /> Tegevuskohad ja teenused
        </h2>
        <LocationAccordion
          activityLocations={details?.locations || []}
          isHospital={false}
          isEditable={false}
        />
      </Card>
      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="resolutions" /> Otsused
          <ApplicationDecisions decisions={details.decisions || []} />
        </h2>
      </Card>
      <ActivityLicenseDetailsHistory
        auditLogItems={details.auditLogItems || []}
      />
    </div>
  );
};
