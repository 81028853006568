import React from "react";
import { SuccessBadge } from "../../../Component/Badge/SuccessBadge";
import { DangerBadge } from "../../../Component/Badge/DangerBadge";
import { PrimaryBadge } from "../../../Component/Badge/PrimaryBadge";
import { InsuranceContractStatus } from "../../../../api_client/medre_api";

interface Props {
  currentStatus?: InsuranceContractStatus | null;
  index?: number;
}

export const InsuranceContractState = ({ currentStatus, index }: Props) => {
  switch (currentStatus) {
    case InsuranceContractStatus.Valid:
      return <SuccessBadge id={`valid-${ index }`} message="valid" />;
    case InsuranceContractStatus.Expired:
      return <DangerBadge id={`expired-${ index }`} message="expired" />;
    case InsuranceContractStatus.Future:
      return <PrimaryBadge id={`future-${ index }`} message="future" />;
    case InsuranceContractStatus.None:
    case undefined:
    case null:
      return <DangerBadge id={`none-${ index }`} message="none" />;
    default:
      return <></>;
  }
};

export const PublicInsuranceContractState = ({currentStatus, index}: Props) => {
  switch (currentStatus) {
    case InsuranceContractStatus.Valid:
      return <SuccessBadge id={`yes-${ index }`} message="yes" />;
    default:
      return <DangerBadge id={`no-${ index }`} message="no" />;
  }
};