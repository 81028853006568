import React from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "./SendToRenewalModal.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (notes: string) => Promise<void>;
}

type RenewalForm = {
  renewalNotes: string;
};

export const SendToRenewalModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const defaultValues: RenewalForm = {
    renewalNotes: ""
  };

  const { handleSubmit, register, errors, reset, formState } =
    useForm<RenewalForm>({ defaultValues, mode: "onBlur" });
  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const submit = (data: RenewalForm) => {
    onSubmit(data.renewalNotes).then(() => reset(defaultValues));
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <Modal id="send-to-renewal-modal" isOpen={isOpen}>
      <Form onSubmit={handleSubmit(submit)}>
        <ModalHeader>
          <FormattedMessage
            id="SendToRenewalModal.header"
            defaultMessage="Suuna täiendamisele"
          />
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div className="label">
              <FormattedMessage
                id="SendToRenewalModal.body"
                defaultMessage="Lisainfo taotlejale"
              />
            </div>
            <Input
              type="textarea"
              name="renewalNotes"
              valid={
                ((isSubmitted && !isSubmitSuccessful) ||
                  touched.renewalNotes) &&
                !errors.renewalNotes
              }
              invalid={!!errors.renewalNotes}
              innerRef={register({ required: true })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="backToApplication"
            onClick={handleClose}
          />
          <PrimaryFormattedButton id="sendToRenewal" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
