import { RootAction } from "../rootReducer";
import { SET_FEATURE_FLAG } from "./featureFlagActions";
import { Reducer } from "redux";
import { FeatureFlagState } from "../Dto/FeatureFlag/FeatureFlag";

const initialState = {
  featureFlags: {}
};

export const featureFlagReducer: Reducer<FeatureFlagState> = (
  state = initialState,
  action: RootAction
) => {
  switch (action.type) {
    case SET_FEATURE_FLAG:
      return {
        ...state,
        featureFlags: { ...state.featureFlags, [action.payload.id]: action.payload.isEnabled }
      };
    default:
      return state;
  }
};