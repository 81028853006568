import { AddressState } from "./AddressState";
import { Reducer } from "redux";
import { RootAction } from "../rootReducer";
import { SET_COUNTRIES, SET_PARISHES } from "./addressActions";

const initialState: AddressState = {
  countries: [],
  parishes: [],
};

export const addressReducer: Reducer<AddressState> = (
  state= initialState,
  action: RootAction
) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_PARISHES:
      return { ...state, parishes: action.payload };
    default:
      return state;
  }
};
