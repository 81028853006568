import React, { FC, forwardRef, Ref } from "react";
import { getFullName } from "../../../Util/PersonUtils";
import { FormattedMessage } from "react-intl";
import { ApplicantOption } from "./fetchApplicantOptions";
import AsyncSelect, { AsyncProps } from "react-select/async";
import { Col, FormGroup, Label } from "reactstrap";
import {
  GeneralPractitioner,
  Substitute
} from "../../../../api_client/medre_api";
import { RemoveButton } from "../../../Component/Button/RemoveButton";
import { ModalKeys } from "../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";

type GeneralPractitionerListDoctor = GeneralPractitioner | Substitute;
interface GeneralPractitionerListDoctorProps {
  data?: GeneralPractitionerListDoctor;
  isSubstitute?: boolean;
  code?: string;
}

export const getGPDoctorLabel = (
  doctor?: GeneralPractitionerListDoctor,
  code?: string
) =>
  doctor?.id ? `${code ?? doctor.occupationCode} - ${getFullName(doctor)}` : "";

export const GeneralPractitionerListDoctor: FC<
  GeneralPractitionerListDoctorProps
> = ({ data, isSubstitute, code }) => (
  <>
    {getGPDoctorLabel(data, code)}
    {isSubstitute && (
      <FormattedMessage
        id="GPList.substituteLabel"
        defaultMessage="{code} Perearstita nimistu"
        values={{ code }}
      />
    )}
  </>
);

interface GeneralPractitionerListDoctorFieldProps
  extends AsyncProps<ApplicantOption> {
  id?: string;
  value: ApplicantOption;
  onChange: () => void;
  handleDelete: () => void;
  modalId?: ModalKeys;
}

export const GeneralPractitionerListDoctorField: FC<GeneralPractitionerListDoctorFieldProps> =
  forwardRef(
    (
      { id, value, onChange, loadOptions, handleDelete, modalId },
      ref: Ref<HTMLDivElement>
    ) => {
      const isPortal = useSelector((state: RootState) => state.config.isPortal);

      return (
        <div ref={ ref }>
          { !value.id ? (
            <FormGroup row={ true } className="mb-0">
              <Label htmlFor={ id } sm={ 4 } className="text-right">
                <FormattedMessage
                  id="GPListSubstituteDoctorModal.medreCode"
                  defaultMessage="THT kood"
                />
              </Label>
              <Col sm={ 8 } className="flex-grow-1">
                <AsyncSelect
                  inputId={ id }
                  value={ value }
                  placeholder={
                    <FormattedMessage
                      id="asyncCustomSelect.search"
                      defaultMessage="Otsi"
                    />
                  }
                  onChange={ onChange }
                  loadOptions={ loadOptions }
                  cacheOptions={ true }
                />
              </Col>
            </FormGroup>
          ) : (
            <div className="d-flex align-items-baseline">
              { (isPortal || (!isPortal && modalId !== ModalKeys.editSubstitute)) &&
                  <RemoveButton handleDelete={ handleDelete } hideText={ true }/> }
              <div>
                <strong>
                  { value.firstName } { value.lastName }
                </strong>{ " " }
                { value.idCode }
                <br/>
                { value.occupationName } ({ value.occupationCode }),&nbsp;
                { value.specialities.map(({ name }) => name).join(", ") }
              </div>
            </div>
          ) }
        </div>
      )
    }
  );
