import React, { FC, useState, useCallback, useMemo } from "react";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import {
  GeneralPractitionerListStatusEnum,
  GeneralPractitionerList
} from "../../../../../api_client/medre_api";
import { dateToString } from "../../../../Util/DateUtils";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";

interface Props {
  open: boolean;
  onClose: () => void;
}

const GPListLiquidationModal: FC<Props> = ({ onClose, open }) => {
  const [liquidationDate, setLiquidationDate] = useState<Date>();
  const { loading, updateGPList, list } = useGeneralPractitionerList();

  const isLiquidateGPListDisabled = useMemo(
    () =>
      !liquidationDate ||
      loading ||
      list?.status === GeneralPractitionerListStatusEnum.Proceeding,
    [liquidationDate, loading, list]
  );

  const handleSubmit = useCallback(async () => {
    await updateGPList(
      {
        liquidationDate: dateToString(liquidationDate)
      } as GeneralPractitionerList,
      onClose
    );
  }, [updateGPList, liquidationDate, onClose]);

  return (
    <Modal isOpen={open} className="gp-lists-base-modal">
      <ModalHeader>
        <FormattedMessage
          id="GPListLiquidationModal.header"
          defaultMessage="Kas olete kindel, et soovite nimistu likvideerida?"
        />
      </ModalHeader>

      <ModalBody>
        <FormGroup row={true} className={classNames("align-items-center")}>
          <Label
            htmlFor="gpListLiquidationModalLiquidationDate"
            sm={4}
            className="text-right"
          >
            <FormattedMessage
              id="GPListLiquidationModal.bodyLabel"
              defaultMessage="Eemaldamise kuupäev"
            />
          </Label>

          <Col sm={8} className="flex-grow-1">
            <DatePickerComponent
              id="gpListLiquidationModalLiquidationDate"
              name="liquidationDate"
              onDateChange={setLiquidationDate}
              selectedDate={liquidationDate}
              placeholder="pp.kk.aaaa"
              disablePast={true}
            />
          </Col>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={onClose}
          disabled={loading}
        />

        <PrimaryFormattedButton
          id="liquidateGPList"
          onClick={handleSubmit}
          disabled={isLiquidateGPListDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

export default GPListLiquidationModal;
