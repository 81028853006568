import React, { FC, useEffect, useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { TableFooter } from "./TableFooter";
import { ReactTable } from "../../../../Component/Table/ReactTable";
import { usePortal } from "../../../../Hook/usePortal";
import { useDispatch } from "react-redux";
import { searchResultsActions } from "../searchResultsActions";
import { ActivityLicense } from "../../../../../api_client/medre_api";
import { useMobile } from "../../../../Hook/useMobile";
import {
  activityLicenseOfficialColumns,
  activityLicensePortalColumns,
  activityLicensePortalMobileColumns
} from "./ResultsTableColumns/ActivityLicenseResultsColumns";

interface Props {
  data: ActivityLicense[];
  pageCount: number;
  pageIndex: number;
}

export const ActivityLicenseResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex
}) => {
  const isPortal = usePortal();
  const isMobile = useMobile();

  const columns = useMemo(() => {
    return isPortal
      ? isMobile
        ? activityLicensePortalMobileColumns
        : activityLicensePortalColumns
      : activityLicenseOfficialColumns;
  }, [isPortal, isMobile]);

  const table = useTable<ActivityLicense>(
    {
      columns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortBy: isPortal
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: { sortBy }
  } = table;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<ActivityLicense>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) =>
          column.getHeaderProps(column.getSortByToggleProps())
        }
        className="border-0"
        square
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
