import * as React from "react";
import { Card } from "reactstrap";

import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { ServiceDetailsAccordion } from "../../../Shared/ActivityLicense/Services/ServiceDetailsAccordion";
import { BusinessRegistryInfoCard } from "../BusinessRegistryInfoCard";
import { getDocumentsContent } from "../../../Shared/ActivityLicense/Documents/DocumentsUtil";
import {
  ActivityLocation,
  Company,
  ActivityLicenseRestorationTypeEnum,
  FileReference
} from "../../../../../api_client/medre_api";

export interface Props {
  company?: Company;
  businessAreaName?: string;
  locations?: ActivityLocation[];
  isHospital?: boolean;
  fileReferences?: FileReference[];
  licenseNumber?: string;
  proceedingType?: ActivityLicenseRestorationTypeEnum;
}

export const DetailsCard = ({
  company,
  businessAreaName,
  locations,
  isHospital,
  fileReferences,
  licenseNumber,
  proceedingType
}: Props) => {
  const { id, name, address, registryCode, email, phone } = company || {};

  const createTitle = () => {
    let prefix;
    switch (proceedingType) {
      case ActivityLicenseRestorationTypeEnum.ActivityLicense:
      default:
        prefix = "Tegevusloa";
        break;
      case ActivityLicenseRestorationTypeEnum.ActivityLocation:
        prefix = "Tegevuskoha";
        break;
      case ActivityLicenseRestorationTypeEnum.Service:
        prefix = "Teenuse";
        break;
    }
    return `${prefix} taastamise taotluse detailid`;
  };

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details">{createTitle()}</span>
      </h2>
      <BusinessRegistryInfoCard
        id={id}
        name={name}
        address={address}
        registryCode={registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusloa number"
        content={[{ key: "Tegevusloa number", value: licenseNumber || "-" }]}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: businessAreaName }]}
      />
      <ApplicationDetailsInfoCard
        title="Tegevuskoht"
        content={
          locations?.map((location) => {
            return { key: "Tegevuskoht", value: location.fullAddress };
          }) || []
        }
      />

      <h3 className="mt-4">Teenused</h3>
      <div className="service-details-table-container">
        {locations?.map((location) => {
          return (
            <ServiceDetailsAccordion
              key={location.id}
              isHospital={Boolean(isHospital)}
              services={location.services}
              isMobileActivityLocation={
                locations && locations[0].adsAdrID === "mobile"
              }
            />
          );
        })}
      </div>
      <ApplicationDetailsInfoCard
        title="Dokumendid"
        content={getDocumentsContent(fileReferences || [])}
      />
    </Card>
  );
};
