import React from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationState } from "../../../../Application/ApplicationState";
import { Application } from "../../../../../api_client/medre_api";
import { getCurrentStatus } from "../../../../Dto/Application/Application";

interface Props {
  application: Application;
  isDraft: boolean;
}

export const ApplicationDetailsHeader = ({
  application: details,
  isDraft
}: Props) => {
  return (
    <div className="d-flex align-items-center mx-3 my-4">
      <h1 className="mb-0 mr-3">
        <FormattedMessage
          id="ApplicationDetails.header"
          defaultMessage="Taotluse {id} detailvaade"
          values={{ id: details.applicationNumber }}
        />
      </h1>
      <ApplicationState
        application={
          !isDraft ? details : { currentStatus: getCurrentStatus(details) }
        }
        isDetailed={true}
      />
    </div>
  );
};
