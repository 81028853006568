import React, { FC, useCallback, useContext, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  FormGroup
} from "reactstrap";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { Competence } from "../../../../../api_client/medre_api";
import { FormattedButtonProps } from "../../../../Component/Button/FormattedButton";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { ApplicationsContext } from "../../../Shared/Application/ApplicationsContext";
import { getPersonAdditionalSpecialities } from "../../../../Util/PersonUtils";

interface Props {
  onClose: () => void;
  competence?: Competence;
  onCreate: (body: Competence, callback?: () => void) => void;
  onUpdate: (body: Competence, callback?: () => void) => void;
  personCompetenceSpecialities: Option[];
}

interface CompetenceDto extends Competence {
  speciality: Option;
}

const momentUTC = (date: Date | string | undefined = undefined) =>
  moment.utc(date).startOf("day");

export const ApplicantCompetenceModal: FC<Props> = ({
  onClose,
  competence,
  onCreate,
  onUpdate,
  personCompetenceSpecialities
}) => {
  const { submittedApplications } =
    useContext(ApplicationsContext).applications;

  const additionalSpecialities = getPersonAdditionalSpecialities(
    submittedApplications
  );

  personCompetenceSpecialities = !!additionalSpecialities.length
    ? personCompetenceSpecialities.concat(additionalSpecialities)
    : personCompetenceSpecialities;

  const { control, watch, register, formState, handleSubmit } =
    useForm<Competence>({
      mode: "onChange",
      defaultValues: { ...competence },
      shouldUnregister: false
    });

  const isEditMode = useMemo(() => Boolean(competence?.id), [competence?.id]);
  const { isValid, isSubmitting } = formState;

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(momentUTC(date).toISOString());
    },
    []
  );

  const watchFields = watch(["validationDate", "validUntil"]);
  const isValidDates =
    momentUTC(watchFields.validUntil) > momentUTC(watchFields.validationDate);

  const handleCreate = useCallback(
    (data: Competence) => {
      const { speciality } = { ...(data as CompetenceDto) };
      delete (data as Partial<CompetenceDto>).speciality;
      onCreate(
        {
          ...data,
          specialtyName: speciality.label,
          specialtyCode: speciality.value
        } as Competence,
        onClose
      );
    },
    [onCreate, onClose]
  );

  const handleUpdate = useCallback(
    (data: Competence) => {
      const { speciality } = { ...(data as CompetenceDto) };
      delete (data as Partial<CompetenceDto>).speciality;
      onUpdate(
        {
          ...data,
          specialtyName: speciality.label,
          specialtyCode: speciality.value
        } as Competence,
        onClose
      );
    },
    [onUpdate, onClose]
  );

  const submitButtonProps: FormattedButtonProps = {
    disabled: !isValidDates || !isValid || isSubmitting,
    id: "confirm",
    onClick: handleSubmit(handleCreate),
    ...(isEditMode && {
      id: "change",
      onClick: handleSubmit(handleUpdate)
    })
  };

  const handleChangeSpeciality = useCallback(
    (field) => (value: Option) => {
      field.onChange(value);
    },
    []
  );

  const getSpecialityDefaultValue = useCallback(
    () =>
      competence?.specialtyName && competence?.specialtyCode
        ? {
            label: competence?.specialtyName,
            value: competence?.specialtyCode
          }
        : null,
    [competence]
  );

  return (
    <Modal isOpen={true} size="lg">
      <ModalHeader>
        {isEditMode ? (
          <FormattedMessage
            id="competenceSection.changeCompetence"
            defaultMessage="Muuda pädevust"
          />
        ) : (
          <FormattedMessage
            id="competenceSection.newCompetence"
            defaultMessage="Uus pädevus"
          />
        )}
      </ModalHeader>
      <ModalBody className="certificate-request-form">
        <FormGroup row={true} className="required">
          <Label sm={4} className="text-left text-sm-right">
            <FormattedMessage
              id="competenceSection.nameAssociation"
              defaultMessage="Seltsi nimi"
            />
          </Label>
          <Col sm={7} md={5} className="d-flex align-items-center pb-2">
            <Input
              name="nameAssociation"
              autoComplete="off"
              innerRef={register({ required: true })}
            />
          </Col>
        </FormGroup>
        <FormGroup row={true} className="required">
          <Label
            htmlFor="occupationsFormSpeciality"
            className="text-left text-sm-right"
            sm={4}
          >
            <FormattedMessage
              id="competenceSection.specialtyName"
              defaultMessage="Eriala"
            />
          </Label>
          <Col sm={7} md={5} className="d-flex align-items-center pb-2">
            <Controller
              name="speciality"
              control={control}
              rules={{ required: true }}
              defaultValue={getSpecialityDefaultValue()}
              render={(field: ControllerRenderProps<Record<string, any>>) => (
                <SingleSelect
                  inputId="specialityName"
                  className="w-100"
                  options={personCompetenceSpecialities}
                  disabled={!personCompetenceSpecialities.length || isEditMode}
                  isMulti={false}
                  hideSearchIcon={true}
                  handleOptionChange={handleChangeSpeciality(field)}
                  value={field.value}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row={true} className="required">
          <Label sm={4} className="text-left text-sm-right">
            <FormattedMessage
              id="competenceSection.validationDate"
              defaultMessage="Kinnitamise kuupäev"
            />
          </Label>
          <Col sm={7} md={5} className="d-flex align-items-center pb-2">
            <Controller
              control={control}
              name="validationDate"
              rules={{ required: true }}
              defaultValue=""
              render={(field: ControllerRenderProps<Record<string, any>>) => (
                <DatePickerComponent
                  onDateChange={handleChangeDate(field)}
                  selectedDate={field.value}
                  disableFuture={true}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row={true} className="required">
          <Label sm={4} className="text-left text-sm-right">
            <FormattedMessage
              id="competenceSection.validUntil"
              defaultMessage="Kehtib kuni"
            />
          </Label>
          <Col sm={7} md={5} className="d-flex align-items-center pb-2">
            <Controller
              control={control}
              name="validUntil"
              rules={{ required: true }}
              defaultValue=""
              render={(field: ControllerRenderProps<Record<string, any>>) => (
                <DatePickerComponent
                  onDateChange={handleChangeDate(field)}
                  selectedDate={field.value}
                  minDate={momentUTC(watchFields.validationDate)
                    .add(1, "day")
                    .toDate()}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row={true}>
          <Label sm={4} className="text-left text-sm-right">
            <FormattedMessage
              id="competenceSection.notes"
              defaultMessage="Märkmed"
            />
          </Label>
          <Col sm={7} md={5} className="d-flex align-items-center pb-2">
            <Input
              type="textarea"
              name="notes"
              rows={6}
              autoComplete="off"
              innerRef={register}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="back"
          onClick={onClose}
          disabled={isSubmitting}
        />
        <PrimaryFormattedButton {...submitButtonProps} />
      </ModalFooter>
    </Modal>
  );
};
