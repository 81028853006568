import React, {ReactNode, useEffect, useState} from "react";
import "./CustomRadioButton.scss";

export interface Props {
    index: number;
    content: ReactNode;
    checked: boolean;
    groupName: string;
    value?: any;
    onChange: (event) => void;
}

export const CustomRadioButton = ({content, checked, groupName, onChange, index, value}: Props) => {
    const handleChange = (event) => {
        onChange(event); // Pass the event to the parent component
    };

    return (
        <div className="custom-radio-button">
            <input
                className="radio-button-input"
                type="radio"
                name={groupName} // Group radio buttons by the same name
                checked={checked}
                value={value}
                onChange={(event) => handleChange(event)}
                id={`radio-${index}`}
            />
            <label htmlFor={`radio-${index}`} className="custom-content">
                {content}
            </label>
        </div>
    )
};