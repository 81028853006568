import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
  applicationNumber?: number;
  modalMessage: string;
}

export const ApplicationDeclinationModal = ({
  isOpen,
  onClose,
  onDecline,
  applicationNumber,
  modalMessage
}: Props) => {
  const decline = () => {
    onDecline();
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="activityLicenseApplicationDeclinationModal.header"
          defaultMessage="Oled Sa kindel, et soovid taotlusest {id} keelduda?"
          values={{ id: applicationNumber }}
        />
      </ModalHeader>
      <ModalBody>{modalMessage}</ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="back" onClick={onClose} />
        <PrimaryFormattedButton
          id="declineApplicationConfirm"
          onClick={decline}
        />
      </ModalFooter>
    </Modal>
  );
};
