import React from "react";

import { handleFileDownload } from "../../../Util/FileUtils";
import { FileReference } from "../../../../api_client/medre_api";

interface Props {
  fileReferences: FileReference[];
  name?: string;
}

export const AttachmentCardTitle = ({ fileReferences, name }: Props) => {
  const createAttachmentLink = ({ fileName, minioFileName }: FileReference) => (
    <span
      className="pointer attachment-download-link"
      onClick={() => handleFileDownload(fileName, minioFileName, undefined)}
    >
      {fileName}
    </span>
  );

  const attachmentLinks = fileReferences
    .map((fileRef) => createAttachmentLink(fileRef))
    .reduce(
      (previous, next, currentIndex) => (
        <>
          {previous}
          {currentIndex === 0 ? <span /> : <span>; </span>}
          {next}
        </>
      ),
      <span />
    );

  if (fileReferences.length > 0 && name) {
    return (
      <>
        {name + " ("}
        {attachmentLinks}
        {")"}
      </>
    );
  }

  if (name) {
    return <>{name}</>;
  }

  return <>{attachmentLinks}</>;
};
