import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "chooseOccupation"
  | "applicationOccupationOrSpeciality"
  | "state"
  | "applicationType"
  | "applicationNumber"
  | "certificate"
  | "submissionDate"
  | "occupation"
  | "occupationName"
  | "additionalOccupationName"
  | "savedAt"
  | "speciality"
  | "submitApplication"
  | "applicantName"
  | "applicantIdCode";

export const ApplicationFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    chooseOccupation: {
      id: "applicationFM.chooseOccupation",
      defaultMessage: "Vali Kutse"
    },
    applicationOccupationOrSpeciality: {
      id: "applicationFM.applicationOccupationOrSpeciality",
      defaultMessage: "Taotletav kutse, eriala või tõend"
    },
    state: {
      id: "applicationFM.state",
      defaultMessage: "Taotluse olek"
    },
    applicationType: {
      id: "applicationFM.applicationType",
      defaultMessage: "Taotluse liik"
    },
    applicationNumber: {
      id: "applicationFM.applicationNumber",
      defaultMessage: "Taotluse number"
    },
    certificate: {
      id: "applicationFM.certificate",
      defaultMessage: "Kutsekvalifikatsiooni tunnustamine"
    },
    submissionDate: {
      id: "applicationFM.submissionDate",
      defaultMessage: "Esitamise kuupäev"
    },
    occupation: {
      id: "applicationFM.occupation",
      defaultMessage: "Kutse"
    },
    occupationName: {
      id: "applicationFM.occupationName",
      defaultMessage: "Kutse nimetus"
    },
    additionalOccupationName: {
      id: "applicationFM.additionalOccupationName",
      defaultMessage: "Lisakutse nimetus"
    },
    savedAt: {
      id: "applicationFM.savedAt",
      defaultMessage: "Salvestamise kuupäev"
    },
    speciality: {
      id: "applicationFM.speciality",
      defaultMessage: "Eriala"
    },
    submitApplication: {
      id: "applicationFM.submitApplication",
      defaultMessage: "Esita taotlus"
    },
    applicantName: {
      id: "applicationFM.applicantName",
      defaultMessage: "Taotluse esitaja nimi"
    },
    applicantIdCode: {
      id: "applicationFM.applicantIdCode",
      defaultMessage: "Taotluse esitaja Isikukood"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
