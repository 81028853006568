import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ServiceContentContainer } from "./ServiceContentContainer";
import { RemoveButton } from "../../../../Component/Button/RemoveButton";
import { RootState } from "../../../../rootReducer";
import {
  createServiceName,
  isAmbulanceService,
  isGpService,
  serviceNeedsBeds,
} from "../../../../Dto/ActivityLicense/Service";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import {
  ActivityLicenseBusinessAreaBusinessAreaTypeEnum,
  ActivityLicenseService,
  Service,
} from "../../../../../api_client/medre_api";
import { ActivityLicenseServiceDto } from "../../../../Dto/ActivityLicense/ActivityLicense";
import { ServiceDeletionConfirmationModal } from "./ServiceDeletionConfirmationModal";

interface Props {
  locationInfoIndex: number;
  service: ActivityLicenseService;
  serviceIds: string[];
  addService: (service: Service) => void;
  removeService: (serviceId?: string) => void;
  baOptionalServices: Service[];
  baRequiredServices: Service[];
}

export const ServiceContainer = ({
  locationInfoIndex,
  service,
  serviceIds,
  addService,
  removeService,
  baOptionalServices,
  baRequiredServices,
}: Props) => {
  const isHospitalBusinessArea = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication.businessArea?.isHospital
  );

  const isSpecialistBusinessArea: boolean = useSelector((state: RootState) => {
    return (
      state.activityLicenseApplication.businessArea?.businessAreaType ===
      ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist
    );
  });

  const hasOnlyOneRequiredService = (): boolean  => {
    return (baOptionalServices.length + baRequiredServices?.length) === 1;
  };

  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const isMobileActivityLocation = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication.locations![0].adsAdrID === "mobile"
  );

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  useEffect(() => {
    const shouldShowRemoveButton = !hasOnlyOneRequiredService() && !(service as ActivityLicenseServiceDto)?.requiredByBusinessArea;

    setShowRemoveButton(shouldShowRemoveButton);
  }, [hasOnlyOneRequiredService, baOptionalServices, baRequiredServices, service, serviceIds]);

  const renderRequiredServices = () => {
    let requiredServices = service?.service?.requiredServices?.filter(
      (rs) => !serviceIds.includes(rs.id as string)
    );
    if (requiredServices && requiredServices.length) {
      return (
        <div className="text">
          <FormattedMessage
            id="activityLicense.addMandatoryServicesText"
            defaultMessage="Teenusele on vaja lisaks ka üks allolevatest teenustest:"
          />
          {requiredServices.map((requiredService) => {
            return (
              <div key={requiredService.code} className="d-block">
                <b>{requiredService.name}</b>
                <LinkButton
                  id="add"
                  onClick={() => addService(requiredService)}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderServiceName = () => (
    <h4 className="fw-normal">{createServiceName(service.service!, true)}</h4>
  );

  const handleDelete = () => {
    removeService(service.service?.id);
    setIsDeletionModalOpen(false);
  };

  return (
    <div className="service-container">
      <div className="service-title">
        {renderServiceName()}
        { showRemoveButton && (
          <div className="pt-4">
            <RemoveButton handleDelete={() => setIsDeletionModalOpen(true)} />
          </div>
        )}
      </div>
      {renderRequiredServices()}
      {isMobileActivityLocation ? (isSpecialistBusinessArea ? undefined :
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="mobileActivityLocation"
          service={service}
        />
      ) : (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="rooms"
          service={service}
        />
      )}
      {serviceNeedsBeds(isHospitalBusinessArea, service.service?.type) && (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="beds"
          service={service}
        />
      )}
      {isAmbulanceService(service.service?.code) && (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="ambulanceBrigades"
          service={service}
        />
      )}
      {isSpecialistBusinessArea ? (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="specialists"
          service={service}
        />
      ) : (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="employees"
          service={service}
        />
      )}
      {isGpService(service.service?.code) && (
        <ServiceContentContainer
          locationInfoIndex={locationInfoIndex}
          serviceContentType="gpLists"
          service={service}
        />
      )}
      <ServiceDeletionConfirmationModal
        isOpen={isDeletionModalOpen}
        service={service.service!}
        onClose={() => setIsDeletionModalOpen(false)}
        onDelete={handleDelete}
      />
    </div>
  );
};
