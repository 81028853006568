import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Card, Col, FormGroup, Input, Label} from "reactstrap";
import {PrimaryFormattedButton} from "../../../Component/Button/PrimaryFormattedButton";
import {useDispatch} from "react-redux";
import {AdminTeenusApiFactory as adminTeenusApi} from "../../../../api_client/medre_api";
import {API, getBaseUrl} from "../../../api";
import {displayAlert} from "../../../Util/AlertUtil";
import {AlertType} from "../../../Dto/Alert/AlertItem";
import {Loader} from "../../../Component/Loader/Loader";

export const TortaExcludedCompanies = () => {
    const dispatch = useDispatch();
    const [registryCodes, setRegistryCodes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleRegistryCodesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegistryCodes(e.target.value.split(','));
    }

    useEffect(() => runQuery(), registryCodes);

    const runQuery = () => {
        setIsLoading(true);
        adminTeenusApi(undefined, getBaseUrl(), API)
            .fetchTortaExcludedCompanies({withCredentials: true})
            .then((res) => {
                setIsLoading(false);
                setRegistryCodes(res.data.registryCodes!);
            })
            .catch(() => {
                setIsLoading(false);
                displayAlert("requestFailed", AlertType.Danger, dispatch)
            });
    };

    const handleSaveButton = () => {
        setIsLoading(true);

        adminTeenusApi(undefined, getBaseUrl(), API).tortaExcludeCompanies({
            registryCodes: registryCodes
        }, {withCredentials: true})
            .then((res) => {
                setIsLoading(false);
                () => displayAlert("requestSuccess", AlertType.Success, dispatch)
            })
            .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
    }

    return (
    <>
        <Card className="system-administration-card mt-4">
            <div>
                <h3 className="system-administration-card-header fw-normal">
                    <FormattedMessage
                        id="systemAdministration.tab.torta.tortaCheck.excludedCompanies"
                        defaultMessage="TORTA kontrollist välistatavad ettevõtted"
                    />
                </h3>
            </div>
            {isLoading ? <Loader/> :
                <>
                    <FormGroup row={true}>
                        <Label sm={2} className="text-left text-sm-right">
                            <FormattedMessage
                                id="systemAdministration.tab.torta.tortaCheck.registryCodes"
                                defaultMessage="Registrikoodid"
                            />
                        </Label>
                        <Col sm={7} md={5} lg={5} className="d-flex align-items-center pb-2">
                            <Input
                                className="pb-2"
                                placeholder="Registrikoodid (komaga eraldatud list)"
                                type="textarea"
                                name="notes"
                                rows={6}
                                autoComplete="off"
                                value={registryCodes}
                                onChange={(e) => handleRegistryCodesChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <div className="form-inline col-7 d-flex justify-content-end">
                        <PrimaryFormattedButton id="saveIt" type="submit" onClick={handleSaveButton}/>
                    </div>
                </>
            }
        </Card>
    </>
    );
}