import { createContext, useCallback, useEffect, useState } from "react";
import { AxiosPromise } from "axios";
import { useHistory } from "react-router-dom";

import {
  OccupationCode,
  CertificateMetadata,
  Speciality
} from "../../../../api_client/medre_api";
import { HEALTHCARE_PROFESSIONALS_PREFIXES, P_PHARMACIST_PREFIX } from "../../../Constants";

interface ApplicationCertificateContextState {
  isLoading: boolean;
  allowedOccupationCodes: OccupationCode[];
  allowedHealthcareOccupationCodes: OccupationCode[];
  allowedSpecialities: Speciality[];
}

const defaultContextValue = {
  isLoading: true,
  allowedOccupationCodes: [],
  allowedHealthcareOccupationCodes: [],
  allowedSpecialities: []
};

type AppEndpointWithId = (
  personId: string,
  options?: any
) => AxiosPromise<CertificateMetadata>;
type AppEndpoint = (options?: any) => AxiosPromise<CertificateMetadata>;

export const ApplicationCertificateContext =
  createContext<ApplicationCertificateContextState>(defaultContextValue);

const filterHealthcareAndPharmacistsProfessionals = (allOccupationCodes: OccupationCode[]): OccupationCode[]  => {
  return allOccupationCodes.filter(
    allowedOccupation => HEALTHCARE_PROFESSIONALS_PREFIXES.includes(allowedOccupation.prefix) ||
      allowedOccupation.prefix === P_PHARMACIST_PREFIX
  );
};

export const useApplicationCertificateState = (
  endpoint?: AppEndpoint | AppEndpointWithId,
  id?: string
): ApplicationCertificateContextState => {
  const [state, setState] =
    useState<ApplicationCertificateContextState>(defaultContextValue);
  const history = useHistory();

  const fetch = useCallback(async () => {

    if (endpoint) {
      try {
        const { data } = id
          ? await endpoint(id, {
            withCredentials: true
          })
          : await (endpoint as AppEndpoint)({
            withCredentials: true
          });
        setState({
          isLoading: false,
          allowedOccupationCodes: data.allowedOccupationCodes!,
          allowedSpecialities: data.allowedSpecialities!,
          allowedHealthcareOccupationCodes: filterHealthcareAndPharmacistsProfessionals(data.allowedOccupationCodes!)
        });
      } catch (error) {
        if ((error as Record<string, any>)?.response?.status !== 400) {
          history.push("/");
        }
      }
    }
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch, id]);

  return state;
};

export const { Provider: ApplicationCertificateProvider } =
  ApplicationCertificateContext;
