import React from "react";
import { Card } from "reactstrap";

import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { renderOverViewRow } from "./ApplicationOverViewUtil";

interface Props {
  occupation?: string;
  additionalOccupation?: string;
}

export const OccupationOverViewCard = ({
  occupation,
  additionalOccupation
}: Props) => (
  <>
    <h5 className="fw-normal">
      <ApplicationFM id="occupation" />
    </h5>
    <Card className="info-card">
      {renderOverViewRow("application", "occupationName", occupation)}
      {additionalOccupation &&
        renderOverViewRow(
          "application",
          "additionalOccupationName",
          additionalOccupation
        )}
    </Card>
  </>
);
