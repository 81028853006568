import React, { ReactElement } from "react";

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  classNameOverride?: string;
  headline?: string | ReactElement;
}

export const Card = ({
  title,
  subtitle,
  classNameOverride,
  headline
}: Props) => {
  const className = classNameOverride
    ? classNameOverride
    : "card p-4 mr-1 my-3";
  return (
    <div className={className}>
      <hr className="mt-2" />
      {headline && <h4 className="fw-500">{headline}</h4>}
      {title && <h5>{title}</h5>}
      <span className="subtitle">{subtitle}</span>
    </div>
  );
};
