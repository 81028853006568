import { FormattedMessage } from "react-intl";
import React from "react";
import { EducationFM } from "../../../Messages/EducationFM";
import { ContactInfoFM } from "../../../Messages/ContactInfoFM";
import { ApplicationFM } from "../../../Messages/ApplicationFM";

export const occupationApplicationSteps = [
  <ApplicationFM key="occupationStep1" id="chooseOccupation"/>,
  <FormattedMessage key="occupationStep2" id="occupationSelection.userInfo" defaultMessage="Kontrolli isikuinfot"/>,
  <ContactInfoFM key="occupationStep3" id="contacts"/>,
  <EducationFM key="occupationStep4" id="educationInfo"/>,
  <FormattedMessage key="occupationStep5" id="occupationSelection.submitApplication" defaultMessage="Esita taotlus"/>
];

export const specialityApplicationSteps = [
  <FormattedMessage key="specialityStep1" id="specialitySelection.speciality" defaultMessage="Vali eriala"/>,
  <FormattedMessage key="specialityStep2" id="specialitySelection.userInfo" defaultMessage="Kontrolli isikuinfot"/>,
  <ContactInfoFM key="specialityStep3" id="contacts"/>,
  <EducationFM key="specialityStep4" id="educationInfo"/>,
  <FormattedMessage key="specialityStep5" id="specialitySelection.submitApplication" defaultMessage="Esita taotlus"/>
];

export const certificateRequestApplicationStep = [
  (
    <FormattedMessage
      key="ApplicationCertificateStep.occupation"
      id="ApplicationCertificateStep.occupation"
      defaultMessage="Vali kutse"
    />
  ),
  (
    <FormattedMessage
      key="ApplicationCertificateStep.contacts"
      id="ApplicationCertificateStep.contacts"
      defaultMessage="Kontaktandmed"
    />
  ),
  (
    <FormattedMessage
      key="ApplicationCertificateStep.apply"
      id="ApplicationCertificateStep.apply"
      defaultMessage="Esita taotlus"
    />
  ),
];

export enum PortalApplicationStep {
  SPECIALITY_OCCUPATION,
  PERSONAL_DATA,
  CONTACT_INFO,
  EDUCATION_INFO,
  OVERVIEW
}

export enum ApplicationCertificateStep {
  OCCUPATION,
  CONTACTS,
  OVERVIEW,
}
