import React, { ReactNode } from "react";
import { Card } from "reactstrap";

import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { renderOverViewRow } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";

interface Props {
  occupations?: string;
  speciality?: string;
  countries?: ReactNode[];
  education?: string;
}

export const ApplicationCertificateOverViewCard = ({
  occupations,
  speciality,
  countries,
  education
}: Props) => (
  <>
    <h5 className="fw-normal">
      <ApplicationFM id="occupation" />
    </h5>
    <Card className="info-card">
      {renderOverViewRow("application", "occupation", occupations)}
      {renderOverViewRow("application", "speciality", speciality)}
      {renderOverViewRow("contactInfo", "country", <>{countries}</>)}
      {renderOverViewRow("education", "education", education)}
    </Card>
  </>
);
