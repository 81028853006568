import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
    vacanciesSize?: number;
}

const GeneralPractitionerListVacanciesSize: FC<Props> = ({ vacanciesSize }) => {
    const vacanciesSizeText = useMemo(() =>
        Boolean(vacanciesSize) ? (
            <FormattedMessage
                id="button.yes"
                defaultMessage="Jah"
            />
        ) : (
            <FormattedMessage
                id="button.no"
                defaultMessage="Ei" 
            />
    ), [vacanciesSize]);

    if (vacanciesSize === undefined) {
        return null;
    }

    return (
        <span>{vacanciesSizeText}</span>
    );
};

export default GeneralPractitionerListVacanciesSize;