import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message = "businessArea";

export const BusinessAreaFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    businessArea: {
      id: "businessAreaFM.businessArea",
      defaultMessage: "Tegevusala"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
