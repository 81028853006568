import { AuditLogItemActorTypeEnum } from "../../../api_client/medre_api";

export const prettifyActorType = (type?: AuditLogItemActorTypeEnum) => {
  switch (type) {
    case AuditLogItemActorTypeEnum.Person:
      return "Taotleja";
    case AuditLogItemActorTypeEnum.Official:
      return "Ametnik";
    case AuditLogItemActorTypeEnum.System:
      return "Süsteem";
    default:
      return "Tundmatu kasutaja";
  }
};
