import { PeriodOptions, CountryGroups, RelativeTimes } from "./FilterTypes";
import { Option } from "../../../../Component/Select/SingleSelect";
import { InsuranceContractStatus } from "../../../../../api_client/medre_api";

// tslint:disable-next-line
export const timePeriods: PeriodOptions = Object.values(RelativeTimes).reduce((object: any, period) => {
  object[period] = {value: period, label: period};
  return object;
}, {});

export const countryGroups: Option[] = [
  {value: CountryGroups.Estonia, label: "Eesti"},
  {value: CountryGroups.Eca, label: "Euroopa"},
  {value: CountryGroups.Third, label: "Muu"}
];

export const applicationStatuses: Option[] = [
  {value: "SUBMITTED", label: "Esitatud"},
  {value: "DECLINED", label: "Keeldutud"},
  {value: "FINISHED", label: "Lõpetatud"},
  {value: "IN_PROCEEDING", label: "Menetluses"},
  {value: "PAUSED", label: "Menetlus peatatud"},
  {value: "ACCEPTED", label: "Rahuldatud"},
  {value: "REGISTERING", label: "Registreerimisel"},
  {value: "SAVED", label: "Salvestatud"},
  {value: "PAUSED_DUE_TO_APTITUDE_TEST", label: "Sobivustest"},
  {value: "PAUSED_DUE_TO_COMPLIANCE_EXAM", label: "Vastavuseksam"}
];

export const stateFeeStatuses: Option[] = [
  {value: "false", label: "Tasumata"},
  {value: "true", label: "Tasutud"}
];

export const personStatuses: Option[] = [
  {value: "REGISTERED", label: "Registris"},
  {value: "APPLIED", label: "Taotleja"},
  {value: "PAUSED", label: "Peatatud"},
  {value: "ARCHIVED", label: "Arhiivis"}
];

export const sexes: Option[] = [
  {value: "MALE", label: "Mees"},
  {value: "FEMALE", label: "Naine"}
];

export const singleSelectProps = {
  className: "input",
  isClearable: true,
  alwaysClearable: false,
  isSearchable: false,
  hideSearchIcon: true,
  alwaysShowPlaceholder: true
};

export const EuCodes = [
  "AUT",
  "BEL",
  "BGR",
  "ESP",
  "NLD",
  "HRV",
  "ITA",
  "IRL",
  "ISL",
  "GRC",
  "CYP",
  "LTU",
  "LIE",
  "LUX",
  "LVA",
  "MLT",
  "NOR",
  "POL",
  "PRT",
  "FRA",
  "SWE",
  "ROU",
  "DEU",
  "SVK",
  "SVN",
  "FIN",
  "GBR",
  "CHE",
  "DNK",
  "CZE",
  "HUN"
];

export const decisionStatuses: Option[] = [
  {value: "WAITING_FOR_SIGNATURE", label: "Allkirjastamise ootel"},
  {value: "DECLINED", label: "Keeldutud"},
  {value: "ACCEPTED", label: "Rahuldatud"},
  {value: "ACCEPTED_AFTER_APTITUDE_TEST", label: "Rahuldatud sobivustesti järel"},
  {value: "ACCEPTED_AFTER_COMPLIANCE_EXAM", label: "Rahuldatud vastavuseksami järel"},
  {value: "APTITUDE_TEST", label: "Sobivustest"},
  {value: "COMPLIANCE_EXAM", label: "Vastavuseksam"}
];


export const activityLicenseInsuranceContractStatuses = [
  { value: InsuranceContractStatus.Valid, label: "Kehtiv" },
  { value: InsuranceContractStatus.Expired, label: "Lõppenud" },
  { value: InsuranceContractStatus.Future, label: "Tulevikus" },
  { value: InsuranceContractStatus.None, label: "Puudub" }
];

export const publicActivityLicenseInsuranceContractStatuses = [
  { value: InsuranceContractStatus.Valid, label: "Kehtiv" },
  { value: InsuranceContractStatus.None, label: "Puudub" },
  { value: "ALL", label: "Kõik" }
];
