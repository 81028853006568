import React from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";

import "./Header.scss";
import { SideMenuItem } from "../SideMenuItem/SideMenuItem";
import burger_white from "../../../assets/images/burger_white.svg";
import burger_blue from "../../../assets/images/burger_blue.svg";
import close from "../../../assets/images/close.svg";
import { Logo } from "./Logo";
import { LanguageDropdown } from "./LanguageDropdown";
import { LogoutButton } from "../../Security/LogoutButton";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../rootReducer";
import { useWindowWidthSize } from "../../Hook/useWindowsSize";
import { RoleDropDown } from "./RoleDropDown";

interface Props {
  onClick: () => void;
  isSideMenuCollapsed: boolean;
  hasLanguageDropdown: boolean;
  headerColor: string;
}

export const Header = ({
  onClick,
  isSideMenuCollapsed,
  hasLanguageDropdown,
  headerColor
}: Props) => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const getBurgerMenuIcon =
    useWindowWidthSize() <= 1200 ? burger_blue : burger_white;

  const menuExpansionClassName = () =>
    isSideMenuCollapsed ? " collapsed" : " expanded";

  const rightSideSeparatorClassName = hasLanguageDropdown
    ? "header-right-side-separator order-2"
    : "d-none";

  const getTitle = () =>
    isSideMenuCollapsed ? (
      <div className="menu-text">
        <span className="desktop">
          <FormattedMessage
            id="header.menu.expand"
            defaultMessage="Laiendan menüü"
          />
        </span>
        <span className="mobile">
          <FormattedMessage id="header.menu.name" defaultMessage="Menüü" />
        </span>
      </div>
    ) : (
      <>
        <div className="menu-text">
          <FormattedMessage
            id="header.menu.collapse"
            defaultMessage="Kitsendan menüü"
          />
        </div>
        <span className="show-1100">
          <Logo isWhite={true} isSideMenuCollapsed={isSideMenuCollapsed} />
        </span>
      </>
    );

  return (
    <header className={"main-header " + headerColor}>
      <Row className="m-0 m-0 align-items-center h-100 row">
        <div
          id="toggle-global-menu"
          className={"p-0 menu-burger" + menuExpansionClassName()}
        >
          <div onClick={onClick}>
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={getTitle()}
              icon={isSideMenuCollapsed ? getBurgerMenuIcon : close}
            />
          </div>
        </div>
        <Logo isSideMenuCollapsed={isSideMenuCollapsed} />
        <div className="hide-1100 ml-auto mr-4 h-100">
          <div className="header-menu-right-side align-items-start flex-row-reverse">
            {hasLanguageDropdown && <LanguageDropdown />}
            {userInfo?.roles && (
              <>
                <div className="header-right-side-separator order-2" />
                <RoleDropDown />
              </>
            )}
            {userInfo && (
              <>
                <div className={rightSideSeparatorClassName} />
                <div>
                  <LogoutButton />
                </div>
              </>
            )}
          </div>
        </div>
      </Row>
    </header>
  );
};
