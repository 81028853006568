import React from "react";
import { useParams } from "react-router-dom";

import { AmetnikuTegevuskohtadeTaastamiseTeenusApiFactory as officialActivityAPI } from "../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../api";
import { RestorationDetails } from "./RestorationDetails";

export const ActivityLocationRestorationDetails = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <RestorationDetails
      endpoint={
        officialActivityAPI(undefined, getBaseUrl(), API)
          .getActivityLocationRestoration
      }
      id={id}
    />
  );
};
