import React from "react";
import { Card } from "reactstrap";

import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";

interface Props {
  personName?: string;
  personIdCode?: string;
}

export const SubmittingPersonCard = ({ personName, personIdCode }: Props) => (
  <>
    <Card className="info-card mb-2">
      {renderOverViewRow("submittingPerson", "name", personName)}
      {renderOverViewRow("submittingPerson", "idCode", personIdCode)}
    </Card>
  </>
);
