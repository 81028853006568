import React, { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { API, getBaseUrl } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import {
  ApplicantCertificatesModal,
  CertificateData,
  mapApplicationCertificateToCertificateValues
} from "../../Applicant/Certificates/ApplicantCertificatesModal";
import {
  AmetnikuRakendussertifikaadiAndmeteenusApiFactory as officialAppCertificateDataAPI,
  AmetnikuRakendussertifikaadiAndmeteenusApiFactory as officialApplicationDataAPI,
  ApplicationCertificate,
  OfficialUserPrivilegesEnum,
  Person,
  ProceedingStatusStatusEnum
} from "../../../../../api_client/medre_api";
import { ApplicationsContext } from "../../../Shared/Application/ApplicationsContext";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import { ActionButtonClasses } from "../../../../Component/Button/Button";
import { useHistory } from "react-router-dom";
import {
  ApplicationDeleteConfirmationModal
} from "../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { displayAlert } from "../../../../Util/AlertUtil";

interface Props {
  person: Person;
  application: ApplicationCertificate;
  setIsLoading: (isLoading: boolean) => void;
  refreshApplication: () => Promise<void>;
}

export const ApplicationCertificateDetailsActions = ({
  person,
  application,
  setIsLoading,
  refreshApplication
}: Props) => {
  const [certificateModalData, setCertificateModalData] = useState<
  CertificateData | undefined
  >(undefined);
  const handleCloseCertificateModal = useCallback(() => {
    setCertificateModalData(undefined);
  }, [setCertificateModalData]);
  const history = useHistory();

  const { fetchApplications } = useContext(ApplicationsContext);

  const [isSubmitting, setSubmitting] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteApplication = (): void => {
    setIsLoading(true);
    officialApplicationDataAPI(undefined, getBaseUrl(), API)
      .deleteCertificateApplication(application.id as string, {
        withCredentials: true
      })
      .then((res) => {
        displayAlert("applicationDraftDeleteSuccess", AlertType.Success, dispatch);
        history.push("/search");
      })
      .catch((error) => {
        displayAlert("applicationDraftDeleteFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChangeStatus = useCallback(
    (newStatus: ProceedingStatusStatusEnum) => async (): Promise<void> => {
      let changeStatusMethod;
      switch (newStatus) {
        case ProceedingStatusStatusEnum.InProceeding:
          changeStatusMethod = "proceed";
          break;
        case ProceedingStatusStatusEnum.Finished:
          changeStatusMethod = "finish";
          break;
        case ProceedingStatusStatusEnum.Accepted:
          setCertificateModalData(
            mapApplicationCertificateToCertificateValues(
              person,
              application
            ) as CertificateData
          );
          break;
        default:
          break;
      }

      if (changeStatusMethod) {
        try {
          setSubmitting(true);
          if (changeStatusMethod === "proceed") {
            await officialAppCertificateDataAPI(
              undefined,
              getBaseUrl(),
              API
            ).proceedApplicationCertificate(application.id!, {
              withCredentials: true
            });
          } else {
            await officialAppCertificateDataAPI(
              undefined,
              getBaseUrl(),
              API
            ).finishApplicationCertificate(application.id!, {
              withCredentials: true
            });
          }
          await Promise.all([refreshApplication(), fetchApplications()]);
          displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        } catch (e) {
          displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
        } finally {
          setSubmitting(false);
        }
      }
    },
    [application, person, refreshApplication, fetchApplications, dispatch]
  );

  const renderActionButtons = () => {
    switch (application.currentStatus?.status) {
      case ProceedingStatusStatusEnum.Submitted:
        return application.stateFee?.paid ? (
          <>
            <PrimaryFormattedButton
              id="initiateProceeding"
              onClick={handleChangeStatus(
                ProceedingStatusStatusEnum.InProceeding
              )}
              disabled={isSubmitting}
            />
                &nbsp;
            <PrimaryFormattedButton
              id="finishProceeding"
              onClick={handleChangeStatus(
                ProceedingStatusStatusEnum.Finished
              )}
              disabled={isSubmitting}
            />
          </>
        ) : (
          <SecondaryFormattedButton
            id="finishProceeding"
            className={ActionButtonClasses}
            onClick={handleChangeStatus(ProceedingStatusStatusEnum.Finished)}
          />
        );
      case ProceedingStatusStatusEnum.InProceeding:
        return (
          <>
            <PrimaryFormattedButton
              id="acceptApplication"
              onClick={handleChangeStatus(
                ProceedingStatusStatusEnum.Accepted
              )}
              disabled={isSubmitting}
            />
                &nbsp;
            <PrimaryFormattedButton
              id="finishProceeding"
              onClick={handleChangeStatus(
                ProceedingStatusStatusEnum.Finished
              )}
              disabled={isSubmitting}
            />
          </>
        );
      case ProceedingStatusStatusEnum.Saved:
        return (
          <DangerFormattedButton
            id="deleteApplication"
            className={ActionButtonClasses}
            onClick={(): void => setDeleteModalIsOpen(true)}
          />
        );
    }
  };
  
  return (
    <>
      <h2>
        <span id="actions"/> Tegevused
      </h2>
      <div className="mt-1 mb-4">
        <ProtectedFieldWrapper allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
        >
          {renderActionButtons()}
        </ProtectedFieldWrapper>
      </div>
      <ApplicationDeleteConfirmationModal
        applicationNumber={application.applicationNumber!}
        isOpen={deleteModalIsOpen}
        onClose={(): void => setDeleteModalIsOpen(false)}
        onDelete={deleteApplication}
      />
      { Boolean(certificateModalData) && (
        <ApplicantCertificatesModal
          isOpen
          onClose={handleCloseCertificateModal}
          defaultValues={certificateModalData}
          person={person}
          afterSubmit={refreshApplication}
          disableApplicationCertificateSelect
        />
      ) }
    </>
  );
};
