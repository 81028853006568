import React from "react";

import "./WizardProgressBar.scss";

type Props = {
  highestStep?: number;
  steps: JSX.Element[];
  locked?: boolean;
  formComplete?: boolean;
  disableStepNumber?: boolean;
};

export const WizardProgressBar = ({
  highestStep,
  steps,
  locked,
  formComplete,
  disableStepNumber
}: Props) => {
  const progressStatus = {
    ACTIVE: "active",
    COMPLETE: "complete",
    COMPLETE_DONE: "complete-done",
    DISABLED: "disabled"
  };

  const getListItemStatus = (
    stepIndex: number,
    totalSteps: number,
    locked?: boolean,
    formComplete?: boolean,
    highestStep?: number
  ): string => {
    if (formComplete || highestStep === undefined) {
      return locked ? progressStatus.COMPLETE_DONE : progressStatus.COMPLETE;
    }

    if (highestStep === totalSteps) {
      return locked ? progressStatus.COMPLETE_DONE : progressStatus.COMPLETE;
    } else if (stepIndex < highestStep) {
      return progressStatus.COMPLETE;
    } else if (stepIndex === highestStep) {
      return progressStatus.ACTIVE;
    }

    return progressStatus.DISABLED;
  };

  const renderSteps = () => (
    <>
      {steps.map((step, index) => {
        const currentStatus = getListItemStatus(
          index,
          steps.length - 1,
          locked,
          formComplete,
          highestStep
        );
        return (
          <li key={index + "-wpb"} className={`nav-item ${currentStatus}`}>
            <a className={`nav-link ${disableStepNumber ? 'no-number' : ''}`} {...{}}>
              {step}
            </a>
          </li>
        );
      })}
    </>
  );

  return (
    <div className={"wizard-progress-bar mb-4"}>
      <ol className={"nav nav-tabs form-nav form-steps"}>{renderSteps()}</ol>
    </div>
  );
};
