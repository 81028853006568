import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { RootState } from "../../../../rootReducer";
import { PersonalDataFM } from "../../../../Messages/PersonalDataFM";
import { FormattedMessage } from "react-intl";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import classNames from "classnames";

export const SubmittingPersonForm = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo || {});
  const [submittingPersonIdCode] = useState(userInfo.idCode || "");
  const [submittingPersonFirstName] = useState(userInfo.firstName || "");
  const [submittingPersonLastName] = useState(userInfo.lastName || "");

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  useEffect(() => {
    dispatch(activityLicenseApplicationActions.updateActivityLicense(
      "submittingPersonIdCode",
      submittingPersonIdCode));
    dispatch(activityLicenseApplicationActions.updateActivityLicense(
      "submittingPersonFirstName",
      submittingPersonFirstName));
    dispatch(activityLicenseApplicationActions.updateActivityLicense(
      "submittingPersonLastName", 
      submittingPersonLastName));
  }, [dispatch, submittingPersonIdCode, submittingPersonFirstName, submittingPersonLastName]);

  const className = classNames("w-100", "flex-nowrap", {
    "form-inline": !isMobile,
  });
  return (
    <>
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.submittingPerson"
          defaultMessage="Esitav isik"
        />
      </h4>
      <FormGroup className={className}>
        <Label htmlFor="submittingPersonFormPersonName">
          <PersonalDataFM id="name"/>
        </Label>
        <div className="input-wrapper">
          <Input
            id="submittingPersonFormPersonName"
            disabled={true}
            name="name"
            value={submittingPersonFirstName + " " + submittingPersonLastName}
          />
        </div>
      </FormGroup>
      <FormGroup className={className}>
        <Label htmlFor="submittingPersonFormPersonalCode">
          <PersonalDataFM id="personalCode"/>
        </Label>
        <div className="input-wrapper">
          <Input
            id="submittingPersonFormPersonalCode"
            disabled={true}
            name="personalCode"
            value={submittingPersonIdCode}
          />
        </div>
      </FormGroup>
    </>
  );
};