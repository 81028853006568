import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BusinessRegistryInfoCard } from "../BusinessData/BusinessRegistryInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { ContactInfoCard } from "./ContactInfoCard";
import { SubmittingPersonCard } from "./SubmittingPersonCard";
import { BusinessAreaCard } from "./BusinessAreaCard";
import { ServicesCard } from "./ServicesCard";
import { DocumentsCard } from "./DocumentsCard";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { ActivityLicenseCard } from "./ActivityLicenseCard";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplication
} from "../../../../../api_client/medre_api";
import { ActivityLocationsCard } from "./ActivityLocationsCard";

export const ActivityLicenseOverview = () => {
  const dispatch = useDispatch();
  const activityLicense = useSelector(
    (state: RootState) => state.activityLicenseApplication
  );
  const [isIntegrationConfirmed, setIsIntegrationConfirmed] = useState(false);
  const isActivityLocationApplication =
    activityLicense.proceedingType ===
    ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation;
  const companyInfo = isActivityLocationApplication
    ? activityLicense.activityLicense?.company
    : activityLicense.company;

  useEffect(() => {
    dispatch(
      activityLicenseApplicationActions.updateActivityLicense(
        "saveAllowed" as keyof ActivityLicenseApplication,
        isIntegrationConfirmed
      )
    );
  }, [isIntegrationConfirmed, dispatch]);

  return (
    <>
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.overview"
          defaultMessage="Esita taotlus"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicense.overviewInfo"
          defaultMessage={`Kontrolli taotluses edastav info üle ja seejärel klõpsa nuppu “Esita taotlus”.
           Nupu klõpsamise järel saate juhendi riigilõivu maksmiseks.`}
        />
      </p>
      <div className="main-column">
        <h4 className="application-title fw-normal">
          <FormattedMessage
            id="activityLicense.businessRegisterData"
            defaultMessage="Äriregistri andmed"
          />
        </h4>
        <BusinessRegistryInfoCard
          name={companyInfo?.name}
          address={companyInfo?.address}
          registryCode={companyInfo?.registryCode}
        />
        <ContactInfoCard
          email={companyInfo?.email}
          phone={companyInfo?.phone}
        />
        <h4 className="application-title fw-normal">
          <FormattedMessage
            id="activityLicense.submittingPerson"
            defaultMessage="Esitav isik"
          />
        </h4>
        <SubmittingPersonCard
          personName={
            activityLicense.submittingPersonLastName
              ? activityLicense.submittingPersonFirstName +
                " " +
                activityLicense.submittingPersonLastName
              : "-"
          }
          personIdCode={activityLicense.submittingPersonIdCode}
        />
        <ActivityLocationsCard activityLocations={activityLicense.locations} />
        <BusinessAreaCard businessArea={activityLicense.businessArea?.name} />
        {isActivityLocationApplication && (
          <ActivityLicenseCard
            licenseNumber={activityLicense.activityLicense?.number}
          />
        )}
        <ServicesCard 
          services={activityLicense?.locations?.[0].services}
        />
        <DocumentsCard documents={activityLicense.fileReferences || []} />
        <Checkbox
          id="integration-with-system-exists"
          isChecked={isIntegrationConfirmed}
          labelText="Kinnitan, et liidestus Tervise Infosüsteemiga on olemas"
          onChange={() => setIsIntegrationConfirmed(!isIntegrationConfirmed)}
        />
      </div>
    </>
  );
};
