import React, { useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../rootReducer";
import { FormattedMessage } from "react-intl";
import { API, getBaseUrl } from "../../api";
import { UserInfo } from "../../Dto/Security/UserInfo";
import { selectCompany, selectPersonRole } from "../Portal/Role/RoleHelpers";
import "./RoleDropDown.scss";
import { getSelectedCompanyId } from "../../Security/roleHelpers";
import { useHistory } from "react-router-dom";
import {
  FirmadeAndmeteTeenusApiFactory as companyAPI,
  BusinessInfo,
  OfficialUserPrivilegesEnum
} from "../../../api_client/medre_api";

interface Props {
  isSideMenu?: boolean;
}

export const RoleDropDown = ({ isSideMenu }: Props) => {
  const [isDropdownOpen, setIsRoleDropDownOpen] = useState(false);
  const toggle = () => setIsRoleDropDownOpen((prevState) => !prevState);

  const history = useHistory();
  const dispatch = useDispatch();
  const authConfig = useSelector(
    (state: RootState) => state.config.authConfig,
    shallowEqual
  );
  const { name, roles, selectedRoles } = useSelector(
    (state: RootState) => Object(state.user.userInfo) as UserInfo
  );
  const status = useSelector((state: RootState) => state.person.status);
  const [companies, setCompanies] = useState<BusinessInfo[]>();
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  useEffect(() => {
    if (isPortal) {
      companyAPI(undefined, getBaseUrl(), API)
        .getUserCompanies({
          withCredentials: true
        })
        .then((result) => setCompanies(result.data));
    }
  }, [isPortal, authConfig]);

  const [isPerson, isOfficial] = useMemo(
    () => [
      roles?.includes(OfficialUserPrivilegesEnum.Person) &&
        !!selectedRoles?.length,
      roles?.includes(OfficialUserPrivilegesEnum.Official)
    ],
    [roles]
  );

  const roleName = useMemo(() => {
    if (isPerson || isOfficial) {
      return name;
    }
    return companies?.find(
      (c) => c.registryCode === getSelectedCompanyId(roles)
    )?.name;
  }, [isPerson, isOfficial, companies, name, roles]);

  return (
    <>
      <div className="role-dropdown-container px-3">
        <span id="choose-role-text" className="d-block role-header">
          <FormattedMessage
            id="roleDropDown.header"
            defaultMessage="Minu roll:"
          />
        </span>
        <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
          <DropdownToggle
            id="choose-role-button"
            color="link"
            className="p-0 border-0 text-decoration-none"
            aria-expanded={isDropdownOpen}
            aria-labelledby="choose-role-text choose-role-button"
            aria-haspopup={true}
          >
            <span className="language-selection">
              {roleName}
              {companies && !!companies.length && (
                <FontAwesomeIcon icon={faChevronDown} className="option m-1" />
              )}
            </span>
          </DropdownToggle>
          {companies && !!companies.length && (
            <DropdownMenu
              right={true}
              aria-labelledby="choose-role-text"
              className="mw-270"
            >
              <DropdownItem header={true}>
                <FormattedMessage
                  id="roleDropDown.person"
                  defaultMessage="Eraisik"
                />
              </DropdownItem>
              <DropdownItem
                onClick={() => selectPersonRole(status, dispatch, !isPerson)}
                active={isPerson}
              >
                {name}
              </DropdownItem>
              <DropdownItem header={true}>
                <FormattedMessage
                  id="roleDropDown.companies"
                  defaultMessage="Ettevõtted"
                />
              </DropdownItem>
              {companies.map((company: BusinessInfo) => (
                <DropdownItem
                  key={`company-${company.registryCode}`}
                  onClick={() => selectCompany(company, dispatch, history)}
                  active={roleName === company.name}
                >
                  {company.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </Dropdown>
      </div>
      {!isPerson && !isOfficial && !isSideMenu && (
        <>
          <div className="header-right-side-separator order-1" />
          <div className="name-container px-3 order-1">
            <div className="name-header">
              <FormattedMessage id="roleDropDown.name" defaultMessage="Nimi:" />
            </div>
            <div className="name">{name}</div>
          </div>
        </>
      )}
    </>
  );
};
