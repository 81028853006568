import React from "react";
import { Card, Col, Row } from "reactstrap";

import { ActivityLicenseApplicationChannelTypeEnum } from "../../../../../api_client/medre_api";

interface Props {
  channelType?: ActivityLicenseApplicationChannelTypeEnum;
}

export const ArrivalChannelOverViewCard = ({ channelType }: Props) => {
  const convertChannelToString = (
    currentChannelType?: ActivityLicenseApplicationChannelTypeEnum
  ) => {
    switch (currentChannelType) {
      case ActivityLicenseApplicationChannelTypeEnum.EMail:
        return "E-mail";
      case ActivityLicenseApplicationChannelTypeEnum.Mail:
        return "Tavapost";
      case ActivityLicenseApplicationChannelTypeEnum.Delivered:
        return "Toodi kohapeale";
      case ActivityLicenseApplicationChannelTypeEnum.SelfService:
        return "Iseteenindus";
      default:
        return "-";
    }
  };

  return (
    <>
      <h5 className="fw-normal">Taotluse saabumise kanal</h5>
      <Card className="info-card">
        <Row>
          <Col xs={12} md={4} className="col">
            Kanal
          </Col>
          <Col>{convertChannelToString(channelType)}</Col>
        </Row>
      </Card>
    </>
  );
};
