import { combineReducers } from "redux";
import { addressReducer } from "./Address/addressReducer";
import { addressActions } from "./Address/addressActions";
import { configReducer } from "./Config/configReducer";
import { localeReducer } from "./Locale/localeReducer";
import { Locale } from "./Dto/Locale/Locale";
import { configActions } from "./Config/configActions";
import { ActionType } from "typesafe-actions";
import { AddressState } from "./Address/AddressState";
import { ConfigState } from "./Config/ConfigState";
import { localeActions } from "./Locale/localeActions";
import { LoginInfo } from "./Dto/Security/UserInfo";
import { userActions } from "./Security/userActions";
import { userReducer } from "./Security/userReducer";
import { personReducer } from "./Person/personReducer";
import { contactInfoFromRrReducer } from "./Person/contactInfoFromRrReducer";
import { contactInfoFromRegistryActions } from "./Person/contactInfoFromRegistryActions";
import { personActions } from "./Person/personActions";
import { applicationDraftReducer } from "./Application/applicationDraftReducer";
import { applicationDraftActions } from "./Application/applicationDraftActions";
import { ApplicationCertificateDraft } from "./Dto/ApplicationCertificate/ApplicationCertificate";
import { alertActions } from "./Alert/alertActions";
import { alertReducer, AlertState } from "./Alert/alertReducer";
import { applicationReducer } from "./Application/applicationReducer";
import { applicationActions } from "./Application/applicationActions";
import { searchResultsActions } from "./MainContainer/Shared/Search/searchResultsActions";
import { searchResultsReducer, SearchResultsState } from "./MainContainer/Shared/Search/searchResultsReducer";
import { filterOptionsReducer, FilterOptionsState } from "./MainContainer/Shared/Search/Filters/filterStore";
import { filterActions } from "./MainContainer/Shared/Search/Filters/filterActions";
import { activityLicenseApplicationActions } from "./ActivityLicense/activityLicenseApplicationActions";
import { activityLicenseApplicationReducer } from "./ActivityLicense/activityLicenseApplicationReducer";
import { generalPractitionerListActions } from "./GeneralPractitionerLists/generalPractitionerListActions";
import { GeneralPractitionerListState } from "./Dto/GeneralPractitionerList/GeneralPractitionerList";
import { generalPractitionerListReducer } from "./GeneralPractitionerLists/generalPractitionerListReducer";
import { licenseAcquisitionApplicationReducer } from "./ActivityLicense/licenseAcquisitionApplicationReducer";
import { licenseAcquisitionActions } from "./ActivityLicense/licenseAcquisitionActions";
import { activityLicenseTableActions } from "./ActivityLicense/activityLicenseTableActions";
import {
  ActivityLicenseApplication,
  Application,
  ApplicationDraft,
  CompanyApplication,
  ContactInfo,
  LicenseAcquisition,
  Person
} from "../api_client/medre_api";
import { activityLicenseTableReducer, ActivityLicenseTableState } from "./ActivityLicense/activityLicenseTableReducer";
import { featureFlagActions } from "./Security/featureFlagActions";
import { featureFlagReducer } from "./Security/featureFlagReducer";
import { FeatureFlagState } from "./Dto/FeatureFlag/FeatureFlag";

const actions = {
  user: userActions,
  address: addressActions,
  config: configActions,
  locale: localeActions,
  person: personActions,
  contactInfoFromRR: contactInfoFromRegistryActions,
  applicationDraft: applicationDraftActions,
  application: applicationActions,
  alert: alertActions,
  searchResults: searchResultsActions,
  filterOptions: filterActions,
  activityLicenseApplication: activityLicenseApplicationActions,
  generalPractitionerLists: generalPractitionerListActions,
  licenseAcquisitionApplication: licenseAcquisitionActions,
  activityLicenseTable: activityLicenseTableActions,
  featureFlagActions: featureFlagActions
};

export type RootAction = ActionType<typeof actions>;

export interface RootState {
  user: LoginInfo;
  address: AddressState;
  config: ConfigState;
  locale: Locale;
  person: Person;
  contactInfoFromRR: ContactInfo;
  applicationDraft: ApplicationDraft | ApplicationCertificateDraft;
  application: Application | CompanyApplication;
  alert: AlertState;
  searchResults: SearchResultsState;
  filterOptions: FilterOptionsState;
  activityLicenseApplication: ActivityLicenseApplication;
  generalPractitionerList: GeneralPractitionerListState;
  licenseAcquisitionApplication: LicenseAcquisition;
  activityLicenseTable: ActivityLicenseTableState;
  featureFlag: FeatureFlagState;
}

const appReducer = combineReducers<RootState, RootAction>({
  user: userReducer,
  address: addressReducer,
  config: configReducer,
  locale: localeReducer,
  person: personReducer,
  contactInfoFromRR: contactInfoFromRrReducer,
  applicationDraft: applicationDraftReducer,
  application: applicationReducer,
  alert: alertReducer,
  searchResults: searchResultsReducer,
  filterOptions: filterOptionsReducer,
  activityLicenseApplication: activityLicenseApplicationReducer,
  generalPractitionerList: generalPractitionerListReducer,
  licenseAcquisitionApplication: licenseAcquisitionApplicationReducer,
  activityLicenseTable: activityLicenseTableReducer,
  featureFlag: featureFlagReducer
});

export const rootReducer = (
  state: RootState | undefined,
  action: RootAction
) => {
  return appReducer(state, action);
};
