import React from "react";
import { FormGroup } from "reactstrap";

import { RadioButton } from "./RadioButton";

export interface RadioButtonElement {
  id: string;
  value: string;
  labelText: string | JSX.Element;
}

interface Props {
  options: RadioButtonElement[];
  name: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  inline?: boolean;
  disabled?: boolean;
}

export const RadioGroup = ({
  options,
  name,
  value,
  onChange,
  className,
  inline,
  disabled
}: Props) => (
  <FormGroup className={className}>
    {options.map((b, index) => (
      <RadioButton
        key={index}
        id={b.id}
        name={name}
        value={b.value}
        selected={Boolean(value && value === b.value)}
        onChange={onChange}
        labelText={b.labelText}
        inline={inline}
        disabled={disabled}
      />
    ))}
  </FormGroup>
);
