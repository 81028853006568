import { FormattedMessage } from "react-intl";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { API, getBaseUrl } from "../../../../../api";
import "./AddSpecialistModal.scss";
import { ErrorMessage } from "../../../../../Component/ErrorField/ErrorMessage";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { SingleSelect } from "../../../../../Component/Select/SingleSelect";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import {
  HealthCareProfessional,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  Occupation,
  OccupationCode,
  PersonalData,
  ServiceEmployee,
  SpecialistCode,
  TegevuslubadeTeenusApiFactory as activityPermitServiceAPI,
} from "../../../../../../api_client/medre_api";
import {
  D_DOCTOR_PREFIX,
  DENTIST,
  DOCTOR,
  MOBILE_MAX_WIDTH
} from "../../../../../Constants";
import { AxiosResponse } from "axios";

interface Props {
  isOpen: boolean;
  preselectedSpecialistOccupation?: Occupation | undefined;
  onClose: () => void;
  onSave: (specialist: PersonalData, specializationId: string, isTortaCheckRequired: boolean) => void;
  serviceEmployees: ServiceEmployee[];
}

export const AddSpecialistModal = ({ isOpen, preselectedSpecialistOccupation, onClose, onSave, serviceEmployees }: Props) => {
  const [specialistCodes, setSpecialistCodes] = useState<SpecialistCode[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [personalCode, setPersonalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<JSX.Element | undefined>();
  const [specialist, setSpecialist] = useState<PersonalData | undefined>();
  const [existingPersonSpecialistCodes, setExistingPersonSpecialistCodes] = useState<string[]>([]);
  const [isCodeMatched, setIsCodeMatched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [occupationExists, setOccupationExists] = useState(false);
  const [occupationName, setOccupationName] = useState<string | undefined>(undefined);
  const [occupationCode, setOccupationCode] = useState<string | undefined>(undefined);

  const warningConditions = {
    S100: (code: OccupationCode) => code.prefix === D_DOCTOR_PREFIX && code.name === DOCTOR,
    S110: (code: OccupationCode) => code.prefix === D_DOCTOR_PREFIX && code.name === DENTIST,
  };

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const options = specialistCodes.map((code) => ({
    value: code.id,
    label: code.name + " - " + code.code,
    code: code.code
  }));

  const preselectedSpecialistOccupationOption = () => ({
    value: preselectedSpecialistOccupation?.id,
    label: preselectedSpecialistOccupation?.name + " - " + preselectedSpecialistOccupation?.code
  });

  const specialistSpecialityId = preselectedSpecialistOccupation?.specialities?.at(0)?.id;

  const isTortaCheckRequired = useMemo(() => {
    if (!selectedOption || !specialistCodes) {
      return true;
    }
    const matchingSpecialistOccupation = specialistCodes.find((specialistCode) => specialistCode.code === selectedOption.code);
    return matchingSpecialistOccupation?.isTortaCheckRequired ?? true;
  }, [selectedOption, specialistCodes]);

  useEffect(() => {
    if (specialist) {
      setLoading(true);
      getExistingPersonSpecialistCodes().then(codes => {
        setExistingPersonSpecialistCodes(codes);
        setLoading(false);
        setIsCodeMatched(preselectedSpecialistOccupation ? codes.includes(preselectedSpecialistOccupation?.code): codes.includes(selectedOption?.code));
      });
    }
  }, [specialist, selectedOption]);

  useEffect(() => {
    activityPermitServiceAPI(undefined, getBaseUrl())
      .getActiveSpecialistCodes({
        withCredentials: true
      })
      .then((res) => setSpecialistCodes(res.data));
  }, []);

  useEffect(() => {
    if (isOpen) {
      setSelectedOption(undefined);
      setPersonalCode("");
      setErrorMessage(undefined);
      setSpecialist(undefined);
      setSelectedOption(undefined);
    }
  }, [isOpen]);

  const handlePersonalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage(undefined);
    }
    setPersonalCode(e.target.value);
  };

  const getExistingPersonSpecialistCodes = (): Promise<string[]> => {
    return userDataAPI(undefined, getBaseUrl(), API).getByPersonalCode(specialist?.idCode!,
      {withCredentials: true})
      .then((response: AxiosResponse) => {
        const data = response.data as HealthCareProfessional;
        const employeeId = data.id!;

        checkOccupation(data.occupationCodes)

        return serviceEmployees
          ?.filter(serviceEmployee => serviceEmployee.employeeId === employeeId
                  && serviceEmployee.specialistCode)
          .map(serviceEmployee => serviceEmployee.specialistCode!.code) ?? [];
      });
  };

  const checkOccupation = (occupationCodes: OccupationCode[] | undefined) => {
    if (!occupationCodes) return;

    const condition = warningConditions[selectedOption.code];
    setOccupationExists(condition && occupationCodes.some(condition));
    setOccupationName(condition && occupationCodes.find(condition)?.name)
    setOccupationCode(condition && occupationCodes.find(condition)?.code)
  }

  const performTortaRequest = () => {
    setErrorMessage(undefined);
    const specialistCode = selectedOption ? selectedOption.code : preselectedSpecialistOccupation?.code;
    activityPermitServiceAPI(undefined, getBaseUrl(), API)
      .validateSpecialist(specialistCode, personalCode, {
        withCredentials: true
      })
      .then((res) => {
        const data = res.data;
        setSpecialist({
          firstName: data.firstName,
          lastName: data.lastName,
          idCode: data.personCode
        });
      })
      .catch((res) => {
        let responseData = res.response?.data;
        if (responseData.error === "InvalidPersonalCodeException") {
          setErrorMessage(
            <FormattedMessage
              id="addSpecialistModal.wrongIdCode"
              defaultMessage="Isikukoodi formaat on vale"
            />
          );
        } else if (responseData.error === "TortaException") {
          setErrorMessage(
            <FormattedMessage
              id="addSpecialistModal.tortaException"
              defaultMessage="Isik ei tööta valitud ettevõttes"
            />
          );
        } else {
          setErrorMessage(
            <FormattedMessage
              id="addSpecialistModal.generalException"
              defaultMessage="Viga isiku kontrollimisel"
            />
          );
        }
        setSpecialist(undefined);
      });
  };

  const isAddEmployeeToServiceEnabled = (!selectedOption && !preselectedSpecialistOccupation) ||
    isCodeMatched ||
    !personalCode ||
    occupationExists ||
    !specialist;

  return (
    <Modal id="add-specialist-modal" isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="addSpecialistModal.header"
          defaultMessage="Lisa spetsialist"
        />
      </ModalHeader>
      <ModalBody>
        <p className="subtitle fw-normal my-0">
          <FormattedMessage
            id="addSpecialistModal.searchLabel"
            defaultMessage="Otsi spetsialisti"
          />
        </p>
        <div className="personal-code-search-box">
          <div>
            <div className="input-container speciality-input-container">
              <FormattedMessage
                id="addSpecialistModal.selectSpecialization"
                defaultMessage="Vali spetsialiseerumine"
              />
              <SingleSelect
                value={!!preselectedSpecialistOccupation ? preselectedSpecialistOccupationOption() : selectedOption}
                className="speciality-select"
                options={options}
                hideSearchIcon
                disabled={!!preselectedSpecialistOccupation}
                handleOptionChange={setSelectedOption}
              />
            </div>
          </div>
          <div className="input-container">
            <p className="m-0">
              <FormattedMessage
                id="addSpecialistModal.personalCode"
                defaultMessage="Isikukood"
              />
            </p>
            <div>
              <Input
                className="personal-code-input"
                name="personalCode"
                value={personalCode}
                onChange={handlePersonalCodeChange}
                invalid={!!errorMessage}
              />
              {errorMessage && (
                <div className="d-block personal-code-error">
                  <ErrorMessage message={errorMessage} />
                </div>
              )}
            </div>
            <Button
              color="primary"
              className={"btn-primary search-personal-data"}
              onClick={performTortaRequest}
              disabled={!personalCode || (!selectedOption && !preselectedSpecialistOccupation)}
            >
              <FormattedMessage
                id="addSpecialistModal.validateData"
                defaultMessage="Kontrolli andmeid"
              />
            </Button>
          </div>
          {specialist && (
            <>
              <p className="subtitle ml-0 mb-2 mt-4">
                {specialist.firstName} {specialist.lastName} (
                {specialist.idCode})
              </p>
              { !loading && occupationExists &&
                  <p className="text-danger">
                      Selle isikukoodiga isikul on registreeritud {occupationName}i kutse (registreerimiskood {occupationCode}). Palun siduge see isik tegevusloaga tervishoiutöötajana (tegevusega &quot;Lisa THT&quot;).
                  </p> }
              { !loading && (existingPersonSpecialistCodes.includes(selectedOption?.code) || existingPersonSpecialistCodes.includes(preselectedSpecialistOccupation?.code!)) && <p className="text-danger">
                Isik juba töötab teenuse juures selle spetsialiseerumisega.
              </p>}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className={ isMobile ? "justify-content-center" : "justify-content-right" }>
        <SecondaryFormattedButton id="cancel" onClick={onClose} />
        <PrimaryFormattedButton
          id="addEmployeeToService"
          disabled={isAddEmployeeToServiceEnabled}
          onClick={() => onSave(specialist!, preselectedSpecialistOccupation ? specialistSpecialityId : selectedOption.value, isTortaCheckRequired)}
        />
      </ModalFooter>
    </Modal>
  );
};
