import React, { useEffect, useState } from "react";
import { Option, SingleSelect } from "../../../../Component/Select/SingleSelect";
import { selectProps } from "../../Search/Filters/ActivityLicense/ActivityLicenseFIltersCommons";

interface Props {
  digitalPlatform: string;
  updateDigitalPlatform: (digitalPortal: string | null) => void;
  digitalPlatformOptions: Array<Option>;
}

export const DigitalPlatformSelector = ({ digitalPlatform, updateDigitalPlatform, digitalPlatformOptions }: Props) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    digitalPlatform
      ? setSelectedOption({ label: digitalPlatform, value: digitalPlatform })
      : setSelectedOption(null);
  }, [digitalPlatform]);

  const handleChangeSelect = (option: Option): void => {
    setSelectedOption(option);
    updateDigitalPlatform(option?.value ?? null);
  };

  return (
    <SingleSelect
      value={selectedOption}
      options={digitalPlatformOptions}
      handleOptionChange={handleChangeSelect}
      {...selectProps}
    />
  );
};