import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const AutomaticAcceptanceModal = ({ isOpen, onClose, onAccept }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="AutomaticAcceptanceModal.header"
          defaultMessage="Kas oled kindel, et soovid algatada automaatse menetluse?"
        />
      </ModalHeader>
      <ModalFooter>
        <SecondaryFormattedButton
          id="backToApplication"
          onClick={(): void => onClose()}
        />
        <PrimaryFormattedButton
          id="proceed"
          onClick={(): void => onAccept()}
        />
      </ModalFooter>
    </Modal>
  );
};