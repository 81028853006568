import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Form,
  Input,
  Col,
  Row,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown
} from "reactstrap";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { useDispatch } from "react-redux";

import "./RepresentativeSearch.scss";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { momentUTCtoString } from "../../../../Util/DateUtils";
import {
  PowerOfAttorneyStatusEnum,
  PowerOfAttorneyTypeEnum,
  PowersOfAttorneySearchFilterStartDateOperatorEnum as DateOperatorEnum
} from "../../../../../api_client/medre_api";
import { RepresentativeSearchForm, RepresentativeSearchProps } from "./types";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import {
  dateOperatorOptions,
  dateOperators,
  getDefaultSelectedStatuses
} from "../../../../Util/RepresentativeUtils";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { MultiSelect } from "../../../../Component/Select/MultiSelect";

const RepresentativeSearch = ({ handleSearch }: RepresentativeSearchProps) => {
  const defaultValues: RepresentativeSearchForm = {
    personCode: "",
    startDate: "",
    endDate: "",
    personFirstName: "",
    personLastName: "",
    personEmailAddress: "",
    startDateOperator: DateOperatorEnum.Equals,
    endDateOperator: DateOperatorEnum.Equals,
    status: getDefaultSelectedStatuses()
  };

  const { handleSubmit, control, reset } = useForm<RepresentativeSearchForm>({
    defaultValues,
    mode: "onBlur"
  });

  const [dropdownMenu, setDropdownMenu] = useState<Record<string, any>>({
    startDateOperator: { isOpen: false },
    endDateOperator: { isOpen: false }
  });
  const dispatch = useDispatch();

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(momentUTCtoString(date));
    },
    []
  );

  const handleStatusChange = useCallback(
    (field) => (status: Option[]) => {
      field.onChange(status);
    },
    []
  );

  const handleDateOperatorSelect = (
    field: ControllerRenderProps<Record<string, any>>,
    value: DateOperatorEnum
  ) => field.onChange(value);

  const handleResetSearch = () => {
    reset({
      ...defaultValues,
      status: [],
      type: null
    });
    dispatch(searchResultsActions.setFilters(null));
  };

  const getTypeOptions = useMemo(
    () => [
      {
        label: PowerOfAttorneyTypeEnum.Ultimate,
        value: PowerOfAttorneyTypeEnum.Ultimate
      },
      {
        label: PowerOfAttorneyTypeEnum.Full,
        value: PowerOfAttorneyTypeEnum.Full
      },
      {
        label: PowerOfAttorneyTypeEnum.Limited,
        value: PowerOfAttorneyTypeEnum.Limited
      },
      {
        label: PowerOfAttorneyTypeEnum.Gpl,
        value: PowerOfAttorneyTypeEnum.Gpl
      }
    ],
    []
  );

  const getStatusOptions = useMemo(
    () => [
      {
        label: (
          <FormattedMessage
            id="representativeSearch.valid"
            defaultMessage="Kehtiv"
          />
        ),
        value: PowerOfAttorneyStatusEnum.Valid
      },
      {
        label: (
          <FormattedMessage
            id="representativeSearch.expired"
            defaultMessage="Aegunud"
          />
        ),
        value: PowerOfAttorneyStatusEnum.Expired
      },
      {
        label: (
          <FormattedMessage
            id="representativeSearch.scheduled"
            defaultMessage="Planeeritud"
          />
        ),
        value: PowerOfAttorneyStatusEnum.Scheduled
      }
    ],
    []
  );

  const handleDropdownToggle = (dropdownId: string) =>
    setDropdownMenu((prevState: Record<string, any>) => ({
      ...prevState,
      [dropdownId]: { isOpen: !prevState[dropdownId].isOpen }
    }));

  const handleTypeChange = useCallback(
    (field) => (type: Option) => {
      field.onChange(type.value);
    },
    []
  );

  return (
    <>
      <div className="d-flex my-4">
        <h1 className="fw-300">
          <FormattedMessage
            id="representativeSearch.title"
            defaultMessage="Esindajate ja volituste haldamine"
          />
        </h1>
      </div>
      <div className="repr-search-form-container">
        <Form onSubmit={handleSubmit(handleSearch)}>
          <Row className="mx-0">
            <Col className="col-lg-4 col-sm-10 px-0">
              <div className="d-flex repr-search-form">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.personalCode"
                    defaultMessage="Isikukood"
                  />
                </div>
                <Controller
                  control={control}
                  name="personCode"
                  defaultValue=""
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <Input
                      bsSize="sm"
                      name="personCode"
                      className="repr-search-input"
                      value={value}
                      onChange={onChange}
                      placeholder="Esindaja isikukood"
                    />
                  )}
                />
              </div>
              <div className="d-flex repr-search-form">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.name"
                    defaultMessage="Eesnimi"
                  />
                </div>
                <Controller
                  control={control}
                  name="personFirstName"
                  defaultValue=""
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <Input
                      bsSize="sm"
                      name="personFirstName"
                      className="repr-search-input"
                      value={value}
                      onChange={onChange}
                      placeholder="Esindaja eesnimi"
                    />
                  )}
                />
              </div>
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.lastName"
                    defaultMessage="Perekonnanimi"
                  />
                </div>
                <Controller
                  control={control}
                  name="personLastName"
                  defaultValue=""
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <Input
                      bsSize="sm"
                      name="personLastName"
                      className="repr-search-input"
                      value={value}
                      onChange={onChange}
                      placeholder="Esindaja perekonnanimi"
                    />
                  )}
                />
              </div>
            </Col>
            <Col className="col-lg-4 col-sm-10 px-0">
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.type"
                    defaultMessage="Volituse tüüp"
                  />
                </div>
                <Controller
                  name="type"
                  control={control}
                  render={(
                    field: ControllerRenderProps<Record<string, any>>
                  ) => (
                    <SingleSelect
                      value={field.value}
                      options={getTypeOptions}
                      handleOptionChange={handleTypeChange(field)}
                      hideSearchIcon={true}
                      className="repr-search-select"
                      placeholder="Kõik"
                    />
                  )}
                />
              </div>
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.startDate"
                    defaultMessage="Alguskuupäev"
                  />
                </div>
                <div className="repr-form-date-container">
                  <Controller
                    control={control}
                    name="startDateOperator"
                    defaultValue=""
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <Dropdown
                        id="startDateOperator"
                        isOpen={dropdownMenu?.startDateOperator?.isOpen}
                        toggle={() => handleDropdownToggle("startDateOperator")}
                      >
                        <DropdownToggle caret size="lg">
                          {dateOperators[field.value]}
                        </DropdownToggle>
                        <DropdownMenu className="repr-search-dropdown-menu">
                          {dateOperatorOptions.map((operator) => (
                            <DropdownItem
                              key={operator.value}
                              onClick={() =>
                                handleDateOperatorSelect(field, operator.value)
                              }
                            >
                              {operator.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  />
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue=""
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <DatePickerComponent
                        onDateChange={handleChangeDate(field)}
                        selectedDate={field.value}
                        disableFuture={true}
                        inputWrapperClass="repr-search-date"
                        inputSize="sm"
                        placeholder="pp.kk.aaaa"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.endDate"
                    defaultMessage="Lõppkuupäev"
                  />
                </div>
                <div className="repr-form-date-container">
                  <Controller
                    control={control}
                    name="endDateOperator"
                    defaultValue=""
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <Dropdown
                        id="endDateOperator"
                        isOpen={dropdownMenu["endDateOperator"].isOpen}
                        toggle={() => handleDropdownToggle("endDateOperator")}
                      >
                        <DropdownToggle caret size="lg">
                          {dateOperators[field.value]}
                        </DropdownToggle>
                        <DropdownMenu className="repr-search-dropdown-menu">
                          {dateOperatorOptions.map((operator) => (
                            <DropdownItem
                              key={operator.value}
                              onClick={() =>
                                handleDateOperatorSelect(field, operator.value)
                              }
                            >
                              {operator.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  />
                  <Controller
                    control={control}
                    name="endDate"
                    defaultValue=""
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <DatePickerComponent
                        onDateChange={handleChangeDate(field)}
                        selectedDate={field.value}
                        inputWrapperClass="repr-search-date"
                        inputSize="sm"
                        placeholder="pp.kk.aaaa"
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-lg-4 col-sm-10 px-0">
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.email"
                    defaultMessage="E-post"
                  />
                </div>
                <Controller
                  control={control}
                  name="personEmailAddress"
                  defaultValue=""
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <Input
                      bsSize="sm"
                      name="personEmailAddress"
                      className="repr-search-input"
                      value={value}
                      onChange={onChange}
                      placeholder="Esindaja e-posti aadress"
                    />
                  )}
                />
              </div>
              <div className="d-flex mb-3">
                <div className="repr-search-label justify-content-end">
                  <FormattedMessage
                    id="representativeSearch.form.status"
                    defaultMessage="Olek"
                  />
                </div>
                <div className="w-100 repr-search-status">
                  <Controller
                    name="status"
                    control={control}
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <MultiSelect
                        inputId="representativeStatus"
                        options={getStatusOptions}
                        value={field.value}
                        handleOptionsChange={handleStatusChange(field)}
                        placeholder="Kõik"
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mx-0 mt-4">
            <PrimaryFormattedButton
              id="search"
              type="submit"
              className="repr-search-btn mr-3"
            />
            <SecondaryFormattedButton
              id="clearAllValues"
              type="reset"
              onClick={handleResetSearch}
            />
          </Row>
        </Form>
      </div>
    </>
  );
};

export default RepresentativeSearch;
