import { ReactElement } from "react";

export enum AlertType {
  Success = "success",
  Warning = "warning",
  Info = "info",
  Danger = "danger"
}

export interface AlertItem {
  id: number;
  type: AlertType;
  message: ReactElement;
}
