import React, { useCallback, useState, ChangeEvent, useEffect } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { AdsAddressInput } from "../../../Shared/Application/ContactInfo/Address/AdsAddressInput";
import { validateLength } from "../../../Shared/Validation/fieldValidator";
import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { isLocationAddressField } from "../../../../Dto/ActivityLicense/ActivityLicense";
import { ActivityLocation } from "../../../../../api_client/medre_api";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { RootState } from "../../../../rootReducer";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

interface Props {
  isCertificateIssuanceAddressValid?: boolean;
  setIsCertificateIssuanceAddressValid: (value?: boolean) => void;
}

const CertificateIssueAddress = ({
  isCertificateIssuanceAddressValid,
  setIsCertificateIssuanceAddressValid
}: Props) => {
  const activityLicense = useSelector(
    (state: RootState) => state.activityLicenseApplication
  );
  const currentLocation = activityLicense.locations
    ? activityLicense.locations[0]
    : {};
  
  const [manuallyInput, setManuallyInput] = useState<boolean>(
    !!currentLocation?.fullAddress && !currentLocation?.adrCode
  );

useEffect(() => {
  if (!currentLocation?.adrCode) {
    setIsCertificateIssuanceAddressValid(
      validateLength(currentLocation.fullAddress)
    );
  }
}, []);

const dispatch = useDispatch();
const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
const toggleManuallyInput = () => {
  setManuallyInput((prevState) => !prevState);
  updateAddress("fullAddress", "");
  updateAddress("zipCode", "");
  updateAddress("adrCode", "");
  updateAddress("adsAdrID", "");
  updateAddress("ehak", "");
  updateAddress("ehakmk", "");
  updateAddress("ehakov", "");
  updateAddress("apartmentNumber", "");
  setIsCertificateIssuanceAddressValid(false);
};

  const updateAddress = useCallback(
    (field: keyof ContactInfoForm, value?: string | boolean) => {
      if (isLocationAddressField(field)) {
        dispatch(
          activityLicenseApplicationActions.updateLocationInfo(
            0,
            field as keyof ActivityLocation,
            value
          )
        );
      }
    },
    [dispatch]
  );

  const handleManualInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateAddress("fullAddress", event.target.value);
    setIsCertificateIssuanceAddressValid(validateLength(event.target.value));
  };

  return (
    <div className="certificate-issue-address">
      {manuallyInput ? (
        <>
          <FormGroup
            className={
              "required w-100 m-0 flex-nowrap " +
              (isMobile ? "" : "form-inline")
            }
          >
            <Label
              htmlFor="contactInfoCertificateDeliveryAddress"
              className="control-label"
            >
              <ContactInfoFM id="certificateDeliveryType" />
            </Label>
            <div className="input-wrapper">
              <Input
                id="contactInfoCertificateDeliveryAddress"
                valid={isCertificateIssuanceAddressValid}
                onChange={handleManualInputChange}
                value={currentLocation.fullAddress}
              />
              <FormFeedback className="pt-2">
                <FormattedErrorMessage id="mandatoryField" />
              </FormFeedback>
              <div className="mt-2">
                <div className="address-input-text">
                  <span>
                    <FormattedMessage
                      id="ApplicationCertificateContactInfo.estonianAddressText"
                      defaultMessage="Eesti aadressi saate sisestada"
                    />
                  </span>
                  <LinkButton
                    id="useAddressSearch"
                    onClick={toggleManuallyInput}
                  />
                </div>
              </div>
            </div>
          </FormGroup>
        </>
      ) : (
        <>
          <AdsAddressInput
            setIsAddressValid={setIsCertificateIssuanceAddressValid}
            updateContactInfo={updateAddress}
            fullAddress={currentLocation?.fullAddress}
            adsAdrID={currentLocation?.adsAdrID}
            zipCode={currentLocation?.zipCode}
            isMandatory={true}
            addressLabel={<ContactInfoFM id="certificateDeliveryType" />}
            addressContainerClassName="ads-address-input-container"
            zipCodeClassName="ads-address-zip-code"
            isCertificateIssueAddress
          />
          <div className="address-input-additional-info">
            <span>
              <FormattedMessage
                id="ApplicationCertificateContactInfo.anotherAddressText"
                defaultMessage="Kui postiaadress on välismaal, või otsing ei anna tulemusi siis"
              />
            </span>
            <LinkButton id="markItManually" onClick={toggleManuallyInput} />
          </div>
        </>
      )}
    </div>
  );
};

export default CertificateIssueAddress;
