import React, { useMemo, useState } from "react";

import { getFullPhoneNumber } from "../../../Shared/Application/ContactInfo/Address/AddressUtil";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ApplicantContactInfoEditModal } from "./ApplicantContactInfoEditModal";
import { ContactInfoRow } from "../../../Shared/Application/ContactInfo/ContactInfoRow";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import {
  ContactInfo,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";

interface Props {
  personId?: string;
  personIdCode?: string;
  contactInfo?: ContactInfo;
  handleSave: (contactInfo: ContactInfo) => void;
}

export const ApplicantContactInfo = ({
  personId,
  personIdCode,
  contactInfo,
  handleSave
}: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const onSave = (newContactInfo: ContactInfo) => {
    setIsEditModalOpen(false);
    handleSave(newContactInfo);
  };

  const button = useMemo(
    () => (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="change"
          onClick={() => setIsEditModalOpen(true)}
        />
      </ProtectedFieldWrapper>
    ),
    []
  );

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.ContactInfo}
      button={button}
    >
      <ContactInfoRow id="email" value={contactInfo?.email} />
      <ContactInfoRow id="phone" value={getFullPhoneNumber(contactInfo)} />
      <ApplicantContactInfoEditModal
        personId={personId}
        personIdCode={personIdCode}
        contactInfo={contactInfo}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={onSave}
      />
    </ApplicantDetailsSection>
  );
};
