import React from "react";
import { FormattedMessage } from "react-intl";

import { SuspensionModalContainer } from "./SuspensionModalContainer";
import { createServiceName } from "../../../../Dto/ActivityLicense/Service";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import info_icon from "../../../../../assets/images/information.svg";
import {
    ActivityLicenseService,
    Service,
    ActivityLicenseApplicationProceedingTypeEnum, ServiceSuspensionInfo
} from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: (
    suspensionType?: ActivityLicenseApplicationProceedingTypeEnum
  ) => void;
  serviceId: string;
  service: Service;
  servicesToSuspend?: ActivityLicenseService[];
  serviceSuspensionInfo?: ServiceSuspensionInfo;
  onServiceSuspensionSubmit?: () => void;
}

export const ActivityLicenseServiceSuspensionModal = ({
  isOpen,
  onClose,
  serviceId,
  service,
  servicesToSuspend,
  serviceSuspensionInfo,
  onServiceSuspensionSubmit
}: Props) => {
  const renderServicesToSuspend = () => {
    if (servicesToSuspend?.length) {
      return (
        <div className="text">
          <FormattedMessage
            id="suspendActivityLicenseModal.requiredServicesRemovalText"
            defaultMessage="Peatatakse ka järgnevad kohustuslikud teenused"
          />
          {servicesToSuspend?.map((rs) => (
            <div key={rs?.service?.code} className="d-block">
              <b>{rs?.service?.name}</b>
            </div>
          ))}
        </div>
      );
    }
  };

  const getServicesIds = () => servicesToSuspend?.map((s) => s.id).flat();

  const getTooltipMessage = () => (
    <FormattedMessage
      id="suspendActivityLicenseModal.personnelInfoIconTooltip"
      defaultMessage="Pane tähele! Personali eemaldades tuleb enne peatamise alustamist eelnevalt eemaldada töötaja TÖR'ist"
    />
  );

  const createTitle = () => (
    <>
      <FormattedMessage
        id="addSuspensionModal.serviceHeader"
        defaultMessage="Peata teenus {service}"
        values={{ service: createServiceName(service, true) }}
      />
      <FormattedToolTip
        placement="top"
        target="suspension-personnel-info-icon"
        message={getTooltipMessage()}
      />
      <img
        id="suspension-personnel-info-icon"
        src={info_icon}
        alt="icon"
        className="pl-1"
      />
      <>{renderServicesToSuspend()}</>
    </>
  );

  return (
    <SuspensionModalContainer
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={createTitle()}
      suspensionType={
        ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension
      }
      idToSuspend={serviceId}
      serviceIdsToSuspend={getServicesIds() as string[]}
      serviceSuspensionInfo={serviceSuspensionInfo}
      onSubmitEmit={onServiceSuspensionSubmit}
    />
  );
};
