import React from "react";
import { Card } from "reactstrap";

import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { renderOverViewRow } from "./ApplicationOverViewUtil";
import { ContactInfo } from "../../../../../api_client/medre_api";
import { getFullPhoneNumber } from "../ContactInfo/Address/AddressUtil";

interface Props {
  contactInfo?: ContactInfo;
}

export const ContactInfoOverViewCard = ({ contactInfo }: Props) => (
  <>
    <h5 className="fw-normal">
      <ContactInfoFM id="contacts" />
    </h5>
    <Card className="info-card">
      {renderOverViewRow("contactInfo", "email", contactInfo?.email)}
      {renderOverViewRow(
        "contactInfo",
        "phone",
        getFullPhoneNumber(contactInfo) || "-"
      )}
    </Card>
  </>
);
