import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import "./CompanyApplication.scss";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { NoResults } from "../../Table/NoResults";
import { CompanyApplicationTable } from "./CompanyApplicationTable";
import {
  CompanyApplication,
  TegevuslubadeTaotlusteTeenusApiFactory as activityLicenseAppAPI
} from "../../../../../api_client/medre_api";
import "../Table/MyActivityLicenses.scss";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import {activityLicenseApplicationActions} from "../../../../ActivityLicense/activityLicenseApplicationActions";
import {useDispatch} from "react-redux";

export const CompanyApplications = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applications, setApplications] = useState<CompanyApplication[]>([]);

  const history = useHistory();
  const dispatch = useDispatch();


  const loadApplications = useCallback(() => {
    setIsLoading(true);
    activityLicenseAppAPI(undefined, getBaseUrl(), API)
      .getCompanyApplications({
        withCredentials: true
      })
      .then((res) => setApplications(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    loadApplications();
  }, [loadApplications]);

  const handleDelete = (rowNumber: number) => {
    setApplications((prevState) => {
      if (prevState) {
        prevState.splice(rowNumber, 1);
        return [...prevState];
      }
      return [];
    });
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    } else if (applications?.length) {
      return (
        <CompanyApplicationTable
          applications={applications}
          handleDelete={handleDelete}
          reloadApplications={loadApplications}
        />
      );
    } else {
      return <NoResults />;
    }
  };

  const createNewApplication = () => {
    dispatch(
        activityLicenseApplicationActions.setActivityLicense({
          currentStep: 0,
          locations: [{}]
        })
    );
    history.push("/new-activity-license");
  };

  return (
    <div id="my-applications-container">
      <Row>
        <h1 className="fw-300">
          <FormattedMessage
            id="companyApplications.title"
            defaultMessage="Minu taotlused"
          />
        </h1>
        <div className="button-container">
          <PrimaryFormattedButton
            id="newApplication"
            onClick={createNewApplication}
          />
        </div>
      </Row>
      {renderContent()}
    </div>
  );
};
