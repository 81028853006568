import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import "../PreviousNames/PreviousNamesDetails.scss";
import { RootState } from "../../../../rootReducer";
import { PrescriptionPowerForm } from "./PrescriptionPowerForm";
import { Card } from "../Card";
import { AttachmentCardTitle } from "../AttachmentCardTitle";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import { FileReference, OfficialUserPrivilegesEnum, PersonStatusEnum } from "../../../../../api_client/medre_api";
import { PrescriptivePowerSpeciality } from "../../../../Dto/Application/Speciality";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";
import { UserInfo } from "../../../../Dto/Security/UserInfo";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { getFormattedDate } from "../../../../Util/DateUtils";
import { PrescriptionPowerDetailsProps } from "./types";

export const PrescriptionPowerDetails = ({
  person,
  applicantChanges,
  setApplicantChanges,
  forceValidation,
  specialNurseChanges,
  setSpecialNurseChanges
}: PrescriptionPowerDetailsProps) => {
  const specialityCode = useSelector(
    (state: RootState) => state.config.prescriptionPowerSpecialityCode
  );
  const nursePrefix = useSelector(
    (state: RootState) => state.config.occupationPrefixes?.nurse
  );
  const midWifePrefix = useSelector(
    (state: RootState) => state.config.occupationPrefixes?.midwife
  );
  const prescriptionRightSpecialityCode = useSelector(
    (state: RootState) => state.config.prescriptionRightSpecialityCode
  );

  const { roles } = useSelector(
    (state: RootState) => Object(state.user.userInfo) as UserInfo
  );

  const existingPrescriptionSpeciality =
    person.specialities &&
    person.specialities.find(
      (speciality) => speciality.code === specialityCode
    );
  const hasPrescriptionPower =
    (person.residencyInfo?.studyYear && person.residencyInfo.studyYear >= 3) ||
    (person.specialities &&
      person.specialities.some((s) => s.code?.startsWith("E"))) ||
    person.occupationCodes?.some(
      (o) => o.prefix === midWifePrefix || o.prefix === nursePrefix
    );

  const existingPrescriptionRightSpeciality =
    person.specialities &&
    person.specialities.find(
      (speciality) => speciality.code === prescriptionRightSpecialityCode
    );

  const personHasNurseOccupation =
    person.occupationCodes &&
    person.occupationCodes.some(
      (occupationCode) => occupationCode.name === "Õde"
    );

  const isFormVisible =
    person.status &&
    person.status === PersonStatusEnum.Registered &&
    personHasNurseOccupation &&
    !existingPrescriptionSpeciality;

  const prescriptionPowerDocumentArray =
    existingPrescriptionSpeciality &&
    (existingPrescriptionSpeciality as PrescriptivePowerSpeciality)
      .prescriptionPowerDocument
      ? [
          (existingPrescriptionSpeciality as PrescriptivePowerSpeciality)
            .prescriptionPowerDocument
        ]
      : [];

  const prescriptionRightPowerDocumentArray =
    existingPrescriptionRightSpeciality &&
    (existingPrescriptionRightSpeciality as PrescriptivePowerSpeciality)
      .prescriptionPowerDocument
      ? [
          (existingPrescriptionRightSpeciality as PrescriptivePowerSpeciality)
            .prescriptionPowerDocument
        ]
      : [];

  const getSpecialNurseTitleParam = () => {
    const prescriptionPowerNumber = (
      existingPrescriptionRightSpeciality as PrescriptivePowerSpeciality
    ).prescriptionPowerNumber?.toLocaleLowerCase();

    return prescriptionPowerNumber?.includes("migrated") ||
      prescriptionPowerNumber?.includes("migreeritud") ? (
      <FormattedMessage
        id="specialNursePrescription.param"
        defaultMessage="- Migreeritud"
      />
    ) : (
      ""
    );
  };

  //N800 None
  const getPrescriptionRightSpecialityEmptyBlock = () => {
    if (
      personHasNurseOccupation &&
      ((roles?.length === 1 &&
        roles?.includes(OfficialUserPrivilegesEnum.Sign) &&
        person.status !== PersonStatusEnum.Registered) ||
        roles?.includes(OfficialUserPrivilegesEnum.Observe) ||
        roles?.includes(OfficialUserPrivilegesEnum.Proceede))
    ) {
      return (
        <Card
          classNameOverride="card p-4"
          headline={
            <FormattedMessage
              id="specialNursePrescription.title"
              defaultMessage="Eriõe retseptiõigus ({code}) {param}"
              values={{
                code: prescriptionRightSpecialityCode,
                param: (
                  <FormattedMessage
                    id="specialNursePrescription.none"
                    defaultMessage="- Puudub"
                  />
                )
              }}
            />
          }
        />
      );
    }
  };

  const specialNurseSpecialityExist =
    person.specialities &&
    person.specialities.some(
      (speciality) =>
        speciality.code === "N100" ||
        speciality.code === "N200" ||
        speciality.code === "N300" ||
        speciality.code === "N400"
    );

  //N100 or N200 or N300 or N400
  const getSpecialNurseSpecialityBlock = () => (
    <ProtectedComponent allowedRoles={[OfficialUserPrivilegesEnum.Sign]}>
      <div className="card p-4">
        <hr className="mt-2" />
        <h4 className="fw-500 mb-3">
          <FormattedMessage
            id="specialNursePrescription.title"
            defaultMessage="Eriõe retseptiõigus ({code}) {param}"
            values={{
              code: prescriptionRightSpecialityCode,
              param: ""
            }}
          />
        </h4>
        <PrescriptionPowerForm
          id="prescriptionRightSpecialityCode"
          person={person}
          applicantChanges={specialNurseChanges}
          setApplicantChanges={setSpecialNurseChanges}
          forceValidation={forceValidation}
          applicantFormClassName="prescription-power-applicant-form"
          dateConfig={{
            required: true,
            disableFuture: true
          }}
        />
      </div>
    </ProtectedComponent>
  );

  //N700
  const getPrescriptionSpecialityBlock = () => (
    <Card
      classNameOverride="card p-4"
      key={existingPrescriptionSpeciality?.id}
      title={
        <AttachmentCardTitle
          fileReferences={prescriptionPowerDocumentArray as FileReference[]}
          name={
            (existingPrescriptionSpeciality as PrescriptivePowerSpeciality)
              .prescriptionPowerNumber
          }
        />
      }
      subtitle={
        <FormattedMessage
          id="specialNursePrescription.dateOfProof"
          defaultMessage="Tõendi kuupäev: {date}"
          values={{
            date: getFormattedDate(
              (existingPrescriptionSpeciality as PrescriptivePowerSpeciality)
                .prescriptionPowerDate as Date
            )
          }}
        />
      }
      headline={
        <FormattedMessage
          id="familyNursePrescription.title"
          defaultMessage="Pereõe retseptiõigus ({code})"
          values={{ code: specialityCode }}
        />
      }
    />
  );

  //N800
  const getPrescriptionRightSpecialityBlock = () =>
    personHasNurseOccupation && (
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Observe
        ]}
      >
        <Card
          classNameOverride="card p-4"
          key={existingPrescriptionRightSpeciality?.id}
          headline={
            <FormattedMessage
              id="specialNursePrescription.title"
              defaultMessage="Eriõe retseptiõigus ({code}) {param}"
              values={{
                code: prescriptionRightSpecialityCode,
                param: getSpecialNurseTitleParam()
              }}
            />
          }
          subtitle={
            <FormattedMessage
              id="specialNursePrescription.dateOfRelease"
              defaultMessage="Väljastamise kuupäev: {date}"
              values={{
                date: getFormattedDate(
                  (
                    existingPrescriptionRightSpeciality as PrescriptivePowerSpeciality
                  ).prescriptionPowerDate as Date
                )
              }}
            />
          }
          title={
            <AttachmentCardTitle
              fileReferences={
                prescriptionRightPowerDocumentArray as FileReference[]
              }
              name={
                (
                  existingPrescriptionRightSpeciality as PrescriptivePowerSpeciality
                ).prescriptionPowerNumber
              }
            />
          }
        />
      </ProtectedComponent>
    );

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.PrescriptionPower}
      customTitle="Retseptiõigus"
    >
      <div className="detail-card-grid">
        {isFormVisible ? (
          <div className="card p-4">
            <hr className="mt-2" />
            <h4 className="fw-500 mb-3">
              <FormattedMessage
                id="familyNursePrescription.title"
                defaultMessage="Pereõe retseptiõigus ({code})"
                values={{ code: specialityCode }}
              />
            </h4>
            <PrescriptionPowerForm
              id="prescriptionPowerSpeciality"
              person={person}
              applicantChanges={applicantChanges}
              setApplicantChanges={setApplicantChanges}
              forceValidation={forceValidation}
              applicantFormClassName="prescription-power-applicant-form"
            />
          </div>
        ) : existingPrescriptionSpeciality ? (
          getPrescriptionSpecialityBlock()
        ) : null}
        {specialNurseSpecialityExist &&
        !existingPrescriptionRightSpeciality &&
        person?.status === PersonStatusEnum.Registered
          ? getSpecialNurseSpecialityBlock()
          : existingPrescriptionRightSpeciality
          ? getPrescriptionRightSpecialityBlock()
          : getPrescriptionRightSpecialityEmptyBlock()}
      </div>
    </ApplicantDetailsSection>
  );
};
