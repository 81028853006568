import React from "react";
import { Col, Row } from "reactstrap";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ContactInfoRow } from "./ContactInfoRow";
import { ContactInfo } from "../../../../../api_client/medre_api";
import { UserInfo } from "../../../../Dto/Security/UserInfo";
import { getFullPhoneNumber } from "./Address/AddressUtil";

interface Props {
  userInfo?: UserInfo;
  personContactInfo?: ContactInfo;
  setIsEditActive: (isActive: boolean) => void;
}

export const ContactInfoView = ({
  userInfo,
  personContactInfo,
  setIsEditActive
}: Props) => (
  <>
    <Row className="mb-0 mt-4 mr-4 ml-4">
      <Col md={8} className="p-0">
        <h2 className="contact-info-card-header fw-normal">
          {userInfo?.name} ({userInfo?.idCode})
        </h2>
      </Col>
      <Col className="p-0 change-button-column">
        <PrimaryFormattedButton
          id="change"
          className="change-button-mobile-view"
          onClick={() => setIsEditActive(true)}
        />
      </Col>
    </Row>
    <ContactInfoRow id="email" value={personContactInfo?.email} />
    <ContactInfoRow id="phone" value={getFullPhoneNumber(personContactInfo)} />
  </>
);
