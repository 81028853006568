import React from "react";

import { FormattedButton, FormattedButtonProps } from "./FormattedButton";
import "./Button.scss";

export const DangerFormattedButton = ({
  id,
  className,
  onClick,
  disabled,
  size
}: FormattedButtonProps) => (
  <FormattedButton
    id={id}
    color="danger"
    className={className + " btn-danger"}
    onClick={onClick}
    disabled={disabled}
    size={size}
  />
);
