import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Input, TooltipProps } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData,
} from "../../../Shared/Search/Filters/filterActions";
import {
  FilterColumn,
  FilterContainer,
  FilterField,
} from "../../../../Component/Filter/Filter";
import { useMobile } from "../../../../Hook/useMobile";

enum FilterKeys {
  generalPractitionerOrSubstitute = "generalPractitionerOrSubstitute",
  companyName = "companyName",
  serviceArea = "serviceArea",
  onlyWithVacancies = "onlyWithVacancies",
  code = "code"
}

const tooltipMessages = {
  [FilterKeys.generalPractitionerOrSubstitute]: (
    <FormattedMessage
      id="filters.generalPractitionerOrSubstituteTooltip"
      defaultMessage="Sisestage perearsti või asendaja nimi või nimeosa (ees-või perenimi või mõlemad, suvalises järjekorras). Kui teate, siis võite siia sisestada hoopis tervishoiutöötaja koodi."
    />
  ),
  [FilterKeys.companyName]: (
    <FormattedMessage
      id="filters.companyTooltip"
      defaultMessage="Sisestage sõna või sõnaosa või järjestikused sõnad, mis teenuse osutaja nimes esinevad. Ärge kasutage otsingus sõnu osaühing, füüsilisest isikust ettevõtja, aktsiaselts või nende lühendeid (OÜ, FIE, AS)"
    />
  ),
  [FilterKeys.serviceArea]: (
    <FormattedMessage
      id="filters.serviceAreaTooltip"
      defaultMessage="Sisestage linna või valla nimi või nimiosa. Kui soovite perearsti teeninduspiirkonnaga Tallinnas siis pange otsingusse Tallinn või teie elukoha linnaosa. Vajutades nimistu numbrile saate nimistu täpsemad andmed. Tänavate põhine otsing on võimalik ainult Tallinna linna nimistute vahel, juhul kui sisestatud tänavat ei leitud siis tuleb sisestada linnaosa või Tallinn"
    />
  ),
  [FilterKeys.onlyWithVacancies]: (
    <FormattedMessage
      id="filters.onlyWithVacanciesTooltip"
      defaultMessage="Kui teha linnuke, siis otsing näitab ainult neid perearste, kelle nimistutes on päringu hetkel vabu kohti"
    />
  )
};

export const PublicGeneralPractitionerListFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    filterSelectors.selectPublicGeneralPractitionerListFilters,
    shallowEqual
  );
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(
        filterActions.setPublicGeneralPractitionerListFilter({ key, data })
      );
    },
    [dispatch]
  );
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );
  const handleToggleCheckbox = useCallback(
    () =>
      dispatch(
        filterActions.setPublicGeneralPractitionerListFilter({
          key: FilterKeys.onlyWithVacancies,
          data: !state.onlyWithVacancies
        })
      ),
    [dispatch, state.onlyWithVacancies]
  );

  const isMobile = useMobile();
  const tooltipProps: Omit<TooltipProps, "target"> = useMemo(
    () => ({
      trigger: "focus",
      placement: "auto",
      ...(!isMobile && {
        placement: "right"
      })
    }),
    [isMobile]
  );

  return (
    <FilterContainer>
      <FilterColumn xl={5}>
        <FilterField
          id={FilterKeys.code}
          label={
            <FormattedMessage
              id="filters.codeLabel"
              defaultMessage="Nimistu nr"
            />
          }
          field={
            <Input
              id={FilterKeys.code}
              className="w-100"
              value={state.code}
              onChange={handleChangeInput}
              name="code"
            />
          }
        />
        <FilterField
          id={FilterKeys.generalPractitionerOrSubstitute}
          label={
            <FormattedMessage
              id="filters.generalPractitionerOrSubstituteLabel"
              defaultMessage="Perearst või asendaja"
            />
          }
          field={
            <>
              <Input
                id={FilterKeys.generalPractitionerOrSubstitute}
                className="w-100"
                value={state.generalPractitionerOrSubstitute}
                onChange={handleChangeInput}
                name="generalPractitionerOrSubstitute"
              />
              <span
                id="general-practitioner-or-substitute-info-icon"
                className="info-icon"
                tabIndex={0}
                role="region"
                aria-label="tooltip"
              />
              <FormattedToolTip
                target="general-practitioner-or-substitute-info-icon"
                message={
                  tooltipMessages[FilterKeys.generalPractitionerOrSubstitute]
                }
                {...tooltipProps}
              />
            </>
          }
        />

        <FilterField
          id={FilterKeys.companyName}
          label={
            <FormattedMessage
              id="filters.companyLabel"
              defaultMessage="Teenuse osutaja"
            />
          }
          field={
            <>
              <Input
                id={FilterKeys.companyName}
                className="w-100"
                value={state.companyName}
                onChange={handleChangeInput}
                name="companyName"
              />
              <span
                id="company-name-info-icon"
                className="info-icon"
                tabIndex={0}
                role="region"
                aria-label="tooltip"
              />
              <FormattedToolTip
                target="company-name-info-icon"
                message={tooltipMessages[FilterKeys.companyName]}
                {...tooltipProps}
              />
            </>
          }
        />
      </FilterColumn>
      <FilterColumn xl={5}>
        <FilterField
          id={FilterKeys.serviceArea}
          label={
            <FormattedMessage
              id="filters.serviceAreaLabel"
              defaultMessage="Teeninduspiirkond"
            />
          }
          field={
            <>
              <Input
                id={FilterKeys.serviceArea}
                className="w-100"
                value={state.serviceArea}
                onChange={handleChangeInput}
                name="serviceArea"
              />
              <span
                id="service-area-info-icon"
                className="info-icon"
                tabIndex={0}
                role="region"
                aria-label="tooltip"
              />
              <FormattedToolTip
                target="service-area-info-icon"
                message={tooltipMessages[FilterKeys.serviceArea]}
                {...tooltipProps}
              />
            </>
          }
        />

        <FilterField
          field={
            <div className="d-flex align-items-center">
              <Checkbox
                isChecked={Boolean(state.onlyWithVacancies)}
                id={FilterKeys.onlyWithVacancies}
                labelText={
                  <FormattedMessage
                    id="filters.onlyWithVacanciesLabel"
                    defaultMessage="Vabade kohtade olemasolu"
                  />
                }
                onChange={handleToggleCheckbox}
                className="d-inline-block gp-filter-checkbox"
              />
              <span
                id="only-with-vacancies-info-icon"
                className="info-icon position-relative d-inline-block m-0"
                tabIndex={0}
                role="region"
                aria-label="tooltip"
              />
              <FormattedToolTip
                target="only-with-vacancies-info-icon"
                message={tooltipMessages[FilterKeys.onlyWithVacancies]}
                {...tooltipProps}
              />
            </div>
          }
        />
      </FilterColumn>
    </FilterContainer>
  );
};
