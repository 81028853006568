import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  Controller,
  useFieldArray,
  useForm,
  ControllerRenderProps
} from "react-hook-form";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
  useAllowedServiceAreaOptions,
  useGeneralPractitionerListServiceAreaSelector
} from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import { FieldArray } from "../../../../Component/HookForm/FieldArray";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { Option } from "../../../../Component/Select/SelectTypes";
import { ModalKeys } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import {
  ServiceArea,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI
} from "../../../../../api_client/medre_api";
import { useToday } from "../../../../Util/DateUtils";

interface Props {
  id: ModalKeys.createServiceArea | ModalKeys.changeServiceArea;
  onClose: () => void;
}

interface ServiceAreaOption extends Option {
  disabled?: boolean;
}

interface FormValues {
  ids: (ServiceAreaOption & { _id?: string })[];
  activationDate?: Date | string;
}

const defaultAppendValue = { value: "", label: "" };

const messages = {
  [ModalKeys.createServiceArea]: (
    <FormattedMessage
      id="GPListServiceAreaModal.createServiceArea"
      defaultMessage="Määra teeninduspiirkond"
    />
  ),
  [ModalKeys.changeServiceArea]: (
    <FormattedMessage
      id="GPListServiceAreaModal.changeServiceArea"
      defaultMessage="Muuda teeninduspiirkond"
    />
  )
};

export default function ServiceAreaModal({ id: modalId, onClose }: Props) {
  const { fetchGPList, list } = useGeneralPractitionerList();
  const serviceArea = useGeneralPractitionerListServiceAreaSelector();
  const allowedServiceAreas = useAllowedServiceAreaOptions();
  const { activationDate } = serviceArea;
  const today = useToday();
  const { setValue, control, handleSubmit, formState, watch } =
    useForm<FormValues>({
      mode: "onChange",
      defaultValues: {
        ids: [defaultAppendValue],
        activationDate:
          activationDate && moment(activationDate).isAfter()
            ? activationDate
            : today
      }
    });
  const fieldArray = useFieldArray({
    control,
    name: "ids",
    keyName: "_id"
  });
  const { isSubmitting, isValid } = formState;
  const usedServiceAreas = watch("ids").map(({ value }) => value);
  const getAllowedServiceAreaOptions = useCallback(
    (currentValue: string): Option[] => {
      const currentUsedServiceAreaIds = usedServiceAreas.filter(
        (id) => id !== currentValue
      );
      return allowedServiceAreas.filter(
        ({ value, deleteOnly }) =>
          !currentUsedServiceAreaIds.includes(value) && !deleteOnly
      );
    },
    [usedServiceAreas, allowedServiceAreas]
  );

  useEffect(() => {
    if (serviceArea.ids?.length) {
      setValue(
        "ids",
        allowedServiceAreas
          .filter(({ value }) => serviceArea.ids.includes(value))
          .map((option) => ({
            ...option,
            disabled: modalId === ModalKeys.changeServiceArea
          }))
      );
    }
  }, [allowedServiceAreas, modalId, serviceArea, setValue]);

  const handleChangeParishIds = useCallback(
    (props) => (option: Option) => {
      props.onChange(option.value);
    },
    []
  );

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(moment.utc(date).startOf("day").toISOString());
    },
    []
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { data: serviceAreaData } = await officialFamilyListAPI(
        undefined,
        getBaseUrl(),
        API
      ).updateServiceAreaModification(
        list.id,
        {
          ids: values.ids.map(({ value }) => value),
          activationDate: moment
            .utc(values.activationDate)
            .startOf("day")
            .toISOString()
        } as ServiceArea,
        {
          withCredentials: true
        }
      );

      dispatch(generalPractitionerListActions.setServiceArea(serviceAreaData));

      await fetchGPList(list.id);

      onClose();
    },
    [list.id, fetchGPList, dispatch, onClose]
  );

  const validateId = useCallback((value) => Boolean(value), []);

  const title = useMemo(() => messages[modalId], [modalId]);
  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      <ModalHeader>{title}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="pt-0">
          <FieldArray<ServiceAreaOption>
            array={fieldArray}
            appendValue={defaultAppendValue}
            appendText={
              <FormattedMessage
                id="GPListServiceAreaModal.serviceAreaAddLink"
                defaultMessage="Lisa teeninduspiirkond"
              />
            }
            render={(fields, { appendButton, removeButton }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field._id} className="mb-3">
                    {fields.length > 1 && (
                      <div className="text-right mb-1">
                        {removeButton(index)}
                      </div>
                    )}
                    <Card>
                      <CardHeader className="p-3 border-0 position-relative">
                        <FormGroup row={true} className="mb-0">
                          <Label
                            htmlFor={`gpListServiceAreaModalServiceAreaId_${index}`}
                            sm="auto"
                          >
                            <FormattedMessage
                              id="GPListContacts.serviceArea"
                              defaultMessage="Teeninduspiirkond"
                            />
                          </Label>
                          <Col sm="auto" className="flex-grow-1">
                            <Controller
                              name={`ids[${index}].value`}
                              control={control}
                              defaultValue={field.value}
                              rules={{ validate: validateId }}
                              render={({
                                value,
                                onChange
                              }: ControllerRenderProps<
                                Record<string, any>
                              >) => (
                                <SingleSelect
                                  inputId={`gpListServiceAreaModalServiceAreaId_${index}`}
                                  options={getAllowedServiceAreaOptions(value)}
                                  value={value}
                                  handleOptionChange={handleChangeParishIds({
                                    onChange
                                  })}
                                  disabled={Boolean(field.disabled)}
                                />
                              )}
                            />
                          </Col>
                        </FormGroup>
                      </CardHeader>
                    </Card>
                  </div>
                ))}
                {appendButton}
              </>
            )}
          />
          <div className="pl-4 pr-4 pt-3">
            <FormGroup row={true}>
              <Label htmlFor="gpListServiceAreaModalActivationDate" sm="auto">
                <FormattedMessage
                  id="GPListModal.assignDate"
                  defaultMessage="Määra kuupäev"
                />
              </Label>
              <Col sm="auto" className="flex-grow-1">
                <Controller
                  name="activationDate"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    onChange,
                    value
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <DatePickerComponent
                      id="gpListServiceAreaModalActivationDate"
                      onDateChange={handleChangeDate({ onChange })}
                      selectedDate={value}
                      disablePast={true}
                      placeholder={"pp.kk.aaaa"}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            onClick={onClose}
            disabled={isSubmitting}
          />

          <Button
            type="submit"
            color="primary"
            className="m-2 btn-primary"
            disabled={!isValid || isSubmitting}
          >
            {title}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
