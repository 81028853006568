import React, { FC, ReactElement } from "react";

import {
  ApplicantDetailsSectionKeys,
  APPLICANT_DETAILS_SECTIONS,
} from "./ApplicantNavigationList";
import DetailsSection from "../../../Component/DetailsSection/DetailsSection";

interface Props {
  section: ApplicantDetailsSectionKeys;
  button?: ReactElement | null;
  classNames?: string;
  customTitle?: string;
}

const ApplicantDetailsSection: FC<Props> = ({
  section,
  button,
  classNames = "",
  customTitle = "",
  children,
}) => {
  const { id, title } = APPLICANT_DETAILS_SECTIONS[section];

  return (
    <DetailsSection
      id={id}
      title={customTitle || title}
      className={`mx-3 ${classNames}`}
      button={button}
    >
      {children}
    </DetailsSection>
  );
};

export default ApplicantDetailsSection;
