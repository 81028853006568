import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  applicationNumber?: number;
  modalMessage: string;
}

export const ApplicationAcceptanceModal = ({
  isOpen,
  onClose,
  onAccept,
  applicationNumber,
  modalMessage
}: Props) => {
  const accept = () => {
    onClose();
    onAccept();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="activityLicenseApplicationAcceptanceModal.header"
          defaultMessage="Oled Sa kindel, et soovid taotluse {id} rahuldada?"
          values={{ id: applicationNumber }}
        />
      </ModalHeader>
      <ModalBody>{modalMessage}</ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="back" onClick={onClose} />
        <PrimaryFormattedButton id="acceptApplication" onClick={accept} />
      </ModalFooter>
    </Modal>
  );
};
