import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  ControllerRenderProps
} from "react-hook-form";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { PhoneCodeSelector } from "../../../Shared/Application/ContactInfo/PhoneCodeSelector";
import { DigitalPlatformSelector } from "../../../Shared/Application/ContactInfo/DigitalPlatformSelector";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import {
  CheckboxGroup,
  Values
} from "../../../../Component/Checkbox/CheckboxGroup";
import {
  isInsertedEmailValid,
  validateArray,
  validateOptionalFieldNumber,
  validateURL
} from "../../../Shared/Validation/fieldValidator";
import { API, getBaseUrl } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { useWorkingDayOptions } from "../../../../Util/OptionUtil";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { useFieldFocused } from "../../../../Hook/useFieldFocused";
import {
  DigitalPlatform,
  GeneralPractitionerListContact,
  WorkingDay,
} from "../../../../../api_client/medre_api";
import "./Contact.scss";
import {
  PerearstiNimistudTeenusApiFactory as doctorDirectoryAPI,
  DigiteenindusplatvormidApiFactory as digitalPlatformsAPI
} from "../../../../../api_client/medre_api";
import WorkingHours from "../../../../Component/WorkingHours/WorkingHours";
import { getUpdatedWorkingDays } from "../../../../Util/GeneralPractitionerUtils";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import { Option } from "../../../../Component/Select/SingleSelect";
import {
  Item,
  makeOptions
} from "../../../Shared/Search/Filters/ActivityLicense/ActivityLicenseFIltersCommons";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  contacts: GeneralPractitionerListContact[];
}

const GPListContactsModal: FC<Props> = ({ open, onClose }) => {
  const { list, fetchGPList } = useGeneralPractitionerList();
  const { id, contacts } = list;
  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    watch,
    getValues
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      contacts
    }
  });
  useEffect(() => {
    if (!open) {
      reset({ contacts: contacts });
    }
  }, [open, reset, contacts]);
  const { fields } = useFieldArray<GeneralPractitionerListContact>({
    control,
    name: "contacts"
  });
  const { isSubmitting, isValid, errors } = formState;
  const { contacts: licenseErrors = [] } = errors;

  const {
    isFocused: isFocusedEmail,
    handleFocus: handleEmailFocus,
    handleBlur: handleEmailBlur
  } = useFieldFocused();

  const {
    isFocused: isFocusedHomepage,
    handleFocus: handleHomepageFocus,
    handleBlur: handleHomepageBlur
  } = useFieldFocused();

  const workingDayOptions = useWorkingDayOptions();

  const [digitalPlatforms, setDigitalPlatforms] = useState<DigitalPlatform[]>();
  const [digitalPlatformOptions, setDigitalPlatformOptions] = useState<Array<Option>>([]);

  const formatDigitalPlatforms = (contactsList: GeneralPractitionerListContact[]): void => {
    contactsList.forEach(contact => {
      contact.digitalPlatform = digitalPlatforms?.find(platform =>
        `${ platform.name } (${ platform.url })` === contact.digitalPlatform);
    });
  };

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      formatDigitalPlatforms(values.contacts);

      try {
        await doctorDirectoryAPI(
          undefined,
          getBaseUrl(),
          API
        ).updateGeneralPractitionerList1(id, values, {
          withCredentials: true
        });

        await fetchGPList(id);
        onClose();
      } catch (e) {
        const alertMessage = <AlertMessage id="requestFailed"/>;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }
    },
    [id, fetchGPList, onClose, dispatch, digitalPlatforms]
  );

  const handleCheckboxChange = useCallback(
    (field, fieldIndex) => (selectedWorkingDays: Values) => {
      setValue(
        field.name,
        getUpdatedWorkingDays(
          selectedWorkingDays,
          getValues().contacts[fieldIndex].workingDays as Array<WorkingDay>
        ),
        {
          shouldValidate: true
        }
      );
    },
    []
  );

  const hoursNotFilled = (): boolean => {
    let isHoursNotFilled = false;
    getValues().contacts?.every((contact) => {
      isHoursNotFilled = !!contact.workingDays?.some(
        (wDay) => !wDay.workingFrom || !wDay.workingTo
      );
      if (!isHoursNotFilled) {
        return true;
      }
    });

    return isHoursNotFilled;
  };

  const createDigitalPlatformOptions = (platforms: DigitalPlatform[]): void => {
    if (!platforms) {
      return;
    }

    const digitalPlatforms: Array<Item> = [];
    platforms.forEach(platform => {
      const platformName = `${ platform.name } (${ platform.url })`;
      digitalPlatforms.push({ id: platformName, name: platformName });
    });

    setDigitalPlatformOptions(makeOptions(digitalPlatforms));
  };

  useEffect(() => {
    open && digitalPlatformsAPI(undefined, getBaseUrl(), API).getDigitalPlatforms()
      .then((response) => {
        setDigitalPlatforms(response.data.digitalPlatforms!);
        createDigitalPlatformOptions(response.data.digitalPlatforms!);
      })
      .catch((e) => {
        console.error("Error fetching digital platforms:", e);
      });
  }, [open]);

  return (
    <Modal
      isOpen={open}
      size="lg"
      className="contact-modal"
      unmountOnClose
    >
      <ModalHeader>
        <FormattedMessage
          id="GPContactInformationChangeModal.header"
          defaultMessage="Muuda nimistu kontaktandmeid"
        />
      </ModalHeader>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gp-contact-information-form"
      >
        <ModalBody>
          { fields.map((field, fieldIndex) => {
            const fieldName = "";
            const fieldId = `gpListContactsModalLicenses${ fieldIndex }`;
            const fieldError = licenseErrors[fieldIndex] ?? {};
            const fieldContactName = `${ fieldName }.contacts[${ fieldIndex }]`;
            const fieldContactId = `${ fieldId }contacts${ fieldIndex }`;
            const watchField = watch([`${ fieldContactName }.workingDays`]);

            return (
              <Fragment key={field.id}>
                <Fragment key={field.locationId}>
                  <Controller
                    name={`${ fieldContactName }.address`}
                    control={control}
                    defaultValue={field.address}
                    render={() => <h5 className="mb-4">{ field.address }</h5>}
                  />
                  <Controller
                    name={`${ fieldContactName }.locationId`}
                    control={control}
                    defaultValue={field.locationId}
                    as={<input type="hidden" />}
                  />
                  <FormGroup row>
                    <Label
                      htmlFor={`${ fieldContactId }-dialCode`}
                      className="text-xl-right text-md-right text-sm-left"
                      sm={4}
                    >
                      <ContactInfoFM id="phone" />
                    </Label>
                    <Col sm={8} className="d-flex">
                      <Controller
                        name={`contacts[${ fieldIndex }].dialCode`}
                        control={control}
                        defaultValue={field.dialCode}
                        render={({ onChange, value }) => (
                          <PhoneCodeSelector
                            dialCode={value}
                            updateDialCode={onChange}
                          />
                        )}
                      />
                      <div className="input-wrapper ml-2 flex-grow-1">
                        <Input
                          id={`${ fieldContactId }-dialCode`}
                          type="tel"
                          name={`${ fieldContactName }.phone`}
                          defaultValue={field.phone}
                          invalid={Boolean(fieldError.phone)}
                          innerRef={register({
                            validate: (value) =>
                              validateOptionalFieldNumber(value)
                          })}
                        />
                        <FormFeedback>
                          <FormattedErrorMessage id="phone" />
                        </FormFeedback>
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label
                      htmlFor={`${ fieldContactId }-email`}
                      className="text-xl-right text-md-right text-sm-left"
                      sm={4}
                    >
                      <ContactInfoFM id="email" />
                    </Label>
                    <Col sm={8}>
                      <Input
                        id={`${ fieldContactId }-email`}
                        type="email"
                        name={`${ fieldContactName }.email`}
                        defaultValue={field.email}
                        onBlur={handleEmailBlur}
                        onFocus={handleEmailFocus}
                        invalid={!isFocusedEmail && Boolean(fieldError.email)}
                        innerRef={register({
                          validate: (value) =>
                            value ? isInsertedEmailValid(value) : undefined
                        })}
                      />
                      <FormFeedback className="pt-2">
                        <FormattedErrorMessage id="email" />
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup noValidate row>
                    <Label
                      htmlFor={`${ fieldContactId }-homepage`}
                      className="text-xl-right text-md-right text-sm-left"
                      sm={4}
                    >
                      <ContactInfoFM id="website" />
                    </Label>
                    <Col sm={8}>
                      <Input
                        id={`${ fieldContactId }-homepage`}
                        type="url"
                        name={`${ fieldContactName }.homepage`}
                        onBlur={handleHomepageBlur}
                        onFocus={handleHomepageFocus}
                        defaultValue={field.homepage}
                        invalid={
                          Boolean(fieldError.homepage) && !isFocusedHomepage
                        }
                        innerRef={register({
                          validate: (value) =>
                            value ? validateURL(value) : undefined
                        })}
                      />
                      <FormFeedback className="pt-2">
                        <FormattedErrorMessage id="exampleAddress" />
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="required d-flex mb-0">
                    <Label
                      tag="span"
                      className="text-xl-right text-md-right text-sm-left"
                      sm={4}
                    >
                      <ContactInfoFM id="workingDays" />
                    </Label>

                    <Col xs={1} className="contact-modal-checkbox-group">
                      <Controller
                        control={control}
                        name={`${ fieldContactName }.workingDays`}
                        defaultValue={field.workingDays}
                        rules={{
                          validate: validateArray
                        }}
                        render={(
                          field: ControllerRenderProps<Record<string, any>>
                        ) => (
                          <CheckboxGroup
                            name={field.name}
                            options={workingDayOptions}
                            onChange={handleCheckboxChange(field, fieldIndex)}
                            value={field.value}
                            valuesKey="workingDay"
                            inline={false}
                            invalid={Boolean(fieldError.workingDays)}
                            className="checkbox-item"
                          />
                        )}
                      />
                    </Col>
                    <Col xs={6} className="d-flex flex-column ml-3">
                      <WorkingHours
                        options={workingDayOptions}
                        watchField={watchField}
                        fieldName={`${ fieldContactName }.workingDays`}
                        setValue={setValue}
                        fieldError={fieldError}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-4">
                    <Label sm={4}>
                      <div
                        className="d-flex-with-gap justify-content-xl-end justify-content-md-end justify-content-sm-start">
                        <ContactInfoFM id="digitalPlatform"/>
                        <FormattedToolTip
                          placement={"top"}
                          target={"digital-platform-info-icon"}
                          message={
                            <FormattedMessage
                              id="digital-platform.tooltip-content"
                              defaultMessage="Digiteenindusplatvorm on turvaline infotehnoloogiline iseteeninduse kanal patsiendi ja perearstikeskuse vahelises suhtluses."
                            />
                          }
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #D2D3D8",
                            borderRadius: "0",
                            color: "black",
                            maxWidth: "300px",
                            padding: "10px 10px",
                            textAlign: "left",
                          }}
                        />
                        <span
                          id="digital-platform-info-icon"
                          className="digital-platform-info-icon position-relative d-inline-block m-0"
                          tabIndex={0}
                          role="region"
                          aria-label="tooltip"
                        />
                      </div>
                    </Label>
                    <Col sm={8}>
                      <Controller
                        name={`contacts[${ fieldIndex }].digitalPlatform`}
                        control={control}
                        defaultValue={
                          field.digitalPlatform
                            ? `${ field.digitalPlatform.name } (${ field.digitalPlatform.url })`
                            : null
                        }
                        render={({ onChange, value }) => (
                          <DigitalPlatformSelector
                            digitalPlatform={value}
                            updateDigitalPlatform={onChange}
                            digitalPlatformOptions={digitalPlatformOptions}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                </Fragment>
              </Fragment>
            );
          }) }
        </ModalBody>
        <ModalFooter className="d-flex flex-column flex-md-row justify-content-sm-end">
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton
            id="saveIt"
            type="submit"
            disabled={!isValid || isSubmitting || hoursNotFilled()}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default GPListContactsModal;
