import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Card, Col, Row } from "reactstrap";
import { useHistory } from "react-router";

import "../../Style/Dashboard.scss";
import proceeding_drawing from "../../../../assets/images/proceedings.svg";
import { RootState } from "../../../rootReducer";
import PortalGpListManagement from "../GeneralPractitioners/PortalGPListManagement";
import { OfficialUserPrivilegesEnum } from "../../../../api_client/medre_api";
import { PortalDashboardButtonsManagement } from "./PortalDashboardButtonsManagement";

export const PortalDashboard = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();

  const isPerson = userInfo?.roles?.includes(OfficialUserPrivilegesEnum.Person);

  return (
    <>
      <div className="dashboard-banner justify-content-center">
        <span className="banner-text">
          {userInfo && (
            <FormattedMessage
              id="portalDashboard.welcomeMessage"
              defaultMessage={"Tere tulemast Terviseameti registrite iseteenindusse, {userName}!"}
              values={{ userName: userInfo.name }}
            />
          )}
        </span>
      </div>
      <Card className="create-proceeding-card p-3 p-xl-0">
        <Row>
          <Col
            xl={4}
            className="create-proceeding-card-media text-center text-xl-right pt-2 pt-xl-0 pr-xl-0 order-xl-last"
          >
            <img
              src={proceeding_drawing}
              alt="pilt avaldustest"
              className="content-container-card-img"
            />
          </Col>
          <Col
            xl={8}
            className="p-3 pb-5 pl-xl-4 order-xl-1 text-center text-xl-left"
          >
            <PortalDashboardButtonsManagement dispatch={dispatch} history={history} isPerson={isPerson} />
            <PortalGpListManagement />
          </Col>
        </Row>
      </Card>
    </>
  );
};
