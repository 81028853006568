import React from "react";
import Card from "reactstrap/lib/Card";

import { ApplicationDetailsInfoCard } from "../../../../../Component/Card/ApplicationDetailsInfoCard";
import { BusinessRegistryInfoCard } from "../../BusinessRegistryInfoCard";
import { DetailedDiscardServiceApplication } from "../../../../../../api_client/medre_api";
import { ClickableLicenseNumberCard } from "../../ClickableLicenseNumberCard";

interface Props {
  details: DetailedDiscardServiceApplication;
}

export const DetailsCard = ({ details }: Props) => {
  const {
    licenseNumber,
    licenseId,
    company,
    submittingPersonFirstName,
    submittingPersonLastName,
    submittingPersonIdCode,
    businessAreaName
  } = details;

  const submittingPersonName =
    submittingPersonFirstName + " " + submittingPersonLastName;

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" /> Teenusest loobumise taotluse detailid
      </h2>
      <BusinessRegistryInfoCard
        title={"Taotleja andmed"}
        id={company?.id}
        name={company?.name}
        address={company?.address}
        registryCode={company?.registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          { key: "Nimi", value: submittingPersonName || "-" },
          { key: "Isikukood", value: submittingPersonIdCode || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: company?.email || "-" },
          { key: "Telefon", value: company?.phone || "-" }
        ]}
      />
      <ClickableLicenseNumberCard
        licenseId={licenseId || ""}
        licenseNumber={licenseNumber || ""}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: businessAreaName || "-" }]}
      />
    </Card>
  );
};
