import React from "react";
import {
  ActivityLicenseStep,
  activityLicenseSteps
} from "../../Shared/ActivityLicense/ActivityLicenseStep";
import { ActivityLicenseBusinessData } from "../../Shared/ActivityLicense/BusinessData/ActivityLicenseBusinessData";
import { ActivityLicenseBusinessAreaStep } from "../../Shared/ActivityLicense/ActivityLicenseBusinessAreaStep";
import { Stepper } from "../../../Component/ProgressBar/Stepper";
import { Card } from "reactstrap";
import { WizardProgressBar } from "../../../Component/ProgressBar/WizardProgressBar";
import { FormattedMessage } from "react-intl";
import { ActivityLicenseFooter } from "../../Shared/ActivityLicense/ActivityLicenseFooter";
import "../../Shared/ActivityLicense/ActivityLicense.scss";
import { RootState } from "../../../rootReducer";
import { useSelector } from "react-redux";
import { getSelectedCompanyId } from "../../../Security/roleHelpers";
import { ActivityLicenseOverview } from "../../Shared/ActivityLicense/Overview/ActivityLicenseOverview";
import { NewActivityLicenseServices } from "../../Shared/ActivityLicense/NewActivityLicenseServices";
import { ActivityLicenseDocumentsContainer } from "../../Shared/ActivityLicense/Documents/ActivityLicenseDocumentsContainer";

export const PortalActivityLicenseContainer = () => {
  const currentStep = useSelector(
    (state: RootState) => state.activityLicenseApplication.currentStep || 0
  );
  const roles = useSelector((state: RootState) =>
    Object(state.user.userInfo?.roles)
  );
  const steps = activityLicenseSteps;

  const urls = {
    routes: {
      portal: "/activity-license-application",
      official: "/activity-license-applications"
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case ActivityLicenseStep.BUSINESS_DATA:
        return (
          <ActivityLicenseBusinessData
            registryCode={getSelectedCompanyId(roles)!}
          />
        );
      case ActivityLicenseStep.BUSINESS_AREA:
        return <ActivityLicenseBusinessAreaStep />;
      case ActivityLicenseStep.SERVICES:
        return <NewActivityLicenseServices />;
      case ActivityLicenseStep.DOCUMENTS:
        return <ActivityLicenseDocumentsContainer />;
      case ActivityLicenseStep.OVERVIEW:
        return <ActivityLicenseOverview />;
      default:
        return (
          <ActivityLicenseBusinessData
            registryCode={getSelectedCompanyId(roles)!}
          />
        );
    }
  };

  return (
    <div className="activity-license-container">
      <h1 className="ml-3">
        <FormattedMessage
          id="activityLicense.title"
          defaultMessage="Tegevusloa taotlemine"
        />
      </h1>

      <Stepper className={"m-3"} currentStep={currentStep || 0} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <ActivityLicenseFooter
            currentStep={currentStep || 0}
            steps={steps}
            urls={urls}
          />
        </div>
      </Card>
    </div>
  );
};
