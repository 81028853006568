import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { MultiSelect } from "../../../../Component/Select/MultiSelect";
import { Option } from "../../../../Component/Select/SelectTypes";
import { getBaseUrl, API } from "../../../../api";
import { RootState } from "../../../../rootReducer";
import * as Types from "../../../Shared/Search/Filters/FilterTypes";
import { filterActions } from "../../../Shared/Search/Filters/filterActions";
import { TaotluseAndmeteTeenusApiFactory as applicationDataAPI } from "../../../../../api_client/medre_api";
import { SpecialityListItem } from "../../../Shared/Search/Filters/FilterTypes";
import { FilterField } from "../../../../Component/Filter/Filter";

interface Props {
  id?: string;
  occupationValue: Option[];
  onOccupationChange: (options: Option[]) => void;
  specialityKey: string;
  specialityValue: Option[];
  onSpecialityChange: (options: Option[]) => void;
}

export const OccupationSpecialityFilter = ({
  id,
  occupationValue,
  onOccupationChange,
  specialityKey,
  specialityValue,
  onSpecialityChange
}: Props) => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: RootState) => rootState.filterOptions);

  const removeDuplicates = (option: {
    option: {value: string | undefined; label: string | undefined};
    occupation: string;
  }[]): {option: {value: string | undefined; label: string | undefined}; occupation: string}[] => {
    return option
      .filter((item: {option: {value: string | undefined; label: string | undefined}; occupation: string}, i, self) =>
        i === self.findIndex((t) => t.option.label === item.option.label));
  };

  React.useEffect(() => {
    const fetchOccupations = async () => {
      const { data } = await applicationDataAPI(
        undefined,
        getBaseUrl(),
        API
      ).getOccupations1(undefined, {
        withCredentials: true
      });
      return data;
    };

    if (state.allOccupations.length === 0) {
      fetchOccupations().then((data) => {
        const occupationList = data.map((item) => ({
          value: item.name,
          label: item.name
        }));
        const specialityList = data
          .map((item) =>
            item.specialities.map((spec) => ({
              option: { value: spec.code, label: spec.name },
              occupation: item.name
            }))
          )
          .flat();

        const uniqueSpecialities = removeDuplicates(specialityList);

        uniqueSpecialities.sort((a, b) =>
          (a.option.label as string).localeCompare(b.option.label as string)
        );
        dispatch(filterActions.setOccupations(occupationList));
        dispatch(
          filterActions.setSpecialities(uniqueSpecialities as SpecialityListItem[])
        );
      });
    }
  }, [dispatch, state.allOccupations]);

  React.useEffect(() => {
    if (state.allSpecialities.length > 0) {
      const filteredSpecialities = state.allSpecialities
        .filter((spec: Types.SpecialityListItem) => {
          if (occupationValue && occupationValue.length > 0) {
            const selected = occupationValue.map((item: Option) => item.value);
            return selected.includes(spec.occupation);
          }
          return true;
        })
        .map((spec) => spec.option);

      let filteredSpecialityOptions = filteredSpecialities.map(
        (spec) => spec.value
      );
      const existingOptions = specialityValue.filter((spec) =>
        filteredSpecialityOptions.includes(spec.value)
      );
      dispatch(filterActions.setFilteredSpecialities(filteredSpecialities));
      dispatch(
        filterActions.setApplicantFilter({
          key: specialityKey,
          data: existingOptions
        })
      );
    }
  }, [dispatch, state.allSpecialities, occupationValue]);

  const idPrefix = (Boolean(id) && `${ id }_`) ?? "";
  const occupationsId = `${ idPrefix }occupations`;
  const specialitiesId = `${ idPrefix }specialitiesId`;
  return (
    <>
      <FilterField
        id={occupationsId}
        label="Kutse nimi"
        field={
          <MultiSelect
            inputId={occupationsId}
            options={state.allOccupations}
            value={occupationValue}
            handleOptionsChange={onOccupationChange}
            placeholder={
              <FormattedMessage
                id="filters.selectOccupation"
                defaultMessage="Vali kutse"
              />
            }
          />
        }
      />
      <FilterField
        id={specialitiesId}
        label="Eriala nimi"
        field={
          <MultiSelect
            inputId={specialitiesId}
            options={state.filteredSpecialities}
            value={specialityValue}
            handleOptionsChange={onSpecialityChange}
            placeholder={
              <FormattedMessage
                id="filters.selectSpeciality"
                defaultMessage="Vali eriala"
              />
            }
          />
        }
      />
    </>
  );
};
