import { FormattedMessage } from "react-intl";
import React from "react";

export const activityLicenseServiceSteps = [
  <FormattedMessage key="activityLicenseStep1" id="activityLicense.services" defaultMessage="Teenused"/>,
  <FormattedMessage key="activityLicenseStep2" id="activityLicense.documents" defaultMessage="Dokumendid"/>,
  <FormattedMessage key="activityLicenseStep3" id="activityLicense.overview" defaultMessage="Esita taotlus"/>,
];

export const officialActivityLicenseServiceSteps = [
  <>Teenused</>,
  <>Dokumendid</>,
  <>Taotluse saabumise kanal</>,
  <>Esita taotlus</>
];

export enum ActivityLicenseServiceStep {
  SERVICES,
  DOCUMENTS,
  OVERVIEW
}

export enum OfficialActivityLicenseServiceStep {
  SERVICES,
  DOCUMENTS,
  APPLICATION_ARRIVAL_CHANNEL,
  OVERVIEW
}