import React from "react";

import { getBaseUrl, API } from "../../../api";
import {
  Speciality,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  PersonSearchFilter,
  PersonSearch
} from "../../../../api_client/medre_api";
import { Option } from "../../../Component/Select/SingleSelect";

export interface ApplicantOption extends Option {
  id: string;
  idCode: string;
  occupationCode: string;
  occupationName: string;
  firstName: string;
  lastName: string;
  specialities: Speciality[];
}
interface Filters {
  occupationCode?: string;
  fullNameOrOccupationCode?: string;
  fullNameOrOccupationCodeOrIdCode?: string;
}

export default async function fetchApplicantOptions(filters: Filters) {
  return (
    await userDataAPI(undefined, getBaseUrl(), API).searchDoctors(
      {
        ...filters,
        page: 0,
        size: 5
      } as PersonSearchFilter,
      {
        withCredentials: true
      }
    )
  ).data.content!.reduce<PersonSearch[]>(
    (
      acc,
      { firstName, lastName, occupationCodes, id, idCode, specialities }
    ) => [
      ...acc,
      ...occupationCodes!.map(
        ({ id: occupationId, code: occupationCode, name: occupationName }) => ({
          value: occupationId,
          label: (
            <>
              <b>
                {firstName} {lastName}
              </b>
              {` - ${occupationName} (${occupationCode})`}
            </>
          ),
          id: id!,
          idCode,
          firstName,
          lastName,
          occupationCode,
          occupationName,
          specialities
        })
      )
    ],
    []
  );
}
