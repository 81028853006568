import React, { useCallback } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { ContactInfoFM } from "../../../Messages/ContactInfoFM";
import {
  isInsertedEmailValid,
  validateOptionalFieldNumber
} from "../Validation/fieldValidator";
import { FormattedErrorMessage } from "../../../Component/ErrorField/FormattedErrorMessage";
import { prettifyRoleName } from "../../../Dto/Company/Company";
import { getBaseUrl, API } from "../../../api";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { PersonalDataFM } from "../../../Messages/PersonalDataFM";
import { displayAlert } from "../../../Util/AlertUtil";
import {
  Representative,
  EttevteteAndmetegaSeotudTegevusedApiFactory as activitieCorporateDataAPI
} from "../../../../api_client/medre_api";
import { getFullName } from "../../../Util/PersonUtils";

interface Props {
  representative: Representative;
  registryCode: string;
  isOpen: boolean;
  onClose: () => void;
  refresh: () => void;
}

export const EditRepresentativeContactInfoModal = ({
  representative,
  registryCode,
  isOpen,
  onClose,
  refresh
}: Props) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm<Representative>({
    mode: "onBlur"
  });

  const onSubmit = useCallback(
    async (data: Representative) => {
      try {
        await activitieCorporateDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).updateRepresentativeContactInfo(
          registryCode,
          representative.id!,
          data,
          {
            withCredentials: true
          }
        );

        onClose();
        refresh();
        displayAlert("contactInfoUpdateSuccess", AlertType.Success, dispatch);
      } catch (e) {
        displayAlert("requestFailed", AlertType.Danger, dispatch);
      }
    },
    [representative.id, registryCode, onClose, refresh, dispatch]
  );

  return (
    <Modal isOpen={isOpen} size="xl">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <FormattedMessage
            id="editRepresentativeContactInfoModal.header"
            defaultMessage="Ettevõtte esindaja"
          />
        </ModalHeader>
        <ModalBody>
          <FormGroup row={true}>
            <Label className="text-xl-right text-md-right text-sm-left" sm={3}>
              <PersonalDataFM id="name" />
            </Label>
            <Col sm={9} className="my-auto">
              <b>{getFullName(representative)}</b>
            </Col>
            <Label className="text-xl-right text-md-right text-sm-left" sm={3}>
              <PersonalDataFM id="personalCode" />
            </Label>
            <Col sm={9} className="my-auto">
              <b>{representative.idCode}</b>
            </Col>
            <Label className="text-xl-right text-md-right text-sm-left" sm={3}>
              <PersonalDataFM id="role" />
            </Label>
            <Col sm={9} className="my-auto mb-5">
              <b>{prettifyRoleName(representative.personRole)}</b>
            </Col>
            <Label className="text-xl-right text-md-right text-sm-left" sm={3}>
              <ContactInfoFM id="phone" />
            </Label>
            <Col sm={9} className="d-flex">
              <Input
                type="tel"
                name="phone"
                defaultValue={representative.phone}
                invalid={!!errors.phone}
                innerRef={register({
                  validate: (value) => validateOptionalFieldNumber(value)
                })}
              />
              <FormFeedback>
                <FormattedErrorMessage id="phone" />
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Label className="text-xl-right text-md-right text-sm-left" sm={3}>
              <ContactInfoFM id="email" />
            </Label>
            <Col sm={9}>
              <Input
                type="email"
                name="email"
                defaultValue={representative.email}
                invalid={!!errors.email}
                innerRef={register({
                  validate: (value) =>
                    value ? isInsertedEmailValid(value) : undefined
                })}
              />
              <FormFeedback className="pt-2">
                <FormattedErrorMessage id="email" />
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-column flex-md-row justify-content-sm-end">
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
          />
          <PrimaryFormattedButton id="saveIt" type="submit" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
