import React, { useCallback, useMemo, useState } from "react";
import { Column, HeaderGroup, useSortBy, useTable } from "react-table";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import sort from "../../../../../assets/images/sort.svg";
import sortActive from "../../../../../assets/images/sort_active.svg";
import {
  DetailViewLinkButton,
  LinkButton
} from "../../../../Component/Button/LinkButton";
import {
  ActivityLicenseProceeding,
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialApplDataService,
  ApplicationOfficialRelationDtoProceedingTypeEnum as ProceedingTypeEnum,
  ApplicationOfficialRelationDtoRelationTypeEnum
} from "../../../../../api_client/medre_api";
import { isProceedingStatus } from "../../../../Dto/ActivityLicense/ActivityLicense";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { RootState } from "../../../../rootReducer";

export interface Props {
  data: ActivityLicenseProceeding[];
  refresh: () => void;
}

interface ConfirmModalType {
  isOpen: boolean;
  id: string;
  proceedingType?: ProceedingTypeEnum;
}

export const ProceedingTable = ({ data, refresh }: Props) => {
  const [hidingAppConfirmModal, setHidingAppConfirmModal] =
    useState<ConfirmModalType>({ isOpen: false, id: "" });
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user.userInfo?.userId);
  const proceedingOfficialId = "proceedingOfficialId";

  const getProceedingPausedDays = (proceedingPausedDays?: number) => {
    switch (proceedingPausedDays) {
      case 0:
        return "";
      case 1:
        return <span className="ml-3">Peatatud 1 päev</span>;
      default:
        return (
          <span className="ml-3">Peatatud { proceedingPausedDays } päeva</span>
        );
    }
  };

  const getDetailsLink = (
    proceedingType: ActivityLicenseApplicationProceedingTypeEnum,
    id: string
  ) => {
    let prefix;
    switch (proceedingType) {
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense:
      default:
        prefix = "/activity-license-applications/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation:
        prefix = "/activity-license-invalidations/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation:
        prefix = "/activity-license-invalidations/location/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
        prefix = "/activity-license-suspensions/location/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
        prefix = "/activity-license-suspensions/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
        prefix = "/activity-license-suspensions/service/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition:
        prefix = "/license-acquisitions/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard:
        prefix = "/discard-services/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration:
        prefix = "/license-restorations/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration:
        prefix = "/location-restorations/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration:
        prefix = "/service-restorations/";
        break;
      case ActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication:
        prefix = "/general-practitioner-application/";
        break;
    }
    return prefix + id;
  };

  const handleHidingAppModalOpen = (application: ActivityLicenseProceeding) => {
    setHidingAppConfirmModal({
      isOpen: true,
      id: application.id as string,
      proceedingType: application.proceedingType
    });
  };

  const handleAppHide = () => {
    return officialApplDataService(undefined, getBaseUrl(), API)
      .addApplicationOfficialRelation(hidingAppConfirmModal.id, {
        applicationId: hidingAppConfirmModal.id,
        proceedingType:
          hidingAppConfirmModal.proceedingType as ProceedingTypeEnum,
        relationType: ApplicationOfficialRelationDtoRelationTypeEnum.Hidden
      })
      .then(() => {
        const alertMessage = <AlertMessage id="applicationHideSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        handleAppHideModalClose();
        refresh();
        return Promise.resolve();
      })
      .catch((error) => {
        const alertMessage = <AlertMessage id="applicationHideFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error);
      });
  };

  const handleAppHideModalClose = () => {
    setHidingAppConfirmModal({ isOpen: false, id: "" });
  };

  const hideButtonExists = useCallback(
    (currentUserId, fields) =>
      currentUserId === fields.proceedingOfficialId &&
      (fields.applicationStatus === ActivityLicenseApplicationStatus.Accepted ||
        fields.applicationStatus ===
        ActivityLicenseApplicationStatus.Finished ||
        fields.applicationStatus === ActivityLicenseApplicationStatus.Declined),
    [data]
  );

  const columns: Column<ActivityLicenseProceeding>[] = useMemo(
    () => [
      {
        Header: "Esitatud",
        accessor: "arrivalTime",
        Cell: ({ value }) => <div className="ml-2">{ formatDate(value) }</div>,
        minWidth: 100
      },
      {
        Header: "Tegevusala",
        accessor: "businessArea"
      },
      {
        Header: "Taotluse liik",
        accessor: "proceedingType",
        Cell: ({ cell }) => (
          <>{ formatProceedingType(cell.row.values.proceedingType) }</>
        )
      },
      {
        Header: "Taotluse olek",
        accessor: "applicationStatus",
        Cell: ({ cell }) => (
          <>
            <ActivityLicenseApplicationState
              currentStatus={cell.row.values.applicationStatus}
              inTable
              isStateFeePaid={cell.row.original.stateFeeIsPaid}
            />
            { getProceedingPausedDays(cell.row.original?.proceedingPausedDays) }
          </>
        )
      },
      {
        Header: "Tähtaeg",
        accessor: "proceedingDeadline",
        Cell: ({ cell }) => (
          <div>
            { isProceedingStatus(cell.row.values.applicationStatus) &&
              formatDate(cell.row.values.proceedingDeadline) }
          </div>
        ),
        minWidth: 100,
        sortType: (rowA, rowB) => {
          if (
            !isProceedingStatus(
              rowA.values.applicationStatus ||
              rowA.values.proceedingDeadline === null
            )
          ) {
            return -1;
          }
          if (
            !isProceedingStatus(rowB.values.applicationStatus) ||
            rowB.values.proceedingDeadline === null
          ) {
            return 1;
          }
          return rowA.values.proceedingDeadline > rowB.values.proceedingDeadline
            ? 1
            : -1;
        }
      },
      {
        Header: "Taotleja",
        accessor: "businessName"
      },
      {
        Header: "Taotluse number",
        accessor: "applicationNumber"
      },
      {
        accessor: proceedingOfficialId,
        Cell: ({ cell }) =>
          hideButtonExists(userId, cell.row.values) ? (
            <LinkButton
              id="hide"
              onClick={() => handleHidingAppModalOpen(cell.row.values)}
            />
          ) : null,
        sortType: (rowA, rowB) => {
          const firstRow: string = hideButtonExists(userId, rowA.values)
            ? "hide"
            : "";
          const secondRow: string = hideButtonExists(userId, rowB.values)
            ? "hide"
            : "";

          return firstRow.localeCompare(secondRow);
        }
      },
      {
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="link-column float-right">
            <DetailViewLinkButton
              id="detailsLink"
              to={getDetailsLink(
                cell.row.values.proceedingType,
                cell.row.values.id
              )}
            />
          </div>
        )
      }
    ],
    [userId]
  );

  const generateSortingIndicator = (
    column: HeaderGroup<ActivityLicenseProceeding>
  ) => {
    let columnClass = "mr-1 ml-0";

    if (column.id === "arrivalTime") {
      columnClass = "ml-1";
    } else if (column.id === proceedingOfficialId) {
      columnClass = "ml-0 mr-0";
    }

    return column.isSorted ? (
      <img
        className={`${ columnClass } sort-indicator`}
        src={sortActive}
        alt="sort"
      />
    ) : (
      <img className={`${ columnClass } sort-indicator`} src={sort} alt="sort" />
    );
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy
    );

  return (
    <>
      <div className="table-wrap">
        <div className="scrollable" style={{ marginRight: "0" }}>
          <table {...getTableProps()}>
            <thead style={{ display: "table-header-group" }}>
              { headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  { headerGroup.headers.map((column) => (
                  // tslint:disable-next-line:jsx-key
                    <th
                      className={
                        column.id === proceedingOfficialId
                          ? "proceeding-hidden-button-header"
                          : ""
                      }
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      { column.id !== "id" &&
                      column.id !== "applicationHiddenStatus" &&
                      generateSortingIndicator(column) }{ " " }
                      { column.render("Header") }
                    </th>
                  )) }
                </tr>
              )) }
            </thead>
            <tbody {...getTableBodyProps()}>
              { rows.map((row, i: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    { row.cells.map((cell, j: number) => (
                      <td className="pl-4" {...cell.getCellProps()}>
                        { cell.render("Cell") }
                      </td>
                    )) }
                  </tr>
                );
              }) }
            </tbody>
            <tfoot>
              <tr/>
            </tfoot>
          </table>
        </div>
      </div>
      <ConfirmationModal
        isOpen={hidingAppConfirmModal.isOpen}
        modalId={hidingAppConfirmModal.id}
        title={
          <FormattedMessage
            id="application.hide.confirm.modal.title"
            defaultMessage="Olete Te kindel, et soovite taotluse nimekirjast peita?"
          />
        }
        saveButtonId="confirmHide"
        closeButtonId="cancelHide"
        onAsyncSave={handleAppHide}
        onClose={handleAppHideModalClose}
      >
        <FormattedMessage
          id="application.hide.confirm.modal.content"
          defaultMessage='Taotluse peitmisel eemaldatakse taotlus "Minu menetlused" nimekirjast.'
        />
      </ConfirmationModal>
    </>
  );
};
