import {
  FileObjectType,
  Application,
  Decision
} from "../../../api_client/medre_api";

// export enum DecisionType {
//   APPLICATION = "APPLICATION",
//   PERSON_SUSPENSION = "PERSON_SUSPENSION",
//   ACTIVITY_LICENSE = "ACTIVITY_LICENSE",
//   ACTIVITY_LICENSE_APPLICATION = "ACTIVITY_LICENSE_APPLICATION",
//   ACTIVITY_LICENSE_INVALIDATION = "ACTIVITY_LICENSE_INVALIDATION",
//   ACTIVITY_LOCATION_INVALIDATION = "ACTIVITY_LOCATION_INVALIDATION",
//   LICENSE_ACQUISITION = "LICENSE_ACQUISITION",
//   ACTIVITY_LOCATION_SUSPENSION = "ACTIVITY_LOCATION_SUSPENSION",
//   SERVICE_SUSPENSION = "SERVICE_SUSPENSION",
//   ACTIVITY_LICENSE_SUSPENSION = "ACTIVITY_LICENSE_SUSPENSION",
//   DISCARD_SERVICE = "DISCARD_SERVICE",
//   ACTIVITY_LICENSE_RESTORATION = "ACTIVITY_LICENSE_RESTORATION",
//   ACTIVITY_LOCATION_RESTORATION = "ACTIVITY_LOCATION_RESTORATION",
//   SERVICE_RESTORATION = "SERVICE_RESTORATION",
//   GENERAL_PRACTITIONER_LIST_APPLICATION = "GENERAL_PRACTITIONER_LIST_APPLICATION",
//   ADD_SERVICE = "ADD_SERVICE",
//   ADD_LOCATION = "ADD_LOCATION"
// }

export const filterDecisions = (
  decisions: Decision[],
  fileTypes: FileObjectType[]
): Decision[] =>
  decisions?.filter((d) =>
    fileTypes.includes(d.fileObjectType as FileObjectType)
  );

export const getApplicationFinalDecision = (
  application: Application
): Decision[] => {
  const fileTypes = [
    FileObjectType.ApplicationAcceptanceDecision,
    FileObjectType.ApplicationDeclinationDecision
  ];
  return filterDecisions(application.decisions || [], fileTypes);
};
