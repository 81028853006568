import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../rootReducer";
import { searchResultsActions } from "../searchResultsActions";
import "./TableFooter.scss";
import leftIcon from "../../../../../assets/images/arrow_left.svg";
import rightIcon from "../../../../../assets/images/arrow_right.svg";

interface Props {
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  goToPage: (updater: ((pageIndex: number) => number) | number) => void;
}

export const TableFooter = ({
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  goToPage
}: Props) => {
  const state = useSelector((rootState: RootState) => rootState.searchResults);
  const dispatch = useDispatch();

  const setSize = (size: number): void => {
    dispatch(searchResultsActions.setSize(size));
  };

  const setCurrentPage = (index: number): void => {
    dispatch(searchResultsActions.setIndex(index));
    goToPage(index);
  };

  const renderPagesBlock = () => {
    const { pageIndex, pageCount } = state;
    let pageButtons: number[];
    if (pageCount <= 7) {
      pageButtons = Array.from(Array(pageCount).keys());
    } else {
      if (pageIndex > 3) {
        if (pageIndex + 4 < pageCount) {
          pageButtons = [
            0,
            -1,
            pageIndex - 1,
            pageIndex,
            pageIndex + 1,
            -1,
            pageCount - 1
          ];
        } else {
          pageButtons = [
            0,
            -1,
            pageCount - 5,
            pageCount - 4,
            pageCount - 3,
            pageCount - 2,
            pageCount - 1
          ];
        }
      } else {
        pageButtons = [0, 1, 2, 3, 4, -1, pageCount - 1];
      }
    }
    return renderPageButtons(pageButtons);
  };

  const renderPageButtons = (pageButtons: number[]) => {
    if (pageButtons.length === 0) {
      pageButtons.push(0);
    }
    return pageButtons.map((page, i) => {
      if (page === -1) {
        return (
          <button className={"nav-button-page"} disabled={true} key={i}>
            ...
          </button>
        );
      } else {
        return (
          <button
            onClick={() => {
              setCurrentPage(page);
            }}
            key={i}
            className={`nav-button-page ${
              page === state.pageIndex ? "current" : ""
            }`}
          >
            {page + 1}
          </button>
        );
      }
    });
  };

  return (
    <div className="table-footer">
      <div className="pagination-wrapper">
        <button
          onClick={() => {
            previousPage();
            setCurrentPage(state.pageIndex - 1);
          }}
          disabled={!canPreviousPage}
          className={`nav-button-arrow ${!canPreviousPage ? "disable" : ""}`}
        >
          <img src={leftIcon} alt="previous" />
        </button>
        {renderPagesBlock()}
        <button
          onClick={() => {
            nextPage();
            setCurrentPage(state.pageIndex + 1);
          }}
          disabled={!canNextPage}
          className={`nav-button-arrow ${!canNextPage ? "disable" : ""}`}
        >
          <img src={rightIcon} alt="next" />
        </button>
      </div>
      <div className="page-count-wrapper">
        Kuvan korraga:
        <select
          className={"per-page-number"}
          value={state.pageSize}
          onChange={(e) => setSize(Number(e.target.value))}
        >
          {[1, 2, 5, 10, 20, 30, 40, 50].map((rowsPerPage) => (
            <option key={rowsPerPage} value={rowsPerPage}>
              {rowsPerPage}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
