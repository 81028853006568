import React, { FC } from "react";
import "./QuickLinks.scss";
import { FormattedMessage } from "react-intl";
import { ProtectedComponent } from '../../Security/ProtectedComponent';

type Link = {
  id: string;
  title: string | React.ReactElement;
};

interface QuickLinksProps {
  title?: string;
  links: Link[];
  companyOwnerOnly?: boolean;
}

const defaultTitle = (
  <FormattedMessage
    id="QuickLinks.title"
    defaultMessage="Hüppa kiirelt:"
  />
);

export const QuickLinks: FC<QuickLinksProps> = ({
  title = defaultTitle,
  links = [],
  companyOwnerOnly = false
}) => (
  <>
    {companyOwnerOnly && (
      <ProtectedComponent directRepresentativeOnly={true}>
        <div className="mb-2 font-size-18">{title}</div>
        <ul className="quick-links">
          {links.map((link) => (
            <li key={link.id} className="d-block my-2">
              <a href={`#${link.id}`}>{link.title}</a>
            </li>
          ))}
        </ul>
      </ProtectedComponent>
    )
    }
    {!companyOwnerOnly && (
      <>
        <div className="mb-2 font-size-18">{title}</div>
        <ul className="quick-links">
          {links.map((link) => (
            <li key={link.id} className="d-block my-2">
              <a href={`#${link.id}`}>{link.title}</a>
            </li>
          ))}
        </ul>
      </>
    )
    }
  </>
);
