import React, { Dispatch } from "react";
import { AxiosError, AxiosPromise } from "axios";

import { AlertType } from "../../Dto/Alert/AlertItem";
import { AlertMessage } from "../../Alert/AlertMessage";
import { alertActions } from "../../Alert/alertActions";
import {
  FileObjectType,
  FileReference,
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI,
  PowerOfAttorney,
  FileDeleteType,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialLicenseApplicationAPI,
  TegevuslubadeTaotlusteTeenusApiFactory as licenseApplicationAPI,
  TegevuslubadeLevtmisteTeenusApiFactory as licenseAcquisitionAPI,
  AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFactory as officialLicenseAcquisitionApplicationAPI
} from "../../../api_client/medre_api";
import { getBaseUrl, API } from "../../api";
import { ErrorResponseData } from "../../Dto/Error/Error";

export const saveFiles = (
  files: File[],
  fileObjectType: FileObjectType,
  objectId?: string,
  saveEndpointOverride?: (
    registryCode: string,
    file: any[],
    options?: any
  ) => AxiosPromise<PowerOfAttorney[] | FileReference[]>,
  registryCode?: string
) => {
  return saveEndpointOverride
    ? saveEndpointOverride(registryCode!, files, {
        withCredentials: true
      })
    : fileUploadAPI(undefined, getBaseUrl(), API).uploadFile(
        objectId!,
        fileObjectType,
        files,
        {
          withCredentials: true
        }
      );
};
export const saveFilesWithAlerts = (
  files: File[],
  fileObjectType: FileObjectType,
  // tslint:disable-next-line:no-any
  objectId: string | undefined,
  dispatch: Dispatch<any>,
  onUploadSuccess: ((files: FileReference[]) => void) | undefined,
  saveEndpointOverride?: (
    registryCode: string,
    file: any[],
    options?: any
  ) => AxiosPromise<PowerOfAttorney[] | FileReference[]>,
  registryCode?: string
) => {
  saveFiles(files, fileObjectType, objectId, saveEndpointOverride, registryCode)
    .then((response) => {
      const alertMessage = <AlertMessage id="fileUploadSuccessful" />;
      const alert = { id: 0, type: AlertType.Success, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      if (onUploadSuccess) {
        onUploadSuccess(response.data as FileReference[]);
      }
    })
    .catch((error: AxiosError) => {
      let responseData = error.response?.data as ErrorResponseData;
      let alertMessage, alert;
      if (responseData.error === "MaxUploadSizeExceededException") {
        alertMessage = <AlertMessage id="maxFileUploadSizeExceeded" />;
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      }
      if (responseData.error === "FileExtensionException") {
        alertMessage = <AlertMessage id="fileExtensionNotAllowed" />;
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      }
      alertMessage = <AlertMessage id="fileUploadFailure" />;
      alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      return dispatch(alertActions.addAlert(alert));
    });
};

export const deleteFunctions = {
  official: {
    [FileDeleteType.ActivityLicenseApplication]: (id: string, fileObjectType: FileObjectType, fileId: string) =>
      officialLicenseApplicationAPI(undefined, getBaseUrl(), API)
        .deleteOfficialActivityLicenseApplicationFile(id, fileObjectType, fileId, { withCredentials: true}),
    [FileDeleteType.LicenseAcquisition]: (id: string, fileObjectType: FileObjectType, fileId: string) =>
      officialLicenseAcquisitionApplicationAPI(undefined, getBaseUrl(), API)
        .deleteOfficialLicenseAcquisitionFile(id, fileObjectType, fileId, { withCredentials: true})
  },
  portal: {
    [FileDeleteType.ActivityLicenseApplication]: (id: string, fileObjectType: FileObjectType, fileId: string) =>
      licenseApplicationAPI(undefined, getBaseUrl(), API)
        .deleteActivityLicenseApplicationFile(id, fileObjectType, fileId, { withCredentials: true}),
    [FileDeleteType.LicenseAcquisition]: (id: string, fileObjectType: FileObjectType, fileId: string) =>
      licenseAcquisitionAPI(undefined, getBaseUrl(), API)
        .deleteLicenseAcquisitionFile(id, fileObjectType, fileId, { withCredentials: true})
  }
}
