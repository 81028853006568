import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { getBaseUrl, API } from "../../../../../../api";
import { AlertMessage } from "../../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../../Alert/alertActions";
import { Loader } from "../../../../../../Component/Loader/Loader";
import {
  RadioButtonElement,
  RadioGroup
} from "../../../../../../Component/Radio/RadioGroup";
import {
  AmetnikeTeenusAmetnikeleApiFactory as officialServiceAPI,
  OfficialUserPrivilegesEnum,
  OfficialUser
} from "../../../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  forwardToOfficial: (official: OfficialUser) => void;
}

export const OfficialForwardingModal = ({
  isOpen,
  onClose,
  forwardToOfficial
}: Props) => {
  const [officials, setOfficials] = useState<OfficialUser[]>([]);
  const [selectedOfficial, setSelectedOfficial] = useState<OfficialUser>();
  const HAS_ONLY_VISIBLE_OFFICIALS = true;

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedOfficial(undefined);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && officials.length === 0) {
      officialServiceAPI(undefined, getBaseUrl(), API)
        .getOfficialsWithPrivilege(OfficialUserPrivilegesEnum.Proceede, HAS_ONLY_VISIBLE_OFFICIALS, {
          withCredentials: true
        })
        .then((res) => setOfficials(res.data as OfficialUser[]))
        .catch(() => {
          const alertMessage = <AlertMessage id="requestFailed" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    }
  }, [isOpen, officials, dispatch]);

  const mapOfficialsToRadioButtonElement = (officialArray: OfficialUser[]) =>
    officialArray.map((official) => ({
      id: official.id,
      selected: selectedOfficial === official,
      value: official.id,
      labelText: official.firstName + " " + official.lastName
    }));

  const handleOfficialSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const id = event.target.value;
    setSelectedOfficial(officials.find((official) => official.id === id));
  };

  if (!officials) {
    return <Loader />;
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Suuna menetlejale</ModalHeader>
      <ModalBody>
        <RadioGroup
          options={
            mapOfficialsToRadioButtonElement(officials) as RadioButtonElement[]
          }
          value={selectedOfficial?.id}
          name="officialName"
          className="d-flex flex-column flex-wrap official-selection-buttons"
          onChange={handleOfficialSelectionChange}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="backToApplication" onClick={onClose} />
        <PrimaryFormattedButton
          id="forwardToOfficial"
          disabled={!selectedOfficial}
          onClick={() => forwardToOfficial(selectedOfficial!)}
        />
      </ModalFooter>
    </Modal>
  );
};
