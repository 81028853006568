import { Card, Col, Container, Row } from "reactstrap";
import React from "react";
import { FormattedMessage } from "react-intl";
import doctors from "../../../../assets/images/undraw_doctors_hwty.svg";
import doctor from "../../../../assets/images/undraw_doctor_kw5l.svg";
import building from "../../../../assets/images/undraw_Building_vpx.svg";
import "./PublicHome.scss";
import { Link } from "react-router-dom";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import {
  publicSearchEmployeesUrl,
  publicSearchGPListsUrl,
  publicSearchLicensesUrl,
} from "./PublicSearch";

export const PublicHome = () => {
  return (
    <>
      <div className="dashboard-banner justify-content-center">
        <span className="banner-text mt-2 mb-1 dis">
          <FormattedMessage
            id="portalHomePage.welcomeMessage"
            defaultMessage="Tere tulemast Terviseameti registrite, perearsti nimistute ja iseteeninduse avalikule veebilehele"
          />
        </span>
      </div>
      <Container>
        <Card className="content-container-card">
          <Row className="content-container-card-large-text home-title-margins">
            <Col sm={10}>
              <div className="home-title">
                <FormattedMessage
                  id="portalHomePage.introduction"
                  defaultMessage="Tutvustus"
                />
              </div>
            </Col>
          </Row>
          <Row
            width="80"
            className="content-container-card-medium-text home-description-margins"
          >
            <Col>
              <div className="home-description-message">
                <FormattedMessage
                  id="portalHomePage.welcomeText"
                  defaultMessage="Selle lehe eesmärk on Teid varustada tervishoiu valdkonna riiklikes registrites ja perearsti nimistutes olevate andmetega, mis on vajalikud tervishoiuteenuse või apteegiteenuse õiguspärasuse tuvastamiseks ning millele avaliku teabe seadus ja isikuandmete kaitse seadus ei näe ette juurdepääsupiirangute kehtestamist."
                />
              </div>
            </Col>
          </Row>
        </Card>
        <Row className="m-sm-2 p-0 p-lg-3 px-lg-5 justify-content-center justify-content-md-between">
          <Col
            xl={3}
            lg={5}
            md={5}
            xs={10}
            className="p-0 mb-5 d-flex flex-column align-content-center align-content-md-start ml-md-5"
          >
            <div className="d-flex m-auto m-md-0">
              <img src={doctors} alt="doctors" />
            </div>

            <p className="application-type-selection text-center text-md-left">
              <FormattedMessage
                id="portalHomePage.healthCareDescription"
                defaultMessage="Kontrolli, kas tervishoiutöötaja tohib kutsealal/erialal tegutseda ning leia infot töökohtade ja pädevuse tunnustamiste kohta."
              />
            </p>
            <Link
              className="m-auto m-md-0"
              to={`/search/${publicSearchEmployeesUrl}`}
            >
              <PrimaryFormattedButton id="healthCareProfessionals" />
            </Link>
          </Col>
          <Col
            xl={3}
            lg={5}
            md={5}
            xs={10}
            className="mb-5 d-flex flex-column align-content-center align-content-md-start mr-md-5 mr-xl-0"
          >
            <div className="d-flex m-auto m-md-0">
              <img src={doctor} alt="doctor" />
            </div>
            <p className="application-type-selection text-center text-md-left">
              <FormattedMessage
                id="portalHomePage.familyDoctor"
                defaultMessage="Kasuta perearstinimistut, et leida endale uus perearst, uurida perearsti tööaegu, leia ta kontaktandmed jpm..."
              />
            </p>
            <Link
              className="m-auto m-md-0"
              to={`/search/${publicSearchGPListsUrl}`}
            >
              <PrimaryFormattedButton id="familyMedicine" />
            </Link>
          </Col>
          <Col
            xl={3}
            lg={5}
            md={5}
            xs={10}
            className="mb-5 d-flex flex-column align-content-center align-content-md-start ml-md-5 mr-xl-5"
          >
            <div className="d-flex m-auto m-md-0">
              <img src={building} alt="building" />
            </div>
            <p className="application-type-selection text-center text-md-left">
              <FormattedMessage
                id="portalHomePage.licences"
                defaultMessage="Raviasutuste tegevuslubade registrist leiad informatsiooni töötajate kohta, teenuste ning lubade kehtivuse kohta."
              />
            </p>
            <Link
              className="m-auto m-md-0"
              to={`/search/${publicSearchLicensesUrl}`}
            >
              <PrimaryFormattedButton id="licences" />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};
