import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";

interface Props {
  applicationNumber: number;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const ApplicationDeleteConfirmationModal = ({
  applicationNumber,
  isOpen,
  onClose,
  onDelete
}: Props) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>
      <FormattedMessage
        id="ApplicationDeleteConfirmationModal.header"
        defaultMessage="Olete Te kindel, et soovite taotlust {applicationNumber} kustutada?"
        values={{ applicationNumber: applicationNumber }}
      />
    </ModalHeader>
    <ModalBody>
      <FormattedMessage
        id="ApplicationDeleteConfirmationModal.body"
        defaultMessage="Taotluse kustutamisel kõik taotlusega seonduv informatsioon kustutatakse ära."
      />
    </ModalBody>
    <ModalFooter>
      <SecondaryFormattedButton
        id="backToApplications"
        onClick={() => onClose()}
      />
      <PrimaryFormattedButton
        id="deleteApplication"
        onClick={() => onDelete()}
      />
    </ModalFooter>
  </Modal>
);
