import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../../../../api";
import { AlertMessage } from "../../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../../Alert/alertActions";
import { SuspensionActionsContent } from "../../SuspensionActionsContent";
import {
  DetailedActivityLocationSuspension,
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI,
  OfficialUser,
  ActivityLicenseSuspensionStatus,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityAppAPI
} from "../../../../../../../api_client/medre_api";

interface Props {
  details?: DetailedActivityLocationSuspension;
  setDetails: (locationSuspension: DetailedActivityLocationSuspension) => void;
  refresh: () => void;
  setIsLoading: (value: boolean) => void;
}

export const LocationSuspensionDetailsActions = ({
  details,
  setDetails,
  refresh,
  setIsLoading
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleForwardToOfficial = (official: OfficialUser) => {
    setIsLoading(true);
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .forwardLocationSuspensionToOfficial(details!.id!, official, {
        withCredentials: true
      })
      .then((res) => {
        const alertMessage = <AlertMessage id="applicationForwardingSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setDetails(res.data);
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="applicationForwardingFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => setIsLoading(false));
  };

  const changeStatusTo = (
    newStatus:
      | ActivityLicenseApplicationStatus
      | ActivityLicenseSuspensionStatus
  ) => {
    setIsLoading(true);
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .changeLocationSuspensionStatus(
        details!.id!,
        newStatus as ActivityLicenseSuspensionStatus,
        {
          withCredentials: true
        }
      )
      .then((res) => {
        const alertMessage = <AlertMessage id="statusUpdateSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setDetails(res.data);
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="statusUpdateFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteApplication = (applicationId: string) => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .deleteApplicationByProceedingType(
        applicationId,
        details?.suspensionType!,
        {
          withCredentials: true
        }
      )
      .then(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteSuccess" />
        );
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        history.push("/my-proceedings");
      })
      .catch(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteFailure" />
        );
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  return (
    <SuspensionActionsContent
      changeStatusTo={changeStatusTo}
      refresh={refresh}
      handleForwardToOfficial={handleForwardToOfficial}
      proceedingType={
        ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension
      }
      details={details}
      deleteApplication={deleteApplication}
    />
  );
};
