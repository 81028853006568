import React, { useState } from "react";

import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import { SuspensionModal } from "./SuspensionModal";
import "./SuspensionDetails.scss";
import { SuspensionInfo } from "./SuspensionInfo";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import {
  PersonSuspension,
  Person,
  PersonStatusEnum,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";

interface Props {
  personId: string;
  personStatus: PersonStatusEnum;
  suspensions?: PersonSuspension[];
  updateSuspensions: (suspensions: PersonSuspension[]) => void;
  updatePerson: (person: Person) => void;
  refresh: () => void;
}

export const SuspensionDetails = ({
  personId,
  personStatus,
  suspensions,
  updateSuspensions,
  updatePerson,
  refresh
}: Props) => {
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);

  const handleSuspensionDeletion = (index: number) => {
    let newSuspensions = suspensions ? [...suspensions] : [];
    newSuspensions.splice(index, 1);
    updateSuspensions(newSuspensions);
  };

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.Suspension}
      classNames="suspension-details"
    >
      {personStatus === PersonStatusEnum.Registered && (
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <DangerFormattedButton
            id="startSuspension"
            className="mt-1 mb-3"
            onClick={() => setIsSuspensionModalOpen(true)}
          />
        </ProtectedFieldWrapper>
      )}
      {suspensions?.map((suspension, index) => (
        <SuspensionInfo
          key={index}
          suspension={suspension}
          handleSuspensionDeletion={() => handleSuspensionDeletion(index)}
          refresh={refresh}
        />
      ))}
      <SuspensionModal
        isOpen={isSuspensionModalOpen}
        onClose={() => setIsSuspensionModalOpen(false)}
        personId={personId}
        updatePerson={updatePerson}
      />
    </ApplicantDetailsSection>
  );
};
