import React from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "./Link";
import { Row } from "reactstrap";
import "./Footer.scss";
import { useWindowWidthSize } from "../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../Constants";
import elLogo from "../../../assets/images/el-logo_1.svg";

export const FooterBottom = () => {

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  return (
    <Row className="footer-bottom justify-content-center m-0">
      <div className="footer-bottom-links align-items-center">
        <div className="first-group">
          <div className="m-1">
            <Link
              id="facebook"
              url="https://www.facebook.com/terviseamet/"
            />
          </div>
          <FontAwesomeIcon className="footer-bottom-link-separator" icon={faCircle} fixedWidth={true}/>
          <div className="m-1">
            <Link
              id="youtube"
              url="https://www.youtube.com/channel/UCWsrQmpXjgLFxKvt91s-4_w"
            />
          </div>
          {!isMobile && <FontAwesomeIcon className="footer-bottom-link-separator" icon={faCircle} fixedWidth={true}/>}
        </div>
        <div className="second-group">
          <div className="m-1">
            <Link
              id="userAgreement"
              url="https://www.terviseamet.ee/et"
            />
          </div>
        </div>
      </div>
      <img className="footer-bottom__el-logo" src={elLogo} alt="logo" />
    </Row>
  );
};
