import React, { useCallback } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  UseFormMethods,
  useFormContext,
  useFieldArray,
  Controller,
  ControllerRenderProps
} from "react-hook-form";
import { FieldArray } from "../../../../Component/HookForm/FieldArray";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { Option } from "../../../../Component/Select/SelectTypes";
import { GeneralPractitionerFormValues } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerLicense";
import moment from "moment";
import { isDateInPast } from "../../../../Util/DateUtils";

interface Props {
  index: number;
  control: UseFormMethods["control"];
  options: Option[];
}

export const defaultAppendValue = { locationId: "" };

export default function LicenseAddress({ index, control, options }: Props) {
  const fieldArray = useFieldArray({
    name: `licenses[${index}].contacts`,
    keyName: "_id",
    control
  });
  const { watch, getValues, setValue } =
    useFormContext<GeneralPractitionerFormValues>();


  // const usedLocations = watch("licenses")[index].contacts?.map(
  //   (contact) => contact.locationId
  // );

    const licenses = watch("licenses");
    console.log("licenses:", licenses);

    // Declare usedLocations and set it conditionally after hooks
    let usedLocations: string[] = [];

    if (licenses && licenses[index]) {
        // Safeguard to prevent errors if contacts are undefined because of data missing because of rendering
        usedLocations = licenses[index].contacts?.map(
            (contact) => contact.locationId
        ) || [];
    } else {
        console.warn(`License at index ${index} is undefined`);
    }


    const getLocationOptions = useCallback(
    (currentValue: string): Option[] => {
      const currentContactOptions = usedLocations
        ? usedLocations.filter((contactId: any) => contactId !== currentValue)
        : [];
      return options.filter(
        ({ value }) => !currentContactOptions.includes(value)
      );
    },
    [usedLocations, options]
  );

  const handleChangeContact = useCallback(
    (onChange, contactIndex) => (option: Option) => {
      const license = getValues().licenses[index];
      const modifiedActivationDate = isDateInPast(license.activationDate)
        ? moment().format("YYYY-MM-DD")
        : license.activationDate;

      setValue(
        `contacts[${contactIndex}]`,
        {
          contacts: license.contacts,
          activationDate: modifiedActivationDate
        },
        { shouldValidate: true }
      );
      onChange(option.value);
    },
    [getValues, setValue]
  );

  return (
    <FieldArray
      array={fieldArray}
      appendValue={defaultAppendValue}
      appendText={
        <FormattedMessage
          id="GPListLicenseModal.appendLicenseAddressButton"
          defaultMessage="Lisa uus vastuvõtu aadress"
        />
      }
      render={(fields, { appendButton }) => {
        const lastIndex = fields.length - 1;
        return (
          <>
            {fields.map((field, i) => (
              <FormGroup key={field._id} row={true}>
                <Label
                  htmlFor={`gpListLicenseModalLicenseLocationId_${index}-${i}`}
                  sm={4}
                  className="text-right"
                >
                  Vastuvõtu aadress
                </Label>
                <Col sm={8} className="flex-grow-1 text-right">
                  <Controller
                    name={`licenses[${index}].contacts[${i}].locationId`}
                    control={control}
                    defaultValue={field.locationId}
                    rules={{ required: true }}
                    render={({
                      value,
                      onChange
                    }: ControllerRenderProps<Record<string, any>>) => (
                      <SingleSelect
                        inputId={`gpListLicenseModalLicenseLocationId_${index}-${i}`}
                        className="text-left"
                        options={getLocationOptions(value)}
                        value={value}
                        handleOptionChange={handleChangeContact(
                          onChange,
                          index
                        )}
                        disabled={!options.length}
                      />
                    )}
                  />
                  {i === lastIndex &&
                    usedLocations &&
                    usedLocations.length < options.length &&
                    appendButton}
                </Col>
              </FormGroup>
            ))}
          </>
        );
      }}
    />
  );
}
