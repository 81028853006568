import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "firstName"
  | "lastName"
  | "personalCode"
  | "dateOfBirth"
  | "gender"
  | "male"
  | "female"
  | "citizenship"
  | "name"
  | "role";

export const PersonalDataFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    firstName: {
      id: "personalDataFM.firstName",
      defaultMessage: "Eesnimi"
    },
    lastName: {
      id: "personalDataFM.lastName",
      defaultMessage: "Perenimi"
    },
    personalCode: {
      id: "personalDataFM.personalCode",
      defaultMessage: "Isikukood"
    },
    dateOfBirth: {
      id: "personalDataFM.dateOfBirth",
      defaultMessage: "Sünniaeg"
    },
    gender: {
      id: "personalDataFM.gender",
      defaultMessage: "Sugu"
    },
    male: {
      id: "personalDataFM.male",
      defaultMessage: "Mees"
    },
    female: {
      id: "personalDataFM.female",
      defaultMessage: "Naine"
    },
    citizenship: {
      id: "personalDataFM.citizenship",
      defaultMessage: "Kodakondsus"
    },
    name: {
      id: "personalDataFM.name",
      defaultMessage: "Nimi"
    },
    role: {
      id: "personalDataFM.role",
      defaultMessage: "Roll"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
