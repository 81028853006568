import React, { ReactElement, useCallback } from "react";
import { components, OptionProps } from "react-select";
import { ValueContainerProps } from "react-select/src/components/containers";
import { MultiValueGenericProps } from "react-select/src/components/MultiValue";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import classNames from "classnames";

import { CustomSelect } from "./CustomSelect";
import { Props, Option } from "./SelectTypes";
import "./MultiSelect.scss";
import { joinJSX } from "../../Util/ArrayUtil";

const OptionWithCheckbox = (props: OptionProps<Option, boolean>) => (
  <components.Option {...props}>
    <div
      className={classNames("fake-checkbox", { selected: props.isSelected })}
    />
    <div className="checkbox-label">{props.label}</div>
  </components.Option>
);

export const MultiSelect = (props: Props) => {
  const ValueContainer = useCallback(
    (passedProps: ValueContainerProps<Option, boolean>) => {
      const children = React.Children.toArray(passedProps.children);
      const input = children.pop();
      return (
        <components.ValueContainer {...passedProps}>
          {passedProps.hasValue ? (
            <components.SingleValue
              {...(passedProps as SingleValueProps<Option>)}
            >
              {joinJSX(
                (
                  children as ReactElement<MultiValueGenericProps<Option>>[]
                ).map<[string, JSX.Element]>(({ props: { data: option } }) => [
                  option.value,
                  <>{option.label}</>
                ])
              )}
            </components.SingleValue>
          ) : (
            children
          )}
          {input}
        </components.ValueContainer>
      );
    },
    []
  );

  const customComponents = {
    MultiValueRemove: () => null,
    Option: OptionWithCheckbox,
    Control: components.Control,
    ValueContainer
  };

  return (
    <CustomSelect
      alwaysShowPlaceholder={true}
      alwaysClearable={true}
      isMulti={true}
      isClearable={true}
      closeMenuOnSelect={false}
      className="multi-select"
      components={customComponents}
      hideSearchIcon={true}
      {...props}
    />
  );
};
