import React, { ReactElement, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ApplicationDetailsInfoCard } from "../../../../../Component/Card/ApplicationDetailsInfoCard";
import { ServiceDetailsAccordion } from "../../../../Shared/ActivityLicense/Services/ServiceDetailsAccordion";
import "../../../../Shared/ActivityLicense/ActivityLicense.scss";
import { LinkButton } from "../../../../../Component/Button/LinkButton";
import { StateFeeInfoModal } from "../../../../Shared/StateFee/StateFeeInfoModal";
import { ManualPaymentConfirmationModal } from "../../../Application/Details/ManualPaymentConfirmationModal";
import { ProtectedFieldWrapper } from "../../../../../Security/ProtectedFieldWrapper";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { getBaseUrl, API } from "../../../../../api";
import { ClickableInfoValue } from "../../../../../Component/QuickLinks/ClickableInfoValue";
import { getDocumentsContent } from "../../../../Shared/ActivityLicense/Documents/DocumentsUtil";
import { ClickableLicenseNumberCard } from "../../ClickableLicenseNumberCard";
import {
  ActivityLicenseApplication,
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitApp
} from "../../../../../../api_client/medre_api";
import { specialistBusinessAreaId } from "../../../../Shared/ActivityLicense/Locations/LocationsUtil";

export interface Props {
  details: ActivityLicenseApplication;
  // tslint:disable-next-line:no-any
  setDetails: (details: any) => void;
}

export const DetailsCard = ({ details, setDetails }: Props) => {
  const history = useHistory();

  const isActivityLocationApplication =
    details.proceedingType === ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation;

  const companyInfo = isActivityLocationApplication
    ? (details.activityLicense?.company ? details.activityLicense?.company : details.company)
    : details.company;

  const { name, address, registryCode, email, phone, id } = companyInfo || {};

  const [isStateFeeInfoModalOpen, setIsStateFeeInfoModalOpen] = useState(false);
  const [isManualPaymentConfirmationModalOpen, setIsManualPaymentConfirmationModalOpen] = useState(false);

  const getPaymentStatus = (application: ActivityLicenseApplication): string | ReactElement => {
    const UNPAID = "Tasumata";
    if (application?.currentStatus?.status === ActivityLicenseApplicationStatus.Saved) {
      return UNPAID;
    }
    if (application.stateFee?.paid) {
      return "Tasutud";
    }

    return (
      <>
        <div className="payment-status-wrapper">
          <span className="fw-500">Tasumata</span>
          <LinkButton
            id="paymentInstructions"
            onClick={(): void => setIsStateFeeInfoModalOpen(true)}
          />
        </div>
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <SecondaryFormattedButton
            id="paymentManuallyConfirmed"
            onClick={(): void => setIsManualPaymentConfirmationModalOpen(true)}
          />
        </ProtectedFieldWrapper>
      </>
    );
  };

  const getTitle = (): string =>
    details.proceedingType ===
    ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation
      ? "Tegevuskoha lisamise taotluse detailid"
      : details.proceedingType ===
      ActivityLicenseApplicationProceedingTypeEnum.Service
        ? "Teenuse lisamise taotluse detailid"
        : "Tegevusloa taotluse detailid";

  const navigateToCompanyDetails = (): void => history.push(`/companies/${ id }`);

  const personName = useMemo(
    () =>
      `${ details.submittingPersonFirstName || "" } ${
        details.submittingPersonLastName || ""
      }`,
    [details.submittingPersonFirstName, details.submittingPersonLastName]
  );

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" /> { getTitle() }
      </h2>
      <ApplicationDetailsInfoCard
        title="Äriregistri andmed"
        content={[
          {
            key: "Nimi",
            value: (
              <ClickableInfoValue
                name={name || ""}
                onClick={navigateToCompanyDetails}
              />
            )
          },
          { key: "Aadress", value: address || "-" },
          { key: "Registrikood", value: registryCode || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          { key: "Nimi", value: personName },
          { key: "Isikukood", value: details.submittingPersonIdCode }
        ]}
      />
      { details.proceedingType !==
        ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense && (
        <ClickableLicenseNumberCard
          licenseId={details.activityLicense?.id || ""}
          licenseNumber={details.activityLicense?.number || ""}
        />
      ) }
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: details.businessArea?.name }]}
      />
      <ApplicationDetailsInfoCard
        businessAreaId={details.businessArea?.id}
        title="Tegevuskoht"
        content={
          details.locations?.map((location) => {
            return {
              key: "Tegevuskoht",
              value: location.fullAddress,
              isMobileLocation: location.adsAdrID === "mobile"
            };
          }) || []
        }
      />
      <h3 className="mt-4">Teenused</h3>
      <div className="service-details-table-container">
        { details?.locations?.map((location) => (
          <ServiceDetailsAccordion
            specialistBusinessAreaId={specialistBusinessAreaId(details)}
            key={location.id}
            isHospital={!!details?.businessArea?.isHospital}
            services={location.services}
            isMobileActivityLocation={
              details.locations && details.locations[0].adsAdrID === "mobile"
            }
          />
        )) }
      </div>
      <ApplicationDetailsInfoCard
        title="Dokumendid"
        content={getDocumentsContent(details.fileReferences || [])}
      />
      <span id="fee" />
      <ApplicationDetailsInfoCard
        title="Riigilõiv"
        content={[
          { key: "Maksmise staatus", value: getPaymentStatus(details) }
        ]}
      />
      <StateFeeInfoModal
        isOpen={isStateFeeInfoModalOpen}
        onClose={(): void => setIsStateFeeInfoModalOpen(false)}
        deadline={details.stateFee?.stateFeeDeadline}
        referenceNumber={details.stateFee?.referenceNumber}
        stateFeeValue={details.stateFee?.stateFeeValue}
      />
      <ManualPaymentConfirmationModal
        isOpen={isManualPaymentConfirmationModalOpen}
        onClose={(): void => setIsManualPaymentConfirmationModalOpen(false)}
        apiRequest={() =>
          officialActivityPermitApp(
            undefined,
            getBaseUrl(),
            API
          ).markAsManuallyPaid2(details.id!, {
            withCredentials: true
          })
        }
        updateApplication={setDetails}
      />
      <ApplicationDetailsInfoCard
        title="Tervise Infosüsteemi liidestus"
        content={[
          {
            key: "TIS liidestus",
            value: details?.currentStatus?.status === ActivityLicenseApplicationStatus.Saved ? "-" : "Kinnitatud"
          }
        ]}
      />
    </Card>
  );
};
