import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  ContactInfoForm,
  formInfoToContactInfo
} from "../../../../Dto/ContactInfo/ContactInfoForm";
import { KasutajaAndmeteTeenusApiFactory as userDataAPI } from "../../../../../api_client/medre_api";
import { personActions } from "../../../../Person/personActions";

interface Props {
  formInfo?: ContactInfoForm;
  isInitialEdit: boolean;
  isFormValid: boolean;
  setIsForceValidation: (isForceValidation: boolean) => void;
  setIsEditActive: (isActive: boolean) => void;
}

export const ContactInfoEditFooter = ({
  formInfo,
  isInitialEdit,
  isFormValid,
  setIsEditActive,
  setIsForceValidation
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSave = () => {
    if (isFormValid) {
      userDataAPI(undefined, getBaseUrl(), API)
        .updatePerson1(formInfoToContactInfo(formInfo!), {
          withCredentials: true
        })
        .then((res) => {
          const alertMessage = (
            <FormattedMessage
              id="contactInfoEditFooter.successfulSave"
              defaultMessage="Kontaktandmed salvestatud"
            />
          );
          const alert = {
            id: 0,
            type: AlertType.Success,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          dispatch(personActions.setPerson(res.data));
          setIsEditActive(false);
          history.push("/contacts");
        })
        .catch(() => {
          const alertMessage = (
            <FormattedMessage
              id="contactInfoEditFooter.unsuccessfulSave"
              defaultMessage="Kontaktandmete muutmine ebaõnnestus"
            />
          );
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    } else {
      setIsForceValidation(true);
      setTimeout(() => setIsForceValidation(false), 500);
    }
  };

  return (
    <div className="button-container d-flex">
      {!isInitialEdit && (
        <SecondaryFormattedButton
          id="discard"
          className="mt-0 mr-3 mb-4 ml-3"
          onClick={() => setIsEditActive(false)}
        />
      )}
      <PrimaryFormattedButton
        id="save"
        className="mt-0 mr-4 mb-4 ml-3"
        onClick={() => handleSave()}
      />
    </div>
  );
};
