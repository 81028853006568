import { action } from "typesafe-actions";
import { ContactInfo, } from "../../api_client/medre_api";

export const SET_CONTACT_INFO = "SET_CONTACT_INFO";


export const contactInfoFromRegistryActions = {
  setContactInfoFromRR: (contactInfo: ContactInfo) => action(SET_CONTACT_INFO, contactInfo),

};
