import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getBaseUrl, API } from "../../../api";
import { useGeneralPractitionerListSelector } from "../../../GeneralPractitionerLists/generalPractitionerListSelector";
import {
  PerearstiNimistudTeenusApiFactory as doctorDirectoryServiceAPI,
  GeneralPractitionerList,
  OfficialUserPrivilegesEnum
} from "../../../../api_client/medre_api";
import { getFullName } from "../../../Util/PersonUtils";
import { UserInfo } from "../../../Dto/Security/UserInfo";
import { RootState } from "../../../rootReducer";

const useGeneralPractitionerLists = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<GeneralPractitionerList[]>([]);
  const { selectedRoles } = useSelector(
    (state: RootState) => state.user.userInfo as UserInfo
  );

  useEffect(() => {
    if (selectedRoles?.includes(OfficialUserPrivilegesEnum.Person)) {
      setList([]);
    } else {
      setLoading(true);
      doctorDirectoryServiceAPI(undefined, getBaseUrl(), API)
        .getGeneralPractitionerList2({
          withCredentials: true
        })
        .then((res) => setList(res.data))
        .finally(() => setLoading(false));
    }
  }, [selectedRoles]);

  return { list, loading };
};

export const useGeneralPractitionerEmployeeOptions = () => {
  return useGeneralPractitionerListSelector().employees.map((employee) => ({
    value: employee.id,
    label: getFullName(employee)
  }));
};

export const useGeneralPractitionerSubstituteDoctorOptions = () => {
  return useGeneralPractitionerListSelector().substituteDoctors?.map(
    (substitute) => ({
      value: substitute.id,
      label: (
        <>
          {getFullName(substitute)} ({substitute?.occupationCode?.code})
        </>
      )
    })
  );
};

export default useGeneralPractitionerLists;
