import React from "react";

import file_icon from "../../../../../../../assets/images/file.svg";
import "./AttachmentFileRow.scss";
import { handleFileDownload } from "../../../../../../Util/FileUtils";
import { RemoveButton } from "../../../../../../Component/Button/RemoveButton";

interface Props {
  fileName?: string;
  minioFileName?: string;
  fileContent?: File;
  isDeletingPossible: boolean;
  handleFileDelete?: (fileName: string) => void;
}

export const AttachmentFileRow = ({
  fileName,
  minioFileName,
  isDeletingPossible,
  handleFileDelete,
  fileContent
}: Props) => {
  const handleDelete = () => {
    handleFileDelete!(fileName!);
  };

  return (
    <>
      <span
        className="pointer pr-2"
        onClick={() => handleFileDownload(fileName, minioFileName, fileContent)}
      >
        <img src={file_icon} alt="fail" className="d-inline pr-2" />
        <span className="d-inline file-name file-name-break">{fileName}</span>
      </span>
      {isDeletingPossible && <RemoveButton handleDelete={handleDelete} />}
    </>
  );
};
