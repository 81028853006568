import React, { FC } from "react";
import { GeneralPractitionerListStatusEnum } from "../../../../api_client/medre_api";
import { FormattedMessage } from "react-intl";

interface Props {
  status?: GeneralPractitionerListStatusEnum;
}

const STATUSES = {
  [GeneralPractitionerListStatusEnum.Register]: (
    <FormattedMessage
      id="GPListStatus.IN_REGISTER"
      defaultMessage="Registris"
    />
  ),
  [GeneralPractitionerListStatusEnum.Archives]: (
    <FormattedMessage id="GPListStatus.IN_ARCHIVES" defaultMessage="Arhiivis" />
  ),
  [GeneralPractitionerListStatusEnum.Proceeding]: (
    <FormattedMessage
      id="GPListStatus.IN_PROCEEDING"
      defaultMessage="Pooleli"
    />
  )
};

const GeneralPractitionerListStatus: FC<Props> = ({ status }) =>
  status ? STATUSES[status] : null;

export default GeneralPractitionerListStatus;
