import React from "react";
import { useDispatch } from "react-redux";
import { AxiosResponse } from "axios";

import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  ActivityLicenseApplication,
  ProceedingStatusStatusEnum,
  DetailedApplication
} from "../../../../../api_client/medre_api";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";

interface Props<TApplication> {
  isOpen: boolean;
  onClose: () => void;
  updateApplication: (application: TApplication | DetailedApplication) => void;
  apiRequest: () => Promise<AxiosResponse>;
}

export const ManualPaymentConfirmationModal = <
  TApplication extends DetailedApplication | ActivityLicenseApplication
>({
  isOpen,
  onClose,
  apiRequest,
  updateApplication
}: Props<TApplication>) => {
  const dispatch = useDispatch();
  const setPaidManually = () => {
    apiRequest()
      .then((res) => {
        let application: TApplication = res.data;
        updateApplication(application);
        let alertMessage = <AlertMessage id="manualPaymentSuccess" />;
        let alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        if (
          application.currentStatus?.status ===
          ProceedingStatusStatusEnum.Accepted
        ) {
          alertMessage = <AlertMessage id="applicationAcceptedAutomatically" />;
          alert = { id: 1, type: AlertType.Info, message: alertMessage };
          dispatch(alertActions.addAlert(alert));
        }
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="manualPaymentFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(onClose);
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Olete Te kindel, et soovite riigilõivu makstuks märkida?"
      onClose={onClose}
      onSave={setPaidManually}
      closeButtonId="backToApplication"
      saveButtonId="confirm"
    >
      Taotluse riigilõiv märgitakse käsitsi makstuks.
    </ConfirmationModal>
  );
};
