import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "diploma"
  | "academicReport"
  | "additionalDocument"
  | "nameChangeDocument"
  | "medicalTechnologyProject"
  | "rightOfUserForRooms"
  | "listOfDevices"
  | "primaryCarePhysicianInfo"
  | "schoolNurseImmunisation"
  | "nurseTrainings"
  | "midwifeHomeBirth"
  | "otherDocuments"
  | "personnelAgreement"
  | "ambulance"
  | "onSiteInspectionProtocol"
  | "economicActivityConfirmation"
  | "personnelList"
  | "healthcareProvidingConfirmation"
  | "previousOwnerAgreement"
  | "propertyTransitionConfirmation"
  | "addedFiles"
  | "filledApplication"
  | "requirementsTable"
  | "employeeQualifications"
  | "proceedingDocuments";

export const FilesFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    diploma: {
      id: "filesFm.diploma",
      defaultMessage: "Diplomi koopia"
    },
    academicReport: {
      id: "filesFm.academicReport",
      defaultMessage: "Akadeemilise õiendi koopia"
    },
    additionalDocument: {
      id: "filesFm.additionalDocument",
      defaultMessage: "Täiendavate dokumentide koopiad"
    },
    nameChangeDocument: {
      id: "filesFm.nameChangeDocument",
      defaultMessage: "Nimemuutust tõendava dokumendi koopia"
    },
    medicalTechnologyProject: {
      id: "filesFm.medicalTechnologyProject",
      defaultMessage: "Meditsiinitehnoloogia projekt "
    },
    rightOfUserForRooms: {
      id: "filesFm.rightOfUserForRooms",
      defaultMessage: "Ruumide kasutusõiguse tõend"
    },
    listOfDevices: {
      id: "filesFm.listOfDevices",
      defaultMessage: "Aparatuuri ja sisseseade loetelu"
    },
    primaryCarePhysicianInfo: {
      id: "filesFm.primaryCarePhysicianInfo",
      defaultMessage:
        "Vormid perearsti tegevuskoha ruumide, sisseseade ja aparatuuri kohta"
    },
    schoolNurseImmunisation: {
      id: "filesFm.schoolNurseImmunisation",
      defaultMessage: "Kooliõe immuniseerimise tunnistus"
    },
    nurseTrainings: {
      id: "filesFm.nurseTrainings",
      defaultMessage:
        "Õe viimase kahe aasta jooksul läbitud kutse- ja erialaseid täienduskoolitused"
    },
    midwifeHomeBirth: {
      id: "filesFm.midwifeHomeBirth",
      defaultMessage:
        "Kodusünnitusabi osutamiseks ämmaemanda pädevust tõendavad dokumendid"
    },
    otherDocuments: {
      id: "filesFm.otherDocuments",
      defaultMessage: "Muud dokumendid"
    },
    personnelAgreement: {
      id: "filesFm.personnelAgreement",
      defaultMessage: "Personali nõusoleku dokumendid "
    },
    ambulance: {
      id: "filesFm.ambulance",
      defaultMessage: "Kiirabiga seotud dokumendid "
    },
    onSiteInspectionProtocol: {
      id: "filesFm.onSiteInspectionProtocol",
      defaultMessage: "Paikvaatluse protokoll "
    },
    economicActivityConfirmation: {
      id: "filesFm.economicActivityConfirmation",
      defaultMessage: "Majandustegevuse jätkamise õigust kinnitavad dokumendid "
    },
    personnelList: {
      id: "filesFm.personnelList",
      defaultMessage: "Personali nõusoleku dokumendid "
    },
    healthcareProvidingConfirmation: {
      id: "filesFm.healthcareProvidingConfirmation",
      defaultMessage:
        "Tõend, et täidetud on muud tervishoiuteenuse osutamiseks kehtestatud nõuded "
    },
    previousOwnerAgreement: {
      id: "filesFm.previousOwnerAgreement",
      defaultMessage:
        "Nõusolek tegevusloa üleandmiseks (võib sisalduda ettevõtte võõrandamise lepingus) "
    },
    propertyTransitionConfirmation: {
      id: "filesFm.propertyTransitionConfirmation",
      defaultMessage:
        "Tegevusloa kontrolliesemesse kuuluva vara ülemineku kinnitus "
    },
    addedFiles: {
      id: "filesFm.addedFiles",
      defaultMessage: "Lisatud failid"
    },
    filledApplication: {
      id: "filesFm.filledApplication",
      defaultMessage: "Tegevusloa taotlemise täidetud avaldus"
    },
    requirementsTable: {
      id: "filesFm.requirementsTable",
      defaultMessage: "Nõuete tabel"
    },
    employeeQualifications: {
      id: "filesFm.employeeQualifications",
      defaultMessage: "Kvalifikatsiooni tõendavad dokumendid"
    },
    proceedingDocuments: {
      id: "filesFm.proceedingDocuments",
      defaultMessage: "Kvalifikatsiooni tõendavad dokumendid"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
