import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";
import { useHistory } from "react-router";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";

import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { formatDate, renderOverViewRow } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { renderActivityLicenseRow } from "./ActivityLicenseRowUtil";
import { API, getBaseUrl } from "../../../../api";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { CompanyInfoRow } from "../Applications/CompanyInfoRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { FilesRow } from "../Applications/FilesRow";
import { ActivityLicenseState } from "../../../Shared/ActivityLicense/ActivityLicenseState";
import { DecisionsRow } from "../Applications/DecisionsRow";
import { Loader } from "../../../../Component/Loader/Loader";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { FormattedButton } from "../../../../Component/Button/FormattedButton";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import {
  ActivityLicenseSuspensionModal
} from "../../../Shared/ActivityLicense/Suspension/ActivityLicenseSuspensionModal";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import { displayAlert } from "../../../../Util/AlertUtil";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { ConfirmationStickyFooter } from "../../../Footer/ConfirmationStickyFooter";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";
import { RootState } from "../../../../rootReducer";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { AddEmployeeModal, SearchBy } from "../../../Shared/ActivityLicense/Services/AddEmployeeModal";
import useModal from "../../../../Hook/useModal";
import {
    ActivityLicense,
    ActivityLicenseRowHeader,
    ActivityLicenseRowHeaderStatusEnum, ActivityLicenseSuspensionInfo, HealthCareProfessional,
    LocationWithSuspensionInfo,
    OfficialUserPrivilegesEnum,
    TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFactory as revocationActivityPermitAPI,
    TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import {
  isUserBusinessOwnerOrPowerOfAttorneyTypeUltimate,
  ProtectedComponent
} from "../../../../Security/ProtectedComponent";
import { specialistBusinessAreaId } from "../../../Shared/ActivityLicense/Locations/LocationsUtil";
import { ErrorResponseData } from "../../../../Dto/Error/Error";
import { getDate } from "../../../../Util/DateUtils";

interface Props {
  header: ActivityLicenseRowHeader;
  isOpen?: boolean;
  index: number;
  handleRowClick: (index: number) => void;
  reload: () => void;
}

const PHARMACY_BUSINESS_AREA_ID = "7e1f57a7-9144-4de4-8550-b7da2b292dd0";

export const ActivityLicenseRow = React.memo(
  ({ isOpen, index, handleRowClick, header }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    const [activityLicense, setActivityLicense] = useState<ActivityLicense>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] =
      useState<boolean>(false);
    const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
      useState<boolean>(false);
    const [isFilesRowOpen, setIsFilesRowOpen] = useState<boolean>(false);
    const [isDecisionsRowOpen, setIsDecisionsRowOpen] =
      useState<boolean>(false);
    const [
      isActivityLicenseSuspensionModalOpen,
      setIsActivityLicenseSuspensionModalOpen
    ] = useState<boolean>(false);
    const [
      isActivityLicenseInvalidationModalOpen,
      setIsActivityLicenseInvalidationModalOpen
    ] = useState<boolean>(false);

    const servicesToBeDiscarded = useSelector(
      (state: RootState) => state.activityLicenseTable.servicesToDiscard
    );
    const maxSuspensionDuration = useSelector(
      (state: RootState) => state.config.maxSuspensionDuration
    );

    const addEmployeeModal = useModal();

    const { createdAt, businessAreaId, businessAreaName } = header;

    const [locationsSuspensionInfo, setLocationsSuspensionInfo]  = useState<Array<LocationWithSuspensionInfo>>([]);
    const [licenseSuspensionInfo, setLicenseSuspensionInfo]  = useState<ActivityLicenseSuspensionInfo | undefined>(undefined);


    const fetchActivityLicenseSuspensionInfo = (async (licenseId) => {
      const { data } = await activityPermitAPI(
          undefined,
          getBaseUrl(),
          API
      ).getPublicActivityLicenseInDepthSuspensionInfo(licenseId);
      setLicenseSuspensionInfo(data.activityLicenseSuspensionsInfo);
      setLocationsSuspensionInfo([...data.locationsWithSuspensionInfo!]);
    });

      const isPharmacyLicense = useMemo(
      () => businessAreaId === PHARMACY_BUSINESS_AREA_ID,
      [businessAreaId]
    );

    const getActivityLicenseInfo = useCallback(
      (id) => {
        setIsLoading(true);
        activityPermitAPI(undefined, getBaseUrl(), API)
          .getActivityLicense3(id, {
            withCredentials: true
          })
          .then((res) => {
            setActivityLicense(res.data);
            fetchActivityLicenseSuspensionInfo(res.data.id);
          })
          .catch(() => {
            displayAlert(
              "activityLicenseFetchFailed",
              AlertType.Danger,
              dispatch
            );
          })
          .finally(() => setIsLoading(false));
      },
      [dispatch]
    );

    useEffect(() => {
      if (isOpen && !activityLicense) {
        getActivityLicenseInfo(header.id);
      }
    }, [isOpen, activityLicense, header.id, getActivityLicenseInfo]);

    const className = isOpen ? "expanded" : "";
    const widthSize = useWindowWidthSize();
    const invalidationConfirmationMessage = (
      <FormattedMessage
        id="ActivityLicenseRow.invalidationMessage"
        defaultMessage="Kas olete kindel, et soovite tegevusloa kehtetuks tunnistada?"
      />
    );

    const renderRowContent = useCallback(
      (icon: JSX.Element) => {
        return renderActivityLicenseRow(
          widthSize,
          icon,
          header.status,
          new Date(createdAt!),
          businessAreaName,
          header.number,
          header.id,
          isPharmacyLicense
        );
      },
      [
        widthSize,
        createdAt,
        businessAreaName,
        header.number,
        header.status,
        header.id,
        isPharmacyLicense
      ]
    );

    const invalidateActivityLicense = useCallback(() => {
      return revocationActivityPermitAPI(undefined, getBaseUrl(), API)
        .invalidateActivityLicense1(header.id, { withCredentials: true })
        .then((res) => {
          setActivityLicense((license) => ({
            ...license!,
            invalidationApplicationId: res.data.id
          }));
          setIsActivityLicenseInvalidationModalOpen(false);
        })
        .catch((_) =>
          displayAlert("requestFailed", AlertType.Danger, dispatch)
        );
    }, [header.id, dispatch]);

    const handleNewActivityLocation = () => {
      dispatch(
        activityLicenseApplicationActions.setInitialActivityLocationApplication(
          activityLicense!
        )
      );
      history.push("/new-activity-location");
    };

    const submitDiscardApplication = () => {
      setIsLoading(true);
      activityPermitAPI(undefined, getBaseUrl(), API)
        .submitDiscardServices(servicesToBeDiscarded, {
          withCredentials: true
        })
        .then(() => {
          displayAlert("applicationSubmitted", AlertType.Success, dispatch);
          getActivityLicenseInfo(header.id);
        })
        .catch((error) => {
          const responseData = error.response?.data;
          if (
            responseData.error === "MedreRuntimeException" &&
            responseData.message.startsWith(
              "Cannot discard all services from location"
            )
          ) {
            displayAlert(
              "cannotDiscardAllServices",
              AlertType.Danger,
              dispatch
            );
          } else {
            displayAlert("requestFailed", AlertType.Danger, dispatch);
          }
        })
        .finally(() => {
          dispatch(activityLicenseTableActions.setServicesToDiscard([]));
          setIsLoading(true);
        });
    };

    const InvalidateActivityLicenseBtn = () => {
        return <ProtectedComponent
            directRepresentativeOnly
            allowedRoles={[
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
            ]}
            officialsOnly
            matchAnyCondition
        >
            <DangerFormattedButton
                id="invalidateActivityLicense"
                className="mt-md-0 mt-3"
                onClick={() =>
                    setIsActivityLicenseInvalidationModalOpen(true)
                }
            />
        </ProtectedComponent>;
    }

    const SuspendActivityLicenseBtn = () => {
        return <ProtectedComponent
            directRepresentativeOnly
            allowedRoles={[
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
            ]}
            officialsOnly
            matchAnyCondition
        >
            <FormattedButton
                id="suspendActivityLicense"
                className="mt-md-0 mt-3 mr-md-3"
                onClick={() =>
                    setIsActivityLicenseSuspensionModalOpen(true)
                }
            />
        </ProtectedComponent>;
    }

      const canShowSuspendLicenseButton = !isPharmacyLicense
          && licenseSuspensionInfo?.licenseSuspensionInProceeding !== true
          && (activityLicense?.status === ActivityLicenseRowHeaderStatusEnum.Valid ||
              (activityLicense?.status === ActivityLicenseRowHeaderStatusEnum.Paused &&
                  licenseSuspensionInfo?.licenseSuspendedDays! < maxSuspensionDuration!
              ))

      const handleSaveNewEmployees = (
      employeesToBeAdded: HealthCareProfessional[]
    ) => {
      addEmployeeModal.handleClose();

      if (activityLicense && activityLicense.id) {
        setIsLoading(true);
        activityPermitAPI(undefined, getBaseUrl(), API)
          .addEmployeesToPharmacyLicense(
            activityLicense.id,
            employeesToBeAdded,
            {
              withCredentials: true
            }
          )
          .then(() => {
            displayAlert("employeesAdded", AlertType.Success, dispatch);
            getActivityLicenseInfo(header.id);
          })
          .catch((error: AxiosError) => {
            const responseData = error.response?.data as ErrorResponseData;
            if (responseData.error === "TortaException") {
              displayAlert("tortaFailure", AlertType.Danger, dispatch);
            } else {
              displayAlert("requestFailed", AlertType.Danger, dispatch);
            }
          })
          .finally(() => setIsLoading(false));
      }
    };

    if (isLoading) {
      return <Loader backdrop />;
    }

    const isPausedAndValid = licenseSuspensionInfo?.licenseStatus === "PAUSED" &&
      getDate(new Date(licenseSuspensionInfo.lastLicenseSuspensionEndDate)) >= getDate(new Date());

    if (isOpen) {
      return (
        <>
          <tr
            className={`${ className } cursor-pointer`}
            onClick={() => handleRowClick(index)}
          >
            { renderRowContent(
              <button className="btn-unstyled d-inline-block">
                <MinusIcon />
              </button>
            ) }
          </tr>
          <tr className="detail-info">
            <td colSpan={6} className="py-0 pr-0">
              { activityLicense?.status !== ActivityLicenseRowHeaderStatusEnum.Invalid &&
                  <Row className="my-3 justify-content-md-end justify-content-center application-header-row">
                      { canShowSuspendLicenseButton && <SuspendActivityLicenseBtn/>}
                      { !activityLicense?.invalidationApplicationId && (
                          <InvalidateActivityLicenseBtn/>
                      ) }
                      { isPharmacyLicense && activityLicense?.status !== ActivityLicenseRowHeaderStatusEnum.Paused && (
                          <PrimaryFormattedButton
                              id="addServiceEmployees"
                              className="mt-md-0 mt-3 ml-md-3"
                              onClick={addEmployeeModal.handleOpen}
                          />
                      ) }
                  </Row>
              }
              <div className="detail-col px-0">
                <>
                  <Row className="col-header">
                    <GeneralFM id="licenseInfo" />
                  </Row>
                  { renderOverViewRow(
                    "activityLicense",
                    "licenseNumber",
                    activityLicense?.number
                  ) }
                  { renderOverViewRow(
                    "activityLicense",
                    "state",
                    licenseSuspensionInfo
                      ? <ActivityLicenseState
                          status={isPausedAndValid ? "PAUSED" : activityLicense?.status}
                          values={isPausedAndValid ? {date: formatDate(licenseSuspensionInfo.lastLicenseSuspensionEndDate)} : undefined}
                        />
                      : <ActivityLicenseState status={activityLicense?.status} />
                  ) }
                  { renderOverViewRow(
                    "activityLicense",
                    "startDate",
                    formatDate(activityLicense?.createdAt)
                  ) }
                  { renderOverViewRow(
                    "activityLicense",
                    "endDate",
                    formatDate(activityLicense?.validUntil) || (
                      <GeneralFM id="termless" />
                    )
                  ) }
                  { renderOverViewRow(
                    "activityLicense",
                    "businessArea",
                    activityLicense?.businessArea?.name
                  ) }
                </>

                <CompanyInfoRow
                  isOpen={isApplicantInfoRowOpen}
                  handleClick={() =>
                    setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)
                  }
                  company={activityLicense?.company}
                  hideNullRows
                />
                { activityLicense?.locations && (
                  <ActivityLocationsRow
                    specialistBusinessAreaId={specialistBusinessAreaId(activityLicense)}
                    isOpen={isActivityLocationsRowOpen}
                    handleClick={() =>
                      setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
                    }
                    activityLocations={activityLicense?.locations}
                    isHospital={activityLicense?.businessArea?.isHospital!}
                    refresh={() => getActivityLicenseInfo(header.id)}
                    showTorConfirmation
                    activityLicense={activityLicense}
                    isPharmacyLicense={isPharmacyLicense}
                    loadServiceEndpoint={
                      activityPermitAPI(undefined, getBaseUrl(), API)
                        .getService1
                    }
                    {...(activityLicense.status !== ActivityLicenseRowHeaderStatusEnum.Invalid && {
                      isEditable: isUserBusinessOwnerOrPowerOfAttorneyTypeUltimate(userInfo?.roles),
                      handleNewActivityLocation,
                      addTHTEndpoint: activityPermitAPI(
                        undefined,
                        getBaseUrl(),
                        API
                      ).addEmployees1,
                      addSpecialistEndpoint: activityPermitAPI(
                        undefined,
                        getBaseUrl(),
                        API
                      ).addEmployees,
                      removeEmployeeEndpoint: activityPermitAPI(
                        undefined,
                        getBaseUrl(),
                        API
                      ).removeEmployee,
                      removeThtServiceEmploymentEndpoint: activityPermitAPI(
                        undefined,
                        getBaseUrl(),
                        API
                      ).removeThtServiceEmployee,
                      removeSpecialistServiceEmploymentEndpoint: activityPermitAPI(
                        undefined,
                        getBaseUrl(),
                        API
                      ).removeSpecialistServiceEmployee,
                    })}
                      locationsSuspensionInfo={locationsSuspensionInfo}
                      onLocationSuspensionSubmit={() => fetchActivityLicenseSuspensionInfo(activityLicense?.id)}
                      onServiceSuspensionSubmit={() => fetchActivityLicenseSuspensionInfo(activityLicense?.id)}
                  />
                ) }
                { !isPharmacyLicense && (
                  <>
                    <ProtectedComponent directRepresentativeOnly>
                      <FilesRow
                        isOpen={isFilesRowOpen}
                        handleClick={() => setIsFilesRowOpen(!isFilesRowOpen)}
                        documents={activityLicense?.fileReferences || []}
                      />
                    </ProtectedComponent>
                    <DecisionsRow
                      isOpen={isDecisionsRowOpen}
                      handleClick={() =>
                        setIsDecisionsRowOpen(!isDecisionsRowOpen)
                      }
                      decisions={activityLicense?.decisions}
                    />
                  </>
                ) }
                <ActivityLicenseSuspensionModal
                  isOpen={isActivityLicenseSuspensionModalOpen}
                  onClose={() => setIsActivityLicenseSuspensionModalOpen(false)}
                  activityLicense={activityLicense}
                  activityLicenseSuspensionInfo={licenseSuspensionInfo}
                  onLicenseSuspensionSubmit={() => fetchActivityLicenseSuspensionInfo(activityLicense?.id)}
                />
                <ConfirmationModal
                  isOpen={isActivityLicenseInvalidationModalOpen}
                  title={invalidationConfirmationMessage}
                  onClose={() =>
                    setIsActivityLicenseInvalidationModalOpen(false)
                  }
                  onAsyncSave={invalidateActivityLicense}
                  saveButtonId="confirm"
                  closeButtonId="backToDetailView"
                  footerClassName="justify-content-between"
                >
                  { invalidationConfirmationMessage }
                </ConfirmationModal>
                <AddEmployeeModal
                  isOpen={addEmployeeModal.isOpen}
                  defaultEmployees={[]}
                  onClose={addEmployeeModal.handleClose}
                  onSave={handleSaveNewEmployees}
                  searchBy={SearchBy.Pharmacists}
                  title={
                    <FormattedMessage
                      id="addEmployeeModal.header"
                      defaultMessage="Lisa töötajad"
                    />
                  }
                  subtitle={
                    <FormattedMessage
                      id="addEmployeeModal.subtitleTHT"
                      defaultMessage="Otsi proviisoreid ja farmatseute"
                    />
                  }
                  occupationCodeLabel={
                    <FormattedMessage
                      id="addEmployeeModal.occupationCodeTHT"
                      defaultMessage="THT kood"
                    />
                  }
                  closeButtonId="back"
                  addButtonId="addToLicense"
                />
                { servicesToBeDiscarded && servicesToBeDiscarded.length > 0 && (
                  <ConfirmationStickyFooter
                    handleConfirm={submitDiscardApplication}
                    handleDiscard={() =>
                      dispatch(
                        activityLicenseTableActions.setServicesToDiscard([])
                      )
                    }
                    saveMessageId="sendChangesToProceeding"
                  />
                ) }
              </div>
            </td>
          </tr>
        </>
      );
    }
    return (
      <tr
        className={`${ className } cursor-pointer`}
        onClick={() => handleRowClick(index)}
      >
        { renderRowContent(
          <button className="btn-unstyled d-inline-block">
            <PlusIcon />
          </button>
        ) }
      </tr>
    );
  }
);
