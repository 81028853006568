import React, { useContext, useMemo } from "react";
import { PortalApplicationOverViewHeader } from "../../Application/OverView/PortalApplicationOverView";
import { ApplicationCertificateOverViewCard } from "./ApplicationCertificateOverViewCard";
import { ApplicationCertificateContactInfoOverViewCard } from "./ApplicationCertificateContactInfoOverViewCard";
import { PersonalDataOverViewCard } from "../../../Shared/Application/OverView/PersonalDataOverViewCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { ApplicationCertificateContext } from "../../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { PersonalData } from "../../../../../api_client/medre_api";
import { translateCountries } from "../../../../Component/Translate/CountryTranslator";

export const ApplicationCertificateOverView = () => {
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft as ApplicationCertificateDraft
  );
  const { allowedOccupationCodes, allowedSpecialities } = useContext(
    ApplicationCertificateContext
  );

  const occupations = useMemo(
    () =>
      allowedOccupationCodes
        .filter((value) =>
          applicationDraft.occupations
            ?.map((o: { id: string }) => o.id)
            .includes(value.occupationId!)
        )
        .map((o) => o.name)
        .join(", "),
    [applicationDraft.occupations, allowedOccupationCodes]
  );
  const speciality = useMemo(
    () =>
      allowedSpecialities.find(
        (value) => value.specialityId === applicationDraft.speciality?.id
      )?.name,
    [applicationDraft.speciality, allowedSpecialities]
  );

  const personalData = useSelector(
    (state: RootState) => state.person
  ) as PersonalData;

  const contactInfo = useSelector(
    (state: RootState) => state.person.contactInfo
  );
  const { education, deliveryType, deliveryAddress } = applicationDraft;

  return (
    <>
      <PortalApplicationOverViewHeader />

      <div className="application-overview-card-container">
        <ApplicationCertificateOverViewCard
          occupations={occupations}
          speciality={speciality}
          countries={translateCountries(applicationDraft.countries)}
          education={education}
        />

        <PersonalDataOverViewCard personalData={personalData} />
        <ApplicationCertificateContactInfoOverViewCard
          contactInfo={contactInfo}
          deliveryType={deliveryType}
          deliveryAddress={deliveryAddress}
        />
      </div>
    </>
  );
};
