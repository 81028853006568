import React from "react";
import { Col, Row } from "reactstrap";

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../../../Constants";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import {
  CompanyApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus
} from "../../../../../api_client/medre_api";
import { isProceedingStatus } from "../../../../Dto/ActivityLicense/ActivityLicense";

export const CompanyApplicationRowUtil = (
  widthSize: number,
  icon: JSX.Element,
  status: JSX.Element,
  arrivalTime: string,
  proceedingType?: CompanyApplicationProceedingTypeEnum,
  proceedingDeadline?: Date,
  stateFeeDeadline?: Date,
  applicationNumber?: number,
  businessArea?: string,
  applicationStatus?: ActivityLicenseApplicationStatus
) => {
  const formattedProceedingType = formatProceedingType(proceedingType!);
  const formatDeadline = () => {
    if (isProceedingStatus(applicationStatus!)) {
      return (
        <>
          {proceedingDeadline && (
            <GeneralFM
              id="deadline"
              values={{ deadline: formatDate(proceedingDeadline) }}
            />
          )}
        </>
      );
    } else if (
      applicationStatus === ActivityLicenseApplicationStatus.Submitted
    ) {
      return (
        <>
          {stateFeeDeadline && (
            <GeneralFM
              id="deadline"
              values={{ deadline: formatDate(stateFeeDeadline) }}
            />
          )}
        </>
      );
    }
  };

  if (widthSize > TABLET_MAX_WIDTH) {
    return (
      <>
        <td style={{ minWidth: 40 }} className="text-center">
          {icon}
        </td>
        <td style={{ minWidth: 100 }}>{arrivalTime}</td>
        <td style={{ minWidth: 100 }}>{businessArea}</td>
        <td>{formattedProceedingType}</td>
        <td style={{ minWidth: 150 }}>{status}</td>
        <td style={{ minWidth: 158 }}>{formatDeadline()}</td>
        <td style={{ minWidth: 65 }}>{applicationNumber}</td>
      </>
    );
  } else if (widthSize > MOBILE_MAX_WIDTH) {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" md={1}>
              {icon}
            </Col>
            <Col md={3}>
              <Row className="fs-14 lh-150">
                <GeneralFM id="date" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{arrivalTime}</Row>
            </Col>
            <Col md={4}>
              <Row className="fs-14 lh-150">
                <ActivityLicenseFM id="proceedingType" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">
                {formattedProceedingType}
              </Row>
            </Col>
            <Col md={4}>
              <Row>{status}</Row>
              <Row className="fs-16 lh-131">{formatDeadline()}</Row>
            </Col>
          </Row>
        </td>
      </>
    );
  } else {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" xs="1">
              {icon}
            </Col>
            <Col>
              <Row className="fw-500 fs-16 lh-150">{arrivalTime}</Row>
              <Row className="fs-14 lh-150">
                <ActivityLicenseFM id="proceedingType" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">
                {formattedProceedingType}
              </Row>
              <Row>{status}</Row>
            </Col>
          </Row>
        </td>
      </>
    );
  }
};
