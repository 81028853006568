import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Label } from "reactstrap";

import { SingleSelect } from "../../../../../Component/Select/SingleSelect";
import { Option } from "../../../../../Component/Select/SelectTypes";
import { RoomNumberInput } from "./RoomNumberInput";
import { Room, ServiceRoom } from "../../../../../../api_client/medre_api";

interface Props {
  serviceRoom: ServiceRoom;
  setSelectedServiceRoom: (selectedService: ServiceRoom) => void;
}

export const RequiredRoomInfoCard = ({
  serviceRoom,
  setSelectedServiceRoom
}: Props) => {
  const createOption = (room: Room): Option => ({
    label: room.name,
    value: room.id
  });

  const updateNumber = (value: string) => {
    serviceRoom.number = value;
    setSelectedServiceRoom(serviceRoom);
  };

  return (
    <>
      <div className="title-container">
        <h5>
          <FormattedMessage
            id="addRoomModal.mandatoryRooms"
            defaultMessage="Kohustuslik ruum"
          />
        </h5>
      </div>
      <Card className="info-card">
        <Label htmlFor="roomInfoCardRoomTypeRequired">
          <FormattedMessage
            id="addRoomModal.roomType"
            defaultMessage="Ruumi liik"
          />
        </Label>
        <SingleSelect
          inputId="roomInfoCardRoomTypeRequired"
          disabled={true}
          value={createOption(serviceRoom.room!)}
          options={[createOption(serviceRoom.room!)]}
        />

        <Label htmlFor="roomInfoCardRoomNumberRequired">
          <FormattedMessage
            id="addRoomModal.roomNumber"
            defaultMessage="Ruumi number"
          />
        </Label>

        <RoomNumberInput
          id="roomInfoCardRoomNumberRequired"
          isDisabled={false}
          currentValue={serviceRoom.number}
          setRoomNumber={updateNumber}
        />
      </Card>
    </>
  );
};
