import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { GeneralPractitionerListSubstituteDoctorRoleEnum } from "../../api_client/medre_api";

const Messages = defineMessages({
  addEmployee: {
    id: "employeeFM.addEmployee",
    defaultMessage: "Vali töötaja"
  },
  setRoll: {
    id: "employeeFM.setRoll",
    defaultMessage: "Määra roll"
  },
  generalPractitioner: {
    id: "employeeFM.generalPractitioner",
    defaultMessage: "Perearst"
  },
  substituteDoctor: {
    id: "employeeFM.substituteDoctor",
    defaultMessage: "Asendusarst"
  },
  familyNurse: {
    id: "employeeFM.familyNurse",
    defaultMessage: "Pereõde"
  },
  doctor: {
    id: "employeeFM.doctor",
    defaultMessage: "Nimistu juures töötav arst"
  }
});

export interface Props {
  id: keyof typeof Messages;
}

export const EmployeeFM = ({ id }: Props) => (
  <FormattedMessage {...Messages[id]} />
);

interface EmployeeRoleFMProps {
  role?: GeneralPractitionerListSubstituteDoctorRoleEnum;
}
export const EmployeeRoleFM = ({ role }: EmployeeRoleFMProps) => {
  switch (role) {
    case GeneralPractitionerListSubstituteDoctorRoleEnum.SubstituteDoctor:
      return <EmployeeFM id="substituteDoctor" />;
    case GeneralPractitionerListSubstituteDoctorRoleEnum.FamilyNurse:
      return <EmployeeFM id="familyNurse" />;
    case GeneralPractitionerListSubstituteDoctorRoleEnum.Doctor:
      return <EmployeeFM id="doctor" />;
    default:
      return <EmployeeFM id="generalPractitioner" />;
  }
};
