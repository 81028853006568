import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { FormattedMessage } from "react-intl";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  FileReferenceDto,
  fileToRef
} from "../../../../Dto/File/FileReference";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import useModal from "../../../../Hook/useModal";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import {
  FileObjectType,
  OfficialUserPrivilegesEnum,
  RestorationCreation
} from "../../../../../api_client/medre_api";
import { displayAlert } from "../../../../Util/AlertUtil";
import { dateToString } from "../../../../Util/DateUtils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  objectId: string;
  type: FileObjectType;
  modalTitle?: string;
  endpoint: (
    id: string,
    restoration: RestorationCreation,
    file: any[],
    options?: any
  ) => Promise<any>;
}

type RestorationForm = {
  restorationDate?: Date;
};

export const ActivityLicenseRestorationAcceptanceModal = ({
  isOpen,
  onClose,
  objectId,
  type,
  modalTitle,
  endpoint
}: Props) => {
  const dispatch = useDispatch();
  const discardModal = useModal();
  const [restorationDate, seRestorationDate] = useState<Date>();
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileReference, setFileReference] = useState<FileReferenceDto>();

  const defaultValues: RestorationForm = {
    restorationDate: undefined
  };

  const { handleSubmit, control, errors, reset, setValue } =
    useForm<RestorationForm>({
      defaultValues,
      mode: "onBlur"
    });

  const handleClose = () => {
    reset(defaultValues);
    discardModal.handleClose();
    setIsPopOverOpen(false);
    onClose();
  };

  const handleRestorationDateChange = (date: Date) => {
    seRestorationDate(date);
    setValue("restorationDate", date, { shouldValidate: true });
  };

  const onSubmit = (form: RestorationForm) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    endpoint(
      objectId,
      { restorationDate: dateToString(restorationDate) },
      [(fileReference as FileReferenceDto).file],
      {
        withCredentials: true
      }
    )
      .then(() => {
        displayAlert("applicationSubmitted", AlertType.Success, dispatch);
        handleClose();
      })
      .catch((error: AxiosError) =>
        displayAlert("activityLicenseSubmitFailed", AlertType.Danger, dispatch)
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleFileAdd = (files: File[]) =>
    setFileReference(fileToRef(files[0], type));

  return (
    <Modal isOpen={isOpen} id="activity-license-restoration-modal">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center mb-3">
            <div className="label d-inline-flex">
              <FormattedMessage
                id="restoreActivityLicenseModal.restorationDate"
                defaultMessage="Taastamise kuupäev"
              />
            </div>
            <div className="d-flex align-items-center">
              <Controller
                control={control}
                name="restorationDate"
                rules={{ required: true }}
                defaultValue=""
                placeholder="pp.kk.aaaa"
                as={
                  <DatePickerComponent
                    onDateChange={handleRestorationDateChange}
                  />
                }
                selectedDate={restorationDate}
                valid={!!restorationDate && !errors.restorationDate}
                invalid={!!errors.restorationDate}
              />
            </div>
          </div>
          <AttachmentContainer
            key={type}
            isActive={isPopOverOpen}
            onClick={() => setIsPopOverOpen(!isPopOverOpen)}
            type={type}
            fileReferences={fileReference ? [fileReference] : []}
            maxFilesCount={1}
            saveFiles={handleFileAdd}
            handleFileDelete={() => setFileReference(undefined)}
            allowedRoles={[
              OfficialUserPrivilegesEnum.Sign,
              OfficialUserPrivilegesEnum.Proceede
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="backToApplication"
            type="reset"
            onClick={handleClose}
          />
          <PrimaryFormattedButton
            id="sendApplication"
            type="submit"
            disabled={!(fileReference && restorationDate)}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
