import React, { useState } from "react";

import "../../../../Component/Table/Table.scss";
import { ServiceDetailsRow } from "./ServiceDetailsRow";
import { ActivityLicenseService } from "../../../../../api_client/medre_api";

interface Props {
  services?: ActivityLicenseService[];
  isHospital: boolean;
  specialistBusinessAreaId?: string;
  isMobileActivityLocation?: boolean;
}

export const ServiceDetailsAccordion = ({
  services,
  isHospital,
  isMobileActivityLocation,
  specialistBusinessAreaId
}: Props) => {
  const [expandedRowNumber, setExpandedRowNumber] = useState<number>();

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      setExpandedRowNumber(rowNumber);
    } else {
      setExpandedRowNumber(undefined);
    }
  };

  return (
    <table>
      <tbody>
        {services?.map((service, i) => (
          <ServiceDetailsRow
            key={i}
            specialistBusinessAreaId={specialistBusinessAreaId}
            service={service}
            isHospital={isHospital}
            isOpen={expandedRowNumber === i}
            index={i}
            handleRowClick={handleRowClick}
            isMobileActivityLocation={isMobileActivityLocation}
          />
        ))}
      </tbody>
    </table>
  );
};
