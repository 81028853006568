export enum ApplicantDetailsSectionKeys {
  PersonalData = "PERSONAL_DATA",
  HealthcareProfessionalDetails = "HEALTHCARE_PROFESSIONAL_DETAILS",
  Applications = "APPLICATIONS",
  ContactInfo = "CONTACT_INFO",
  PreviousNames = "PREVIOUS_NAMES",
  ExternalDecisions = "EXTERNAL_DECISIONS",
  PrescriptionPower = "PRESCRIPTION_POWER",
  Education = "EDUCATION",
  MigratedDocuments = "MIGRATED_DOCUMENTS",
  MigratedDecrees = "MIGRATED_DECREES",
  Certificates = "CERTIFICATES",
  Competencies = "COMPETENCIES",
  Suspension = "SUSPENSION",
}

type SectionType = Record<ApplicantDetailsSectionKeys, { id: string; title: string }>;

const APPLICANT_DETAILS_SECTIONS: SectionType = {
  [ApplicantDetailsSectionKeys.PersonalData]: {
    id: "personal-data",
    title: "Isikuinfo",
  },
  [ApplicantDetailsSectionKeys.HealthcareProfessionalDetails]: {
    id: "healthcare-professional-details",
    title: "Tervishoiutöötaja üldandmed",
  },
  [ApplicantDetailsSectionKeys.Applications]: {
    id: "applications",
    title: "Taotlused",
  },
  [ApplicantDetailsSectionKeys.ContactInfo]: {
    id: "contact-info",
    title: "Kontaktandmed",
  },
  [ApplicantDetailsSectionKeys.PreviousNames]: {
    id: "previous-names",
    title: "Eelmised nimed",
  },
  [ApplicantDetailsSectionKeys.ExternalDecisions]: {
    id: "external-decisions",
    title: "Süsteemivälised otsused",
  },
  [ApplicantDetailsSectionKeys.PrescriptionPower]: {
    id: "prescription-power",
    title: "Retseptiõigus",
  },
  [ApplicantDetailsSectionKeys.Education]: {
    id: "education",
    title: "Haridus",
  },
  [ApplicantDetailsSectionKeys.MigratedDocuments]: {
    id: "migrated-documents",
    title: "Migreeritud dokumendid",
  },
  [ApplicantDetailsSectionKeys.MigratedDecrees]: {
    id: "migrated-decrees",
    title: "Migreeritud käskkirjad",
  },
  [ApplicantDetailsSectionKeys.Certificates]: {
    id: "certificates",
    title: "Tõendid",
  },
  [ApplicantDetailsSectionKeys.Competencies]: {
    id: "competencies",
    title: "Pädevused",
  },
  [ApplicantDetailsSectionKeys.Suspension]: {
    id: "suspension",
    title: "Tegevusõiguse peatamine",
  },
};

const APPLICANT_DETAILS_SECTIONS_LIST = Object.values(
  APPLICANT_DETAILS_SECTIONS
);

export { APPLICANT_DETAILS_SECTIONS, APPLICANT_DETAILS_SECTIONS_LIST };
