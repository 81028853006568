import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";

interface Props {
  businessArea?: string;
}

export const BusinessAreaCard = ({ businessArea }: Props) => (
  <>
    <h4 className="application-title fw-normal">
      <FormattedMessage
        id="activityLicense.businessArea"
        defaultMessage="Tegevusala"
      />
    </h4>
    <Card className="info-card mb-2">
      {renderOverViewRow("businessArea", "businessArea", businessArea)}
    </Card>
  </>
);
