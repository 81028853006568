import { PrescriptivePowerSpeciality } from "../Application/Speciality";
import { PersonStatusEnum, Speciality } from "../../../api_client/medre_api";

export interface SpecialityCode
  extends Omit<
    Speciality,
    "applicationDraftId" | keyof PrescriptivePowerSpeciality
  > {
  occupationId: string;
  specialityId: string;
}

export const prettifyPersonStatus = (status: PersonStatusEnum): string => {
  switch (status) {
    case PersonStatusEnum.Registered:
      return "Registris";
    case PersonStatusEnum.Applied:
      return "Taotleja";
    case PersonStatusEnum.Paused:
      return "Peatatud";
    case PersonStatusEnum.Archived:
      return "Arhiivis";
    default:
      return "Taotleja";
  }
};

/* export interface Employment {
  companyName: string;
} */

export interface ResidencyInfo {
  studyYear: number;
  curriculumCode: string;
  curriculumName: string;
  schoolName: string;
  occupationCode: string;
  specialityName: string;
}
