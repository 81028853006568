import React, { useState } from "react";
import Card from "reactstrap/lib/Card";

import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { BusinessRegistryInfoCard } from "../BusinessRegistryInfoCard";
import { getDocumentsContent } from "../../../Shared/ActivityLicense/Documents/DocumentsUtil";
import { StateFeeInfoModal } from "../../../Shared/StateFee/StateFeeInfoModal";
import { ManualPaymentConfirmationModal } from "../../Application/Details/ManualPaymentConfirmationModal";
import { getBaseUrl, API } from "../../../../api";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import {
  ActivityLicenseApplication,
  DetailedApplication,
  DetailedLicenseAcquisition,
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum,
  AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFactory as officialActivityPermitAPI
} from "../../../../../api_client/medre_api";
import { ClickableLicenseNumberCard } from "../ClickableLicenseNumberCard";

interface Props {
  details: DetailedLicenseAcquisition;
  setDetails: (
    details: ActivityLicenseApplication | DetailedApplication
  ) => void;
}

export const DetailsCard = ({ details, setDetails }: Props) => {
  const {
    license,
    company,
    transferor,
    submittingPersonFirstName,
    submittingPersonLastName,
    submittingPersonIdCode,
    fileReferences
  } = details;

  const submittingPersonName =
    submittingPersonFirstName + " " + submittingPersonLastName;

  const [isStateFeeInfoModalOpen, setIsStateFeeInfoModalOpen] = useState(false);
  const [
    isManualPaymentConfirmationModalOpen,
    setIsManualPaymentConfirmationModalOpen
  ] = useState(false);

  const getPaymentStatus = () => {
    if (details.status === ActivityLicenseApplicationStatus.Saved) {
      return "Tasumata";
    }
    if (details.stateFee?.paid) {
      return "Tasutud";
    }
    return (
      <>
        <div className="payment-status-wrapper">
          <span className="fw-500">Tasumata</span>
          <LinkButton
            id="paymentInstructions"
            onClick={() => setIsStateFeeInfoModalOpen(true)}
          />
        </div>
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <SecondaryFormattedButton
            id="paymentManuallyConfirmed"
            onClick={() => setIsManualPaymentConfirmationModalOpen(true)}
          />
        </ProtectedFieldWrapper>
      </>
    );
  };

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" /> Tegevusloa ülevõtmise taotluse detailid
      </h2>
      <BusinessRegistryInfoCard
        title={"Ülevõtja äriregistri andmed"}
        id={company?.id}
        name={company?.name}
        address={company?.address}
        registryCode={company?.registryCode}
      />
      <BusinessRegistryInfoCard
        title={"Üleandja äriregistri andmed"}
        id={transferor?.id}
        name={transferor?.name}
        address={transferor?.address}
        registryCode={transferor?.registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          { key: "Nimi", value: submittingPersonName || "-" },
          { key: "Isikukood", value: submittingPersonIdCode || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: company?.email || "-" },
          { key: "Telefon", value: company?.phone || "-" }
        ]}
      />
      <ClickableLicenseNumberCard
        licenseId={license?.id || ""}
        licenseNumber={license?.number || ""}
      />
      <ApplicationDetailsInfoCard
        title="Dokumendid"
        content={getDocumentsContent(fileReferences || [])}
      />
      <span id="fee" />
      <ApplicationDetailsInfoCard
        title="Riigilõiv"
        content={[{ key: "Maksmise staatus", value: getPaymentStatus() }]}
      />

      <StateFeeInfoModal
        isOpen={isStateFeeInfoModalOpen}
        onClose={() => setIsStateFeeInfoModalOpen(false)}
        deadline={details.stateFee?.stateFeeDeadline}
        referenceNumber={details.stateFee?.referenceNumber}
        stateFeeValue={details.stateFee?.stateFeeValue}
      />
      <ManualPaymentConfirmationModal
        isOpen={isManualPaymentConfirmationModalOpen}
        onClose={() => setIsManualPaymentConfirmationModalOpen(false)}
        //markAsManuallyPaid
        apiRequest={() =>
          officialActivityPermitAPI(
            undefined,
            getBaseUrl(),
            API
          ).markAsManuallyPaid(details.id!)
        }
        updateApplication={setDetails}
      />

      <ApplicationDetailsInfoCard
        title="Tervise Infosüsteemi liidestus"
        content={[
          {
            key: "TIS liidestus",
            value:
              details.status === ActivityLicenseApplicationStatus.Saved
                ? "-"
                : "Kinnitatud"
          }
        ]}
      />
    </Card>
  );
};
