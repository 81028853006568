import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { AdditionalOccupationSelection } from "./AdditionalOccupationSelection";
import { getBaseUrl, API } from "../../../../api";
import { RootState } from "../../../../rootReducer";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { OccupationSelectionMessageCard } from "./OccupationSelectionMessageCard";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import {
  Occupation,
  TaotluseAndmeteTeenusApiFactory as appDataAPI,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI
} from "../../../../../api_client/medre_api";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

export const OccupationSelection = () => {
  const dispatch = useDispatch();

  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const specialOccupationCodes = useSelector(
    (state: RootState) => state.config.specialOccupationCodes
  );
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const selectedOccupation = applicationDraft.occupation;
  const applicationDraftId = applicationDraft.id;
  const personId = applicationDraft.personId;

  const getOccupationOption = (o?: Occupation) => {
    if (!o) {
      return {} as Option;
    }
    return { value: o.code, label: o.name } as Option;
  };

  const [isAdditionalOccupationVisible, setIsAdditionalOccupationVisible] =
    useState(false);
  const [allOccupations, setAllOccupations] = useState<Occupation[]>([]);
  const [possessedOccupations, setPossessedOccupations] = useState<
    Occupation[]
  >([]);
  const [savedOccupations, setSavedOccupations] = useState<Occupation[]>([]);
  const [appliedOccupations, setAppliedOccupations] = useState<Occupation[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<Option>(
    getOccupationOption(selectedOccupation)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [initialDraft] = useState(applicationDraft);
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const occupationListContainsOccupation = useCallback(
    (occupationList: Occupation[], occupationCode?: string) => {
      if (!occupationCode || !occupationList.length) {
        return false;
      }
      return (
        occupationList.filter((o) => {
          let isNotCurrentDraft =
            o.applicationDraftId === undefined ||
            o.applicationDraftId !== applicationDraftId;
          return o.code === occupationCode && isNotCurrentDraft;
        }).length > 0
      );
    },
    [applicationDraftId]
  );

  const occupationApplied = useCallback(
    (occupationCode?: string) => {
      return (
        occupationListContainsOccupation(
          possessedOccupations,
          occupationCode
        ) ||
        occupationListContainsOccupation(appliedOccupations, occupationCode) ||
        occupationListContainsOccupation(savedOccupations, occupationCode)
      );
    },
    [
      occupationListContainsOccupation,
      possessedOccupations,
      appliedOccupations,
      savedOccupations
    ]
  );

  useEffect(() => {
    if (initialDraft.occupation && !isLoading) {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", true)
      );

      if (initialDraft.occupation?.code === specialOccupationCodes?.midwife) {
        setIsAdditionalOccupationVisible(
          !occupationApplied(specialOccupationCodes?.nurse)
        );
      } else if (
        initialDraft.occupation?.code === specialOccupationCodes?.nurse
      ) {
        setIsAdditionalOccupationVisible(
          !occupationApplied(specialOccupationCodes?.midwife)
        );
      }
    }
  }, [
    dispatch,
    initialDraft,
    isLoading,
    specialOccupationCodes,
    occupationApplied,
    possessedOccupations,
    savedOccupations,
    appliedOccupations
  ]);

  const getOccupations = useCallback(
    () =>
      appDataAPI(undefined, getBaseUrl(), API).getOccupations1("HEALTHCARE_PROFESSIONAL", {
        withCredentials: true
      }),
    []
  );

  const getOccupationApplications = useCallback(
    () =>
      isPortal
        ? appDataAPI(
            undefined,
            getBaseUrl(),
            API
          ).getUserOccupationApplications1({
            withCredentials: true
          })
        : officialAppDataAPI(
            undefined,
            getBaseUrl(),
            API
          ).getUserOccupationApplications(personId as string, {
            withCredentials: true
          }),

    [isPortal, personId]
  );

  useEffect(() => {
    getOccupations()
      .then((occupationsResponse) => {
        setAllOccupations(occupationsResponse.data);
        getOccupationApplications().then((applicationsResponse) => {
          setPossessedOccupations(
            applicationsResponse.data.possessedOccupations as Occupation[]
          );
          setSavedOccupations(
            applicationsResponse.data.savedOccupations as Occupation[]
          );
          setAppliedOccupations(
            applicationsResponse.data.appliedOccupations as Occupation[]
          );
        });
      })
      .finally(() => setIsLoading(false));
  }, [getOccupations, getOccupationApplications]);

  const selectOptions = allOccupations.map((availableOccupation) =>
    getOccupationOption(availableOccupation)
  );

  const selectBoxPlaceholder = (
    <FormattedMessage
      id="occupationSelection.placeholder"
      defaultMessage="Otsi kutset"
    />
  );

  const handleOccupationChange = (option: Option) => {
    if (occupationApplied(option.value)) {
      setIsAdditionalOccupationVisible(false);
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "additionalOccupation",
          undefined
        )
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", false)
      );
    } else {
      if (option.value === specialOccupationCodes?.midwife) {
        if (!occupationApplied(specialOccupationCodes.nurse)) {
          let additionalOccupation = allOccupations.filter(
            (availableOccupation) =>
              availableOccupation.code === specialOccupationCodes?.nurse
          )[0];
          setIsAdditionalOccupationVisible(true);
          dispatch(
            applicationDraftActions.updateApplicationDraft(
              "additionalOccupation",
              additionalOccupation
            )
          );
        } else {
          setIsAdditionalOccupationVisible(false);
          dispatch(
            applicationDraftActions.updateApplicationDraft(
              "additionalOccupation",
              undefined
            )
          );
        }
      } else if (option.value === specialOccupationCodes?.nurse) {
        setIsAdditionalOccupationVisible(
          !occupationApplied(specialOccupationCodes.midwife)
        );
        dispatch(
          applicationDraftActions.updateApplicationDraft(
            "additionalOccupation",
            undefined
          )
        );
      } else {
        setIsAdditionalOccupationVisible(false);
        dispatch(
          applicationDraftActions.updateApplicationDraft(
            "additionalOccupation",
            undefined
          )
        );
      }
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", true)
      );
    }

    let occupation = allOccupations.filter(
      (availableOccupation) => availableOccupation.code === option.value
    )[0];
    dispatch(
      applicationDraftActions.updateApplicationDraft("occupation", occupation)
    );
    setSelectedOption(option);
  };

  return (
    <>
      <h3 className="application-title fw-normal">
        <ApplicationFM id="chooseOccupation" />
      </h3>
      <p>
        <FormattedMessage
          id="occupationSelection.occupationSelectionInstruction"
          defaultMessage={`Tee omandatud kutse valik ja seejärel klõpsake nuppu "Edasi".`}
        />
      </p>
      <Row>
        <Col className="offset-lg-1 offset-md-1" lg={4} md={11} xs={12}>
          <FormGroup
            className={isMobile ? "" : "form-inline justify-content-end"}
          >
            <Label for="occupationSelect" className="mr-2">
              <FormattedMessage
                id="occupationSelection.occupation"
                defaultMessage="Kutse"
              />
            </Label>
            <SingleSelect
              className={isMobile ? "w-100" : "w-75"}
              value={selectedOption}
              placeholder={selectBoxPlaceholder}
              options={selectOptions}
              handleOptionChange={handleOccupationChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {occupationListContainsOccupation(
        possessedOccupations,
        selectedOccupation?.code
      ) && <OccupationSelectionMessageCard messageType="possessed" />}
      {occupationListContainsOccupation(
        appliedOccupations,
        selectedOccupation?.code
      ) &&
        isPortal && <OccupationSelectionMessageCard messageType="applied" />}
      {occupationListContainsOccupation(
        appliedOccupations,
        selectedOccupation?.code
      ) &&
        !isPortal && (
          <OccupationSelectionMessageCard messageType="appliedOfficial" />
        )}
      {occupationListContainsOccupation(
        savedOccupations,
        selectedOccupation?.code
      ) &&
        isPortal && <OccupationSelectionMessageCard messageType="saved" />}
      {occupationListContainsOccupation(
        savedOccupations,
        selectedOccupation?.code
      ) &&
        !isPortal && (
          <OccupationSelectionMessageCard messageType="savedOfficial" />
        )}
      {isAdditionalOccupationVisible && (
        <AdditionalOccupationSelection
          availableOccupations={allOccupations}
          selectedOccupationCode={"" + applicationDraft.occupation?.code}
        />
      )}
    </>
  );
};
