import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { Option } from "../../../Component/Select/SingleSelect";
import { useWindowWidthSize } from "../../../Hook/useWindowsSize";
import { RootState } from "../../../rootReducer";
import { API, getBaseUrl } from "../../../api";
import { Loader } from "../../../Component/Loader/Loader";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { PersonalDataFM } from "../../../Messages/PersonalDataFM";
import { MOBILE_MAX_WIDTH } from "../../../Constants";
import { inputRegex } from "../../../Util/inputRegexValidation";
import { DatePickerComponent } from "../../../Component/DatePicker/DatePickerComponent";
import { createCountryOption } from "../../../Util/OptionUtil";
import { CountrySelect } from "../../../Component/Select/CountrySelect";
import {
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  Person,
  PersonalData,
  PersonGenderEnum
} from "../../../../api_client/medre_api";
import { GenderSelect } from "../../../Component/Select/GenderSelect";
import { dateToString } from "../../../Util/DateUtils";
import { ApplicationCertificateDraft } from "../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { contactInfoFromRegistryActions } from '../../../Person/contactInfoFromRegistryActions';

interface Props {
  formInfo?: PersonalData;
  personData?: Person;
  setIsFormValid?: (value: boolean) => void;
  updatePersonInfo?: (fieldName: keyof PersonalData, value?: string) => void;
}

export const ApplicationPersonalData = ({
  formInfo,
  personData,
  updatePersonInfo,
  setIsFormValid
}: Props) => {
  const dispatch = useDispatch();

  const person = useSelector((state: RootState) =>
    personData ? personData : state.person
  );

  const isFormValid = setIsFormValid;
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopulationRegistryFailure, setIsPopulationRegistryFailure] =
    useState<boolean>(false);
  const [personalData, setPersonalData] = useState<Person>(
    (applicationDraft as ApplicationCertificateDraft).personalData || {
      ...person
    }
  );
  const { firstName, lastName, idCode } =
  formInfo || ({ ...personalData } as PersonalData);

  const [hasEstonianPersonalCode, setHasEstonianPersonalCode] =
    useState<boolean>(!!personalData.idCode);

  useEffect(() => {
    setHasEstonianPersonalCode(!!personalData.idCode);
  }, [personalData.idCode]);

  useEffect(() => {
    if (isPortal && hasEstonianPersonalCode) {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", false)
      );
      setIsLoading(true);
      const dataAPI = userDataAPI(undefined, getBaseUrl(), API);
      dataAPI
        .getPersonFromRR({
          withCredentials: true
        })
        .then((result) => {
          let personFromRRResponse = result.data;
          const { firstName, lastName, gender, citizenship, dateOfBirth } = personFromRRResponse;
          setPersonalData((previousPersonalData) => ({
            ...previousPersonalData,
            firstName,
            lastName,
            gender,
            citizenship,
            dateOfBirth
          }));
          setIsLoading(false);
          dispatch(
            applicationDraftActions.updateApplicationDraft("saveAllowed", true)
          );
          dataAPI.getContactInfoFromRR().then((result) => {
            if (result.data) {
              dispatch(contactInfoFromRegistryActions.setContactInfoFromRR(result.data));
            }
          });
        })
        .catch(() => {
          setIsLoading(false);
          setIsPopulationRegistryFailure(true);
        });
    }
  }, [isPortal, hasEstonianPersonalCode, dispatch]);

  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft(
        "personalData",
        personalData
      )
    );
  }, [personalData, dispatch]);

  useEffect(() => {
    if (isFormValid) {
      isFormValid(
        Boolean(
          firstName &&
            lastName &&
            personalData.gender &&
            personalData.citizenship &&
            personalData.dateOfBirth
        )
      );
    }
  }, [firstName, lastName, idCode, personalData, isFormValid]);

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const formGroupClassName = isMobile ? "" : "form-inline";
  const inputClassName = isMobile ? "w-100" : "w-75";
  const dpDisabledClass = hasEstonianPersonalCode
    ? " dp-input disabled"
    : " dp-input";

  const handleGenderOptionChange = (option: Option) => {
    const value = option.value as PersonGenderEnum;
    setPersonalData((previousPersonalData: PersonalData) => {
      return { ...previousPersonalData, gender: value };
    });
    if (formInfo) {
      formInfo.gender = value;
    }
  };

  const handleDobChange = (newDate?: Date) => {
    setPersonalData((previousPersonalData: PersonalData) => {
      return { ...previousPersonalData, dateOfBirth: dateToString(newDate) };
    });
    if (formInfo) {
      formInfo.dateOfBirth = dateToString(newDate);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      let value = e.target.value;
      let fieldName = e.target.name;

      setPersonalData((previousFormInfo: PersonalData) => {
        return { ...previousFormInfo, [fieldName]: value };
      });
      if (updatePersonInfo) {
        updatePersonInfo(fieldName as keyof PersonalData, value);
      }
    }
  };

  const handleCitizenshipChange = (option: Option) => {
    setPersonalData((previousPersonalData: PersonalData) => {
      return { ...previousPersonalData, citizenship: option.value };
    });
    if (formInfo) {
      formInfo.citizenship = option.value;
    }
  };

  const renderContentWithPopulationRegistryService = () =>
    isLoading ? (
      <Loader />
    ) : isPopulationRegistryFailure ? (
      renderErrorMessage()
    ) : (
      renderContent()
    );

  const renderErrorMessage = () => (
    <FormattedMessage
      id="application.populationRegistryFailure"
      defaultMessage="Rahvastikuregister ei vasta päringule. Palun proovige hiljem uuesti. "
    />
  );

  const renderContent = () => (
    <Row>
      <Col className="offset-md-1 personal-data-col" xl={6} lg={7} md={12}>
        <Form>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataFirstName"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="firstName" />
            </Label>
            <Input
              id="applicationPersonalDataFirstName"
              name="firstName"
              className={inputClassName}
              value={firstName || ""}
              disabled={hasEstonianPersonalCode}
              onChange={(e) => handleChange(inputRegex(e))}
            />
          </FormGroup>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataLastName"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="lastName" />
            </Label>
            <Input
              id="applicationPersonalDataLastName"
              name="lastName"
              className={inputClassName}
              disabled={hasEstonianPersonalCode}
              value={lastName || ""}
              onChange={(e) => handleChange(inputRegex(e))}
            />
          </FormGroup>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataIdCode"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="personalCode" />
            </Label>
            <Input
              id="applicationPersonalDataIdCode"
              name="idCode"
              className={inputClassName}
              disabled={true}
              value={
                personalData.idCode
                  ? personalData.idCode
                  : personalData.foreignIdCode
              }
            />
          </FormGroup>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataDateOfBirth"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="dateOfBirth" />
            </Label>
            <span className={inputClassName + dpDisabledClass}>
              <DatePickerComponent
                id="applicationPersonalDataDateOfBirth"
                selectedDate={personalData.dateOfBirth || undefined}
                onDateChange={handleDobChange}
                disabled={hasEstonianPersonalCode}
              />
            </span>
          </FormGroup>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataGender"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="gender" />
            </Label>
            <GenderSelect
              inputId="applicationPersonalDataGender"
              className={inputClassName}
              disabled={hasEstonianPersonalCode}
              handleOptionChange={handleGenderOptionChange}
              selectedGender={personalData.gender}
            />
          </FormGroup>
          <FormGroup className={formGroupClassName}>
            <Label
              htmlFor="applicationPersonalDataCitizenship"
              className="application-personal-data-label"
            >
              <PersonalDataFM id="citizenship" />
            </Label>
            <CountrySelect
              inputId="applicationPersonalDataCitizenship"
              className={inputClassName}
              value={createCountryOption(personalData.citizenship)}
              disabled={hasEstonianPersonalCode}
              hideSearchIcon={hasEstonianPersonalCode}
              handleOptionChange={handleCitizenshipChange}
              withNotSpecified={!isPortal}
            />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );

  const renderTitle = () => {
    if (isPortal) {
      return (
        <>
          <h3 className="application-title fw-normal">
            <FormattedMessage
              id="application.personalDataTitle"
              defaultMessage="Kontrolli isikuinfot"
            />
          </h3>
          <p>
            <FormattedMessage
              id="application.personalDataInstruction"
              defaultMessage={`Kontrolli enda isikuinfot ning kui kõik on korrektne, siis seejärel klõpsake nuppu “Edasi”.`}
            />
          </p>
        </>
      );
    }
    return <h4 className="application-title fw-normal mb-4">Isikuinfo</h4>;
  };

  return (
    <>
      {renderTitle()}
      {hasEstonianPersonalCode && isPortal
        ? renderContentWithPopulationRegistryService()
        : renderContent()}
    </>
  );
};
