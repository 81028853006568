import React, { useMemo, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import "./AddRoomsModal.scss";
import { RequiredRoomInfoCard } from "./RequiredRoomInfoCard";
import { LinkButton } from "../../../../../Component/Button/LinkButton";
import { OptionalRoomInfoCard } from "./OptionalRoomInfoCard";
import {
  ServiceRoom,
  RoomRoomTypeEnum
} from "../../../../../../api_client/medre_api";
import { ServiceRoomDraft } from "../../../../../Dto/ActivityLicense/Room";

interface Props {
  isOpen: boolean;
  serviceRooms: ServiceRoom[];
  savedServiceRooms?: ServiceRoom[];
  onClose: () => void;
  onSave: (selectedRooms?: ServiceRoom[]) => void;
  deleteRooms: (selectedRooms?: ServiceRoom[]) => void;
}

export const AddRoomsModal = ({
  isOpen,
  serviceRooms,
  savedServiceRooms,
  onClose,
  onSave,
  deleteRooms
}: Props) => {
  const savedRequiredSelectedRooms = (requiredRooms: ServiceRoom[], savedRooms?: ServiceRoom[]) => {
    if (!savedRooms) {
      return requiredRooms;
    }
    const existingSavedRequiredRoomsIds = savedRooms.map(serviceRoom => serviceRoom.room.id);
    return requiredRooms.filter(serviceRoom => !existingSavedRequiredRoomsIds.includes(serviceRoom.room.id))
      .concat(savedRooms);
  };

  const requiredRooms = serviceRooms.filter(
    (serviceRoom) => !serviceRoom.room?.isOptional
  ) as ServiceRoom[];
  const optionalRooms = serviceRooms.filter(
    (serviceRoom) => serviceRoom.room?.isOptional
  ) as ServiceRoom[];
  const [availableOptionalRooms, setAvailableOptionalRooms] =
    useState<ServiceRoom[]>(optionalRooms);
  const [selectedRooms, setSelectedRooms] =
    useState<(ServiceRoom | ServiceRoomDraft)[]>(savedRequiredSelectedRooms(requiredRooms, savedServiceRooms));
  const selectedRequiredRooms = selectedRooms.filter(
    (serviceRoom) => !serviceRoom.room?.isOptional
  );
  const selectedOptionalRooms = selectedRooms.filter(
    (serviceRoom) => serviceRoom.room?.isOptional
  );

  const addOptionalRoom = (): void => {
    const newOptionalRoom = { room: { isOptional: true } };
    setSelectedRooms((prev) => [...prev, newOptionalRoom]);
  };

  const updateOptionalSelectedRoom = (
    selectedRoom: ServiceRoom | ServiceRoomDraft,
    idx: number
  ): void => {
    const data = [...selectedOptionalRooms];
    const currentSelection = data[idx];
    if (
      !!selectedRoom &&
      selectedRoom.room?.id !== currentSelection?.room?.id
    ) {
      const updatedAvailableOptionalRooms = availableOptionalRooms.filter(
        (availableRoom) => availableRoom.room?.id !== selectedRoom.room?.id
      );
      if (currentSelection !== undefined) {
        currentSelection.number = undefined;
        updatedAvailableOptionalRooms.push(currentSelection as ServiceRoom);
      }
      setAvailableOptionalRooms(updatedAvailableOptionalRooms);
    }
    data[idx] = selectedRoom;
    setSelectedRooms(selectedRequiredRooms.concat(data));
  };

  const handleDelete = (idx: number): void => {
    const data = [...selectedOptionalRooms];
    const currentSelection = data[idx];
    if (currentSelection !== undefined) {
      const currentlyAvailableOptionalRooms = [...availableOptionalRooms];
      currentSelection.number = undefined;
      currentlyAvailableOptionalRooms.push(currentSelection as ServiceRoom);
      setAvailableOptionalRooms(currentlyAvailableOptionalRooms);
    }
    data.splice(idx, 1);
    setSelectedRooms(selectedRequiredRooms.concat(data));
    deleteRooms(data.length > 0 ? (data as ServiceRoom[]) : undefined);
  };

  const updateRequiredService = (serviceRoom: ServiceRoom): void => {
    const data = [...selectedRooms];
    const serviceIndex = data.findIndex(
      (room) => room.room?.id === serviceRoom.room?.id
    );
    data[serviceIndex] = serviceRoom;
    setSelectedRooms(data);
  };

  const selectedRoomsHaveRoomId = selectedRooms.every(
    (selectedRoom) => !!selectedRoom.room?.id
  );
  const selectedRoomsHaveNumber = selectedRooms.every(
    (selectedRoom) =>
      !!selectedRoom.number && selectedRoom.number.trim() !== ""
  );
  const mandatoryOptionalRoomsPresent = useMemo(() => {
    if (
      optionalRooms
        .map((room) => room.room?.roomType)
        .some((type) => type === RoomRoomTypeEnum.ExaminationAndReception)
    ) {
      const selectedRoomTypes = selectedRooms.map(
        (room) => room.room?.roomType
      );
      return (
        selectedRoomTypes.includes(RoomRoomTypeEnum.ExaminationAndReception) ||
        (selectedRoomTypes.includes(RoomRoomTypeEnum.Examination) &&
          selectedRoomTypes.includes(RoomRoomTypeEnum.Reception))
      );
    }
    return true;
  }, [optionalRooms, selectedRooms]);

  const initialAvailableOptionalRooms = useMemo(
    () => availableOptionalRooms,
    []
  );

  const isRoomSaved = (roomId: string) => {
    return savedServiceRooms?.some(
      (serviceRoom) => serviceRoom.room.id === roomId
    );
  };

  const handleModalClose = (): void => {
    if (savedServiceRooms) {
      selectedRooms.forEach((selectedRoom, index) => {
        if (!isRoomSaved(selectedRoom.room.id!)) {
          handleDelete(index);
        }
      });
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} id="add-rooms-modal">
      <ModalHeader tag="h4">
        <FormattedMessage
          id="addRoomModal.addRooms"
          defaultMessage="Lisa ruumid"
        />
      </ModalHeader>
      <ModalBody>
        { optionalRooms.length > 0 && (
          <p>
            <FormattedMessage
              id="addRoomsModal.instruction"
              defaultMessage="Teenusele on kohustuslik lisada valikulisi ruume."
            />
          </p>
        ) }
        { selectedRequiredRooms?.map((requiredRoom, idx) => (
          <RequiredRoomInfoCard
            key={idx}
            serviceRoom={requiredRoom as ServiceRoom}
            setSelectedServiceRoom={updateRequiredService}
          />
        )) }
        { selectedOptionalRooms.map((optionalRoom, idx) => (
          <OptionalRoomInfoCard
            key={idx + "-optional"}
            serviceRoomOptions={availableOptionalRooms}
            cardNumber={idx + 1}
            selectedServiceRoom={optionalRoom}
            handleDelete={(): void => handleDelete(idx)}
            setSelectedServiceRoom={(
              serviceRoom: ServiceRoom | ServiceRoomDraft
            ): void => updateOptionalSelectedRoom(serviceRoom, idx)}
            serviceRooms={serviceRooms}
          />
        )) }
        { selectedOptionalRooms.length <
          initialAvailableOptionalRooms.length && (
          <LinkButton
            className="d-flex"
            id={"addOptionalRoom"}
            onClick={addOptionalRoom}
          />
        ) }
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <SecondaryFormattedButton
          id="backToApplication"
          onClick={handleModalClose}
        />
        <PrimaryFormattedButton
          id="addServiceRooms"
          disabled={
            !selectedRoomsHaveNumber ||
            !selectedRoomsHaveRoomId ||
            !mandatoryOptionalRoomsPresent
          }
          onClick={(): void => onSave(selectedRooms as ServiceRoom[])}
        />
      </ModalFooter>
    </Modal>
  );
};
