import React from "react";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "../../../Footer/StickyFooter.scss";

interface Props {
  handleSave: (notes: string) => void;
  handleDiscard: () => void;
  notes: string;
}

export const ApplicationNotesStickyFooter = ({
  handleSave,
  handleDiscard,
  notes
}: Props) => (
  <div className="navbar fixed-bottom sticky-footer">
    <SecondaryFormattedButton id={"discardChanges"} onClick={handleDiscard} />
    <PrimaryFormattedButton id={"saveNote"} onClick={() => handleSave(notes)} />
  </div>
);
