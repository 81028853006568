import React from "react";
import { Input } from "reactstrap";
import { SetValueConfig } from "react-hook-form";

import { Option, Options } from "../Checkbox/CheckboxGroup";
import { WorkingDay } from "../../../api_client/medre_api";

export interface Props {
  options: Options;
  fieldName: string;
  watchField: Record<string, any>;
  setValue: (
    name: string,
    value: Array<WorkingDay>,
    config?: SetValueConfig
  ) => void;
  fieldError: Record<string, any>;
}

interface WorkingDayDto extends WorkingDay {
  [key: string]: any;
}

const WorkingHours = ({
  options,
  fieldName,
  watchField,
  setValue,
  fieldError
}: Props) => {
  const handleHoursChange = (
    value: string,
    fieldKey: string,
    workingDay: string
  ) => {
    const currentWorkingDays: Array<WorkingDayDto> = JSON.parse(
      JSON.stringify(watchField[fieldName])
    );

    const currentIndex = currentWorkingDays.findIndex(
      (wDay: WorkingDay) => wDay.workingDay === workingDay
    );
    currentWorkingDays[currentIndex][fieldKey] = value;

    setValue(fieldName, currentWorkingDays);
  };

  return (
    <>
      {options.map((option: Option) => {
        let currentWorkingDay: WorkingDay | undefined;

        const hoursEnabled = watchField[fieldName].some((wDay: WorkingDay) => {
          currentWorkingDay = wDay;
          return wDay.workingDay === option.value;
        });

        return (
          <div
            key={`${option.value}_${fieldName}`}
            className="d-flex align-items-center mb-3 contact-modal-hours"
          >
            <Input
              type="time"
              name="workingFrom"
              placeholder="09:00"
              value={
                hoursEnabled ? (currentWorkingDay?.workingFrom as string) : ""
              }
              invalid={Boolean(fieldError.workingDays)}
              className="mr-2"
              onChange={(e) =>
                handleHoursChange(e.target.value, e.target.name, option.value)
              }
              disabled={!hoursEnabled}
            />
            -
            <Input
              type="time"
              name="workingTo"
              placeholder="18:00"
              invalid={Boolean(fieldError.workingDays)}
              value={
                hoursEnabled ? (currentWorkingDay?.workingTo as string) : ""
              }
              className="ml-2"
              onChange={(e) =>
                handleHoursChange(e.target.value, e.target.name, option.value)
              }
              disabled={!hoursEnabled}
            />
          </div>
        );
      })}
    </>
  );
};

export default WorkingHours;
