import { action } from "typesafe-actions";

export const SET_EXPANDED_ACTIVITY_LICENSE_ROW = "SET_EXPANDED_ACTIVITY_LICENSE_ROW";
export const SET_EXPANDED_LOCATION_ROW = "SET_EXPANDED_LOCATION_ROW";
export const SET_EXPANDED_LOCATIONS_ROW = "SET_EXPANDED_LOCATIONS_ROW";
export const SET_SERVICES_TO_DISCARD = "SET_SERVICES_TO_DISCARD";
export const RESET_EXPANDED_ROWS = "RESET_EXPANDED_ROWS";

export const activityLicenseTableActions = {
  setExpandedActivityLicenseRow: (rowNumber: number | null) =>
    action(SET_EXPANDED_ACTIVITY_LICENSE_ROW, rowNumber),
  setExpandedLocationRow: (rowNumber: number | null) =>
    action(SET_EXPANDED_LOCATION_ROW, rowNumber),
  setExpandedLocationsRow: (rowNumber: number | null) =>
    action(SET_EXPANDED_LOCATIONS_ROW, rowNumber),
  setServicesToDiscard: (rowNumbers: string[]) =>
    action(SET_SERVICES_TO_DISCARD, rowNumbers),
  resetExpandedRows: () =>
    action(RESET_EXPANDED_ROWS),
};