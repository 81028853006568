import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label
} from "reactstrap";
import { useForm, ControllerRenderProps, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import "./PersonalDetailModal.scss";
import {
  PersonalData,
  PersonStatusEnum,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as userOfficialDataAPI,
  Person
} from "../../../../../../api_client/medre_api";
import { Loader } from "../../../../../Component/Loader/Loader";
import { PersonalDetailModalProps } from "./types";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { CountrySelect } from "../../../../../Component/Select/CountrySelect";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { DatePickerComponent } from "../../../../../Component/DatePicker/DatePickerComponent";
import { momentUTCtoString } from "../../../../../Util/DateUtils";
import { GenderSelect } from "../../../../../Component/Select/GenderSelect";
import {
  Option,
  SingleSelect
} from "../../../../../Component/Select/SingleSelect";
import { createCountryOption } from "../../../../../Util/OptionUtil";
import { API, getBaseUrl } from "../../../../../api";
import { AlertMessage } from "../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../Alert/alertActions";
import { displayAlertWithValues } from "../../../../../Util/AlertUtil";
import { RootState } from "../../../../../rootReducer";
import { getPersonalData, validateCode } from "../../../../../Util/PersonUtils";

export const PersonalDetailModal = ({
  modal,
  onClose,
  countryCode,
  reFetchAndUpdateData
}: PersonalDetailModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rrRequestImplemented, setRrRequestImplemented] =
    useState<boolean>(false);
  const [fetchedPersonalData, setFetchedPersonalData] =
    useState<PersonalData | null>(null);
  const [personExists, setPersonExists] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const { data, isOpen } = modal;
  const defaultValues: PersonalData = { ...data };

  const {
    handleSubmit,
    errors,
    reset,
    formState,
    control,
    watch,
    setValue,
    getValues
  } = useForm<PersonalData>({
    defaultValues,
    mode: "onBlur"
  });

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  useEffect(() => {
    if (fetchedPersonalData) {
      reset(fetchedPersonalData);
    }
  }, [rrRequestImplemented]);

  const onSubmit = async (formData: PersonalData) => {
    if (rrRequestImplemented && fetchedPersonalData) {
      try {
        setLoading(true);
        await userOfficialDataAPI(undefined, getBaseUrl(), API).updatePerson2(
          (data as Person).id!,
          getPersonalData(fetchedPersonalData),
          {
            withCredentials: true
          }
        );
        reFetchAndUpdateData();
      } catch (e) {
        const alertMessage = <AlertMessage id="personalDataUpdateFailed" />;
        const alert = {
          id: 0,
          type: AlertType.Danger,
          message: alertMessage
        };
        dispatch(alertActions.addAlert(alert));
        setLoading(false);
      }
    } else {
      const userData = {
        ...formData,
        foreigner: formData.foreignIdCodeCountryId !== "EST",
        foreignIdCode:
          formData.foreignIdCode === "-" ? "" : formData.foreignIdCode
      };
      try {
        setLoading(true);
        const res = await userOfficialDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).getPersonInfoFromPersonalCode(
          userData?.foreigner ? userData.foreignIdCode! : userData?.idCode!,
          userData?.foreigner ? userData?.foreignIdCodeCountryId! : "EST",
          {
            withCredentials: true
          }
        );
        if (res.data.person) {
          setFetchedPersonalData(res.data.person);
          setRrRequestImplemented(true);
        }
      } catch (error) {
        displayAlertWithValues(
          "rrRequestFailed",
          { id: userInfo?.userId },
          AlertType.Danger,
          dispatch
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    reset(defaultValues);
    onClose();
  };

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(momentUTCtoString(date));
    },
    []
  );

  const getStatusOptions = useMemo(
    () => [
      {
        label: (
          <FormattedMessage
            id="person.status.registered"
            defaultMessage="Registreeritud"
          />
        ),
        value: PersonStatusEnum.Registered
      },
      {
        label: (
          <FormattedMessage
            id="person.status.applied"
            defaultMessage="Lisatud"
          />
        ),

        value: PersonStatusEnum.Applied
      },
      {
        label: (
          <FormattedMessage
            id="person.status.archived"
            defaultMessage="Arhiveeritud"
          />
        ),
        value: PersonStatusEnum.Archived
      },
      {
        label: (
          <FormattedMessage
            id="person.status.paused"
            defaultMessage="Peatatud"
          />
        ),
        value: PersonStatusEnum.Paused
      }
    ],
    []
  );

  const handleGenderChange = useCallback(
    (field) => (gender?: Option) => {
      field.onChange(gender?.value);
    },
    []
  );

  const handleForeignCountryChange = useCallback(
    (field) => (country?: Option) => {
      if (country?.value === "EST") {
        setValue("foreignIdCode", "", { shouldValidate: true });
      } else {
        setValue("idCode", "", { shouldValidate: true });
      }
      field.onChange(country?.value);
    },
    []
  );

  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const watchFields = watch([
    "foreignIdCodeCountryId",
    "foreignIdCode",
    "idCode"
  ]);

  const fetchAndSetPerson = async (idCode: string, countryId: string) => {
    const { data } = await userOfficialDataAPI(
      undefined,
      getBaseUrl(),
      API
    ).filterPersons1(
      { idCode, idCodeCountry: countryId },
      { withCredentials: true }
    );
    setPersonExists(!!data);
    if (data) {
      const alertMessage = <AlertMessage id="personExists" />;
      const alert = {
        id: 0,
        type: AlertType.Info,
        message: alertMessage
      };
      dispatch(alertActions.addAlert(alert));
    }
  };

  const checkPersonInDB = (idCode: string, isEstonian: boolean = true) => {
    const countryId = getValues().foreignIdCodeCountryId;
    if (isEstonian) {
      if (validateCode(idCode)) {
        fetchAndSetPerson(idCode, countryId!);
      }
    } else {
      if (idCode?.trim()) {
        fetchAndSetPerson(idCode, countryId!);
      }
    }
  };

  return (
    <Modal
      id="personal-detail-modal"
      isOpen={isOpen}
      toggle={closeModal}
      centered={true}
      size={"lg"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">
          <FormattedMessage
            id="person.detail.modal.title"
            defaultMessage="Isikuandmete muutmine"
          />
        </ModalHeader>
        {loading ? <Loader absolute={true} /> : null}
        <ModalBody>
          <div className="personal-detail-modal-body">
            <FormGroup className="form-inline">
              <Label>Eesnimi</Label>
              <Controller
                control={control}
                name="firstName"
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <Input
                    name="firstName"
                    value={value === null ? "" : value}
                    onChange={onChange}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.firstName) &&
                      !errors.firstName
                    }
                    invalid={!!errors.firstName}
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Perenimi</Label>
              <Controller
                control={control}
                name="lastName"
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <Input
                    name="lastName"
                    value={value === null ? "" : value}
                    onChange={onChange}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.lastName) &&
                      !errors.lastName
                    }
                    invalid={!!errors.lastName}
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Eesti isikukood</Label>
              <Controller
                control={control}
                name="idCode"
                rules={{ validate: validateCode }}
                render={({
                  value,
                  onChange,
                  onBlur
                }: ControllerRenderProps<Record<string, any>>) => {
                  const handleIdCodeBlur = () => {
                    onBlur();
                    checkPersonInDB(value);
                  };
                  return (
                    <Input
                      className={
                        watchFields.foreignIdCodeCountryId !== "EST" && !value
                          ? "personal-detail-modal-disable-input"
                          : ""
                      }
                      name="idCode"
                      value={value ?? ""}
                      onChange={onChange}
                      onBlur={handleIdCodeBlur}
                      valid={
                        ((isSubmitted && !isSubmitSuccessful) ||
                          touched.idCode) &&
                        !errors.idCode
                      }
                      invalid={!!errors.idCode}
                      disabled={
                        watchFields.foreignIdCodeCountryId !== "EST" ||
                        rrRequestImplemented
                      }
                    />
                  );
                }}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Isikukoodi riik</Label>
              <Controller
                control={control}
                name="foreignIdCodeCountryId"
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <CountrySelect
                    defaultSelectedValue={countryCode}
                    hideSearchIcon={true}
                    handleOptionChange={handleForeignCountryChange(field)}
                    value={createCountryOption(field.value)}
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Välismaa isikukood</Label>
              <Controller
                control={control}
                name="foreignIdCode"
                rules={{ validate: (value) => !!value?.trim() }}
                render={({
                  value,
                  onChange,
                  onBlur
                }: ControllerRenderProps<Record<string, any>>) => {
                  const handleIdCodeBlur = () => {
                    onBlur();
                    checkPersonInDB(value, false);
                  };
                  return (
                    <Input
                      className={
                        watchFields.foreignIdCodeCountryId === "EST" && !value
                          ? "personal-detail-modal-disable-input"
                          : ""
                      }
                      name="foreignIdCode"
                      value={value ?? ""}
                      onChange={onChange}
                      onBlur={handleIdCodeBlur}
                      valid={
                        ((isSubmitted && !isSubmitSuccessful) ||
                          touched.foreignIdCode) &&
                        !errors.foreignIdCode
                      }
                      invalid={!!errors.foreignIdCode}
                      disabled={
                        watchFields.foreignIdCodeCountryId === "EST" ||
                        rrRequestImplemented
                      }
                    />
                  );
                }}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Sünniaeg</Label>
              <Controller
                control={control}
                name="dateOfBirth"
                defaultValue=""
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <DatePickerComponent
                    onDateChange={handleChangeDate(field)}
                    selectedDate={field.value}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.dateOfBirth) &&
                      !errors.dateOfBirth
                    }
                    invalid={!!errors.dateOfBirth}
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Sugu</Label>
              <Controller
                control={control}
                name="gender"
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <GenderSelect
                    handleOptionChange={handleGenderChange(field)}
                    selectedGender={field.value}
                    className="input"
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Kodakondsus</Label>
              <Controller
                control={control}
                name="citizenship"
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <CountrySelect
                    hideSearchIcon={true}
                    handleOptionChange={handleForeignCountryChange(field)}
                    value={createCountryOption(field.value)}
                    disabled={rrRequestImplemented}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="form-inline">
              <Label>Isiku olek</Label>
              <Controller
                name="status"
                control={control}
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <SingleSelect
                    className="personal-detail-modal-status-field"
                    value={field.value}
                    options={getStatusOptions}
                    hideSearchIcon={true}
                    disabled={true}
                  />
                )}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="reset"
            onClick={closeModal}
          />
          {rrRequestImplemented ? (
            <PrimaryFormattedButton id="confirm" type="submit" />
          ) : (
            <PrimaryFormattedButton
              id="rrRequest"
              type="submit"
              disabled={personExists}
            />
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
