import React from "react";

import "./Stepper.scss";
import stepper15 from "../../../assets/images/stepper15.svg";
import stepper25 from "../../../assets/images/stepper25.svg";
import stepper35 from "../../../assets/images/stepper35.svg";
import stepper45 from "../../../assets/images/stepper45.svg";
import stepper55 from "../../../assets/images/stepper55.svg";

type Props = {
  currentStep: number;
  className?: string;
  steps: JSX.Element[];
};

export const Stepper = ({ currentStep, className, steps }: Props) => {
  let stepper;
  switch (currentStep + 1) {
    case 1:
      stepper = stepper15;
      break;
    case 2:
      stepper = stepper25;
      break;
    case 3:
      stepper = stepper35;
      break;
    case 4:
      stepper = stepper45;
      break;
    case 5:
      stepper = stepper55;
      break;
    default:
      stepper = stepper15;
  }

  return (
    <div className={"stepper " + className}>
      <div className="d-flex flex-row">
        <div>
          <img src={stepper} alt="stepper ikoon" />
          <span className="stepper-icon-text">
            {currentStep + 1}/{steps.length}
          </span>
        </div>
        <div className="d-flex flex-column stepper-text">
          <span className="stepper-step-name">{steps[currentStep]}</span>
          {currentStep + 1 !== steps.length && (
            <span className="stepper-next-step">
              Järgmine samm: {steps[currentStep + 1]}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
