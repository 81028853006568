import * as React from "react";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  applicationStatuses,
  stateFeeStatuses
} from "../../../Shared/Search/Filters/FilterUtils";
import { MultiSelect } from "../../../../Component/Select/MultiSelect";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { OccupationSpecialityFilter } from "./OccupationSpecialityFilter";
import { CountryFilters } from "./CountryFilter";
import { TimePeriodFilter } from "./TimePeriodFilter";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";

const professionalQualificationsAllowedFilters = [
  "onlyApplicationCertificate",
  "currentApplicationNumber",
  "currentStatuses",
  "stateFee",
  "currentOccupations",
  "currentSpecialities",
  "timePeriod",
  "timePeriod",
  "currentEndDate"
];
export const ApplicationFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(filterSelectors.selectApplicationFilters);
  const set = React.useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setApplicationFilter({ key, data }));
    },
    [dispatch]
  );

  const handleChangeApplicationNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value.substring(0, 15);
    let applicationNumber = value === "" ? null : +value;
    set("currentApplicationNumber", applicationNumber);
  };

  const handleProfessionalQualificationsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    set("onlyApplicationCertificate", checked);
    if (checked) {
      Object.keys(state)
        .filter(
          (key) => !professionalQualificationsAllowedFilters.includes(key)
        )
        .forEach((key) => {
          set(key, undefined);
        });
    }
  };

  return (
    <>
      <FilterContainer>
        <FilterColumn>
          <FilterField
            id="applicationFiltersCurrentApplicationNumber"
            label="Taotluse number"
            field={
              <Input
                id="applicationFiltersCurrentApplicationNumber"
                placeholder="Taotluse number"
                value={state.currentApplicationNumber || ""}
                onChange={handleChangeApplicationNumber}
              />
            }
          />

          <FilterField
            id="applicationFiltersCurrentStatuses"
            label="Taotluse staatus"
            field={
              <MultiSelect
                inputId="applicationFiltersCurrentStatuses"
                options={applicationStatuses}
                handleOptionsChange={(e) => set("currentStatuses", e)}
                value={state.currentStatuses!}
                placeholder={
                  <FormattedMessage
                    id="filters.selectStatus"
                    defaultMessage="Vali taotluse staatus"
                  />
                }
              />
            }
          />

          <FilterField
            id="applicationFiltersStateFee"
            label="Riigilõivu staatus"
            field={
              <SingleSelect
                inputId="applicationFiltersStateFee"
                options={stateFeeStatuses}
                handleOptionChange={(e) => set("stateFee", e)}
                value={state.stateFee}
                className="input"
                isClearable={true}
                isSearchable={false}
                alwaysClearable={false}
                hideSearchIcon={true}
                placeholder={
                  <FormattedMessage
                    id="filters.selectStateFee"
                    defaultMessage="Vali riigilõivu staatus"
                  />
                }
                alwaysShowPlaceholder={true}
              />
            }
          />
        </FilterColumn>

        <FilterColumn>
          <OccupationSpecialityFilter
            id="applicationFilters"
            occupationValue={state.currentOccupations!}
            onOccupationChange={(e) => set("currentOccupations", e)}
            specialityKey="currentSpecialities"
            specialityValue={state.currentSpecialities!}
            onSpecialityChange={(e) => set("currentSpecialities", e)}
          />
          <TimePeriodFilter id="applicationFilters" set={set} state={state} />
        </FilterColumn>

        <FilterColumn>
          <CountryFilters
            id="applicationFilters"
            countryGroupValue={state.currentCountryGroup}
            countryValue={state.currentCountries!}
            onCountryChange={(e) => set("currentCountries", e)}
            onCountryGroupChange={(e) => set("currentCountryGroup", e)}
            disabled={state.onlyApplicationCertificate}
          />

          <Checkbox
            isChecked={Boolean(state.onlyApplicationCertificate)}
            id="applicationFiltersOnlyApplicationCertificate"
            labelText="Otsi vaid kutsekvalifikatsiooni tunnustamise taotlusi"
            onChange={handleProfessionalQualificationsChange}
          />
        </FilterColumn>
      </FilterContainer>
    </>
  );
};
