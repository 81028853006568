import React, { FC } from "react";
import classNames from "classnames";
import GPListLiquidationModal from "../Modals/GPListLiquidationModal";
import DetailsSection from "../../../../Component/DetailsSection/DetailsSection";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../../../Shared/GeneralPractitioners/GeneralPractitionersDetailedNavigationList";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import useModal from "../../../../Hook/useModal";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import {
  GeneralPractitionerListStatusEnum,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";

export const GPListLiquidation: FC = () => {
  const gpListLiquidationModal = useModal();
  const { list } = useGeneralPractitionerList();

  if (list.status !== GeneralPractitionerListStatusEnum.Register) {
    return null;
  }

  return (
    <ProtectedComponent
      allowedRoles={[
        OfficialUserPrivilegesEnum.Sign,
        OfficialUserPrivilegesEnum.Proceede
      ]}
    >
      <DetailsSection
        className={classNames("list-liquidation-card")}
        {...GENERAL_PRACTITIONERS_DETAILS_SECTIONS.LIQUIDATION}
      >
        {gpListLiquidationModal.isOpen && (
          <GPListLiquidationModal
            open={true}
            onClose={gpListLiquidationModal.handleClose}
          />
        )}

        <DangerFormattedButton
          id="liquidateGPList"
          className={classNames("align-self-start mt-4 mb-2")}
          onClick={gpListLiquidationModal.handleOpen}
        />
      </DetailsSection>
    </ProtectedComponent>
  );
};
