import {
  ContactInfo,
  Country,
  County,
  Parish,
  Settlement
} from "../../../api_client/medre_api";

export const clearAddressInfo = (
  updateFunction: (field: keyof ContactInfoForm, value?: string) => void
) => {
  updateFunction("apartmentNumber", undefined);
  updateFunction("countyName", undefined);
  updateFunction("ehakmk", undefined);
  updateFunction("parishName", undefined);
  updateFunction("ehakov", undefined);
  updateFunction("settlementName", undefined);
  updateFunction("ehak", undefined);
  updateFunction("adsAdrID", undefined);
  updateFunction("adsOid", undefined);
  updateFunction("fullAddress", undefined);
  updateFunction("zipCode", undefined);
};

export class ContactInfoForm {
  email: string;
  dialCode: string;
  phone: string;
  countryCode?: string;
  countyName?: string;
  ehakmk?: string;
  parishName?: string;
  ehakov?: string;
  settlementName?: string;
  ehak?: string;
  adsAdrID?: string;
  adsOid?: string;
  fullAddress?: string;
  apartmentNumber?: string;
  zipCode?: string;
  adrCode?: string;

  constructor(contactInfo: ContactInfo) {
    this.email = contactInfo.email;
    this.dialCode = contactInfo.dialCode;
    this.phone = contactInfo.phone;
    if (contactInfo.address) {
      this.countryCode = contactInfo.address.country?.code || "EST";
      this.countyName = contactInfo.address.county?.name;
      this.ehakmk = contactInfo.address.county?.ehakmk;
      this.parishName = contactInfo.address.parish?.name;
      this.ehakov = contactInfo.address.parish?.ehakov;
      this.settlementName = contactInfo.address.settlement?.name;
      this.ehak = contactInfo.address.settlement?.ehak;
      this.adsAdrID = contactInfo.address.adsAdrID;
      this.adsOid = contactInfo.address.adsOid;
      this.fullAddress = contactInfo.address.fullAddress;
      this.zipCode = contactInfo.address.zipCode;
      this.adrCode = contactInfo.address.adrCode;
    }
  }
}

export const formInfoToContactInfo = (formInfo: ContactInfoForm) => {
  const {
    countryCode,
    countyName,
    ehakmk,
    parishName,
    ehakov,
    settlementName,
    ehak,
    adsAdrID,
    adsOid,
    fullAddress,
    apartmentNumber,
    email,
    dialCode,
    phone,
    zipCode
  } = formInfo;
  let country: Country = { code: countryCode };
  let county: County = { name: countyName, ehakmk };
  let parish: Parish = { name: parishName, ehakov };
  let settlement: Settlement = { name: settlementName, ehak };

  let address = {
    country,
    county,
    parish,
    settlement,
    adsAdrID,
    adsOid,
    fullAddress: apartmentNumber
      ? `${fullAddress} - ${apartmentNumber}`
      : fullAddress,
    zipCode
  };
  let contactInfo: ContactInfo = {
    email,
    dialCode,
    phone,
    address: fullAddress ? address : {}
  };
  return contactInfo;
};
