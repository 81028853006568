import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { SigningModal } from "../../../../../../Signing/SigningModal";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { ButtonName } from "../../../../../../Component/Button/FormattedButton";
import {
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";

interface Props {
  signButtonId: ButtonName;
  refresh: () => void;
  decisionId: string;
  newStatus?: ActivityLicenseApplicationStatus;
  changeStatusTo?: (newStatus: ActivityLicenseApplicationStatus) => void;
  canRevert?: boolean;
}

export const SignActions = ({
  signButtonId,
  newStatus,
  refresh,
  changeStatusTo,
  decisionId,
  canRevert = true
}: Props) => {
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);

  return (
    <>
      <ProtectedFieldWrapper allowedRoles={[OfficialUserPrivilegesEnum.Sign]}>
        <PrimaryFormattedButton
          id={signButtonId}
          className={ActionButtonClasses}
          onClick={() => setIsSignModalOpen(true)}
        />
        <SigningModal
          isOpen={isSignModalOpen}
          onClose={() => setIsSignModalOpen(false)}
          decisionId={decisionId}
          onSuccess={() => {
            setIsSignModalOpen(false);
            refresh();
          }}
        />
      </ProtectedFieldWrapper>
      {canRevert && changeStatusTo && newStatus && (
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <SecondaryFormattedButton
            id="revertWaitingForSignatureStatus"
            className={ActionButtonClasses}
            onClick={() => changeStatusTo(newStatus)}
          />
        </ProtectedFieldWrapper>
      )}
    </>
  );
};
