import React from "react";
import { Card } from "reactstrap";

import { BusinessRegistryInfoCard } from "../BusinessRegistryInfoCard";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { ClickableLicenseNumberCard } from "../ClickableLicenseNumberCard";
import { Company, ActivityLocation } from "../../../../../api_client/medre_api";

export interface Props {
  company?: Company;
  businessAreaName?: string;
  locations?: ActivityLocation[];
  isHospital?: boolean;
  licenseNumber: string;
  licenseId: string;
  numberOfGpLists: number;
}

export const DetailsCard = ({
  company,
  businessAreaName,
  licenseNumber,
  licenseId,
  numberOfGpLists
}: Props) => {
  const { id, name, address, registryCode, email, phone } = company || {};

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" />
        Perearsti nimistute arvu muutmise taotluse detailid
      </h2>
      <BusinessRegistryInfoCard
        id={id}
        name={name}
        address={address}
        registryCode={registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ClickableLicenseNumberCard
        licenseId={licenseId || ""}
        licenseNumber={licenseNumber || ""}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: businessAreaName }]}
      />
      <ApplicationDetailsInfoCard
        title="Nimistute arv"
        content={[{ key: "Nimistute arv", value: numberOfGpLists }]}
      />
    </Card>
  );
};
