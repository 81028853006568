import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "reactstrap";
import { useCallback } from "react";
import {
  filterActions,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import { createCountryOption } from "../../../../Util/OptionUtil";
import { CountrySelect } from "../../../../Component/Select/CountrySelect";
import { Option } from "../../../../Component/Select/SingleSelect";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import {
  DecisionStatusFilter,
  statuses,
  decisionTHTStatuses
} from "./DecisionStatusFilter";
import { DecisionStatusEnum } from "../../../../../api_client/medre_api";

export const DecisionTHTFilters = () => {
  const state = useSelector(
    filterSelectors.selectDecisionTHTFilters,
    shallowEqual
  );

  const dispatch = useDispatch();
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setDecisionTHTFilter({ key, data }));
    },
    [dispatch]
  );

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );

  const handleToggleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;
      set(name, checked);
      if (checked) {
        set("decisionSearchStatuses", [statuses[DecisionStatusEnum.Accepted]]);
      }
    },
    [set]
  );

  const handleChangeSelect = useCallback(
    (option: Option, name: string) => {
      const value = option?.value ?? null;
      set(name, value);
    },
    [set]
  );

  const handleChangeMultiSelect = useCallback(
    (options: Option[], name: string) => {
      set(name, options || []);
    },
    [set]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <FilterField
          id="decisionTHTFiltersPersonName"
          label={
            <FormattedMessage id="filters.personName" defaultMessage="Isik" />
          }
          field={
            <Input
              id="decisionTHTFiltersPersonName"
              value={state.personName}
              onChange={handleChangeInput}
              name="personName"
              placeholder="THT nimi"
            />
          }
        />

        <FilterField
          id="decisionTHTFiltersDecisionNumber"
          label={
            <FormattedMessage
              id="filters.decisionNumber"
              defaultMessage="Otsuse number"
            />
          }
          field={
            <Input
              id="decisionTHTFiltersDecisionNumber"
              value={state.decisionNumber}
              onChange={handleChangeInput}
              name="decisionNumber"
              placeholder="Otsuse number"
            />
          }
        />

        <FilterField
          id="decisionTHTFiltersDecisionSearchStatuses"
          label={
            <FormattedMessage
              id="filters.decisionSearchStatuses"
              defaultMessage="Otsuse tüüp"
            />
          }
          field={
            <DecisionStatusFilter
              inputId="decisionTHTFiltersDecisionSearchStatuses"
              handleOptionsChange={handleChangeMultiSelect}
              options={decisionTHTStatuses}
              value={state.decisionSearchStatuses}
              disabled={state.acceptedAutomatically}
            />
          }
        />

        <FilterField
          field={
            <Checkbox
              isChecked={Boolean(state.acceptedAutomatically)}
              name="acceptedAutomatically"
              id="decisionTHTFiltersAcceptedAutomatically"
              labelText={
                <FormattedMessage
                  id="filters.acceptedAutomatically"
                  defaultMessage="Automaatne otsus"
                />
              }
              onChange={handleToggleCheckbox}
              className="d-inline-block"
            />
          }
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="decisionTHTFiltersCountryCode"
          label={
            <FormattedMessage
              id="filters.countryCode"
              defaultMessage="Kodakondsus"
            />
          }
          field={
            <CountrySelect
              inputId="decisionTHTFiltersCountryCode"
              name="countryCode"
              className="w-100"
              {...(state.countryCode && {
                value: createCountryOption(state.countryCode)
              })}
              handleOptionChange={handleChangeSelect}
              isClearable
              placeholder="Vali kodakondsus"
              alwaysShowPlaceholder
              withNotSpecified
            />
          }
        />

        <FilterField
          id="decisionTHTFiltersPersonIdCode"
          label={
            <FormattedMessage
              id="filters.personIdCode"
              defaultMessage="Isikukood"
            />
          }
          field={
            <Input
              id="decisionTHTFiltersPersonIdCode"
              value={state.personIdCode}
              onChange={handleChangeInput}
              name="personIdCode"
              placeholder="Isikukood"
            />
          }
        />
      </FilterColumn>

      <FilterColumn />
    </FilterContainer>
  );
};
