import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import {
  ActivityLicenseApplicationChannelTypeEnum,
  ActivityLicenseApplication
} from "../../../../api_client/medre_api";
import { ArrivalChannelContainer } from "../Application/ArrivalChannel/ArrivalChannelContainer";

export const ActivityLicenseArrivalChannel = () => {
  const dispatch = useDispatch();
  const { channelType = ActivityLicenseApplicationChannelTypeEnum.EMail } =
    useSelector(
      (state: RootState) =>
        state.activityLicenseApplication as ActivityLicenseApplication
    );

  useEffect(() => {
    if (!channelType) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "channelType",
          ActivityLicenseApplicationChannelTypeEnum.EMail
        )
      );
    }
    dispatch(
      activityLicenseApplicationActions.updateActivityLicense(
        "saveAllowed" as keyof ActivityLicenseApplication,
        true
      )
    );
  }, [dispatch, channelType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const channel = e.target.value as ActivityLicenseApplicationChannelTypeEnum;
    dispatch(
      activityLicenseApplicationActions.updateActivityLicense(
        "channelType",
        channel
      )
    );
  };

  return (
    <ArrivalChannelContainer
      handleChange={handleChange}
      channelType={channelType}
    />
  );
};
