import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { OccupationOverViewCard } from "../../../Shared/Application/OverView/OccupationOverViewCard";
import { PersonalDataOverViewCard } from "../../../Shared/Application/OverView/PersonalDataOverViewCard";
import { ContactInfoOverViewCard } from "../../../Shared/Application/OverView/ContactInfoOverViewCard";
import { EducationOverViewCard } from "../../../Shared/Application/OverView/EducationOverviewCard";
import { SpecialityOverViewCard } from "../../../Shared/Application/OverView/SpecialityOverViewCard";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import {
  DetailedApplicationTypeEnum,
  PersonalData
} from "../../../../../api_client/medre_api";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";

export const PortalApplicationOverViewHeader = () => (
  <>
    <h3 className="application-title fw-normal">
      <ApplicationFM id="submitApplication" />
    </h3>
    <p>
      <FormattedMessage
        id="applicationOverView.Instruction"
        defaultMessage={`Kontrolli taotluses edastav info üle ja seejärel klõpsa nuppu “Esita taotlus”.
           Nupu klõpsamise järel saate juhendi riigilõivu maksmiseks.`}
      />
    </p>
  </>
);

export const PortalApplicationOverView = () => {
  const dispatch = useDispatch();

  const personalData = useSelector(
    (state: RootState) => state.person
  ) as PersonalData;

  const contactInfo = useSelector(
    (state: RootState) => state.person.contactInfo
  );
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );

  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft("saveAllowed", true)
    );
  }, [dispatch]);

  return (
    <>
      <PortalApplicationOverViewHeader />
      <div className="application-overview-card-container">
        {applicationDraft.type === DetailedApplicationTypeEnum.Occupation ? (
          <OccupationOverViewCard
            occupation={applicationDraft.occupation?.name}
            additionalOccupation={applicationDraft.additionalOccupation?.name}
          />
        ) : (
          <SpecialityOverViewCard
            occupation={applicationDraft.occupation?.name}
            speciality={applicationDraft.speciality?.name}
          />
        )}
        <PersonalDataOverViewCard personalData={personalData} />
        <ContactInfoOverViewCard contactInfo={contactInfo} />
        <EducationOverViewCard
          educationInfo={applicationDraft.educationInfo}
          files={applicationDraft.fileReferences}
        />
      </div>
    </>
  );
};
