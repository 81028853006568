import {FormattedMessage} from "react-intl";
import {Card, Row} from "reactstrap";
import {WizardProgressBar} from "../../../Component/ProgressBar/WizardProgressBar";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {NewActivityLicenseServices} from "./NewActivityLicenseServices";
import {PrimaryFormattedButton} from "../../../Component/Button/PrimaryFormattedButton";
import {SecondaryFormattedButton} from "../../../Component/Button/SecondaryFormattedButton";
import {useHistory} from "react-router-dom";
import {
    AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityLicenseAppAPI
} from "../../../../api_client/medre_api";
import {API, getBaseUrl} from "../../../api";
import {displayAlert} from "../../../Util/AlertUtil";
import {AlertType} from "../../../Dto/Alert/AlertItem";

interface Props {
}
export const UpdateActivityLicenseServices = ({}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const officialLicenseApplicationAPI = officialActivityLicenseAppAPI(undefined, getBaseUrl(), API);
    const urls = {
        routes: {
            portal: "/activity-location-application",
            official: "/activity-license-applications"
        }
    };

    const activityLicenseApplication = useSelector((state: RootState) => state.activityLicenseApplication);
    const applicationNumber = activityLicenseApplication?.applicationNumber;
    const steps: JSX.Element[] =  [<FormattedMessage key="activityLicenseStep3" id="activityLicense.services" defaultMessage="Teenused"/>];
    const [saveRequestLoading, setSaveRequestLoading] = useState<boolean>(false);

    const handleSaveChangesButton = () => {
        setSaveRequestLoading(true);
        activityLicenseApplication.changeServicesRequest = true;
        officialLicenseApplicationAPI.updateActivityLicense(activityLicenseApplication.id!, activityLicenseApplication)
            .then((response) => {
                setSaveRequestLoading(false);
                displayAlert("changeRequestSucceeded", AlertType.Success, dispatch);
                history.push(`${urls.routes.official}/${activityLicenseApplication.id}`);
            })
            .catch(() => {
                setSaveRequestLoading(false);
                displayAlert("changeRequestFailed", AlertType.Danger, dispatch)
            })
    }

    const handleCancelButton = () => {
        history.push(`${urls.routes.official}/${activityLicenseApplication.id}`);
    }

    const shouldDisableSaveButton = () => {
        const services = activityLicenseApplication.locations![0].services;
        if( services === null || services === undefined) {
            return true;
        }
        return saveRequestLoading || services!.length === 0;
    }

    return (
        <div className="activity-license-container">
            <h1 className="ml-3">
                <FormattedMessage
                    id="activityLicenseApplication.updateRequest"
                    defaultMessage="Taotluse {applicationNumber} muutmine"
                    values={{ applicationNumber }}
                />
            </h1>

            <Card className="ml-3 mt-4 mb-5 mr-3">
                <div className="m-4">
                    <WizardProgressBar highestStep={0} steps={steps} disableStepNumber={true}/>
                    <NewActivityLicenseServices isUpdateActivityLicenseService={true}/>
                    <hr className="mt-5 application-footer-hr"/>
                    <Row className="ml-0 mr-0 application-footer-buttons">
                        <SecondaryFormattedButton
                            id="discard"
                            onClick={handleCancelButton}
                        />
                        <PrimaryFormattedButton
                            className={`ml-4 service`}
                            id="submitConsultationTime"
                            onClick={handleSaveChangesButton}
                            disabled={shouldDisableSaveButton()}
                        />
                    </Row>
                </div>
            </Card>
        </div>
    )
}