import React from "react";

import { ServiceContainer } from "./ServiceContainer";
import {
  ActivityLicenseService,
  Service
} from "../../../../../api_client/medre_api";

interface Props {
  locationInfoIndex: number;
  services?: ActivityLicenseService[];
  addService: (service: Service) => void;
  removeService: (serviceId?: string) => void;
  baOptionalServices: Service[];
  baRequiredServices: Service[];
}

export const ServicesList = ({
  locationInfoIndex,
  services,
  addService,
  removeService,
  baOptionalServices,
  baRequiredServices,
}: Props) => (
  <div>
    {services?.map((service) => (
      <ServiceContainer
        locationInfoIndex={locationInfoIndex}
        key={service.service?.id}
        service={service}
        serviceIds={services
          .filter((s) => s.service && s.service.id)
          .map((s) => s.service!.id)}
        addService={addService}
        removeService={removeService}
        baOptionalServices={baOptionalServices}
        baRequiredServices={baRequiredServices}
      />
    ))}
  </div>
);
