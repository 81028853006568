import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Search } from "../../Shared/Search/SearchApplication";
import { FormattedMessage } from "react-intl";
import { searchResultsActions } from "../../Shared/Search/searchResultsActions";
import * as Types from "../../Shared/Search/Filters/FilterTypes";
import { useParams } from "react-router-dom";
import { filterSelectors } from "../../Shared/Search/Filters/filterStore";
import { filterActions } from "../../Shared/Search/Filters/filterActions";
import { useHistory } from "react-router";

type PublicSearchUrl = "employees" | "gp-lists" | "licenses";
export const publicSearchUrls = ["employees", "gp-lists", "licenses"];
export const [
  publicSearchEmployeesUrl,
  publicSearchGPListsUrl,
  publicSearchLicensesUrl,
] = publicSearchUrls;

export const publicSearchFilterTypeByUrl = {
  [publicSearchEmployeesUrl]: Types.ActiveFilters.PublicHealthcareEmployees,
  [publicSearchGPListsUrl]: Types.ActiveFilters.PublicGeneralPractitionerList,
  [publicSearchLicensesUrl]: Types.ActiveFilters.PublicActivityLicenses,
};

const titles = {
  [publicSearchEmployeesUrl]: (
    <FormattedMessage
      id="HealthcareContainer.title"
      defaultMessage="Tervishoiutöötajad"
    />
  ),
  [publicSearchGPListsUrl]: (
    <FormattedMessage
      id="PublicGPListSearch.title"
      defaultMessage="Perearstinimistute otsing"
    />
  ),
  [publicSearchLicensesUrl]: (
    <FormattedMessage
      id="PublicActivityLicensesSearch.title"
      defaultMessage="Tegevuslubade otsing"
    />
  ),
};

export const PublicSearch = () => {
  const { type } = useParams<{ type: PublicSearchUrl }>();

  const dispatch = useDispatch();
  const filterType = useSelector(filterSelectors.selectFilterType);
  const filterTypeByUrl = publicSearchFilterTypeByUrl[type];

  const history = useHistory();
  useEffect(() => {
    if (!publicSearchUrls.includes(type)) {
      history.replace("/home");
    }
  }, [type, history]);

  useEffect(() => {
    if (filterType !== filterTypeByUrl) {
      dispatch(filterActions.setFilterType(filterTypeByUrl));
    }
  }, [filterType, filterTypeByUrl, dispatch]);

  useEffect(() => {
    dispatch(searchResultsActions.setData([], filterType));
    dispatch(searchResultsActions.setRowCount(0));
    dispatch(searchResultsActions.setPageCount(0));
  }, [dispatch, filterType]);

  return (
    <Search
      title={titles[type]}
      hideFilterSelector={true}
      hideResultsAtStart={true}
    />
  );
};
