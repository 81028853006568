import {
  EducationInfo,
  PersonalData
} from "../../../../../api_client/medre_api";

export const canQueryFromEHIS = (
  isPortal?: boolean,
  educationInfo?: EducationInfo,
  personalData?: PersonalData
) => {
  return (
    (isPortal && educationInfo?.fromEHIS !== false) ||
    (!isPortal && personalData?.idCode)
  );
};

export const isSwitchToAutomaticEducationPossible = (
  isPortal?: boolean,
  personalData?: PersonalData
) => {
  return isPortal || (!isPortal && personalData?.idCode);
};
