import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";
import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";

interface Props {
  licenseNumber?: string;
}

export const ActivityLicenseCard = ({licenseNumber}: Props) => {
  return (
    <>
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.activityLicense"
          defaultMessage="Tegevusluba"
        />
      </h4>
      <Card className="info-card mb-2">
        {renderOverViewRow("activityLicense", "licenseNumber", licenseNumber)}
      </Card>
    </>
  );
};