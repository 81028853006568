import React from "react";
import { Col, Row } from "reactstrap";

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../../../Constants";

export const renderServiceRow = (
  widthSize: number,
  icon: JSX.Element,
  serviceName: string
) => {
  if (widthSize > TABLET_MAX_WIDTH) {
    return (
      <>
        <td style={{ minWidth: 20 }} className="text-center">
          {icon}
        </td>
        <td className="fw-500" style={{ minWidth: 100 }}>
          {serviceName}
        </td>
      </>
    );
  } else if (widthSize > MOBILE_MAX_WIDTH) {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col md={1}>{icon}</Col>
            <Col md={10}>
              <Row className="fw-500 fs-16 lh-150">{serviceName}</Row>
            </Col>
          </Row>
        </td>
      </>
    );
  } else {
    return (
      <td colSpan={100}>
        <Row className="m-0">
          <Col className="icon-col" xs={1}>
            {icon}
          </Col>
          <Col>
            <Row className="fw-500 fs-16 lh-150">{serviceName}</Row>
          </Col>
        </Row>
      </td>
    );
  }
};
