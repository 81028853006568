import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import {
  FormattedButton,
  FormattedButtonProps
} from "../../../../Component/Button/FormattedButton";
import { GPListBulkDeletionState } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { useGeneralPractitionerListDeletionsSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";

type GPListDeleteRowButtonProps = Omit<FormattedButtonProps, "id"> & {
  index?: number;
  rowId: string;
  candidate: keyof GPListBulkDeletionState;
};

export const GPListDeleteRowButton = ({
  index,
  rowId,
  candidate,
  ...props
}: GPListDeleteRowButtonProps) => {
  const [isSelected, setSelected] = useState(false);

  const ids = useGeneralPractitionerListDeletionsSelector()[candidate];

  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(
      generalPractitionerListActions.setGPListBulkDeletions({
        [candidate]: ids.includes(rowId)
          ? ids.filter((id) => id !== rowId)
          : [...ids, rowId]
      })
    );
  }, [dispatch, candidate, ids, rowId]);

  useEffect(() => {
    setSelected(Boolean(ids.includes(rowId)));
  }, [setSelected, ids, rowId]);
  return (
    <FormattedButton
      onClick={handleClick}
      id={isSelected ? "cancel" : "remove"}
      idPrefix={index}
      className={classNames("p-0", {
        "text-danger": !isSelected
      })}
      color="link"
      {...props}
    />
  );
};
