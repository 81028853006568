import React, { useMemo } from "react";
import { Card } from "reactstrap";
import {
  ReactTable,
  ReactTableColumn,
  ReactTableExpander
} from "../../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { useMobile } from "../../../../Hook/useMobile";
import { useGPListTable } from "../useGPListTable";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";
import { PortalSubstituteDoctorPeriodModal } from "../../../Portal/GeneralPractitioners/Modals/SubstituteDoctorPeriod";
import useModal from "../../../../Hook/useModal";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { usePortal } from "../../../../Hook/usePortal";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { useGeneralPractitionerListDeletionsSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { formatDate } from "../../Application/OverView/ApplicationOverViewUtil";
import { getFullName } from "../../../../Util/PersonUtils";
import { GPListDeleteRowButton } from "./GPListDeleteRowButton";
import classNames from "classnames";
import { GeneralPractitionerListSubstituteDoctorPeriod } from "../../../../../api_client/medre_api";
import { GPListModifyRowButton } from "./GPListModifyRowButton";

const headers = {
  dateFrom: (
    <FormattedMessage
      id="GPListDetailedTableHeader.dateFrom"
      defaultMessage="Asenduse aja algus"
    />
  ),
  dateTo: (
    <FormattedMessage
      id="GPListEmployeesTableHeader.dateTo"
      defaultMessage="Asenduse aja lõpp"
    />
  ),
  substitute: (
    <FormattedMessage
      id="GPListEmployeesTableHeader.substitute"
      defaultMessage="Asendaja"
    />
  )
};

export const GPListSubstitutePeriod = () => {
  const isMobile = useMobile();
  const isPortal = usePortal();
  const {
    list: { substituteDoctorsPeriod, substituteDoctors }
  } = useGeneralPractitionerList();
  const {
    substituteDoctors: selectedSubstituteDoctors = [],
    substituteDoctorPeriods: selectedSubstituteDoctorPeriods = []
  } = useGeneralPractitionerListDeletionsSelector();

  const columns: ReactTableColumn<GeneralPractitionerListSubstituteDoctorPeriod>[] =
    useMemo(() => {
      const baseColumns: ReactTableColumn<GeneralPractitionerListSubstituteDoctorPeriod>[] =
        [
          {
            collapse: isMobile,
            Header: headers.dateFrom,
            accessor: "dateFrom",
            Cell: (cell) =>
              isMobile ? (
                <>
                  <div className="font-size-14">{headers.dateFrom}</div>
                  <span className="font-weight-500">
                    {formatDate(cell.value)}
                  </span>
                </>
              ) : (
                formatDate(cell.value)
              ),
            width: 50
          },
          {
            collapse: isMobile,
            Header: headers.dateTo,
            accessor: "dateTo",
            Cell: (cell) =>
              isMobile ? (
                <>
                  <div className="font-size-14">{headers.dateTo}</div>
                  <span className="font-weight-500">
                    {formatDate(cell.value)}
                  </span>
                </>
              ) : (
                formatDate(cell.value)
              ),
            width: 50
          }
        ];

      if (isMobile) {
        baseColumns.unshift({
          id: "expander",
          accessor: "substituteDoctorId",
          Header: headers.substitute,
          Cell: (cell) => {
            const doctor = substituteDoctors.find(
              (doctorItem) => doctorItem.id === cell.value
            );
            if (!doctor) {
              return null;
            }
            return (
              <ReactTableExpander cell={cell}>
                {getFullName(doctor)}
              </ReactTableExpander>
            );
          }
        });
      }

      if (!isMobile) {
        baseColumns.splice(2, 0, {
          id: "substituteDoctorId",
          accessor: "substituteDoctorId",
          Header: headers.substitute,
          Cell: ({ value: substituteDoctorId }) => {
            const doctor = substituteDoctors.find(
              (doctorItem) => doctorItem.id === substituteDoctorId
            );
            if (!doctor) {
              return null;
            }
            return getFullName(doctor);
          },
          width: 0
        });
      }

      if (isPortal) {
        baseColumns.push({
          collapse: isMobile,
          accessor: "substituteDoctorId",
          id: "actions",
          sticky: !isMobile,
          Cell: (cell) => (
            <>
              <GPListModifyRowButton index={cell.row.index}>
                {(modal) => (
                  <PortalSubstituteDoctorPeriodModal
                    defaultValues={cell.row.original}
                    open={modal.isOpen}
                    onClose={modal.handleClose}
                  />
                )}
              </GPListModifyRowButton>
              <GPListDeleteRowButton
                index={cell.row.index}
                rowId={cell.row.original.id as string}
                candidate="substituteDoctorPeriods"
                disabled={Boolean(
                  selectedSubstituteDoctors.includes(cell.value as string)
                )}
              />
            </>
          ),
          disableSortBy: true,
          width: 0
        });
      }

      return baseColumns;
    }, [isMobile, selectedSubstituteDoctors, substituteDoctors, isPortal]);

  const table = useGPListTable<GeneralPractitionerListSubstituteDoctorPeriod>({
    data: substituteDoctorsPeriod,
    columns
  });

  const substituteDoctorPeriodModal = useModal();
  return (
    <Card className="my-4 pb-1 p-0 p-md-4">
      {substituteDoctorPeriodModal.isOpen && (
        <PortalSubstituteDoctorPeriodModal
          open={true}
          onClose={substituteDoctorPeriodModal.handleClose}
        />
      )}

      <div
        id={
          GENERAL_PRACTITIONERS_DETAILS_SECTIONS.SUBSTITUTE_REPLACEMENT_PERIOD
            .id
        }
        className="h2 d-flex justify-content-between pt-3 pr-3 pl-3 p-md-0"
      >
        <h2>
          {
            GENERAL_PRACTITIONERS_DETAILS_SECTIONS.SUBSTITUTE_REPLACEMENT_PERIOD
              .title
          }
        </h2>

        {isPortal && (
          <div className="d-none d-sm-block">
            <PrimaryFormattedButton
              id="addSubstituteDoctorPeriod"
              idPrefix="sm"
              onClick={substituteDoctorPeriodModal.handleOpen}
            />
          </div>
        )}
      </div>

      {isPortal && (
        <div className="d-block d-sm-none pl-3">
          <PrimaryFormattedButton
            id="addSubstituteDoctorPeriod"
            idPrefix="xs"
            onClick={substituteDoctorPeriodModal.handleOpen}
          />
        </div>
      )}

      <div className="mt-3">
        <ReactTable<GeneralPractitionerListSubstituteDoctorPeriod>
          table={table}
          getHeaderProps={(column) =>
            column.getHeaderProps(column.getSortByToggleProps())
          }
          rows={table.rows}
          getRowProps={(row) =>
            row.getRowProps({
              className: classNames({
                danger:
                  selectedSubstituteDoctors.includes(
                    row.values.substituteDoctorId
                  ) || selectedSubstituteDoctorPeriods.includes(row.id)
              })
            })
          }
        />
      </div>
    </Card>
  );
};
