import React, { useMemo } from "react";
import { PersonalDataFM } from "../Messages/PersonalDataFM";
import { Option } from "../Component/Select/SingleSelect";
import { translateCountry } from "../Component/Translate/CountryTranslator";
import {
  WorkingDayWorkingDayEnum,
  PersonGenderEnum
} from "../../api_client/medre_api";
import { useWorkingDays } from "./DateUtils";

export const createGenderOption = (gender?: PersonGenderEnum): Option => {
  if (!gender) {
    return { value: "", label: "" };
  } else if (gender === PersonGenderEnum.Male) {
    return {
      value: PersonGenderEnum.Male,
      label: <PersonalDataFM id="male" />
    };
  } else {
    return {
      value: PersonGenderEnum.Female,
      label: <PersonalDataFM id="female" />
    };
  }
};

export const createCountryOption = (countryCode?: string): Option => {
  return { value: countryCode!, label: translateCountry(countryCode) };
};

export const useWorkingDayOptions = () => {
  const workingDays = useWorkingDays();
  return useMemo(
    () =>
      Object.keys(workingDays).map((key) => ({
        label: workingDays[key as WorkingDayWorkingDayEnum].min,

        value: key
      })),
    [workingDays]
  );
};
