import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { ApplicationDeclinationModal } from "./ApplicationDeclinationModal";
import { ApplicationAcceptanceModal } from "./ApplicationAcceptanceModal";
import {
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import { ApplicationDeleteConfirmationModal } from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";

interface Props {
  applicationNumber: number | undefined;
  changeStatusTo: (newStatus: ActivityLicenseApplicationStatus) => void;
  newAcceptanceStatus: ActivityLicenseApplicationStatus;
  newDeclinationStatus?: ActivityLicenseApplicationStatus;
  canDecline?: boolean;
  canAccept?: boolean;
  applicationId?: string;
  deleteApplication?: (applicationId: string) => void;
  hasExtendedRoles?: boolean;
}

export const AcceptanceActions = ({
  applicationNumber,
  changeStatusTo,
  newAcceptanceStatus,
  newDeclinationStatus,
  canDecline = true,
  canAccept = true,
  applicationId,
  deleteApplication,
  hasExtendedRoles
}: Props) => {
  const [isDeclinationModalOpen, setIsDeclinationModalOpen] = useState(false);
  const [isAcceptanceModalOpen, setIsAcceptanceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAppDelete = () => {
    deleteApplication!(applicationId!);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        {canAccept && (
          <>
            <PrimaryFormattedButton
              id="acceptApplication"
              className={ActionButtonClasses}
              onClick={() => setIsAcceptanceModalOpen(true)}
            />
            <ApplicationAcceptanceModal
              applicationNumber={applicationNumber}
              isOpen={isAcceptanceModalOpen}
              onClose={() => setIsAcceptanceModalOpen(false)}
              onAccept={() => changeStatusTo(newAcceptanceStatus)}
              modalMessage="Rahuldamise otsus suunatakse allkirjastamisele"
            />
          </>
        )}
        {canDecline && newDeclinationStatus && (
          <>
            <SecondaryFormattedButton
              id="declineApplication"
              className={ActionButtonClasses}
              onClick={() => setIsDeclinationModalOpen(true)}
            />
            <ApplicationDeclinationModal
              applicationNumber={applicationNumber}
              isOpen={isDeclinationModalOpen}
              onClose={() => setIsDeclinationModalOpen(false)}
              onDecline={() => changeStatusTo(newDeclinationStatus)}
              modalMessage="Keeldumise otsus suunatakse allkirjastamisele"
            />
          </>
        )}
      </ProtectedFieldWrapper>
      {deleteApplication && applicationId && (
        <ProtectedComponent
          allowedRoles={hasExtendedRoles ?
            [OfficialUserPrivilegesEnum.Sign, OfficialUserPrivilegesEnum.Proceede] :
            [OfficialUserPrivilegesEnum.Sign]}
        >
          <DangerFormattedButton
            id="deleteApplication"
            className={ActionButtonClasses}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </ProtectedComponent>
      )}
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
    </>
  );
};
