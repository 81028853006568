import React from "react";
import { CustomInput } from "reactstrap";

import "./RadioButton.scss";

export interface Props {
  id: string;
  value: string;
  selected: boolean;
  name: string;
  labelText: string | JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inline?: boolean;
  disabled?: boolean;
}

export const RadioButton = ({
  id,
  name,
  value,
  selected,
  labelText,
  onChange,
  inline,
  disabled
}: Props) => (
  <CustomInput
    type="radio"
    id={id}
    name={name}
    value={value}
    label={labelText}
    checked={selected}
    onChange={onChange}
    inline={inline ?? false}
    disabled={disabled}
  />
);
