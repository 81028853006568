import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";

import you_icon from "../../../../../assets/images/you.svg";
import house_icon from "../../../../../assets/images/house.svg";
import { Loader } from "../../../../Component/Loader/Loader";
import { SubmissionTimeLineEvent } from "../../Application/Submitted/SubmissionTimeLineEvent";
import "../../Application/Submitted/SubmissionTimeLine.scss";
import { KlassifikaatoriteTeenusApiFactory as classifierServiceAPI } from "../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../api";

interface Props {
  isPaid?: boolean;
}

export const ActivityLicenseSubmissionTimeLine = ({ isPaid }: Props) => {
  const stateFeeDeadline = useSelector(
    (state: RootState) => state.config.activityLicenseStateFeeDeadline
  );
  const [loading, setLoading] = useState(true);
  const [proceedingDeadlineDays, setProceedingDeadlineDays] =
    useState<number>();

  const stateFeeMessage = "upToXWorkingDays";
  const decisionMessage = "applicationDecision";

  useEffect(() => {
    classifierServiceAPI(undefined, getBaseUrl(), API)
      .getActivityLicenseProceedingDeadline({ withCredentials: true })
      .then((res) => setProceedingDeadlineDays(res.data))
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="timeline">
      <SubmissionTimeLineEvent
        first={true}
        icon={you_icon}
        userText="you"
        status="done"
        comment="applicationSubmitted"
        days={stateFeeDeadline}
        info="xDays"
      />
      <SubmissionTimeLineEvent
        icon={you_icon}
        userText="you"
        status={isPaid ? "done" : "in-progress"}
        comment="payingStateFee"
        info={stateFeeMessage}
        days={proceedingDeadlineDays}
      />
      <SubmissionTimeLineEvent
        icon={house_icon}
        userText="healthAgency"
        status={isPaid ? "in-progress" : undefined}
        comment={decisionMessage}
        last={true}
      />
    </div>
  );
};
