import React from "react";
import "./Footer.scss";
import { FooterTop } from "./FooterTop";
import { FooterBottom } from "./FooterBottom";

export const Footer = () => {
  return (
    <div className="footer">
      <FooterTop/>
      <FooterBottom/>
    </div>
  );
};
