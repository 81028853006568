import { Card } from "reactstrap";
import React, { FC } from "react";
import { LinkButton } from "../../../Component/Button/LinkButton";
import {
  OpenDataFM,
  OpenDataLinkFM, OpenDataLinkFMProps,
} from "../../../Messages/OpenDataFM";
import "./PublicOpenData.scss";
import { FormattedButton } from "../../../Component/Button/FormattedButton";

interface OpenDataItem {
  id: OpenDataLinkFMProps["id"];
  links: string[];
}

const openDataItemsMain: OpenDataItem[] = [
  {
    id: "authorities",
    links: [
      "/api-common/public/companies/open-data",
      "/api-common/public/open-data/companies/description",
    ],
  },
  {
    id: "lists",
    links: [
      "/api-common/public/general-practitioner-lists/open-data",
      "/api-common/public/open-data/lists/description",
    ],
  },
  {
    id: "pharmacists",
    links: [
      "/api-common/public/persons/pharmacists/open-data",
      "/api-common/public/open-data/pharmacists/description",
    ],
  },
];

const openDataItemsAdditional: OpenDataItem[] = [
  {
    id: "specialties",
    links: ["/api-common/public/persons/specialities/open-data"],
  },
  {
    id: "invitations",
    links: ["/api-common/public/persons/occupations/open-data"],
  },
  {
    id: "services",
    links: ["/api-common/public/activity-licenses/services/open-data"],
  },
];

const OpenDataLinks: FC<{ items: OpenDataItem[] }> = ({ items }) => (
  <>
    {items.map(({ id, links: [linkXML, linkPDF] }) => (
      <p key={id} className="file-link ml-3">
        <a className="mx-1 link" href={linkXML}>
          <OpenDataLinkFM id={id} />
        </a>
        {linkPDF && (
          <>
            (
            <FormattedButton
              id="descriptionLink"
              color="link"
              tag="a"
              target="_blank"
              href={linkPDF}
              className="p-0 border-0"
            />
            )
          </>
        )}
      </p>
    ))}
  </>
);

export const PublicOpenData = () => {
  return (
    <>
      <h1 className="mb-3 ml-3 font-weight-light">
        <OpenDataFM id="title" />
      </h1>
      <p className="ml-3">
        <OpenDataFM id="subtitle" />
      </p>
      <Card className="pt-3 content-container-card">
        <h3 className="ml-3 font-weight-light">
          <OpenDataFM id="cardTitle" />
        </h3>

        <OpenDataLinks items={openDataItemsMain} />

        <span className="mb-3 ml-3">
          <OpenDataFM id="classificationsTitle" />
        </span>

        <OpenDataLinks items={openDataItemsAdditional} />

        <a
          className="mb-3 ml-1"
          target="_blank"
          href="/api-common/public/open-data/data/description"
        >
          <LinkButton id="linkToDescriptionOfOpenData" />
        </a>
      </Card>
    </>
  );
};
