import React from "react";
import { History } from "history";
import { Dispatch } from "redux";

import { getBaseUrl, API } from "../../../api";
import {
  POWER_OF_ATTORNEY_TYPE_PREFIX,
  REPRESENTATIVE_PREFIX
} from "../../../Constants";
import { userActions } from "../../../Security/userActions";
import { alertActions } from "../../../Alert/alertActions";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { personActions } from "../../../Person/personActions";
import {
  Person,
  PersonStatusEnum,
  BusinessInfo,
  OfficialUserPrivilegesEnum,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  FirmadeAndmeteTeenusApiFactory as companyDataAPI
} from "../../../../api_client/medre_api";

export const selectCompany = (
  company: BusinessInfo,
  dispatch: Dispatch,
  history: History
) => {
  companyDataAPI(undefined, getBaseUrl(), API)
    .selectCompany(company, {
      withCredentials: true
    })
    .then((data) => {
      const roles = [REPRESENTATIVE_PREFIX + company.registryCode];
      const powerOfAttorneyType = data?.data?.powerOfAttorney?.type;
      if (powerOfAttorneyType) {
        roles.push(POWER_OF_ATTORNEY_TYPE_PREFIX + powerOfAttorneyType);
      }
      dispatch(userActions.updateUserRoles(roles));
      history.push("/");
    })
    .catch(() => {
      const alertMessage = <AlertMessage id="companySelectionFailed" />;

      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    });
};

export const selectPersonRole = (
  status: PersonStatusEnum | undefined,
  dispatch: Dispatch,
  clearCompany?: boolean
) => {
  if (clearCompany) {
    companyDataAPI(undefined, getBaseUrl(), API).unSelectCompany({
      withCredentials: true
    });
  }
  if (status === null) {
    userDataAPI(undefined, getBaseUrl(), API)
      .updateStatusToApplied({
        withCredentials: true
      })
      .then((res) => {
        const person: Person = res.data;
        dispatch(personActions.setPerson(person));
        dispatch(
          userActions.updateUserRoles([OfficialUserPrivilegesEnum.Person])
        );
      });
  } else {
    dispatch(userActions.updateUserRoles([OfficialUserPrivilegesEnum.Person]));
  }
};
