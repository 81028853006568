import React, { useMemo } from "react";
import { Card } from "reactstrap";
import {
  ReactTable,
  ReactTableColumn,
  ReactTableExpander
} from "../../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { useMobile } from "../../../../Hook/useMobile";
import { useGPListTable } from "../useGPListTable";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";
import { usePortal } from "../../../../Hook/usePortal";
import useModal from "../../../../Hook/useModal";
import { PortalSubstituteDoctorModal } from "../../../Portal/GeneralPractitioners/Modals/SubstituteDoctor";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { getFullName } from "../../../../Util/PersonUtils";
import { EmployeeRoleFM } from "../../../../Messages/EmployeeFM";
import { GPListDeleteRowButton } from "./GPListDeleteRowButton";
import { useGeneralPractitionerListDeletionsSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { GeneralPractitionerListSubstituteDoctor } from "../../../../../api_client/medre_api";
import classNames from "classnames";

const headers = {
  fullName: (
    <FormattedMessage
      id="GPListDetailedTableHeader.fullName"
      defaultMessage="Nimi"
    />
  ),
  occupationCode: (
    <FormattedMessage
      id="GPListDetailedTableHeader.occupationCode"
      defaultMessage="Kood"
    />
  ),
  role: (
    <FormattedMessage
      id="GPListDetailedTableHeader.role"
      defaultMessage="Roll"
    />
  )
};

export const GPListSubstituteDoctors = () => {
  const {
    list: { substituteDoctors }
  } = useGeneralPractitionerList();
  const { substituteDoctors: selectedSubstituteDoctors = [] } =
    useGeneralPractitionerListDeletionsSelector();

  const isMobile = useMobile();
  const isPortal = usePortal();
  const columns: ReactTableColumn<GeneralPractitionerListSubstituteDoctor>[] =
    useMemo(() => {
      const baseColumns: ReactTableColumn<GeneralPractitionerListSubstituteDoctor>[] =
        [
          {
            accessor: "role",
            id: "expander",
            Header: headers.fullName,
            Cell: (cell) =>
              isMobile ? (
                <ReactTableExpander cell={cell}>
                  <div>{getFullName(cell.row.original)}</div>
                  <EmployeeRoleFM role={cell.value} />
                </ReactTableExpander>
              ) : (
                getFullName(cell.row.original)
              ),
            width: 50
          },
          {
            collapse: isMobile,
            accessor: "occupationCode",
            Header: headers.occupationCode,
            Cell: (cell) =>
              isMobile ? (
                <>
                  <div className="font-size-14">{headers.occupationCode}</div>
                  <span className="font-weight-500">{cell.value?.code}</span>
                </>
              ) : (
                cell.value?.code
              ),
            width: 0
          }
        ];

      if (!isMobile) {
        baseColumns.splice(1, 0, {
          Header: headers.role,
          id: "role",
          accessor: "role",
          Cell: ({ value: role }) => <EmployeeRoleFM role={role} />,
          width: 50
        });
      }

      if (isPortal) {
        baseColumns.push({
          sticky: !isMobile,
          collapse: isMobile,
          accessor: "id",
          Cell: (cell) => (
            <>
              <GPListDeleteRowButton
                rowId={cell.value as string}
                candidate="substituteDoctors"
              />
            </>
          ),
          disableSortBy: true,
          width: 0
        });
      }

      return baseColumns;
    }, [isMobile, isPortal]);

  const table = useGPListTable<GeneralPractitionerListSubstituteDoctor>({
    data: substituteDoctors,
    columns
  });

  const substituteDoctorModal = useModal();
  return (
    <Card className="my-4 pb-1 p-0 p-md-4">
      {substituteDoctorModal.isOpen && (
        <PortalSubstituteDoctorModal
          open={true}
          onClose={substituteDoctorModal.handleClose}
        />
      )}

      <div
        id={GENERAL_PRACTITIONERS_DETAILS_SECTIONS.SUBSTITUTE_DOCTORS.id}
        className="h2 d-flex justify-content-between pt-3 pr-3 pl-3 p-md-0"
      >
        <h2>
          {GENERAL_PRACTITIONERS_DETAILS_SECTIONS.SUBSTITUTE_DOCTORS.title}
        </h2>

        {isPortal && (
          <div className="d-none d-sm-block">
            <PrimaryFormattedButton
              id="addSubstituteDoctor"
              idPrefix="sm"
              onClick={substituteDoctorModal.handleOpen}
            />
          </div>
        )}
      </div>

      {isPortal && (
        <div className="d-block d-sm-none pl-3">
          <PrimaryFormattedButton
            id="addSubstituteDoctor"
            idPrefix="xs"
            onClick={substituteDoctorModal.handleOpen}
          />
        </div>
      )}

      <div className="mt-3">
        <ReactTable<GeneralPractitionerListSubstituteDoctor>
          table={table}
          getHeaderProps={(column) =>
            column.getHeaderProps(column.getSortByToggleProps())
          }
          rows={table.rows}
          getRowProps={(row) =>
            row.getRowProps({
              className: classNames({
                danger: selectedSubstituteDoctors.includes(row.id)
              })
            })
          }
        />
      </div>
    </Card>
  );
};
