import React from "react";

import { SecondaryFormattedButton } from "../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../Component/Button/PrimaryFormattedButton";
import "./StickyFooter.scss";
import { ButtonName } from "../../Component/Button/FormattedButton";

interface Props {
  handleConfirm: () => void;
  handleDiscard: () => void;
  saveMessageId?: ButtonName;
}

export const ConfirmationStickyFooter = ({
  handleDiscard,
  handleConfirm,
  saveMessageId = "saveAllChanges"
}: Props) => (
  <div className="navbar fixed-bottom sticky-footer">
    <SecondaryFormattedButton id="discardChanges" onClick={handleDiscard} />
    <PrimaryFormattedButton id={saveMessageId} onClick={handleConfirm} />
  </div>
);
