import React, { useCallback, useMemo } from "react";
import Select, {
  components,
  ControlProps,
  createFilter,
  IndicatorProps,
  OptionProps,
  OptionTypeBase,
  PlaceholderProps,
  Styles,
  ValueType
} from "react-select";
import classNames from "classnames";
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { NoticeProps } from "react-select/src/components/Menu";
import { ValueContainerProps } from "react-select/src/components/containers";

import { Option, Props, State } from "./SelectTypes";
import "./CustomSelect.scss";

interface CustomSelectProps extends Omit<Props, "value"> {
  value?: Props["value"] | string | any;
}

export const CustomSelect = React.forwardRef(
  (
    {
      isMulti,
      handleOptionsChange,
      handleOptionChange,
      options,
      value,
      name = "",
      isSearchable,
      isClearable,
      closeMenuOnSelect,
      disabled,
      alwaysClearable,
      placeholder,
      alwaysShowPlaceholder,
      hideSearchIcon,
      disableOnOneOption,
      filterOptions,
      inputId,
      isValid,
      isInValid,
      ...props
    }: CustomSelectProps,
    // tslint:disable-next-line:no-any
    ref: React.Ref<any>
  ) => {
    const allOptions = useMemo(
      () =>
        [...options].reduce<Option[]>((acc, groupOrOption) => {
          if ("options" in groupOrOption) {
            return [...acc, ...groupOrOption.options];
          }
          return [...acc, groupOrOption as Option];
        }, []),
      [options]
    );

    const selectedValue = useMemo(
      () =>
        typeof value === "string"
          ? allOptions.find((option) => option.value === value)
          : value,

      [value, allOptions]
    );

    const hasOnlyOneOption = options.length === 1;

    const getBorderColor = useCallback(
      (props: ControlProps<Option, boolean>) =>
        props.isDisabled ? "#ced4da" : props.menuIsOpen ? "#005AA3" : "#A6A8B1",
      []
    );

    const customStyles : Partial<Styles<Option, boolean>> = {
      placeholder: (base: React.CSSProperties, props: PlaceholderProps<Option, boolean>) => ({
        ...base,
        paddingLeft: hideSearchIcon ? 0 : 24,
        display:
          props.isFocused || alwaysShowPlaceholder ? base.display : "none"
      }),
      singleValue: (base: React.CSSProperties) => ({
        ...base,
        paddingLeft:
          (disableOnOneOption && hasOnlyOneOption) || hideSearchIcon ? 0 : 24,
        color: disabled ? "#495057" : base.color,
        backgroundColor: disabled ? "#E9ECEF" : base.backgroundColor
      }),
      multiValue: (base: React.CSSProperties) => ({
        ...base,
        backgroundColor: disabled ? "#E9ECEF" : "white",
        fontSize: "111%",
        minWidth: "auto",
        padding: 0,
        ":not(:nth-last-of-type(2)):after": {
          content: "','",
          display: "inline-block"
        },
        "div:first-of-type": {
          paddingLeft: 0,
          paddingRight: 1
        }
      }),
      input: (base: React.CSSProperties) => ({
        ...base,
        paddingLeft: hideSearchIcon ? 0 : 8,
        width: 50
      }),
      dropdownIndicator: (base: React.CSSProperties) => ({
        ...base,
        paddingLeft: 0,
        color: "black"
      }),
      indicatorSeparator: (base: React.CSSProperties) => ({
        ...base,
        paddingLeft: 0
      }),
      control: (base: React.CSSProperties, props: ControlProps<Option, boolean>) => ({
        ...base,
        borderColor: getBorderColor(props),
        boxShadow: "none",
        overflow: isMulti ? "hidden" : "",
        backgroundColor: props.isDisabled ? "#e9ecef" : base.backgroundColor,
        height: 38,
        "div:first-of-type": {
          flexWrap: "nowrap"
        }
      }),
      option: (base: React.CSSProperties, props: OptionProps<Option, boolean>) => ({
        ...base,
        color: props.isSelected && isMulti ? "inherit" : base.color,
        backgroundColor: props.isSelected
          ? isMulti
            ? props.isFocused
              ? "#DBDFE2"
              : "white"
            : "#005AA3"
          : base.backgroundColor,
        display: isMulti ? "flex" : base.display,
        alignItems: "center"
      })
    };

    const DropdownIndicator = useCallback(
      (passedProps: IndicatorProps<Option, boolean>) => (
        <components.DropdownIndicator {...passedProps}>
          <FontAwesomeIcon icon={faCaretDown} />
        </components.DropdownIndicator>
      ),
      []
    );

    const NoOptionsMessage = useCallback(
      (passedProps: NoticeProps<Option, boolean>) => (
        <components.NoOptionsMessage {...passedProps}>
          <FormattedMessage
            id="singleSelect.NoOptions"
            defaultMessage="Valikuid ei leitud"
          />
        </components.NoOptionsMessage>
      ),
      []
    );

    const ValueContainer = useCallback(
      (passedProps: ValueContainerProps<Option, boolean>) =>
        components.ValueContainer && (
          <components.ValueContainer {...passedProps}>
            {!passedProps.selectProps.isDisabled && !hideSearchIcon && (
              <FontAwesomeIcon icon={faSearch} />
            )}
            {passedProps.children}
          </components.ValueContainer>
        ),
      [hideSearchIcon]
    );

    const ControlComponent = useCallback(
      ({ className, ...passedProps }: ControlProps<Option, boolean>) =>
        components.Control && (
          <components.Control
            {...passedProps}
            className={classNames(className, "form-control", {
              "is-valid": isValid,
              "is-invalid": isInValid
            })}
          >
            {passedProps.children}
          </components.Control>
        ),
      [isValid, isInValid]
    );

    const onChange = useCallback(
      (selected: ValueType<Option | Option[], boolean>): void => {
        if (isMulti && handleOptionsChange) {
          handleOptionsChange(selected as Option[], name);
        }

        if (handleOptionChange) {
          handleOptionChange(selected as Option, name);
        }
      },
      [isMulti, name, handleOptionChange, handleOptionsChange]
    );

    const customComponents = props.components || {};

    const modifiedComponents = {
      IndicatorSeparator: () => null,
      DropdownIndicator,
      NoOptionsMessage,
      ValueContainer,
      Control: ControlComponent,
      ...customComponents
    };

    return (
      <Select
        ref={ref}
        className={classNames("single-select", props.className)}
        value={selectedValue ?? null}
        options={options}
        closeMenuOnSelect={
          closeMenuOnSelect === undefined ? true : closeMenuOnSelect
        }
        hideSelectedOptions={false}
        isDisabled={(disableOnOneOption && hasOnlyOneOption) || disabled}
        isClearable={isClearable || false}
        isMulti={isMulti}
        isSearchable={
          (!hasOnlyOneOption && isSearchable === undefined
            ? true
            : isSearchable) || alwaysClearable
        }
        placeholder={placeholder}
        filterOption={filterOptions || createFilter({ ignoreAccents: false })}
        components={modifiedComponents}
        inputId={inputId}
        onChange={onChange}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#DBDFE2",
            primary: "black"
          }
        })}
      />
    );
  }
);
