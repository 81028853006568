import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { AxiosError } from "axios";

import "./PortalApplicationCertificateContainer.scss";
import { RootState } from "../../../rootReducer";
import { ApplicationCertificateDraft } from "../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { WizardProgressBar } from "../../../Component/ProgressBar/WizardProgressBar";
import { OccupationsSelection } from "./Occupations/OccupationsSelection";
import { ApplicationCertificateContactInfo } from "./ContactInfo/ApplicationCertificateContactInfo";
import { ApplicationCertificateFooter } from "./ApplicationCertificateFooter";
import { ApplicationCertificatePrompt } from "./ApplicationCertificatePrompt";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { ApplicationCertificateOverView } from "./OverView/ApplicationCertificateOverView";
import {
  ApplicationCertificateProvider,
  useApplicationCertificateState
} from "../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import { Loader } from "../../../Component/Loader/Loader";
import { getBaseUrl, API } from "../../../api";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import { applicationActions } from "../../../Application/applicationActions";
import {
  ApplicationCertificateStep,
  certificateRequestApplicationStep
} from "../Application/ApplicationStep";
import { personActions } from "../../../Person/personActions";
import { FormattedButtonProps } from "../../../Component/Button/FormattedButton";
import {
  ApplicationCertificateDeliveryTypeEnum,
  RakendussertifikaadiAndmeteenusApiFactory as applicationCertificateDataAPI,
  KlassifikaatoriteTeenusApiFactory as classifierServiceAPI,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  ContactInfo,
  RakendussertifikaadiAndmeteenusApiFactory as appCertificateDataAPI,
  CompanyApplication,
  ActivityLicenseApplication, Occupation
} from "../../../../api_client/medre_api";
import { defaultAppendCountryValue } from "../../Shared/ApplicationCertificate/Occupations/OccupationsFormCountries";
import { getCertificateIssuanceAddress } from "../../../Util/PersonUtils";

export const PortalApplicationCertificateContainer = (): React.JSX.Element => {
  const certificateRequestState = useApplicationCertificateState(
    appCertificateDataAPI(undefined, getBaseUrl(), API)
      .getApplicationCertificateMetadata1
  );
  const { applicationDraft, currentStep, personOccupationCodes } = useSelector((state: RootState) => ({
    applicationDraft: state.applicationDraft as ApplicationCertificateDraft,
    currentStep: state.applicationDraft?.currentStep ?? 0,
    personOccupationCodes: state.person.occupationCodes
  }));
  const { locations } = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication as ActivityLicenseApplication
  );

  const [
    isCertificateIssuanceAddressValid,
    setIsCertificateIssuanceAddressValid
  ] = useState<boolean>();

  const form = useForm<ApplicationCertificateDraft>({
    mode: "onChange",
    defaultValues: {
      ...applicationDraft,
      occupations: applicationDraft.occupations ?? [],
      speciality: applicationDraft.speciality,
      countries: applicationDraft.countries ?? [defaultAppendCountryValue],
      education: applicationDraft.education ?? ""
    },
    shouldUnregister: false
  });

  const { isValid, isSubmitting, isSubmitSuccessful, isSubmitted } =
    form.formState;

  const history = useHistory();
  const dispatch = useDispatch();
  const handleCreateDraft = useCallback(
    (data) =>
      applicationCertificateDataAPI(
        undefined,
        getBaseUrl(),
        API
      ).createApplicationCertificate(data, {
        withCredentials: true
      }),
    []
  );

  const handleUpdateDraft = useCallback(
    (id, data) =>
      applicationCertificateDataAPI(
        undefined,
        getBaseUrl(),
        API
      ).updateApplicationCertificate(id, data, {
        withCredentials: true
      }),
    []
  );

  const { id: appDraftId } = applicationDraft;
  const handleSendRequest = useCallback(
    async (data) => {
      try {
        return await (!appDraftId
          ? handleCreateDraft(data)
          : handleUpdateDraft(appDraftId, data));
      } catch (error) {
        let responseData = (error as any).response?.data;
        let alertMessage;
        if (responseData.error === "PortalApplicationDraftSaveException") {
          alertMessage = <AlertMessage id={responseData.message} />;
        } else {
          alertMessage = <AlertMessage id="applicationSaveFailed" />;
        }
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error as AxiosError);
      }
    },
    [appDraftId, handleCreateDraft, handleUpdateDraft, dispatch]
  );

  const handleBackward = useCallback(
    async (event?: SyntheticEvent) => {
      event?.preventDefault();
      const nextStep = Math.max(0, currentStep - 1);
      if (currentStep === nextStep && nextStep === 0) {
        return history.push("/applications");
      }
      await handleSendRequest({
        ...applicationDraft,
        currentStep: nextStep
      });
      dispatch(
        applicationDraftActions.updateApplicationDraft("currentStep", nextStep)
      );
    },
    [currentStep, history, handleSendRequest, applicationDraft, dispatch]
  );

  const handleSaveDraft = useCallback(
    async (data: ApplicationCertificateDraft) => {
      const res = await handleSendRequest({
        ...applicationDraft,
        ...data,
        deliveryAddress: getCertificateIssuanceAddress(
          locations![0],
          deliveryType
        ),
        deliveryType: deliveryType
      });
      dispatch(applicationDraftActions.setApplicationDraft(res.data));
      const alertMessage = <AlertMessage id="applicationSaveSuccess" />;
      const alert = { id: 0, type: AlertType.Success, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      history.push("/applications");
    },
    [handleSendRequest, applicationDraft, dispatch, history, locations]
  );

  const handleSaveContactInfo = useCallback(
    () =>
      userDataAPI(undefined, getBaseUrl(), API).updatePerson1(
        applicationDraft.contactInfo as ContactInfo,
        {
          withCredentials: true
        }
      ),
    [applicationDraft.contactInfo]
  );

  const { deliveryType } = applicationDraft;

  const includeMissingOccupationPrefixesForCreateRequest = (occupations: Occupation[]): Occupation[] => {
    return occupations.map(occupation => {
      let match = personOccupationCodes?.find(code => code.occupationId === occupation.id);
      if (match) {
        return {...occupation, prefix: match.prefix};
      } else {
        return occupation;
      }
    });
  };

  const handleSubmit = useCallback(
    async (data: ApplicationCertificateDraft) => {
      let res;
      switch (currentStep) {
        case ApplicationCertificateStep.OVERVIEW:
          res = await applicationCertificateDataAPI(
            undefined,
            getBaseUrl(),
            API
          ).submitApplicationCertificate(appDraftId as string, {
            withCredentials: true
          });
          const stateFeeDeadline = (
            await classifierServiceAPI(
              undefined,
              getBaseUrl(),
              API
            ).getApplicationCertificateStateFeeDeadline({
              withCredentials: true
            })
          ).data;
          // Reset applicationDraft in redux storage
          dispatch(applicationDraftActions.setApplicationDraft({}));
          dispatch(
            applicationActions.setPortalApplication({
              ...res.data,
              stateFeeDeadline: String(stateFeeDeadline)
            } as CompanyApplication)
          );
          history.push("/application", {
            stateFeeMessageId: "upToXDays"
          });
          break;
        default:
          if (currentStep === ApplicationCertificateStep.CONTACTS) {
            const { contactInfo } = (await handleSaveContactInfo()).data;
            dispatch(
              personActions.updatePerson(
                "contactInfo",
                contactInfo as ContactInfo
              )
            );
          }

          res = await handleSendRequest({
            ...data,
            occupations: includeMissingOccupationPrefixesForCreateRequest([...data.occupations]),
            // countries: data.countries.filter((country) => Boolean(country.code)),
            currentStep: Math.min(2, currentStep + 1),
            deliveryType:
              deliveryType ?? ApplicationCertificateDeliveryTypeEnum.Electronic,
            deliveryAddress:
              deliveryType === ApplicationCertificateDeliveryTypeEnum.Post
                ? getCertificateIssuanceAddress(locations![0], deliveryType)
                : null
          });
          dispatch(applicationDraftActions.setApplicationDraft(res.data));
          break;
      }
    },
    [
      currentStep,
      deliveryType,
      handleSaveContactInfo,
      handleSendRequest,
      appDraftId,
      dispatch,
      history,
      locations
    ]
  );

  const { reset: handleFormReset, getValues } = form;
  useEffect(() => {
    if (isSubmitted && isSubmitSuccessful) {
      // need reset `isSubmitted` for Prompt
      handleFormReset({
        ...getValues(),
        ...applicationDraft
      });
    }
  }, [
    isSubmitted,
    isSubmitSuccessful,
    handleFormReset,
    getValues,
    applicationDraft
  ]);

  const getSteps = () => {
    switch (currentStep) {
      case ApplicationCertificateStep.OCCUPATION:
        return <OccupationsSelection />;
      case ApplicationCertificateStep.CONTACTS:
        return (
          <ApplicationCertificateContactInfo
            isCertificateIssuanceAddressValid={
              isCertificateIssuanceAddressValid
            }
            setIsCertificateIssuanceAddressValid={
              setIsCertificateIssuanceAddressValid
            }
          />
        );
      case ApplicationCertificateStep.OVERVIEW:
        return <ApplicationCertificateOverView />;
      default:
        return null;
    }
  };

  const { isLoading, allowedOccupationCodes } = certificateRequestState;
  const saveAllowed =
    currentStep === ApplicationCertificateStep.CONTACTS
      ? applicationDraft.saveAllowed
      : true;
  const hasOccupationCodes = Boolean(allowedOccupationCodes.length);
  const isDisabled = [isLoading, isSubmitting, !hasOccupationCodes].includes(
    true
  );

  const backwardButtonProps: FormattedButtonProps = {
    id: "back",
    onClick: handleBackward,
    disabled: isLoading || isSubmitting,
    ...(currentStep === ApplicationCertificateStep.OCCUPATION && {
      id: "discard"
    })
  };
  const draftButtonProps: FormattedButtonProps = {
    id: "saveDraft",
    onClick: form.handleSubmit(handleSaveDraft),
    disabled: !isValid || isDisabled || !saveAllowed
  };
  const forwardButtonProps: FormattedButtonProps = {
    id: "forward",
    disabled:
      !isValid ||
      isDisabled ||
      !saveAllowed ||
      (currentStep === ApplicationCertificateStep.CONTACTS &&
        deliveryType === ApplicationCertificateDeliveryTypeEnum.Post &&
        !isCertificateIssuanceAddressValid),
    ...(currentStep === ApplicationCertificateStep.OVERVIEW && {
      id: "sendApplication"
    })
  };

  return (
    <div className="container-fluid application-container certificate-request-container">
      <h2 className="font-weight-normal">
        <FormattedMessage
          id="ApplicationCertificate.title"
          defaultMessage="Kutsekvalifikatsiooni tunnustamise tõendi taotluse {number}esitamine"
          values={{
            number: `${applicationDraft.applicationNumber ?? ""} `
          }}
        />
      </h2>

      <ApplicationCertificateProvider value={certificateRequestState}>
        <div className="card pt-4 pr-4 pb-3 pl-4 mt-4 mb-5">
          <WizardProgressBar
            highestStep={currentStep}
            steps={certificateRequestApplicationStep}
          />

          <FormProvider {...form}>
            <form
              className="certificate-request-form"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              {isSubmitting && <Loader backdrop />}
              {isLoading ? <Loader /> : getSteps()}

              <hr className="mt-5 mb-2 application-footer-hr" />

              <ApplicationCertificateFooter
                backwardButtonProps={backwardButtonProps}
                draftButtonProps={draftButtonProps}
                forwardButtonProps={forwardButtonProps}
              />
            </form>

            <ApplicationCertificatePrompt
              when={!isSubmitting && !isSubmitted}
            />
          </FormProvider>
        </div>
      </ApplicationCertificateProvider>
    </div>
  );
};
