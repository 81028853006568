import React, { useCallback, useMemo } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useWorkingDayOptions } from "../../../../Util/OptionUtil";
import { CheckboxGroup } from "../../../../Component/Checkbox/CheckboxGroup";
import { validateArray } from "../../../Shared/Validation/fieldValidator";
import { useGeneralPractitionerEmployeeOptions } from "../useGeneralPractitionerLists";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { GPListFM } from "../../../../Messages/GPListFM";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { API, getBaseUrl } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { timeRangeOverlaps } from "../../../../Util/DateUtils";
import {
  GeneralPractitionerListAppointment,
  PerearstiNimistudTeenusApiFactory as doctorDirectoryAPI
} from "../../../../../api_client/medre_api";
import { getEmployeeRoleOptions } from "../../../../Util/GeneralPractitionerUtils";

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: FormValues;
}

type FormValues = Partial<GeneralPractitionerListAppointment>;

export function PortalAppointmentTimeModal({
  defaultValues,
  open,
  onClose
}: Props) {
  const { control, register, formState, handleSubmit, watch, setValue } =
    useForm<FormValues>({
      mode: "onChange",
      defaultValues
    });
  const { errors, isValid, isSubmitting } = formState;
  const employeeOptions = useGeneralPractitionerEmployeeOptions();
  const workingDayOptions = useWorkingDayOptions();

  const { id } = useParams<{ id: string }>();
  const {
    fetchGPList,
    list: { employees }
  } = useGeneralPractitionerList();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      try {
        if (values?.id) {
          await doctorDirectoryAPI(
            undefined,
            getBaseUrl(),
            API
          ).updateAppointment(id, values, {
            withCredentials: true
          });
        } else {
          await doctorDirectoryAPI(
            undefined,
            getBaseUrl(),
            API
          ).createAppointment(id, values, {
            withCredentials: true
          });
        }
        await fetchGPList(id);
        onClose();
      } catch (e) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }
    },
    [id, fetchGPList, onClose, dispatch]
  );

  const { employeeId, workingFrom, role } = watch();
  const employee = useMemo(() => {
    const currentEmployee = employees
      ? employees.find((employee) => employee.id === employeeId)
      : undefined;
    setValue("role", currentEmployee?.role);
    return currentEmployee;
  }, [employees, employeeId]);

  const employeeRoleOptions = useMemo(() => getEmployeeRoleOptions(), []);

  return (
    <Modal
      isOpen={open}
      size="md"
      unmountOnClose={true}
      contentClassName="pr-2 pl-2"
    >
      <ModalHeader>
        <FormattedMessage
          id="GPAppointmentTimeModal.header"
          defaultMessage="Lisa vastuvõtu aeg"
        />
      </ModalHeader>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gp-contact-information-form"
      >
        <Controller
          name="id"
          defaultValue=""
          control={control}
          as={<input type="hidden" />}
        />

        <Controller
          name="locationId"
          defaultValue=""
          control={control}
          as={<input type="hidden" />}
        />

        <ModalBody>
          <FormGroup row={true}>
            <Label
              htmlFor="gpAppointmentTimeModalEmployeeId"
              className="text-right"
              sm={4}
            >
              <GPListFM id="employee" />
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                control={control}
                name="employeeId"
                defaultValue=""
                render={({ value, onChange }) => (
                  <SingleSelect
                    inputId="gpAppointmentTimeModalEmployeeId"
                    options={employeeOptions}
                    value={value}
                    handleOptionChange={(option) => onChange(option?.value)}
                    isInValid={Boolean(errors.employeeId)}
                    isClearable={true}
                    disabled={!!role && !employeeId}
                  />
                )}
              />
            </Col>
          </FormGroup>

          <FormGroup row={true}>
            <Label
              htmlFor="gpAppointmentTimeModalRole"
              className="text-right"
              sm={4}
            >
              <GPListFM id="role" />
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                control={control}
                name="role"
                defaultValue={employee?.role}
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <SingleSelect
                    inputId="gpAppointmentTimeModalRole"
                    options={employeeRoleOptions}
                    value={value}
                    handleOptionChange={(option) => onChange(option?.value)}
                    isClearable={true}
                    disabled={!!employeeId}
                  />
                )}
              />
            </Col>
          </FormGroup>

          <FormGroup row={true} className="required">
            <Label tag="span" className="text-right" sm={4}>
              <GPListFM id="workingDays" />
            </Label>
            <Col sm={8} className="d-flex align-items-center">
              <Controller
                control={control}
                name="workingDays"
                defaultValue={[]}
                rules={{ validate: validateArray }}
                render={({
                  value,
                  name,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <CheckboxGroup
                    name={name}
                    options={workingDayOptions}
                    onChange={onChange}
                    value={value}
                    inline={true}
                    invalid={Boolean(errors.workingDays)}
                  />
                )}
              />
            </Col>
          </FormGroup>

          <FormGroup row={true} className="required">
            <Label tag="span" className="text-right" sm={4}>
              <GPListFM id="workingHours" />
            </Label>
            <Col sm={8} className="d-flex align-items-center">
              <div className="flex-grow-1">
                <Input
                  type="time"
                  name="workingFrom"
                  defaultValue=""
                  placeholder="09:00"
                  innerRef={register({ required: true })}
                  invalid={Boolean(errors.workingFrom)}
                />
              </div>
              <span className="flex-grow-0">&nbsp;―&nbsp;</span>
              <div className="flex-grow-1">
                <Input
                  type="time"
                  name="workingTo"
                  defaultValue=""
                  placeholder="18:00"
                  innerRef={register({
                    required: true,
                    ...(workingFrom && {
                      validate: (value: string) =>
                        timeRangeOverlaps(workingFrom as string, value)
                    })
                  })}
                  invalid={Boolean(errors.workingTo)}
                />
              </div>
            </Col>
          </FormGroup>

          <FormGroup row={true}>
            <Label
              htmlFor="gpAppointmentTimeModalComment"
              className="text-right"
              sm={4}
            >
              <GPListFM id="comment" />
            </Label>
            <Col sm={8} className="d-flex align-items-center">
              <Input
                id="gpAppointmentTimeModalComment"
                type="text"
                name="comment"
                defaultValue=""
                innerRef={register()}
              />
            </Col>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton
            id="submitAppointmentTime"
            type="submit"
            disabled={!isValid || isSubmitting || (!role && !employee?.role)}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
