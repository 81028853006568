import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import {
  ReactTable,
  ReactTableColumn,
  ReactTableExpander
} from "../../../../Component/Table/ReactTable";
import { useGPListTable } from "../useGPListTable";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { useAuthorized, usePortal } from "../../../../Hook/usePortal";
import useModal from "../../../../Hook/useModal";
import { PortalAppointmentTimeModal } from "../../../Portal/GeneralPractitioners/Modals/AppointmentTime";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { useWorkingDays } from "../../../../Util/DateUtils";
import { useMobile } from "../../../../Hook/useMobile";
import { getFullName } from "../../../../Util/PersonUtils";
import { EmployeeRoleFM } from "../../../../Messages/EmployeeFM";
import { useGeneralPractitionerListDeletionsSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { GPListDeleteRowButton } from "./GPListDeleteRowButton";
import { GPListModifyRowButton } from "./GPListModifyRowButton";
import {
  GeneralPractitionerListAppointment,
  GeneralPractitionerListContact
} from "../../../../../api_client/medre_api";
import { LinkToPublicPortal } from "../LinkToPublicPortal";

const headers = {
  fullName: (
    <FormattedMessage
      id="GPListDetailedTableHeader.fullName"
      defaultMessage="Nimi"
    />
  ),
  role: (
    <FormattedMessage
      id="GPListDetailedTableHeader.role"
      defaultMessage="Roll"
    />
  ),
  workingDays: (
    <FormattedMessage
      id="GPListDetailedTableHeader.workingDays"
      defaultMessage="Päev"
    />
  ),
  workingHours: (
    <FormattedMessage
      id="GPListDetailedTableHeader.workingHours"
      defaultMessage="Kellaaeg"
    />
  ),
  comment: (
    <FormattedMessage
      id="GPListDetailedTableHeader.comment"
      defaultMessage="Kommentaar"
    />
  )
};

interface GPListAppointmentTimesProps {
  contact: GeneralPractitionerListContact;
}

export const GPListAppointmentTimesTable = ({
  contact
}: GPListAppointmentTimesProps) => {
  const {
    list: { appointments, employees }
  } = useGeneralPractitionerList();

  const workingDays = useWorkingDays();

  const isMobile = useMobile();
  const isPortal = usePortal();
  const isAuthorized = useAuthorized();
  const {
    employees: selectedEmployees = [],
    appointments: selectedAppointments
  } = useGeneralPractitionerListDeletionsSelector();
  const columns: ReactTableColumn<GeneralPractitionerListAppointment>[] =
    useMemo(() => {
      const baseColumns: ReactTableColumn<GeneralPractitionerListAppointment>[] =
        [
          {
            collapse: isMobile,
            Header: headers.workingDays,
            accessor: "workingDays",
            Cell: ({ value }) => {
              const days = value.map((day) => workingDays[day]?.min).join(", ");
              return isMobile ? (
                <>
                  <div className="font-size-14">{headers.workingDays}</div>
                  <span className="font-weight-500">{days}</span>
                </>
              ) : (
                days
              );
            },
            width: 50
          },
          {
            collapse: isMobile,
            Header: headers.workingHours,
            id: "workingHours",
            accessor: ({ workingFrom, workingTo }) => {
              const hours = `${workingFrom} - ${workingTo}`;
              return isMobile ? (
                <>
                  <div className="font-size-14">{headers.workingHours}</div>
                  <span className="font-weight-500">{hours}</span>
                </>
              ) : (
                hours
              );
            },
            width: 50
          },
          {
            Header: headers.fullName,
            accessor: "employeeId",
            Cell: (cell) => {
              const employee = employees.find((item) => item.id === cell.value);
              if (!employee) {
                return null;
              }
              const fullName = (
                <LinkToPublicPortal to={`/employees/${employee.personId}`}>
                  {employee.occupationCode.code}&nbsp;-&nbsp;
                  {getFullName(employee)}
                </LinkToPublicPortal>
              );
              return isMobile ? (
                <ReactTableExpander cell={cell}>
                  <div className="font-size-14">{fullName}</div>
                  <span className="font-weight-500">
                    <EmployeeRoleFM role={employee.role} />
                  </span>
                </ReactTableExpander>
              ) : (
                fullName
              );
            },
            width: 50
          },
          {
            collapse: isMobile,
            Header: headers.comment,
            accessor: "comment",
            Cell: ({ value }) =>
              isMobile ? (
                <>
                  <div className="font-size-14">{headers.comment}</div>
                  <span className="font-weight-500">{value}</span>
                </>
              ) : (
                value
              ),
            width: 0
          }
        ];

      if (!isMobile) {
        baseColumns.splice(3, 0, {
          Header: headers.role,
          id: "occupationCode",
          accessor: "employeeId",
          Cell: ({ cell }) => {
            const employee = employees.find((item) => item.id === cell.value);
            if (!employee && !cell.row.original.role) {
              return null;
            }
            return (
              <EmployeeRoleFM role={employee?.role ?? cell.row.original.role} />
            );
          },
          width: 50
        });
      }

      if (isPortal && isAuthorized) {
        baseColumns.push({
          collapse: isMobile,
          sticky: !isMobile,
          accessor: "employeeId",
          id: "actions",
          Cell: (cell) => (
            <>
              <GPListModifyRowButton index={cell.row.index}>
                {(modal) => (
                  <PortalAppointmentTimeModal
                    defaultValues={cell.row.original}
                    open={modal.isOpen}
                    onClose={modal.handleClose}
                  />
                )}
              </GPListModifyRowButton>
              <GPListDeleteRowButton
                index={cell.row.index}
                rowId={cell.row.original.id as string}
                candidate="appointments"
                disabled={Boolean(
                  selectedEmployees.includes(cell.value as string)
                )}
              />
            </>
          ),
          disableSortBy: true,
          width: 0
        });
      }

      return baseColumns;
    }, [
      selectedEmployees,
      isMobile,
      employees,
      workingDays,
      isPortal,
      isAuthorized
    ]);

  const appointmentsData = useMemo(
    () =>
      appointments.filter(
        (appointment) => appointment.locationId === contact.locationId
      ) ?? [],
    [appointments, contact.locationId]
  );

  const table = useGPListTable<GeneralPractitionerListAppointment>({
    data: appointmentsData,
    columns
  });

  const appointmentTimeModal = useModal();

  return (
    <>
      {appointmentTimeModal.isOpen && (
        <PortalAppointmentTimeModal
          open={true}
          onClose={appointmentTimeModal.handleClose}
          defaultValues={{
            locationId: contact.locationId
          }}
        />
      )}
      <div className="mt-3">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 mt-3">
          <div className="fw-500">{contact.address}</div>
          {isPortal && isAuthorized && (
            <div className="d-sm-block">
              <PrimaryFormattedButton
                id="addAppointmentTime"
                idPrefix="sm"
                onClick={appointmentTimeModal.handleOpen}
              />
            </div>
          )}
        </div>
        <ReactTable<GeneralPractitionerListAppointment>
          table={table}
          getHeaderProps={(column) =>
            column.getHeaderProps(column.getSortByToggleProps())
          }
          rows={table.rows}
          getRowProps={(row) =>
            row.getRowProps({
              className: classNames({
                danger:
                  selectedEmployees.includes(row.values.employeeId) ||
                  selectedAppointments.includes(row.id)
              })
            })
          }
        />
      </div>
    </>
  );
};
