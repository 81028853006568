import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";

import "../../../Shared/Application/Application.scss";
import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { QuickLinks } from "../../../../Component/QuickLinks/QuickLinks";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { Loader } from "../../../../Component/Loader/Loader";
import { DetailsCard } from "./DetailsCard";
import { ApplicationNotes } from "../../Application/Details/ApplicationNotes";
import { ApplicationDecisions } from "../../Application/Details/ApplicationDecisions";
import { ActivityLicenseDetailsHistory } from "../Application/Details/ActivityLicenseDetailsHistory";
import { LicenseAcquisitionDetailsActions } from "./LicenseAcquisitionDetailsActions";
import { useDetailedApplication } from "../Details/ActivityLicenseApplicationDetailsHook";
import {
  DetailedLicenseAcquisition,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeLevtmiseTaotlusteTeenusApiFactory as officialActivityPermitIssuanceAPI
} from "../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../api";

export const LicenseAcquisitionDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { details, setDetails, refresh } =
    useDetailedApplication<DetailedLicenseAcquisition>(
      officialActivityPermitIssuanceAPI(undefined, getBaseUrl(), API)
        .getDetailed,
      id
    );

  const isSubmitted =
    details?.status !== ActivityLicenseApplicationStatus.Saved &&
    details?.status !== ActivityLicenseApplicationStatus.Registering;

  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      {
        id: "history",
        title: "Menetluse ajalugu"
      },
      {
        id: "decisions",
        title: "Otsused"
      },
      {
        id: "fee",
        title: "Riigilõiv"
      }
    ],
    []
  );

  if (!details) {
    return <Loader />;
  }

  return (
    <div className="application-container activity-license-details-container">
      <div className="d-flex align-items-center mx-3 mb-1">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details.status}
          isStateFeePaid={details.stateFee?.paid}
          isDetailed={true}
        />
      </div>
      <div className="ml-3 activity-license-quick-links">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <LicenseAcquisitionDetailsActions
          details={details}
          setDetails={setDetails}
          refresh={refresh}
        />
      </Card>
      <DetailsCard details={details} setDetails={setDetails} />
      {isSubmitted && (
        <ApplicationNotes
          notes={details.notes || ""}
          setDetails={setDetails}
          endpoint={
            officialActivityPermitIssuanceAPI(undefined, getBaseUrl(), API)
              .updateApplicationNote
          }
          detailsId={details.id}
        />
      )}
      {isSubmitted && (
        <ActivityLicenseDetailsHistory
          auditLogItems={details.auditLogItems || []}
        />
      )}
      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="decisions" /> Otsused
          <ApplicationDecisions decisions={details.decisions || []} />
        </h2>
      </Card>
    </div>
  );
};
