import { MOBILE_MAX_WIDTH } from "../Constants";
import { useCallback, useLayoutEffect, useState } from "react";

const checkMobile = () => window.innerWidth <= MOBILE_MAX_WIDTH;

export function useMobile() {
  const [isMobile, setMobile] = useState(checkMobile());
  const listener = useCallback(() => {
    setMobile(checkMobile());
  }, [setMobile]);

  useLayoutEffect(() => {
    window.addEventListener("resize", listener);
    listener();
    return () => window.removeEventListener("resize", listener);
  }, [listener]);
  return isMobile;
}