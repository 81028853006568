import React, { FC, useEffect, useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { TableFooter } from "./TableFooter";
import {
  generalPractitionerListOfficialColumns,
  generalPractitionerListPublicPortalColumns,
  generalPractitionerListPublicPortalMobileColumns
} from "../../GeneralPractitioners/GeneralPractitionerListColumns";
import { ReactTable } from "../../../../Component/Table/ReactTable";
import { searchResultsActions } from "../searchResultsActions";
import { useDispatch } from "react-redux";
import { usePortal } from "../../../../Hook/usePortal";
import { GeneralPractitionerList } from "../../../../../api_client/medre_api";
import { useMobile } from "../../../../Hook/useMobile";

interface Props {
  data: GeneralPractitionerList[];
  pageCount: number;
  pageIndex: number;
}

export const GeneralPractitionerListResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex
}) => {
  const isPortal = usePortal();
  const isMobile = useMobile();
  const columns = useMemo(
    () =>
      isPortal
        ? isMobile
          ? generalPractitionerListPublicPortalMobileColumns
          : generalPractitionerListPublicPortalColumns
        : generalPractitionerListOfficialColumns,
    [isPortal, isMobile]
  );
  const table = useTable<GeneralPractitionerList>(
    {
      columns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortBy: isPortal
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: { sortBy }
  } = table;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<GeneralPractitionerList>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) =>
          column.getHeaderProps(column.getSortByToggleProps())
        }
        className="border-0"
        square={true}
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
