import React from "react";
import contact from "../../../assets/images/contact.svg";
import oval from "../../../assets/images/oval.svg";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";

export const FooterContactInfo = () => {
  return (
    <div className="text-white line-separator">
      <p className="footer-title">
        <img src={oval} alt="ovaal" />
        <img
          className="icon contact-info-icon"
          src={contact}
          alt="kontaktinfo ikoon"
        />
        <FormattedMessage id="contactInfo.header" defaultMessage="KONTAKT" />
      </p>
      <div className="content">
        <p className="mb-0">
          <FormattedMessage
            id="contactInfo.companyName"
            defaultMessage="Terviseamet"
          />
        </p>
        <address>
          <p className="mb-0">
            <FormattedMessage
              id="contactInfo.companyAddress"
              defaultMessage="Paldiski mnt 81, 10614 Tallinn"
            />
          </p>
          <p className="mb-0">
            <FormattedMessage
              id="contactInfo.companyEpost"
              defaultMessage="E-post: <a>medre@terviseamet.ee</a>"
              values={{
                a: (msg: string) => (
                  <a
                    href="mailto:medre@terviseamet.ee"
                    className="link"
                    rel="noopener noreferrer"
                    target={"_blank"}
                  >
                    { msg }
                  </a>
                )
              }}
            />
          </p>
        </address>
      </div>
    </div>
  );
};
