import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Spinner from "reactstrap/lib/Spinner";
import Card from "reactstrap/lib/Card";
import { AxiosResponse } from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CountrySelect } from "../../../../Component/Select/CountrySelect";
import { Option } from "../../../../Component/Select/SingleSelect";
import "./PersonalDataView.scss";
import { API, getBaseUrl } from "../../../../api";
import { ErrorMessage } from "../../../../Component/ErrorField/ErrorMessage";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { contactInfoFromRegistryActions } from '../../../../Person/contactInfoFromRegistryActions';
import { RootState } from "../../../../rootReducer";
import { PersonalDataContactContainer } from "./PersonalDataContactContainer";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import {
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI,
  Person,
  PersonDataResponse,
  PersonDataResponseNotificationTypesEnum
} from "../../../../../api_client/medre_api";
import { createCountryOption } from "../../../../Util/OptionUtil";

export const PersonalDataView = () => {
  enum loadingState {
    INITIAL,
    IS_LOADING,
    HAS_LOADED
  }

  const [personalDataLoadingState, setPersonalDataLoadingState] = useState(
    loadingState.INITIAL
  );
  const [personalCode, setPersonalCode] = useState("");
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft as Record<string, any>,
    shallowEqual
  );
  const [countryCode, setCountryCode] = useState(
    applicationDraft?.personalData?.foreigner
      ? applicationDraft?.personalData?.foreignIdCodeCountryId
      : "EST"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [personData, setPersonData] = useState<Person | null | undefined>(null);
  const [notificationTypes, setNotificationTypes] = useState(new Array<PersonDataResponseNotificationTypesEnum>());
  const isIdCodeMissing = Boolean(
    applicationDraft?.personalData?.isIdCodeMissing
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (applicationDraft.personalData && !personData) {
      if (applicationDraft.personalData?.foreigner) {
        setPersonalCode(applicationDraft.personalData?.foreignIdCode || "");
      } else {
        setPersonalCode(applicationDraft.personalData?.idCode || "");
      }
      setPersonData(applicationDraft.personalData);
      return;
    }
  }, [applicationDraft.personalData, personData, applicationDraft]);

  const handleCountryChange = (option: Option) => {
    if (option.value === "EST" && isIdCodeMissing) {
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "personalData",
          undefined
        )
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft("contactInfo", undefined)
      );
    }
    setCountryCode(option.value);
  };

  const handlePersonalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
    setPersonalCode(e.target.value);
  };

  const getPersonAndContactData = () => {
    if (!personalCode) {
      return;
    }
    clearData();
    setPersonalDataLoadingState(loadingState.IS_LOADING);
    setErrorMessage("");
    officialUserDataAPI(undefined, getBaseUrl(), API)
      .getPersonInfoFromPersonalCode(personalCode, countryCode, {
        withCredentials: true
      })
      .then((result) => {
        handlePersonResponse(result);
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          setErrorMessage("Viga taotleja andmete otsimisel.");
          return;
        }
        let responseData = error.response?.data;
        if (responseData.error === "InvalidPersonalCodeException") {
          setErrorMessage("Isikukoodi formaat on vale.");
        } else if (responseData.error === "PersonNotFoundException") {
          setErrorMessage("Isikut ei leitud.");
        } else if (responseData.error === "MedreRuntimeException") {
          setErrorMessage("RR päring ei vasta hetkel.");
        } else if (responseData.error === "IllegalStateException") {
          if (responseData.message === "Person is dead") {
            setErrorMessage("Taotleja on surnud.");
          } else {
            setErrorMessage(
              "Taotlejat ei saa Eesti isikukoodi põhjal süsteemi lisada."
            );
          }
        } else {
          setErrorMessage("Viga taotleja andmete otsimisel.");
        }
      })
      .finally(() => {
        setPersonalDataLoadingState(loadingState.HAS_LOADED);
      });
  };

  const handlePersonResponse = (response: AxiosResponse<PersonDataResponse>) => {
    let data = response.data;
    let personResponse = data.person;
    let contactInfo = personResponse?.contactInfo;

    if (contactInfo) {
      applicationDraft.contactInfo = contactInfo;
    }

    setPersonData(data.person);
    applicationDraft.personalData = personResponse;
    if (personResponse) {

      if (personResponse.idCode) {
        officialUserDataAPI(undefined, getBaseUrl(), API)
          .getContactInfoFromRR1(personResponse.idCode)
          .then((contactInfoFromRR) =>
            dispatch(contactInfoFromRegistryActions.setContactInfoFromRR(contactInfoFromRR.data)));
      }

      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "personId",
          personResponse.id
        )
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "personalData",
          personResponse
        )
      );
    }
    if (data.notificationTypes) {
      setNotificationTypes(data.notificationTypes);
    }
  };

  const renderNotificationMessages = () => {
    if (notificationTypes.length === 0) {
      return;
    }

    return (
      <div className="card-holder">
        <Card className="w-100 p-4 d-flex align-items-left justify-content-center info-card">
          {notificationTypes.indexOf(PersonDataResponseNotificationTypesEnum.ApplicantIsDead) >=0
            ? (convertNotificationTypeToMessage(PersonDataResponseNotificationTypesEnum.ApplicantIsDead))
            : (
              <ul>
                {notificationTypes
                  .map((value) => (<li>{convertNotificationTypeToMessage(value)}</li>))
                }
              </ul>
            )
          }
        </Card>
      </div>
    );
  };

  const convertNotificationTypeToMessage = (
    notificationType: PersonDataResponseNotificationTypesEnum
  ) => {
    switch (notificationType) {
      case PersonDataResponseNotificationTypesEnum.IsResidentOfEstoniaBasedOnRegistry:
        return "Taotleja on Rahvastikuregistri põhjal Eesti elanik.";
      case PersonDataResponseNotificationTypesEnum.HasForeignPersonalCode:
        return "Taotlejale on Rahvastikuregistris määratud ka välismaa isikukood.";
      case PersonDataResponseNotificationTypesEnum.ExistsInSystemBasedOnForeignPersonalCode:
        return "Taotleja on süsteemis olemas välismaa isikukoodi põhjal.";
      case PersonDataResponseNotificationTypesEnum.NewEstonianPersonalCodeAndResidencyWillBeUpdated:
        return 'Taotleja eksisteerivale kontole lisatakse Eesti isikukood ja kasutaja märgitakse kui "Eesti elanik".';
      case PersonDataResponseNotificationTypesEnum.PersonalDataWillBeUpdated:
        return "Taotleja isikuandmed uuendatakse Rahvastikuregistri põhjal.";
      case PersonDataResponseNotificationTypesEnum.ApplicantDoesNotExistInSystem:
        return "Taotlejat ei ole süsteemis olemas.";
      case PersonDataResponseNotificationTypesEnum.ExistsInRegistryButNotResidentOfEstonia:
        return "Taotleja on Rahvastikuregistris olemas, aga ta ei ole Eesti elanik.";
      case PersonDataResponseNotificationTypesEnum.EstonianPersonalCodeWillBeAdded:
        return "Taotleja eksisteerivale kontole lisatakse Eesti isikukood.";
      case PersonDataResponseNotificationTypesEnum.RegistryDoesNotContainPerson:
        return "Rahvastikuregistris sisestatud isikukoodiga inimest ei ole.";
      case PersonDataResponseNotificationTypesEnum.ApplicantCannotBeAdded:
        return "Taotlejat ei saa Eesti isikukoodi põhjal süsteemi lisada.";
      case PersonDataResponseNotificationTypesEnum.ApplicantIsDead:
        return "Taotleja on surnud.";
      default:
        return "";
    }
  };

  const clearData = () => {
    applicationDraft.personalData = undefined;
    applicationDraft.contactInfo = undefined;
    setPersonData(null);
    setPersonalDataLoadingState(loadingState.INITIAL);
    setNotificationTypes([]);
  };

  const renderButtonText = () => {
    switch (personalDataLoadingState) {
      case loadingState.INITIAL:
        return "Päri isikuinfo";
      case loadingState.IS_LOADING:
        return <Spinner size={"sm"} color={"white"} />;
      case loadingState.HAS_LOADED:
        return "Päri uuesti";
      default:
        return "Päri isikuinfo";
    }
  };

  const contactAreaVisible = () => {
    return !!applicationDraft.personalData;
  };

  const handleCheckBoxChange = () => {
    if (isIdCodeMissing) {
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "personalData",
          undefined
        )
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft("contactInfo", undefined)
      );
    } else {
      setPersonalCode("");
      dispatch(
        applicationDraftActions.updateApplicationDraft("personalData", {
          foreignIdCodeCountryId: countryCode,
          isIdCodeMissing: true
        } as Record<string, any>)
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft("contactInfo", {})
      );
    }
  };

  return (
    <>
      <h3 className="application-title fw-normal">Sisesta isikuinfo</h3>
      <p>Kutse või eriala isikuandmete ja kontaktandmete sisestamine</p>
      <div className="personal-data">
        <FormGroup className="form-inline">
          <Label>Isikukoodi riik</Label>
          <CountrySelect
            className="personal-code-country-select"
            defaultSelectedValue={countryCode}
            hideSearchIcon={true}
            handleOptionChange={handleCountryChange}
            withNotSpecified={true}
            value={createCountryOption(countryCode)}
          />
        </FormGroup>
        <FormGroup className="form-inline">
          <Label>Isikukood</Label>
          <div className="personal-code-container">
            <Input
              className="personal-code-input"
              name="personalCode"
              value={personalCode}
              onChange={handlePersonalCodeChange}
              invalid={errorMessage !== ""}
              disabled={isIdCodeMissing}
            />
            <Button
              color="primary"
              className={"btn-primary search-personal-data"}
              onClick={getPersonAndContactData}
              disabled={!personalCode}
            >
              {renderButtonText()}
            </Button>
            {errorMessage !== "" && (
              <div className="d-block personal-code-error">
                <ErrorMessage message={errorMessage} />
              </div>
            )}
          </div>
        </FormGroup>
        {countryCode !== "EST" && !applicationDraft.id && (
          <FormGroup className="form-inline checkbox-form-group">
            <Checkbox
              id="person-id-code-missing"
              isChecked={isIdCodeMissing}
              labelText="Isikukood puudub"
              onChange={handleCheckBoxChange}
            />
          </FormGroup>
        )}
      </div>
      {renderNotificationMessages()}
      {contactAreaVisible() && <PersonalDataContactContainer />}
    </>
  );
};
