import React, { CSSProperties, FC } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { GeneralPractitionerListContact } from "../../../../../api_client/medre_api";
import { useWorkingDayOptions } from "../../../../Util/OptionUtil";
import { getWorkingDayLabel } from "../../../../Util/GeneralPractitionerUtils";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import { useAuthorized } from "../../../../Hook/usePortal";

interface Props {
  contact: GeneralPractitionerListContact;
}

export const GPListContactCard: FC<Props> = ({ contact }) => {
  const workingDayOptions = useWorkingDayOptions();
  const isAuthorized = useAuthorized();

  const renderToolTipDependingOnPortal = () => {
    const toolTipStyle: CSSProperties = {
      backgroundColor: "white",
      border: "1px solid #D2D3D8",
      borderRadius: "0",
      color: "black",
      maxWidth: "300px",
      padding: "10px 10px",
      textAlign: "left",
    };

    return <FormattedToolTip
      target={"digital-platform-info-icon"}
      message={
        <FormattedMessage
          id="digital-platform.tooltip-content"
          defaultMessage="Digiteenindusplatvorm on turvaline infotehnoloogiline iseteeninduse kanal patsiendi ja perearstikeskuse vahelises suhtluses."
        />
      }
      placement={isAuthorized ? "top" : "right"}
      style={isAuthorized ? toolTipStyle : {}}
    />;
  };

  return (
    <ApplicationDetailsInfoCard
      key={contact.locationId}
      content={[
        {
          key: (
            <FormattedMessage
              id="GPListContacts.address"
              defaultMessage="Vastuvõtu aadress"
            />
          ),
          value: contact.address
        },
        {
          key: (
            <FormattedMessage
              id="GPListContacts.phone"
              defaultMessage="Nimistu telefon"
            />
          ),
          value: "",
          ...(contact.dialCode &&
            contact.phone && {
            value: `${ contact.dialCode } ${ contact.phone }`
          })
        },
        {
          key: (
            <FormattedMessage
              id="GPListContacts.email"
              defaultMessage="Nimistu e-post"
            />
          ),
          value: contact.email
        },
        {
          key: (
            <FormattedMessage
              id="GPListContacts.homepage"
              defaultMessage="Nimistu koduleht"
            />
          ),
          value: contact.homepage
        },
        {
          key: (
            <FormattedMessage
              id="GPListContacts.workingHours"
              defaultMessage="Lahtiolekuajad"
            />
          ),
          value: contact.workingDays?.map((wDay) => (
            <div key={wDay.workingDay} className="d-flex mb-1">
              <div className="pr-4">
                { getWorkingDayLabel(workingDayOptions, wDay.workingDay) }
              </div>
              <div className="pr-1">{ wDay.workingFrom }</div>
              -
              <div className="pl-1">{ wDay.workingTo }</div>
            </div>
          ))
        },
        {
          key: (
            <div className="d-flex-with-gap">
              <FormattedMessage
                id="GPListContacts.digitalPlatform"
                defaultMessage="Digiteenindusplatvorm"
              />
              <span
                id="digital-platform-info-icon"
                className="digital-platform-info-icon position-relative d-inline-block m-0"
                tabIndex={0}
                role="region"
                aria-label="tooltip"
              />
              { renderToolTipDependingOnPortal() }
            </div>
          ),
          value: contact.digitalPlatform && (
            <>
              { contact.digitalPlatform.name } (
              <a href={`https://${ contact.digitalPlatform.url }`} rel="noopener noreferrer"
                target={"_blank"}>{ contact.digitalPlatform.url }</a>
              )
            </>
          )
        }
      ]}
    />
  );
};
