import React, { useMemo } from "react";
import { Card, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { WizardProgressBar } from "../../../../Component/ProgressBar/WizardProgressBar";
import {
  occupationApplicationSteps,
  specialityApplicationSteps,
  certificateRequestApplicationStep
} from "../ApplicationStep";
import { FormattedMessage } from "react-intl";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { SubmissionTimeLine } from "./SubmissionTimeLine";
import { RootState } from "../../../../rootReducer";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { StateFeeInstructions } from "../../../Shared/StateFee/StateFeeInstructions";
import { StateFeeFM } from "../../../../Messages/StateFeeFM";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import {
  Application,
  DetailedApplicationTypeEnum
} from "../../../../../api_client/medre_api";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

export const SubmittedApplication = () => {
  const history = useHistory();

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const application = useSelector((state: RootState) => state.application);
  const applicationType = useSelector(
    (state: RootState) => (state.application as Application).type
  );
  const steps = useMemo(() => {
    switch (applicationType) {
      case DetailedApplicationTypeEnum.Occupation:
        return occupationApplicationSteps;
      case DetailedApplicationTypeEnum.Specialization:
        return specialityApplicationSteps;
      case DetailedApplicationTypeEnum.ApplicationCertificate:
        return certificateRequestApplicationStep;
      default:
        return [];
    }
  }, [applicationType]);

  return (
    <div className="application-container">
      <h1 className="ml-3 mt-3">
        <FormattedMessage
          id="submittedApplication.title"
          defaultMessage="Sinu taotlus {applicationNumber} jõudis meieni"
          values={{ applicationNumber: application.applicationNumber }}
        />
      </h1>

      <Card>
        <div className="m-4">
          <WizardProgressBar locked={true} formComplete={true} steps={steps} />
          <div className="submission-info-container">
            <h5 className="fw-normal sea-green-10">
              <GeneralFM id="thankYou" />!
            </h5>
            <p>
              <FormattedMessage
                id="submittedApplication.applicationReceived"
                defaultMessage="Sinu taotlus jõudis meieni!"
              />
            </p>
            <p>
              <FormattedMessage
                id="submittedApplication.informationText"
                defaultMessage="  "
              />
            </p>
            <SubmissionTimeLine
              educationCountryCode={
                (application as Application).educationInfo?.countryCode!
              }
              automaticProceeding={
                (application as Application).automaticProceeding
              }
              isPaid={(application as Application).stateFee?.paid}
            />
            <h5 className="fw-normal mt-4">
              <StateFeeFM id="stateFeeInstructionHeader" />
            </h5>
            <StateFeeInstructions
              deadline={(application as Application).stateFee?.stateFeeDeadline}
              referenceNumber={
                (application as Application).stateFee?.referenceNumber
              }
              stateFeeValue={
                (application as Application).stateFee?.stateFeeValue
              }
            />
          </div>
          <hr className="mt-5 application-footer-hr" />
          <Row
            className={isMobile ? "justify-content-center" : "m-0 float-right"}
          >
            <PrimaryFormattedButton
              id="backToLandingPage"
              onClick={() => history.push("/")}
            />
          </Row>
        </div>
      </Card>
    </div>
  );
};
