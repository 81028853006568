import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const SwitchToAutomaticEducationViewModal = ({
  isOpen,
  onClose,
  onSave
}: Props) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>
      <FormattedMessage
        id="switchToAutomaticEducationViewModal.header"
        defaultMessage="Oled sa kindel, et soovid lisada hariduse andmed EHISest?"
      />
    </ModalHeader>
    <ModalBody>
      <FormattedMessage
        id="switchToAutomaticEducationViewModal.body"
        defaultMessage="Hariduse andmed kirjutatakse toimingu käigus üle."
      />
    </ModalBody>
    <ModalFooter>
      <SecondaryFormattedButton id="close" onClick={() => onClose()} />
      <PrimaryFormattedButton id="addAutomatically" onClick={() => onSave()} />
    </ModalFooter>
  </Modal>
);
