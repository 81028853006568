import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState
} from "react";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { GeneralPractitionerList } from "../../../../../api_client/medre_api";
import { useGeneralPractitionerListSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";

interface Props {
  onClose: () => void;
}

const ChangeMaxPatientsSize: FC<Props> = ({ onClose }) => {
  const list = useGeneralPractitionerListSelector();
  const currentMaxPatientsSize = list.maxPatientsSize;
  const header = useMemo(
    () =>
      currentMaxPatientsSize
        ? {
            id: "GPListChangeMaxPatientsSizeModal.header",
            defaultMessage: "Muuda nimistu piirsuurus"
          }
        : {
            id: "GPListSetMaxPatientsSizeModal.header",
            defaultMessage: "Määra nimistu piirsuurus"
          },
    [currentMaxPatientsSize]
  );

  const [maxPatientsSize, setMaxPatientsSize] = useState<number | undefined>(
    currentMaxPatientsSize
  );
  const { loading, updateGPList } = useGeneralPractitionerList();

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value);

    setMaxPatientsSize(value > 0 ? value : undefined);
  }, []);

  const handleSubmit = useCallback(async () => {
    await updateGPList({ maxPatientsSize } as GeneralPractitionerList, onClose);
  }, [maxPatientsSize, onClose, updateGPList]);

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      <ModalHeader>
        <FormattedMessage {...header} />
      </ModalHeader>
      <ModalBody>
        <div className="p-2 mt-2">
          <FormGroup row={true}>
            <Label htmlFor="gpListSetMaxPatientsSizeModalNumber" sm={5}>
              Nimistu piirsuurus
            </Label>
            <Col sm={7}>
              <Input
                id="gpListSetMaxPatientsSizeModalNumber"
                type="number"
                value={maxPatientsSize || ""}
                onChange={handleChange}
                min={1}
              />
            </Col>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={onClose}
          disabled={loading}
        />
        <PrimaryFormattedButton
          id={
            currentMaxPatientsSize
              ? "changeGPMaxPatientsSize"
              : "setGPMaxPatientsSize"
          }
          onClick={handleSubmit}
          disabled={loading || !maxPatientsSize}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ChangeMaxPatientsSize;
