import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { RootState } from "../../../../rootReducer";
import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

export const ApplicationDiscardConfirmationModal = ({
  isOpen,
  onClose,
  onDiscard
}: Props) => {
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={
        <FormattedMessage
          id="ApplicationDiscardConfirmationModal.header"
          defaultMessage="Oled sa kindel, et soovid taotluse täitmist lõpetada?"
        />
      }
      saveButtonId="discardDraft"
      onClose={onClose}
      onSave={onDiscard}
    >
      {isPortal ? (
        <FormattedMessage
          id="ApplicationDiscardConfirmationModal.body"
          defaultMessage={`Eelnevalt salvestatud taotlused leiate "Minu taotlused" alt.`}
        />
      ) : (
        <FormattedMessage
          id="ApplicationDiscardConfirmationModal.officialBody"
          defaultMessage={`Eelnevalt salvestatud taotlused leiate "Otsing" alt.`}
        />
      )}
    </ConfirmationModal>
  );
};
