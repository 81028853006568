import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "reactstrap";
import { AxiosPromise } from "axios";

import { ApplicationNotesStickyFooter } from "./ApplicationNotesStickyFooter";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  ApplicationDraft,
  ApplicationNote,
  DetailedActivityLicenseApplication
} from "../../../../../api_client/medre_api";

interface Props<TApplication> {
  notes: string;
  setDetails: (details: TApplication) => void;
  endpoint: (
    id: string,
    applicationNote: ApplicationNote,
    options?: any
  ) => AxiosPromise<DetailedActivityLicenseApplication | ApplicationNote>;
  detailsId?: string;
}

export const ApplicationNotes = <TApplication extends ApplicationDraft>({
  notes,
  endpoint,
  detailsId,
  setDetails
}: Props<TApplication>) => {
  const [currentNotes, setCurrentNotes] = useState(notes);
  const isStickyFooterVisible = currentNotes !== notes;
  const dispatch = useDispatch();

  const changeNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(event.target.value);
  };

  const handleSave = (noteText: string) => {
    endpoint(
      detailsId!,
      { noteText },
      {
        withCredentials: true
      }
    )
      .then((response) => {
        setDetails(response.data as any);
        const alertMessage = <AlertMessage id={"noteSaveSuccessful"} />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .catch(() => {
        const alertMessage = <AlertMessage id={"noteSaveFailure"} />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const handleDiscard = () => setCurrentNotes(notes);

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="notes" /> Menetluse märkmed
      </h2>

      <div className="d-flex flex-row my-4">
        <label htmlFor="application-notes" className="mt-2">
          Märkmed
        </label>
        <textarea
          value={currentNotes}
          onChange={changeNotes}
          id="application-notes"
          rows={!!currentNotes ? 10 : 3}
          cols={64}
          className="p-2 ml-3"
        />
      </div>

      {isStickyFooterVisible && (
        <ApplicationNotesStickyFooter
          handleSave={handleSave}
          notes={currentNotes}
          handleDiscard={handleDiscard}
        />
      )}
    </Card>
  );
};
