import React from "react";
import { useSelector } from "react-redux";

import { PrimaryBadge } from "../../../Component/Badge/PrimaryBadge";
import { WarningBadge } from "../../../Component/Badge/WarningBadge";
import { SuccessBadge } from "../../../Component/Badge/SuccessBadge";
import { DangerBadge } from "../../../Component/Badge/DangerBadge";
import { RootState } from "../../../rootReducer";
import { ActivityLicenseApplicationStatus } from "../../../../api_client/medre_api";

interface Props {
  currentStatus?: ActivityLicenseApplicationStatus;
  isStateFeePaid?: boolean;
  index?: number;
  isDetailed?: boolean;
  inTable?: boolean;
}

export const ActivityLicenseApplicationState = ({
  currentStatus,
  isStateFeePaid,
  index,
  isDetailed,
  inTable
}: Props) => {
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  switch (currentStatus) {
    case ActivityLicenseApplicationStatus.Saved:
      return <PrimaryBadge id={`saved-${index}`} message="saved" />;
    case ActivityLicenseApplicationStatus.Submitted:
    case ActivityLicenseApplicationStatus.SubmittedByOfficial:
      return (
        <>
          <PrimaryBadge id={`submitted-${index}`} message="submitted" />
          {(isDetailed || inTable) && !isStateFeePaid && (
            <div className={isDetailed ? "ml-4" : "mt-1"}>
              <WarningBadge
                id={`stateFeeNotPaid-${index}`}
                message="stateFeeNotPaid"
              />
            </div>
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.ProceedingPending:
      return (
        <>
          {(isPortal || inTable || isDetailed) && (
            <PrimaryBadge
              id={`proceeding-pending-${index}`}
              message="proceedingPending"
            />
          )}
          {(isDetailed || inTable) && !isStateFeePaid && (
            <div className={isDetailed ? "ml-4" : "mt-1"}>
              <WarningBadge
                id={`stateFeeNotPaid-${index}`}
                message="stateFeeNotPaid"
              />
            </div>
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.InProceeding:
    case ActivityLicenseApplicationStatus.ProceedingReopened:
    case ActivityLicenseApplicationStatus.SignReopened:
      return (
        <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
      );
    case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
    case ActivityLicenseApplicationStatus.UploadDeclinedDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="decisionPending"
            />
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.OnSiteInspectionPending:
      return (
        <>
          {isPortal ? (
            <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          ) : (
            <WarningBadge
              id={`onSiteInspectionPending-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="onSiteInspectionPending"
            />
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.ProtocolInPreparation:
      return (
        <>
          {isPortal ? (
            <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          ) : (
            <WarningBadge
              id={`protocolInPreparation-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="protocolInPreparation"
            />
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.ReadyForDecision:
      return (
        <>
          {isPortal ? (
            <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          ) : (
            <WarningBadge
              id={`readyForDecision-${index}`}
              message="readyForDecision"
            />
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.SignAcceptedDecision:
    case ActivityLicenseApplicationStatus.SignDeclinedDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="waitingForSignature"
            />
          )}
        </>
      );
    case ActivityLicenseApplicationStatus.Paused:
      return <WarningBadge id={`paused-${index}`} message="paused" />;
    case ActivityLicenseApplicationStatus.Accepted:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
    case ActivityLicenseApplicationStatus.Declined:
      return <DangerBadge id={`declined-${index}`} message="declined" />;
    case ActivityLicenseApplicationStatus.Finished:
      return <DangerBadge id={`finished-${index}`} message="finished" />;
    case ActivityLicenseApplicationStatus.Registering:
      return <PrimaryBadge id={`registering-${index}`} message="registering" />;
    default:
      return <PrimaryBadge id={`saved-${index}`} message="saved" />;
  }
};
