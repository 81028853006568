import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { AddressSwitch, ContactInfoEditFormFields } from "./ContactInfo/ContactInfoEditFormFields";
import { RootState } from "../../../rootReducer";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { ContactInfoForm, formInfoToContactInfo } from "../../../Dto/ContactInfo/ContactInfoForm";
import "./ApplicationContactInfo.scss";
import { ContactInfo } from "../../../../api_client/medre_api";

interface Props {
  contactInfo?: ContactInfo;
  renderTitle?: ReactElement;
  renderInstructions?: ReactElement;
  addressSwitch?: AddressSwitch | boolean;
}

export const ApplicationContactInfo = ({
  contactInfo,
  renderTitle,
  renderInstructions,
  addressSwitch
}: Props) => {
  const dispatch = useDispatch();

  const appContactInfo = useSelector((state: RootState) => {
    return contactInfo
      ? contactInfo
      : state.person.contactInfo || ({} as ContactInfo);
  });

  const contactInfoFromRR = useSelector((state: RootState) => {
    return state.contactInfoFromRR || ({} as ContactInfo);
  });

  const [formInfo, setFormInfo] = useState(new ContactInfoForm(appContactInfo));
  const personIdCode = useSelector((state: RootState) => state.person.idCode);

  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft(
        "contactInfo",
        formInfoToContactInfo(formInfo)
      )
    );
  }, [dispatch, formInfo]);

  const setIsSaveAllowed = useCallback(
    (isAllowed: boolean) => {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", isAllowed)
      );
    },
    [dispatch]
  );

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      setFormInfo((previousFormInfo) => {
        return { ...previousFormInfo, [field]: value };
      });
    },
    []
  );

  return (
    <div className="portal-application-contact-info-wrapper">
      <h3 className="card-title font-weight-normal mb-2">
        {renderTitle ?? (
          <FormattedMessage
            id="ApplicationContactInfo.title"
            defaultMessage="Kontaktandmed"
          />
        )}
      </h3>
      <p className="mb-4">
        {renderInstructions ?? (
          <FormattedMessage
            id="ApplicationContactInfo.instructions"
            defaultMessage={`Sisestage enda kontaktandmed, seejärel klõpsake nuppu Edasi.`}
          />
        )}
      </p>

      <ContactInfoEditFormFields
        formInfo={formInfo}
        updateContactInfo={updateContactInfo}
        setIsFormValid={setIsSaveAllowed}
        hasEstonianPersonalCode={!!personIdCode}
        addressSwitch={addressSwitch}
        contactInfoFromRR={contactInfoFromRR}
        personIdCode={personIdCode}
      />
    </div>
  );
};
