import React, { useCallback } from "react";

import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { DecisionState } from "../../../../Decision/DecisionState";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { handleFileDownload } from "../../../../Util/FileUtils";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { DecisionFM } from "../../../../Messages/DecisionFM";
import "./DecisionsRow.scss";
import { Decision } from "../../../../../api_client/medre_api";
import { getDecisionInfo } from "../../../Shared/Decision/DecisionRowUtil";

interface Props {
  isOpen: boolean;
  handleClick: () => void;
  decisions?: Decision[];
}

export const DecisionsRow = ({ isOpen, handleClick, decisions }: Props) => {
  const className = isOpen ? "expanded" : "";
  const widthSize = useWindowWidthSize();

  const renderRow = useCallback(
    (icon: JSX.Element) => {
      return renderAccordionHeaderRow(
        widthSize,
        icon,
        <ActivityLicenseFM id="decisions" />
      );
    },
    [widthSize]
  );

  const renderTableHead = useCallback(() => {
    return (
      <thead className="d-md-table-header-group">
        <tr>
          <th className="pl-4">
            <DecisionFM id="decisionNumber" />
          </th>
          <th className="pl-4">
            <DecisionFM id="decisionType" />
          </th>
          <th className="pl-4">
            <DecisionFM id="decisionStatus" />
          </th>
          <th />
        </tr>
      </thead>
    );
  }, []);

  const renderContent = () => {
    if (!decisions || decisions.length === 0) {
      return null;
    }
    if (widthSize > MOBILE_MAX_WIDTH) {
      return (
        <table>
          {renderTableHead()}
          <tbody>{decisions?.map(renderDecisionTableRow)}</tbody>
        </table>
      );
    } else {
      return (
        <div id="decisions-mobile-wrapper" className="p-3 m-0">
          {decisions?.map(renderDecisionMobileRow)}
        </div>
      );
    }
  };

  const renderDecisionTableRow = (decision: Decision, i: number) => {
    const { signed, fileName, minioFileName, minioContainerName } = decision;

    let { downloadText, name, minioId } = getDecisionInfo(
      signed,
      fileName as string,
      minioContainerName as string,
      minioFileName as string
    );

    return (
      <tr role="row" key={i}>
        <td className="normal-column short-column pl-4">
          {decision.decisionNumber}
        </td>
        <td className="normal-column short-column pl-4" />
        <td className="normal-column short-column">
          <DecisionState index={i} isSigned={signed} />
        </td>
        <td className="normal-column text-right">
          <LinkButton
            id={downloadText}
            className="p-0"
            onClick={() => handleFileDownload(name, minioId)}
          />
        </td>
      </tr>
    );
  };

  const renderDecisionMobileRow = (decision: Decision, i: number) => {
    const { signed, fileName, minioFileName, minioContainerName } = decision;
    let { downloadText, name, minioId } = getDecisionInfo(
      signed,
      fileName as string,
      minioContainerName as string,
      minioFileName as string
    );
    return (
      <dl>
        <dt className="mb-2">
          <DecisionFM id="decisionNumber" />
        </dt>
        <dd>{decision.decisionNumber}</dd>
        <dt className="mb-2">
          <DecisionFM id="decisionType" />
        </dt>
        <dd>{decision.decisionNumber}</dd>
        <dt className="mb-2">
          <DecisionFM id="decisionStatus" />
        </dt>
        <dd>
          <DecisionState index={i} isSigned={signed} />
        </dd>
        <dt />
        <dd>
          <LinkButton
            id={downloadText}
            className="p-0 m-0"
            onClick={() => handleFileDownload(name, minioId)}
          />
        </dd>
      </dl>
    );
  };

  if (isOpen) {
    return (
      <>
        <div
          className={`${className} cursor-pointer accordion-header`}
          onClick={handleClick}
        >
          {renderRow(<MinusIcon />)}
        </div>
        <div className="detail-col pl-0 pr-0">{renderContent()}</div>
      </>
    );
  }

  return (
    <div
      className={`${className} cursor-pointer accordion-header`}
      onClick={handleClick}
    >
      {renderRow(<PlusIcon />)}
    </div>
  );
};
