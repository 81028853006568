import React from "react";
import { Col, Row } from "reactstrap";
import { FooterContactInfo } from "./FooterContactInfo";
import { QuickLinks } from "./QuickLinks";
import { Communication } from "./Communication";

export const FooterTop = () => {
  return (
    <Row className=" footer-top justify-content-center m-0 w-100">
      <Col className="footer-cell" xl={3} lg={4} xs={10}>
        <FooterContactInfo/>
      </Col>
      <Col className="footer-cell" xl={3} lg={4} xs={10}>
        <QuickLinks/>
      </Col>
      <Col className="footer-cell" xl={3} lg={4} xs={7}>
        <Communication/>
      </Col>
    </Row>
  );
};
