import React from "react";
import oval from "../../../assets/images/oval.svg";
import communication from "../../../assets/images/communication.svg";
import { FormattedMessage } from "react-intl";

export const Communication = () => {
  return (
    <div className="text-white">
      <div>
        <p className="footer-title">
          <img src={oval} alt="ovaal" />
          <img
            className="icon communication-icon"
            src={communication}
            alt="suhtluse ikoon"
          />
          <FormattedMessage
            id="communication.header"
            defaultMessage="SUHTLUS"
          />
        </p>
      </div>
      <div className="content">
        <a
          href="mailto:info@terviseamet.ee"
          className="link"
          rel="noopener noreferrer"
          target={"_blank"}
        >
          <FormattedMessage
            id="communication.getInTouch"
            defaultMessage="Alustan suhtlust"
          />
        </a>
      </div>
    </div>
  );
};
