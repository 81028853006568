import React, { cloneElement, Fragment } from "react";

type ValueOf<T> = T[keyof T];

// tslint:disable-next-line:no-any
export const groupBy = <T extends Record<string, any>, K extends keyof T>(
  list: T[],
  key: K
) =>
  list.reduce((previous, currentItem) => {
    const group = currentItem[key];
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<ValueOf<T>, T[]>);

export const uniqBy = <T, K extends keyof T>(arr: T[], key: K): T[] => {
  return Object.values(
    arr.reduce(
      (map, item) => ({
        ...map,
        [`${item[key]}`]: item
      }),
      {}
    )
  );
};

export const joinJSX = <T extends [string, JSX.Element][]>(
  array: T,
  separator: JSX.Element | null = <>,&nbsp;</>
): any =>
  array.reduce(
    (acc: JSX.Element[], [key, item]) => [
      ...acc,
      <Fragment key={`${key}_separator`}>
        {acc.length ? separator : null}
      </Fragment>,
      cloneElement(item, { key })
    ],
    []
  );
