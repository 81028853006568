import React, { FC, useCallback, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { ModalKeys } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { getBaseUrl, API } from "../../../../api";
import {
  GeneralPractitionerListStatusEnum,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI
} from "../../../../../api_client/medre_api";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import { ErrorMessage } from "../../../../Component/ErrorField/ErrorMessage";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { getDate } from "../../../../Util/DateUtils";

interface Props {
  onClose: () => void;
  onOpenModal: (id: ModalKeys) => void;
}

interface FormValues {
  deactivationDate: string;
}

export const RemoveFamilyDoctor: FC<Props> = ({ onClose, onOpenModal }) => {
  const dispatch = useDispatch();
  const [removed, setRemoved] = useState(false);
  const { fetchGPList, list, fetchGPListModifications } = useGeneralPractitionerList();
  const { control, formState, handleSubmit, watch } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {},
    shouldUnregister: false
  });

  const { isValid, isSubmitting } = formState;

  const gpDeactivationDate = watch("deactivationDate");

  const onSubmit = useCallback(async (values: FormValues) => {
      const { deactivationDate } = values;
      await officialFamilyListAPI(undefined, getBaseUrl(), API)
        .updateGeneralPractitionerModification(list.id, { deactivationDate }, { withCredentials: true });

      await fetchGPList(list.id);
      await fetchGPListModifications(list.id);

      setRemoved(true);
    },
    [fetchGPList, list.id, dispatch]
  );

  const handleOpenFamilyDoctorModal = useCallback(() => {
    onOpenModal(ModalKeys.addFamilyDoctor);
  }, [onOpenModal]);
  const handleOpenAssignSubstituteModal = useCallback(() => {
    onOpenModal(ModalKeys.assignSubstitute);
  }, [onOpenModal]);

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      {removed ? (
        <ModalBody className="d-flex flex-column align-items-center">
          <Button
            color="primary"
            className="mb-3"
            onClick={handleOpenFamilyDoctorModal}
          >
            <FormattedMessage
              id="GPListEditRemoveFamilyDoctorModal.addGPButton"
              defaultMessage="Lisa nimistule uus perearst"
            />
          </Button>
          {list.status === GeneralPractitionerListStatusEnum.Register && (
            <Button
              color="primary"
              className="mb-3"
              onClick={handleOpenAssignSubstituteModal}
            >
              <FormattedMessage
                id="GPListEditRemoveFamilyDoctorModal.addSubstituteButton"
                defaultMessage="Lisa nimistu asendaja"
              />
            </Button>
          )}
          <SecondaryFormattedButton id="cancel" onClick={onClose} />
        </ModalBody>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <FormattedMessage
              id="GPListAddRemoveFamilyDoctorModal.header"
              defaultMessage="Kas olete kindel, et soovite nimistult perearsti eemaldada?"
            />
          </ModalHeader>
          <ModalBody>
            <FormGroup row={true}>
              <Label
                htmlFor="gpListRemoveFamilyDoctorModalDeactivationDate"
                sm={4}
                className="text-right"
              >
                Eemaldamise kuupäev
              </Label>
              <Col sm={8} className="flex-grow-1">
                <Controller
                  name="deactivationDate"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={(field: ControllerRenderProps<Record<string, any>>) => (
                    <>
                      <DatePickerComponent
                        id="gpListRemoveFamilyDoctorModalDeactivationDate"
                        onDateChange={field.onChange}
                        selectedDate={field.value}
                        placeholder="pp.kk.aaaa"
                        invalid={field.value && getDate(new Date(list.generalPractitioner.activationDate!)) > field.value}
                      />
                      { field.value && getDate(new Date(list.generalPractitioner.activationDate!)) > field.value &&
                        <div className="d-block mt-2">
                          <ErrorMessage
                              message={`Perearsti eemaldamise kuupäev peab olema hilisem kui alguskuupäev ${formatDate(list.generalPractitioner.activationDate)}`}
                          />
                        </div>
                      }
                    </>
                  ) }
                />
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <SecondaryFormattedButton
              id="cancel"
              onClick={onClose}
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              color="primary"
              disabled={
                !isValid ||
                isSubmitting ||
                (list.generalPractitioner.activationDate
                  ? getDate(new Date(list.generalPractitioner.activationDate)) > getDate(new Date(gpDeactivationDate))
                  : false)
              }
            >
              <FormattedMessage
                id="GPListEditRemoveFamilyDoctorModal.submit"
                defaultMessage="Eemalda perearst"
              />
            </Button>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};