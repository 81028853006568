import React from "react";
import { FormattedMessage } from "react-intl";

import {
  OfficialUserPrivilegesEnum,
  PowerOfAttorney,
  PowerOfAttorneyStatusEnum,
  PowerOfAttorneyTypeEnum,
  PowersOfAttorneySearchFilterStartDateOperatorEnum as DateOperatorEnum
} from "../../api_client/medre_api";
import { isUserBusinessOwner } from "../Security/ProtectedComponent";
import { POWER_OF_ATTORNEY_TYPE_PREFIX } from "../Constants";

export const dateOperatorOptions = [
  {
    label: "=",
    value: DateOperatorEnum.Equals
  },
  {
    label: ">",
    value: DateOperatorEnum.GreaterThan
  },
  {
    label: ">=",
    value: DateOperatorEnum.GreaterThanEqual
  },
  {
    label: "<",
    value: DateOperatorEnum.LessThan
  },
  {
    label: "<=",
    value: DateOperatorEnum.LessThanEqual
  }
];

export const dateOperators: Record<string, string> = {
  [DateOperatorEnum.Equals]: "=",
  [DateOperatorEnum.GreaterThan]: ">",
  [DateOperatorEnum.GreaterThanEqual]: ">=",
  [DateOperatorEnum.LessThan]: "<",
  [DateOperatorEnum.LessThanEqual]: "<="
};

export const powerOfAttorneyTypeOptions = [
  {
    label: PowerOfAttorneyTypeEnum.Ultimate,
    value: PowerOfAttorneyTypeEnum.Ultimate
  },
  {
    label: PowerOfAttorneyTypeEnum.Full,
    value: PowerOfAttorneyTypeEnum.Full
  },
  {
    label: PowerOfAttorneyTypeEnum.Limited,
    value: PowerOfAttorneyTypeEnum.Limited
  },
  {
    label: PowerOfAttorneyTypeEnum.Gpl,
    value: PowerOfAttorneyTypeEnum.Gpl
  }
];

export const powerOfAttorneyTypeLevels: Record<string, number> = {
  [PowerOfAttorneyTypeEnum.Ultimate]: 1,
  [PowerOfAttorneyTypeEnum.Full]: 2,
  [PowerOfAttorneyTypeEnum.Limited]: 3,
  [PowerOfAttorneyTypeEnum.Gpl]: 4
};

export const userRoleLevels: Record<string, number> = {
  [OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate]: 1,
  [OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull]: 2,
  [OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited]: 3,
  [OfficialUserPrivilegesEnum.PowerOfAttorneyTypeGpl]: 4
};

export const showEditIcon = (
  representative: PowerOfAttorney,
  userRoles?: string[]
) => {
  if (isStatusNotExpired(representative.status)) {
    if (isUserBusinessOwner(userRoles)) {
      return true;
    } else {
      const foundRole = userRoles?.find((role) =>
        role.startsWith(POWER_OF_ATTORNEY_TYPE_PREFIX)
      );
      return (
        foundRole &&
        userRoleLevels[foundRole] <
          powerOfAttorneyTypeLevels[representative.type!]
      );
    }
  }
  return false;
};

export const showDeleteIcon = (
  representative: PowerOfAttorney,
  userRoles?: string[]
) => {
  return showEditIcon(representative, userRoles);
};

export const isStatusNotExpired = (status?: PowerOfAttorneyStatusEnum) =>
  status === PowerOfAttorneyStatusEnum.Valid ||
  status === PowerOfAttorneyStatusEnum.Scheduled;

export const getDefaultSelectedStatuses = () => {
  return [
    {
      label: (
        <FormattedMessage
          id="representativeSearch.valid"
          defaultMessage="Kehtiv"
        />
      ),
      value: PowerOfAttorneyStatusEnum.Valid
    },
    {
      label: (
        <FormattedMessage
          id="representativeSearch.scheduled"
          defaultMessage="Planeeritud"
        />
      ),
      value: PowerOfAttorneyStatusEnum.Scheduled
    }
  ];
};

export interface RepresentativeStatusDto {
  label: JSX.Element | string;
  value: PowerOfAttorneyStatusEnum;
}

export const getRepresentativeStatusValues = (
  statusArray: Array<RepresentativeStatusDto>
) => statusArray.map((status) => status.value);
