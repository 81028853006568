import React from "react";

import { FormattedButton, FormattedButtonProps } from "./FormattedButton";
import "./Button.scss";

export const SecondaryFormattedButton = ({
  id,
  type,
  disabled,
  className,
  onClick
}: FormattedButtonProps) => (
  <FormattedButton
    id={id}
    type={type}
    color="secondary"
    disabled={disabled}
    className={className + " btn-secondary"}
    onClick={onClick}
  />
);
