import React, { FC, forwardRef, ReactChild, ReactNode, ReactText } from "react";
import { Row, Col, ColProps, FormGroup, Label } from "reactstrap";
import classNames from "classnames";

import "./Filter.scss";

interface FilterContainerProps {
  children?: ReactChild | Array<ReactChild> | ReactText | ReactNode;
}

interface FilterFieldProps {
  id?: string;
  label?: ReactChild | Array<ReactChild> | ReactText | ReactNode;
  field?: ReactChild | Array<ReactChild> | ReactText | ReactNode;
}

export const FilterContainer: FC<FilterContainerProps> = ({ children }) => (
  <Row className="filter">{children}</Row>
);

export const FilterColumn = forwardRef(
  ({ className, children, ...props }: ColProps, ref) => (
    <Col
      className={classNames("filter__col", className)}
      {...props}
      // @ts-ignore
      ref={ref}
    >
      <div className="filter__group">{children}</div>
    </Col>
  )
);

export const FilterField: FC<FilterFieldProps> = ({ id, label, field }) => (
  <FormGroup>
    <Label
      htmlFor={id}
      className="form-group__label"
      {...(!Boolean(label) && { tag: "span" })}
    >
      {label}
    </Label>
    <div className="form-group__field">{field}</div>
  </FormGroup>
);
