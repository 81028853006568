import React, { useState } from "react";
import "../../Style/ContentContainer.scss";
import { useSelector } from "react-redux";
import { OfficialDashboard } from "../Dashboard/OfficialDashboard";
import { RootState } from "../../../rootReducer";
import { Loader } from "../../../Component/Loader/Loader";
import { Route, Switch } from "react-router";
import { OfficialLoginPage } from "../../../Security/Official/OfficialLoginPage";
import { Redirect } from "react-router-dom";
import { OfficialApplicationTypeSelection } from "../Application/OfficialApplicationTypeSelection";
import { ApplicationContainer } from "../Application/ApplicationContainer";
import { Search } from "../../Shared/Search/SearchApplication";
import { SystemAdministration } from "../SystemAdministration/SystemAdministration";
import { ApplicationDetails } from "../Application/Details/ApplicationDetails";
import { ApplicationCertificateDetails } from "../ApplicationCertificate/Details/ApplicationCertificateDetails";
import { ApplicantDetails } from "../Applicant/ApplicantDetails";
import { OfficialActivityLicenseContainer } from "../ActivityLicense/OfficialActivityLicenseContainer";
import { ActivityLicenseApplicationDetails } from "../ActivityLicense/Application/Details/ActivityLicenseApplicationDetails";
import { CountriesSelectProvider } from "../../../Component/Select/CountrySelect";
import { MyProceedings } from "../ActivityLicense/Proceedings/MyProceedings";
import GeneralPractitionerLists from "../GeneralPractitioners/GeneralPractitionerLists";
import { GeneralPractitionersDetailed } from "../GeneralPractitioners/GeneralPractitionersDetailed";
import { CompanyDetails } from "../../Shared/Company/CompanyDetails";
import { LocationSuspensionDetails } from "../ActivityLicense/Suspension/Details/Location/LocationSuspensionDetails";
import { ActivityLicenseDetails } from "../ActivityLicense/Details/ActivityLicenseDetails";
import { ActivityLicenseSuspensionDetails } from "../ActivityLicense/Suspension/Details/ActivityLicenseSuspensionDetails";
import { ServiceSuspensionDetails } from "../ActivityLicense/Suspension/Details/Service/ServiceSuspensionDetails";
import { LicenseAcquisitionDetails } from "../ActivityLicense/LicenseAcquisition/LicenseAcqusitionDetails";
import { ActivityLicenseInvalidationDetails } from "../ActivityLicense/Invalidation/ActivityLicenseInvalidationDetails";
import { ActivityLocationInvalidationDetails } from "../ActivityLicense/Invalidation/ActivityLocationInvalidationDetails";
import { OfficialActivityLocationContainer } from "../ActivityLicense/Location/OfficialActivityLocationContainer";
import { OfficialActivityLicenseServiceContainer } from "../ActivityLicense/Service/OfficialActivityLicenseServiceContainer";
import { DiscardServiceApplicationDetails } from "../ActivityLicense/Service/Discard/DiscardServiceApplicationDetails";
import { ActivityLicenseRestorationDetails } from "../ActivityLicense/Restoration/ActivityLicenseRestorationDetails";
import { ActivityLocationRestorationDetails } from "../ActivityLicense/Restoration/ActivityLocationRestorationDetails";
import { ActivityLicenseServiceRestorationDetails } from "../ActivityLicense/Restoration/ActivityLicenseServiceRestorationDetails";
import { DetailedApplicationTypeEnum } from "../../../../api_client/medre_api";
import { GeneralPractitionerApplicationDetails } from "../ActivityLicense/GPList/GeneralPractitionerApplicationDetails";
import {UpdateActivityLicenseServices} from "../../Shared/ActivityLicense/UpdateActivityLicenseServices";

export const OfficialContentContainer = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [isLoading, setIsLoading] = useState(false);

  const getLoggedInUserRouting = () => {
    return (
      <>
        <Switch>
          <Route exact={true} path="/" component={OfficialDashboard} />
          <Route
            exact={true}
            path="/new-application"
            component={OfficialApplicationTypeSelection}
          />
          <Route
            exact={true}
            path="/new-activity-license"
            component={OfficialActivityLicenseContainer}
          />
          <Route
            exact={true}
            path="/new-activity-location"
            component={OfficialActivityLocationContainer}
          />
          <Route
            exact={true}
            path="/new-activity-license-service"
            component={OfficialActivityLicenseServiceContainer}
          />
          <Route
              exact={true}
              path="/update-activity-license-application-services"
              component={UpdateActivityLicenseServices}
          />
          <Route
            exact={true}
            path="/new-application/occupation"
            component={() => (
              <ApplicationContainer
                type={DetailedApplicationTypeEnum.Occupation}
              />
            )}
          />
          <Route
            exact={true}
            path="/new-application/speciality"
            component={() => (
              <ApplicationContainer
                type={DetailedApplicationTypeEnum.Specialization}
              />
            )}
          />
          <Route
            exact={true}
            path="/applications/drafts/:id"
            component={ApplicationDetails}
          />
          <Route
            exact={true}
            path="/applications/certificate/:id"
            component={ApplicationCertificateDetails}
          />
          <Route path="/applications/:id" component={ApplicationDetails} />
          <Route
            path="/activity-license-applications/:id"
            component={ActivityLicenseApplicationDetails}
          />
          <Route
            exact={true}
            path="/activity-license-invalidations/:id"
            component={ActivityLicenseInvalidationDetails}
          />
          <Route
            exact={true}
            path="/activity-license-invalidations/location/:id"
            component={ActivityLocationInvalidationDetails}
          />
          <Route
            exact={true}
            path="/activity-license-suspensions/:id"
            component={ActivityLicenseSuspensionDetails}
          />
          <Route
            exact={true}
            path="/activity-license-suspensions/location/:id"
            component={LocationSuspensionDetails}
          />
          <Route
            exact={true}
            path="/activity-license-suspensions/service/:id"
            component={ServiceSuspensionDetails}
          />
          <Route
            path="/activity-licenses/:id"
            component={ActivityLicenseDetails}
          />
          <Route
            path="/license-acquisitions/:id"
            component={LicenseAcquisitionDetails}
          />
          <Route
            path="/license-restorations/:id"
            component={ActivityLicenseRestorationDetails}
          />
          <Route
            path="/location-restorations/:id"
            component={ActivityLocationRestorationDetails}
          />
          <Route
            path="/service-restorations/:id"
            component={ActivityLicenseServiceRestorationDetails}
          />
          <Route
            path="/discard-services/:id"
            component={DiscardServiceApplicationDetails}
          />
          <Route path="/persons/:id" component={ApplicantDetails} />
          <Route path="/companies/:id" component={CompanyDetails} />
          <Route
            exact={true}
            path="/my-proceedings"
            component={MyProceedings}
          />
          <Route exact={true} path="/search" component={Search} />
          <Route
            exact={true}
            path="/general-practitioner-lists"
            component={GeneralPractitionerLists}
          />
          <Route
            path="/general-practitioner-lists/:id"
            component={GeneralPractitionersDetailed}
          />
          <Route
            path="/general-practitioner-application/:id"
            component={GeneralPractitionerApplicationDetails}
          />
          <Route exact={true} path="/system-administration" component={SystemAdministration} />
          <Redirect to="/" />
        </Switch>
      </>
    );
  };

  if (userInfo) {
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <CountriesSelectProvider>
            {getLoggedInUserRouting()}
          </CountriesSelectProvider>
        )}
      </>
    );
  } else {
    return (
      <div className="content-container content-container-w-sidemenu">
        <OfficialLoginPage setIsLoading={setIsLoading} />
      </div>
    );
  }
};
