import React from "react";

export const RepresentativeInfoTooltipContent = () => {
  return (
    <div style={{textAlign:"left"}}>
      <b>1. TYPE_ULTIMATE</b>
      <p>
        Volituse tüüp annab õiguse teha tegevuslubade taotlusi, hallata perearsti nimistuid ning
        tegevuslubadel töötamisi. Samuti saab volitatud isik anda edasi volituse (ainult tüüpidega
        TYPE_FULL, TYPE_LIMITED ja TYPE_GPL) kellelegi teisele tegevuste tegemiseks.
      </p>
      <b>2. TYPE_FULL</b>
      <p>
        Volituse tüüp annab õiguse tegevuslubadel töötamisi ning perearsti nimistuid hallata. Samuti saab
        volitatud isik anda edasi volituse (ainult tüüpidega TYPE_LIMITED ja TYPE_GPL) kellelegi teisele
        tegevuste tegemiseks.
      </p>
      <b>3. TYPE_LIMITED</b>
      <p>
        Volituse tüüp annab õiguse tegevuslubadel töötamisi ning perearsti nimistuid hallata. Volitatud
        isiku poolt edasi volituste andmine järgmistele isikutele pole lubatud.
      </p>
      <b>4. TYPE_GPL</b>
      <p>Volituse tüüp annab õiguse hallata perearsti nimistuid.</p>
    </div>
  );
};