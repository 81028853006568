import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const EhisQueryConfirmationModal = ({
  isOpen,
  onClose,
  onSave
}: Props) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>
      <FormattedMessage
        id="ehisQueryConfirmationModal.header"
        defaultMessage="Oled sa kindel, et soovid pärida hariduse andmed uuesti?"
      />
    </ModalHeader>
    <ModalBody>
      <FormattedMessage
        id="ehisQueryConfirmationModal.body"
        defaultMessage={`Hariduse andmed kirjutatakse toimingu käigus üle.`}
      />
    </ModalBody>
    <ModalFooter>
      <SecondaryFormattedButton id="close" onClick={() => onClose()} />
      <PrimaryFormattedButton id="newQuery" onClick={() => onSave()} />
    </ModalFooter>
  </Modal>
);
