import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { FileReference } from "../../../../../api_client/medre_api";
import { renderDocumentRows } from "../../../../Dto/File/FileReference";

interface Props {
  documents: FileReference[];
}

export const DocumentsCard = ({ documents }: Props) => (
  <>
    <h4 className="application-title fw-normal">
      <FormattedMessage
        id="activityLicense.documents"
        defaultMessage="Dokumendid"
      />
    </h4>
    <Card className="info-card mb-2">{renderDocumentRows(documents)}</Card>
  </>
);
