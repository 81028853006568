import React from "react";
import Card from "reactstrap/lib/Card";

import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { getDocumentsContent } from "../../../Shared/ActivityLicense/Documents/DocumentsUtil";
import { ActivityLicense } from "../../../../../api_client/medre_api";
import { BusinessRegistryInfoCard } from "../BusinessRegistryInfoCard";
import { ActivityLicenseDownloadButton } from "../../../Shared/ActivityLicense/ActivityLicenseDownloadButton";

export interface Props {
  details: ActivityLicense;
}

export const DetailsCard = ({ details }: Props) => {
  const { id, name, address, registryCode, email, phone } =
    details.company || {};

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" /> Tegevusloa detailid
        <ActivityLicenseDownloadButton id={details.id || ""} />
      </h2>
      <BusinessRegistryInfoCard
        id={id}
        name={name}
        address={address}
        registryCode={registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: details.businessArea?.name }]}
      />
      <ApplicationDetailsInfoCard
        title="Dokumendid"
        content={getDocumentsContent(details.fileReferences || [])}
      />
    </Card>
  );
};
