import React, { useState } from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useHistory } from "react-router";
import { AxiosPromise, AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import useModal from "../../../../Hook/useModal";
import {
  ActivityLicenseGPListApplication,
  DetailedActivityLicenseGPListApplication
} from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  licenseId: string;
  numberOfGpLists?: number;
  endpoint: (
    activityLicenseGPListApplication: ActivityLicenseGPListApplication,
    options?: any
  ) => AxiosPromise<DetailedActivityLicenseGPListApplication>;
}

type GPListNumberChangeForm = {
  numberOfGpLists?: number;
};

export const ActivityLicenseGPListApplicationModal = ({
  isOpen,
  onClose,
  licenseId,
  numberOfGpLists,
  endpoint
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const GPListNumberChangeModal = useModal();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues: GPListNumberChangeForm = {
    numberOfGpLists: numberOfGpLists
  };

  const { handleSubmit, register, errors, reset, formState } =
    useForm<GPListNumberChangeForm>({
      defaultValues,
      mode: "onBlur"
    });

  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const handleClose = () => {
    reset(defaultValues);
    GPListNumberChangeModal.handleClose();
    onClose();
  };

  const onSubmit = (form: GPListNumberChangeForm) => {
    if (isSubmitting) {
      return;
    }
    const data = {
      numberOfGpLists: form.numberOfGpLists,
      licenseId: licenseId
    };

    setIsSubmitting(true);
    endpoint(data, {
      withCredentials: true
    })
      .then((res) => {
        displayAlert("applicationSubmitted", AlertType.Success, dispatch);
        handleClose();
        history.push("/general-practitioner-application/" + res.data.id);
      })
      .catch((error: AxiosError) =>
        displayAlert("activityLicenseSubmitFailed", AlertType.Danger, dispatch)
      )
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal id="gp-list-application-modal" isOpen={isOpen}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">Muuda nimistute arvu</ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div className="label">Nimistute arv</div>
            <Input
              name="numberOfGpLists"
              valid={
                ((isSubmitted && !isSubmitSuccessful) ||
                  touched.numberOfGpLists) &&
                !errors.numberOfGpLists
              }
              invalid={!!errors.numberOfGpLists}
              innerRef={register({ required: true })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="reset"
            onClick={handleClose}
          />
          <PrimaryFormattedButton id="confirm" type="submit" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
