import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { SingleSelect } from "../../../Component/Select/SingleSelect";
import { GroupOption, Option } from "../../../Component/Select/SelectTypes";
import "./ActivityLicenseBusinessArea.scss";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import { RootState } from "../../../rootReducer";
import { ActivityLicenseFM } from "../../../Messages/ActivityLicenseFM";
import {
  ActivityLicenseBusinessArea,
  ActivityLicenseApplication,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../api_client/medre_api";
import { GeneralFM } from "../../../Messages/GeneralFM";
import { getBaseUrl, API } from "../../../api";

interface Props {
  disabled?: boolean;
}

export const ActivityLicenseBusinessAreaStep = ({ disabled }: Props) => {
  const dispatch = useDispatch();
  const [hospitalAreas, setHospitalAreas] = useState<
    ActivityLicenseBusinessArea[]
  >([]);
  const [regularAreas, setRegularAreas] = useState<
    ActivityLicenseBusinessArea[]
  >([]);

  const { businessArea, saveAllowed }: Record<string, any> = useSelector(
    (state: RootState) => state.activityLicenseApplication
  );
  useEffect(() => {
    activityPermitAPI(undefined, getBaseUrl(), API)
      .getBusinessAreaClassifiers1({
        withCredentials: true
      })
      .then((res) => {
        setHospitalAreas(res.data.hospitalTypeAreas!);
        setRegularAreas(res.data.regularAreas!);
      });
  }, []);

  useEffect(() => {
    if (businessArea && !saveAllowed) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          true
        )
      );
    } else if (!businessArea && saveAllowed) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          false
        )
      );
    }
  }, [businessArea, saveAllowed, dispatch]);

  const createOption = (area: ActivityLicenseBusinessArea) => {
    return { label: area.name, value: area.id };
  };

  const getOptions = (): GroupOption[] => {
    return [
      {
        label: <GeneralFM id="hospital" />,
        options: hospitalAreas?.map(createOption) as Option[]
      },
      {
        label: <ActivityLicenseFM id="businessArea" />,
        options: regularAreas?.map(createOption) as Option[]
      }
    ];
  };

  const handleOptionChange = (selectedOption: Option) => {
    const selectedArea = [...regularAreas, ...hospitalAreas].find(
      (area: ActivityLicenseBusinessArea) => area.id === selectedOption.value
    );
    dispatch(
      activityLicenseApplicationActions.updateActivityLicense(
        "businessArea",
        selectedArea as string
      )
    );
    dispatch(
      activityLicenseApplicationActions.updateLocationInfo(0, "services", [])
    );
  };

  return (
    <div className="activity-license-business-area-wrapper">
      <h3>
        <FormattedMessage
          id="activityLicenseStepBusinessArea.title"
          defaultMessage="Vali tegevusala"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicenseStepBusinessArea.subtitle"
          defaultMessage={`ning klõpsa "Edasi"`}
        />
      </p>
      <div className="business-area-form">
        <Label htmlFor="activityLicenseBusinessAreaStepBusinessArea">
          <FormattedMessage
            id="ActivityLicenseBusinessArea.chooseArea"
            defaultMessage="Vali tegevusala"
          />
        </Label>
        <SingleSelect
          inputId="activityLicenseBusinessAreaStepBusinessArea"
          value={businessArea ? createOption(businessArea) : undefined}
          options={getOptions()}
          handleOptionChange={handleOptionChange}
          placeholder={<></>}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
