import React, { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label } from "reactstrap";

import { AsyncCustomSelect } from "../../../../Component/Select/AsyncCustomSelect";
import { getBaseUrl, API } from "../../../../api";
import { TypedOption } from "../../../../Component/Select/SelectTypes";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { RootState } from "../../../../rootReducer";
import {
  ActivityLicense,
  TegevuslubadeTeenusApiFactory as activityPermitServiceAPI
} from "../../../../../api_client/medre_api";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";

export const LicenseSelectionContainer = () => {
  const dispatch = useDispatch();
  const activityLicense = useSelector(
    (state: RootState) => state.licenseAcquisitionApplication.license
  );
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const formClass = "ml-0 mt-4 w-100";

  const loadLicenses = (licensePrefix: string) => {
    return activityPermitServiceAPI(undefined, getBaseUrl(), API).getByCode1(
      licensePrefix,
      {
        withCredentials: true
      }
    );
  };

  useEffect(() => {
    loadLicenses("L");
  }, []);

  useEffect(() => {
    dispatch(
      licenseAcquisitionActions.updateApplicationDraft(
        "saveAllowed",
        !!activityLicense
      )
    );
  }, [dispatch, activityLicense]);

  const selectedValue = (license: ActivityLicense | undefined): undefined | {
    label: ReactElement<any, any>;
    value: ActivityLicense;
  } => {
    if (!license) {
      return;
    }
    return {
      label: (
        <span>
          <b>{ license.number }</b> - { license.businessArea?.name }
        </span>
      ),
      value: license
    };
  };

  const createLicenseOption = (license: ActivityLicense | undefined): {
    label: ReactElement<any, any>;
    value: ActivityLicense;
  } => {
    if (!license) {
      return {
        label: <></>,
        value: {} as ActivityLicense
      };
    }
    return {
      label: (
        <span>
          <b>{ license.number }</b> - { license.businessArea?.name }
        </span>
      ),
      value: license
    };
  };

  const selectedLicense = createLicenseOption(activityLicense);

  const filterLicenseResponse = (license: ActivityLicense): boolean => {
    return selectedLicense?.value.id !== license.id;
  };

  const handleSelect = (option: TypedOption<ActivityLicense>): void => {
    dispatch(
      licenseAcquisitionActions.updateApplicationDraftLicense(option.value)
    );
  };

  return (
    <>
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.activityLicense"
          defaultMessage="Tegevusluba"
        />
      </h3>
      <p>
        <FormattedMessage
          id="licenseAcquisition.licenseSelectionDescription"
          defaultMessage="Vali tegevusluba, mida üle võtta."
        />
      </p>
      <FormGroup className={isMobile ? formClass : formClass + " form-inline"}>
        <Label
          htmlFor="licenseSelectionContainerLicenseAcquisition"
          className="text-right mr-4"
        >
          <FormattedMessage
            id="activityLicense.activityLicense"
            defaultMessage="Tegevusluba"
          />
        </Label>
        <div className="input-wrapper w-100">
          <AsyncCustomSelect
            inputId="licenseSelectionContainerLicenseAcquisition"
            value={selectedValue(activityLicense)}
            toOption={(license): {label: ReactElement<any, any>; value: ActivityLicense} =>
              createLicenseOption(license)}
            loadMethod={loadLicenses}
            onSelect={handleSelect}
            filterOption={filterLicenseResponse}
            placeholder="Otsi tegevusluba"
          />
        </div>
      </FormGroup>
    </>
  );
};
