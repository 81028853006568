import React, { ReactNode } from "react";

import { QueryBoxInfo } from "./QueryBoxInfo";
import { QueryBoxSuccess } from "./QueryBoxSuccess";

enum QueryBoxType {
  INFO = "INFO",
  SUCCESS = "SUCCESS"
}

export const getQueryBoxType = (
  currentValue: string | undefined,
  newValue: string
) => (currentValue === newValue ? QueryBoxType.SUCCESS : QueryBoxType.INFO);

interface Props {
  type: QueryBoxType;
  className?: string;
  value?: string;
  useValue: () => void;
  headerContent: ReactNode;
  textContent: ReactNode;
}

export const QueryBox = ({ type, className, value, useValue, headerContent, textContent }: Props) =>
  type === QueryBoxType.INFO ? (
    <QueryBoxInfo
      className={className}
      value={value}
      useValue={useValue}
      headerContent={headerContent}
      textContent={textContent} />
  ) : (
    <QueryBoxSuccess className={className} value={value!} />
  );
