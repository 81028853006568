import React, { useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ContactInfoEditFormFields } from "./ContactInfoEditFormFields";
import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { RootState } from "../../../../rootReducer";
import "./ContactInfoEditForm.scss";
import { ContactInfo, KasutajaAndmeteTeenusApiFactory as userDataAPI } from '../../../../../api_client/medre_api';
import { API, getBaseUrl } from '../../../../api';

interface Props {
  formInfo: ContactInfoForm;
  updateContactInfo: (fieldName: keyof ContactInfoForm, value?: string) => void;
  setIsFormValid: (value: boolean) => void;
  isForceValidation: boolean;
}

export const ContactInfoEditForm = ({
  formInfo,
  isForceValidation,
  updateContactInfo,
  setIsFormValid
}: Props) => {
  const personIdCode = useSelector((state: RootState) => state.person.idCode);
  const [contactInfoFromRR, setContactInfoFromRR] = useState<ContactInfo>();
  const dataAPI = userDataAPI(undefined, getBaseUrl(), API);
  useEffect(() => {
    if (personIdCode) {
      dataAPI.getContactInfoFromRR().then((result) => setContactInfoFromRR(result.data));
    }
  }, []);

  return (
    <Form className="contact-info-edit-form">
      <Row className="flex-column m-3">
        <p className="contact-info-edit-title-text">
          <FormattedMessage
            id="contactInfoEdit.changeContactInfo"
            defaultMessage="Kontaktandmete muutmine"
          />
        </p>
        <p className="contact-info-edit-info-text">
          <FormattedMessage
            id="contactInfoEdit.infoText"
            defaultMessage="Asjakohalised andmed on olulised, et Sinuga kiirelt kontakti saada."
          />
        </p>
      </Row>
      <ContactInfoEditFormFields
        formInfo={formInfo}
        updateContactInfo={updateContactInfo}
        setIsFormValid={setIsFormValid}
        isForceValidation={isForceValidation}
        hasEstonianPersonalCode={!!personIdCode}
        contactInfoFromRR={contactInfoFromRR}
        personIdCode={personIdCode}
      />
    </Form>
  );
};
