import React, { FC, useEffect } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { SearchInsuranceContract } from "../../../Shared/Search/Filters/FilterTypes";
import { ReactTable } from "../../../../Component/Table/ReactTable";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { useDispatch } from "react-redux";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { InsuranceContractListColumns } from "../../../Shared/InsuranceContract/InsuranceContractListColumns";

interface Props {
  data: SearchInsuranceContract[];
  pageCount: number;
  pageIndex: number;
}

export const InsuranceContractResults: FC<Props> = ({ data, pageCount, pageIndex }) => {
  const dispatch = useDispatch();

  const table = useTable<SearchInsuranceContract>(
    {
      columns: InsuranceContractListColumns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: { sortBy }
  } = table;

  useEffect(() => {
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<SearchInsuranceContract>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) => column.getHeaderProps(column.getSortByToggleProps)}
        className="border-0"
        square
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};