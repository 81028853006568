import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import base64js from "base64-js";
import { AxiosResponse } from "axios";

import { SecondaryFormattedButton } from "../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../Component/Button/PrimaryFormattedButton";
import { RadioButtonElement, RadioGroup } from "../Component/Radio/RadioGroup";
import { IdCardSigning } from "./IdCardSigning";
import { MobileSigning } from "./MobileSigning";
import { Loader } from "../Component/Loader/Loader";
import { getBaseUrl, API } from "../api";
import { AlertMessage } from "../Alert/AlertMessage";
import { AlertType } from "../Dto/Alert/AlertItem";
import { alertActions } from "../Alert/alertActions";
import { OtsusteAllkirjastamiseTeenusApiFactory as decisionSignAPI } from "../../api_client/medre_api";
import "./SigningModal.scss";

interface Props {
  isOpen: boolean;
  decisionId: string;
  onClose: () => void;
  onSuccess: () => void;
}

type SignatureModalType = "idCard" | "mobile";

export const SigningModal = ({
  isOpen,
  decisionId,
  onClose,
  onSuccess
}: Props) => {
  const dispatch = useDispatch();

  const [modalType, setModalType] = useState<SignatureModalType>("idCard");
  const [loading, setLoading] = useState(false);
  const [idCode, setIdCode] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [challenge, setChallenge] = useState<string>();

  const radioButtons: RadioButtonElement[] = [
    {
      id: "idCard",
      value: "idCard",
      labelText: (
        <FormattedMessage id="signingModal.idCard" defaultMessage="ID-Kaart" />
      )
    },
    {
      id: "mobile",
      value: "mobile",
      labelText: (
        <FormattedMessage
          id="signingModal.SigningModal"
          defaultMessage="Mobiili-ID"
        />
      )
    }
  ];

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value;
    setModalType(id as SignatureModalType);
    setMobile(undefined);
    setIdCode(undefined);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "mobile") {
      setMobile(e.target.value);
    } else {
      setIdCode(e.target.value);
    }
  };

  const sign = () => {
    if (modalType === "idCard") {
      signWithIdCard();
    } else {
      signWithMobile();
    }
  };

  const handleSuccess = () => {
    const alertMessage = <AlertMessage id="signingSuccess" />;
    const alert = { id: 0, type: AlertType.Success, message: alertMessage };
    onSuccess();
    dispatch(alertActions.addAlert(alert));
  };

  const handleException = () => {
    const alertMessage = <AlertMessage id="signingFailed" />;
    const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
    setLoading(false);
  };

  const createContainer = async () =>
    decisionSignAPI(undefined, getBaseUrl(), API)
      .createContainer(decisionId, {
        withCredentials: true
      })
      .then((res) => res.data.containerId)
      .catch(handleException);

  const signWithIdCard = async () => {
    setLoading(true);
    const containerId = await createContainer();

    // tslint:disable-next-line:no-any
    let form: any = {};
    // tslint:disable-next-line:no-any
    let hwcCertificate: any = null;

    // tslint:disable-next-line:no-any
    const hwcrypto = (window as any).hwcrypto;
    if (hwcrypto === undefined) {
      const alertMessage = <AlertMessage id="certificateLoadFailed" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      setLoading(false);
      return;
    }
    hwcrypto
      .getCertificate({ lang: "et" })
      // tslint:disable-next-line:no-any
      .then((certificate: any) => {
        form.certificate = Array.prototype.slice.call(certificate.encoded);
        form.containerId = containerId;
        hwcCertificate = certificate;
        return decisionSignAPI(
          undefined,
          getBaseUrl(),
          API
        ).prepareRemoteSigning(decisionId, form, {
          withCredentials: true
        });
      })
      .then((response: AxiosResponse) => {
        form.signatureId = response.data.generatedSignatureId;
        let dataToSignHash = new Uint8Array(
          base64js.toByteArray(response.data.dataToSignHash)
        );
        return hwcrypto.sign(
          hwcCertificate,
          {
            type: response.data.digestAlgorithm,
            value: dataToSignHash
          },
          { lang: "et" }
        );
      })
      // tslint:disable-next-line:no-any
      .then((signature: any) => {
        form.signature = Array.prototype.slice.call(signature.value);
        return decisionSignAPI(
          undefined,
          getBaseUrl(),
          API
        ).finalizeRemoteSigning(decisionId, form, {
          withCredentials: true
        });
      })
      .then(handleSuccess)
      .catch(handleException)
      .finally(() => setLoading(false));
  };

  const signWithMobile = async () => {
    setLoading(true);
    const containerId = await createContainer();

    decisionSignAPI(undefined, getBaseUrl(), API)
      .prepareMobileSigning(
        decisionId,
        {
          containerId: containerId as string | undefined,
          phoneNr: mobile?.trim(),
          personIdentifier: idCode?.trim()
        },
        {
          withCredentials: true
        }
      )
      .then((res) => {
        setChallenge(res.data.challengeId);
        setLoading(false);
        return decisionSignAPI(
          undefined,
          getBaseUrl(),
          API
        ).finalizeMobileSigning(
          decisionId,
          {
            signatureId: res.data.signatureId,
            containerId: containerId as string | undefined
          },
          {
            withCredentials: true
          }
        );
      })
      .then(handleSuccess)
      .catch(handleException)
      .finally(() => setChallenge(undefined));
  };

  const renderModalContent = () => (
    <>
      <RadioGroup
        options={radioButtons}
        name="TypeSelection"
        value={modalType}
        className="d-flex flex-wrap"
        onChange={handleTypeChange}
        disabled={loading || Boolean(challenge)}
      />
      {modalType === "idCard" ? (
        <IdCardSigning />
      ) : (
        <MobileSigning
          mobile={mobile}
          idCode={idCode}
          challenge={challenge}
          handleChange={handleInputChange}
        />
      )}
    </>
  );

  return (
    <Modal isOpen={isOpen} id="signing-modal">
      <ModalHeader>Valige allkirjastamise meetod</ModalHeader>
      <ModalBody>{loading ? <Loader /> : renderModalContent()}</ModalBody>
      <ModalFooter className="justify-content-between">
        <SecondaryFormattedButton
          id="backToDetailView"
          disabled={loading}
          onClick={onClose}
        />
        <PrimaryFormattedButton
          id="confirm"
          disabled={loading || Boolean(challenge)}
          onClick={sign}
        />
      </ModalFooter>
    </Modal>
  );
};
