import {
  FilterColumn,
  FilterContainer,
  FilterField,
} from "../../../../Component/Filter/Filter";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "reactstrap";
import { useCallback } from "react";
import {
  filterActions,
  FilterItemData,
} from "../../../Shared/Search/Filters/filterActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import { Option } from "../../../../Component/Select/SingleSelect";
import { DecisionStatusFilter, decisionLicenseStatuses } from "./DecisionStatusFilter";

export const DecisionLicenseFilters = () => {
  const state = useSelector(
    filterSelectors.selectDecisionLicenceFilters,
    shallowEqual
  );

  const dispatch = useDispatch();
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setDecisionLicenseFilter({ key, data }));
    },
    [dispatch]
  );

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );

  const handleChangeMultiSelect = useCallback(
    (options: Option[], name: string) => {
      set(name, options || []);
    },
    [set]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <FilterField
          id="decisionLicenseFiltersCompanyName"
          label={
            <FormattedMessage
              id="filters.companyName"
              defaultMessage="Ettevõtte nimi"
            />
          }
          field={
            <Input
              id="decisionLicenseFiltersCompanyName"
              value={state.companyName}
              onChange={handleChangeInput}
              name="companyName"
              placeholder="Ettevõtte nimi"
            />
          }
        />

        <FilterField
          id="decisionLicenseFiltersDecisionNumber"
          label={
            <FormattedMessage
              id="filters.decisionNumber"
              defaultMessage="Otsuse number"
            />
          }
          field={
            <Input
              id="decisionLicenseFiltersDecisionNumber"
              value={state.decisionNumber}
              onChange={handleChangeInput}
              name="decisionNumber"
              placeholder="Otsuse number"
            />
          }
        />

        <FilterField
          id="decisionLicenseFiltersDecisionSearchStatuses"
          label={
            <FormattedMessage
              id="filters.decisionSearchStatuses"
              defaultMessage="Otsuse tüüp"
            />
          }
          field={
            <DecisionStatusFilter
              inputId="decisionLicenseFiltersDecisionSearchStatuses"
              handleOptionsChange={handleChangeMultiSelect}
              options={decisionLicenseStatuses}
              value={state.decisionSearchStatuses}
            />
          }
        />
      </FilterColumn>
    </FilterContainer>
  );
};
