import {Option} from "../Component/Select/SingleSelect";
import countries_en from "../../translations/countries/en.json";
import countries_ru from "../../translations/countries/ru.json";
import countries_et from "../../translations/countries/et.json";
export const getCountryOption = (option: Option, locale: string) => {
  // tslint:disable-next-line:no-any
  let countries: any;
  switch (locale) {
    case "en":
      countries = countries_en;
      break;
    case "ru":
      countries = countries_ru;
      break;
    case "et":
    default:
      countries = countries_et;
      break;
  }
  const countryCode = option.value;
  return countries["country." + countryCode];
};