import React, { useMemo } from "react";
import { useTable } from "react-table";

import { GeneralPractitionerList } from "../../../../../api_client/medre_api";
import { useMobile } from "../../../../Hook/useMobile";
import {
  activityLicenseGPListColumns,
  activityLicenseGPListMobileColumns
} from "../../../Shared/GeneralPractitioners/GeneralPractitionerListColumns";
import { ReactTable } from "../../../../Component/Table/ReactTable";

interface Props {
  data: GeneralPractitionerList[];
}

const ActivityLicenseGPList = ({ data }: Props) => {
  const isMobile = useMobile();
  const columns = useMemo(
    () =>
      isMobile
        ? activityLicenseGPListMobileColumns
        : activityLicenseGPListColumns,

    [isMobile]
  );

  const table = useTable<GeneralPractitionerList>({
    columns,
    data
  });
  return (
    <div className="table-wrap">
      <ReactTable<GeneralPractitionerList> table={table} rows={table.rows} />
    </div>
  );
};

export default ActivityLicenseGPList;
