import React from "react";
import { Card } from "reactstrap";

import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { getFullPhoneNumber } from "../../../Shared/Application/ContactInfo/Address/AddressUtil";
import {
  renderOverViewRow,
  renderDeliveryByPost
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import {
  ApplicationCertificateDeliveryTypeEnum,
  ContactInfo
} from "../../../../../api_client/medre_api";

interface Props {
  deliveryType?: ApplicationCertificateDeliveryTypeEnum;
  contactInfo?: ContactInfo;
  deliveryAddress?: string;
}

export const ApplicationCertificateContactInfoOverViewCard = ({
  deliveryType,
  contactInfo,
  deliveryAddress
}: Props) => {
  return (
    <>
      <h5 className="fw-normal">
        <ContactInfoFM id="contactsCertificate" />
      </h5>
      <Card className="info-card">
        {renderOverViewRow("contactInfo", "email", contactInfo?.email)}
        {renderOverViewRow(
          "contactInfo",
          "phone",
          getFullPhoneNumber(contactInfo) || "-"
        )}
        {deliveryType && (
          <>
            {deliveryType ===
              ApplicationCertificateDeliveryTypeEnum.TakeMyself &&
              renderOverViewRow(
                "contactInfo",
                "certificateIssuance",
                <ContactInfoFM id="takeCertificateCompanyAddress" />
              )}
            {deliveryType === ApplicationCertificateDeliveryTypeEnum.Post &&
              renderDeliveryByPost(deliveryAddress)}
            {deliveryType ===
              ApplicationCertificateDeliveryTypeEnum.Electronic &&
              renderOverViewRow(
                "contactInfo",
                "certificateIssuance",
                <ContactInfoFM id="certificateIssuedElectronically" />
              )}
          </>
        )}
      </Card>
    </>
  );
};
