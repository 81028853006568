import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  ReactTable,
  ReactTableColumn,
} from "../../../../Component/Table/ReactTable";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { SearchDecisionLicense } from "../../../Shared/Search/Filters/FilterTypes";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { DecisionState } from "../../../../Decision/DecisionState";
import { DecisionDownloadButton } from "../../../Shared/Decision/DecisionDownloadButton";

interface Props {
  data: SearchDecisionLicense[];
  pageCount: number;
  pageIndex: number;
}

const decisionLicenseColumns: ReactTableColumn<SearchDecisionLicense>[] = [
  {
    accessor: "createdAt",
    Header: <GeneralFM id="date" />,
    Cell: (cell) => formatDate(cell.value),
    width: 100,
  },
  {
    accessor: "decisionNumber",
    Header: (
      <FormattedMessage
        id="filters.decisionNumber"
        defaultMessage="Otsuse number"
      />
    ),
    width: 100,
  },
  {
    accessor: "companyName",
    Header: (
      <FormattedMessage
        id="filters.companyName"
        defaultMessage="Ettevõtte nimi"
      />
    ),
    width: 0,
  },
  {
    accessor: "businessAreaName",
    Header: (
      <FormattedMessage
        id="filters.businessAreaName"
        defaultMessage="Tegevusala"
      />
    ),
    width: 0,
  },
  {
    accessor: "status",
    id: "fileObjectType",
    Header: (
      <FormattedMessage
        id="filters.decisionSearchStatuses"
        defaultMessage="Otsuse tüüp"
      />
    ),
    Cell: ({ cell }) => (
      <DecisionState status={cell.value} index={cell.row.index} />
    ),
    width: 100,
  },
  {
    accessor: "id",
    Cell: ({ cell }) => <DecisionDownloadButton decision={cell.row.original} />,
    width: 0,
    sticky: true,
  },
];

export const DecisionLicenseResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex,
}) => {
  const dispatch = useDispatch();
  const table = useTable<SearchDecisionLicense>(
    {
      columns: decisionLicenseColumns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: { sortBy },
  } = table;

  useEffect(() => {
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<SearchDecisionLicense>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) =>
          column.getHeaderProps(column.getSortByToggleProps())
        }
        className="border-0"
        square={true}
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
