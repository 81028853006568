import { OfficialUserPrivilegesEnum } from "../../api_client/medre_api";
import { REPRESENTATIVE_PREFIX } from "../Constants";

export const getSelectedCompanyId = (
  roles?: (OfficialUserPrivilegesEnum | string)[]
) => {
  let filteredCompanyRole = roles?.filter(
    (role: OfficialUserPrivilegesEnum | string) =>
      role.startsWith(REPRESENTATIVE_PREFIX)
  );
  if (filteredCompanyRole && filteredCompanyRole.length) {
    return filteredCompanyRole[0].substring(REPRESENTATIVE_PREFIX.length);
  }
};
