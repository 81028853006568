import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./Security.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTimeLeft, keycloakLogout, sendRefreshRequest } from "./TokenHelpers";
import moment from "moment";
import { useInterval } from "../Hook/interval";
import { FormattedMessage } from "react-intl";
import { RootState } from "../rootReducer";
import { userActions } from "./userActions";
import { useHistory } from "react-router-dom";
import { SecondaryFormattedButton } from "../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../Component/Button/PrimaryFormattedButton";
import { UNINTERCEPTED_API_WITH_CREDENTIALS } from "../api";

interface Props {
  setIsSessionExpired: (isSessionExpired: boolean) => void;
  setIsSessionExpiring: (isSessionExpiring: boolean) => void;
}

export const SessionExpiringModal = ({setIsSessionExpired, setIsSessionExpiring}: Props) => {
  const clientId = useSelector((state: RootState) => state.config.clientId);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [timeLeftInSession, setTimeLeftInSession] = useState(() => getTimeLeft(userInfo?.tokenExpiresAt));
  const [delay, setDelay] = useState<number | null>(1000);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useInterval(() => {
    if (userInfo) {
      setTimeLeftInSession(getTimeLeft(userInfo.tokenExpiresAt));
    }
  }, delay);

  const logUserOut = () => {
    setDelay(null);
    keycloakLogout(loading, "" + clientId, setLoading, dispatch, history);
    dispatch(userActions.logout());
    setIsModalOpen(false);
  };

  const refreshAccessCookie = () => {
    if (!userInfo || !clientId) {
      return;
    }
    sendRefreshRequest(clientId)
      .then(() => {
        const nonce = window.localStorage.getItem('nonce');
        UNINTERCEPTED_API_WITH_CREDENTIALS.get(`/public/auth/userinfo`, { headers: { 'X-Nonce': nonce } })
          .then(res => {
            dispatch(userActions.updateTokenExpiryTime(res.data.tokenExpiresAt));
            setIsModalOpen(false);
            setIsSessionExpiring(false);
          });
      });
  };

  if (timeLeftInSession && timeLeftInSession < 1000 && delay) {
    setDelay(null);
    setIsSessionExpired(true);
  }

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader>
        <FormattedMessage
          id="sessionExpiringModal.header"
          defaultMessage="Sessioon on aegumas"
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="sessionExpiringModal.body"
          defaultMessage="Sinu sessioon aegub {timeLeft} pärast."
          values={{timeLeft: moment(timeLeftInSession).format("mm:ss")}}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="logOut"
          onClick={logUserOut}
        />
        <PrimaryFormattedButton
          id="continue"
          onClick={refreshAccessCookie}
        />
      </ModalFooter>
    </Modal>
  );
};
