import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import {
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  FileObjectType,
  FileReference
} from "../../../../../api_client/medre_api";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { FileReferenceDto, fileToRef } from "../../../../Dto/File/FileReference";
import { FILENAME_SANITIZE_PATTERN, isFileNameUnique } from "../../../../Util/FileUtils";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { useDispatch } from "react-redux";
import { saveFiles } from "../../../../Component/FileDropzone/UploadFile";
import { API, getBaseUrl } from "../../../../api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  fileReferences: FileReference[];
  applicationId: string;
  refreshApplication: () => void;
}

export const AdditionalDocumentsModal = ({ isOpen, onClose, fileReferences, applicationId, refreshApplication }: Props) => {
  const [activeAttachmentType, setActiveAttachmentType] = useState<FileObjectType | null>(null);
  const [fileRefs, setFileRefs] = useState<FileReferenceDto[]>(fileReferences);
  const [filesToDelete, setFilesToDelete] = useState<FileReference[]>([])
  const [isFileReferencesUpdated, setIsFileReferencesUpdated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setFileRefs(fileReferences);
      setFilesToDelete([]);
    }
  }, [isOpen, fileReferences]);

  const validateAndSave = (files: File[], objectType: FileObjectType) => {
    const validFiles: File[] = [];
    const processedFileNames = new Set<string>();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const sanitizedFileName = file.name.replace(FILENAME_SANITIZE_PATTERN, "_");

      if ((fileReferences && !isFileNameUnique(sanitizedFileName, fileReferences)) || processedFileNames.has(sanitizedFileName)) {
        if (validFiles.length > 0) {
          addFiles(validFiles, objectType);
        }
        displayAlert("fileNameNotUnique", AlertType.Danger, dispatch);
        return;
      }
      validFiles.push(file);
      processedFileNames.add(sanitizedFileName);
    }

    if (validFiles.length > 0) {
      addFiles(validFiles, objectType);
    }
  };

  const addFiles = (files: File[], fileType: FileObjectType): void => {
    setFileRefs((prevState) => {
      return [...prevState, ...files.map((f) => fileToRef(f, fileType))];
    });
    setIsFileReferencesUpdated(true);
  };

  const handleFileDelete = (fileName: string, fileType: FileObjectType) => {
    const fileToDelete = findFile(fileName, fileType, fileRefs);
    if (!fileToDelete) return;

    updateFilesToDelete(fileToDelete);
    updateFileRefs(fileName, fileType);
    setIsFileReferencesUpdated(true);
  }

  const findFile = (fileName: string, fileType: FileObjectType, files: FileReference[]): FileReference | undefined => {
    return files.find(file => file.fileName === fileName && file.fileObjectType === fileType);
  }

  const updateFilesToDelete = (file: FileReference) => {
    setFilesToDelete((prevFiles: FileReference[]) => [...prevFiles, file]);
  }

  const updateFileRefs = (fileName: string, fileType: FileObjectType) => {
    setFileRefs((prevState: FileReference[]) =>
      prevState.filter(f => f.fileObjectType !== fileType || f.fileName !== fileName));
  }

  const addFilesToMinio = async (): Promise<void> => {
    for (let file of fileRefs) {
      if (!file.minioFileName) {
        await saveFiles([file.file!], file.fileObjectType!, applicationId);
      }
    }
  }

  const deleteFiles = async (): Promise<void> => {
    for (let file of filesToDelete) {
      if (file.minioFileName) {
        await officialAppDataAPI(undefined, getBaseUrl(), API)
          .deleteOfficialApplicationFile(applicationId, file.fileObjectType!, file.minioFileName!, {
            withCredentials: true
          })
      }
    }
  };

  const handleClose = () => {
    setIsFileReferencesUpdated(false);
    setActiveAttachmentType(null);
    onClose();
  }

  const saveChanges = () => {
    Promise.all([deleteFiles(), addFilesToMinio()])
      .then(() => {
        displayAlert("renewApplicationSuccess", AlertType.Success, dispatch);
        refreshApplication();
        handleClose();
      })
      .catch(() => {
        displayAlert("renewApplicationFailure", AlertType.Danger, dispatch);
        handleClose();
      })
  }

  const filterFiles = (type: FileObjectType) => {
    return fileRefs?.filter(fileReference => fileReference.fileObjectType === type)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader tag="h4">
        <FormattedMessage
          id="AdditionalDocumentsModal.header"
          defaultMessage="Täiendavate dokumentide haldamine"
        />
      </ModalHeader>
      <ModalBody>
        <AttachmentContainer
          type={FileObjectType.AdditionalDocument}
          isActive={activeAttachmentType === FileObjectType.AdditionalDocument}
          onClick={() => setActiveAttachmentType(FileObjectType.AdditionalDocument)}
          fileReferences={filterFiles(FileObjectType.AdditionalDocument)}
          maxFilesCount={50}
          saveFiles={(files) => validateAndSave(files, FileObjectType.AdditionalDocument)}
          handleFileDelete={(fileName) => handleFileDelete(fileName, FileObjectType.AdditionalDocument)}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={handleClose}
        />
        <PrimaryFormattedButton
          id="saveIt"
          disabled={!isFileReferencesUpdated}
          onClick={saveChanges}
        />
      </ModalFooter>
    </Modal>
  )
}