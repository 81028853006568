import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import {
  OfficialActivityLicenseStep,
  officialActivityLicenseSteps
} from "../../../Shared/ActivityLicense/ActivityLicenseStep";
import { BusinessData } from "../BusinessData";
import { ActivityLicenseBusinessAreaStep } from "../../../Shared/ActivityLicense/ActivityLicenseBusinessAreaStep";
import { NewActivityLicenseServices } from "../../../Shared/ActivityLicense/NewActivityLicenseServices";
import { ActivityLicenseDocumentsContainer } from "../../../Shared/ActivityLicense/Documents/ActivityLicenseDocumentsContainer";
import { ActivityLicenseArrivalChannel } from "../ActivityLicenseArrivalChannel";
import { ActivityLicenseOverview } from "../../../Shared/ActivityLicense/Overview/ActivityLicenseOverview";
import { FormattedMessage } from "react-intl";
import { Stepper } from "../../../../Component/ProgressBar/Stepper";
import { Card } from "reactstrap";
import { WizardProgressBar } from "../../../../Component/ProgressBar/WizardProgressBar";
import { ActivityLicenseFooter } from "../../../Shared/ActivityLicense/ActivityLicenseFooter";

export const OfficialActivityLocationContainer = () => {
  const currentStep = useSelector(
    (state: RootState) => state.activityLicenseApplication.currentStep || 0
  );
  const steps = officialActivityLicenseSteps;

  const urls = {
    routes: {
      portal: "/activity-location-application",
      official: "/activity-license-applications"
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case OfficialActivityLicenseStep.BUSINESS_DATA:
        return <BusinessData isContactInfoDisabled={true} />;
      case OfficialActivityLicenseStep.BUSINESS_AREA:
        return <ActivityLicenseBusinessAreaStep disabled={true} />;
      case OfficialActivityLicenseStep.SERVICES:
        return <NewActivityLicenseServices />;
      case OfficialActivityLicenseStep.DOCUMENTS:
        return <ActivityLicenseDocumentsContainer />;
      case OfficialActivityLicenseStep.APPLICATION_ARRIVAL_CHANNEL:
        return <ActivityLicenseArrivalChannel />;
      case OfficialActivityLicenseStep.OVERVIEW:
        return <ActivityLicenseOverview />;
      default:
        return <BusinessData isContactInfoDisabled={true} />;
    }
  };

  return (
    <div className="activity-license-container">
      <h1 className="ml-3">
        <FormattedMessage
          id="newActivityLocation.title"
          defaultMessage="Tegevuskoha lisamine"
        />
      </h1>

      <Stepper className={"m-3"} currentStep={currentStep} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <ActivityLicenseFooter
            currentStep={currentStep}
            steps={steps}
            urls={urls}
          />
        </div>
      </Card>
    </div>
  );
};
