import { RootAction } from "../rootReducer";
import { SET_APPLICATION } from "./applicationActions";

export const applicationReducer = (state = {}, action: RootAction) => {
  switch (action.type) {
    case SET_APPLICATION:
      return action.payload;
    default:
      return state;
  }
};
