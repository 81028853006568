import React from "react";

import "./QueryBox.scss";
import info_success from "../../../../../../assets/images/success.svg";

interface Props {
  value: string;
  className?: string;
}

export const QueryBoxSuccess = ({ value, className }: Props) => {
  return (
    <div className={`query-box query-box-success d-flex ${className}`}>
      <div className="info-container">
        <div className="d-inline-flex pt-3 pb-1 pl-3">
          <img src={info_success} alt="icon" className={"with-padding"}/>
          <div className="pl-1 query-box-header-text">
            Rahvastikuregistri andmed ühtivad
          </div>
        </div>
        <div className="query-box-text pt-0 pr-2 pb-2 pl-3">{value}</div>
      </div>
    </div>
  );
};
