import React from "react";
import oval from "../../../assets/images/oval.svg";
import fastAccess from "../../../assets/images/fast_access.svg";
import { FormattedMessage } from "react-intl";
import { Link } from "./Link";

export const QuickLinks = () => {
  return (
    <div className="text-white line-separator">
      <>
        <p className="footer-title">
          <img src={oval} alt="ovaal" />
          <img
            className="icon quick-links-icon"
            src={fastAccess}
            alt="kiirelt kätte ikoon"
          />
          <FormattedMessage
            id="quickLinks.header"
            defaultMessage="KIIRELT KÄTTE"
          />
        </p>
      </>
      <div className="content quick-links d-flex flex-column ">
        <div>
          <Link id="agency" url="https://www.terviseamet.ee/et" />
        </div>
        <div>
          <Link id="vaccinate" url="https://www.vaktsineeri.ee/" />
        </div>
        <div>
          <Link id="news" url="https://www.terviseamet.ee/et/uudised" />
        </div>
        <div>
          <Link
            id="userManual"
            url="https://www.terviseamet.ee/et/tervishoid/tervishoiutootajale/iseteeninduse-juhendid"
          />
        </div>
      </div>
    </div>
  );
};
