import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { publicSearchLicensesUrl } from "../Public/PublicSearch";
import { ApplicationDetailsInfoCard } from "../../../Component/Card/ApplicationDetailsInfoCard";
import DetailsSection from "../../../Component/DetailsSection/DetailsSection";
import { ActivityLicenseFM } from "../../../Messages/ActivityLicenseFM";
import { GeneralFM } from "../../../Messages/GeneralFM";
import { formatDate } from "../../Shared/Application/OverView/ApplicationOverViewUtil";
import { DecisionFM } from "../../../Messages/DecisionFM";
import "../../Shared/ActivityLicense/ActivityLicense.scss";
import { joinJSX } from "../../../Util/ArrayUtil";
import { BackButton } from "../../../Component/Button/BackButton";
import { ActivityLicenseDownloadButton } from "../../Shared/ActivityLicense/ActivityLicenseDownloadButton";
import "./PublicActivityLicense.scss";
import { LocationAccordion } from "../../Official/ActivityLicense/Details/LocationAccordion";
import {
  DetailedActivityLicense,
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI,
  InsuranceContract,
  VastutuskindlustusteTeenusApiFactory as insuranceContractAPI,
  InsuranceContractStatus,
  DetailedActivityLicenseStatusEnum
} from "../../../../api_client/medre_api";
import { useDetailedApplication } from "../../Official/ActivityLicense/Details/ActivityLicenseApplicationDetailsHook";
import { getBaseUrl, API } from "../../../api";
import { InsuranceDetailsCard } from "../../Official/ActivityLicense/InsuranceContract/InsuranceDetailsCard";

const backToSearchUrl = `/search/${publicSearchLicensesUrl}`;

export const PublicActivityLicense = () => {
  const { id } = useParams<{ id: string }>();

  const { details, refresh } = useDetailedApplication<DetailedActivityLicense>(
    publicActivityPermitAPI(undefined, getBaseUrl(), API).getActivityLicense,
    id
  );

  const [validInsurances, setValidInsurances] = useState<InsuranceContract[]>([]);

  const getValidInsurances = (): void => {
    if (!details || !details.company?.registryCode || details.status === DetailedActivityLicenseStatusEnum.Invalid) {
      return;
    }

    insuranceContractAPI(undefined, getBaseUrl(), API)
      .getPublicPolicyHolderInsuranceContracts(
        details.company.registryCode,
        InsuranceContractStatus.Valid
      ).then((r) => {
        setValidInsurances(r.data.content ?? []);
      });
  };

  useEffect(() => {
    if (details) {
      getValidInsurances();
    }
  }, [details]);

  return (
    <div className="activity-license-details-container application-container application-detail p-2 p-md-3">
      <BackButton />
      <h1 className="title">
        <FormattedMessage
          id="PublicActivityLicense.header"
          defaultMessage="Tegevusluba {activityLicenseNumber}"
          values={{ activityLicenseNumber: details?.number }}
        />
      </h1>

      <DetailsSection
        id="details"
        title={
          <>
            <span className="details-section-title">
              <FormattedMessage
                id="PublicActivityLicense.detailsTitle"
                defaultMessage="Tegevusloa detailid"
              />
            </span>
            <ActivityLicenseDownloadButton id={details?.id || ""} />
          </>
        }
      >
        <ApplicationDetailsInfoCard
          content={[
            {
              key: <ActivityLicenseFM id="licenseNumber" />,
              value: details?.number
            },
            {
              key: <ActivityLicenseFM id="companyName" />,
              value: details?.company?.name
            },
            {
              key: <GeneralFM id="valid" />,
              value: (
                <>
                  {formatDate(details?.createdAt)},&nbsp;
                  {details?.validUntil ? (
                    formatDate(details?.validUntil)
                  ) : (
                    <span className="text-lowercase">
                      <GeneralFM id="termless" />
                    </span>
                  )}
                </>
              )
            },
            {
              key: <DecisionFM id="decision" />,
              value: joinJSX(
                (details?.decisions ?? []).map<[string, JSX.Element]>(
                  (decision) => [
                    decision.id as string,
                    <DecisionFM
                      key={decision.id}
                      id="signedNumberDate"
                      values={{
                        number: decision.decisionNumber,
                        date: formatDate(decision.signedAt)
                      }}
                    />
                  ]
                )
              )
            },
            {
              key: <ActivityLicenseFM id="hospitalType" />,
              value: details?.businessArea?.isHospital
                ? details.businessArea.name
                : "-"
            }
          ]}
        />
      </DetailsSection>

      <InsuranceDetailsCard id="insurance" insurances={validInsurances} company={details?.company} />

      <DetailsSection
        id="services"
        title={
          <FormattedMessage
            id="PublicActivityLicense.servicesTitle"
            defaultMessage="Asukoht ja teenused"
          />
        }
      >
        <div className="ml-n4 mr-n4">
          <LocationAccordion
            activityLocations={details?.locations || []}
            isHospital={details?.businessArea?.isHospital ?? false}
            isEditable={false}
            refresh={refresh}
            activityLicense={details}
            locationLoadingEndpoint={
              publicActivityPermitAPI(undefined, getBaseUrl(), API)
                .getActivityLicenseLocation
            }
            serviceLocationEndpoint={
              publicActivityPermitAPI(undefined, getBaseUrl(), API).getService
            }
          />
        </div>
      </DetailsSection>
    </div>
  );
};
