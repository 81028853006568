import {
  Service,
  ServiceStatusEnum,
  ActivityLicense,
  DetailedActivityLicense,
  ActivityLocation,
  ActivityLicenseService,
  ActivityLicenseApplication,
  ActivityLicenseApplicationStatus,
  ActivityLicenseRowHeaderStatusEnum,
  ActivityLicenseServiceRoomDTO,
  ServiceBrigadeBrigadeTypeEnum,
} from "../../../api_client/medre_api";
import { dateToString } from "../../Util/DateUtils";
import { PHYSIOTHERAPIST_ID, PSYCHOLOGIST_ID, SPEECH_THERAPIST_ID } from "../../Constants";

export const convertServiceToActivityLicenseService = (
  service: Service,
  isRequired: boolean
) => {
  const requiredByBusinessArea = isRequired;
  return { requiredByBusinessArea, service };
};

export const createActivityLicenseDTO = (
  application: ActivityLicenseApplication
): ActivityLicenseApplication => {
  const { id, representatives, status, ...companyDTO } =
  application.company || {};

  return {
    id: application.id,
    currentStep: application.currentStep,
    company: companyDTO,
    submittingPersonFirstName: application.submittingPersonFirstName,
    submittingPersonLastName: application.submittingPersonLastName,
    submittingPersonIdCode: application.submittingPersonIdCode,
    submittingPersonRole: application.submittingPersonRole,
    businessArea: application.businessArea,
    locations: application.locations?.map(createLocationDTO),
    activityLicense: application.activityLicense,
    proceedingType: application.proceedingType,
    arrivalTime: dateToString(new Date()),
    channelType: application.channelType
  };
};

export const createLocationDTO = (location: ActivityLocation): ActivityLocation => ({
  id: location.id,
  fullAddress: location.fullAddress,
  ehakmk: location.ehakmk,
  ehakov: location.ehakov,
  ehak: location.ehak,
  adsAdrID: location.adsAdrID,
  zipCode: location.zipCode,
  adrCode: location.adrCode,
  services: location.services?.map(createServiceDTO)
});

export const createServiceDTO = (
  service: ActivityLicenseService
): ActivityLicenseService => ({
  serviceId: service.service?.id,
  employeeIds: service.employees?.map((e) => e.id!),
  beds: service.beds,
  ambulanceBrigades: service.ambulanceBrigades,
  numberOfGpLists: service.numberOfGpLists,
  mobileActivityLocationRegistryNumber:
    service.mobileActivityLocationRegistryNumber?.trim(),
  roomDTOs: service.rooms?.map(
    (room) =>
      ({
        roomId: room?.room?.id,
        number: room.number
      } as ActivityLicenseServiceRoomDTO)
  ),
  brigades: service.brigades
});

export const prettifyBrigadeType = (type?: ServiceBrigadeBrigadeTypeEnum) => {
  switch (type) {
    case ServiceBrigadeBrigadeTypeEnum.NursingBrigade:
      return "numberOfNursingBrigades";
    case ServiceBrigadeBrigadeTypeEnum.MedicalBrigade:
      return "numberOfMedicalBrigades";
    case ServiceBrigadeBrigadeTypeEnum.ReanimobileBrigade:
      return "numberOfReanimobileBrigades";
    case ServiceBrigadeBrigadeTypeEnum.FirstAidBrigade:
      return "numberOfFirstAidBrigades";
    default:
      return "numberOfAmbulanceBrigades";
  }
};

export const isProceedingStatus = (status: ActivityLicenseApplicationStatus) => {
  const proceedingStatuses: ActivityLicenseApplicationStatus[] = [
    ActivityLicenseApplicationStatus.InProceeding,
    ActivityLicenseApplicationStatus.OnSiteInspectionPending,
    ActivityLicenseApplicationStatus.ProtocolInPreparation,
    ActivityLicenseApplicationStatus.ReadyForDecision,
    ActivityLicenseApplicationStatus.ProceedingReopened,
    ActivityLicenseApplicationStatus.UploadAcceptedDecision,
    ActivityLicenseApplicationStatus.UploadDeclinedDecision,
    ActivityLicenseApplicationStatus.SignAcceptedDecision,
    ActivityLicenseApplicationStatus.SignDeclinedDecision,
    ActivityLicenseApplicationStatus.SignReopened
  ];
  return proceedingStatuses.includes(status);
};

const locationAddressFields = [
  "fullAddress",
  "zipCode",
  "ehakmk",
  "ehakov",
  "ehak",
  "adsAdrID",
  "adrCode",
  "apartmentNumber"
];
export type LocationAddressField = typeof locationAddressFields[number];

export const isLocationAddressField = (value: string): value is LocationAddressField =>
  locationAddressFields.includes(value);

export const containsServiceId = (services: ActivityLicenseService[], id: string) =>
  services.map((rs) => rs?.id).includes(id);

export const canAddLocation = (license: ActivityLicense | DetailedActivityLicense) =>
  license?.businessArea?.isHospital &&
  license.status === ActivityLicenseRowHeaderStatusEnum.Valid &&
  license.businessArea.status === ServiceStatusEnum.Inactive;

export const isSpecialistBusinessAreaId = (businessAreaId?: string): boolean =>
  businessAreaId !== PHYSIOTHERAPIST_ID && businessAreaId !== PSYCHOLOGIST_ID && businessAreaId !== SPEECH_THERAPIST_ID;

const getMobileLocationDescription = (businessAreaId?: string): string => {
  return !businessAreaId || isSpecialistBusinessAreaId(businessAreaId) ?
    "Mobiilne asukoht (Eesti Vabariik)" :
    "Teenuse osutamine patsiendi igapäevases keskkonnas";
};

export const getLocationAddress = (location: ActivityLocation, businessAreaId?: string): string => {
  return location.adsAdrID === "mobile"
    ? getMobileLocationDescription(businessAreaId)
    : location.fullAddress || "";
};

export interface ActivityLicenseServiceDto extends ActivityLicense {
  requiredByBusinessArea?: boolean;
}
