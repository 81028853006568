import { RootAction } from "../rootReducer";
import { ContactInfo } from "../../api_client/medre_api";
import { SET_CONTACT_INFO } from "./contactInfoFromRegistryActions";

export const contactInfoFromRrReducer = (state: ContactInfo = {} as ContactInfo  , action: RootAction) => {
  switch (action.type) {
    case SET_CONTACT_INFO:{
      return action.payload;
    }

    default:
      return state;
  }
};
