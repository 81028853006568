import React, { FC } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  competenceId: string;
  deleting: boolean;
  onDelete: (id: string, callback?: () => void) => void;
  onClose: () => void;
}
const ApplicantCompetenceDeletionModal: FC<Props> = ({
  competenceId,
  deleting,
  onDelete,
  onClose,
}) => {
  const handleDelete = () => {
    onDelete(competenceId, onClose);
  };

  return (
    <Modal isOpen={true} size="md">
      <ModalBody>Kas olete kindel, et soovite pädevuse kustutada?</ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="backToPerson"
          disabled={deleting}
          onClick={onClose}
        />
        <PrimaryFormattedButton
          id="deleteCompetence"
          disabled={deleting}
          onClick={handleDelete}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ApplicantCompetenceDeletionModal;
