import React from "react";

import {
  WorkingDay,
  GeneralPractitionerListSubstituteDoctorRoleEnum as GpListSubstituteDoctorRoleEnum
} from "../../api_client/medre_api";
import { Values } from "../Component/Checkbox/CheckboxGroup";
import { Option } from "../Component/Select/SingleSelect";
import { EmployeeFM } from "../Messages/EmployeeFM";

export const getUpdatedWorkingDays = (
  selectedWorkingDays: Values,
  existingWorkingDays: Array<WorkingDay>
) => {
  const updatedWorkingDays: Array<WorkingDay> = [];

  selectedWorkingDays.forEach((selectedWorkingDay) => {
    const existingWorkingDay = existingWorkingDays.find(
      (wDay: WorkingDay) => wDay.workingDay === selectedWorkingDay
    );
    if (existingWorkingDay) {
      updatedWorkingDays.push(existingWorkingDay);
    } else {
      updatedWorkingDays.push({
        workingDay: selectedWorkingDay,
        workingFrom: "",
        workingTo: ""
      } as WorkingDay);
    }
  });

  return updatedWorkingDays;
};

export const getWorkingDayLabel = (
  wDayOptions: Array<Option>,
  workingDay: string
) => wDayOptions.find((option) => option.value === workingDay)?.label;

export const getEmployeeRoleOptions = () => [
  {
    label: <EmployeeFM id="substituteDoctor" />,
    value: GpListSubstituteDoctorRoleEnum.SubstituteDoctor
  },
  {
    label: <EmployeeFM id="familyNurse" />,
    value: GpListSubstituteDoctorRoleEnum.FamilyNurse
  },
  {
    label: <EmployeeFM id="doctor" />,
    value: GpListSubstituteDoctorRoleEnum.Doctor
  },
  {
    label: <EmployeeFM id="generalPractitioner" />,
    value: GpListSubstituteDoctorRoleEnum.GeneralPractitioner
  }
];
