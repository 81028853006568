import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./Icon.scss";

export const PlusIcon = () => {
  return (
    <span className="icon-container">
      <FontAwesomeIcon icon={faPlus}/>
    </span>
  );
};
