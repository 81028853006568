import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { SubmittedApplication } from "./SubmittedApplication";

export const SubmittedActivityLicense = () => {
  const {
    applicationNumber,
    stateFee,
    proceedingType
  } = useSelector((state: RootState) => state.activityLicenseApplication);

  return (
  <SubmittedApplication 
    applicationNumber={applicationNumber} 
    stateFee={stateFee}
                               
    proceedingType={proceedingType}
  />);
};
