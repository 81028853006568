import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { SubmittedActions } from "../Application/Details/Proceeding/SubmittedActions";
import { AcceptanceActions } from "../Application/Details/Acceptance/AcceptanceActions";
import { SignActions } from "../Application/Details/Sign/SignActions";
import { displayAlert } from "../../../../Util/AlertUtil";
import { DecisionActions } from "../Application/Details/Decision/DecisionActions";
import {
  FileObjectType,
  DetailedActivityLocationInvalidation,
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum,
  OfficialUser,
  AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFactory as officialPlacesActivityAPI,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  DecisionType,
  ActivityLicenseSuspensionStatus,
} from "../../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { ActionButtonClasses } from "../../../../Component/Button/Button";
import {
  ApplicationDeleteConfirmationModal
} from "../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { OfficialForwardingModal } from "../Application/Details/Proceeding/OfficialForwardingModal";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { ProceedingPendingActions } from "../Application/Details/Proceeding/ProceedingPendingActions";
import { SubmittedByOfficialActions } from "../Application/Details/Proceeding/SubmittedByOfficialActions";
import { DeleteApplicationUtil } from "../ActionUtils/DeleteApplicationUtil";

interface Props {
  details?: DetailedActivityLocationInvalidation;
  setDetails: (
    locationInvalidation: DetailedActivityLocationInvalidation
  ) => void;
  refresh: () => void;
  setIsLoading: (value: boolean) => void;
}

export const ActivityLocationInvalidationDetailsActions = ({
  details,
  setDetails,
  refresh,
  setIsLoading
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);

  const handleForwardToOfficial = (official: OfficialUser) => {
    setIsLoading(true);
    officialPlacesActivityAPI(undefined, getBaseUrl(), API)
      .forwardToOfficial(details?.id!, official, {
        withCredentials: true
      })
      .then((res) => {
        setDetails(res.data);
        displayAlert(
          "applicationForwardingSuccess",
          AlertType.Success,
          dispatch
        );
      })
      .catch(() =>
        displayAlert("applicationForwardingFailure", AlertType.Danger, dispatch)
      )
      .finally(() => setIsLoading(false));
  };

  const initiateProceeding = () => {
    setIsLoading(true);
    officialPlacesActivityAPI(undefined, getBaseUrl(), API)
      .initiateProceeding1(details?.id!, {
        withCredentials: true
      })
      .then((res) => {
        setDetails(res.data);
        displayAlert("initiateProceedingSuccess", AlertType.Success, dispatch);
      })
      .catch((e) => {
        displayAlert("initiateProceedingFailure", AlertType.Danger, dispatch);
      })
      .finally(() => setIsLoading(false));
  };

  const changeStatusTo = (
    newStatus:
    | ActivityLicenseApplicationStatus
    | ActivityLicenseSuspensionStatus
  ) => {
    setIsLoading(true);
    officialPlacesActivityAPI(undefined, getBaseUrl(), API)
      .changeInvalidationStatus(
        details?.id!,
        newStatus as ActivityLicenseSuspensionStatus,
        {
          withCredentials: true
        }
      )
      .then((res) => {
        setDetails(res.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
      })
      .catch(() =>
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch)
      )
      .finally(() => setIsLoading(false));
  };

  const deleteApplication = (applicationId: string) => {
    DeleteApplicationUtil(
      applicationId,
      dispatch,
      history,
      details?.proceedingType!,
      "applicationDraftDeleteSuccess",
      "applicationDraftDeleteFailure");
  };

  const handleAppDelete = () => {
    deleteApplication(details?.id!);
    setIsDeleteModalOpen(false);
  };

  const renderForwardToOfficialButton = () => {
    return <ProtectedComponent
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}>
      <SecondaryFormattedButton
        id={"forwardToOfficial"}
        className={ActionButtonClasses}
        onClick={() => setIsOfficialForwardingModalOpen(true)}
      />
    </ProtectedComponent>;
  };

  const renderContent = () => {
    if (!details) {
      return;
    }
    switch (details?.status) {
      case ActivityLicenseApplicationStatus.Submitted:
        return (
          <SubmittedActions
            handleForwardToOfficial={handleForwardToOfficial}
            canFinish={false}
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            deleteApplication={deleteApplication}
          />
        );
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return (
          <SubmittedByOfficialActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
          />
        );
      case ActivityLicenseApplicationStatus.ProceedingPending:
        return (
          <ProceedingPendingActions
            applicationId={details.id}
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            deleteApplication={deleteApplication}
            handleForwardToOfficial={handleForwardToOfficial}
            initiateProceeding={initiateProceeding}
            isDeleteCheckNeeded={false}
          />
        );
      case ActivityLicenseApplicationStatus.InProceeding:
        return (
          <>
            <AcceptanceActions
              applicationNumber={details.applicationNumber}
              changeStatusTo={changeStatusTo}
              newAcceptanceStatus={
                ActivityLicenseApplicationStatus.UploadAcceptedDecision
              }
              canDecline={false}
              applicationId={details.id}
              deleteApplication={deleteApplication}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.SignReopened:
        return (
          <>
            <AcceptanceActions
              applicationNumber={details.applicationNumber}
              changeStatusTo={changeStatusTo}
              newAcceptanceStatus={
                ActivityLicenseApplicationStatus.UploadAcceptedDecision
              }
              canDecline={false}
              applicationId={details.id}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
        return (
          <>
            <DecisionActions
              fileObjectType={FileObjectType.ActivityLicenseDecision}
              decisionUploadEndpoint={
                decisionServiceAPI(undefined, getBaseUrl(), API)
                  .assignDecisionToSigner
              }
              updateApplication={refresh}
              decision={{
                id: details.id!,
                type: DecisionType.ActivityLocationInvalidation
              }}
            />
            {renderForwardToOfficialButton()}
          </>
        );
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
        return (
          <SignActions
            signButtonId="signApplicationAcceptanceDecision"
            refresh={refresh}
            decisionId={details?.decisions?.find((d) => !d.signed)?.id!}
            newStatus={ActivityLicenseApplicationStatus.SignReopened}
            changeStatusTo={changeStatusTo}
            canRevert
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="mt-1 mb-4">
      {renderContent()}
      <ApplicationDeleteConfirmationModal
        applicationNumber={details?.applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </div>
  );
};
