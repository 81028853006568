import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "businessName"
  | "businessAddress"
  | "registryCode"
  | "juridicalAddress"
  | "locationAddress"
  | "status"
  | "email"
  | "phone"
  | "role"
  | "instituteName";

export const BusinessInfoFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    businessName: {
      id: "businessInfoFM.businessName",
      defaultMessage: "Nimi"
    },
    businessAddress: {
      id: "businessInfoFM.businessAddress",
      defaultMessage: "Aadress"
    },
    registryCode: {
      id: "businessInfoFM.registryCode",
      defaultMessage: "Registrikood"
    },
    juridicalAddress: {
      id: "businessInfoFM.juridicalAddress",
      defaultMessage: "Juriidiline aadress"
    },
    locationAddress: {
      id: "businessInfoFM.locationAddress",
      defaultMessage: "Tegevuskoha aadress"
    },
    status: {
      id: "businessInfoFM.status",
      defaultMessage: "Ettevõtte staatus"
    },
    email: {
      id: "businessInfoFM.email",
      defaultMessage: "Ettevõtte e-post"
    },
    phone: {
      id: "businessInfoFM.phone",
      defaultMessage: "Ettevõtte telefon"
    },
    role: {
      id: "businessInfoFM.role",
      defaultMessage: "Roll"
    },
    instituteName: {
      id: "businessInfoFM.instituteName",
      defaultMessage: "Asutuse nimi"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
