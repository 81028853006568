import { SET_PERSON, UPDATE_PERSON } from "./personActions";
import { RootAction } from "../rootReducer";
import { Person } from "../../api_client/medre_api";

export const personReducer = (state: Person = {}, action: RootAction) => {
  let newState;
  switch (action.type) {
    case SET_PERSON:
      return action.payload;
    case UPDATE_PERSON:
      newState = {
        ...state,
        [action.payload.fieldKey]: action.payload.fieldValue
      };
      return newState;
    default:
      return state;
  }
};
