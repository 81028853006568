import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  AUTHENTICATION_FAILURE_COUNT,
  AUTHORIZATION_FAILURE_COUNT,
  SCOPE,
  TABLET_MAX_WIDTH
} from "../Constants";
import "./Security.scss";
import { RootState } from "../rootReducer";
import { userActions } from "./userActions";
import { PrimaryFormattedButton } from "../Component/Button/PrimaryFormattedButton";
import { useWindowWidthSize } from "../Hook/useWindowsSize";
import { Loader } from "../Component/Loader/Loader";
import {
  getTokenExchangeUrl,
  UNINTERCEPTED_API_WITH_CREDENTIALS
} from "../api";

interface Props {
  setIsLoading: (value: boolean) => void;
}

export const LoginButton = ({ setIsLoading }: Props) => {
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isAuthenticationFailure, setIsAuthenticationFailure] = useState(false);
  const [isAuthorizationFailure, setIsAuthorizationFailure] = useState(false);

  const authConfig = useSelector((state: RootState) => state.config.authConfig);
  const clientId = useSelector((state: RootState) => state.config.clientId);

  useEffect(() => {
    let authenticationFailureCount = Number(
      localStorage.getItem(AUTHENTICATION_FAILURE_COUNT)
    );
    let authorizationFailureCount = Number(
      localStorage.getItem(AUTHORIZATION_FAILURE_COUNT)
    );
    if (authenticationFailureCount > 0) {
      setIsAuthenticationFailure(true);
      localStorage.removeItem(AUTHENTICATION_FAILURE_COUNT);
    }
    if (authorizationFailureCount > 0) {
      setIsAuthorizationFailure(true);
      localStorage.removeItem(AUTHORIZATION_FAILURE_COUNT);
    }
  }, [setIsAuthenticationFailure, setIsAuthorizationFailure]);

  const keycloakLogin = useCallback(async () => {
    if (!authConfig) {
      return;
    }
    const pkceChallenge = (await import('pkce-challenge')).default;
    const {code_challenge, code_verifier} = await pkceChallenge(64)

    const state = generateRandomString(16)
    const nonce = generateRandomString(16)
    sessionStorage.setItem('oauth_state', state);
    window.localStorage.setItem('nonce', nonce);

    setIsLoading(true);
    const redirectBase = window.location.origin;
    const tokenExchangeUrl = getTokenExchangeUrl();
    const redirectUri = encodeURIComponent(
      `${tokenExchangeUrl}?client_id=${clientId}&auth_success_url=${redirectBase}&code_verifier=${code_verifier}`
    );
    const requestSuffix = `response_type=code&login=true&scope=${SCOPE}`;
    const queryString = `?client_id=${clientId}&state=${state}&nonce=${nonce}&code_challenge=${code_challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}&${requestSuffix}`;
    window.location.href = `${authConfig.authorizationEndpoint}${queryString}`;
  }, [authConfig, setIsLoading, clientId]);

  const generateRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  useLayoutEffect(() => {
    if (search.length && authConfig) {
      const [redirectUri, params] = window.location.href.split("?");
      (async () => {
        if (params.includes("token_success")) {
          setLoading(true);
          const storedState = sessionStorage.getItem('oauth_state');
          const receivedState = new URLSearchParams(window.location.search).get("state");
          if (receivedState !== storedState) {
            const queryString = `?redirect_uri=${redirectUri}`;
            dispatch(userActions.incrementAuthenticationFailure());
            window.location.href = `${authConfig.logoutEndpoint}${queryString}`;
          }

          try {
            const nonce = window.localStorage.getItem('nonce');
            const userInfo = (
              await UNINTERCEPTED_API_WITH_CREDENTIALS.get(
              `/public/auth/userinfo`,
            { headers: { 'X-Nonce': nonce } }
              )
            ).data;
            dispatch(userActions.login({ userInfo }));
            history.push("/role-selection");
          } catch (error) {
            setLoading(false);
            if (
              (error as Record<string, any>)?.response?.data &&
              (error as Record<string, any>)?.response?.data.message ===
                "401 Unauthorized"
            ) {
              dispatch(userActions.incrementAuthorizationFailure());
            } else {
              dispatch(userActions.incrementAuthenticationFailure());
            }
            const queryString = `?redirect_uri=${redirectUri}`;
            window.location.href = `${authConfig.logoutEndpoint}${queryString}`;
          } finally {
            setIsLoading(false);
          }
        } else if (params === "login_error") {
          const redirectUri = window.location.href.split("?")[0];
          const queryString = `?redirect_uri=${redirectUri}`;
          dispatch(userActions.incrementAuthenticationFailure());
          window.location.href = `${authConfig.logoutEndpoint}${queryString}`;
        }
      })();
    }
  }, [
    search,
    authConfig,
    setLoading,
    setIsLoading,
    clientId,
    dispatch,
    history
  ]);

  const popoverLocation =
    useWindowWidthSize() > TABLET_MAX_WIDTH ? "right" : "top";

  const getButtonId = () => {
    return isPortal ? "loginPerson" : "loginOfficial";
  };

  return (
    <>
      <PrimaryFormattedButton
        id={getButtonId()}
        className="login-button"
        onClick={keycloakLogin}
      />
      {loading && <Loader backdrop={true} />}
      <Popover
        placement={popoverLocation}
        isOpen={isAuthenticationFailure}
        target={getButtonId()}
      >
        <PopoverHeader>Viga autentimisega</PopoverHeader>
        <PopoverBody>
          Esines viga autentimisega. Palun proovige mõne aja pärast uuesti.
        </PopoverBody>
      </Popover>
      <Popover
        placement={popoverLocation}
        isOpen={isAuthorizationFailure}
        target={getButtonId()}
      >
        <PopoverHeader>Viga autoriseerimisega</PopoverHeader>
        <PopoverBody>
          Kasutajal ei ole piisavalt õigusi, et siseneda portaali.
        </PopoverBody>
      </Popover>
    </>
  );
};
