import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import "./Application.scss";
import doctor from "../../../../assets/images/doctor.svg";
import speciality from "../../../../assets/images/speciality.svg";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { RootState } from "../../../rootReducer";
import { ApplicationCertificateContext } from "../ApplicationCertificate/ApplicationCertificateContext";

interface Props {
  pageTitle: MessageDescriptor;
  specialityDescription: MessageDescriptor;
  occupationDescription: MessageDescriptor;
  certificateDescription?: MessageDescriptor;
  activityLicenseDescription?: MessageDescriptor;
  handleNewSpecialityApplication: () => void;
  handleNewOccupationApplication: () => void;
  handleNewApplicationCertificate?: () => void;
  handleNewActivityLicense?: () => void;
}

export const ApplicationTypeSelection = ({
  pageTitle,
  occupationDescription,
  specialityDescription,
  certificateDescription,
  activityLicenseDescription,
  handleNewOccupationApplication,
  handleNewSpecialityApplication,
  handleNewApplicationCertificate,
  handleNewActivityLicense
}: Props): React.JSX.Element => {
  const { isPortal } = useSelector((state: RootState) => state.config);
  const { allowedHealthcareOccupationCodes } = useContext(
    ApplicationCertificateContext
  );

  const hasHealthcareProfessionalOccupation = (): boolean => {
    return allowedHealthcareOccupationCodes.length > 0;
  };

  return (
    <div className="application-container">
      <h1 className="ml-3">
        <FormattedMessage {...pageTitle} />
      </h1>
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <Row className="m-2 p-3 justify-content-center">
          <Col
            className="mb-3 application-type-block"
            xl={3}
            lg={4}
            md={5}
            xs={12}
          >
            <div>
              <img src={doctor} alt="doktor" />
              <h3 className="mt-2 fw-normal">
                <FormattedMessage
                  id="proceedingTypeSelection.occupationTitle"
                  defaultMessage="Kutse taotlus"
                />
              </h3>
              <p className="application-type-selection">
                <FormattedMessage {...occupationDescription} />
              </p>
            </div>
            <PrimaryFormattedButton
              id="newOccupation"
              className="proceeding-primary-button"
              onClick={handleNewOccupationApplication}
            />
          </Col>
          <Col
            className="mb-3 application-type-block"
            xl={3}
            lg={4}
            md={5}
            xs={12}
          >
            <div>
              <img src={speciality} alt="eriala" />
              <h3 className="mt-2 fw-normal">
                <FormattedMessage
                  id="proceedingTypeSelection.specialityTitle"
                  defaultMessage="Eriala taotlus"
                />
              </h3>
              <p className="application-type-selection">
                <FormattedMessage {...specialityDescription} />
              </p>
            </div>
            <PrimaryFormattedButton
              id="newSpeciality"
              className="proceeding-primary-button"
              onClick={handleNewSpecialityApplication}
            />
          </Col>
          {isPortal && (
            <Col
              className="mb-3 application-type-block"
              xl={3}
              lg={4}
              md={5}
              xs={12}
            >
              <div>
                <img src={speciality} alt="eriala" />
                <h3 className="mt-2 fw-normal">
                  <FormattedMessage
                    id="proceedingTypeSelection.certificateTitle"
                    defaultMessage="Kutsekvalifikatsiooni tunnustamise tõendi taotlus"
                  />
                </h3>
                <p className="application-type-selection">
                  <FormattedMessage {...certificateDescription} />
                </p>
              </div>
              <PrimaryFormattedButton
                id="newApplicationCertificate"
                className="proceeding-primary-button"
                onClick={handleNewApplicationCertificate}
                disabled={!hasHealthcareProfessionalOccupation()}
              />
            </Col>
          )}
          {!isPortal && (
            <Col
              className="mb-3 application-type-block"
              xl={3}
              lg={4}
              md={5}
              xs={12}
            >
              <div>
                <img src={speciality} alt="tegevusluba" />
                <h3 className="mt-2 fw-normal">
                  <FormattedMessage
                    id="proceedingTypeSelection.activityLicenseTitle"
                    defaultMessage="Tegevusloa taotlus"
                  />
                </h3>
                <p className="application-type-selection">
                  <FormattedMessage {...activityLicenseDescription} />
                </p>
              </div>
              <PrimaryFormattedButton
                id="newActivityLicense"
                className="proceeding-primary-button"
                onClick={handleNewActivityLicense}
              />
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};
