import React, { SyntheticEvent, useCallback, useState } from "react";
import { Col, Row } from "reactstrap";
import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import { LinkButton } from "../../../Component/Button/LinkButton";
import { FormattedButtonProps } from "../../../Component/Button/FormattedButton";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { ApplicationSaveConfirmationModal } from "../../Shared/Application/Footer/ApplicationSaveConfirmationModal";
import { FormattedMessage } from "react-intl";

interface Props {
  backwardButtonProps: FormattedButtonProps;
  draftButtonProps: FormattedButtonProps;
  forwardButtonProps: FormattedButtonProps;
}

export const ApplicationCertificateFooter = ({
  backwardButtonProps,
  draftButtonProps,
  forwardButtonProps,
}: Props) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const handleClickSaveDraft = useCallback((event?: SyntheticEvent) => {
    event?.preventDefault();
    setConfirmationModalIsOpen(true);
  }, [setConfirmationModalIsOpen]);

  const handleCancelConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, [setConfirmationModalIsOpen]);

  const handleConfirmConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(false);
    if (draftButtonProps.onClick) {
      draftButtonProps.onClick();
    }
  }, [setConfirmationModalIsOpen, draftButtonProps]);

  const getModalMessage = () => {
    return (
      <FormattedMessage
        id="applicationSaveConfirmationModal.bodyPortal"
        defaultMessage={`Taotluse seis salvestatakse ja saate hiljem jätkata täitmist. Salvestatud taotluse leiate "Minu taotlused" alt.`}
      />
    );
  };

  return (
    <>
      <Row className="d-flex flex-column flex-md-row justify-content-between">
        <Col xs={12} md="auto" className="order-1 pt-2 pb-2 text-center">
          <SecondaryFormattedButton type="button" {...backwardButtonProps} />
        </Col>
        <Col xs={12} md="auto" className="ml-auto order-0 order-md-1 pt-2 pb-2 text-center">
          <LinkButton
            type="button"
            {...draftButtonProps}
            onClick={handleClickSaveDraft}
          />
        </Col>
        <Col xs={12} md="auto" className="order-1 pt-2 pb-2 text-center">
          <PrimaryFormattedButton
            type="submit"
            {...forwardButtonProps}
          />
        </Col>
      </Row>

      <ApplicationSaveConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={handleCancelConfirmationModal}
        onSave={handleConfirmConfirmationModal}
        modalMessage={getModalMessage()}
      />
    </>
  );
};
