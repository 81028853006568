import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useGeneralPractitionerList from "../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { GPListDetailedHeader } from "../../Shared/GeneralPractitioners/Detailed/GPListDetailedHeader";
import { GPListContacts } from "../../Shared/GeneralPractitioners/Detailed/GPListContacts";
import { GPListEmployees } from "../../Shared/GeneralPractitioners/Detailed/GPListEmployees";
import { GPListAppointmentTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListAppointmentTimes";
import { GPListConsultationTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListConsultationTimes";
import { GPListSubstituteDoctors } from "../../Shared/GeneralPractitioners/Detailed/GPListSubstituteDoctors";
import { GPListSubstitutePeriod } from "../../Shared/GeneralPractitioners/Detailed/GPListSubstitutePeriod";
import { QuickLinks } from "../../../Component/QuickLinks/QuickLinks";
import {
  usePortalGeneralPractitionersDetailsSectionsList
} from "../../Shared/GeneralPractitioners/GeneralPractitionersDetailedNavigationList";
import { Loader } from "../../../Component/Loader/Loader";
import { generalPractitionerListActions } from "../../../GeneralPractitionerLists/generalPractitionerListActions";
import {
  useGeneralPractitionerListDeletionsSelector
} from "../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { API, getBaseUrl } from "../../../api";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import { GPListBulkDeletionState } from "../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { PerearstiNimistudTeenusApiFactory as doctorDirectoryAPI } from "../../../../api_client/medre_api";
import { ConfirmationStickyFooter } from "../../Footer/ConfirmationStickyFooter";

export const PortalGPListDetails = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { list, fetchGPList } = useGeneralPractitionerList();
  const quickLinks = usePortalGeneralPractitionersDetailsSectionsList();

  useEffect(() => {
    if (!id) {
      return;
    }
    window.scrollTo(0, 0);
    fetchGPList(id, true);

    return () => {
      dispatch(generalPractitionerListActions.clearList());
      dispatch(generalPractitionerListActions.clearGPListBulkDeletions());
    };
  }, [id, fetchGPList, dispatch]);

  const deletions = useGeneralPractitionerListDeletionsSelector();
  const hasDeletions = useMemo(() => {
    const keys = Object.keys(deletions) as Array<keyof typeof deletions>;
    return keys.some((key) => Boolean(deletions[key]?.length));
  }, [deletions]);

  const handleDiscardDeletions = useCallback(() => {
    dispatch(generalPractitionerListActions.clearGPListBulkDeletions());
  }, [dispatch]);

  const handleConfirmDeletions = useCallback(async () => {
    try {
      await doctorDirectoryAPI(undefined, getBaseUrl(), API).deleteGPListFields(
        id,
        deletions as GPListBulkDeletionState,
        {
          withCredentials: true
        }
      );
      await fetchGPList(id, true);
      dispatch(generalPractitionerListActions.clearGPListBulkDeletions());
    } catch (e) {
      const alertMessage = <AlertMessage id="requestFailed" />;
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    }
  }, [id, deletions, fetchGPList, dispatch]);

  if (!list?.id) {
    return <Loader />;
  }

  return (
    <div className="gp-lists-container application-container application-detail p-2 p-md-3">
      <GPListDetailedHeader />
      <QuickLinks links={quickLinks} />
      <GPListContacts />
      <GPListEmployees />
      <GPListAppointmentTimes />
      <GPListConsultationTimes />
      <GPListSubstituteDoctors />
      <GPListSubstitutePeriod />
      {hasDeletions && (
        <ConfirmationStickyFooter
          handleDiscard={handleDiscardDeletions}
          handleConfirm={handleConfirmDeletions}
          saveMessageId="confirmGPListDeletion"
        />
      )}
    </div>
  );
};
