import { UNINTERCEPTED_API_WITH_CREDENTIALS } from "../api";
import moment from "moment";
import { userActions } from "./userActions";
import { Dispatch } from "redux";
import { History } from "history";
import { applicationDraftActions } from "../Application/applicationDraftActions";

export async function sendRefreshRequest(clientId: string) {
  return UNINTERCEPTED_API_WITH_CREDENTIALS.post(`/public/auth/refresh?client_id=${clientId}`);
}

export function isUserInfoExpired(expiresAt: Date | undefined) {
  return expiresAt ? moment().utc().diff(expiresAt) > 0 : true;
}

export function willSessionExpire(expiresAt: Date | undefined, minutes: (string | undefined)) {
  minutes = "" + minutes;
  const datePlusMinutes = moment().utc().add(parseInt(minutes, 10), "minutes");
  return expiresAt ? moment(datePlusMinutes).diff(expiresAt) > 0 : true;
}

export function getTimeLeft(timeLeft: Date | undefined) {
  if (!timeLeft) {
    return;
  }
  const now = moment(new Date());
  const refreshExpiry = moment(timeLeft);
  return refreshExpiry.diff(now);
}

export async function keycloakLogout(loading: boolean, clientId: string, setLoading: (b: boolean) => void,
                                     dispatch: Dispatch, history: History) {
  if (loading) {
    return;
  }

  try {
    const data = {
      clientId: clientId
    };
    setLoading(true);

    return await UNINTERCEPTED_API_WITH_CREDENTIALS.post("/public/auth/logout", data).then(
      () => {
        setLoading(false);
        dispatch(applicationDraftActions.setApplicationDraft({}));
        dispatch(userActions.logout());
        history.push("/");
      }
    );
  } catch (error) {
    setLoading(false);
    dispatch(applicationDraftActions.setApplicationDraft({}));
    dispatch(userActions.logout());
    history.push("/");
  }
}
