import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import React from "react";
import { SubmittedApplication } from "./SubmittedApplication";

export const SubmittedLicenseAcquisition = () => {
  const {applicationNumber, stateFee} = useSelector((state: RootState) => state.licenseAcquisitionApplication);

  return <SubmittedApplication applicationNumber={applicationNumber} stateFee={stateFee}/>;
};
