import { ButtonProps as ReactstrapButtonProps } from "reactstrap";

export interface ButtonProps extends ReactstrapButtonProps {
  id: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  onAsyncClick?: () => Promise<void>;
}

export const ActionButtonClasses = "mr-4 mt-3";
