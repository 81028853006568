import * as React from "react";
import { Card } from "reactstrap";

import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { ServiceDetailsAccordion } from "../../../Shared/ActivityLicense/Services/ServiceDetailsAccordion";
import { BusinessRegistryInfoCard } from "../BusinessRegistryInfoCard";
import { getDocumentsContent } from "../../../Shared/ActivityLicense/Documents/DocumentsUtil";
import {
  Company,
  ActivityLocation,
  FileReference
} from "../../../../../api_client/medre_api";
import { ClickableLicenseNumberCard } from "../ClickableLicenseNumberCard";

export interface Props {
  company?: Company;
  submittingPersonFirstName?: string;
  submittingPersonLastName?: string;
  submittingPersonIdCode?: string;
  businessAreaName?: string;
  locations?: ActivityLocation[];
  isHospital?: boolean;
  isLicenseInvalidation: boolean;
  fileReferences: FileReference[];
  licenseNumber: string;
  licenseId: string;
}

export const DetailsCard = ({
  company,
  submittingPersonFirstName,
  submittingPersonLastName,
  submittingPersonIdCode,
  businessAreaName,
  locations,
  isHospital,
  isLicenseInvalidation,
  fileReferences,
  licenseNumber,
  licenseId
}: Props) => {
  const { id, name, address, registryCode, email, phone } = company || {};

  const renderLocationsAndServices = () => {
    if (isLicenseInvalidation) {
      return;
    }
    return (
      <>
        <ApplicationDetailsInfoCard
          title="Tegevuskoht"
          content={
            locations?.map((location) => ({
              key: "Tegevuskoht",
              value: location.fullAddress
            })) || []
          }
        />
        <h3 className="mt-4">Teenused</h3>
        <div className="service-details-table-container">
          {locations?.map((location) => (
            <ServiceDetailsAccordion
              key={location.id}
              isHospital={Boolean(isHospital)}
              services={location.services}
              isMobileActivityLocation={
                locations && locations[0].adsAdrID === "mobile"
              }
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" />
        {isLicenseInvalidation
          ? "Tegevusloa kehtetuks tunnistamise taotluse detailid"
          : "Tegevuskoha kehtetuks tunnistamise taotluse detailid"}
      </h2>
      <BusinessRegistryInfoCard
        id={id}
        name={name}
        address={address}
        registryCode={registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          {
            key: "Nimi",
            value: `${submittingPersonFirstName} ${submittingPersonLastName}`
          },
          { key: "Isikukood", value: submittingPersonIdCode }
        ]}
      />
      <ClickableLicenseNumberCard
        licenseId={licenseId || ""}
        licenseNumber={licenseNumber || ""}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: businessAreaName }]}
      />
      {renderLocationsAndServices()}
      <ApplicationDetailsInfoCard
        title="Dokumendid"
        content={getDocumentsContent(fileReferences || [])}
      />
    </Card>
  );
};
