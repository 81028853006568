import { LOCALE } from "../Constants";
import { SET_LOCALE } from "./localeActions";
import { RootAction } from "../rootReducer";
import moment from "moment";
import "moment/locale/et";
import "moment/locale/ru";
import { Locale } from "../Dto/Locale/Locale";

const defaultLocale: Locale = (localStorage.getItem(LOCALE) as Locale) || "et";

moment.locale(defaultLocale);

const initialState = defaultLocale;

export const localeReducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case SET_LOCALE:
      localStorage.setItem(LOCALE, action.payload);
      moment.locale(action.payload);
      return action.payload;
    default:
      return state;
  }
};
