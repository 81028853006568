import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AxiosPromise } from "axios";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { Assignment, AssignmentModal } from "./AssignmentModal";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { getBaseUrl, API } from "../../../../../../api";
import { displayAlert } from "../../../../../../Util/AlertUtil";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import {
  FileObjectType,
  OfficialUserPrivilegesEnum,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  OfficialUser,
  DecisionType, ProceedingStatusStatusEnum
} from "../../../../../../../api_client/medre_api";
import { Loader } from "../../../../../../Component/Loader/Loader";

interface Props {
  decisionUploadEndpoint: (
    decisionType: DecisionType,
    id: string,
    signer: OfficialUser,
    file: any,
    options?: any,
  ) => AxiosPromise<void>;
  updateApplication?: () => void;
  fileObjectType: FileObjectType;
  decision: { id: string; type: DecisionType };
  changeStatusTo?: (newStatus: ProceedingStatusStatusEnum) => void;
  isFinishProceedingAllowed?: boolean;
}

export const DecisionActions = ({
  decisionUploadEndpoint,
  fileObjectType,
  updateApplication,
  decision,
  changeStatusTo,
  isFinishProceedingAllowed
}: Props) => {
  const dispatch = useDispatch();
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAssign = (assignment: Assignment) => {
    setIsLoading(true);
    return decisionUploadEndpoint(
      decision.type,
      decision.id,
      assignment.signer,
      assignment.file.file,
      {
        withCredentials: true
      }
    )
      .then(() => {
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
        if (updateApplication) {
          updateApplication();
        }
        return Promise.resolve();
      })
      .catch(() => {
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch);
        return Promise.reject();
      })
      .finally(() => setIsLoading(false));
  };

  const downloadTemplate = () => {
    setIsLoading(true);
    decisionServiceAPI(undefined, getBaseUrl(), API)
      .download1(decision.type, decision.id, {
        withCredentials: true,
        responseType: "blob"
      })
      .then((response) => {
        const fileName =
          response.headers["content-disposition"]?.split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <Loader absolute backdrop />;
  }

  return (
    <ProtectedFieldWrapper
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}
    >
      <PrimaryFormattedButton
        id="addDecisionFile"
        className={ActionButtonClasses}
        onClick={() => setIsAssignmentModalOpen(true)}
      />
      {isFinishProceedingAllowed && (
        <SecondaryFormattedButton
          id="finishProceeding"
          className={ActionButtonClasses}
          onClick={() => changeStatusTo!(ProceedingStatusStatusEnum.Finished)}
        />
      )}
      <SecondaryFormattedButton
        id="downloadDecisionTemplate"
        className={ActionButtonClasses + " float-right"}
        onClick={downloadTemplate}
      />
      <AssignmentModal
        isOpen={isAssignmentModalOpen}
        onClose={() => setIsAssignmentModalOpen(false)}
        onAssign={handleAssign}
        modalMessage="Rahuldamise otsus suunatakse allkirjastamisele"
        fileObjectType={fileObjectType}
      />
    </ProtectedFieldWrapper>
  );
};
