import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { GeneralFM } from "../../../../Messages/GeneralFM";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { CompanyApplication } from "../../../../../api_client/medre_api";
import { CompanyApplicationRow } from "./CompanyApplicationRow";

interface Props {
  applications: CompanyApplication[];
  handleDelete: (rowNumber: number) => void;
  reloadApplications: () => void;
}

export const CompanyApplicationTable = ({
  applications,
  handleDelete,
  reloadApplications
}: Props) => {
  const [expandedRowNumber, setExpandedRowNumber] = useState<number>();

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      setExpandedRowNumber(rowNumber);
    } else {
      setExpandedRowNumber(undefined);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>
            <GeneralFM id="date" />
          </th>
          <th>
            <ActivityLicenseFM id="businessArea" />
          </th>
          <th>
            <ActivityLicenseFM id="proceedingType" />
          </th>
          <th>
            <ApplicationFM id="state" />
          </th>
          <th>
            <FormattedMessage
              id="activityLicenseTable.stateInformation"
              defaultMessage="Oleku lisainformatsioon"
            />
          </th>
          <th>
            <ApplicationFM id="applicationNumber" />
          </th>
        </tr>
      </thead>
      <tbody>
        {applications?.map((application, i) => (
          <CompanyApplicationRow
            key={i}
            application={application}
            isOpen={expandedRowNumber === i}
            index={i}
            handleRowClick={handleRowClick}
            handleDelete={() => handleDelete(i)}
            reloadApplications={reloadApplications}
          />
        ))}
      </tbody>
      <tfoot>
        <tr />
      </tfoot>
    </table>
  );
};
