import React from "react";

import info_icon from "../../../assets/images/information.svg";
import "./ErrorField.scss";

interface Props {
  message: string | JSX.Element;
  className?: string;
}

export const ErrorMessage = ({ className, message }: Props) => {
  return (
    <div className={"error-field arrow-top d-flex " + className}>
      <img src={info_icon} alt="icon" className="p-1" />
      <div className="error-field-text">{message}</div>
    </div>
  );
};
