import React, { ReactElement } from "react";
import { Label } from "reactstrap";

import "./Checkbox.scss";

interface Props {
  isChecked: boolean;
  id: string;
  name?: string;
  labelText: ReactElement | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
}

export const Checkbox = ({
  isChecked,
  id,
  name,
  labelText,
  onChange,
  className,
  disabled
}: Props) => (
  <div className={"custom-control custom-checkbox " + className}>
    <input
      checked={isChecked}
      className={"custom-control-input"}
      onChange={onChange}
      name={name}
      id={id}
      type="checkbox"
      disabled={disabled}
    />
    <Label className={"custom-control-label"} htmlFor={id}>
      {labelText}
    </Label>
  </div>
);
