import { AttachmentFileRow } from "../../Application/Education/Manual/Attachment/AttachmentFileRow";
import { FilesFM } from "../../../../Messages/FilesFM";
import { fileObjectTypeToKey } from "../../../../Dto/File/FileReference";
import React from "react";
import { FileReference } from "../../../../../api_client/medre_api";

export const getDocumentsContent = (fileReferences: FileReference[]) => {
  const content = new Map();
  fileReferences?.map((fileReference) => {
    if (content.has(fileReference.fileObjectType)) {
      content.get(fileReference.fileObjectType).push({
        key: "",
        value: (
          <AttachmentFileRow {...fileReference} isDeletingPossible={false} />
        ),
        valueClassName: "negative-margin"
      });
    } else {
      content.set(fileReference.fileObjectType, [
        {
          key: (
            <FilesFM id={fileObjectTypeToKey(fileReference?.fileObjectType!)} />
          ),
          value: (
            <AttachmentFileRow {...fileReference} isDeletingPossible={false} />
          )
        }
      ]);
    }
  });
  return [...Array.from(content.values())].reduce((a, b) => a.concat(b), []);
};
