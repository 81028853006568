import React from "react";
import { Card, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import drawing from "../../../../../assets/images/undraw_hiring_cyhs.svg";

export const InitialContactInfoEditCard = () => {
  return (
    <Card className="contact-info-edit-card align-content-center">
      <Row>
        <Col xl={9} md={8}>
          <h1 className="contact-info-edit-card-large-text">
            <FormattedMessage
              id="contactInfoEdit.initialWelcome"
              defaultMessage="Tere tulemast Terviseameti registrite iseteenindusse. Palun täitke enda kontaktandmed."
            />
          </h1>
        </Col>
        <Col xl={3} md={4} className="text-center pl-0">
          <img src={drawing} alt="pilt kontaktandmetest" className="contact-info-edit-card-img"/>
        </Col>
      </Row>
    </Card>
  );
};
