import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

type Message =
  | "thankYou"
  | "date"
  | "yourData"
  | "applicationData"
  | "deadline"
  | "hospital"
  | "applicantInfo"
  | "transferorInfo"
  | "licenseInfo"
  | "activities"
  | "validFrom"
  | "validUntil"
  | "termless"
  | "detailsLink"
  | "lookCloserLink"
  | "selectPlaceholderAll"
  | "look"
  | "insuranceContractData";

export const GeneralFM = (props: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    thankYou: {
      id: "generalFM.thankYou",
      defaultMessage: "Aitäh"
    },
    date: {
      id: "generalFM.date",
      defaultMessage: "Kuupäev"
    },
    yourData: {
      id: "generalFM.yourData",
      defaultMessage: "Sinu andmed"
    },
    applicationData: {
      id: "generalFM.applicationData",
      defaultMessage: "Taotluse info"
    },
    deadline: {
      id: "generalFM.deadline",
      defaultMessage: "Tähtaeg {deadline}"
    },
    hospital: {
      id: "generalFM.hospital",
      defaultMessage: "Haigla"
    },
    applicantInfo: {
      id: "generalFM.applicantInfo",
      defaultMessage: "Taotleja info"
    },
    transferorInfo: {
      id: "generalFM.transferorInfo",
      defaultMessage: "Üleandja info"
    },
    licenseInfo: {
      id: "generalFM.licenseInfo",
      defaultMessage: "Tegevusloa info"
    },
    activities: {
      id: "generalFM.activities",
      defaultMessage: "Tegevused"
    },
    valid: {
      id: "generalFM.valid",
      defaultMessage: "Kehtib"
    },
    validFrom: {
      id: "generalFM.validFrom",
      defaultMessage: "alates"
    },
    validUntil: {
      id: "generalFM.validUntil",
      defaultMessage: "Kehtib kuni"
    },
    termless: {
      id: "generalFM.termless",
      defaultMessage: "Tähtajatu"
    },
    detailsLink: {
      id: "generalFM.detailsLink",
      defaultMessage: "Detailvaade"
    },
    lookCloserLink: {
      id: "generalFM.lookCloserLink",
      defaultMessage: "Vaata lähemalt"
    },
    selectPlaceholderAll: {
      id: "generalFM.selectPlaceholderAll",
      defaultMessage: "Kõik"
    },
    look: {
      id: "generalFM.look",
      defaultMessage: "Vaata"
    },
    insuranceContractData: {
      id: "generalFM.insuranceContractData",
      defaultMessage: "Vastutuskindlustuse teave"
    }
  });

  return <FormattedMessage {...messages[props.id as Message]} {...props} />;
};
