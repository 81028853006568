import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "./SuspensionModal.scss";
import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { alertActions } from "../../../../Alert/alertActions";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import {
  Person,
  OfficialUserPrivilegesEnum,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI
} from "../../../../../api_client/medre_api";
import { doNothing } from "../../../../Util/MethodUtils";
import { dateToString } from "../../../../Util/DateUtils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  updatePerson: (person: Person) => void;
}

interface FormInput {
  courtName?: string;
  courtDecisionNumber?: string;
  startDate?: Date;
  endDate?: Date;
}

export const SuspensionModal = ({
  isOpen,
  onClose,
  personId,
  updatePerson
}: Props) => {
  const defaultValues: FormInput = { endDate: undefined };

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    errors,
    reset,
    formState,
    control,
    setValue,
    trigger
  } = useForm<FormInput>({
    defaultValues,
    mode: "onBlur"
  });
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { touched, isSubmitted } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (startDate) {
      trigger("endDate");
    }
  }, [trigger, startDate]);

  const onSubmit = (data: FormInput) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const { courtName, courtDecisionNumber } = data;
    officialUserDataAPI(undefined, getBaseUrl(), API)
      .suspendPerson(
        personId,
        {
          courtName,
          courtDecisionNumber,
          endDate: dateToString(endDate),
          startDate: dateToString(startDate)
        },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        const alertMessage = <AlertMessage id="createDecisionSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        updatePerson(response.data);
        handleClose();
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="createDecisionFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      })
      .finally(() => setIsSubmitting(false));
  };

  const validateEndDate = (value: Date) => {
    if (startDate && value) {
      let endMoment = moment(value, "DD.MM.YYYY").startOf("day");
      let startMoment = moment(startDate).startOf("day");
      return !endMoment.isBefore(startMoment);
    }
    return true;
  };

  const handleClose = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    reset(defaultValues);
    onClose();
  };

  const handleStartChange = (date: Date) => {
    setStartDate(date);
    setValue("startDate", date, { shouldValidate: true });
  };

  const handleEndChange = (date: Date) => {
    setEndDate(date);
    setValue("endDate", date, { shouldValidate: true });
  };

  return (
    <Modal className="suspend-health-care-professional-modal" isOpen={isOpen}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">
          <FormattedMessage
            id="SuspensionModal.header"
            defaultMessage="Tegevusõiguse peatamine"
          />
        </ModalHeader>
        <ModalBody>
          <div className="d-flex form-inline flex-nowrap">
            <label>Kohtu nimi</label>
            <Input
              name="courtName"
              autoComplete="off"
              valid={(isSubmitted || touched.courtName) && !errors.courtName}
              invalid={!!errors.courtName}
              innerRef={register({ required: true })}
            />
          </div>
          <div className="d-flex form-inline flex-nowrap">
            <label>Kohtuotsuse number</label>
            <Input
              name="courtDecisionNumber"
              autoComplete="off"
              valid={
                (isSubmitted || touched.courtDecisionNumber) &&
                !errors.courtDecisionNumber
              }
              invalid={!!errors.courtDecisionNumber}
              innerRef={register({ required: true })}
            />
          </div>
          <div className="d-flex form-inline flex-nowrap">
            <label>Peatamise algus</label>
            <Controller
              control={control}
              name="startDate"
              rules={{ required: true }}
              defaultValue=""
              as={<DatePickerComponent onDateChange={doNothing} />}
              onDateChange={handleStartChange}
              selectedDate={startDate}
              valid={!!startDate && !errors.startDate}
              invalid={!!errors.startDate}
            />
          </div>
          <div className="d-flex form-inline flex-nowrap">
            <label>Peatamise lõpp</label>
            <Controller
              control={control}
              name="endDate"
              rules={{ required: true, validate: validateEndDate }}
              defaultValue=""
              as={<DatePickerComponent onDateChange={doNothing} />}
              onDateChange={handleEndChange}
              selectedDate={endDate}
              valid={!!endDate && !errors.endDate}
              invalid={!!errors.endDate}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="backToDetailView"
            onClick={handleClose}
          />
          <ProtectedFieldWrapper
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <PrimaryFormattedButton
              id="confirmAndCreateDecision"
              disabled={isSubmitting}
            />
          </ProtectedFieldWrapper>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
