import React from "react";
import { Card } from "reactstrap";

import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { renderOverViewRow } from "./ApplicationOverViewUtil";

interface Props {
  occupation?: string;
  speciality?: string;
}

export const SpecialityOverViewCard = ({ occupation, speciality }: Props) => (
  <>
    <h5 className="fw-normal">
      <ApplicationFM id="speciality" />
    </h5>
    <Card className="info-card">
      {renderOverViewRow("application", "occupationName", occupation)}
      {renderOverViewRow("application", "speciality", speciality)}
    </Card>
  </>
);
