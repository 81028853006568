import React, { useState } from "react";
import "./ActivityLicenseServices.scss";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { FormattedMessage } from "react-intl";
import { FormFeedback, Input } from "reactstrap";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { AddEmployeeModal } from "./AddEmployeeModal";
import { useDispatch, useSelector } from "react-redux";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { AddRoomsModal } from "./Room/AddRoomsModal";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import {
  ActivityLicenseBusinessAreaBusinessAreaTypeEnum,
  ActivityLicenseService,
  HealthCareProfessional,
  Occupation,
  ServiceRoom,
} from "../../../../../api_client/medre_api";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import { RootState } from "../../../../rootReducer";
import { AddInitialSpecialistModal } from "./Specialist/AddInitialSpecialistModal";
import { RemoveButton } from "../../../../Component/Button/RemoveButton";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";

type ServiceContentType =
  | "rooms"
  | "employees"
  | "specialists"
  | "beds"
  | "ambulanceBrigades"
  | "gpLists"
  | "mobileActivityLocation";

interface Props {
  locationInfoIndex: number;
  serviceContentType: ServiceContentType;
  service: ActivityLicenseService;
}

export const ServiceContentContainer = ({
  locationInfoIndex,
  serviceContentType,
  service,
}: Props) => {
  const dispatch = useDispatch();
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isSpecialistModalOpen, setIsSpecialistModalOpen] = useState(false);
  const [isAddRoomsModalOpen, setIsAddRoomsModalOpen] = useState(false);

  const isSpecialist: boolean = useSelector((state: RootState) => {
    return (
      state.activityLicenseApplication.businessArea?.businessAreaType ===
      ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist
    );
  });

  const requiredOccupations: Occupation[] = useSelector(
    (state: RootState) => service.service?.requiredOccupations as Occupation[]
  );

  const handleSaveRooms = (rooms?: ServiceRoom[]) => {
    saveRooms(rooms);
    closeAddRoomsModal();
  };
  const requiredOccupationPrefix = requiredOccupations[0]?.prefix || null;

  const isSpecialistWithMatchingOccupation = (employee: HealthCareProfessional): boolean => {
    
    return employee?.occupationCodes?.some(e => e.prefix === requiredOccupationPrefix) || false;
  };

  const updateServiceEmployees = (employees: HealthCareProfessional[]) => {
    dispatch(
      activityLicenseApplicationActions.updateActivityLicenseService(
        locationInfoIndex,
        service.service!.id,
        { ...service, employees }
      )
    );
    setIsEmployeeModalOpen(false);
  };

  const openEmployeeModal = () => {
    setIsEmployeeModalOpen(true);
  };

  const openSpecialistModal = () => {
    setIsSpecialistModalOpen(true);
  };

  const openAddRoomsModal = () => {
    setIsAddRoomsModalOpen(true);
  };

  const closeAddRoomsModal = () => {
    setIsAddRoomsModalOpen(false);
  };

  const handleDeleteRooms = (rooms?: ServiceRoom[]) => {
    saveRooms(rooms);
  };

  const saveSpecialist = (
    specialist: HealthCareProfessional,
    specializationId?: string
  ) => {
    try {
      if (service?.employees) {
        if (!service.employees.map((spec) => spec.id).includes(specialist.id))
          updateServiceEmployees([...service.employees!, specialist]);
      } else {
        updateServiceEmployees([specialist]);
      }
      displayAlert(
        "specialistAddedSuccess",
        AlertType.Success,
        dispatch
      );
      setIsSpecialistModalOpen(false)
    } catch (e) {
      displayAlert(
        "specialistAddedFailure",
        AlertType.Danger,
        dispatch
      );
    }
  };


  const removeSpecialist = (specialist: HealthCareProfessional) => {
    try {
      if (service?.employees) {
        const updatedEmployees = service.employees.filter((spec) => spec.id !== specialist.id);
        updateServiceEmployees(updatedEmployees);
      }
      displayAlert(
        "specialistRemovedSuccess",
        AlertType.Success,
        dispatch
      );
    } catch (e) {
      displayAlert(
        "specialistRemovedFailure",
        AlertType.Danger,
        dispatch
      );
    }
  } 

  const saveRooms = (rooms?: ServiceRoom[]) => {
    dispatch(
      activityLicenseApplicationActions.updateActivityLicenseService(
        locationInfoIndex,
        service.service!.id,
        { ...service, rooms }
      )
    );
  };


  const createContent = () => {
    switch (serviceContentType) {
      case "rooms":
        return createServiceRoomsContent();
      case "employees":
        return createServiceEmployeesContent();
      case "specialists":
        return createServiceEmployeesContent();
      case "beds":
        return createServiceBedsContent();
      case "ambulanceBrigades":
        return createAmbulanceBrigadesContent();
      case "gpLists":
        return createGpListsNumberContent();
      case "mobileActivityLocation":
        return createMobileActivityLocationContent();
      default:
        return "rooms";
    }
  };

  const createLink = () => {
    let id: ButtonName;
    switch (serviceContentType) {
      case "employees":
        id = service?.employees
          ? "addOrEditServiceEmployees"
          : "addServiceEmployees";
        break;
      case "rooms":
        id = service?.rooms ? "addOrEditServiceRooms" : "addServiceRooms";
        break;
      case "specialists":
        id = "addSpecialist";
        break;
      default:
        id = service?.employees
          ? "addOrEditServiceEmployees"
          : "addServiceEmployees";
    }

    return (
      <LinkButton
        className="d-flex"
        id={id}
        onClick={
          serviceContentType === "rooms"
            ? openAddRoomsModal
            : serviceContentType === "specialists"
            ? openSpecialistModal
            : openEmployeeModal
        }
      />
    );
  };

  const createServiceEmployeesContent = () => {
    return (
      <>
        {service?.employees && (
          <ul className="d-flex flex-wrap">
            {service?.employees?.map((employee) => {
              return (
                <li className="employees-element" key={employee.id}>
                  <b> {employee.firstName + " " + employee.lastName} </b>
                  {renderEmployment(employee)}
                  
                </li>
              );
            })}
          </ul>
        )}
        {createLink()}
      </>
    );
  };

  const renderRemoveSpecialistButton = (employee: HealthCareProfessional) => {
    return  (
      <RemoveButton 
        handleDelete={() => removeSpecialist(employee)}/>
    )
  }
  
  const renderEmployment = (employee: HealthCareProfessional) => {
    return isSpecialist ? (
      <div>
        {renderSpecialistEmploymentInfo(employee)}
        {renderRemoveSpecialistButton(employee)}
      </div>
    ) : (
      renderNonSpecialistEmploymentInfo(employee)
    )
  }
  
  const renderSpecialistEmploymentInfo = (employee: HealthCareProfessional) => {
    if (isSpecialistWithMatchingOccupation(employee)) {
      return employee.occupationCodes
      ?.filter(occupation => occupation.prefix === requiredOccupationPrefix)
      .map((occ) => {
        return <div key={occ.code}>{occ.code}, {occ.name}</div>;
      })
    } else {
      return <div>{requiredOccupations[0].name}</div>;
    }
  }

  const renderNonSpecialistEmploymentInfo = (employee: HealthCareProfessional) => {
    return (
      <>
        {employee.occupationCodes?.map((occupation) => (
          <div key={occupation.code}>
            {occupation.code + ", " + occupation.name}
          </div>
        ))}
        {employee.specialities?.map((speciality) => (
          <div key={speciality.code}>{speciality.name}</div>
        ))}
      </>
    );
  }

  const createServiceRoomsContent = () => (
    <>
      {service?.rooms && (
        <ul className="d-block">
          {service?.rooms?.map((serviceRoom) => (
            <li key={serviceRoom.room?.id} className="w-100">
              <b> {serviceRoom.room?.name} </b>
              <div>{serviceRoom.number}</div>
            </li>
          ))}
        </ul>
      )}
      {createLink()}
    </>
  );

  const createMobileActivityLocationContent = () => {
    return (
      <div className="form-inline w-100">
        <div className="pl-2 text text-right">
          <FormattedMessage
            id="activityLicense.mobileActivityLocationRegistryNumber"
            defaultMessage="Registrinumber"
          />
        </div>
        <div className="input-wrapper mw-100">
          <Input
            name="mobileActivityLocationRegistryNumber"
            value={service.mobileActivityLocationRegistryNumber || ""}
            onChange={(e) => handleRegistryCodeChange(inputRegex(e))}
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </div>
    );
  };

  const handleNumericFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.substring(0, 15);
    const numericValue = value === "" ? undefined : +value;
    dispatch(
      activityLicenseApplicationActions.updateActivityLicenseService(
        locationInfoIndex,
        service.service!.id,
        { ...service, [e.target.name]: numericValue }
      )
    );
  };

  const handleRegistryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.substring(0, 15).toUpperCase();
    dispatch(
      activityLicenseApplicationActions.updateActivityLicenseService(
        locationInfoIndex,
        service.service!.id,
        { ...service, [e.target.name]: value }
      )
    );
  };

  const createServiceBedsContent = () => {
    return (
      <div className="form-inline w-100">
        <div className="pl-2 text text-right">
          <FormattedMessage
            id="activityLicense.addServiceBeds"
            defaultMessage="Voodikohtade arv"
          />
        </div>
        <div className="input-wrapper mw-100">
          <Input
            name="beds"
            value={service?.beds || ""}
            onChange={(e) => handleNumericFieldChange(inputRegex(e))}
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </div>
    );
  };

  const createAmbulanceBrigadesContent = () => {
    return (
      <div className="form-inline w-100">
        <div className="pl-2 text text-right">
          <FormattedMessage
            id="activityLicense.numberOfAmbulanceBrigades"
            defaultMessage="Kiirabibrigaadide arv"
          />
        </div>
        <div className="input-wrapper mw-100">
          <Input
            name="ambulanceBrigades"
            value={service?.ambulanceBrigades || ""}
            onChange={(e) => handleNumericFieldChange(inputRegex(e))}
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </div>
    );
  };

  const createGpListsNumberContent = () => {
    return (
      <div className="form-inline w-100">
        <div className="pl-2 text text-right">
          <FormattedMessage
            id="activityLicense.numberOfGpLists"
            defaultMessage="Nimistute arv"
          />
        </div>
        <div className="input-wrapper mw-100">
          <Input
            name="numberOfGpLists"
            value={service?.numberOfGpLists || ""}
            onChange={(e) => handleNumericFieldChange(inputRegex(e))}
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </div>
    );
  };

  const createHealthCareEmployeeModal = () => {
    return isSpecialist ? (
      <AddInitialSpecialistModal
        requiredOccupations={requiredOccupations}
        isOpen={isSpecialistModalOpen}
        onClose={() => setIsSpecialistModalOpen(false)}
        onSave={saveSpecialist}
      />
    ) : (
      <AddEmployeeModal
        isOpen={isEmployeeModalOpen}
        defaultEmployees={service?.employees || []}
        requiredOccupations={service.service?.requiredOccupations}
        requiredSpecialities={service.service?.requiredSpecialities}
        optionalOccupations={service.service?.optionalOccupations}
        optionalSpecialities={service.service?.optionalSpecialities}
        onClose={() => setIsEmployeeModalOpen(false)}
        onSave={updateServiceEmployees}
        title={
          <FormattedMessage
            id="addEmployeeModal.header"
            defaultMessage="Lisa töötajad"
          />
        }
        subtitle={
          <FormattedMessage
            id="addEmployeeModal.subtitle"
            defaultMessage="Otsi tervishoiu töötajaid"
          />
        }
        occupationCodeLabel={
          <FormattedMessage
            id="addEmployeeModal.occupationCode"
            defaultMessage="Kutse kood"
          />
        }
        closeButtonId="backToApplication"
        addButtonId="addEmployeesToService"
        serviceCode={service.service?.code}
        serviceStatus={service.service?.status}
      />
    );
  };

  return (
    <div className="elements-container">
      {createContent()}
      {createHealthCareEmployeeModal()}
      <AddRoomsModal
        isOpen={isAddRoomsModalOpen}
        serviceRooms={service?.service?.serviceRooms || []}
        savedServiceRooms={service?.rooms}
        onClose={closeAddRoomsModal}
        onSave={handleSaveRooms}
        deleteRooms={handleDeleteRooms}
      />
    </div>
  );
};
