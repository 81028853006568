import { Col, FormGroup, Row } from "reactstrap";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import "./CompetenceByExamSelection.scss";

export const CompetenceByExamSelection = () => {

  const competenceExam = useSelector((state: RootState) => state.applicationDraft.competenceExam);

  const dispatch = useDispatch();

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const message =
    <FormattedMessage id={"competenceByExamSelection.message"} defaultMessage={"Lisapädevus eksami alusel"}/>;

  function handleChange() {
      dispatch(applicationDraftActions.updateApplicationDraft("competenceExam", !competenceExam));
  }

  return (
    <Row>
      <Col className="offset-sm-1">
        <FormGroup className={isMobile ? "d-flex justify-content-left p-0" : "ml-4"} check={true}>
          <Checkbox
            isChecked={!!competenceExam}
            id={"competence-exam-selection"}
            className="competence-text"
            labelText={message}
            onChange={handleChange}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
