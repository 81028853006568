import * as React from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row as TableRow,
  usePagination,
  useTable
} from "react-table";

import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { SearchApplicant } from "../../../Shared/Search/Filters/FilterTypes";
import { personStatuses } from "../../../Shared/Search/Filters/FilterUtils";
import { translateCountry } from "../../../../Component/Translate/CountryTranslator";
import "../../../Shared/Search/ResultsTable/ResultsTable.scss";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";

interface Props {
  data: Array<SearchApplicant>;
  pageCount: number;
  pageIndex: number;
}

const COLUMNS: Column<SearchApplicant>[] = [
  {
    Header: "Eesnimi",
    accessor: "firstName"
  },
  {
    Header: "Perenimi",
    accessor: "lastName"
  },
  {
    Header: "THT olek",
    accessor: "status",
    Cell: ({ cell }) =>
      personStatuses.find((status) => status.value === cell.row.values.status)
        ?.label || "Taotleja"
  },
  {
    Header: "Isikukoodi riik",
    accessor: "idCodeCountry",
    Cell: ({ cell }) => translateCountry(cell.row.values.idCodeCountry)
  },
  {
    Header: "Isikukood",
    accessor: "idCode"
  },
  {
    Header: "",
    accessor: "id",
    Cell: ({ cell }) => (
      <DetailViewLinkButton to={`/persons/${cell.row.values.id}`} />
    )
  }
];

export const ApplicantResults = ({ data, pageCount, pageIndex }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount
    },
    usePagination
  );

  return (
    <div className="table-wrap">
      <div className="scrollable">
        <table {...getTableProps()} className="s-table">
          <thead>
            {headerGroups.map(
              (headerGroup: HeaderGroup<SearchApplicant>, i: number) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: ColumnInstance<SearchApplicant>, j: number) => {
                      return (
                        <th
                          className="normal-column"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    }
                  )}
                </tr>
              )
            )}
          </thead>
          <tbody {...getTableBodyProps()} className="table-result">
            {page.map((row: TableRow<SearchApplicant>, i: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell<SearchApplicant>, j: number) => {
                    return (
                      <td className="normal-column" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
