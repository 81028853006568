import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
  days?: number | string;
}

type Message =
  | "applicationSubmitted"
  | "payingStateFee"
  | "xDays"
  | "upToXDays"
  | "upToXWorkingDays"
  | "onStateFeeReceive"
  | "you"
  | "healthAgency"
  | "applicationDecision"
  | "applicationDecisionAutomatic";

export const SubmissionTimeLineFM = ({ id, days }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    applicationSubmitted: {
      id: "SubmissionTimeLineFM.applicationSubmitted",
      defaultMessage: "Taotlus esitatud"
    },
    payingStateFee: {
      id: "SubmissionTimeLineFM.payingStateFee",
      defaultMessage: "Riigilõivu tasumine"
    },
    xDays: {
      id: "SubmissionTimeLineFM.xDays",
      defaultMessage: "{days} päeva aega"
    },
    upToXDays: {
      id: "SubmissionTimeLineFM.upToXDays",
      defaultMessage: "kuni {days} päeva"
    },
    upToXWorkingDays: {
      id: "SubmissionTimeLineFM.upToXWorkingDays",
      defaultMessage: "kuni {days} tööpäeva"
    },
    onStateFeeReceive: {
      id: "SubmissionTimeLineFM.onStateFeeReceive",
      defaultMessage: "Riigilõivu laekumisel"
    },
    you: {
      id: "SubmissionTimeLineFM.you",
      defaultMessage: "Sina"
    },
    healthAgency: {
      id: "SubmissionTimeLineFM.healthAgency",
      defaultMessage: "Terviseamet"
    },
    applicationDecision: {
      id: "SubmissionTimeLineFM.applicationDecision",
      defaultMessage: "Taotluse otsus"
    },
    applicationDecisionAutomatic: {
      id: "SubmissionTimeLineFM.applicationDecisionAutomatic",
      defaultMessage: "Taotluse otsus automaatne"
    }
  });

  return (
    <FormattedMessage {...messages[id as Message]} values={{ days: days }} />
  );
};
