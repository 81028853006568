import React from "react";
import { Link, LinkProps } from "react-router-dom";
import classNames from "classnames";

import { FormattedButton, FormattedButtonProps } from "./FormattedButton";
import "./Button.scss";
import { GeneralFM } from "../../Messages/GeneralFM";
import chevronRight from "../../../assets/images/chevron_right.svg";

export const LinkButton = ({ className, ...props }: FormattedButtonProps) => {
  return (
    <FormattedButton
      {...props}
      className={classNames("link-button", className)}
      color="link"
    />
  );
};

type DetailViewLinkButtonProps = LinkProps & {
  id?: "detailsLink" | "lookCloserLink" | "look";
};

export const DetailViewLinkButton = ({
  id = "detailsLink",
  ...props
}: DetailViewLinkButtonProps) => (
  <Link {...props}>
    <GeneralFM id={id} />
    <img src={chevronRight} width={16} height={16} alt="" />
  </Link>
);
