import React, { useState } from "react";
import "./ContactInfo.scss";
import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { ContactInfoView } from "./ContactInfoView";
import { OfficialUserPrivilegesEnum } from "../../../../../api_client/medre_api";
import { ContactInfoEdit } from "./ContactInfoEdit";

export const ContactInfo = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const personContactInfo = useSelector(
    (state: RootState) => state.person.contactInfo
  );
  const [isContactInfoEditActive, setIsContactInfoEditActive] = useState(
    userInfo?.roles?.includes(OfficialUserPrivilegesEnum.Person) &&
      !personContactInfo?.email
  );

  return (
    <>
      <h1 className="mt-5 ml-3 contact-info-header fw-300">
        <FormattedMessage
          id="contactInfoDashboard.header"
          defaultMessage="Minu kontaktandmed"
        />
      </h1>
      <Card className="ml-3 mt-3 mb-5 mr-3">
        {isContactInfoEditActive ? (
          <ContactInfoEdit setIsEditActive={setIsContactInfoEditActive} />
        ) : (
          <ContactInfoView
            userInfo={userInfo}
            personContactInfo={personContactInfo}
            setIsEditActive={setIsContactInfoEditActive}
          />
        )}
      </Card>
    </>
  );
};
