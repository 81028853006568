import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";

import { Loader } from "../../../Component/Loader/Loader";
import { getFullName } from "../../../Util/PersonUtils";
import DetailsSection from "../../../Component/DetailsSection/DetailsSection";
import { ApplicationDetailsInfoCard } from "../../../Component/Card/ApplicationDetailsInfoCard";
import { formatDate } from "../../Shared/Application/OverView/ApplicationOverViewUtil";
import { getBaseUrl, API } from "../../../api";
import { uniqBy } from "../../../Util/ArrayUtil";
import { BackButton } from "../../../Component/Button/BackButton";
import {
  Competence,
  PdevusteLisamineIsikuandmeteleAvalikusPortaalisApiFactory as publicPortalAPI,
  AvalikudIsikudLoetlevadTeenustApiFactory as publicEntitieListAPI,
  PersonPublic,
  OccupationCodePublic,
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI, ActivityLicense
} from "../../../../api_client/medre_api";
import "./PublicHealthcareEmployeesDetails.scss";
import { PHARMACISTS_PREFIXES, SPECIALISTS_PREFIXES } from "../../../Constants";
import { displayAlert } from "../../../Util/AlertUtil";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { useDispatch } from "react-redux";
import { getDate } from "../../../Util/DateUtils";

export const PublicHealthcareEmployeeDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [employee, setEmployee] = useState<PersonPublic>();
  const [competencies, setCompetencies] = useState<Competence[]>();
  const [activityLicense, setActivityLicense] = useState<ActivityLicense>();
  const [employeePharmacies, setEmployeePharmacies] = useState(new Map());

  const dispatch = useDispatch();

  const updateEmployeePharmacies = (companyName, pharmacyName): void => {
    if (pharmacyName === null) return;

    const existingPharmacies = employeePharmacies.get(companyName) || [];
    if (!existingPharmacies.includes(pharmacyName)) {
      setEmployeePharmacies(employeePharmacies.set(companyName, [...existingPharmacies, pharmacyName]));
    }
  }

  const displayNotification = (id: string, type: AlertType): void => {
    displayAlert(
      id,
      type,
      dispatch
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([
      publicEntitieListAPI(undefined, getBaseUrl(), API).getPerson(id, {
        withCredentials: true
      }),
      publicPortalAPI(undefined, getBaseUrl(), API).getAllCompetenciesForPerson(
        id,
        {
          withCredentials: true
        }
      )
    ]).then(([{ data: employeeData }, { data: competenciesData }]) => {
      setCompetencies(competenciesData);
      setEmployee(employeeData);

      if (!employeeData || !employeeData.occupationCodes) {
        displayNotification("fetchDetailedEmployeeEmploymentsInfoFailed", AlertType.Danger);
        return;
      }

      employeeData.occupationCodes.forEach((code) => {
        if (PHARMACISTS_PREFIXES.includes(code.prefix)) {
          if (employeeData.employments !== undefined) {
            employeeData.employments.forEach((employment) => {
              publicActivityPermitAPI(undefined, getBaseUrl(), API).getActivityLicense(
                employment.activityLicenseId!,
                {
                  withCredentials: true
                }
              ).then(({data: activityLicenseData}) => {
                updateEmployeePharmacies(employment.companyName, activityLicenseData.mainPharmacyName);

                setActivityLicense(activityLicenseData);
              });
            });
          } else {
            displayNotification("fetchDetailedEmployeeEmploymentsInfoFailed", AlertType.Danger);
            return;
          }
        }
      });
    });
  }, [id]);

  if (!employee) {
    return <Loader />;
  }

  const { employments = [] } = employee;
  const hasEmployments = !!employments.length;
  const specialistOccupations = employee.occupationCodes!
      .filter((code) => SPECIALISTS_PREFIXES.includes(code.code.charAt(0)));
  const isVirtualOccupation = (occupationCodePublic: OccupationCodePublic) => {
    return !SPECIALISTS_PREFIXES.includes(occupationCodePublic.prefix);
  }
  const pharmaciesToString = (companyName): string => {
    if (!companyName) {
      return "";
    } else {
      return employeePharmacies.has(companyName) ? ` (${employeePharmacies.get(companyName).join(", ")})` : "";
    }
  }

  const getValidCompetencies = () => {
    const today = getDate(new Date());
    if (!competencies) return;

    return competencies.filter(c => getDate(new Date(c.validUntil!)) >= today);
  }

  return (
    <div className="public-healthcare-employees-details gp-lists-container application-container application-detail p-2 p-md-3">
      <BackButton />
      <div className="d-flex flex-wrap align-items-center my-4">
        <h1 className="title mb-0 mr-3">
          <FormattedMessage
            id="HealthcareEmployee.title"
            defaultMessage="{fullName} {code}"
            values={{
              code: employee
                .occupationCodes!
                  .filter((code) => isVirtualOccupation(code))
                  .map((code) => code.code)
                .join(", "),
              fullName: getFullName(employee)
            }}
          />
        </h1>
      </div>

      <DetailsSection
        id="details"
        className="section"
        title={
          <h2 className="title">
            <FormattedMessage
              id="HealthcareEmployee.detailsTitle"
              defaultMessage="Tervishoiutöötaja andmed"
            />
          </h2>
        }
      >
        <ApplicationDetailsInfoCard
          title={
            <FormattedMessage
              id="HealthcareEmployee.personalInformation"
              defaultMessage="Isikuinfo"
            />
          }
          content={
            [
              {
                key: (
                  <FormattedMessage
                    id="HealthcareEmployee.THTCode"
                    defaultMessage="Tervishoiutöötaja kood"
                  />
                ),
                value: employee.occupationCodes
                  ?.filter((code) => isVirtualOccupation(code))
                    .map((code) => code.code)
                  .join(", ")
              },
              {
                key: (
                  <FormattedMessage
                    id="HealthcareEmployee.firstName"
                    defaultMessage="Eesnimi"
                  />
                ),
                value: employee.firstName
              },
              {
                key: (
                  <FormattedMessage
                    id="HealthcareEmployee.lastName"
                    defaultMessage="Perenimi"
                  />
                ),
                value: employee.lastName
              },
              {
                key: (
                  <FormattedMessage
                    id="HealthcareEmployee.occupation"
                    defaultMessage="Kutse"
                  />
                ),
                value: employee.occupationCodes
                  ?.filter((code) => isVirtualOccupation(code))
                    .map((occupation) => occupation.name)
                  .join(", ")
              },
              employee.residencyInfo && {
                key: "",
                value: (
                  <FormattedMessage
                    id="HealthcareEmployee.studyingInResidency"
                    defaultMessage="Õpib residentuuris"
                  />
                )
              },
              {
                key: (
                  <FormattedMessage
                    id="HealthcareEmployee.specialty"
                    defaultMessage="Eriala"
                  />
                ),
                value: employee.specialities
                  ?.map((specialty) => specialty.name)
                  .join(", ")
              },
              specialistOccupations.length > 0 && {
                key: (
                    <FormattedMessage
                        id="HealthcareEmployee.specialization"
                        defaultMessage="Spetsialiseerumine"
                    />
                ),
                value: specialistOccupations
                    ?.map((specialistOccupation) => {
                      return specialistOccupation.code + " " + specialistOccupation.name;
                    })
                    .join(", ")
              }
            ].filter(Boolean) as []
          }
        />
        {!!getValidCompetencies()?.length && (
          <>
            <h3 className="mt-4 d-flex justify-content-between">
              <FormattedMessage
                id="HealthcareEmployee.competenceAssessments"
                defaultMessage="Pädevuse hindamised"
              />
            </h3>
            {getValidCompetencies()!.map(
              ({
                 id: competenceId,
                 validationDate,
                 validUntil,
                 nameAssociation
               }) => (
                <ApplicationDetailsInfoCard
                  key={competenceId}
                  content={[
                    {
                      key: (
                        <FormattedMessage
                          id="HealthcareEmployee.nameOfIssuer"
                          defaultMessage="Väljastaja nimi"
                        />
                      ),
                      value: nameAssociation
                    },
                    {
                      key: (
                        <FormattedMessage
                          id="HealthcareEmployee.dateOfApproval"
                          defaultMessage="Kinnitamise kuupäev"
                        />
                      ),
                      value: formatDate(validationDate)
                    },
                    {
                      key: (
                        <FormattedMessage
                          id="HealthcareEmployee.validUntil"
                          defaultMessage="Kehtib kuni"
                        />
                      ),
                      value: formatDate(validUntil)
                    }
                  ]}
                />
              )
            )}
          </>
        )}
        {hasEmployments ? (
          <>
            <h3 className="mt-4 d-flex justify-content-between">
              <FormattedMessage
                id="HealthcareEmployee.jobs"
                defaultMessage="Töökohad"
              />
            </h3>
            {uniqBy(employments, "companyName").map((employment) => (
              <ApplicationDetailsInfoCard
                key={employment.companyName}
                content={[
                  {
                    key: (
                      <FormattedMessage
                        id="HealthcareEmployee.workPlace"
                        defaultMessage="Töökoha nimi"
                      />
                    ),
                    value: employment.companyName + pharmaciesToString(employment.companyName)
                  }
                ]}
              />
            ))}
          </>
        ) : (
          <>
            <h3 className="mt-4 d-flex justify-content-between">
              <FormattedMessage
                id="HealthcareEmployee.noRecordOfWorking"
                defaultMessage="Terviseametil puuduvad andmed töötamise kohta"
              />
            </h3>
            <Row className="no-record-of-working-block pl-3">
              <p>
                <FormattedMessage
                  id="HealthcareEmployee.noRecordOfWorkingMessage"
                  defaultMessage="Kodulehel kuvatakse andmed tervishoiutöötajate kohta Eesti tervishoiuasutuses ja perearsti praksises (st tervishoiutöötajate kohta, kes osutavad ise tervishoiuteenuseid) või õppimise kohta Tartu Ülikooli residentuuris."
                />
              </p>
            </Row>
          </>
        )}
      </DetailsSection>
    </div>
  );
};
