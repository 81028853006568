import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import RepresentativeSearch from "./RepresentativeSearch/RepresentativeSearch";
import { RootState } from "../../../rootReducer";
import RepresentativeList from "./RepresentativeList/RepresentativeList";
import { getBaseUrl, API } from "../../../api";
import {
  EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivityAPI,
  PowerOfAttorney,
  PowersOfAttorneySearchFilter,
  PowersOfAttorneySearchFilterStatusEnum
} from "../../../../api_client/medre_api";
import { searchResultsActions } from "../../Shared/Search/searchResultsActions";
import { ActiveFilters } from "../../Shared/Search/Filters/FilterTypes";
import { RepresentativeSearchForm } from "./RepresentativeSearch/types";
import {
  getRepresentativeStatusValues,
  RepresentativeStatusDto
} from "../../../Util/RepresentativeUtils";
import info_icon from "../../../../assets/images/information.svg";
import { FormattedToolTip } from "../../../Component/ToolTip/FormattedToolTip";
import { RepresentativeInfoTooltipContent } from "./RepresentativeInfoTooltipContent";


const RepresentativeContainer = () => {
  const { pageSize, pageIndex, pageCount, data, rowCount, filters, sort } =
    useSelector((rootState: RootState) => rootState.searchResults);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentRegistryCode, setCurrentRegistryCode] = useState<string>();

  const fetchRepData = useCallback(
    (registryCode: string, body: PowersOfAttorneySearchFilter) => {
      return corporateDataActivityAPI(
        undefined,
        getBaseUrl(),
        API
      ).searchPowersOfAttorney(registryCode, body);
    },
    [pageIndex, pageSize, sort]
  );

  const fetchCurrentCompany = useCallback(() => {
    return corporateDataActivityAPI(
      undefined,
      getBaseUrl(),
      API
    ).getCurrentCompany();
  }, []);

  const fetchAndSetRepData = (
    regCode: string,
    body: PowersOfAttorneySearchFilter
  ) => {
    setLoading(true);
    fetchRepData(regCode, body)
      .then(({ data: listData }) => {
        setLoading(false);
        const currentData = listData.content ?? [];
        dispatch(
          searchResultsActions.setData(
            currentData as Array<PowerOfAttorney>,
            ActiveFilters.RepresentativeList
          )
        );
        dispatch(searchResultsActions.setRowCount(listData.totalElements!));
        dispatch(searchResultsActions.setPageCount(listData.totalPages!));
        dispatch(searchResultsActions.setFilters(body));
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    const currentFilter: PowersOfAttorneySearchFilter = filters
      ? (filters as PowersOfAttorneySearchFilter)
      : {
        status: [
          PowersOfAttorneySearchFilterStatusEnum.Valid,
          PowersOfAttorneySearchFilterStatusEnum.Scheduled
        ]
      };

    if (currentRegistryCode) {
      fetchAndSetRepData(currentRegistryCode, {
        ...currentFilter,
        sort: sort ?? undefined,
        page: pageIndex,
        size: pageSize
      });
    } else {
      setLoading(true);
      fetchCurrentCompany()
        .then(({ data: company }) => {
          if (company.registryCode) {
            fetchAndSetRepData(company.registryCode, {
              ...currentFilter,
              sort: sort ?? undefined,
              page: pageIndex,
              size: pageSize
            });
            setCurrentRegistryCode(company.registryCode);
          } else {
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
    }
  }, [dispatch, pageIndex, pageSize, sort]);

  const handleSearch = (data: RepresentativeSearchForm) => {
    const activeData: RepresentativeSearchForm = {};

    for (const key in data) {
      if (key === "status") {
        activeData[key] = !!data[key]?.length
          ? getRepresentativeStatusValues(
            data[key] as Array<RepresentativeStatusDto>
          )
          : undefined;
      } else if (data[key]) {
        activeData[key] = data[key];
      }
    }

    setLoading(true);
    fetchAndSetRepData(
      currentRegistryCode as string,
      {
        ...activeData,
        sort: sort ?? undefined,
        page: pageIndex,
        size: pageSize
      } as PowersOfAttorneySearchFilter
    );
  };

  const reFetchAndUpdateData = () => {
    fetchAndSetRepData(currentRegistryCode!, {
      ...filters,
      page: pageIndex,
      size: pageSize
    } as PowersOfAttorneySearchFilter);
  };

  return (
    <div className="p-3 h-100 d-flex flex-column">
      <RepresentativeSearch handleSearch={handleSearch} />
      <RepresentativeList
        repData={data.representativeList}
        pageIndex={pageIndex}
        pageCount={pageCount}
        rowCount={rowCount}
        loading={loading}
        registryCode={currentRegistryCode}
        reFetchAndUpdateData={reFetchAndUpdateData}
        title={
          <h3 className="m-0">
            <FormattedMessage
              id="representativeList.title"
              defaultMessage="Esindajate ja volituste nimekiri"
            />
            <FormattedToolTip
              placement={"top-start"}
              target={"representative-info-icon"}
              message={RepresentativeInfoTooltipContent()}
              style={{maxWidth:"500px", backgroundColor:"white", color:"black"}}
            />
            <img
              alt="info-ikoon"
              src={info_icon}
              id="representative-info-icon"
              className="representative-info-icon"
            />
          </h3>
        }
        isPortal
      />
    </div>
  );
};

export default RepresentativeContainer;
