import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import React from "react";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { HealthCareProfessional } from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  employee: HealthCareProfessional | undefined;
  serviceName: string;
}

export const ConfirmRemoveEmployeeModal = ({
  isOpen,
  onClose,
  onSave,
  employee,
  serviceName
}: Props) => {
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  return (
    <ConfirmationModal
      isOpen={isOpen}
      saveButtonId="deleteEmployee"
      onSave={onSave}
      onClose={onClose}
      footerClassName={
        isMobile ? "justify-content-center" : "justify-content-right"
      }
      title={
        <FormattedMessage
          id="confirmRemoveEmployeesModal.header"
          defaultMessage="Kas olete kindel, et soovite töötaja {name} kustutada"
          values={{ name: employee?.firstName + " " + employee?.lastName }}
        />
      }
    >
      <FormattedMessage
        id="confirmRemoveEmployeesModal.warningMessage"
        defaultMessage="Kustutamisel eemaldatakse töötaja teenuse {serviceName} alt."
        values={{ serviceName: serviceName }}
      />
    </ConfirmationModal>
  );
};
