import React from "react";
import { useSelector } from "react-redux";

import { ActivityLicenseServices } from "./ActivityLicenseServices";
import { RootState } from "../../../rootReducer";

interface Props {
  isUpdateActivityLicenseService?: boolean;
}

export const NewActivityLicenseServices = ({ isUpdateActivityLicenseService }: Props) => {
  const services = useSelector((state: RootState) => {
    if (
      state.activityLicenseApplication.locations &&
      state.activityLicenseApplication.locations.length
    ) {
      return state.activityLicenseApplication.locations[0].services || [];
    }
    return [];
  });

  const location = useSelector((state: RootState) => {
    return state.activityLicenseApplication.locations && state.activityLicenseApplication.locations.length ?
      state.activityLicenseApplication.locations[0] :
      undefined;
  })

  return (
    <ActivityLicenseServices
      locationInfoIndex={0}
      services={services}
      isUpdateActivityLicenseService={isUpdateActivityLicenseService}
      location={location}
    />
  );
};
