import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Input } from "reactstrap";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./DatePicker.scss";
import et from "date-fns/locale/et";
import calendar from "../../../assets/images/calendar.svg";

interface Props {
  selectedDate?: Date | string;
  onDateChange: ((selectedDate: Date) => void) | any;
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  filterDate?: (date: Date) => boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  popperPlacement?: string;
  minDate?: Date;
  maxDate?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
  inputWrapperClass?: string;
  inputSize?: "sm" | "lg";
}

const today = new Date();

export const DatePickerComponent = React.forwardRef(
  (
    {
      onDateChange,
      selectedDate,
      valid,
      invalid,
      disabled,
      filterDate,
      name,
      popperPlacement,
      disablePast,
      disableFuture,
      inputWrapperClass,
      inputSize,
      minDate,
      maxDate,
      id,
      placeholder
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    registerLocale("et", et);

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      setInputValue(
        selectedDate ? moment(selectedDate).format("DD.MM.YYYY") : ""
      );
    }, [selectedDate]);

    const validClass = () => (valid ? "is-valid" : invalid ? "is-invalid" : "");

    const handleChange = (newDate: Date) => {
      setOpen(false);
        if (
        newDate &&
        newDate.getHours() + newDate.getTimezoneOffset() / 60 !== 0
      ) {
        newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);
      }
      if (newDate) {
          onDateChange(newDate);
          setInputValue(moment(newDate).format("DD.MM.YYYY"));
      }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      if (isDateValid(event.target.value)) {
        handleChange(moment(event.currentTarget.value, "DD.MM.YYYY").toDate());
      }
    };

    const validateInputContent = () => {
      if (!isDateValid(inputValue)) {
        setInputValue("");
      }
    };

    const isDateValid = (date: string | Date) => {
      const dateString: string =
        date instanceof Date ? moment(date).format("DD.MM.YYYY") : date;

      const dateRegex =
        /^((0)[1-9]|[1-2][0-9]|(3)[0-1])(\.)(((0)[1-9])|((1)[0-2]))(\.)\d{4}$/;
      return dateRegex.test(dateString);
    };

    const datePickerInput = (
      <div className={`input-group ${validClass()} ${open ? "open" : ""}`}>
        <Input
          bsSize={inputSize}
          id={id}
          className={`form-control ${validClass()}`}
          autoComplete="off"
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => (disabled ? setOpen(false) : setOpen(true))}
          onBlur={validateInputContent}
          name={name}
          innerRef={ref}
          placeholder={placeholder}
          disabled={disabled}
        />
        <img
          alt="kalender"
          src={calendar}
          onClick={() => (disabled ? setOpen(false) : setOpen(true))}
        />
      </div>
    );

    const className = (disabled ? " disabled " : " ") + validClass();
    const selected = selectedDate
      ? selectedDate instanceof Date
        ? selectedDate
        : new Date(selectedDate as string)
      : undefined;

    return (
      <DatePicker
        open={open}
        onClickOutside={() => setOpen(false)}
        selected={selected}
        className={`${className} ${inputWrapperClass}`}
        onChange={handleChange}
        showMonthDropdown={true}
        showYearDropdown={true}
        popperPlacement={popperPlacement || "bottom-end"}
        dateFormat="dd.MM.yyyy"
        strictParsing={true}
        locale="et"
        customInput={datePickerInput}
        autoComplete="off"
        disabled={disabled}
        filterDate={filterDate}
        maxDate={disableFuture ? today : maxDate}
        minDate={disablePast ? today : minDate}
      />
    );
  }
);
