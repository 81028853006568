import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

import {
  createServiceName,
  isAmbulanceService,
  isGpService,
  serviceNeedsBeds
} from "../../../../Dto/ActivityLicense/Service";
import { RootState } from "../../../../rootReducer";
import {
  ActivityLicenseBusinessAreaBusinessAreaTypeEnum,
  ActivityLicenseService,
  HealthCareProfessional,
} from "../../../../../api_client/medre_api";
import { renderOverViewRow, renderRow } from "../../Application/OverView/ApplicationOverViewUtil";
import { prettifyBrigadeType } from "../../../../Dto/ActivityLicense/ActivityLicense";

interface Props {
  services?: ActivityLicenseService[];
}

export const ServicesCard = ({ services }: Props) => {
  const isHospital = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication.businessArea?.isHospital
  );
  const isMobileActivityLocation = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication.locations![0].adsAdrID === "mobile"
  );
  const isSpecialistBusinessArea = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication.businessArea?.businessAreaType
        === ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist
  );

  const createContent = () =>
    services
      ? services.map((service) => {
        return renderService(service);
      })
      : "";

  const renderService = (service: ActivityLicenseService) => (
    <>
      <h5 className="fw-normal">{createServiceName(service.service!, true)}</h5>
      {!isSpecialistBusinessArea && isMobileActivityLocation
        ? renderRow(
          "Registrinumber",
          service.mobileActivityLocationRegistryNumber!
        )
        : renderRooms(service)}
      {renderEmployees(service)}
      {serviceNeedsBeds(isHospital, service.service?.type) &&
        renderRow("Voodikohtade arv", service.beds!)}
      {isAmbulanceService(service?.service?.code) &&
          service?.ambulanceBrigades && service?.ambulanceBrigades > 0 ?
        renderOverViewRow("activityLicense", "numberOfAmbulanceBrigades", service?.ambulanceBrigades.toString())
        : renderBrigades(service)
      }
      {isGpService(service.service?.code) &&
        renderRow("Nimistute arv", service.numberOfGpLists)}
    </>
  );

  const isSpecialistWithMatchingOccupation = (service: ActivityLicenseService, employee: HealthCareProfessional): boolean => {
    const requiredOccupationPrefix = service?.service?.requiredOccupations?.[0]?.prefix ?? null;
    
    return employee?.occupationCodes?.some(e => e.prefix === requiredOccupationPrefix) || false;
  };

  const renderRooms = (service: ActivityLicenseService) =>
    service.rooms?.map((serviceRoom) =>
      renderRow(serviceRoom.room?.name, serviceRoom.number)
    );

  const renderEmployees = (service: ActivityLicenseService) =>
    service.employees!.map((employee) => renderEmployeeRow(employee, service));

  const renderBrigades = (service: ActivityLicenseService) =>
    service?.brigades?.map((serviceBrigade) =>
      serviceBrigade?.amount && serviceBrigade?.amount > 0 ? (
        renderOverViewRow("activityLicense", prettifyBrigadeType(serviceBrigade?.brigadeType), serviceBrigade.amount.toString())) : null);

  const renderEmployeeRow = (employee: HealthCareProfessional, service: ActivityLicenseService) => (
    <Row key={employee.id}>
      <Col xs={12} md={4} className="col">
        {employee.firstName + " " + employee.lastName}
      </Col>
      {isSpecialistBusinessArea ? renderSpecialistInfo(service, employee) : renderOccupationInfo(employee) }
    </Row>
  );

  const renderOccupationInfo = (employee: HealthCareProfessional) => (
    <Col>
      {employee.occupationCodes?.map((occupation) => {
        return (
          <div key={occupation.code}>
            {occupation.code + ", " + occupation.name}
          </div>
        );
      })}
      {employee.specialities?.map((speciality) => {
        return <div key={speciality.code}>{speciality.name}</div>;
      })}
    </Col>
  );

  const renderSpecialistInfo = (service: ActivityLicenseService, employee: HealthCareProfessional) => (
    isSpecialistWithMatchingOccupation(service, employee) 
      ? 
      <Col>{employee.occupationCodes
        ?.filter(occupation => occupation.prefix === service.service?.requiredOccupations?.[0]?.prefix)
        .map((occupation) => {
          return (
            <div key={occupation.code}>
              {occupation.code + ", " + occupation.name}
            </div>
          );
        })}</Col> 
      :
      <Col>
        {service.service?.requiredOccupations?.[0]?.name || ""}
      </Col> 
  );

  return (
    <>
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.services"
          defaultMessage="Teenused"
        />
      </h4>
      <Card className="info-card mb-2">{createContent()}</Card>
    </>
  );
};
