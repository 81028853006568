import { FormattedMessage } from "react-intl";
import React from "react";

export const licenseAcquisitionSteps = [
  <FormattedMessage key="activityLicenseStep1" id="activityLicense.businessData" defaultMessage="Ettevõtte andmed"/>,
  <FormattedMessage key="activityLicenseStep2" id="activityLicense.activityLicense" defaultMessage="Tegevusluba"/>,
  <FormattedMessage key="activityLicenseStep4" id="activityLicense.documents" defaultMessage="Dokumendid"/>,
  <FormattedMessage key="activityLicenseStep5" id="activityLicense.overview" defaultMessage="Esita taotlus"/>,
];

export enum LicenseAcquisitionStep {
  BUSINESS_DATA,
  LICENSE_SELECTION,
  DOCUMENTS,
  OVERVIEW
}