import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Input, Label } from "reactstrap";

import "../ApplicantDetails.scss";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { CountrySelect } from "../../../../Component/Select/CountrySelect";
import { Option } from "../../../../Component/Select/SingleSelect";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import { ApplicantChanges } from "../../../../Dto/Applicant/ApplicantChanges";
import { doNothing } from "../../../../Util/MethodUtils";
import { createCountryOption } from "../../../../Util/OptionUtil";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { GenderSelect } from "../../../../Component/Select/GenderSelect";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import {
  PersonGenderEnum,
  OfficialUserPrivilegesEnum,
  PersonalData,
  PersonStatusEnum
} from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { dateToString } from "../../../../Util/DateUtils";
import { PersonalDetailModalType } from "./PersonalDetailModal/types";
import { PersonalDetailModal } from "./PersonalDetailModal/PersonalDetailModal";
import { Props, FormValidity } from "./types";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { Loader } from "../../../../Component/Loader/Loader";

export const ApplicantPersonalDetails = ({
  person,
  setApplicantChanges,
  applicantChanges,
  forceValidation,
  reFetchAndUpdatePersonData,
  handleRrRequest,
  loading
}: Props) => {
  const personalData: PersonalData =
    applicantChanges.personalData || ({ ...person } as PersonalData);
  const countryCode = person.foreigner ? person.foreignIdCodeCountryId : "EST";

  const [formValidity, setFormValidity] = useState<FormValidity>({});
  const [personalDetailModal, setPersonalDetailModal] =
    useState<PersonalDetailModalType>({ data: {}, isOpen: false });

  useEffect(() => {
    if (!applicantChanges.personalData) {
      setFormValidity({});
    }
  }, [applicantChanges.personalData]);

  useEffect(() => {
    if (
      !forceValidation ||
      personalData === undefined ||
      Object.keys(personalData).length === 0
    ) {
      return;
    }
    if (!personalData?.firstName) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, firstNameValid: false };
      });
    }
    if (!personalData?.lastName) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, lastNameValid: false };
      });
    }
    if (!personalData.dateOfBirth) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, dateOfBirthValid: false };
      });
    }
    if (!personalData.gender) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, genderValid: false };
      });
    }
    if (!personalData.citizenship) {
      setFormValidity((prevState: FormValidity) => {
        return { ...prevState, citizenshipValid: false };
      });
    }
  }, [forceValidation, personalData]);

  const handleCitizenshipChange = (option: Option) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        personalData: { ...personalData, citizenship: option.value }
      };
    });
  };

  const handleDobChange = (newDate?: Date) => {
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        personalData: { ...personalData, dateOfBirth: dateToString(newDate) }
      };
    });
  };

  const handleGenderOptionChange = (option: Option) => {
    const value =
      option.value === "MALE" ? PersonGenderEnum.Male : PersonGenderEnum.Female;
    setApplicantChanges((prevState: ApplicantChanges) => {
      return { ...prevState, personalData: { ...personalData, gender: value } };
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setApplicantChanges((prevState: ApplicantChanges) => {
      return {
        ...prevState,
        personalData: { ...personalData, [fieldName]: fieldValue }
      };
    });
    setFormValidity((prevState: FormValidity) => {
      return { ...prevState, [fieldName + "Valid"]: Boolean(fieldValue) };
    });
  };

  const handlePersonalDetailModalOpen = () => {
    setPersonalDetailModal({
      data: { ...personalData, foreignIdCodeCountryId: countryCode },
      isOpen: true
    });
  };

  const handlePersonalDetailModalClose = () => {
    setPersonalDetailModal({ isOpen: false });
  };

  const handleUpdatePersonData = () => {
    handlePersonalDetailModalClose();
    reFetchAndUpdatePersonData();
  };

  const button = useMemo(
    () =>
      !person.idCode &&
      !person.foreignIdCode &&
      !person.foreignIdCodeCountryId ? (
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <PrimaryFormattedButton
            id="change"
            onClick={handlePersonalDetailModalOpen}
          />
        </ProtectedFieldWrapper>
      ) : person.idCode ||
        (!person.idCode &&
          person.foreignIdCode &&
          person.foreignIdCodeCountryId) ? (
        <ProtectedComponent officialsOnly={true}>
          <PrimaryFormattedButton id="rrRequest" onClick={handleRrRequest} />
        </ProtectedComponent>
      ) : null,
    [person.foreignIdCode, person.foreignIdCodeCountryId, person.idCode]
  );

  return (
    <>
      {loading && <Loader absolute={true} backdrop={true} />}
      <ApplicantDetailsSection
        section={ApplicantDetailsSectionKeys.PersonalData}
        button={button}
      >
        <div className="applicant-details-container">
          <FormGroup className="form-inline">
            <Label>Eesnimi</Label>
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              <Input
                name="firstName"
                value={personalData.firstName}
                disabled={true}
                invalid={formValidity?.firstNameValid === false}
                onChange={(e) => handleInputChange(inputRegex(e))}
              />
            </ProtectedFieldWrapper>
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Perenimi</Label>
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              <Input
                name="lastName"
                value={personalData.lastName}
                disabled={true}
                invalid={formValidity?.lastNameValid === false}
                onChange={(e) => handleInputChange(inputRegex(e))}
              />
            </ProtectedFieldWrapper>
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Eesti isikukood</Label>
            <Input value={personalData.idCode} disabled={true} />
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Isikukoodi riik</Label>
            <CountrySelect
              className="personal-code-country-select"
              defaultSelectedValue={countryCode}
              hideSearchIcon={true}
              handleOptionChange={doNothing}
              disabled={true}
            />
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Välismaa isikukood</Label>
            <Input
              value={person.foreignIdCode ? person.foreignIdCode : "-"}
              disabled={true}
            />
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>
              {personalData.status === PersonStatusEnum.Archived
                ? "Surmakuupäev"
                : "Sünniaeg"}
            </Label>
            <div>
              <ProtectedFieldWrapper
                allowedRoles={[
                  OfficialUserPrivilegesEnum.Sign,
                  OfficialUserPrivilegesEnum.Proceede
                ]}
              >
                <DatePickerComponent
                  selectedDate={
                    personalData.status === PersonStatusEnum.Archived
                      ? person.dateOfDeath
                      : personalData.dateOfBirth
                  }
                  onDateChange={handleDobChange}
                  disabled={true}
                />
              </ProtectedFieldWrapper>
            </div>
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Sugu</Label>
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              <GenderSelect
                handleOptionChange={handleGenderOptionChange}
                selectedGender={personalData.gender}
                disabled={true}
                className="input"
              />
            </ProtectedFieldWrapper>
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Kodakondsus</Label>
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              <CountrySelect
                value={createCountryOption(personalData.citizenship)}
                disabled={true}
                hideSearchIcon={true}
                handleOptionChange={handleCitizenshipChange}
              />
            </ProtectedFieldWrapper>
          </FormGroup>
          <FormGroup className="form-inline">
            <Label>Isiku olek</Label>
            <Input
              value={
                personalData.status === PersonStatusEnum.Archived
                  ? "Arhiveeritud"
                  : person.foreigner
                  ? "Välismaalane"
                  : "EE elanik"
              }
              disabled={true}
            />
          </FormGroup>
        </div>
      </ApplicantDetailsSection>
      {personalDetailModal.isOpen && (
        <PersonalDetailModal
          modal={personalDetailModal}
          onClose={handlePersonalDetailModalClose}
          countryCode={countryCode}
          reFetchAndUpdateData={handleUpdatePersonData}
        />
      )}
    </>
  );
};
