import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const CancelModificationGPModal = ({ isOpen, onClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader tag="h4">
        <FormattedMessage
          id="cancelModificationGPModal.header"
          defaultMessage="Kas olete kindel, et soovite tulevikus tööle asuva perearsti töötamise eemaldada?"
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="cancelModificationGPModal.description"
          defaultMessage="Teostatava muudatusega eemaldatakse planeeritud perearsti töötamine."
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={ onClose }
        />
        <PrimaryFormattedButton
          id="confirm"
          onClick={ onConfirm }
        />
      </ModalFooter>
    </Modal>
  )
}