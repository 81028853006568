import React, { useMemo } from "react";
import { Card } from "reactstrap";
import {
  ReactTable,
  ReactTableColumn,
  ReactTableExpander
} from "../../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { useMobile } from "../../../../Hook/useMobile";
import { useGPListTable } from "../useGPListTable";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";
import useModal from "../../../../Hook/useModal";
import { PortalConsultationTimeModal } from "../../../Portal/GeneralPractitioners/Modals/ConsultationTime";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { useAuthorized, usePortal } from "../../../../Hook/usePortal";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { getFullName } from "../../../../Util/PersonUtils";
import { useWorkingDays } from "../../../../Util/DateUtils";
import { EmployeeRoleFM } from "../../../../Messages/EmployeeFM";
import { useGeneralPractitionerListDeletionsSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import classNames from "classnames";
import { GPListDeleteRowButton } from "./GPListDeleteRowButton";
import { GPListModifyRowButton } from "./GPListModifyRowButton";
import { GeneralPractitionerListConsultation } from "../../../../../api_client/medre_api";
import { LinkToPublicPortal } from "../LinkToPublicPortal";

const headers = {
  fullName: (
    <FormattedMessage
      id="GPListDetailedTableHeader.fullName"
      defaultMessage="Nimi"
    />
  ),
  role: (
    <FormattedMessage
      id="GPListDetailedTableHeader.role"
      defaultMessage="Roll"
    />
  ),
  workingDays: (
    <FormattedMessage
      id="GPListDetailedTableHeader.days"
      defaultMessage="Päev"
    />
  ),
  workingHours: (
    <FormattedMessage
      id="GPListDetailedTableHeader.times"
      defaultMessage="Kellaaeg"
    />
  ),
  phone: (
    <FormattedMessage
      id="GPListDetailedTableHeader.phone"
      defaultMessage="Telefon"
    />
  )
};

export const GPListConsultationTimes = () => {
  const isMobile = useMobile();
  const isPortal = usePortal();
  const isAuthorized = useAuthorized();
  const {
    list: { employees, consultations }
  } = useGeneralPractitionerList();
  const workingDays = useWorkingDays();
  const {
    employees: selectedEmployees = [],
    consultations: selectedConsultations = []
  } = useGeneralPractitionerListDeletionsSelector();
  const columns: ReactTableColumn<GeneralPractitionerListConsultation>[] =
    useMemo(() => {
      const baseColumns: ReactTableColumn<GeneralPractitionerListConsultation>[] =
        [
          {
            collapse: isMobile,
            Header: headers.workingDays,
            accessor: "workingDays",
            Cell: ({ value }) => {
              const days = value.map((day) => workingDays[day].min).join(", ");
              return isMobile ? (
                <>
                  <div className="font-size-14">{headers.workingDays}</div>
                  <span className="font-weight-500">{days}</span>
                </>
              ) : (
                days
              );
            },
            width: 50
          },
          {
            collapse: isMobile,
            Header: headers.workingHours,
            id: "workingHours",
            accessor: ({ workingFrom, workingTo }) => {
              const hours = `${workingFrom} - ${workingTo}`;
              return isMobile ? (
                <>
                  <div className="font-size-14">{headers.workingHours}</div>
                  <span className="font-weight-500">{hours}</span>
                </>
              ) : (
                hours
              );
            },
            width: 50
          },
          {
            Header: headers.fullName,
            accessor: "employeeId",
            Cell: (cell) => {
              const employee = employees.find(
                (employeeItem) => employeeItem.id === cell.value
              );
              if (!employee) {
                return null;
              }
              const fullName = (
                <LinkToPublicPortal to={`/employees/${employee.personId}`}>
                  {employee.occupationCode.code}&nbsp;-&nbsp;
                  {getFullName(employee)}
                </LinkToPublicPortal>
              );
              return isMobile ? (
                <ReactTableExpander cell={cell}>
                  <div className="font-size-14">{fullName}</div>
                  <span className="font-weight-500">
                    <EmployeeRoleFM role={employee.role} />
                  </span>
                </ReactTableExpander>
              ) : (
                fullName
              );
            },
            width: 50
          },
          {
            collapse: isMobile,
            Header: headers.phone,
            accessor: "phone",
            Cell: ({ value }) =>
              isMobile ? (
                <>
                  <div className="font-size-14">{headers.phone}</div>
                  <span className="font-weight-500">{value}</span>
                </>
              ) : (
                value
              ),
            width: 0
          }
        ];

      if (!isMobile) {
        baseColumns.splice(3, 0, {
          Header: headers.role,
          id: "role",
          accessor: "employeeId",
          Cell: ({ cell }) => {
            const employee = employees.find(
              (employeeItem) => employeeItem.id === cell.value
            );
            if (!employee && !cell.row.original.role) {
              return null;
            }
            return (
              <EmployeeRoleFM role={employee?.role ?? cell.row.original.role} />
            );
          },
          width: 50
        });
      }

      if (isPortal && isAuthorized) {
        baseColumns.push({
          collapse: isMobile,
          accessor: "employeeId",
          id: "actions",
          sticky: !isMobile,
          Cell: (cell) => (
            <>
              <GPListModifyRowButton index={cell.row.index}>
                {(modal) => (
                  <PortalConsultationTimeModal
                    defaultValues={cell.row.original}
                    open={modal.isOpen}
                    onClose={modal.handleClose}
                  />
                )}
              </GPListModifyRowButton>
              <GPListDeleteRowButton
                rowId={cell.row.original.id as string}
                candidate="consultations"
                disabled={Boolean(
                  selectedEmployees.includes(cell.value as string)
                )}
              />
            </>
          ),
          disableSortBy: true,
          width: 0
        });
      }

      return baseColumns;
    }, [
      selectedEmployees,
      isMobile,
      employees,
      workingDays,
      isPortal,
      isAuthorized
    ]);

  const table = useGPListTable<GeneralPractitionerListConsultation>({
    data: consultations,
    columns
  });

  const consultationTimeModal = useModal();

  return (
    <Card className="my-4 pb-1 p-0 p-md-4">
      {consultationTimeModal.isOpen && (
        <PortalConsultationTimeModal
          open={true}
          onClose={consultationTimeModal.handleClose}
        />
      )}

      <div
        id={GENERAL_PRACTITIONERS_DETAILS_SECTIONS.CONSULTATION_TIMES.id}
        className="h2 d-flex justify-content-between pt-3 pr-3 pl-3 p-md-0"
      >
        <h2>
          {GENERAL_PRACTITIONERS_DETAILS_SECTIONS.CONSULTATION_TIMES.title}
        </h2>

        {isPortal && isAuthorized && (
          <div className="d-none d-sm-block">
            <PrimaryFormattedButton
              id="addAppointmentTime"
              idPrefix="sm"
              onClick={consultationTimeModal.handleOpen}
            />
          </div>
        )}
      </div>
      {isPortal && isAuthorized && (
        <div className="d-block d-sm-none pl-3">
          <PrimaryFormattedButton
            id="addAppointmentTime"
            idPrefix="xs"
            onClick={consultationTimeModal.handleOpen}
          />
        </div>
      )}

      <div className="mt-3">
        <ReactTable<GeneralPractitionerListConsultation>
          table={table}
          getHeaderProps={(column) =>
            column.getHeaderProps(column.getSortByToggleProps())
          }
          rows={table.rows}
          getRowProps={(row) =>
            row.getRowProps({
              className: classNames({
                danger:
                  selectedEmployees.includes(row.values.employeeId) ||
                  selectedConsultations.includes(row.id)
              })
            })
          }
        />
      </div>
    </Card>
  );
};
