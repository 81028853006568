import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import terms from "../../../assets/images/termsLink-white.svg";
import { LocaleMessage } from "../../Locale/LocaleMessage";

interface Props {
  id: string;
  url: string;
  icon?: string;
}

type LinkName =
  | "agency"
  | "vaccinate"
  | "news"
  | "facebook"
  | "youtube"
  | "userAgreement";

export const Link = ({ id, url, icon }: Props) => {
  const links: Record<LinkName, LocaleMessage> = defineMessages({
    agency: {
      id: "quickLinks.agency",
      defaultMessage: "<a>Terviseamet</a>"
    },
    vaccinate: {
      id: "quickLinks.vaccinate",
      defaultMessage: "<a>Vaktsineeri.ee</a>"
    },
    news: {
      id: "quickLinks.news",
      defaultMessage: "<a>Uudised</a>"
    },
    userManual: {
      id: "quickLinks.userManual",
      defaultMessage: "<a>Iseteeninduse kasutusjuhendid</a>"
    },
    facebook: {
      id: "footerBottom.facebook",
      defaultMessage: "<a>Facebook</a>"
    },
    youtube: {
      id: "footerBottom.youtube",
      defaultMessage: "<a>Youtube</a>"
    },
    userAgreement: {
      id: "footerBottom.news",
      defaultMessage: "<a>Kasutustingimused</a>"
    },
    authorizationTypesDescription: {
      id: "authorizationTypes.description",
      defaultMessage: "<a>Volituse tüüpide kirjeldused</a>"
    }
  });

  return (
    <>
      <FormattedMessage
        {...links[id as LinkName]}
        values={{
          a: (msg: string) => (
            <a
              className="link"
              rel="noopener noreferrer"
              href={url}
              target={"_blank"}
            >
              {msg}
            </a>
          )
        }}
      />
      <img src={icon ?? terms} alt="väline link" className="ml-2" />
    </>
  );
};
