import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Label } from "reactstrap";

import { SingleSelect } from "../../../../../Component/Select/SingleSelect";
import { Option } from "../../../../../Component/Select/SelectTypes";
import { RemoveButton } from "../../../../../Component/Button/RemoveButton";
import { RoomNumberInput } from "./RoomNumberInput";
import { Room, ServiceRoom } from "../../../../../../api_client/medre_api";
import { ServiceRoomDraft } from "../../../../../Dto/ActivityLicense/Room";

interface Props {
  serviceRooms: ServiceRoom[];
  serviceRoomOptions: (ServiceRoom | ServiceRoomDraft)[];
  selectedServiceRoom: ServiceRoom | ServiceRoomDraft;
  setSelectedServiceRoom: (serviceRoom: ServiceRoom | ServiceRoomDraft) => void;
  handleDelete: () => void;
  cardNumber: number;
}

export const OptionalRoomInfoCard = ({
  serviceRooms,
  serviceRoomOptions,
  cardNumber,
  selectedServiceRoom,
  setSelectedServiceRoom,
  handleDelete
}: Props) => {
  const createOption = (room: Room | Partial<Room>): Option => {
    return { label: room.name || "", value: room.id || "" };
  };

  const optionToServiceRoom = (
    option: Option
  ): ServiceRoom | ServiceRoomDraft =>
    serviceRoomOptions.find(
      (serviceRoom) => serviceRoom.room?.id === option.value
    ) || { room: { isOptional: true } };

  const handleOptionChange = (option: Option): void => {
    setSelectedServiceRoom(optionToServiceRoom(option));
  };

  const updateNumber = (value: string): void => {
    const selectedRoom = selectedServiceRoom;
    if (selectedRoom) {
      selectedRoom.number = value;
      setSelectedServiceRoom(selectedRoom);
    }
  };

  const getServiceRoomOptions = (): Option[] => {
    const roomOptions: Option[] = [];
    serviceRoomOptions.forEach((serviceRoom) => {
      if (serviceRoom.room.id) {
        roomOptions.push(createOption(serviceRoom.room));
      }
    });

    return roomOptions;
  };

  const verifyServiceRoomRequirements = (): boolean => {
    const { requiredRooms } = selectedServiceRoom.room;

    if (!requiredRooms || requiredRooms.length === 0) {
      return false;
    }

    return requiredRooms.every(requiredRoom =>
      serviceRooms.some(serviceRoom => serviceRoom.room.name === requiredRoom.name));
  };

  return (
    <>
      <div className="title-container">
        <h5>
          <FormattedMessage
            id="addRoomModal.optionalRoom"
            defaultMessage="Valikuline ruum"
          />{ " " }
          { cardNumber }
        </h5>
        <RemoveButton handleDelete={handleDelete} />
      </div>

      { verifyServiceRoomRequirements() &&
          <>
            <p>
              <FormattedMessage
                id="addRoomsModal.requiredRoomInstruction"
                defaultMessage="Ruumile on kohustuslik lisada üks allolevatest valikulistest ruumidest:"
              />
            </p>
            <div className="room-name-wrapper">
              { selectedServiceRoom?.room.requiredRooms?.map((requiredRoom) => (
                <div key={requiredRoom.id} className="room-name">
                  { requiredRoom.name }
                </div>
              )) }
            </div>
          </>
      }

      <Card className="info-card">
        <Label htmlFor={`roomInfoCardRoomTypeOptional_${ cardNumber }`}>
          <FormattedMessage
            id="addRoomModal.roomType"
            defaultMessage="Ruumi liik"
          />
        </Label>
        <SingleSelect
          inputId={`roomInfoCardRoomTypeOptional_${ cardNumber }`}
          disabled={false}
          value={
            selectedServiceRoom?.room
              ? createOption(selectedServiceRoom.room)
              : undefined
          }
          options={getServiceRoomOptions()}
          handleOptionChange={handleOptionChange}
        />
        <Label htmlFor={`roomInfoCardRoomNumberOptional_${ cardNumber }`}>
          <FormattedMessage
            id="addRoomModal.roomNumber"
            defaultMessage="Ruumi number"
          />
        </Label>

        <RoomNumberInput
          id={`roomInfoCardRoomNumberOptional_${ cardNumber }`}
          isDisabled={selectedServiceRoom === undefined}
          currentValue={selectedServiceRoom?.number}
          setRoomNumber={updateNumber}
        />
      </Card>
    </>
  );
};
