import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../../../../api";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { displayAlert } from "../../../../../../Util/AlertUtil";
import { SuspensionActionsContent } from "../../SuspensionActionsContent";
import {
  DetailedServiceSuspension,
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI,
  OfficialUser,
  ActivityLicenseSuspensionStatus,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityAppAPI
} from "../../../../../../../api_client/medre_api";
import { AlertMessage } from "../../../../../../Alert/AlertMessage";
import { alertActions } from "../../../../../../Alert/alertActions";

interface Props {
  details?: DetailedServiceSuspension;
  setDetails: (serviceSuspension: DetailedServiceSuspension) => void;
  refresh: () => void;
  setIsLoading: (value: boolean) => void;
}

export const ServiceSuspensionDetailsActions = ({
  details,
  setDetails,
  refresh,
  setIsLoading
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleForwardToOfficial = (official: OfficialUser) => {
    setIsLoading(true);
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .forwardServiceSuspensionToOfficial(details!.id!, official, {
        withCredentials: true
      })
      .then((res) => {
        setDetails(res.data);
        displayAlert(
          "applicationForwardingSuccess",
          AlertType.Success,
          dispatch
        );
      })
      .catch(() => {
        displayAlert(
          "applicationForwardingFailure",
          AlertType.Success,
          dispatch
        );
      })
      .finally(() => setIsLoading(false));
  };

  const changeStatusTo = (
    newStatus:
      | ActivityLicenseApplicationStatus
      | ActivityLicenseSuspensionStatus
  ) => {
    setIsLoading(true);
    officialActivityPermitAPI(undefined, getBaseUrl(), API)
      .changeServiceSuspensionStatus(
        details!.id!,
        newStatus as ActivityLicenseSuspensionStatus,
        {
          withCredentials: true
        }
      )
      .then((res) => {
        setDetails(res.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
      })
      .catch(() =>
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch)
      )
      .finally(() => setIsLoading(false));
  };

  const deleteApplication = (applicationId: string) => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .deleteApplicationByProceedingType(
        applicationId,
        details?.suspensionType!,
        {
          withCredentials: true
        }
      )
      .then(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteSuccess" />
        );
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        history.push("/my-proceedings");
      })
      .catch(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteFailure" />
        );
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  return (
    <SuspensionActionsContent
      changeStatusTo={changeStatusTo}
      refresh={refresh}
      handleForwardToOfficial={handleForwardToOfficial}
      proceedingType={
        ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension
      }
      details={details}
      deleteApplication={deleteApplication}
    />
  );
};
