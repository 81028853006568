import React, { useContext, useMemo } from "react";
import { getCurrentStatus } from "../../../Dto/Application/Application";
import { ApplicationState } from "../../../Application/ApplicationState";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { ApplicationCertificateDraft } from "../../../Dto/ApplicationCertificate/ApplicationCertificate";
import ApplicantDetailsSection from "./ApplicantDetailsSection";
import { ApplicantDetailsSectionKeys } from "./ApplicantNavigationList";
import { ProtectedFieldWrapper } from "../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { ApplicationsContext } from "../../Shared/Application/ApplicationsContext";
import { ApplicationFM } from "../../../Messages/ApplicationFM";
import {
  ApplicationDraft,
  Application,
  DetailedApplicationTypeEnum,
  OfficialUserPrivilegesEnum
} from "../../../../api_client/medre_api";
import "../../Shared/Search/ResultsTable/ResultsTable.scss";

interface Props {
  onCreateNewApplication: () => void;
}

export const ApplicantApplications = ({ onCreateNewApplication }: Props) => {
  const { savedApplications, submittedApplications } =
    useContext(ApplicationsContext).applications;
  const getHeaders = () => {
    return [
      <th key="h-1" className="normal-column pl-4" role="columnheader">
        Kuupäev
      </th>,
      <th key="h-2" className="normal-column" role="columnheader">
        Number
      </th>,
      <th key="h-3" className="status-column" role="columnheader">
        Olek
      </th>,
      <th key="h-4" className="normal-column" role="columnheader">
        <ApplicationFM id="applicationOccupationOrSpeciality" />
      </th>,
      <th key="h-5" />
    ];
  };

  const getName = (
    application: ApplicationCertificateDraft | ApplicationDraft | Application
  ) => {
    switch (application.type) {
      case DetailedApplicationTypeEnum.Occupation:
        return application.occupation?.name
          ? `${application.occupation.name}${
              application.additionalOccupation?.name
                ? " ja " +
                  (application.additionalOccupation?.name || "").toLowerCase()
                : ""
            }`
          : "";
      case DetailedApplicationTypeEnum.Specialization:
        return application.speciality?.name;
      case DetailedApplicationTypeEnum.ApplicationCertificate:
        return (application as ApplicationCertificateDraft)?.title;
      default:
        return "";
    }
  };

  const getAddress = (application: ApplicationDraft | Application) => {
    let baseUrl = "/applications";
    if (
      application.type === DetailedApplicationTypeEnum.ApplicationCertificate
    ) {
      baseUrl = `${baseUrl}/certificate`;
    }
    const { currentStatus } = application as Application;
    if (!currentStatus) {
      baseUrl = `${baseUrl}/drafts`;
    }
    return `${baseUrl}/${application.id}`;
  };
  const getSavedApplications = () =>
    savedApplications?.map((savedApplication, index) =>
      getRow(
        savedApplication as ApplicationDraft,
        getAddress(savedApplication as ApplicationDraft),
        index
      )
    );

  const getSubmittedApplications = () => {
    return submittedApplications?.map(
      (submittedApplication: Application, index) => {
        return getRow(
          submittedApplication,
          getAddress(submittedApplication),
          index
        );
      }
    );
  };

  const getRow = (
    application: Application | ApplicationDraft,
    address: string,
    i: number
  ) => {
    let arrivalTime = "-";
    if ("arrivalTime" in application && application.arrivalTime) {
      arrivalTime = moment(application.arrivalTime).format("DD.MM.YYYY");
    }
    const currentStatus = getCurrentStatus(application);

    return (
      <tr role="row" key={i}>
        <td className="normal-column short-column pl-4">{arrivalTime}</td>
        <td className="normal-column short-column">
          {application.applicationNumber}
        </td>
        <td className="normal-column large-column">
          <ApplicationState
            application={{
              currentStatus: currentStatus,
              stateFee:
                "stateFee" in application ? application?.stateFee : undefined
            }}
          />
        </td>
        <td className="normal-column speciality-column">
          {getName(application)}
        </td>
        <td className="normal-column">
          <NavLink to={address} activeClassName="item-active">
            Taotluse detailid
          </NavLink>
        </td>
      </tr>
    );
  };

  const button = useMemo(
    () => (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="newApplication"
          onClick={onCreateNewApplication}
        />
      </ProtectedFieldWrapper>
    ),
    [onCreateNewApplication]
  );

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.Applications}
      button={button}
    >
      <div className="search-application mb-5">
        <div className="table-wrap">
          {(submittedApplications?.length !== 0 ||
            savedApplications?.length !== 0) && (
            <table role="table" className="s-table user-applications-table">
              <thead>
                <tr role="row">{getHeaders()}</tr>
              </thead>
              <tbody className="table-result">
                {getSavedApplications()}
                {getSubmittedApplications()}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </ApplicantDetailsSection>
  );
};
