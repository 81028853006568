import { Col, Row } from "reactstrap";
import React from "react";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../../../Constants";
import { ActivityLicenseDownloadButton } from "../../../Shared/ActivityLicense/ActivityLicenseDownloadButton";
import { ActivityLicenseRowHeaderStatusEnum } from "../../../../../api_client/medre_api";
import { ActivityLicenseState } from "../../../Shared/ActivityLicense/ActivityLicenseState";

export const renderActivityLicenseRow = (
  widthSize: number,
  icon: JSX.Element,
  status: ActivityLicenseRowHeaderStatusEnum,
  createdAt: Date,
  businessAreas: string,
  licenseNumber: string,
  id: string,
  isPharmacyLicense: boolean
) => {
  const downloadButton = !isPharmacyLicense &&
    status !== ActivityLicenseRowHeaderStatusEnum.Invalid && (
      <ActivityLicenseDownloadButton id={id} />
    );
  if (widthSize > TABLET_MAX_WIDTH) {
    return (
      <>
        <td style={{ minWidth: 40 }} className="text-center">
          {icon}
        </td>
        <td style={{ minWidth: 100 }}>{formatDate(createdAt)}</td>
        <td>{businessAreas}</td>
        <td style={{ minWidth: 200 }}>
          <ActivityLicenseState status={status} />
        </td>
        <td style={{ minWidth: 65 }}>{licenseNumber}</td>
        <td>{downloadButton}</td>
      </>
    );
  } else if (widthSize > MOBILE_MAX_WIDTH) {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" md={1}>
              {icon}
            </Col>
            <Col md={3}>
              <Row className="fs-14 lh-150">
                <GeneralFM id="date" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{formatDate(createdAt)}</Row>
            </Col>
            <Col md={3}>
              <Row className="fs-14 lh-150">
                <ActivityLicenseFM id="businessArea" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{businessAreas}</Row>
            </Col>
            <Col md={2}>
              <Row>
                <ActivityLicenseState status={status} />
              </Row>
            </Col>
            <Col md={3}>
              <Row>{downloadButton}</Row>
            </Col>
          </Row>
        </td>
      </>
    );
  } else {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" xs="1">
              {icon}
            </Col>
            <Col>
              <Row className="my-2 fw-500 fs-16 lh-150">
                {formatDate(createdAt)}
              </Row>
              <Row className="my-2 fs-14 lh-150">
                <ActivityLicenseFM id="businessArea" />
              </Row>
              <Row className="my-2 fw-500 fs-16 lh-150">{businessAreas}</Row>
              <Row className="my-2 ">
                <ActivityLicenseState status={status} />
              </Row>
              <Row className="my-2">{downloadButton}</Row>
            </Col>
          </Row>
        </td>
      </>
    );
  }
};
