import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { OfficialForwardingModal } from "./OfficialForwardingModal";
import {
  ActivityLicenseApplicationStatus,
  OfficialUserPrivilegesEnum,
  OfficialUser
} from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import {
  ApplicationDeleteConfirmationModal
} from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";

interface Props {
  handleForwardToOfficial: (official: OfficialUser) => void;
  changeStatusTo?: (newStatus: ActivityLicenseApplicationStatus) => void;
  newStatus?: ActivityLicenseApplicationStatus;
  canFinish?: boolean;
  applicationId?: string;
  applicationNumber?: number;
  deleteApplication?: (applicationId: string) => void;
}

export const SubmittedActions = ({
  handleForwardToOfficial,
  changeStatusTo,
  newStatus,
  canFinish = true,
  applicationId,
  applicationNumber,
  deleteApplication
}: Props) => {
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAppDelete = () => {
    deleteApplication!(applicationId!);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}>
        <PrimaryFormattedButton
          id="forwardToOfficial"
          className={ActionButtonClasses}
          onClick={() => setIsOfficialForwardingModalOpen(true)}
        />
      </ProtectedComponent>
      <ProtectedFieldWrapper allowedRoles={[OfficialUserPrivilegesEnum.Sign]}>
        {canFinish && changeStatusTo && newStatus && (
          <SecondaryFormattedButton
            id="finishProceeding"
            className={ActionButtonClasses}
            onClick={() => changeStatusTo(newStatus)}
          />
        )}
      </ProtectedFieldWrapper>
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}>
        {deleteApplication && applicationId && (
          <DangerFormattedButton
            id="deleteApplication"
            className={ActionButtonClasses}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        )}
      </ProtectedComponent>
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </>
  );
};