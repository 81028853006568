import { FormattedButton, FormattedButtonProps } from "./FormattedButton";
import React from "react";

export const SuccessFormattedButton = ({
  id,
  type,
  className,
  disabled,
  onClick
}: FormattedButtonProps) => {
  return (
    <FormattedButton
      id={id}
      type={type}
      color="success"
      disabled={disabled}
      className={className + " btn-success"}
      onClick={onClick}
    />
  );
};