import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ApplicationCertificateTypeEnum
} from "../../../api_client/medre_api";

export const formatProceedingType = (
  proceedingType: ActivityLicenseApplicationProceedingTypeEnum
) => {
  switch (proceedingType) {
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense:
    default:
      return "Uus tegevusluba";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation:
      return "Tegevusloa kehtetuks tunnistamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation:
      return "Tegevuskoha kehtetuks tunnistamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
      return "Tegevusloa peatamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
      return "Tegevuskoha peatamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
      return "Teenuse peatamine";
    case ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition:
      return "TL omaniku muutmine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation:
      return "Tegevuskoha lisamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard:
      return "Teenusest loobumine";
    case ActivityLicenseApplicationProceedingTypeEnum.Service:
      return "Teenuse lisamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration:
      return "Tegevusloa taastamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration:
      return "Tegevuskoha taastamine";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration:
      return "Teenuse taastamine";
    case ActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication:
      return "Perearsti nimistute arvu muutmine";
  }
};

export const formatTHTProceedingType = (proceedingType: ApplicationCertificateTypeEnum): string => {
  switch(proceedingType){
    case ApplicationCertificateTypeEnum.Occupation:
      return "Kutsetaotlus";
    case ApplicationCertificateTypeEnum.Specialization:
      return "Eriala taotlus";
    case ApplicationCertificateTypeEnum.ApplicationCertificate:
      return "Tõendi taotlus";
    default:
      return "";
  }
};
