import React, { useState } from "react";
import moment from "moment";
import { Row } from "reactstrap";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SuspensionCancellationConfirmationModal } from "./SuspensionCancellationConfirmationModal";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { handleFileDownload } from "../../../../Util/FileUtils";
import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { SigningModal } from "../../../../Signing/SigningModal";
import { DecisionActions } from "../../ActivityLicense/Application/Details/Decision/DecisionActions";
import {
  FileObjectType,
  PersonSuspension,
  OfficialUserPrivilegesEnum,
  KasutajaTegevusigusePeatamiseTeenusApiFactory as userActivityRightAPI,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  DecisionType
} from "../../../../../api_client/medre_api";

interface Props {
  suspension: PersonSuspension;
  handleSuspensionDeletion: () => void;
  refresh: () => void;
}

export const SuspensionInfo = ({
  suspension,
  handleSuspensionDeletion,
  refresh
}: Props) => {
  const dispatch = useDispatch();
  const {
    id,
    waitingForSignature,
    fileReference,
    courtDecisionNumber,
    courtName,
    startDate,
    endDate,
    decisionId
  } = suspension;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);

  const getHeaderText = (): string =>
    waitingForSignature
      ? `Otsus '${fileReference?.fileName}' peatamisele suunatud ja allkirjastamise ootel`
      : `Otsus '${fileReference?.fileName}' allkirjastatud`;

  const renderHeader = (): JSX.Element => {
    return (
      <>
        <h3 className="mt-4">{getHeaderText()}</h3>
        <LinkButton
          id={
            waitingForSignature ? "downloadDecision" : "downloadSignedDecision"
          }
          className="p-0"
          onClick={() =>
            handleFileDownload(
              fileReference?.fileName,
              fileReference?.minioFileName
            )
          }
        />
      </>
    );
  };

  const handleCancellationConfirmation = () => {
    return userActivityRightAPI(undefined, getBaseUrl(), API)
      .deleteSuspension(suspension.id as string, {
        withCredentials: true
      })
      .then(() => {
        const alertMessage = <AlertMessage id="decisionDeleteSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        handleSuspensionDeletion();
        setIsConfirmationModalOpen(false);
        return Promise.resolve();
      })
      .catch((error) => {
        const alertMessage = <AlertMessage id="decisionDeleteFailure" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error);
      });
  };

  const handleSigningSuccess = (): void => {
    setIsSignModalOpen(false);
    refresh();
  };

  return (
    <>
      {fileReference && renderHeader()}
      <dl>
        <dt>Kohtu nimi</dt>
        <dd className="text-capitalize">{courtName || "-"}</dd>
        <dt>Kohtuotsuse number</dt>
        <dd className="text-uppercase">{courtDecisionNumber || "-"}</dd>
        <dt>Peatamise algus</dt>
        <dd>{startDate ? moment(startDate).format("DD.MM.YYYY") : ""}</dd>
        <dt>Peatamise lõpp</dt>
        <dd>{endDate ? moment(endDate).format("DD.MM.YYYY") : ""}</dd>
      </dl>
      {waitingForSignature && fileReference && (
        <>
          <Row className="mx-0">
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <SecondaryFormattedButton
                id="revertSendForSigning"
                onClick={() => setIsConfirmationModalOpen(true)}
              />
            </ProtectedFieldWrapper>
            <ProtectedFieldWrapper
              allowedRoles={[OfficialUserPrivilegesEnum.Sign]}
            >
              <PrimaryFormattedButton
                id="signDecision"
                onClick={() => setIsSignModalOpen(true)}
              />
              <SigningModal
                isOpen={isSignModalOpen}
                onClose={() => setIsSignModalOpen(false)}
                decisionId={decisionId!}
                onSuccess={() => handleSigningSuccess()}
              />
            </ProtectedFieldWrapper>
          </Row>
          <SuspensionCancellationConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirmation={handleCancellationConfirmation}
          />
        </>
      )}
      {waitingForSignature && !fileReference && (
        <Row className="mx-0">
          <DecisionActions
            fileObjectType={FileObjectType.PersonSuspensionDecision}
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            decision={{
              id: id!,
              type: DecisionType.PersonSuspension
            }}
            updateApplication={refresh}
          />
        </Row>
      )}
    </>
  );
};
