import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AutomaticEducationView } from "./Automatic/AutomaticEducationView";
import { ManualEducationView } from "./Manual/ManualEducationView";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { EducationFM } from "../../../../Messages/EducationFM";
import { canQueryFromEHIS } from "./AutomaticEducationPossible";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import {
  ApplicationDraft,
  DetailedApplicationTypeEnum,
  EducationInfo
} from "../../../../../api_client/medre_api";

export const ApplicationEducation = () => {
  const dispatch = useDispatch();

  const isPortal = useSelector((state: RootState) => state.config.isPortal);
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft,
    shallowEqual
  );
  const personId = applicationDraft.personId;
  const [isAutomaticEducationInput, setIsAutomaticEducationInput] = useState(
    applicationDraft.educationInfo?.fromEHIS !== false &&
      canQueryFromEHIS(
        isPortal,
        applicationDraft.educationInfo,
        (applicationDraft as ApplicationCertificateDraft).personalData
      )
  );
  const midWifeCode = useSelector(
    (state: RootState) => state.config.specialOccupationCodes?.midwife
  );
  const educationInfo = useSelector(
    (state: RootState) => state.applicationDraft.educationInfo
  );

  const getOccupationCode = (draft: ApplicationDraft) => {
    if (draft.type === DetailedApplicationTypeEnum.Specialization) {
      return draft.speciality?.code;
    } else {
      if (
        draft.occupation?.code === midWifeCode ||
        draft.additionalOccupation?.code === midWifeCode
      ) {
        return midWifeCode;
      } else {
        return draft.occupation?.code;
      }
    }
  };

  const occupationCode = getOccupationCode(
    applicationDraft as ApplicationDraft
  );

  const setIsSaveAllowed = useCallback(
    (isAllowed: boolean) => {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", isAllowed)
      );
    },
    [dispatch]
  );

  const toggleAutomaticEducationLoad = useCallback(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft("educationInfo", {
        fromEHIS: !isAutomaticEducationInput
      })
    );
    setIsSaveAllowed(false);
    setIsAutomaticEducationInput((isAutomatic) => !isAutomatic);
  }, [
    dispatch,
    setIsAutomaticEducationInput,
    setIsSaveAllowed,
    isAutomaticEducationInput
  ]);

  const updateEducationInfo = (info: EducationInfo) => {
    dispatch(
      applicationDraftActions.updateApplicationDraft("educationInfo", info)
    );
  };

  return (
    <>
      <h3 className="application-title fw-normal">
        <EducationFM id="educationInfo" />
      </h3>
      <p>
        <FormattedMessage
          id="applicationEducation.instruction"
          defaultMessage={`Vali taotlusele vastav haridus ning seejärel klõpsake nuppu “Edasi”.`}
        />
      </p>
      {isAutomaticEducationInput ? (
        <AutomaticEducationView
          occupationCode={occupationCode}
          toggleAutomaticEducationLoad={toggleAutomaticEducationLoad}
          setIsSaveAllowed={setIsSaveAllowed}
          updateEducationInfo={updateEducationInfo}
          educationInfo={educationInfo}
          personId={personId!}
        />
      ) : (
        <ManualEducationView
          toggleAutomaticEducationLoad={toggleAutomaticEducationLoad}
          setIsSaveAllowed={setIsSaveAllowed}
        />
      )}
    </>
  );
};
