import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { RootState } from "../../../../rootReducer";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";
import { Loader } from "../../../../Component/Loader/Loader";
import "../../Application/Application.scss";
import {
  FirmadeAndmeteTeenusApiFactory as companyDataServiceAPI,
  LicenseAcquisition
} from "../../../../../api_client/medre_api";

interface Props {
  registryCode: string;
}

export const LicenseAcquisitionBusinessData = ({ registryCode }: Props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo || {});
  const licenseApplication = useSelector(
    (state: RootState) => state.licenseAcquisitionApplication
  );
  const [loading, setIsLoading] = useState(true);
  const company = licenseApplication.company;

  useEffect(() => {
    if (!licenseApplication.id) {
      companyDataServiceAPI(undefined, getBaseUrl(), API)
        .getDetailedBusinessInfo(registryCode, {
          withCredentials: true
        })
        .then((result) => {
          dispatch(
            licenseAcquisitionActions.updateApplicationDraft(
              "company",
              result.data.company
            )
          );
          setIsLoading(false);
        })
        .catch(() => {
          const alertMessage = (
            <AlertMessage id="fetchDetailedBusinessInfoFailed" />
          );
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, registryCode, licenseApplication.id]);

  useEffect(() => {
    if (!loading) {
      dispatch(
        licenseAcquisitionActions.updateApplicationDraft(
          "saveAllowed" as keyof LicenseAcquisition,
          true
        )
      );
      dispatch(
        licenseAcquisitionActions.updateApplicationDraft(
          "submittingPersonFirstName",
          userInfo.firstName
        )
      );
      dispatch(
        licenseAcquisitionActions.updateApplicationDraft(
          "submittingPersonLastName",
          userInfo.lastName
        )
      );
      dispatch(
        licenseAcquisitionActions.updateApplicationDraft(
          "submittingPersonIdCode",
          userInfo.idCode
        )
      );
    }
  }, [dispatch, loading, userInfo]);

  return loading ? (
    <Loader />
  ) : (
    <div className="application-detail application-container p-3">
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.businessData"
          defaultMessage="Ettevõtte andmed"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicense.checkBusinessData"
          defaultMessage="Kontrolli ettevõtte andmeid, määra tegevuskoha info ning klõpsa “Edasi”."
        />
      </p>
      <ApplicationDetailsInfoCard
        title="Äriregistri andmed"
        content={[
          { key: "Nimi", value: company?.name || "" },
          { key: "Aadress", value: company?.address || "-" },
          { key: "Registrikood", value: registryCode || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: company?.email || "-" },
          { key: "Telefon", value: company?.phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          { key: "Nimi", value: `${userInfo.firstName} ${userInfo.lastName}` },
          { key: "Isikukood", value: userInfo.idCode }
        ]}
      />
    </div>
  );
};
