import React from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row as TableRow,
  usePagination,
  useTable
} from "react-table";

import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { SearchApplicationCertificate } from "../../../Shared/Search/Filters/FilterTypes";
import { translateCountries } from "../../../../Component/Translate/CountryTranslator";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import "../../../Shared/Search/ResultsTable/ResultsTable.scss";

interface Props {
  data: Array<SearchApplicationCertificate>;
  pageCount: number;
  pageIndex: number;
}

const COLUMNS: Column<SearchApplicationCertificate>[] = [
  {
    Header: "Taotleja",
    accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`
  },
  {
    Header: "THT kood",
    id: "occupationCodes",
    accessor: ({ occupationCodes }) =>
      occupationCodes.map(({ code }) => code).join(", ")
  },
  {
    Header: "Kutse",
    id: "occupationNames",
    accessor: ({ occupationCodes }) =>
      occupationCodes.map(({ name }) => name).join(", ")
  },
  {
    Header: "Eriala",
    accessor: ({ speciality }) => speciality?.name ?? null
  },
  {
    Header: "Sihtriik",
    accessor: ({ countries }) => translateCountries(countries)
  },
  {
    Header: "Hariduse omandamise riik",
    accessor: ({ educationCountries }) => translateCountries(educationCountries)
  },
  {
    Header: "Väljastatud",
    accessor: ({ issueDate }) => formatDate(issueDate)
  }
];

export const ApplicationCertificateResults = ({
  data,
  pageCount,
  pageIndex
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: {
        pageIndex: pageIndex,
        hiddenColumns: ["additionalOccupationName", "stateFeePaid"]
      },
      manualPagination: true,
      pageCount
    },
    usePagination
  );
  let stickyColumn2 = 6;
  let normalColumnClass = "normal-column";
  let stickyLastColumnClass = "sticky-last-column";
  let columnClass = "";
  return (
    <div className="table-wrap">
      <div className="table-scroll table-scroll-right">
        <table {...getTableProps()} className="s-table">
          <thead>
            {headerGroups.map(
              (
                headerGroup: HeaderGroup<SearchApplicationCertificate>,
                i: number
              ) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (
                      column: ColumnInstance<SearchApplicationCertificate>,
                      j: number
                    ) => {
                      if (j === stickyColumn2) {
                        columnClass = stickyLastColumnClass;
                      } else {
                        columnClass = normalColumnClass;
                      }
                      return (
                        <th
                          className={columnClass}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    }
                  )}
                </tr>
              )
            )}
          </thead>
          <tbody {...getTableBodyProps()} className="table-result">
            {page.map(
              (row: TableRow<SearchApplicationCertificate>, i: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(
                      (cell: Cell<SearchApplicationCertificate>, j: number) => {
                        if (j === stickyColumn2) {
                          columnClass = stickyLastColumnClass;
                        } else {
                          columnClass = normalColumnClass;
                        }
                        return (
                          <td className={columnClass} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
