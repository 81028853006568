import React, { useCallback, useEffect } from "react";
import moment from "moment";

import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { timePeriods } from "../../../Shared/Search/Filters/FilterUtils";
import { FormattedMessage } from "react-intl";
import * as Types from "../../../Shared/Search/Filters/FilterTypes";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import {
  ApplicationFilterState,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import { FilterField } from "../../../../Component/Filter/Filter";

interface Props {
  id?: string;
  initialValue?: Option | null;
  state: {
    timePeriod?: ApplicationFilterState["timePeriod"];
    currentStartDate?: ApplicationFilterState["currentStartDate"];
    currentEndDate?: Date;
  };
  set: (key: string, data: FilterItemData) => void;
}

export const TimePeriodFilter = ({ id, set, state, initialValue }: Props) => {
  const handleTimePeriodChange = useCallback(
    (period: Option | null) => {
      set("timePeriod", period);

      if (!period) {
        set("currentStartDate", undefined);
        set("currentEndDate", undefined);
        return;
      }

      let startDate: Date | undefined = undefined;
      let endDate: Date | undefined = undefined;

      switch (period.value) {
        case Types.RelativeTimes.Today:
          startDate = moment().utc().startOf("day").toDate();
          endDate = moment().utc().startOf("day").toDate();
          break;
        case Types.RelativeTimes.Yesterday:
          startDate = moment().utc().subtract(1, "d").startOf("day").toDate();
          endDate = moment().utc().subtract(1, "d").startOf("day").toDate();
          break;
        case Types.RelativeTimes.CurrentWeek:
          startDate = moment().utc().startOf("isoWeek").toDate();
          endDate = moment().utc().endOf("isoWeek").subtract(1, "d").toDate();
          break;
        case Types.RelativeTimes.CurrentMonth:
          startDate = moment().utc().startOf("month").toDate();
          endDate = moment().utc().endOf("month").subtract(1, "d").toDate();
          break;
        case Types.RelativeTimes.CurrentYear:
          startDate = moment().utc().startOf("year").toDate();
          endDate = moment().utc().endOf("year").subtract(1, "d").toDate();
          break;
        default:
          break;
      }

      set("currentStartDate", startDate);
      set("currentEndDate", endDate);
    },
    [set]
  );

  const handleStartDateChange = useCallback(
    (date: Date | undefined) => {
      let startDate = date;
      if (date) {
        set("timePeriod", timePeriods[Types.RelativeTimes.Manual]);
        startDate = moment.utc(date).startOf("day").toDate();
      }
      set("currentStartDate", startDate);
    },
    [set]
  );

  const handleEndDateChange = useCallback(
    (date: Date | undefined) => {
      let endDate = date;
      if (date) {
        set("timePeriod", timePeriods[Types.RelativeTimes.Manual]);
        endDate = moment.utc(date).startOf("day").toDate();
      }
      set("currentEndDate", endDate);
    },
    [set]
  );

  const filterStartDate = useCallback(
    (date: Date) => {
      if (!state.currentEndDate) {
        return true;
      }
      const normalizedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return normalizedDate < state.currentEndDate;
    },
    [state.currentEndDate]
  );

  const filterEndDate = useCallback(
    (date: Date) => {
      if (!state.currentStartDate) {
        return true;
      }
      const normalizedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return normalizedDate >= state.currentStartDate;
    },
    [state.currentStartDate]
  );

  useEffect(() => {
    if (initialValue) {
      handleTimePeriodChange(initialValue);
    }
  }, [initialValue, handleTimePeriodChange]);

  const idPrefix = (Boolean(id) && `${id}_`) ?? "";
  const timePeriodId = `${idPrefix}timePeriod`;

  return (
    <>
      <FilterField
        id={timePeriodId}
        label="Ajaperiood"
        field={
          <SingleSelect
            inputId={timePeriodId}
            options={Object.values(timePeriods)}
            value={state.timePeriod}
            handleOptionChange={handleTimePeriodChange}
            className="input"
            isClearable={true}
            isSearchable={false}
            hideSearchIcon={true}
            alwaysClearable={false}
            alwaysShowPlaceholder={true}
            placeholder={
              <FormattedMessage
                id="filters.timePeriod"
                defaultMessage="Vali ajaperiood"
              />
            }
          />
        }
      />
      {state.timePeriod?.value === Types.RelativeTimes.Manual && (
        <FilterField
          label=""
          field={
            <div className="d-flex align-items-center datepicker-container">
              <DatePickerComponent
                onDateChange={handleStartDateChange}
                selectedDate={state.currentStartDate}
                placeholder="pp.kk.aaaa"
                filterDate={filterStartDate}
              />
              <span>–</span>
              <DatePickerComponent
                onDateChange={handleEndDateChange}
                selectedDate={state.currentEndDate}
                filterDate={filterEndDate}
                placeholder="pp.kk.aaaa"
                popperPlacement="bottom-end"
              />
            </div>
          }
        />
      )}
    </>
  );
};
