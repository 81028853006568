import { AlertMessage } from "../Alert/AlertMessage";
import { AlertType } from "../Dto/Alert/AlertItem";
import { alertActions } from "../Alert/alertActions";
import React from "react";
import { Dispatch } from "redux";

// tslint:disable-next-line:no-any
export const displayAlert = (id: string, type: AlertType, dispatch: Dispatch<any>) => {
  const alertMessage = <AlertMessage id={id}/>;
  const alert = {id: 0, type: type, message: alertMessage};
  return dispatch(alertActions.addAlert(alert));
};

// tslint:disable-next-line:no-any
export const displayAlertWithValues = (id: string, values: Record<string, any>, type: AlertType, dispatch: Dispatch<any>) => {
  const alertMessage = <AlertMessage id={id} values={values}/>;
  const alert = {id: 0, type: type, message: alertMessage};
  return dispatch(alertActions.addAlert(alert));
};