import React from "react";
import { FormattedMessage } from "react-intl";

export const activityLicenseSteps = [
  <FormattedMessage key="activityLicenseStep1" id="activityLicense.businessData" defaultMessage="Ettevõtte andmed"/>,
  <FormattedMessage key="activityLicenseStep2" id="activityLicense.businessArea" defaultMessage="Tegevusala"/>,
  <FormattedMessage key="activityLicenseStep3" id="activityLicense.services" defaultMessage="Teenused"/>,
  <FormattedMessage key="activityLicenseStep4" id="activityLicense.documents" defaultMessage="Dokumendid"/>,
  <FormattedMessage key="activityLicenseStep5" id="activityLicense.overview" defaultMessage="Esita taotlus"/>,
];

export const officialActivityLicenseSteps = [
  <>Ettevõtte andmed</>,
  <>Tegevusala</>,
  <>Teenused</>,
  <>Dokumendid</>,
  <>Taotluse saabumise kanal</>,
  <>Esita taotlus</>
];

export enum ActivityLicenseStep {
  BUSINESS_DATA,
  BUSINESS_AREA,
  SERVICES,
  DOCUMENTS,
  OVERVIEW
}

export enum OfficialActivityLicenseStep {
  BUSINESS_DATA,
  BUSINESS_AREA,
  SERVICES,
  DOCUMENTS,
  APPLICATION_ARRIVAL_CHANNEL,
  OVERVIEW
}