import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { useHistory } from "react-router-dom";

import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import {
  DetailedApplicationTypeEnum,
  PersonalData, RakendussertifikaadiAndmeteenusApiFactory as appCertificateDataAPI
} from "../../../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { ApplicationTypeSelection } from "../../Shared/Application/ApplicationTypeSelection";
import {
  ApplicationCertificateProvider,
  useApplicationCertificateState
} from "../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import {API, getBaseUrl} from "../../../api";

export const PortalApplicationTypeSelection = (): React.JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const person = useSelector((state: RootState) => state.person);
  const [personalData] = useState({ ...person } as PersonalData);

  const certificateRequestState = useApplicationCertificateState(
    appCertificateDataAPI(undefined, getBaseUrl(), API)
      .getApplicationCertificateMetadata1
  );
  const handleNewOccupationApplication = (): void => {
    const applicationDraft = {};
    (applicationDraft as ApplicationCertificateDraft).type =
      DetailedApplicationTypeEnum.Occupation;
    (applicationDraft as ApplicationCertificateDraft).personId =
      userInfo?.userId;
    (applicationDraft as ApplicationCertificateDraft).contactInfo =
      person.contactInfo;
    (applicationDraft as ApplicationCertificateDraft).personalData =
      personalData;
    (applicationDraft as ApplicationCertificateDraft).currentStep = 0;
    dispatch(applicationDraftActions.setApplicationDraft(applicationDraft));
    history.push("/new-application/occupation");
  };

  const handleNewSpecialityApplication = (): void => {
    const applicationDraft = {};
    (applicationDraft as ApplicationCertificateDraft).type =
      DetailedApplicationTypeEnum.Specialization;
    (applicationDraft as ApplicationCertificateDraft).personId =
      userInfo?.userId;
    (applicationDraft as ApplicationCertificateDraft).contactInfo =
      person.contactInfo;
    (applicationDraft as ApplicationCertificateDraft).personalData =
      personalData;
    (applicationDraft as ApplicationCertificateDraft).currentStep = 0;
    dispatch(applicationDraftActions.setApplicationDraft(applicationDraft));
    history.push("/new-application/speciality");
  };

  const handleNewApplicationCertificate = (): void => {
    const applicationDraft = {};
    (applicationDraft as ApplicationCertificateDraft).type =
      DetailedApplicationTypeEnum.ApplicationCertificate;
    (applicationDraft as ApplicationCertificateDraft).personId =
      userInfo?.userId;
    (applicationDraft as ApplicationCertificateDraft).contactInfo =
      person.contactInfo;
    (applicationDraft as ApplicationCertificateDraft).personalData =
      personalData;
    (applicationDraft as ApplicationCertificateDraft).currentStep = 0;
    dispatch(applicationDraftActions.setApplicationDraft(applicationDraft));
    history.push("/new-application/certificate");
  };

  const pageTitle = {
    id: "proceedingTypeSelection.newProceedingTitle",
    defaultMessage: "Uus taotlus"
  };

  const occupationDescription = {
    id: "proceedingTypeSelection.occupationParagraph",
    defaultMessage:
      "Esita taotlus kui soovid registeerida end arstiks, farmatseutiks, hambaarstiks, proviisoriks, õeks või ämmaemandaks."
  };

  const specialityDescription = {
    id: "proceedingTypeSelection.specialityParagraph",
    defaultMessage:
      "Esita taotlus kui soovid registeerida endale eriala taotluse hambaarsti, arsti või õe kutsele."
  };

  const certificateDescription = {
    id: "proceedingTypeSelection.certificateParagraph",
    defaultMessage:
      "Esita kutsekvalifikatsiooni tunnustamise tõendi taotlus EL või EMP liikmesriigi või Šveitsi pädevale asutusele esitamiseks"
  };

  return (
    <ApplicationCertificateProvider value={certificateRequestState}>
      <ApplicationTypeSelection
        pageTitle={pageTitle}
        specialityDescription={specialityDescription}
        occupationDescription={occupationDescription}
        certificateDescription={certificateDescription}
        handleNewSpecialityApplication={handleNewSpecialityApplication}
        handleNewOccupationApplication={handleNewOccupationApplication}
        handleNewApplicationCertificate={handleNewApplicationCertificate}
      />
    </ApplicationCertificateProvider>
  );
};
