import React, { useState } from "react";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { StateFeeInfoModal } from "../../../Shared/StateFee/StateFeeInfoModal";
import { ManualPaymentConfirmationModal } from "./ManualPaymentConfirmationModal";
import {
  DetailedApplication,
  OfficialUserPrivilegesEnum, ProceedingStatusStatusEnum
} from "../../../../../api_client/medre_api";
import { AxiosResponse } from "axios";

interface Props<TApplication> {
  application: TApplication;
  updateApplication: (
    application: TApplication | DetailedApplication | any
  ) => void;
  manualPaymentApiRequest: () => Promise<AxiosResponse>;
}

export const ApplicationDetailsPayment = <
  TApplication extends DetailedApplication
>({
  application,
  updateApplication,
  manualPaymentApiRequest
}: Props<TApplication>) => {
  const [isStateFeeInfoModalOpen, setIsStateFeeInfoModalOpen] = useState(false);
  const [
    isManualPaymentConfirmationModalOpen,
    setIsManualPaymentConfirmationModalOpen
  ] = useState(false);

  const getPaymentStatus = () => {
    if (!("stateFee" in application) ||
      (!application.stateFee?.paid && application.currentStatus?.status === ProceedingStatusStatusEnum.Finished)) {
      return "Tasumata";
    }
    if (application.stateFee?.paid) {
      return "Tasutud";
    }
    return (
      <>
        <div className="payment-status-wrapper">
          <span className="fw-500">Tasumata</span>
          <LinkButton
            id="paymentInstructions"
            onClick={(): void => setIsStateFeeInfoModalOpen(true)}
          />
        </div>
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign
          ]}
        >
          <SecondaryFormattedButton
            id="paymentManuallyConfirmed"
            onClick={(): void => setIsManualPaymentConfirmationModalOpen(true)}
          />
        </ProtectedFieldWrapper>
      </>
    );
  };

  return (
    <>
      <ApplicationDetailsInfoCard
        title="Riigilõiv"
        content={[{ key: "Maksmise staatus", value: getPaymentStatus() }]}
      />

      <StateFeeInfoModal
        isOpen={isStateFeeInfoModalOpen}
        onClose={(): void => setIsStateFeeInfoModalOpen(false)}
        deadline={application.stateFee?.stateFeeDeadline}
        referenceNumber={application.stateFee?.referenceNumber}
        stateFeeValue={application.stateFee?.stateFeeValue}
      />
      <ManualPaymentConfirmationModal
        isOpen={isManualPaymentConfirmationModalOpen}
        onClose={(): void => setIsManualPaymentConfirmationModalOpen(false)}
        apiRequest={manualPaymentApiRequest}
        updateApplication={updateApplication}
      />
    </>
  );
};
