import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationContactInfo } from "../../../Shared/Application/ApplicationContactInfo";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import {
  ActivityLocation,
  ApplicationCertificateDeliveryTypeEnum
} from "../../../../../api_client/medre_api";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import CertificateIssueAddress from "./CertificateIssueAddress";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";

interface Props {
  isCertificateIssuanceAddressValid?: boolean;
  setIsCertificateIssuanceAddressValid?: (value?: boolean) => void;
}

export const ApplicationCertificateContactInfo = ({
  isCertificateIssuanceAddressValid,
  setIsCertificateIssuanceAddressValid
}: Props) => {
  const dispatch = useDispatch();
  const handleAddressSwitchChange = useCallback(
    (event) => {
      dispatch(
        applicationDraftActions.updateApplicationDraft<ApplicationCertificateDraft>(
          "deliveryType",
          event.target.value
        )
      );
      if (event.target.value === ApplicationCertificateDeliveryTypeEnum.Post) {
        updateAddress("fullAddress", "");
        updateAddress("zipCode", "");
        updateAddress("adrCode", "");
        updateAddress("adsAdrID", "");
        updateAddress("ehak", "");
        updateAddress("ehakmk", "");
        updateAddress("ehakov", "");
        updateAddress("apartmentNumber", "");
      }
    },
    [dispatch]
  );
  const deliveryTypeValue = useSelector(
    (state: RootState) =>
      (state.applicationDraft as ApplicationCertificateDraft).deliveryType ??
      ApplicationCertificateDeliveryTypeEnum.Electronic
  );

  const updateAddress = useCallback(
    (field: keyof ContactInfoForm, value?: string | boolean) => {
      dispatch(
        activityLicenseApplicationActions.updateLocationInfo(
          0,
          field as keyof ActivityLocation,
          value
        )
      );
    },
    [dispatch]
  );

  return (
    <ApplicationContactInfo
      renderInstructions={
        <FormattedMessage
          id="ApplicationCertificateContactInfo.instructions"
          defaultMessage={`Sisesta taotlusega seotud kontaktandmed ning taotluse väljastamise asukoht ja seejärel klõpsa Edasi.`}
        />
      }
      addressSwitch={{
        title: (
          <FormattedMessage
            id="ApplicationCertificateContactInfo.addressSwitchTitle"
            defaultMessage="Kuidas soovid tõendit kätte saada"
          />
        ),
        name: "deliveryType",
        defaultValue: deliveryTypeValue,
        onChange: handleAddressSwitchChange,
        showAddressOption: {
          id: ApplicationCertificateDeliveryTypeEnum.Electronic,
          value: ApplicationCertificateDeliveryTypeEnum.Electronic,
          labelText: (
            <FormattedMessage
              id="ApplicationCertificateContactInfo.electronicIssued"
              defaultMessage="Tõend väljastatakse elektrooniliselt (.PDF)"
            />
          )
        },
        hideSecondAddressOption: {
          id: ApplicationCertificateDeliveryTypeEnum.TakeMyself,
          value: ApplicationCertificateDeliveryTypeEnum.TakeMyself,
          labelText: <ContactInfoFM id="takeCertificateCompanyAddress" />
        },
        hideThirdAddressOption: {
          id: ApplicationCertificateDeliveryTypeEnum.Post,
          value: ApplicationCertificateDeliveryTypeEnum.Post,
          labelText: (
            <FormattedMessage
              id="ApplicationCertificateContactInfo.certificateDeliveryByPost"
              defaultMessage="Postiga valitud aadressile"
            />
          )
        },
        component: (
          <CertificateIssueAddress
            isCertificateIssuanceAddressValid={
              isCertificateIssuanceAddressValid!
            }
            setIsCertificateIssuanceAddressValid={
              setIsCertificateIssuanceAddressValid!
            }
          />
        )
      }}
    />
  );
};