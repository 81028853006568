import React, { ReactElement, useCallback, useMemo } from "react";
import { ArrayField, UseFieldArrayMethods } from "react-hook-form";
import { Button } from "reactstrap";
import { RemoveButton } from "../Button/RemoveButton";

interface Props<TValue, TKey extends string> {
  array: UseFieldArrayMethods<Record<string, TValue>, TKey>;
  appendValue: TValue;
  appendText: ReactElement;
  render: (
    fields: Partial<ArrayField<Record<string, TValue>, TKey>>[],
    buttons: {
      appendButton: JSX.Element;
      removeButton: (index: number) => JSX.Element;
    }
  ) => ReactElement;
}

// tslint:disable-next-line:no-any
export function FieldArray<TValue extends any, TKey extends string = "_id">({
  array,
  appendValue,
  appendText,
  render,
}: Props<TValue, TKey>) {
  const { append, remove, fields } = array;

  const handleAppend = useCallback(() => {
    append(appendValue as Partial<Record<string, TValue>>);
  }, [append, appendValue]);
  const handleRemove = useCallback((i: number) => () => remove(i), [remove]);

  const appendButton = useMemo(
    () => (
      <Button color="link" onClick={handleAppend}>
        {appendText}
      </Button>
    ),
    [handleAppend, appendText]
  );
  const removeButton = useMemo(
    () => (index: number) => (
      <RemoveButton handleDelete={handleRemove(index)} />
    ),
    [handleRemove]
  );

  return render(fields, { appendButton, removeButton });
}
