import React from "react";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (notes: string) => Promise<void>;
}

type PauseProceedingForm = {
  pauseProceedingNotes: string;
};

export const PauseProceedingModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const defaultValues: PauseProceedingForm = {
    pauseProceedingNotes: ""
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const { handleSubmit, register, errors, reset, formState } =
    useForm<PauseProceedingForm>({
      defaultValues,
      mode: "onBlur"
    });
  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const submit = (data: PauseProceedingForm) =>
    onSubmit(data.pauseProceedingNotes).then(() => reset(defaultValues));

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit(submit)}>
        <ModalHeader>
          <FormattedMessage
            id="activityLicensePauseProceedingModal.header"
            defaultMessage="Peata menetlus ja suuna taotlus täiendamisele"
          />
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div className="label">
              <FormattedMessage
                id="activityLicensePauseProceedingModal.body"
                defaultMessage="Põhjendused ja tähtaeg"
              />
            </div>
            <Input
              type="textarea"
              name="pauseProceedingNotes"
              valid={
                ((isSubmitted && !isSubmitSuccessful) ||
                  touched.pauseProceedingNotes) &&
                !errors.pauseProceedingNotes
              }
              invalid={!!errors.pauseProceedingNotes}
              innerRef={register({ required: true })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton id="back" onClick={handleClose} />
          <PrimaryFormattedButton id="pauseProceedingConfirm" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
