import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../rootReducer";
import { ContactInfoForm, formInfoToContactInfo } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { ContactInfoEditFormFields } from "../../../Shared/Application/ContactInfo/ContactInfoEditFormFields";
import { ApplicationPersonalData } from "../../../Shared/Application/ApplicationPersonalData";
import { ContactInfo, PersonalData } from "../../../../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";

export const PersonalDataContactContainer = () => {
  const dispatch = useDispatch();
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft as ApplicationCertificateDraft
  );
  const person = useSelector((state: RootState) => state.person);
  const contactInfoFromRR = useSelector((state: RootState) => state.contactInfoFromRR);
  const [personalData] = useState(
    applicationDraft.personalData || ({ ...person } as PersonalData)
  );
  const contactInfo = useSelector((state: RootState) => {
    return state.person.contactInfo
      ? state.person.contactInfo
      : applicationDraft.contactInfo || ({} as ContactInfo);
  });
  const [contactFormInfo, setContactFormInfo] = useState(
    new ContactInfoForm(contactInfo)
  );
  const [personFormInfo, setPersonFormInfo] = useState(personalData);
  const [validContactInfo, setValidContactInfo] = useState(false);
  const [validPersonalData, setValidPersonalData] = useState(false);

  useEffect(() => {
    let info = formInfoToContactInfo(contactFormInfo);
    dispatch(
      applicationDraftActions.updateApplicationDraft("contactInfo", info)
    );
  }, [dispatch, contactFormInfo]);

  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft(
        "personalData",
        personFormInfo
      )
    );
  }, [dispatch, personFormInfo]);

  useEffect(() => {
    if (validContactInfo && validPersonalData) {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", true)
      );
    } else {
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", false)
      );
    }
  }, [dispatch, validContactInfo, validPersonalData]);

  const setValidContactForm = useCallback((isAllowed: boolean) => {
    setValidContactInfo(isAllowed);
  }, []);

  const setValidPersonalDataForm = useCallback((isAllowed: boolean) => {
    setValidPersonalData(isAllowed);
  }, []);

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      setContactFormInfo((previousFormInfo) => {
        return { ...previousFormInfo, [field]: value };
      });
    },
    []
  );

  const updatePersonInfo = useCallback(
    (field: keyof PersonalData, value?: string) => {
      setPersonFormInfo((previousFormInfo: PersonalData) => {
        return { ...previousFormInfo, [field]: value };
      });
    },
    []
  );

  return (
    <>
      <ApplicationPersonalData
        formInfo={personFormInfo}
        personData={applicationDraft.personalData}
        updatePersonInfo={updatePersonInfo}
        setIsFormValid={setValidPersonalDataForm}
      />
      <h4 className="application-title fw-normal mb-4">Kontaktinfo</h4>
      <ContactInfoEditFormFields
        hasEstonianPersonalCode={!!personalData.idCode}
        formInfo={contactFormInfo}
        updateContactInfo={updateContactInfo}
        setIsFormValid={setValidContactForm}
        contactInfoFromRR={contactInfoFromRR}

        personIdCode={personalData.idCode}
      />
    </>
  );
};
