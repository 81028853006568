import React from "react";

import { BadgeName, FormattedBadge } from "./FormattedBadge";
import icon from "../../../assets/images/badges/warning_info.svg";
import iconGhost from "../../../assets/images/badges/warning_info_ghost.svg";

interface Props {
  id: string;
  message: BadgeName;
  isGhost?: boolean;
  className?: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

export const WarningBadge = ({
  id,
  className,
  message,
  isGhost,
  values
}: Props) => (
  <FormattedBadge
    id={id}
    className={className}
    message={message}
    isGhost={isGhost}
    values={values}
    icon={icon}
    iconGhost={iconGhost}
    color="warning"
  />
);
