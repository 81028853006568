import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AxiosPromise } from "axios";

import {
  ApplicationDraft,
  DetailedApplication
} from "../../../../../api_client/medre_api";

export const useDetailedApplication = <
  TApplication extends DetailedApplication | ApplicationDraft
>(
  applicationEndpoint: (
    id: string,
    options?: any
  ) => AxiosPromise<TApplication>,
  id: string
) => {
  const [details, setDetails] = useState<TApplication>();

  const { pathname } = useLocation();
  const isDraft = pathname.includes("drafts");
  const history = useHistory();

  const fetchDetailedApplication = useCallback(async () => {
    const { data: detailedData } = await applicationEndpoint(id, {
      withCredentials: true
    });
    setDetails(detailedData);
  }, [isDraft, setDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDetailedApplication().catch((error) => {
      if (!error.response || error.response?.status !== 400) {
        history.push("/");
      }
    });
  }, [fetchDetailedApplication, history]);

  return {
    details,
    setDetails,
    refreshApplication: fetchDetailedApplication
  };
};
