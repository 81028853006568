import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

type Message =
  | "receiverName"
  | "ministryOfFinance"
  | "referenceNumber"
  | "sum"
  | "stateFee"
  | "stateFeeSum"
  | "paid"
  | "notPaidWithButton"
  | "stateFeeInstructionHeader"
  | "stateFeeInstruction";

export const StateFeeFM = (props: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    receiverName: {
      id: "stateFeeFM.receiverName",
      defaultMessage: "Saaja nimi"
    },
    ministryOfFinance: {
      id: "stateFeeFM.ministryOfFinance",
      defaultMessage: "Rahandusministeerium"
    },
    referenceNumber: {
      id: "stateFeeFM.referenceNumber",
      defaultMessage: "Viitenumber"
    },
    sum: {
      id: "stateFeeFM.sum",
      defaultMessage: "Summa"
    },
    stateFee: {
      id: "stateFeeFM.stateFee",
      defaultMessage: "Riigilõiv"
    },
    stateFeeSum: {
      id: "stateFeeFM.stateFeeSum",
      defaultMessage: "Riigilõivu summa"
    },
    paid: {
      id: "stateFeeFM.paid",
      defaultMessage: "Tasutud"
    },
    notPaidWithButton: {
      id: "stateFeeFM.notPaidWithButton",
      defaultMessage: "Tasumata <button>Tasumise juhend</button>"
    },
    stateFeeInstructionHeader: {
      id: "stateFeeFM.stateFeeInstructionHeader",
      defaultMessage: "Riigilõivu tasumise juhend"
    },
    stateFeeInstruction: {
      id: "stateFeeFM.stateFeeInstruction",
      defaultMessage:
        "Riigilõivu maksmiseks on Teil aega kuni {deadline}, pärast mida taotlus tühistatakse ja kustutatakse."
    }
  });

  return <FormattedMessage {...messages[props.id as Message]} {...props} />;
};
