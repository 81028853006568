import React, { FC, useCallback, useMemo, useState } from "react";
import { Column, useTable, useSortBy, useFlexLayout } from "react-table";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

import ApplicantDetailsSection from "../ApplicantDetailsSection";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { useCompetencies } from "./ApplicantCompetenciesHooks";
import { Loader } from "../../../../Component/Loader/Loader";
import { ApplicantCompetenceModal } from "./ApplicantCompetenceModal";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { RemoveButton } from "../../../../Component/Button/RemoveButton";
import {
  Competence,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import ApplicantCompetenceDeletionModal from "./ApplicantCompetenceDeletionModal";
import { Option } from "../../../../Component/Select/SingleSelect";
import { FormattedButton } from "../../../../Component/Button/FormattedButton";

interface Props {
  personId: string;
  personCompetenceSpecialities: Option[];
}

enum Modes {
  Creating = "CREATING",
  Updating = "UPDATING",
  Deleting = "DELETING"
}

const useApplicantCompetenciesHelpers = (competencies: Competence[]) => {
  const [mode, setMode] = useState<Modes | undefined>();
  const [currentCompetenceId, setCurrentCompetenceId] = useState<
    string | undefined
  >(undefined);
  const handleCloseModal = useCallback(() => {
    setCurrentCompetenceId(undefined);
    setMode(undefined);
  }, []);

  const handleOpenCreatingModal = useCallback(() => {
    setMode(Modes.Creating);
  }, []);

  const handleOpenUpdatingModal = useCallback((e) => {
    const { id } = e.currentTarget.dataset;

    setCurrentCompetenceId(id);
    setMode(Modes.Updating);
  }, []);

  const handleOpenDeletingModal = useCallback((e) => {
    const { id } = e.currentTarget.dataset;

    setCurrentCompetenceId(id);
    setMode(Modes.Deleting);
  }, []);

  const competence = useMemo(
    () =>
      competencies.find(({ id }) => id === currentCompetenceId) ||
      ({} as Competence),
    [competencies, currentCompetenceId]
  );

  return {
    handleCloseModal,
    handleOpenCreatingModal,
    handleOpenUpdatingModal,
    handleOpenDeletingModal,
    competence,
    mode
  };
};

const ApplicantCompetencies: FC<Props> = ({
  personId,
  personCompetenceSpecialities
}) => {
  const {
    competencies,
    loading,
    deleting,
    handleCreate,
    handleUpdate,
    handleDelete
  } = useCompetencies(personId);

  const {
    mode,
    competence,
    handleCloseModal,
    handleOpenCreatingModal,
    handleOpenUpdatingModal,
    handleOpenDeletingModal
  } = useApplicantCompetenciesHelpers(competencies);

  const COLUMNS: Column<Competence>[] = useMemo(
    () => [
      {
        Header: (
          <FormattedMessage
            id="competenceSection.nameAssociation"
            defaultMessage="Seltsi nimi"
          />
        ),
        accessor: "nameAssociation",
        width: 100
      },
      {
        Header: (
          <FormattedMessage
            id="competenceSection.specialtyName"
            defaultMessage="Eriala"
          />
        ),

        accessor: "specialtyName",
        width: 100
      },
      {
        id: "validationDate",
        Header: (
          <FormattedMessage
            id="competenceSection.validationDate"
            defaultMessage="Kinnitamise kuupäev"
          />
        ),
        accessor: "validationDate",
        Cell: ({ value }) => formatDate(value),
        width: 100
      },
      {
        Header: (
          <FormattedMessage
            id="competenceSection.validUntil"
            defaultMessage="Kehtib kuni"
          />
        ),
        accessor: "validUntil",
        Cell: ({ value }) => formatDate(value),
        width: 100
      },
      {
        id: "notes",
        Header: (
          <FormattedMessage
            id="competenceSection.notes"
            defaultMessage="Märkmed"
          />
        ),
        accessor: "notes"
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => (
          <>
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <FormattedButton
                id="changeCompetence"
                onClick={handleOpenUpdatingModal}
                data-id={value}
                color="link p-0 m-0"
              />
            </ProtectedFieldWrapper>
            &nbsp;|&nbsp;
            <RemoveButton
              handleDelete={handleOpenDeletingModal}
              hideText={true}
              dataId={value}
            />
          </>
        )
      }
    ],
    [handleOpenUpdatingModal, handleOpenDeletingModal]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: COLUMNS,
        data: competencies,
        initialState: {
          sortBy: [{ id: "validationDate", desc: false }]
        }
      },
      useSortBy,
      useFlexLayout
    );

  const button = useMemo(
    () => (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="newCompetence"
          onClick={handleOpenCreatingModal}
        />
      </ProtectedFieldWrapper>
    ),
    [handleOpenCreatingModal]
  );

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.Competencies}
      button={button}
    >
      <div className="table-wrap">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table
              {...getTableProps({
                className: "s-table user-applications-table"
              })}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps({ className: "m-0" })}
                  >
                    {headerGroup.headers.map((column) => (
                      // tslint:disable-next-line:jsx-key
                      <th
                        {...column.getHeaderProps({
                          className: "normal-column h-auto text-wrap"
                        })}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          // tslint:disable-next-line:jsx-key
                          <td
                            {...cell.getCellProps({
                              className: "normal-column h-auto text-wrap"
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {(competence && mode === Modes.Updating) ||
            mode === Modes.Creating ? (
              <ApplicantCompetenceModal
                onClose={handleCloseModal}
                competence={competence}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                personCompetenceSpecialities={personCompetenceSpecialities}
              />
            ) : null}

            {competence.id && mode === Modes.Deleting ? (
              <ApplicantCompetenceDeletionModal
                onClose={handleCloseModal}
                onDelete={handleDelete}
                competenceId={competence.id}
                deleting={deleting}
              />
            ) : null}
          </>
        )}
      </div>
    </ApplicantDetailsSection>
  );
};

export default ApplicantCompetencies;
