import "react-app-polyfill/ie11"; // https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill#supporting-internet-explorer
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./App/App";
import * as serviceWorker from "./App/serviceWorker";
import store from "./App/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: Function;
    moment: typeof moment;
  }
}

window.moment = moment;

ReactDOM.render(
  (
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  ),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
