import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import "./RepresentativeViewModal.scss";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { RepresentativeViewModalProps } from "./types";
import { getDateWithTime, getFormattedDate } from "../../../../Util/DateUtils";

const RepresentativeViewModal = ({
  modal,
  onClose
}: RepresentativeViewModalProps) => {
  const { data, isOpen } = modal;
  return (
    <Modal
      id="representative-modal"
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
      className="representative-modal-container"
    >
      <ModalHeader tag="h4">
        <FormattedMessage
          id="representativeViewModal.title"
          defaultMessage="Esindaja volituse vaatamine"
        />
      </ModalHeader>
      <ModalBody>
        <div className="representative-view-modal-container">
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.personalCode"
                defaultMessage="Isikukood"
              />
            </div>
            <div>{data?.personCode}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.firstName"
                defaultMessage="Eesnimi"
              />
            </div>
            <div>{data?.personFirstName}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.surname"
                defaultMessage="Perekonnanimi"
              />
            </div>
            <div>{data?.personLastName}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.email"
                defaultMessage="E-post"
              />
            </div>
            <div>{data?.personEmailAddress}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.type"
                defaultMessage="Volituse tüüp"
              />
            </div>
            <div>{data?.type}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.alates"
                defaultMessage="Alates"
              />
            </div>
            <div>{getFormattedDate(data?.startDate!)}</div>
          </div>
          <div className="representative-view-modal-item-container">
            <div className="representative-view-modal-label">
              <FormattedMessage
                id="representativeViewModal.label.kuni"
                defaultMessage="Kuni"
              />
            </div>
            <div>{data?.endDate ? getFormattedDate(data?.endDate!) : ""}</div>
          </div>
        </div>
        <div className="representative-view-modal-info-text">
          <FormattedMessage
            id="representativeViewModal.create.text"
            defaultMessage="Lisatud {name} poolt {date}"
            values={{
              name: data?.createdBy,
              date: data?.createdAt ? getDateWithTime(data?.createdAt) : ""
            }}
          />
        </div>
        {data?.modifiedBy && (
          <div className="representative-view-modal-info-text">
            <FormattedMessage
              id="representativeViewModal.modify.text"
              defaultMessage="Viimati muudetud {name} poolt {date}"
              values={{
                name: data?.modifiedBy,
                date: data?.modifiedAt ? getDateWithTime(data?.modifiedAt) : ""
              }}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="close" type="reset" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};

export default RepresentativeViewModal;
