import React, { useState } from "react";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import "./AddNewServiceModal.scss";
import { createServiceName } from "../../../../Dto/ActivityLicense/Service";
import { Service } from "../../../../../api_client/medre_api";
import { Option } from "../../../../Component/Select/SelectTypes";

interface Props {
  isOpen: boolean;
  services?: Service[];
  onClose: () => void;
  onSave: (service: Service) => void;
}

export const AddNewServiceModal = ({
  isOpen,
  services,
  onClose,
  onSave
}: Props) => {
  const [selectedService, setSelectedService] = useState<Service>();

  const handleClose = () => {
    setSelectedService(undefined);
    onClose();
  };

  const handleSave = () => {
    onSave(selectedService!);
    setSelectedService(undefined);
  };

  const createOption = (service: Service): Option => ({
    label: createServiceName(service, true),
    value: service.id as string
  });

  const handleOptionChange = (option: Option) => {
    setSelectedService(
      services?.find((service) => service.id === option.value)
    );
  };

  return (
    <Modal isOpen={isOpen} id="add-new-service-modal">
      <ModalHeader tag="h4">
        <FormattedMessage
          id="addNewServiceModal.header"
          defaultMessage="Lisa uus teenus"
        />
      </ModalHeader>
      <ModalBody>
        <div className="selection-wrapper">
          <Label htmlFor="addNewServiceModalServiceId">
            <FormattedMessage
              id="addNewServiceModal.chooseService"
              defaultMessage="Vali teenus"
            />
          </Label>
          <SingleSelect
            inputId="addNewServiceModalServiceId"
            placeholder={
              <FormattedMessage
                id="addNewServiceModal.service"
                defaultMessage="Teenus"
              />
            }
            alwaysShowPlaceholder={true}
            options={services?.map(createOption) || []}
            handleOptionChange={handleOptionChange}
            value={selectedService ? createOption(selectedService) : null}
          />
        </div>
        {selectedService?.requiredServices &&
          !!selectedService.requiredServices.length && (
            <div className="required-services-wrapper">
              <div>
                <FormattedMessage
                  id="addNewServiceModal.requiredService"
                  defaultMessage="Teenusele on vaja lisaks ka üks allolevatest teenustest:"
                />
              </div>
              {selectedService.requiredServices.map((service) => (
                <div key={service.id} className="fw-700">
                  {createServiceName(service)}
                </div>
              ))}
            </div>
          )}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <SecondaryFormattedButton
          id="backToApplication"
          onClick={handleClose}
        />
        <PrimaryFormattedButton
          id="addService"
          disabled={!selectedService}
          onClick={handleSave}
        />
      </ModalFooter>
    </Modal>
  );
};
