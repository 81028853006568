import React from "react";
import { Card, Col, Row } from "reactstrap";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../../../../Locale/LocaleMessage";

type OccupationSelectionMessageCardType =
  | "possessed"
  | "applied"
  | "appliedOfficial"
  | "saved"
  | "savedOfficial";

interface Props {
  messageType: OccupationSelectionMessageCardType;
}

export const OccupationSelectionMessageCard = ({ messageType }: Props) => {
  const messages: Record<OccupationSelectionMessageCardType, LocaleMessage> =
    defineMessages({
      possessed: {
        id: "occupationSelectionMessageCard.possessed",
        defaultMessage: "Valitud kutse on juba taotletud."
      },
      applied: {
        id: "occupationSelectionMessageCard.applied",
        defaultMessage:
          'Valitud kutse taotlus on teil esitatud. Kutse taotluse hetkeseisu saate jälgida "Minu taotlused" lehelt.'
      },
      appliedOfficial: {
        id: "occupationSelectionMessageCard.appliedOfficial",
        defaultMessage: "Valitud kutse taotlus on esitatud."
      },
      savedOfficial: {
        id: "occupationSelectionMessageCard.savedOfficial",
        defaultMessage: "Valitud kutse taotlus on pooleli."
      },
      saved: {
        id: "occupationSelectionMessageCard.saved",
        defaultMessage:
          'Valitud kutse taotlus on teil pooleli. Poolelioleva kutse taotluse leiate "Minu taotlused" lehelt.'
      }
    });

  return (
    <Row>
      <Col className="offset-md-1 offset-lg-2" lg={7} md={9} xs={12}>
        <Card className="p-3 w-100 d-flex align-items-center justify-content-center info-card">
          <FormattedMessage {...messages[messageType]} />
        </Card>
      </Col>
    </Row>
  );
};
