import React from "react";
import moment from "moment";

import { Card } from "../Card";
import "../PreviousNames/PreviousNamesDetails.scss";
import { AttachmentCardTitle } from "../AttachmentCardTitle";
import { ExternalDecisionUpload } from "./ExternalDecisionUpload";
import { ApplicantChanges } from "../../../../Dto/Applicant/ApplicantChanges";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import { Person } from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";

interface Props {
  person: Person;
  applicantChanges: ApplicantChanges;
  setApplicantChanges: (
    applicantChanges: (prevState: ApplicantChanges) => ApplicantChanges
  ) => void;
}

export const ExternalDecisions = ({
  person,
  applicantChanges,
  setApplicantChanges
}: Props) => {
  const hasExternalDecisions =
    person.externalDecisions && person.externalDecisions.length > 0;
  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.ExternalDecisions}
    >
      <div className="detail-card-grid mt-4">
        {person.externalDecisions!.map((externalDecision) => {
          const fileReferenceArray = externalDecision.fileReference
            ? [externalDecision.fileReference]
            : [];
          return (
            <Card
              classNameOverride="card applicant-details-card-margin p-4"
              key={externalDecision.id}
              title={
                <AttachmentCardTitle fileReferences={fileReferenceArray} />
              }
              subtitle={
                "Üles laadimise kuupäev: " +
                moment(externalDecision.createdAt).format("DD.MM.YYYY")
              }
            />
          );
        })}
      </div>
      <h3 className={hasExternalDecisions ? "name-change-title mb-4" : "mb-4"}>
        Lae üles uus otsus
      </h3>
      <ExternalDecisionUpload
        person={person}
        applicantChanges={applicantChanges}
        setApplicantChanges={setApplicantChanges}
      />
    </ApplicantDetailsSection>
  );
};
