import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "reactstrap";

import { AttachmentFileRow } from "./AttachmentFileRow";
import { FileReferenceDto } from "../../../../../../Dto/File/FileReference";

interface Props {
  files?: FileReferenceDto[];
  isDeletingPossible: boolean;
  handleFileDelete?: (fileName: string) => void;
}

export const AttachmentFileList = ({
  files,
  isDeletingPossible,
  handleFileDelete
}: Props) => (
  <Row className="file-text mb-4">
    <Col md={4} className="file-text-gray pt-2">
      <FormattedMessage
        id="attachment.addedFiles"
        defaultMessage="Lisatud failid"
      />
    </Col>
    <Col>
      {files?.map((file, index) => (
        <div className="attachment-file-row" key={`${file.fileName}-${index}`}>
          <AttachmentFileRow
            fileName={file.fileName}
            minioFileName={file.minioFileName}
            fileContent={file?.file}
            isDeletingPossible={isDeletingPossible}
            handleFileDelete={handleFileDelete}
          />
        </div>
      ))}
    </Col>
  </Row>
);
