import React from "react";
import { Col, Row } from "reactstrap";
import { PersonalDataFM } from "../../../../Messages/PersonalDataFM";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { EducationFM } from "../../../../Messages/EducationFM";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import moment from "moment";
import { StateFeeFM } from "../../../../Messages/StateFeeFM";
import { DecisionFM } from "../../../../Messages/DecisionFM";
import { FilesFM } from "../../../../Messages/FilesFM";
import { BusinessInfoFM } from "../../../../Messages/BusinessInfoFM";
import { BusinessContactInfoFM } from "../../../../Messages/BusinessContactInfoFM";
import { BusinessSubmittingPersonFM } from "../../../../Messages/BusinessSubmittingPersonFM";
import { BusinessAreaFM } from "../../../../Messages/BusinessAreaFM";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { GeneralFM } from "../../../../Messages/GeneralFM";

type MessageType =
  | "application"
  | "personalData"
  | "contactInfo"
  | "education"
  | "files"
  | "stateFee"
  | "decision"
  | "businessRegistryInfo"
  | "businessContactInfo"
  | "submittingPerson"
  | "businessArea"
  | "activityLicense"
  | "general";

const getMessage = (type: MessageType, key: string) => {
  switch (type) {
    case "application":
      return <ApplicationFM id={key} />;
    case "personalData":
      return <PersonalDataFM id={key} />;
    case "contactInfo":
      return <ContactInfoFM id={key} />;
    case "education":
      return <EducationFM id={key} />;
    case "files":
      return <FilesFM id={key} />;
    case "stateFee":
      return <StateFeeFM id={key} />;
    case "decision":
      return <DecisionFM id={key} />;
    case "businessRegistryInfo":
      return <BusinessInfoFM id={key} />;
    case "businessContactInfo":
      return <BusinessContactInfoFM id={key} />;
    case "submittingPerson":
      return <BusinessSubmittingPersonFM id={key} />;
    case "businessArea":
      return <BusinessAreaFM id={key} />;
    case "activityLicense":
      return <ActivityLicenseFM id={key} />;
    case "general":
      return <GeneralFM id={key} />;
    default:
      break;
  }
};

export const formatDate = (date?: moment.MomentInput, format?: string) => {
  format = format || "DD.MM.YYYY";
  return date ? moment(date).format(format) : "";
};

export const renderOverViewRow = (
  type: MessageType,
  key: string,
  value?: string | JSX.Element,
  minSpace?: boolean,
  hideNull?: boolean
) => {
  return !Boolean(value) && hideNull ? null : (
    <Row>
      <Col xs={12} md={minSpace ? 2 : 4} className="col">
        { getMessage(type, key) }
      </Col>
      <Col>{ value || "-" }</Col>
    </Row>
  );
};

export const renderOverViewRowWithoutMessage = (
  value?: string | JSX.Element,
  hideNull?: boolean
) => !Boolean(value) && hideNull ? null : (
  <dd>
    <Row>
      <Col>{value || "-"}</Col>
    </Row>
  </dd>
);

export const renderRow = (key?: string, value?: string | number, hideNull?: boolean, service?: string) => {
  const ambulanceService: string = "69f71199-a1fd-4bc6-8127-f974a39daa45";
  if ((!Boolean(value) && hideNull) || (value === null && service === ambulanceService)) {
    return null;
  }
  return (
    <Row key={key}>
      <Col xs={12} md={4} className="col">
        {key}
      </Col>
      <Col>{value || "-"}</Col>
    </Row>
  );
};

export const renderDeliveryByPost = (deliveryAddress?: string) => {
  return (
    <>
      {renderOverViewRow(
        "contactInfo",
        "certificateIssuance",
        <ContactInfoFM id="certificateDeliveryByPost" />
      )}
      {renderOverViewRow(
        "contactInfo",
        "certificateDeliveryAddressTitle",
        deliveryAddress
      )}
    </>
  );
};
