import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { RoleCard } from "./RoleCard";
import { useDispatch } from "react-redux";
import { selectCompany } from "./RoleHelpers";
import { BusinessInfo } from "../../../../api_client/medre_api";

interface Props {
  companies?: BusinessInfo[];
}

export const CompanySelection = ({ companies }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!companies?.length) {
    return null;
  }

  return (
    <>
      <h5 className="related-companies-header">
        <FormattedMessage
          id="roleSelection.relatedCompanies"
          defaultMessage="Seotud ettevõtted"
        />
      </h5>
      <div className="company-cards">
        {companies?.map((company, i) => (
          <RoleCard
            key={`${company}-${i}`}
            name={company.name!}
            code={company.registryCode!}
            onClick={() => selectCompany(company, dispatch, history)}
          />
        ))}
      </div>
    </>
  );
};
