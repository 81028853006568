import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";

import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { getBaseUrl, API } from "../../../../api";
import { RootState } from "../../../../rootReducer";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { SpecialitySelectionMessageCard } from "./SpecialitySelectionMessageCard";
import { SpecialitySelectionBox } from "./SpecialitySelectionBox";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import {
  Occupation,
  Speciality,
  TaotluseAndmeteTeenusApiFactory as appDataAPI,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI
} from "../../../../../api_client/medre_api";
import { CompetenceByExamSelection } from "./CompetenceByExamSelection";

export const SpecialitySelection = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [possessedOccupations, setPossessedOccupations] = useState<
    Occupation[]
  >([]);
  const [possessedSpecialities, setPossessedSpecialities] = useState<
    Speciality[]
  >([]);
  const [savedSpecialities, setSavedSpecialities] = useState<Speciality[]>([]);
  const [appliedSpecialities, setAppliedSpecialities] = useState<Speciality[]>(
    []
  );
  const [availableSpecialities, setAvailableSpecialities] = useState<
    Speciality[]
  >([]);
  const [initialDraft] = useState(
    useSelector((state: RootState) => state.applicationDraft)
  );
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const dispatch = useDispatch();
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const selectedOccupation = applicationDraft.occupation;
  const personId = applicationDraft.personId;

  useEffect(() => {
    if (initialDraft.occupation) {
      setAvailableSpecialities(
        initialDraft.occupation.specialities as Speciality[]
      );
      dispatch(
        applicationDraftActions.updateApplicationDraft("saveAllowed", true)
      );
    }
    getUserApplicationData()
      .then((res) => {
        let occupations = res.data.possessedOccupations;
        setPossessedOccupations(occupations as Occupation[]);
        setPossessedSpecialities(
          res.data.possessedSpecialities as Speciality[]
        );
        setSavedSpecialities(res.data.savedSpecialities as Speciality[]);
        setAppliedSpecialities(res.data.appliedSpecialities as Speciality[]);
        if (occupations?.length === 1) {
          setAvailableSpecialities(occupations[0].specialities);
          dispatch(
            applicationDraftActions.updateApplicationDraft(
              "occupation",
              occupations[0]
            )
          );
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch, initialDraft, isPortal, personId]);

  const getUserApplicationData = () =>
    isPortal
      ? appDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).getUserSpecializationApplications1({
          withCredentials: true
        })
      : officialAppDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).getUserSpecializationApplications(personId as string, {
          withCredentials: true
        });

  const occupationSelectOptions = possessedOccupations.map((occupation) => ({
    value: occupation.code,
    label: occupation.name
  }));

  const handleOccupationChange = (option: Option) => {
    let modifiedDraft = { ...applicationDraft };
    let occupation = possessedOccupations.filter(
      (possessedOccupation) => possessedOccupation.code === option.value
    )[0];
    modifiedDraft.occupation = occupation;
    modifiedDraft.speciality = undefined;
    modifiedDraft.saveAllowed = false;
    setAvailableSpecialities(occupation.specialities);
    dispatch(applicationDraftActions.setApplicationDraft(modifiedDraft));
  };

  const getSelectedOccupationValue = () =>
    selectedOccupation
      ? { value: selectedOccupation.code, label: selectedOccupation.name }
      : undefined;

  const occupationSelectBoxPlaceholder = (
    <FormattedMessage
      id="specialitySelection.occupationPlaceholder"
      defaultMessage="Otsi kutset"
    />
  );

  const renderContent = () => {
    if (loading) {
      return null;
    } else if (possessedOccupations.length === 0) {
      return <SpecialitySelectionMessageCard messageType="noOccupations" />;
    } else {
      return (
        <>
          <Row>
            <Col className="offset-lg-1 offset-md-1" lg={5} md={11} xs={12}>
              <FormGroup
                className={isMobile ? "" : "form-inline justify-content-end"}
              >
                <Label for="specialitySelectionOccupation" className="mr-2">
                  <FormattedMessage
                    id="specialitySelection.occupation"
                    defaultMessage="Kutse"
                  />
                </Label>
                <SingleSelect
                  inputId="specialitySelectionOccupation"
                  className={isMobile ? "w-100" : "w-75"}
                  placeholder={occupationSelectBoxPlaceholder}
                  value={getSelectedOccupationValue()}
                  disableOnOneOption={true}
                  options={occupationSelectOptions}
                  handleOptionChange={handleOccupationChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {selectedOccupation?.specialities?.length !== 0 && (
            <SpecialitySelectionBox
              draftId={initialDraft.id}
              availableSpecialities={availableSpecialities}
              possessedSpecialities={possessedSpecialities}
              savedSpecialities={savedSpecialities}
              appliedSpecialities={appliedSpecialities}
            />
          )}
          {selectedOccupation &&
            selectedOccupation.specialities?.length === 0 && (
              <SpecialitySelectionMessageCard messageType="noSpecialities" />
            )}
          {!isPortal && <CompetenceByExamSelection />}
        </>
      );
    }
  };

  return (
    <>
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="specialitySelection.specialitySelectionTitle"
          defaultMessage="Vali eriala"
        />
      </h3>
      <p>
        <FormattedMessage
          id="specialitySelection.specialitySelectionInstruction"
          defaultMessage={`Tee omandatud eriala valik ja seejärel klõpsake nuppu "Edasi".`}
        />
      </p>
      {renderContent()}
    </>
  );
};
