import React, {FC, useState} from "react";
import "../PreviousNames/PreviousNamesDetails.scss";
import {translateCountry} from "../../../../Component/Translate/CountryTranslator";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import {
  Education,
  EducationSourceEnum,
  OfficialUserPrivilegesEnum,
  Person,
  PersonStatusEnum
} from "../../../../../api_client/medre_api";
import {ApplicantDetailsSectionKeys} from "../ApplicantNavigationList";
import {renderDocumentRows} from "../../../../Dto/File/FileReference";
import {PrimaryBadge} from "../../../../Component/Badge/PrimaryBadge";
import {getFormattedDate} from "../../../../Util/DateUtils";
import {FormattedMessage} from "react-intl";
import {PrimaryFormattedButton} from "../../../../Component/Button/PrimaryFormattedButton";
import {ProtectedFieldWrapper} from "../../../../Security/ProtectedFieldWrapper";
import {AddEducationModal} from "../../../Shared/Application/Education/AddEducationModal";
import {LinkButton} from "../../../../Component/Button/LinkButton";

interface Props {
  person: Person
  refreshEducations: () => void
}

export const Educations: FC<Props> = ({
                                        person,
                                        refreshEducations
                                      }) => {
  const educations = person.educations;
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] =
    useState<boolean>(false);
  const [editEducation, setEditEducation] = useState<Education>();
  const getLabelsFromSource = (source: EducationSourceEnum | undefined) => {
    switch (source) {
      case EducationSourceEnum.EhisAddedManually:
        return (
          <>
          <span className="fw-500">
            <PrimaryBadge id={`ehisManuallyAdded`} message="ehisManuallyAdded"/>
          </span>
            <span className="fw-500">
            <PrimaryBadge id={`manuallyAdded`} message="manuallyAdded"/>
          </span>
          </>
        );
      case EducationSourceEnum.ManuallyAdded:
        return (
          <span className="fw-500">
          <PrimaryBadge id={`manuallyAdded`} message="manuallyAdded"/>
        </span>
        );
      case EducationSourceEnum.Migrated:
        return (
          <span className="fw-500">
          <PrimaryBadge id={`migrated`} message="migrated"/>
        </span>
        );
      default:
        return null; // Return null or some default value if needed
    }
  };


  const renderSpecialization = (education: Education) => {
    return (
      <>
        <span className="text-right">
          <FormattedMessage
            id="person.education.specialization"
            defaultMessage="Spetsialiseerumine"
          />
        </span>
        <span className="fw-500">
          {education.specialization}
        </span>
      </>);
  };

  const openAddEducationButton = () => {
    setIsAddEducationModalOpen(true)
  };

  const closeAddEducationButton = () => {
    setIsAddEducationModalOpen(false);
    setEditEducation({});
    refreshEducations();
  }

  const changeEducationButton = (education: Education) => {
    return <LinkButton
        id="changeEducation"
        className="p-0 align-baseline"
        onClick={(e) => {
          openAddEducationButton();
          setEditEducation(education);
        }}/>
  }


  const addEducationButton = () => {
    return <ProtectedFieldWrapper
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}
    >
      {person.status === PersonStatusEnum.Registered &&
          <PrimaryFormattedButton
          id="addEducation"
          onClick={openAddEducationButton}/>}
    </ProtectedFieldWrapper>
  }

  return educations ? (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.Education}
      button={addEducationButton()}
    >
      <AddEducationModal isOpen={isAddEducationModalOpen}
                         person={person}
                         editEducation={editEducation}
                         onClose={closeAddEducationButton}
      ></AddEducationModal>
      <div className="detail-card-grid mt-4">
        {educations.sort((a: Education, b: Education) => {
          var firstDate = new Date(a.createdAt!);
          var secondDate = new Date(b.createdAt!);
          return secondDate.getTime() - firstDate.getTime();
        }).map((education) => {
          return (
            <div id="education-details-card" key={education.id} className="card p-4 mr-1 my-3">
              <ProtectedFieldWrapper allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}>
                {education.source === "MANUALLY_ADDED" && <><div className="migrated-education-grid-edit">
                  <td colSpan={2}>
                    {changeEducationButton(education)}
                  </td>
                </div></>}
              </ProtectedFieldWrapper>
              <hr className="mt-2"/>
              <div className="migrated-education-grid">
                                <span className="text-right">
                  <FormattedMessage
                    id="person.education.source"
                    defaultMessage="Allikas"
                  />
                </span>
                <span className="fw-500">
                  {getLabelsFromSource(education.source)}
                </span>
                <span className="text-right">
                  <FormattedMessage
                    id="person.education.country"
                    defaultMessage="Riik"
                  />
                </span>
                <span className="fw-500">
                  {translateCountry(education.countryCode)}
                </span>
                <span className="text-right">
                  <FormattedMessage
                    id="person.education.educational_institution"
                    defaultMessage="Õppeasutus"
                  />
                </span>
                <span className="fw-500">
                  {education.facilityName}
                </span>
                <span className="text-right">
                  <FormattedMessage
                    id="person.education.curriculum"
                    defaultMessage="Õppekava"
                  />
                </span>
                <span className="fw-500">
                  {education.source == EducationSourceEnum.EhisAddedManually ? education.curriculumCode + " " + education.curriculumName : education.curriculumName}
                </span>
                {education.specialization && renderSpecialization(education)}
                <span className="text-right">
                  <FormattedMessage
                    id="person.education.acquired_date"
                    defaultMessage="Õppimise lõpp"
                  />
                </span>
                <span className="fw-500">
                  {getFormattedDate(education.acquiredDate!)}
                </span>
                {education.documentType && <>
                  <span className="text-right">
                    <FormattedMessage
                        id="person.education.document_type"
                        defaultMessage="Dokumendi liik"
                    />
                  </span>
                    <span className="fw-500">
                    {education.documentType}
                  </span>
                </>
                }
                <span className="text-right">
                  <FormattedMessage
                    id="person.education.document_number"
                    defaultMessage="Dokumendi number"
                  />
                </span>
                <span className="fw-500">
                  {education.documentNumber}
                </span>
                {education.fileReferences?.length ? <>
                  <span className="fw-500 text-right mb-4">
                    <FormattedMessage
                      id="person.education.attached_files"
                      defaultMessage="Lisatud failid"
                    />
                  </span>
                  <span className="fw-500 mb-4">
                    {renderDocumentRows(education.fileReferences)}
                  </span>
                </> : <></>}
              </div>
            </div>
          );
        })}
      </div>
    </ApplicantDetailsSection>
  ) : null;
};
