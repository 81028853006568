import React from "react";
import { Card } from "reactstrap";

import { ApplicationDetailsInfoCard } from "../../../../../Component/Card/ApplicationDetailsInfoCard";
import { formatDate } from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { ClickableLicenseNumberCard } from "../../ClickableLicenseNumberCard";
import { BusinessRegistryInfoCard } from "../../BusinessRegistryInfoCard";
import { ServiceDetailsAccordion } from "../../../../Shared/ActivityLicense/Services/ServiceDetailsAccordion";
import {
  DetailedActivityLocationSuspension,
  ActivityLocation,
  ActivityLocationSuspensionSuspensionReasonEnum
} from "../../../../../../api_client/medre_api";

export interface Props {
  details: DetailedActivityLocationSuspension;
  locations?: ActivityLocation[];
  title: string;
}

export const DetailsCard = ({ details, locations, title }: Props) => {
  const { id, name, address, registryCode, email, phone } =
    details.company || {};

  const submittingPersonName =
    details.submittingPersonFirstName + " " + details.submittingPersonLastName;

  return (
    <Card className="mx-3 my-4 p-4">
      <h2>
        <span id="details" /> {title}
      </h2>
      <ApplicationDetailsInfoCard
        title="Taotluse info"
        content={[
          {
            key: "Peatamise periood",
            value:
              formatDate(details?.startDate) +
                " - " +
                formatDate(details?.endDate) || "-"
          },
          {
            key: "Peatamise põhjus",
            value:
              details.suspensionReason ===
              ActivityLocationSuspensionSuspensionReasonEnum.Personnel
                ? "Personal"
                : "Ruumid/vahendid" || "-"
          },
          { key: "Peatamise põhjendus", value: details.explanationNotes || "-" }
        ]}
      />
      <BusinessRegistryInfoCard
        id={id}
        name={name || "-"}
        address={address}
        registryCode={registryCode}
      />
      <ApplicationDetailsInfoCard
        title="Kontaktandmed"
        content={[
          { key: "E-post", value: email || "-" },
          { key: "Telefon", value: phone || "-" }
        ]}
      />
      <ApplicationDetailsInfoCard
        title="Esitav isik"
        content={[
          { key: "Nimi", value: submittingPersonName || "-" },
          { key: "Isikukood", value: details.submittingPersonIdCode || "-" }
        ]}
      />
      <ClickableLicenseNumberCard
        licenseId={details.activityLicenseId || ""}
        licenseNumber={details.activityLicenseNumber || ""}
      />
      <ApplicationDetailsInfoCard
        title="Tegevusala"
        content={[{ key: "Tegevusala", value: details.businessArea || "-" }]}
      />
      {locations && (
        <>
          <ApplicationDetailsInfoCard
            title={locations.length >= 2 ? "Tegevuskohad" : "Tegevuskoht"}
            content={
              locations?.map((l) => {
                return { key: "Tegevuskoht", value: l.fullAddress || "-" };
              }) || []
            }
          />
          <h3 className="mt-4">Teenused</h3>
          <div className="service-details-table-container">
            {locations?.map((location) => {
              return (
                <ServiceDetailsAccordion
                  key={location.id}
                  isHospital={Boolean(details.isHospital)}
                  services={location.services}
                  isMobileActivityLocation={
                    locations && locations[0].adsAdrID === "mobile"
                  }
                />
              );
            })}
          </div>
        </>
      )}
    </Card>
  );
};
