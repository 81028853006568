import { action } from "typesafe-actions";
import {
  DetailedActivityLicenseApplication,
  ActivityLicense,
  ActivityLicenseService,
  ActivityLicenseApplication,
  ActivityLocation,
  ActivityLicenseBusinessArea,
  Company,
  FileReference
} from "../../api_client/medre_api";

export const SET_ACTIVITY_LICENSE = "SET_ACTIVITY_LICENSE";
export const UPDATE_ACTIVITY_LICENSE = "UPDATE_ACTIVITY_LICENSE";
export const UPDATE_COMPANY_CONTACT = "UPDATE_DETAILED_BUSINESS_INFO_CONTACT";
export const UPDATE_LOCATION_INFO = "UPDATE_LOCATION_INFO";
export const UPDATE_ACTIVITY_LICENSE_SERVICE =
  "UPDATE_ACTIVITY_LICENSE_SERVICE";
export const REMOVE_ACTIVITY_LICENSE_SERVICE =
  "REMOVE_ACTIVITY_LICENSE_SERVICE";
export const ADD_ACTIVITY_LICENSE_FILE_REFERENCE =
  "ADD_ACTIVITY_LICENSE_FILE_REFERENCE";
export const REMOVE_ACTIVITY_LICENSE_FILE_REFERENCE =
  "REMOVE_ACTIVITY_LICENSE_FILE_REFERENCE";
export const SET_INITIAL_ACTIVITY_LOCATION_APPLICATION =
  "SET_INITIAL_ACTIVITY_LOCATION_APPLICATION";
export const SET_MOBILE_ACTIVITY_LOCATION = "SET_MOBILE_ACTIVITY_LOCATION";

const mobileActivityLocationFields = [
  {
    fullAddress: undefined,
    zipCode: undefined,
    ehakmk: undefined,
    ehakov: undefined,
    ehak: undefined,
    adsAdrID: "mobile",
    adrCode: undefined
  }
];

export type activityLicenseFieldType =
  | boolean
  | Company
  | string
  | undefined
  | number
  | ActivityLicenseBusinessArea
  | ActivityLicenseService[]
  | FileReference[];

export const activityLicenseApplicationActions = {
  setActivityLicense: (
    activityLicense:
      | ActivityLicenseApplication
      | DetailedActivityLicenseApplication
  ) => action(SET_ACTIVITY_LICENSE, activityLicense),
  updateActivityLicense: (
    fieldName: keyof ActivityLicenseApplication,
    field: activityLicenseFieldType
  ) => 
     action(UPDATE_ACTIVITY_LICENSE, {
      fieldName,
      field
    }),
  updateLocationInfo: (
    index: number,
    fieldName: keyof ActivityLocation,
    value?: string | ActivityLicenseService[] | boolean
  ) =>
    action(UPDATE_LOCATION_INFO, {
      index,
      fieldName,
      value
    }),
  setMobileActivityLocation: () =>
    action(SET_MOBILE_ACTIVITY_LOCATION, mobileActivityLocationFields),
  updateCompanyContacts: (fieldName: keyof Company, value?: string) =>
    action(UPDATE_COMPANY_CONTACT, {
      fieldName,
      value
    }),
  updateActivityLicenseService: (
    locationInfoIndex: number,
    serviceId: string,
    service: ActivityLicenseService
  ) =>
    action(UPDATE_ACTIVITY_LICENSE_SERVICE, {
      locationInfoIndex,
      serviceId,
      service
    }),
  removeActivityLicenseService: (
    locationInfoIndex: number,
    serviceId?: string
  ) =>
    action(REMOVE_ACTIVITY_LICENSE_SERVICE, { locationInfoIndex, serviceId }),
  addActivityLicenseFileReference: (files: FileReference[]) =>
    action(ADD_ACTIVITY_LICENSE_FILE_REFERENCE, { files }),
  removeActivityLicenseFileReference: (minioFileName: string) =>
    action(REMOVE_ACTIVITY_LICENSE_FILE_REFERENCE, { minioFileName }),
  setInitialActivityLocationApplication: (activityLicence: ActivityLicense) =>
    action(SET_INITIAL_ACTIVITY_LOCATION_APPLICATION, activityLicence)
};
