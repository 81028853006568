import React from "react";
import { FormattedMessage } from "react-intl";

import trash_bin from "../../../assets/images/trash_bin.svg";
import "./Button.scss";

interface Props {
  handleDelete: (e: React.SyntheticEvent) => void;
  hideText?: boolean;
  dataId?: string;
}

export const RemoveButton = ({ handleDelete, hideText, dataId }: Props) => (
  <button
    className="border-0 bg-transparent d-inline-block cursor-pointer"
    onClick={handleDelete}
    data-id={dataId}
  >
    <img src={trash_bin} alt="eemalda" />
    {!hideText && (
      <span className="red-link d-inline pl-2">
        <FormattedMessage id="attachment.removeFile" defaultMessage="Eemalda" />
      </span>
    )}
  </button>
);
