import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData,
} from "../../../Shared/Search/Filters/filterActions";
import {
  FilterContainer,
  FilterColumn,
  FilterField,
} from "../../../../Component/Filter/Filter";

export const CompanyFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(filterSelectors.selectCompanyFilters, shallowEqual);

  const set = React.useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setCompanyFilter({ key, data }));
    },
    [dispatch]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <FilterField
          id="companyFiltersCompanyName"
          label="Ettevõtte nimi"
          field={
            <Input
              id="companyFiltersCompanyName"
              value={state.companyName}
              onChange={(e) => set("companyName", e.target.value)}
              placeholder="Ettevõtte nimi"
            />
          }
        />
        <FilterField
          id="companyFiltersRegistryCode"
          label="Ettevõtte registrikood"
          field={
            <Input
              id="companyFiltersRegistryCode"
              value={state.registryCode}
              onChange={(e) => set("registryCode", e.target.value)}
              placeholder="Ettevõtte registrikood"
            />
          }
        />
        <FilterField
          id="companyFiltersRepresentativeName"
          label="Ettevõtte esindaja nimi"
          field={
            <Input
              id="companyFiltersRepresentativeName"
              value={state.representativeName}
              onChange={(e) => set("representativeName", e.target.value)}
              placeholder="Ettevõtte esindaja nimi"
            />
          }
        />
      </FilterColumn>
      <FilterColumn />
      <FilterColumn />
    </FilterContainer>
  );
};
