import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";
import { DecisionStatusEnum } from "../../api_client/medre_api";

const DecisionStatusMessages = defineMessages({
  [DecisionStatusEnum.Accepted]: {
    id: "DecisionStatusFM.ACCEPTED",
    defaultMessage: "Rahuldatud"
  },
  [DecisionStatusEnum.Declined]: {
    id: "DecisionStatusFM.DECLINED",
    defaultMessage: "Keeldutud"
  },
  [DecisionStatusEnum.ComplianceExam]: {
    id: "DecisionStatusFM.COMPLIANCE_EXAM",
    defaultMessage: "Vastavuseksam"
  },
  [DecisionStatusEnum.AptitudeTest]: {
    id: "DecisionStatusFM.APTITUDE_TEST",
    defaultMessage: "Sobivustest"
  },
  [DecisionStatusEnum.AcceptedAfterComplianceExam]: {
    id: "DecisionStatusFM.ACCEPTED_AFTER_COMPLIANCE_EXAM",
    defaultMessage: "Rahuldatud vastavuseksami järel"
  },
  [DecisionStatusEnum.AcceptedAfterAptitudeTest]: {
    id: "DecisionStatusFM.ACCEPTED_AFTER_APTITUDE_TEST",
    defaultMessage: "Rahuldatud sobivustesti järel"
  },
  [DecisionStatusEnum.WaitingForSignature]: {
    id: "DecisionStatusFM.WAITING_FOR_SIGNATURE",
    defaultMessage: "Allkirjastamise ootel"
  }
});

export const DecisionStatusFM = ({
  id
}: {
  id: keyof typeof DecisionStatusMessages;
}) => <FormattedMessage {...DecisionStatusMessages[id]} />;

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

type Message =
  | "decision"
  | "decisionNumber"
  | "decisionType"
  | "decisionStatus"
  | "decisionDate"
  | "download";

export const DecisionFM = (props: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    signedNumberDate: {
      id: "decisionFM.signedNumberDate",
      defaultMessage: "nr. {number} {date}"
    },
    decision: {
      id: "decisionFM.decision",
      defaultMessage: "Otsus"
    },
    decisionNumber: {
      id: "decisionFM.decisionNumber",
      defaultMessage: "Otsuse number"
    },
    decisionType: {
      id: "decisionFM.decisionType",
      defaultMessage: "Otsuse tüüp"
    },
    decisionStatus: {
      id: "decisionFM.decisionStatus",
      defaultMessage: "Otsuse staatus"
    },
    decisionDate: {
      id: "decisionFM.decisionDate",
      defaultMessage: "Otsuse kuupäev"
    },
    download: {
      id: "decisionFM.download",
      defaultMessage: "Lae otsus alla"
    }
  });

  return <FormattedMessage {...messages[props.id as Message]} {...props} />;
};
