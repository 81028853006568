import React, { FC } from "react";
import { usePagination, useTable } from "react-table";
import { FormattedMessage } from "react-intl";
import { NoResults } from "../Table/NoResults";
import useGeneralPractitionerLists from "./useGeneralPractitionerLists";
import { Loader } from "../../../Component/Loader/Loader";
import { generalPractitionerListPortalColumns } from "../../Shared/GeneralPractitioners/GeneralPractitionerListColumns";
import { GeneralPractitionerList } from "../../../../api_client/medre_api";
import { ReactTable } from "../../../Component/Table/ReactTable";

interface Props {}

export const PortalGPListTable: FC<Props> = () => {
  const { loading, list } = useGeneralPractitionerLists();

  const table = useTable<GeneralPractitionerList>(
    {
      columns: generalPractitionerListPortalColumns,
      data: list,
      manualPagination: false
    },
    usePagination
  );

  return (
    <div className="p-3 h-100 d-flex flex-column">
      <div className="d-flex align-items-center my-4">
        <h1 className="fw-300">
          <FormattedMessage
            id="GPListTableContainer.title"
            defaultMessage="Perearsti nimistud"
          />
        </h1>
      </div>

      {loading ? (
        <Loader />
      ) : !list.length ? (
        <NoResults>
          <FormattedMessage
            id="GPListTable.noResults"
            defaultMessage="Nimistud puuduvad"
          />
        </NoResults>
      ) : (
        <ReactTable<GeneralPractitionerList>
          table={table}
          rows={table.rows}
          className="border-0"
          square={true}
        />
      )}
    </div>
  );
};
