import React from "react";
import { FormattedMessage } from "react-intl";

import "./ActivityLicenseSuspensionModal.scss";
import { SuspensionModalContainer } from "./SuspensionModalContainer";
import {
  ActivityLicense,
  ActivityLicenseApplicationProceedingTypeEnum, ActivityLicenseSuspensionInfo
} from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activityLicense?: ActivityLicense;
  activityLicenseSuspensionInfo?: ActivityLicenseSuspensionInfo;
  onLicenseSuspensionSubmit?: () => void;
}

export const ActivityLicenseSuspensionModal = ({
  isOpen,
  onClose,
  activityLicense,
  activityLicenseSuspensionInfo,
  onLicenseSuspensionSubmit
}: Props) => {
  const createTitle = () => (
    <FormattedMessage
      id="suspendActivityLicenseModal.header"
      defaultMessage="Peata tegevusluba {activityLicenseNumber}"
      values={{ activityLicenseNumber: activityLicense?.number }}
    />
  );

  return (
    <SuspensionModalContainer
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={createTitle()}
      suspensionType={
        ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension
      }
      idToSuspend={activityLicense?.id || ""}
      activityLicenseSuspensionInfo={activityLicenseSuspensionInfo}
      onSubmitEmit={onLicenseSuspensionSubmit}
    />
  );
};
