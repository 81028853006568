import React, { FC, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../../Style/SideMenu.scss";
import { SideMenuItem } from "../../SideMenuItem/SideMenuItem";
import home_icon from "../../../../assets/images/home.svg";
import { LogoutButton } from "../../../Security/LogoutButton";
import add_circle_outline from "../../../../assets/images/add_circle_outline.svg";
import search from "../../../../assets/images/search.svg";
import page_icon from "../../../../assets/images/page.svg";
import gplist_icon from "../../../../assets/images/gplist.svg";
import settings from "../../../../assets/images/settings.svg";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { personActions } from "../../../Person/personActions";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import { RootState } from "../../../rootReducer";
import { OfficialUserPrivilegesEnum } from "../../../../api_client/medre_api";
import { UserInfo } from "../../../Dto/Security/UserInfo";

interface Props {
  isSideMenuCollapsed: boolean;
}

const ROUTES = [
  { link: "/", title: "Avaleht", icon: home_icon, exact: true },
  {
    link: "/my-proceedings",
    title: "Minu menetlused",
    icon: page_icon,
    exact: false
  },
  { link: "/search", title: "Otsing", icon: search, exact: false },
  {
    link: "/new-application",
    title: "Taotluse sisestamine",
    icon: add_circle_outline,
    exact: false
  },
  {
    link: "/general-practitioner-lists",
    title: "Perearsti nimistud",
    icon: gplist_icon,
    exact: false
  }
];

const ADMIN_ROUTES = [
    ...ROUTES,
    { link: "/system-administration", title: "Süsteemi administreerimine", icon: settings, exact: false }
];

const OBSERVER_ROUTES = [
  { link: "/", title: "Avaleht", icon: home_icon, exact: true },
  { link: "/search", title: "Otsing", icon: search, exact: false },
  {
    link: "/general-practitioner-lists",
    title: "Perearsti nimistud",
    icon: gplist_icon,
    exact: false
  }
];

export const OfficialSideMenu: FC<Props> = ({ isSideMenuCollapsed }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector(
    (state: RootState) => Object(state.user.userInfo) as UserInfo
  );

  const canProceede = useMemo(
    () => roles?.includes(OfficialUserPrivilegesEnum.Proceede),
    [roles]
  );
  
  const isAdmin = useMemo(
    () => roles?.includes(OfficialUserPrivilegesEnum.Admin),
    [roles]
  );

  const clearSiteData = () => {
    dispatch(applicationDraftActions.setApplicationDraft({}));
    dispatch(activityLicenseApplicationActions.setActivityLicense({}));
    dispatch(personActions.setPerson({}));
  };

  const userRoutes = canProceede ? (isAdmin ? ADMIN_ROUTES : ROUTES) : OBSERVER_ROUTES;

  return (
    <div id="global-sidemenu" className="global-sidemenu">
      <ul className="global-sidemenu-list">
        {userRoutes.map(({ link, title, icon, exact }) => (
          <li key={link}>
            <NavLink
              onClick={clearSiteData}
              exact={exact}
              to={link}
              activeClassName="item-active"
            >
              <SideMenuItem
                isSideMenuCollapsed={isSideMenuCollapsed}
                title={title}
                icon={icon}
              />
            </NavLink>
          </li>
        ))}
      </ul>
      {!isSideMenuCollapsed && (
        <div className="bottom-container">
          <div className="bottom-container-logout-button p-3">
            <LogoutButton isWhite={true} />
          </div>
        </div>
      )}
    </div>
  );
};
