import React from "react";
import { useDispatch } from "react-redux";

import { getBaseUrl, API } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import { SignActions } from "../Application/Details/Sign/SignActions";
import { AcceptanceActions } from "../Application/Details/Acceptance/AcceptanceActions";
import { DecisionActions } from "../Application/Details/Decision/DecisionActions";
import {
  FileObjectType,
  DetailedActivityLicenseGPListApplication,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitApp,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  DecisionType,
  GeneralPractitionerListApplicationStatus
} from "../../../../../api_client/medre_api";

interface Props {
  details?: DetailedActivityLicenseGPListApplication;
  setDetails: (application: DetailedActivityLicenseGPListApplication) => void;
  refresh: () => void;
  setIsLoading: (value: boolean) => void;
}

export const GeneralPractitionerApplicationDetailsActions = ({
  details,
  setDetails,
  refresh,
  setIsLoading
}: Props) => {
  const dispatch = useDispatch();

  const changeStatusTo = (
    newStatus:
      | ActivityLicenseApplicationStatus
      | GeneralPractitionerListApplicationStatus
  ) => {
    setIsLoading(true);
    officialActivityPermitApp(undefined, getBaseUrl(), API)
      .changeApplicationStatus1(
        details!.id!,
        newStatus as GeneralPractitionerListApplicationStatus,
        {
          withCredentials: true
        }
      )
      .then((res) => {
        setDetails(res.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
      })
      .catch(() =>
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch)
      )
      .finally(() => setIsLoading(false));
  };

  const renderSignButtons = (signButtonId: ButtonName) => {
    return (
      <SignActions
        signButtonId={signButtonId}
        refresh={refresh}
        changeStatusTo={changeStatusTo}
        decisionId={details?.decisions?.find((d) => !d.signed)?.id!}
      />
    );
  };

  const renderContent = () => {
    if (!details) {
      return;
    }
    switch (details?.status) {
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return (
          <AcceptanceActions
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            newAcceptanceStatus={
              ActivityLicenseApplicationStatus.UploadAcceptedDecision
            }
            canDecline={false}
          />
        );
      case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
        return (
          <DecisionActions
            fileObjectType={FileObjectType.ActivityLicenseDecision}
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            updateApplication={refresh}
            decision={{
              id: details.id!,
              type: DecisionType.GeneralPractitionerListApplication
            }}
          />
        );
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
        return renderSignButtons("signApplicationAcceptanceDecision");
      default:
        return;
    }
  };

  return (
    <>
      <div className="mt-1 mb-4">{renderContent()}</div>
    </>
  );
};
