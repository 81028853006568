import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import { SubmissionTimeLineFM } from "../../../../Messages/SubmissionTimelineFM";

interface Props {
  first?: boolean;
  last?: boolean;
  icon: string;
  userText: string;
  status?: SubmissionTimeLineEventStatus;
  comment: string;
  days?: number | string;
  info?: string;
}

export type SubmissionTimeLineEventStatus = "done" | "in-progress";

export const SubmissionTimeLineEvent = ({
  icon,
  userText,
  status,
  comment,
  days,
  info
}: Props) => {
  return (
    <div className={classNames("timeline-event", status)}>
      <div className="timeline-event-info-wrapper">
        {info && (
          <div className="timeline-event-info">
            <span>
              <SubmissionTimeLineFM id={info} days={days} />
            </span>
          </div>
        )}
      </div>
      <div className="timeline-event-comment">
        <SubmissionTimeLineFM id={comment} />
      </div>
      <div className={classNames("timeline-event-icon", status)}>
        <FontAwesomeIcon icon={faCheck} className={status} fixedWidth={true} />
      </div>
      <div className="timeline-event-user">
        <img src={icon} alt="icon" />
        <span>
          <SubmissionTimeLineFM id={userText} />
        </span>
      </div>
    </div>
  );
};
