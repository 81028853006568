import React from "react";

import { WarningBadge } from "../Component/Badge/WarningBadge";
import { SuccessBadge } from "../Component/Badge/SuccessBadge";
import { DecisionStatusEnum } from "../../api_client/medre_api";

interface Props {
  status?: DecisionStatusEnum;
  isSigned?: boolean;
  index?: number;
}

export const DecisionState = ({ status, isSigned, index }: Props) => {
  if (status) {
    switch (status) {
      case DecisionStatusEnum.Accepted:
        return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
      case DecisionStatusEnum.Declined:
        return <WarningBadge id={`declined-${index}`} message="declined" />;
      case DecisionStatusEnum.ComplianceExam:
        return (
          <WarningBadge
            id={`directedToComplianceExam-${index}`}
            message="directedToComplianceExam"
          />
        );
      case DecisionStatusEnum.AptitudeTest:
        return (
          <WarningBadge
            id={`directedToAptitudeTest-${index}`}
            message="directedToAptitudeTest"
          />
        );
      case DecisionStatusEnum.AcceptedAfterComplianceExam:
        return (
          <WarningBadge
            id={`decisionAcceptedAfterComplianceExam-${index}`}
            message="decisionAcceptedAfterComplianceExam"
          />
        );
      case DecisionStatusEnum.AcceptedAfterAptitudeTest:
        return (
          <WarningBadge
            id={`decisionAcceptedAfterAptitudeTest-${index}`}
            message="decisionAcceptedAfterAptitudeTest"
          />
        );
      case DecisionStatusEnum.WaitingForSignature:
        return (
          <WarningBadge
            id={`waitingForSignature-${index}`}
            message="waitingForSignature"
          />
        );

      default:
        return null;
    }
  }

  if (isSigned) {
    return <SuccessBadge id={`success-${index}`} message="signed" />;
  }

  return <WarningBadge id={`warning-${index}`} message="waitingForSignature" />;
};
