import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import fetchApplicantOptions, { ApplicantOption } from "../../../Shared/GeneralPractitioners/fetchApplicantOptions";
import { API, getBaseUrl } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ModalKeys } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { Loader } from "../../../../Component/Loader/Loader";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyPhysicianListAPI,
  PersonSearch,
  Substitute
} from "../../../../../api_client/medre_api";
import { GeneralPractitionerListDoctorField } from "../../../Shared/GeneralPractitioners/GeneralPractitionerListDoctor";
import { DOCTOR } from "../../../../Constants";
import { getDate, getNextDate, getPreviousDate } from "../../../../Util/DateUtils";
import { displayAlert } from "../../../../Util/AlertUtil";
import { ErrorMessage } from "../../../../Component/ErrorField/ErrorMessage";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import {
  useGeneralPractitionerListGeneralPractitionerSelector,
  useGeneralPractitionerListSelector
} from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";

const titles = {
  [ModalKeys.assignSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.assignSubstituteTitle"
      defaultMessage="Asendaja määramine"
    />
  ),
  [ModalKeys.editSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.editSubstituteTitle"
      defaultMessage="Muuda nimistu asendaja"
    />
  )
};

const buttons = {
  [ModalKeys.assignSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.assignSubstituteButton"
      defaultMessage="Määra asendaja"
    />
  ),
  [ModalKeys.editSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.editSubstitute"
      defaultMessage="Muuda asendaja"
    />
  )
};

interface Props {
  id: ModalKeys.assignSubstitute | ModalKeys.editSubstitute;
  onClose: () => void;
}

interface FormValues {
  applicant: ApplicantOption;
  id?: string;
  occupationCode?: string;
  activationDate?: string;
  deactivationDate?: string;
}

export const SubstituteModal: FC<Props> = ({ id: modalId, onClose }) => {
  const { id } = useParams<{ id: string }>();
  const isEditing = useMemo(
    () => modalId === ModalKeys.editSubstitute,
    [modalId]
  );
  const [isLoading, setLoading] = useState(isEditing);
  const list = useGeneralPractitionerListSelector();
  const activeSubstitute: Substitute | null | undefined =  list.substitute;
  const [isDeactivationDateChanged, setIsDeactivationDateChanged] = useState<boolean>(false);
  const [substituteDeactivation, setSubstituteDeactivation] = useState(activeSubstitute.deactivationDate);
  const gp = useGeneralPractitionerListGeneralPractitionerSelector();

  const { watch, setValue, handleSubmit, control, formState, reset } =
    useForm<FormValues>({
      mode: "onChange",
      defaultValues: {
        applicant: {}
      }
    });

  useEffect(() => {
    (async () => {
      if (!isEditing) {
        return;
      }

      if (activeSubstitute) {
        reset({
          applicant: (
            await fetchApplicantOptions({ occupationCode: activeSubstitute.occupationCode }))
            .find(({ occupationCode }: any) => occupationCode === activeSubstitute.occupationCode),
          ...activeSubstitute
        });
      }
      setLoading(false);
    })();
  }, [isEditing, id, reset]);

  const { isValid, isSubmitting } = formState;
  const { id: personId, applicant, activationDate, deactivationDate } = watch();

  const loadSubstituteOptions = useCallback(async (occupationCode: string): Promise<PersonSearch[]> => {
      const formattedOccupationCode = occupationCode.replace(/\s+/g, '').trim().toUpperCase();

      if (formattedOccupationCode.length !== 6) return [];

      const options = await fetchApplicantOptions({ occupationCode: formattedOccupationCode });

      return options.filter(option =>
        option["occupationName"] === DOCTOR &&
        option["occupationCode"] === formattedOccupationCode);
    }, []
  );

  const handleDeleteApplicant = useCallback(() => {
    setValue("applicant", {});
  }, [setValue]);

  useEffect(() => {
    setValue("id", applicant.id ?? "", { shouldValidate: true });
    setValue("occupationCode", applicant.occupationCode ?? "", {
      shouldValidate: true
    });
  }, [applicant, setValue]);

  const handleChangeActivationDate = useCallback(
    (date: Date) => {
      setValue("activationDate", date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleChangeDeactivationDate = useCallback((date: Date) => {
    if (modalId === ModalKeys.assignSubstitute) {
      setValue("deactivationDate", date, { shouldValidate: true });
      return;
    }

    const newDeactivationDate = getDate(new Date(date));
    const currentDeactivationDate = getDate(new Date(substituteDeactivation!));

    if (currentDeactivationDate.valueOf() !== newDeactivationDate.valueOf()) {
      setValue("deactivationDate", date, { shouldValidate: true });
      setIsDeactivationDateChanged(true);
    } else {
      setValue("deactivationDate", new Date(substituteDeactivation!), { shouldValidate: true });
      setIsDeactivationDateChanged(false);
    }

  }, [setValue, isDeactivationDateChanged]);

  const { fetchGPList, fetchGPListModifications } = useGeneralPractitionerList();
  const dispatch = useDispatch();
  const onSubmit = useCallback(async (values: Substitute) => {
    try {
      await officialFamilyPhysicianListAPI(undefined, getBaseUrl(), API)
        .updateSubstituteModification(id, values, { withCredentials: true });
      await fetchGPList(id!);
      await fetchGPListModifications(id!);
      onClose();
      displayAlert("substituteSaveSuccess", AlertType.Success, dispatch);
    } catch (error) {
      !!(gp.id && gp.deactivationDate) ?
        displayAlert("substituteSaveFailureGPExists", AlertType.Danger, dispatch) :
        displayAlert("substituteSaveFailure", AlertType.Danger, dispatch);
      onClose();
    }
    },
    [fetchGPList, id, onClose, dispatch]
  );

  const getDeactivationMinDate = (): Date => {
    return activationDate &&
      getDate(new Date(activationDate)) >= getDate(new Date()) ?
        getNextDate(activationDate) :
        new Date();
  }

  const substituteMaxWorkingPeriodDeactivationDate = (): Date => {
    const maxDeactivationDate = getPreviousDate(activationDate);
    maxDeactivationDate.setFullYear(maxDeactivationDate.getFullYear() + 1);
    return getDate(maxDeactivationDate);
  }

  const validationStates = useMemo(() => {
    const deactivation = getDate(new Date(deactivationDate!));
    const activation = getDate(new Date(activationDate!));
    return {
      isDeactivationDateBeforeActivationDate: deactivation < activation,
      isOverMaxSubstitutePeriod: deactivation > substituteMaxWorkingPeriodDeactivationDate()
    }
  }, [activationDate, deactivationDate]);

  const { isDeactivationDateBeforeActivationDate, isOverMaxSubstitutePeriod } = validationStates;

  const validationMessages = {
    deactivationBeforeActivation: "Asenduse perioodi lõpukuupäev peab olema hilisem kui alguskuupäev.",
    overMaxSubstitutePeriod: `Asenduse periood ei tohi ületada 1 aastat, viimane asenduse kuupäev on ${formatDate(substituteMaxWorkingPeriodDeactivationDate())}`
  }

  const getValidationMessage = () => {
    if (isDeactivationDateBeforeActivationDate) {
      return <ErrorMessage message={validationMessages.deactivationBeforeActivation} />;
    }
    if (isOverMaxSubstitutePeriod) {
      return <ErrorMessage message={validationMessages.overMaxSubstitutePeriod} />;
    }
  }

  const isSubmitDisabled = (): boolean => {
    const isDisabledCommon = !isValid || isSubmitting || isDeactivationDateBeforeActivationDate || isOverMaxSubstitutePeriod;
    const isDisabledEdit = isDisabledCommon || !isDeactivationDateChanged;
    return modalId === ModalKeys.assignSubstitute ? isDisabledCommon : isDisabledEdit;
  }

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      {isLoading && <Loader backdrop={true} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{titles[modalId]}</ModalHeader>
        <ModalBody className="pt-0 pb-0">
          <Card className="mt-3 mb-3">
            <CardHeader className="p-3 border-0 position-relative">
              <Controller
                name="id"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={<input type="hidden" />}
              />
              <Controller
                name="occupationCode"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={<input type="hidden" />}
              />

              <Controller
                name="applicant"
                control={control}
                render={(renderProps: ControllerRenderProps<Record<string, any>>) => (
                  <GeneralPractitionerListDoctorField
                    id="gpListSubstituteModalApplicant"
                    {...renderProps}
                    handleDelete={handleDeleteApplicant}
                    loadOptions={loadSubstituteOptions}
                    modalId={modalId}
                  />
                )}
              />
            </CardHeader>
          </Card>

          <Card className="mt-3 mb-3">
            <CardHeader className="p-3 border-0 position-relative">
              <FormGroup row={true} className="mb-0">
                <Label
                  htmlFor="gpListSubstituteModalActivationDate"
                  sm={4}
                  className="text-right"
                >
                  Asenduse periood
                </Label>
                <Col sm={4} className="flex-grow-1">
                  <Controller
                    name="activationDate"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ value }: ControllerRenderProps<Record<string, any>>) => (
                      <DatePickerComponent
                        id="gpListSubstituteModalActivationDate"
                        onDateChange={handleChangeActivationDate}
                        selectedDate={value}
                        disabled={!personId || modalId === ModalKeys.editSubstitute}
                      />
                    )}
                  />
                </Col>
                <Col sm={4} className="flex-grow-1">
                  <Controller
                    name="deactivationDate"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ value }: ControllerRenderProps<Record<string, any>>) => (
                      <>
                        <DatePickerComponent
                          onDateChange={handleChangeDeactivationDate}
                          selectedDate={value}
                          disabled={!personId}
                          minDate={getDeactivationMinDate()}
                        />
                        { value &&
                            <div className="d-block mt-2">
                              { getValidationMessage() }
                            </div>
                        }
                      </>
                    )}
                  />
                </Col>
              </FormGroup>
            </CardHeader>
          </Card>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton id="cancel" onClick={onClose} />
          <Button
            type="submit"
            color="primary"
            disabled={ isSubmitDisabled() }
          >
            {buttons[modalId]}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
