import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Option as filterOption } from "react-select/src/filters";

import { filterSelectors } from "../filterStore";
import { filterActions, FilterItemData } from "../filterActions";
import { FilterColumn, FilterContainer, FilterField } from "../../../../../Component/Filter/Filter";
import { getBaseUrl, API } from "../../../../../api";
import { Option, SingleSelect } from "../../../../../Component/Select/SingleSelect";
import {
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI,
  AvalikAadressideAndmeteTeenusApiFactory as publicAddressDataAPI, Service, County, ActivityLicenseBusinessArea
} from "../../../../../../api_client/medre_api";
import { Loader } from "../../../../../Component/Loader/Loader";
import { Item, makeOptions, selectProps, statuses } from "./ActivityLicenseFIltersCommons";
import { publicActivityLicenseInsuranceContractStatuses, singleSelectProps } from "../FilterUtils";

export const PublicActivityLicenseFilters = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [serviceOptions, setServiceOptions] = useState<Array<Option>>([]);
  const [businessAreaOptions, setBusinessAreaOptions] = useState<Array<Option>>([]);
  const [hospitalAreaOptions, setHospitalAreaOptions] = useState<Array<Option>>([]);
  const [countyOptions, setCountyOptions] = useState<Array<Option>>([]);

  const state = useSelector(
    filterSelectors.selectPublicActivityLicenseFilters,
    shallowEqual
  );

  const loadServices = (): void => {
    publicActivityPermitAPI(undefined, getBaseUrl(), API)
      .getServices({
        withCredentials: true
      })
      .then((
        { data: services }
      ) => {
        setServiceOptions(makeOptions(services));
      });
  };

  const loadClassifiersSpecificServices = (businessAreaIds: string[]): void => {
    publicActivityPermitAPI(undefined, getBaseUrl(), API)
      .getBusinessAreaClassifiersServices(businessAreaIds, {
        withCredentials: true
      })
      .then((
        { data: services }
      ) => {
        setServiceOptions(makeOptions(services));
      });
  };

  const fetchDynamicServiceOptions = (businessAreaIds: string[]): void =>
    businessAreaIds.length === 0 ? loadServices() : loadClassifiersSpecificServices(businessAreaIds);

  const processResponses = (
    services: Service[],
    regularAreas: ActivityLicenseBusinessArea[] | undefined,
    hospitalTypeAreas: ActivityLicenseBusinessArea[] | undefined,
    counties: County[]): void => {
    setServiceOptions(makeOptions(services));
    setBusinessAreaOptions(makeOptions(regularAreas as Item[]));
    setHospitalAreaOptions(makeOptions(hospitalTypeAreas as Item[]));
    setCountyOptions(makeOptions(counties as Item[]));

    if (state.businessAreaIds) {
      const mappedIds = state.businessAreaIds.map(id => id.value);
      fetchDynamicServiceOptions(mappedIds);
    }
  };

  useEffect(() => {
    Promise.all([
      publicActivityPermitAPI(undefined, getBaseUrl(), API).getServices({
        withCredentials: true
      }),
      publicActivityPermitAPI(
        undefined,
        getBaseUrl()
      ).getBusinessAreaClassifiers({
        withCredentials: true
      }),
      publicAddressDataAPI(undefined, getBaseUrl(), API).getCounties()
    ])
      .then(
        ([
          { data: services },
          {
            data: { regularAreas, hospitalTypeAreas }
          },
          { data: counties }
        ]) => {
          processResponses(services, regularAreas, hospitalTypeAreas, counties);
        }
      )
      .finally(() => setLoading(false));
  }, []);

  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setPublicActivityLicensesFilter({ key, data }));
    },
    [dispatch]
  );

  const handleChangeSelect = useCallback(
    (option: Option, name: string) => {
      const value = option?.value ?? null;
      set(name, value);
    },
    [set]
  );

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );

  const serviceFilterOptions = (option: filterOption, rawInput: string) =>
    !!option.label.toLowerCase().match(rawInput.toLowerCase());

  return (
    <FilterContainer>
      { loading && <Loader absolute /> }
      <FilterColumn>
        <FilterField
          id="activityLicenseFiltersCompanyName"
          label={
            <FormattedMessage
              id="filters.companyLabel"
              defaultMessage="Teenuse osutaja"
            />
          }
          field={
            <Input
              id="activityLicenseFiltersCompanyName"
              value={state.companyName}
              onChange={handleChangeInput}
              name="companyName"
              placeholder="Ettevõtte nimi"
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersBusinessArea"
          label={
            <FormattedMessage
              id="filters.businessArea"
              defaultMessage="Tegevusala"
            />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersBusinessArea"
              name="businessAreaId"
              options={businessAreaOptions}
              handleOptionChange={handleChangeSelect}
              value={state.businessAreaId}
              {...selectProps}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersServiceId"
          label={
            <FormattedMessage id="filters.service" defaultMessage="Teenus" />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersServiceId"
              name="serviceId"
              options={serviceOptions}
              handleOptionChange={handleChangeSelect}
              value={state.serviceId}
              {...selectProps}
              isSearchable
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersCounty"
          label={
            <FormattedMessage id="filters.county" defaultMessage="Maakond" />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersCounty"
              name="countyId"
              options={countyOptions}
              handleOptionChange={handleChangeSelect}
              value={state.countyId}
              {...selectProps}
            />
          }
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="activityLicenseFiltersActivityLicenseNumber"
          label={
            <FormattedMessage
              id="filters.activityLicenseNumber"
              defaultMessage="Tegevusloa number"
            />
          }
          field={
            <Input
              id="activityLicenseFiltersActivityLicenseNumber"
              value={state.code}
              onChange={handleChangeInput}
              name="code"
              placeholder="Tegevusloa number"
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersHospitalId"
          label={
            <FormattedMessage
              id="filters.hospitalType"
              defaultMessage="Haigla liik"
            />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersHospitalId"
              name="hospitalId"
              options={hospitalAreaOptions}
              handleOptionChange={handleChangeSelect}
              value={state.hospitalId}
              {...selectProps}
              isSearchable
              filterOptions={serviceFilterOptions}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersValidity"
          label={
            <FormattedMessage id="filters.validity" defaultMessage="Staatus" />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersValidity"
              name="status"
              options={statuses}
              handleOptionChange={handleChangeSelect}
              value={state.status}
              {...selectProps}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersInsuranceContractStatus"
          label={<FormattedMessage id="filters.insuranceContract" defaultMessage="Kindlustus" />}
          field={
            <SingleSelect
              inputId="activityLicenseFiltersInsuranceContractStatus"
              name="insuranceContractStatuses"
              options={publicActivityLicenseInsuranceContractStatuses}
              handleOptionChange={(e): void =>
                e === null
                  ? set("insuranceContractStatuses", [])
                  : set("insuranceContractStatuses", e.value)}
              value={state.insuranceContractStatuses}
              placeholder={
              <FormattedMessage
                id="activityLicenseFilters.selectInsuranceContractStatus"
                defaultMessage="Vali kindlustuse staatus"
              />
            }
            {...singleSelectProps}
            />
          }
        />
      </FilterColumn>
      <FilterColumn />
    </FilterContainer>
  );
};
