import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { AxiosPromise } from "axios";

import { GeneralFM } from "../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { CompanyInfoRow } from "../Applications/CompanyInfoRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { DecisionsRow } from "../Applications/DecisionsRow";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import {
  ActivityLicenseRestoration,
  ActivityLicenseApplicationProceedingTypeEnum,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import { Loader } from "../../../../Component/Loader/Loader";
import { getBaseUrl, API } from "../../../../api";

interface Props {
  isOpen: boolean;
  id: string;
  proceedingType: ActivityLicenseApplicationProceedingTypeEnum;
  restorationEndpointRef?: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseRestoration>;
}

export const RestorationAccordion = ({
  isOpen,
  id,
  proceedingType,
  restorationEndpointRef
}: Props) => {
  const [restoration, setRestoration] = useState<ActivityLicenseRestoration>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
    useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={restoration?.status}
      isDetailed={false}
    />
  );

  const loadRestoration = useCallback(() => {
    setIsLoading(true);
    restorationEndpointRef!(id, {
      withCredentials: true
    })
      .then((res) => setRestoration(res.data))
      .finally(() => setIsLoading(false));
  }, [id, restorationEndpointRef]);

  useEffect(() => {
    if (isOpen) {
      loadRestoration();
    }
  }, [isOpen, loadRestoration]);

  if (isLoading) {
    return <Loader backdrop={true} />;
  }
  return (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            restoration?.applicationNumber!.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            "licenseNumber",
            restoration?.licenseNumber!
          )}
          {renderOverViewRow(
            "activityLicense",
            "submissionDate",
            formatDate(restoration?.createdAt)
          )}
          {renderOverViewRow("application", "state", state)}
          {renderOverViewRow(
            "activityLicense",
            "businessArea",
            restoration?.businessAreaName
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={restoration?.company}
        />
        <ActivityLocationsRow
          isOpen={isActivityLocationsRowOpen}
          handleClick={() =>
            setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
          }
          activityLocations={restoration?.locations}
          loadServiceEndpoint={
            activityPermitAPI(undefined, getBaseUrl(), API).getService1
          }
          isHospital={Boolean(restoration?.isHospital)}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={restoration?.decisions}
        />
      </td>
    </tr>
  );
};
