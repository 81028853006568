import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { GeneralPractitionerListStatusEnum } from "../../../../api_client/medre_api";

const BASE_GENERAL_PRACTITIONERS_DETAILS_SECTIONS = {
  DETAILS: {
    id: "details",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.details"
        defaultMessage="Nimistu detail- ja kontaktandmed"
      />
    )
  },
  EMPLOYEES: {
    id: "employees",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.employees"
        defaultMessage="Nimistu töötajad"
      />
    )
  },
  HOURS: {
    id: "hours",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.hours"
        defaultMessage="Vastuvõtuajad"
      />
    )
  },
  CONSULTATION_TIMES: {
    id: "consultationTimes",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.consultationTimes"
        defaultMessage="Telefonikonsultatsiooni ajad"
      />
    )
  },
  SUBSTITUTE_DOCTORS: {
    id: "substituteDoctors",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.substituteDoctors"
        defaultMessage="Asendusarstid"
      />
    )
  },
  SUBSTITUTE_REPLACEMENT_PERIOD: {
    id: "substituteReplacementPeriod",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.substituteReplacementPeriod"
        defaultMessage="Asenduse perioodid"
      />
    )
  }
};

const OFFICIAL_GENERAL_PRACTITIONERS_DETAILS_SECTIONS = {
  HISTORY: {
    id: "history",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.history"
        defaultMessage="Ajalugu"
      />
    )
  },
  LIQUIDATION: {
    id: "liquidation",
    title: (
      <FormattedMessage
        id="GPListDetailsQuickLinks.liquidation"
        defaultMessage="Nimistu likvideerimine"
      />
    )
  }
};

const GENERAL_PRACTITIONERS_DETAILS_SECTIONS = {
  ...BASE_GENERAL_PRACTITIONERS_DETAILS_SECTIONS,
  ...OFFICIAL_GENERAL_PRACTITIONERS_DETAILS_SECTIONS
};

const useOfficialGeneralPractitionersDetailsSectionsList = (
  listStatus?: GeneralPractitionerListStatusEnum
) =>
  useMemo(() => {
    if (
      listStatus &&
      listStatus !== GeneralPractitionerListStatusEnum.Register
    ) {
      return [
        ...Object.values(BASE_GENERAL_PRACTITIONERS_DETAILS_SECTIONS),
        OFFICIAL_GENERAL_PRACTITIONERS_DETAILS_SECTIONS.HISTORY
      ];
    }

    return Object.values({
      ...BASE_GENERAL_PRACTITIONERS_DETAILS_SECTIONS,
      ...OFFICIAL_GENERAL_PRACTITIONERS_DETAILS_SECTIONS
    });
  }, [listStatus]);

const usePortalGeneralPractitionersDetailsSectionsList = () =>
  useMemo(() => Object.values(BASE_GENERAL_PRACTITIONERS_DETAILS_SECTIONS), []);

export {
  GENERAL_PRACTITIONERS_DETAILS_SECTIONS,
  useOfficialGeneralPractitionersDetailsSectionsList,
  usePortalGeneralPractitionersDetailsSectionsList
};
