import { Card, Col, Row } from "reactstrap";
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../../../../Locale/LocaleMessage";

type OccupationSelectionMessageCardType = "noOccupations";

interface Props {
  messageType: OccupationSelectionMessageCardType;
}

export const ApplicationCertificateOccupationMessageCard = ({
  messageType
}: Props) => {
  const messages: Record<OccupationSelectionMessageCardType, LocaleMessage> =
    defineMessages({
      noOccupations: {
        id: "ApplicationCertificate.noOccupations",
        defaultMessage:
          "Puuduvad kutsed, et kutsekvalifikatsiooni tunnustamise tõendit taotleda."
      }
    });

  return (
    <Row>
      <Col className="offset-md-1 offset-lg-2" lg={7} md={9} xs={12}>
        <Card className="p-3 w-100 d-flex align-items-center justify-content-center info-card">
          <FormattedMessage {...messages[messageType]} />
        </Card>
      </Col>
    </Row>
  );
};
