import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { prettifyBusinessStatus, prettifyRoleName } from "../../../Dto/Company/Company";
import { API, getBaseUrl } from "../../../api";
import { Loader } from "../../../Component/Loader/Loader";
import { ApplicationDetailsInfoCard } from "../../../Component/Card/ApplicationDetailsInfoCard";
import { PersonalDataFM } from "../../../Messages/PersonalDataFM";
import { ContactInfoFM } from "../../../Messages/ContactInfoFM";
import { BusinessInfoFM } from "../../../Messages/BusinessInfoFM";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import DetailsSection from "../../../Component/DetailsSection/DetailsSection";
import { EditRepresentativeContactInfoModal } from "./EditRepresentativeContactInfoModal";
import { NoResults } from "../../Portal/Table/NoResults";
import { ProtectedComponent } from "../../../Security/ProtectedComponent";
import { usePortal } from "../../../Hook/usePortal";
import {
  DetailedCompany,
  EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivity, InsuranceContract, InsuranceContractStatus,
  OfficialUserPrivilegesEnum,
  PowerOfAttorney,
  PowersOfAttorneySearchFilter,
  Representative, VastutuskindlustusteTeenusApiFactory as insuranceContractAPI
} from "../../../../api_client/medre_api";
import { RootState } from "../../../rootReducer";
import { ActiveFilters } from "../Search/Filters/FilterTypes";
import { searchResultsActions } from "../Search/searchResultsActions";
import RepresentativeList from "../../Portal/Representatives/RepresentativeList/RepresentativeList";
import { InsuranceDetailsCard } from "../../Official/ActivityLicense/InsuranceContract/InsuranceDetailsCard";

export const CompanyDetails = () => {
  const { id } = useParams<{id: string}>();
  const history = useHistory();
  const isPortal = usePortal();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRepLoading, setIsRepLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<DetailedCompany | undefined>(
    undefined
  );
  const [representativeToEdit, setRepresentativeToEdit] = useState<number>(0);
  const [isRepresentativeModalOpen, setIsRepresentativeModalOpen] =
    useState(false);
  const [validInsurances, setValidInsurances] = useState<InsuranceContract[]>([]);
  const { pageSize, pageIndex, pageCount, data, rowCount, filters, sort } =
    useSelector((rootState: RootState) => rootState.searchResults);

  const handleRepresentativeModal = useCallback((index: number) => {
    setRepresentativeToEdit(index);
    setIsRepresentativeModalOpen(true);
  }, []);

  const getCompanyData = () =>
    id
      ? corporateDataActivity(undefined, getBaseUrl(), API).getCompany(id, {
        withCredentials: true
      })
      : corporateDataActivity(undefined, getBaseUrl(), API).getCurrentCompany({
        withCredentials: true
      });

  const getCompany = useCallback(() => {
    getCompanyData()
      .then((res) => setCompany(res.data))
      .catch((error) => {
        if (error.response.status !== 400) {
          history.push("/");
        }
      })
      .finally(() => setIsLoading(false));
  }, [id, history]);

  const getValidInsurances = (): void => {
    if (!company || !company.registryCode) {
      return;
    }

    insuranceContractAPI(undefined, getBaseUrl(), API)
      .getPolicyHolderInsuranceContracts(
        company.registryCode,
        InsuranceContractStatus.Valid
      ).then((r) => {
        setValidInsurances(r.data.content ?? []);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCompany();
  }, [getCompany]);

  useEffect(() => {
    if (company && isPortal) {
      getValidInsurances();
    }
  }, [company, isPortal]);

  const fetchRepData = useCallback(
    (registryCode: string, body: PowersOfAttorneySearchFilter) => {
      return corporateDataActivity(
        undefined,
        getBaseUrl(),
        API
      ).searchPowersOfAttorney(registryCode, body);

      // eslint-disable-next-line
    },
    [pageIndex, pageSize]
  );

  const fetchAndSetRepData = (
    regCode: string,
    body: PowersOfAttorneySearchFilter
  ) => {
    setIsRepLoading(true);
    fetchRepData(regCode, body)
      .then(({ data: listData }) => {
        setIsRepLoading(false);
        const currentData = listData.content ?? [];
        dispatch(
          searchResultsActions.setData(
            currentData as Array<PowerOfAttorney>,
            ActiveFilters.RepresentativeList
          )
        );
        dispatch(searchResultsActions.setRowCount(listData.totalElements!));
        dispatch(searchResultsActions.setPageCount(listData.totalPages!));
        dispatch(searchResultsActions.setFilters(body));
      })
      .catch(() => setIsRepLoading(false));
  };

  useEffect(() => {
    if (company?.registryCode && !isPortal) {
      fetchAndSetRepData(company?.registryCode as string, {
        ...filters,
        page: pageIndex,
        sort: sort ?? undefined,
        size: pageSize
      } as PowersOfAttorneySearchFilter);
    }
  }, [dispatch, pageIndex, pageSize, company, sort]);

  if (isLoading) {
    return <Loader />;
  } else if (!company) {
    return (
      <NoResults>
        <FormattedMessage
          id="CompDetails.noResults"
          defaultMessage="Ettevõtte andmed puuduvad"
        />
      </NoResults>
    );
  }

  const getActivityLicenseLocations = () => {
    return company.activityLicenseLocations?.join("; ") || "-";
  };

  const getRepresentativeName = (representative: Representative) => {
    return representative.firstName + " " + representative.lastName || "-";
  };

  const reFetchAndUpdateData = () => {
    fetchAndSetRepData(company?.registryCode!, {
      ...filters,
      page: pageIndex,
      sort: sort ?? undefined,
      size: pageSize
    } as PowersOfAttorneySearchFilter);
  };

  const representatives = company.representatives ?? [];

  return (
    <div className="application-container application-detail">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="CompanyDetails.header"
            defaultMessage="Ettevõtte andmed"
          />
        </h1>
      </div>
      <DetailsSection
        id="details"
        title={company.name!}
        className="mx-3 my-4 p-4"
      >
        <ApplicationDetailsInfoCard
          content={[
            {
              key: <BusinessInfoFM id="registryCode" />,
              value: company.registryCode || "-"
            },
            {
              key: <BusinessInfoFM id="juridicalAddress" />,
              value: company.address || "-"
            },
            {
              key: <BusinessInfoFM id="locationAddress" />,
              value: getActivityLicenseLocations()
            },
            {
              key: <BusinessInfoFM id="status" />,
              value: prettifyBusinessStatus(company.status)
            },
            { key: <BusinessInfoFM id="email" />, value: company.email || "-" },
            { key: <BusinessInfoFM id="phone" />, value: company.phone || "-" }
          ]}
        />
      </DetailsSection>

      { isPortal && <InsuranceDetailsCard id="insurance" insurances={ validInsurances } company={ company }/> }

      { representatives?.map((representative, i) => (
        <React.Fragment key={i}>
          <DetailsSection
            key={i}
            id="details"
            title="Ettevõtte esindaja"
            button={
              <ProtectedComponent directRepresentativeOnly>
                <PrimaryFormattedButton
                  id="updateContactInformation"
                  onClick={() => handleRepresentativeModal(i)}
                />
              </ProtectedComponent>
            }
            className="mx-3 my-4 p-4"
          >
            <ApplicationDetailsInfoCard
              key={representative.idCode}
              content={[
                {
                  key: <PersonalDataFM id="name" />,
                  value: getRepresentativeName(representative)
                },
                {
                  key: <PersonalDataFM id="personalCode" />,
                  value: representative.idCode || "-"
                },
                {
                  key: <BusinessInfoFM id="role" />,
                  value: prettifyRoleName(representative.personRole) || "-"
                },
                {
                  key: <ContactInfoFM id="phone" />,
                  value: representative.phone || "-"
                },
                {
                  key: <ContactInfoFM id="email" />,
                  value: representative.email || "-"
                }
              ]}
            />
          </DetailsSection>
        </React.Fragment>
      )) }
      { !isPortal && (
        <ProtectedComponent
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign,
            OfficialUserPrivilegesEnum.Observe
          ]}
        >
          <DetailsSection id={"officialPowersOfAttorney"} className="mx-3 p-4">
            <RepresentativeList
              containerClass="representative-container-class"
              addBtnClass="representative-add-btn-class"
              title={
                <h2>
                  <FormattedMessage
                    id="officialRepresentatives.title"
                    defaultMessage="Esindajate haldamine"
                  />
                </h2>
              }
              repData={data.representativeList}
              pageIndex={pageIndex}
              pageCount={pageCount}
              rowCount={rowCount}
              loading={isRepLoading}
              registryCode={company.registryCode}
              reFetchAndUpdateData={reFetchAndUpdateData}
              isPortal={isPortal}
            />
          </DetailsSection>
        </ProtectedComponent>
      ) }
      { !!representatives.length && (
        <EditRepresentativeContactInfoModal
          representative={representatives[representativeToEdit]}
          registryCode={company?.registryCode!}
          isOpen={isRepresentativeModalOpen}
          onClose={() => setIsRepresentativeModalOpen(false)}
          refresh={getCompany}
        />
      ) }
    </div>
  );
};
