import React, { useCallback, useEffect, useState } from "react";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { AttachmentContainer } from "../../../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import { fileToRef } from "../../../../../../Dto/File/FileReference";
import { getBaseUrl, API } from "../../../../../../api";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { displayAlert } from "../../../../../../Util/AlertUtil";
import { Option } from "../../../../../../Component/Select/SelectTypes";
import { SingleSelect } from "../../../../../../Component/Select/SingleSelect";
import {
  AmetnikeTeenusAmetnikeleApiFactory as officialServiceAPI,
  FileReference,
  FileObjectType,
  OfficialUserPrivilegesEnum,
  OfficialUser
} from "../../../../../../../api_client/medre_api";

export type Assignment = {signer: OfficialUser; file: Record<string, any>};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAssign: (assignment: Assignment) => Promise<void>;
  modalMessage: string;
  fileObjectType: FileObjectType;
}

export const AssignmentModal = ({
  isOpen,
  onClose,
  onAssign,
  fileObjectType
}: Props) => {
  const dispatch = useDispatch();

  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);
  const [fileReference, setFileReference] = useState<FileReference>();
  const [officials, setOfficials] = useState<OfficialUser[]>([]);
  const [signer, setSigner] = useState<OfficialUser>();
  const HAS_ONLY_VISIBLE_OFFICIALS = true;

  const handleClose = useCallback(() => {
    setActiveAttachmentType(null);
    setFileReference(undefined);
    onClose();
  }, [onClose]);

  const assign = () =>
    onAssign({ signer: signer!, file: fileReference! }).then(handleClose);

  useEffect(() => {
    if (isOpen && officials.length === 0) {
      officialServiceAPI(undefined, getBaseUrl(), API)
        .getOfficialsWithPrivilege(OfficialUserPrivilegesEnum.Sign, HAS_ONLY_VISIBLE_OFFICIALS, {
          withCredentials: true
        })
        .then((res) => {
          setOfficials(res.data as OfficialUser[]);
          setSigner(
            (res.data as OfficialUser[])?.find(
              (official) => official.isDefaultSigner
            )
          );
        })
        .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
    }
  }, [isOpen, officials, dispatch]);

  const setFiles = (files?: File[]) => {
    if (files) {
      setFileReference(fileToRef(files[0], fileObjectType));
    }
  };

  const createOption = useCallback((official: OfficialUser) => {
    return {
      value: official.id,
      label: official.firstName + " " + official.lastName
    } as Option;
  }, []);

  const handleOptionChange = useCallback(
    (option: Option, name: string) => {
      setSigner(
        officials.find((official: OfficialUser) => official.id === option.value)
      );
    },
    [officials]
  );

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Lisa otsus ja suuna allkirjastamisele</ModalHeader>
      <ModalBody>
        <AttachmentContainer
          onClick={() => setActiveAttachmentType(fileObjectType)}
          isActive={activeAttachmentType === fileObjectType}
          type={fileObjectType}
          fileReferences={fileReference ? [fileReference] : []}
          maxFilesCount={1}
          saveFiles={setFiles}
          handleFileDelete={(fileName) => setFileReference(undefined)}
          allowedRoles={[
            OfficialUserPrivilegesEnum.Sign,
            OfficialUserPrivilegesEnum.Proceede
          ]}
          allowedFileTypes={["pdf"]}
        />

        <Label>Määra allkirjastaja</Label>
        <SingleSelect
          handleOptionChange={handleOptionChange}
          value={signer?.id || null}
          options={officials?.map(createOption) || []}
          hideSearchIcon
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="back" onClick={handleClose} />
        <PrimaryFormattedButton
          id="forwardToSign"
          disabled={!Boolean(fileReference && signer)}
          onClick={assign}
        />
      </ModalFooter>
    </Modal>
  );
};
