import React from "react";
import { Route, Switch } from "react-router";
import { RoleSelection } from "../Role/RoleSelection";
import { Redirect } from "react-router-dom";
import { PortalDashboard } from "../Dashboard/PortalDashboard";
import { SubmittedApplication } from "../Application/Submitted/SubmittedApplication";
import { ApplicationContainer } from "../Application/ApplicationContainer";
import { PortalApplicationCertificateContainer } from "../ApplicationCertificate/PortalApplicationCertificateContainer";
import { MyApplications } from "../Application/Table/MyApplications";
import { ContactInfo } from "../../Shared/Application/ContactInfo/ContactInfo";
import { PortalActivityLicenseContainer } from "../ActivityLicense/PortalActivityLicenseContainer";
import { PortalApplicationTypeSelection } from "../Application/PortalApplicationTypeSelection";
import { SubmittedActivityLicense } from "../ActivityLicense/Submitted/SubmittedActivityLicense";
import { MyActivityLicenses } from "../ActivityLicense/Table/MyActivityLicenses";
import { CompanyDetails } from "../../Shared/Company/CompanyDetails";
import { CompanyApplications } from "../ActivityLicense/Applications/CompanyApplications";
import { PortalGPListTable } from "../GeneralPractitioners/PortalGPListTable";
import { PortalGPListDetails } from "../GeneralPractitioners/PortalGPListDetails";
import { PublicGPListDetails } from "../GeneralPractitioners/PublicGPListDetails";
import { PortalLicenseAcquisitionContainer } from "../ActivityLicense/LicenseAcquisition/PortalLicenseAcquisitionContainer";
import { SubmittedLicenseAcquisition } from "../ActivityLicense/Submitted/SubmittedLicenseAcquisition";
import { PortalActivityLocationContainer } from "../ActivityLicense/Location/PortalActivityLocationContainer";
import { PortalActivityLicenseServiceContainer } from "../ActivityLicense/Service/PortalActivityLicenseServiceContainer";
import { PublicHome } from "../Public/PublicHome";
import { PublicSearch } from "../Public/PublicSearch";
import { PublicHealthcareEmployeeDetails } from "../PublicHealthcareEmployees/PublicHealthcareEmployeesDetails";
import { PublicActivityLicense } from "../ActivityLicense/PublicActivityLicense";
import { PublicOpenData } from "../PublicOpenData/PublicOpenData";
import {
  DetailedApplicationTypeEnum,
  OfficialUserPrivilegesEnum
} from "../../../../api_client/medre_api";
import RepresentativeContainer from "../Representatives/RepresentativeContainer";
import { ProtectedComponent } from "../../../Security/ProtectedComponent";

export function getRoleSelectionRouting() {
  return (
    <>
      <Switch>
        <Route exact={true} path="/role-selection" component={RoleSelection} />
        <Redirect to="/role-selection" />
      </Switch>
    </>
  );
}

export function getBusinessRouting() {
  return (
    <>
      <Switch>
        <Route exact={true} path="/" render={() => <PortalDashboard />} />
        <Route exact={true} path="/applications">
          <ProtectedComponent
            directRepresentativeOnly={true}
            allowedRoles={[
              OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
            ]}
            matchAnyCondition={true}
          >
            <CompanyApplications />
          </ProtectedComponent>
        </Route>
        <Route exact={true} path="/licenses" component={MyActivityLicenses} />
        <Route
          exact={true}
          path="/new-activity-license"
          component={PortalActivityLicenseContainer}
        />
        <Route
          exact={true}
          path="/new-license-acquisition"
          component={PortalLicenseAcquisitionContainer}
        />
        <Route
          exact={true}
          path="/new-activity-location"
          component={PortalActivityLocationContainer}
        />
        <Route
          exact={true}
          path="/new-activity-license-service"
          component={PortalActivityLicenseServiceContainer}
        />
        <Route
          exact={true}
          path="/activity-location-application"
          component={SubmittedActivityLicense}
        />
        <Route
          exact={true}
          path="/activity-license-service-application"
          component={SubmittedActivityLicense}
        />
        <Route
          exact={true}
          path="/activity-license-application"
          component={SubmittedActivityLicense}
        />
        <Route
          exact={true}
          path="/license-acquisition-application"
          component={SubmittedLicenseAcquisition}
        />
        <Route
          exact={true}
          path="/general-practitioner-lists"
          component={PortalGPListTable}
        />
        <Route
          path="/general-practitioner-lists/:id"
          component={PortalGPListDetails}
        />
        <Route path="/company" component={CompanyDetails} />
        <Route path="/companies/:id" component={CompanyDetails} />
        <ProtectedComponent
          directRepresentativeOnly={true}
          allowedRoles={[
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
          ]}
          matchAnyCondition={true}
        >
          <Route path="/representatives" component={RepresentativeContainer} />
        </ProtectedComponent>
        <Redirect from="/role-selection" to="/" />
      </Switch>
    </>
  );
}

export function getPersonRouting(
  roles: (OfficialUserPrivilegesEnum | string)[],
  email?: string
) {
  const isPerson = roles.includes(OfficialUserPrivilegesEnum.Person);

  const hasEmail = Boolean(email);

  return (
    <>
      <Switch>
        <Route
          exact={true}
          path="/"
          render={() =>
            !isPerson || hasEmail ? (
              <PortalDashboard />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/new-application"
          render={() =>
            !isPerson || hasEmail ? (
              <PortalApplicationTypeSelection />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/application"
          render={() =>
            !isPerson || hasEmail ? (
              <SubmittedApplication />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/new-application/occupation"
          render={() =>
            !isPerson || hasEmail ? (
              <ApplicationContainer
                type={DetailedApplicationTypeEnum.Occupation}
              />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/new-application/speciality"
          render={() =>
            !isPerson || hasEmail ? (
              <ApplicationContainer
                type={DetailedApplicationTypeEnum.Specialization}
              />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/new-application/certificate"
          render={() =>
            !isPerson || hasEmail ? (
              <PortalApplicationCertificateContainer />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route
          exact={true}
          path="/applications"
          render={() =>
            !isPerson || hasEmail ? (
              <MyApplications />
            ) : (
              <Redirect to="/contacts" />
            )
          }
        />
        <Route exact={true} path="/contacts" component={ContactInfo} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export function getPublicRouting() {
  return [
    <Route key="home" exact={true} path="/home" component={PublicHome} />,
    <Route
      key="publicSearch"
      exact={true}
      path="/search/:type"
      component={PublicSearch}
    />,
    <Route
      key="publicGPList"
      path="/general-practitioner-lists/:id"
      component={PublicGPListDetails}
    />,
    <Route
      key="publicActivityLicense"
      path="/activity-licenses/:id"
      component={PublicActivityLicense}
    />,
    <Route
      key="publicEmployeeDetailed"
      path="/employees/:id"
      component={PublicHealthcareEmployeeDetails}
    />,
    <Route key="publicOpenData" path="/open-data" component={PublicOpenData} />
  ];
}
