import React, { useCallback, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { formatDate } from "../../Application/OverView/ApplicationOverViewUtil";
import { CancelModificationGPModal } from "./CancelModificationGPModal";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI,
  ModificationType,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { useDispatch } from "react-redux";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { CancelDeleteModificationGPModal } from "./CancelDeleteModificationGPModal";
import { CancelModificationSubstituteModal } from "./CancelModificationSubstituteModal";

interface Props {
  modification: ModificationType;
  occupationCode: string;
  firstName: string;
  lastName: string;
  activationDate?: string;
  deactivationDate?: string;
  isGeneralPractitioner?: boolean;
  modificationId: string;
  generalPractitionerListId: string;
  isSubstitute?: boolean;
}

export const GPListModificationCard = ({ modification, occupationCode, firstName, lastName, activationDate, deactivationDate, isGeneralPractitioner, modificationId, generalPractitionerListId, isSubstitute }: Props) => {
  const [isModificationGPModalOpen, setIsModificationGPModalOpen] = useState<boolean>(false);
  const [isDeleteModificationGPModalOpen, setIsDeleteModificationGPModalOpen] = useState<boolean>(false);
  const [isModificationSubstituteModalOpen, setIsModificationSubstituteModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { fetchGPListModifications } = useGeneralPractitionerList();

  const employee = (
    <span className="fw-500">
      { occupationCode } - { firstName } { lastName }
    </span>
  );

  const getDescription = () => {
    switch (modification) {
      case ModificationType.ModificationGeneralPractitioner:
        return (
          <>
            Perearst { employee } alustab nimistu juures <span className="fw-500">töötamist alates { formatDate(activationDate) }</span> (k.a)
          </>
        );
      case ModificationType.DeleteModificationGeneralPractitioner:
        return (
          <>
            Perearst { employee } töötab nimistu juures <span className="fw-500">kuni { formatDate(deactivationDate) }</span> (k.a)
          </>
        );
      case ModificationType.ModificationSubstitute:
        return (
          <>
            Ajutine asendaja { employee } <span className="fw-500">alustab</span> nimistu juures töötamist <span className="fw-500">alates { formatDate(activationDate) } kuni { formatDate(deactivationDate) }</span> (k.a)
          </>
        )
    }
  }

  const openModal = () => {
    switch (modification) {
      case ModificationType.ModificationGeneralPractitioner:
        setIsModificationGPModalOpen(true);
        break;
      case ModificationType.DeleteModificationGeneralPractitioner:
        setIsDeleteModificationGPModalOpen(true);
        break;
      case ModificationType.ModificationSubstitute:
        setIsModificationSubstituteModalOpen(true);
        break;
    }
  }

  const handleRemoveModification = useCallback(async () => {
    officialFamilyListAPI(undefined, getBaseUrl(), API)
      .deleteGeneralPractitionerListModification(generalPractitionerListId, modification, modificationId)
      .then(() => {
        fetchGPListModifications(generalPractitionerListId);
        setIsModificationGPModalOpen(false);
        displayAlert("removeGPModificationSuccess", AlertType.Success, dispatch);
      }).catch(() => {
        setIsModificationGPModalOpen(false);
        displayAlert("removeGPModificationFailure", AlertType.Danger, dispatch);
      })
  }, [])

  return (
    <Card className={ `modification-card ${ (isGeneralPractitioner || isSubstitute) && 'large-margin' }` }>
      <Row>
        <Col className="fw-normal">
          { getDescription() }
        </Col>
        <ProtectedComponent
          allowedRoles={[
            OfficialUserPrivilegesEnum.Sign,
            OfficialUserPrivilegesEnum.Proceede,
          ]}
        >
          <LinkButton
            id="cancel"
            onClick={ openModal }
          />
        </ProtectedComponent>
        <CancelModificationGPModal
          isOpen={isModificationGPModalOpen}
          onClose={() => setIsModificationGPModalOpen(false)}
          onConfirm={ handleRemoveModification }
        />
        <CancelDeleteModificationGPModal
          isOpen={isDeleteModificationGPModalOpen}
          onClose={() => setIsDeleteModificationGPModalOpen(false)}
          onConfirm={ handleRemoveModification }
        />
        <CancelModificationSubstituteModal
          isOpen={isModificationSubstituteModalOpen}
          onClose={() => setIsModificationSubstituteModalOpen(false)}
          onConfirm={ handleRemoveModification }
        />
      </Row>
    </Card>
  );
}