import * as React from "react";
import { Card } from "reactstrap";

import { prettifyActorType } from "../../../Dto/AuditLog/AuditLogItem";
import {
  AuditLogItem,
  AuditLogItemActorTypeEnum
} from "../../../../api_client/medre_api";

interface Props {
  auditLogItems: AuditLogItem[] | undefined;
  createHistoryRow: (
    item: AuditLogItem,
    oldStatusName: string,
    i: number
  ) => JSX.Element;
}

export const getAuthor = (item: AuditLogItem) => {
  if (item.actorType !== AuditLogItemActorTypeEnum.Official) {
    return prettifyActorType(item.actorType);
  }
  return <>{`${item.createdBy} (${prettifyActorType(item.actorType)})`}</>;
};

export const HistoryCard = ({ auditLogItems, createHistoryRow }: Props) => {
  const renderHistory = () => {
    if (!auditLogItems?.length) {
      return;
    }
    let rows: JSX.Element[] = [];
    let initialStatus =
      auditLogItems[0].dataObjectValue === "SUBMITTED"
        ? "SAVED"
        : "REGISTERING";

    for (let i = 0; i < auditLogItems.length; i++) {
      const oldStatusName =
        i === 0 ? initialStatus : auditLogItems[i - 1].dataObjectValue!;
      const newStatus = auditLogItems[i];
      rows.push(createHistoryRow(newStatus, oldStatusName, i));
    }
    return <>{rows}</>;
  };

  return (
    <Card id="history-card" className="mx-3 my-4">
      <h2>
        <span id="history" /> Menetluse ajalugu
      </h2>
      <Card>
        <table id="application-detail-history-table">
          <thead>
            <tr>
              <th>Muudatuse aeg</th>
              <th>Muudatus</th>
              <th>Isik</th>
            </tr>
          </thead>
          <tbody>{renderHistory()}</tbody>
        </table>
      </Card>
    </Card>
  );
};
