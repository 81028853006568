import { useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { useEffect, useState } from "react";
import {
  ActivityLicenseRowHeader, ActivityLicenseRowHeaderStatusEnum,
  OfficialUserPrivilegesEnum,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../api";

export const GetActivityLicenses = (): ActivityLicenseRowHeader[] => {
  const userRoles = useSelector((state: RootState) => state.user.userInfo?.roles);
  const [activityLicenses, setActivityLicenses] = useState<
  ActivityLicenseRowHeader[]
  >([]);

  useEffect(() => {
    let isMounted = true;

    if (userRoles?.includes(OfficialUserPrivilegesEnum.Person)) {
      setActivityLicenses([]);
    } else {
      activityPermitAPI(undefined, getBaseUrl(), API)
        .getRowHeaders({
          withCredentials: true
        })
        .then((res) => {
          if (isMounted) {
            if (
              userRoles?.includes(
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull
              ) ||
                  userRoles?.includes(
                    OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
                  ) ||
                  userRoles?.includes(OfficialUserPrivilegesEnum.PowerOfAttorneyTypeGpl)
            ) {
              setActivityLicenses(
                res.data.filter(
                  (license) =>
                    license.status === ActivityLicenseRowHeaderStatusEnum.Valid
                )
              );
            } else {
              setActivityLicenses(res.data);
            }
          }
        });

      return (): void => {
        isMounted = false;
      };
    }
  }, [userRoles]);
  
  return activityLicenses;
};