import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Loader } from "../../../Component/Loader/Loader";
import { GPListDetailedHeader } from "../../Shared/GeneralPractitioners/Detailed/GPListDetailedHeader";
import { GPListContacts } from "../../Shared/GeneralPractitioners/Detailed/GPListContacts";
import { GPListAppointmentTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListAppointmentTimes";
import { GPListConsultationTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListConsultationTimes";
import { generalPractitionerListActions } from "../../../GeneralPractitionerLists/generalPractitionerListActions";
import useGeneralPractitionerList from "../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { GPListEmployees } from "../../Shared/GeneralPractitioners/Detailed/GPListEmployees";

export const PublicGPListDetails = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { list, fetchGPList } = useGeneralPractitionerList();

  useEffect(() => {
    if (!id) {
      return;
    }

    window.scrollTo(0, 0);

    fetchGPList(id, true);

    return () => {
      dispatch(generalPractitionerListActions.clearList());
      dispatch(generalPractitionerListActions.clearGPListBulkDeletions());
    };
  }, [id, fetchGPList, dispatch]);

  if (!list?.id) {
    return <Loader />;
  }

  return (
    <div className="gp-lists-container application-container application-detail p-2 p-md-3">
      <GPListDetailedHeader />

      <GPListContacts />

      <GPListEmployees publicView={true} />

      <GPListAppointmentTimes />

      <GPListConsultationTimes />
    </div>
  );
};
