import React, { Fragment } from "react";

import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { Company } from "../../../../../api_client/medre_api";
import { renderOverViewRow } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";

interface Props {
  isOpen: boolean;
  handleClick: () => void;
  company?: Company;
  hideNullRows?: boolean;
  submittingPerson?: string;
  submittingPersonIdCode?: string;
  titleId?: string;
  submittable?: boolean;
}

export const CompanyInfoRow = ({
  isOpen,
  handleClick,
  company,
  hideNullRows,
  submittingPerson,
  submittingPersonIdCode,
  titleId,
  submittable = true
}: Props) => {
  const className = isOpen ? "expanded" : "";

  const widthSize = useWindowWidthSize();

  const renderRow = (icon: JSX.Element) => {
    const rowId = titleId || "applicantInfo";
    return renderAccordionHeaderRow(widthSize, icon, <GeneralFM id={rowId} />);
  };

  const renderContent = () => (
    <>
      {renderOverViewRow(
        "businessRegistryInfo",
        "instituteName",
        company?.name
      )}
      {renderOverViewRow(
        "businessRegistryInfo",
        "registryCode",
        company?.registryCode
      )}
      {renderOverViewRow(
        "businessRegistryInfo",
        "businessAddress",
        company?.address
      )}
      {submittable &&
        renderOverViewRow(
          "application",
          "applicantName",
          submittingPerson,
          undefined,
          hideNullRows
        )}
      {submittable &&
        renderOverViewRow(
          "application",
          "applicantIdCode",
          submittingPersonIdCode,
          undefined,
          hideNullRows
        )}
      {!submittable && renderRepresentatives()}
      {renderOverViewRow("contactInfo", "email", company?.email)}
      {renderOverViewRow("contactInfo", "phone", company?.phone)}
    </>
  );

  const renderRepresentatives = () =>
    company?.representatives?.map((representative) => (
      <Fragment key={representative.idCode}>
        {renderOverViewRow(
          "contactInfo",
          "representative",
          representative?.firstName + " " + representative?.lastName
        )}
        {renderOverViewRow(
          "contactInfo",
          "representativeIdCode",
          representative?.idCode
        )}
      </Fragment>
    ));

  return isOpen ? (
    <>
      <div
        className={`${className} cursor-pointer accordion-header`}
        onClick={() => handleClick()}
      >
        {renderRow(<MinusIcon />)}
      </div>
      <div className="detail-col pl-0 pr-0">{renderContent()}</div>
    </>
  ) : (
    <div
      className={`${className} cursor-pointer accordion-header`}
      onClick={() => handleClick()}
    >
      {renderRow(<PlusIcon />)}
    </div>
  );
};
