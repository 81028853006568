import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { ValueType } from "react-select";
import { FormattedMessage } from "react-intl";
import { AxiosResponse } from "axios";

import { TypedOption } from "./SelectTypes";
import { isValidNurseCode, getCheckedNurseCode } from "../../Util/PersonUtils";
import { NoOptionsMessage } from "./SelectUtils";

interface Props<T> {
  inputId?: string;
  toOption: (object: T) => TypedOption<T>;
  loadMethod: (inputValue: string) => Promise<AxiosResponse>;
  onSelect: (option: TypedOption<T>) => void;
  filterOption: (object: T) => boolean;
  cacheOptions?: boolean;
  value?: TypedOption<T>;
  defaultOptions?: TypedOption<T>[];
  placeholder?: React.ReactNode;
  checkIfCodeMatchesAnyEmployeeOccupationCode: (value: string) => void;
  menuIsOpen?: boolean;
}

let asyncSelectTimeOut: ReturnType<typeof setTimeout>;

export const NurseAsyncSelect = <T extends object>({
  toOption,
  loadMethod,
  onSelect,
  cacheOptions,
  filterOption,
  defaultOptions,
  placeholder,
  inputId,
  checkIfCodeMatchesAnyEmployeeOccupationCode,
  menuIsOpen,
  ...props
}: Props<T>) => {
  const { value = null } = props;
  const [inputCode, setInputCode] = useState<string>("N");

  const onChange = (newValue: ValueType<TypedOption<T>, boolean>) => {
    onSelect(newValue as TypedOption<T>);
    setInputCode("N");
  };

  const loadNurseOptions = (inputValue: string) => {
    if (asyncSelectTimeOut) {
      clearTimeout(asyncSelectTimeOut);
    }
    return new Promise((resolve) => {
      const searchValue = getCheckedNurseCode(inputValue);

      if (searchValue.length > 2) {
        asyncSelectTimeOut = setTimeout(async () => {
          const { data } = await loadMethod(searchValue);
          resolve(data?.filter(filterOption).map(toOption));
        }, 1000);
      } else {
        resolve([]);
      }
    });
  };

  const handleInputChange = (newValue: string) => {
    if (isValidNurseCode(newValue) || newValue === "N") {
      setInputCode(newValue);
      checkIfCodeMatchesAnyEmployeeOccupationCode(newValue);
    } else {
      if (newValue.length > 1) {
        const pastedNewValue = newValue.slice(1);
        isValidNurseCode(pastedNewValue) && setInputCode(pastedNewValue);
      }
    }
  };

  return (
    <AsyncSelect
      inputId={inputId}
      value={value}
      placeholder={
        placeholder || (
          <FormattedMessage
            id="asyncCustomSelect.search"
            defaultMessage="Otsi"
          />
        )
      }
      onChange={onChange}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      components={{ NoOptionsMessage: NoOptionsMessage }}
      loadOptions={loadNurseOptions}
      {...({
        inputValue: inputCode,
        onInputChange: handleInputChange,
        className: "async-custom-select"
      })}
    />
  );
};