import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import {
  ActivityLicenseApplicationDTO,
  ActivityLicenseApplicationDTOProceedingTypeEnum
} from "../../../../../api_client/medre_api";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";


interface Props {
  applications: ActivityLicenseApplicationDTO[];
}

export const ActivityLicenseApplications = ({ applications }: Props) => {
  const getHeaders = useCallback(() => {
    return [
      <th key="h-1" className="normal-column pl-4" role="columnheader">
        Kuupäev
      </th>,
      <th key="h-2" className="normal-column" role="columnheader">
        Taotluse liik
      </th>,
      <th key="h-3" className="normal-column" role="columnheader">
        Number
      </th>,
      <th key="h-4" className="status-column" role="columnheader">
        Olek
      </th>,
      <th key="h-5" className="normal-column" role="columnheader">
        Vaata detaile
      </th>,
      <th key="h-6" />
    ];
  }, []);

  const getUrlPrefix = useCallback(
    (proceedingType?: ActivityLicenseApplicationDTOProceedingTypeEnum) => {
      switch (proceedingType) {
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLicense:
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLocation:
        case ActivityLicenseApplicationDTOProceedingTypeEnum.Service:
          return "/activity-license-applications/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLicenseInvalidation:
          return "/activity-license-invalidations/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLocationInvalidation:
          return "/activity-license-invalidations/location/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ServiceDiscard:
          return "/discard-services/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.LicenseAcquisition:
          return "/license-acquisitions/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLicenseSuspension:
          return "/activity-license-suspensions/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLocationSuspension:
          return "/activity-license-suspensions/location/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ServiceSuspension:
          return "/activity-license-suspensions/service/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLicenseRestoration:
          return "/license-restorations/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLocationRestoration:
          return "/location-restorations/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.ServiceRestoration:
          return "/service-restorations/";
        case ActivityLicenseApplicationDTOProceedingTypeEnum.GeneralPractitionerApplication:
          return "/general-practitioner-application/";
        default:
          return "/activity-license-applications/";
      }
    },
    []
  );

  const getRow = (application: ActivityLicenseApplicationDTO, i: number) => {
    const { arrivalTime, status, stateFeeIsPaid, proceedingType } = application;
    return (
      <tr role="row" key={i}>
        <td className="normal-column short-column pl-4">
          {moment(arrivalTime).format("DD.MM.YYYY")}
        </td>
        <td className="normal-column short-column">
          {formatProceedingType(proceedingType!)}
        </td>
        <td className="normal-column short-column">
          {application.applicationNumber}
        </td>
        <td className="normal-column large-column">
          <ActivityLicenseApplicationState
            currentStatus={status}
            isStateFeePaid={stateFeeIsPaid}
            isDetailed
          />
        </td>
        <td className="normal-column">
          <NavLink
            to={`${getUrlPrefix(proceedingType)}${application.id}`}
            activeClassName="item-active"
          >
            Taotluse detailid
          </NavLink>
        </td>
      </tr>
    );
  };

  const getContent = () => applications.map(getRow);

  return (
    <>
      <table role="table" className="s-table user-applications-table">
        <thead>
          <tr role="row">{getHeaders()}</tr>
        </thead>
        <tbody className="table-result">{getContent()}</tbody>
      </table>
    </>
  );
};
