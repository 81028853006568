import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const Messages = defineMessages({
  employee: {
    id: "GPListFM.employee",
    defaultMessage: "Vali töötaja"
  },
  workingDays: {
    id: "GPListFM.workingDays",
    defaultMessage: "Nädalapäevad"
  },
  workingHours: {
    id: "GPListFM.workingHours",
    defaultMessage: "Kellaeg"
  },
  comment: {
    id: "GPListFM.comment",
    defaultMessage: "Kommentaar"
  },
  phone: {
    id: "GPListFM.phone",
    defaultMessage: "Telefon"
  },
  substituteDoctor: {
    id: "GPListFM.substituteDoctor",
    defaultMessage: "Vali asendusarst"
  },
  substitutePeriod: {
    id: "GPListFM.substitutePeriod",
    defaultMessage: "Asenduse periood"
  },
  role: {
    id: "GPListFM.role",
    defaultMessage: "Vali roll"
  }
});

export interface Props {
  id: keyof typeof Messages;
}

export const GPListFM = ({ id }: Props) => (
  <FormattedMessage {...Messages[id]} />
);
