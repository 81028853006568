import React, { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getBaseUrl, API } from "../../../../../api";
import { PlusIcon } from "../../../../../Component/Icon/PlusIcon";
import { PrimaryBadge } from "../../../../../Component/Badge/PrimaryBadge";
import { MinusIcon } from "../../../../../Component/Icon/MinusIcon";
import { FormattedButton } from "../../../../../Component/Button/FormattedButton";
import "./ApplicationTableRow.scss";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import {
  renderApplicantCol,
  renderApplicationRow
} from "./ApplicationTableRowUtil";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import { ApplicationFM } from "../../../../../Messages/ApplicationFM";
import { applicationDraftActions } from "../../../../../Application/applicationDraftActions";
import { ApplicationDeleteConfirmationModal } from "../ApplicationDeleteConfirmationModal";
import { AlertMessage } from "../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../Alert/alertActions";
import {
  formatDate,
  renderOverViewRow
} from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import {
  ApplicationDraft,
  Person,
  ApplicationCertificateDeliveryTypeEnum,
  DetailedApplicationTypeEnum,
  TaotluseAndmeteTeenusApiFactory as appDataAPI,
  RakendussertifikaadiAndmeteenusApiFactory as appCertificateDataAPI
} from "../../../../../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../../../../../Dto/ApplicationCertificate/ApplicationCertificate";

interface Props {
  draft: ApplicationDraft | ApplicationCertificateDraft;
  reloadApplications: () => void;
  handleDraftDelete: () => void;
  isOpen?: boolean;
  person: Person;
  index: number;
  name?: string;
  handleRowClick: (index: number) => void;
}

export const ApplicationDraftTableRow = React.memo(
  ({
    draft,
    isOpen,
    person,
    index,
    handleRowClick,
    name,
    reloadApplications,
    handleDraftDelete
  }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const handleOpenDeleteModal = useCallback(() => {
      setDeleteModalIsOpen(true);
    }, [setDeleteModalIsOpen]);
    const handleCloseDeleteModal = useCallback(() => {
      setDeleteModalIsOpen(false);
    }, [setDeleteModalIsOpen]);

    let education, deliveryType, deliveryAddress;
    if ("education" in draft) {
      education = draft.education;
    }
    if ("deliveryType" in draft) {
      deliveryType = draft.deliveryType;
      deliveryAddress = draft.deliveryAddress;
    }
    const { educationInfo, fileReferences } = draft;
    const className = isOpen ? "expanded" : "";
    const widthSize = useWindowWidthSize();

    const draftType = draft.type;
    const typeString = useMemo(() => {
      switch (draftType) {
        case DetailedApplicationTypeEnum.Occupation:
          return "occupation";
        case DetailedApplicationTypeEnum.Specialization:
          return "speciality";
        case DetailedApplicationTypeEnum.ApplicationCertificate:
          return "certificate";
        default:
          return "";
      }
    }, [draftType]);
    const handleProceedWithDraft = useCallback(() => {
      dispatch(applicationDraftActions.setApplicationDraft(draft));
      history.push(`/new-application/${typeString}`);
    }, [dispatch, draft, history, typeString]);

    const renderRowContent = (icon: JSX.Element) => {
      return renderApplicationRow(
        widthSize,
        icon,
        name!,
        <PrimaryBadge id={`saved-${index}`} message="saved" />,
        "Salvestatud",
        undefined,
        draft.applicationNumber
      );
    };

    const deleteDraft = () =>
      draftType === DetailedApplicationTypeEnum.ApplicationCertificate
        ? appCertificateDataAPI(
            undefined,
            getBaseUrl(),
            API
          ).deleteApplicationCertificate(draft.id as string, {
            withCredentials: true
          })
        : appDataAPI(undefined, getBaseUrl(), API).deleteApplicationDraft2(
            draft.id as string,
            { withCredentials: true }
          );

    const handleAppDraftDelete = () => {
      deleteDraft()
        .then(() => {
          handleDraftDelete();
          const alertMessage = (
            <AlertMessage id="applicationDraftDeleteSuccess" />
          );
          const alert = {
            id: 0,
            type: AlertType.Success,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        })
        .catch(() => {
          const alertMessage = (
            <AlertMessage id="applicationDraftDeleteFailure" />
          );
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          reloadApplications();
        })
        .finally(handleCloseDeleteModal);
    };

    if (!isOpen) {
      return (
        <tr
          className={`${className} cursor-pointer`}
          onClick={() => handleRowClick(index)}
        >
          {renderRowContent(
            <button className="btn-unstyled d-inline-block">
              <PlusIcon />
            </button>
          )}
        </tr>
      );
    } else {
      return (
        <>
          <tr
            className={`${className} cursor-pointer`}
            onClick={() => handleRowClick(index)}
          >
            {renderRowContent(
              <button className="btn-unstyled d-inline-block">
                <MinusIcon />
              </button>
            )}
          </tr>
          <tr className="detail-info">
            <td colSpan={6}>
              <Row className="justify-content-md-end justify-content-center application-header-row">
                <FormattedButton
                  id="deleteApplication"
                  onClick={handleOpenDeleteModal}
                />
                <PrimaryFormattedButton
                  id="continueWithSavedDraft"
                  onClick={handleProceedWithDraft}
                />
              </Row>
              <Row>
                {renderApplicantCol(
                  person,
                  educationInfo,
                  education,
                  deliveryType as ApplicationCertificateDeliveryTypeEnum,
                  fileReferences,
                  deliveryAddress,
                  typeString
                )}
                <Col
                  className="detail-col pl-0 pr-0 order-first order-md-last"
                  xs={12}
                  md={6}
                >
                  <Row className="col-header">
                    <GeneralFM id="applicationData" />
                  </Row>
                  {renderOverViewRow(
                    "application",
                    "applicationType",
                    <ApplicationFM id={typeString} />
                  )}
                  {renderOverViewRow(
                    "application",
                    "applicationNumber",
                    draft.applicationNumber?.toString()
                  )}
                  {renderOverViewRow(
                    "application",
                    "savedAt",
                    formatDate(draft.modifiedAt)
                  )}
                  {renderOverViewRow(
                    "application",
                    "state",
                    <PrimaryBadge id={`saved-${index}`} message="saved" />
                  )}
                </Col>
              </Row>
            </td>
          </tr>
          <ApplicationDeleteConfirmationModal
            applicationNumber={draft.applicationNumber!}
            isOpen={deleteModalIsOpen}
            onClose={handleCloseDeleteModal}
            onDelete={handleAppDraftDelete}
          />
        </>
      );
    }
  }
);
