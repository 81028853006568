import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback
} from "reactstrap";
import { useForm, ControllerRenderProps, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import "./RepresentativeModal.scss";
import { RepresentativeSearchForm } from "../RepresentativeSearch/types";
import { RepresentativeModalProps } from "./types";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import {
  EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivityAPI,
  PowerOfAttorney, PowerOfAttorneyTypeEnum,
  UIRakenduseKonfiguratsiooniTeenusApiFactory as appConfigAPI
} from "../../../../../api_client/medre_api";
import {
  getDateWithTime,
  isDateInPast,
  isDateToday,
  momentUTC,
  momentUTCtoString
} from "../../../../Util/DateUtils";
import {
  SingleSelect,
  Option
} from "../../../../Component/Select/SingleSelect";
import { Loader } from "../../../../Component/Loader/Loader";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { alertActions } from "../../../../Alert/alertActions";
import { API, getBaseUrl } from "../../../../api";
import { validateCode } from "../../../../Util/PersonUtils";
import { RepresentativeModalMode } from "../RepresentativeList/types";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { RootState } from "../../../../rootReducer";
import { isUserBusinessOwner } from "../../../../Security/ProtectedComponent";
import {
  powerOfAttorneyTypeOptions,
  userRoleLevels
} from "../../../../Util/RepresentativeUtils";
import { POWER_OF_ATTORNEY_TYPE_PREFIX } from "../../../../Constants";
import terms from "../../../../../assets/images/termsLink.svg";
import { Link } from "../../../Footer/Link";
import info_icon from "../../../../../assets/images/information.svg";
import { RepresentativeInfoTooltipContent } from "../RepresentativeInfoTooltipContent";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";

const RepresentativeModal = ({
  modal,
  onClose,
  registryCode,
  reFetchAndUpdateData,
  isPortal,
  activityLicenses
}: RepresentativeModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [helpLink, setHelpLink] = useState<string | undefined>("");
  const dispatch = useDispatch();
  const userRoles = useSelector(
    (state: RootState) => state.user.userInfo?.roles
  );

  const { data, isOpen, mode } = modal;
  const defaultValues: RepresentativeSearchForm = {
    ...(data as RepresentativeSearchForm)
  };

  const pharmacyBusinessArea = "Apteegi tegevusala";
  const isAllPharmacyBusinessAreas = activityLicenses!.length > 0
    ? activityLicenses!.every((license) => license.businessAreaName === pharmacyBusinessArea)
    : false;

  useEffect(() => {
    appConfigAPI(undefined, getBaseUrl(), API)
      .getUiConfiguration({
        withCredentials: true
      })
      .then((res) =>
        setHelpLink(res.data.l18nLinks?.POWER_OF_ATTORNEY_HELP_LINK)
      );
    return () => setLoading(false);
  }, []);

  const { handleSubmit, errors, reset, formState, control, getValues, watch } =
    useForm<RepresentativeSearchForm>({
      defaultValues,
      mode: "onBlur"
    });

  const { touched, isSubmitted, isSubmitSuccessful } = formState;

  const closeModal = () => {
    reset(defaultValues);
    onClose();
  };

  const handleError = (error: any) => {
    let responseData = error.response?.data;

    const mappedMessages = [
      "representativePerson_has_right_related_to_selected_list",
      "representativePerson_has_right_of_representation",
      "person_has_right_granted_in_Business_Register"
    ];

    const messageId =
      mappedMessages.indexOf(responseData?.message) >= 0
        ? responseData.message
        : mode === RepresentativeModalMode.Add
          ? "representativeAdditionFailed"
          : "representativeUpdateFailed";
    const alertMessage = <AlertMessage id={messageId}/>;

    const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
  };

  const onSubmit = async (formData: RepresentativeSearchForm) => {
    const newData = {
      ...data,
      ...formData
    };

    try {
      setLoading(true);
      if (mode === RepresentativeModalMode.Edit) {
        if (isPortal) {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).updatePowersOfAttorneyById1(
            registryCode!,
            data!.id as string,
            newData as PowerOfAttorney
          );
        } else {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).updatePowersOfAttorneyById(
            registryCode!,
            data!.id as string,
            newData as PowerOfAttorney
          );
        }
      } else {
        if (isPortal) {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).addPowersOfAttorney1(registryCode!, {
            ...newData,
            companyRegNumber: registryCode
          } as PowerOfAttorney);
        } else {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).addPowersOfAttorney(registryCode!, {
            ...newData,
            companyRegNumber: registryCode
          } as PowerOfAttorney);
        }
      }
      reFetchAndUpdateData();
      const successAlertMessage = (
        <AlertMessage
          id={
            mode === RepresentativeModalMode.Add
              ? "representativeAdditionSuccess"
              : "representativeUpdateSuccess"
          }
        />
      );
      const successAlert = {
        id: 0,
        type: AlertType.Success,
        message: successAlertMessage
      };
      dispatch(alertActions.addAlert(successAlert));
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(momentUTCtoString(date));
    },
    []
  );

  const getTypeOptions = useMemo(() => {
    if (isPortal && isAllPharmacyBusinessAreas) {
      const limitedOption = powerOfAttorneyTypeOptions
        .find(option => option.label === PowerOfAttorneyTypeEnum.Limited);
      return limitedOption ? [limitedOption] : [];
    }

    if (!isPortal || isUserBusinessOwner(userRoles)) {
      return powerOfAttorneyTypeOptions;
    } else {
      const foundRole = userRoles?.find((role) =>
        role.startsWith(POWER_OF_ATTORNEY_TYPE_PREFIX)
      );
      return foundRole
        ? powerOfAttorneyTypeOptions.slice(userRoleLevels[foundRole])
        : [{ label: "", value: "" }];
    }
  }, [userRoles]);

  const isEmailValid = () => {
    const personEmail = getValues("personEmailAddress");
    return (
      !!personEmail?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)?.length ||
      !personEmail
    );
  };

  const validatePersonalCode = (value: string) => validateCode(value);

  const watchFields = watch(["startDate"]);

  const handleTypeChange = useCallback(
    (field) => (type: Option) => {
      field.onChange(type.value);
    },
    []
  );

  return (
    <Modal
      id="representative-modal"
      isOpen={isOpen}
      toggle={closeModal}
      centered
      className="representative-modal-container"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4">
          { mode === RepresentativeModalMode.Edit ? (
            <FormattedMessage
              id="representativeModal.edit.title"
              defaultMessage="Esindaja volituse muutmine"
            />
          ) : (
            <FormattedMessage
              id="representativeModal.add.title"
              defaultMessage="Uue esindaja volituse lisamine"
            />
          ) }
        </ModalHeader>
        { loading ? <Loader absolute /> : null }
        <ModalBody>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span className="required">
                <FormattedMessage
                  id="representativeViewModal.label.personalCode"
                  defaultMessage="Isikukood"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="personCode"
              defaultValue=""
              rules={{ required: true, validate: validatePersonalCode }}
              render={({
                value,
                onChange,
                onBlur
              }: ControllerRenderProps<Record<string, any>>) => (
                <div className="representative-modal-input-container">
                  <Input
                    type="number"
                    bsSize="sm"
                    name="personCode"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    valid={
                      mode === RepresentativeModalMode.Add &&
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.personCode) &&
                      !errors.personCode
                    }
                    invalid={!!errors.personCode}
                    className="representative-modal-input-item"
                    disabled={mode === RepresentativeModalMode.Edit}
                  />
                  <FormFeedback className="representative-modal-validation-error-container">
                    <FormattedErrorMessage id="personalCode" />
                  </FormFeedback>
                </div>
              )}
            />
          </div>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span className="required">
                <FormattedMessage
                  id="representativeViewModal.label.firstName"
                  defaultMessage="Eesnimi"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="personFirstName"
              defaultValue=""
              rules={{ required: true }}
              render={({
                value,
                onChange
              }: ControllerRenderProps<Record<string, any>>) => (
                <div className="representative-modal-input-container">
                  <Input
                    bsSize="sm"
                    name="personFirstName"
                    value={value}
                    onChange={onChange}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.personFirstName) &&
                      !errors.personFirstName
                    }
                    invalid={!!errors.personFirstName}
                    className="representative-modal-input-item"
                  />
                </div>
              )}
            />
          </div>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span className="required">
                <FormattedMessage
                  id="representativeViewModal.label.surname"
                  defaultMessage="Perekonnanimi"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="personLastName"
              defaultValue=""
              rules={{ required: true }}
              render={({
                value,
                onChange
              }: ControllerRenderProps<Record<string, any>>) => (
                <div className="representative-modal-input-container">
                  <Input
                    bsSize="sm"
                    name="personLastName"
                    value={value}
                    onChange={onChange}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.personLastName) &&
                      !errors.personLastName
                    }
                    invalid={!!errors.personLastName}
                    className="representative-modal-input-item"
                  />
                </div>
              )}
            />
          </div>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span>
                <FormattedMessage
                  id="representativeViewModal.label.email"
                  defaultMessage="E-post"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="personEmailAddress"
              defaultValue=""
              rules={{
                validate: isEmailValid
              }}
              render={({
                value,
                onChange,
                onBlur
              }: ControllerRenderProps<Record<string, any>>) => (
                <div className="representative-modal-input-container">
                  <Input
                    bsSize="sm"
                    name="personEmailAddress"
                    value={value ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    valid={
                      ((isSubmitted && !isSubmitSuccessful) ||
                        touched.personEmailAddress) &&
                      !errors.personEmailAddress
                    }
                    invalid={!!errors.personEmailAddress}
                    className="representative-modal-input-item"
                  />
                  <FormFeedback className="representative-modal-validation-error-container">
                    <FormattedErrorMessage id="representativeEmail" />
                  </FormFeedback>
                </div>
              )}
            />
          </div>
          <div className="d-flex representative-modal-item select">
            <div className="representative-modal-label-container">
              <span className="required">
                <FormattedMessage
                  id="representativeViewModal.label.type"
                  defaultMessage="Volituse tüüp"
                />
              </span>
            </div>
            <div className="w-100">
              <Controller
                name="type"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <div className="representative-modal-select-container">
                    <SingleSelect
                      isValid={
                        ((isSubmitted && !isSubmitSuccessful) || touched.type) &&
                        !errors.type
                      }
                      isInValid={!!errors.type}
                      value={field.value}
                      options={getTypeOptions}
                      handleOptionChange={handleTypeChange(field)}
                      hideSearchIcon
                      className="representative-type-select"
                      disabled={mode === RepresentativeModalMode.Edit}
                      placeholder={
                        <FormattedMessage
                          id="representativeModal.form.type.select"
                          defaultMessage="Valige..."
                        />
                      }
                    />
                    { mode === RepresentativeModalMode.Add && isPortal && (
                      <>
                        <FormattedToolTip
                          placement={"top-start"}
                          target={"representative-modal-info-icon"}
                          message={RepresentativeInfoTooltipContent()}
                          style={{ maxWidth: "500px", backgroundColor: "white", color: "black" }}
                        />
                        <img
                          alt="info-ikoon"
                          src={info_icon}
                          id="representative-modal-info-icon"
                          className="representative-modal-info-icon"
                        />
                      </>
                    ) }
                  </div>
                )}
              />
              { mode === RepresentativeModalMode.Add && (
                <div className="mt-2">
                  <Link
                    id="authorizationTypesDescription"
                    url={helpLink ?? ""}
                    icon={terms}
                  />
                </div>
              ) }
            </div>
          </div>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span className="required">
                <FormattedMessage
                  id="representativeViewModal.label.alates"
                  defaultMessage="Alates"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="startDate"
              rules={{ required: true }}
              render={(field: ControllerRenderProps<Record<string, any>>) => (
                <DatePickerComponent
                  inputWrapperClass="representative-modal-date-wrapper"
                  onDateChange={handleChangeDate(field)}
                  selectedDate={field.value}
                  disablePast
                  valid={
                    ((isSubmitted && !isSubmitSuccessful) ||
                      touched.startDate) &&
                    !errors.startDate
                  }
                  invalid={!!errors.startDate}
                  inputSize="sm"
                  disabled={
                    mode === RepresentativeModalMode.Edit &&
                    (isDateInPast(field.value) || isDateToday(field.value))
                  }
                />
              )}
            />
          </div>
          <div className="d-flex representative-modal-item">
            <div className="representative-modal-label-container">
              <span>
                <FormattedMessage
                  id="representativeViewModal.label.kuni"
                  defaultMessage="Kuni"
                />
              </span>
            </div>
            <Controller
              control={control}
              name="endDate"
              render={(field: ControllerRenderProps<Record<string, any>>) => (
                <DatePickerComponent
                  inputWrapperClass="representative-modal-date-wrapper"
                  onDateChange={handleChangeDate(field)}
                  selectedDate={field.value}
                  minDate={momentUTC(watchFields.startDate)
                    .add(1, "day")
                    .toDate()}
                  valid={
                    ((isSubmitted && !isSubmitSuccessful) || touched.endDate) &&
                    !errors.endDate
                  }
                  invalid={!!errors.endDate}
                  inputSize="sm"
                />
              )}
            />
          </div>
          <div className="representative-modal-info-container">
            { data?.createdAt && (
              <div className="d-flex">
                <FormattedMessage
                  id="representativeViewModal.create.text"
                  defaultMessage="Lisatud {name} poolt {date}"
                  values={{
                    name: data?.createdBy,
                    date: getDateWithTime(data?.createdAt)
                  }}
                />
              </div>
            ) }
            { data?.modifiedAt && (
              <div className="d-flex">
                <FormattedMessage
                  id="representativeViewModal.modify.text"
                  defaultMessage="Viimati muudetud {name} poolt {date}"
                  values={{
                    name: data?.modifiedBy,
                    date: getDateWithTime(data?.modifiedAt)
                  }}
                />
              </div>
            ) }
          </div>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="reset"
            onClick={closeModal}
          />
          <PrimaryFormattedButton id="confirm" type="submit" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RepresentativeModal;
