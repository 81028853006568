import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message = "name" | "idCode";

export const BusinessSubmittingPersonFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    name: {
      id: "submittingPersonFM.name",
      defaultMessage: "Nimi"
    },
    idCode: {
      id: "submittingPersonFM.idCode",
      defaultMessage: "Isikukood"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
