import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
  ControllerRenderProps
} from "react-hook-form";
import moment from "moment";
import { useParams } from "react-router-dom";

import { ModalKeys } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { FieldArray } from "../../../../Component/HookForm/FieldArray";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import LicenseAddress, {
  defaultAppendValue as defaultAppendAddressValue
} from "./LicenseAddress";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { getBaseUrl, API } from "../../../../api";
import { Option } from "../../../../Component/Select/SelectTypes";
import { GeneralPractitionerFormValues } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerLicense";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { Loader } from "../../../../Component/Loader/Loader";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import {
  ActivityLicense,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyPhysicianListAPI,
  ActivityLicenseRowHeaderStatusEnum
} from "../../../../../api_client/medre_api";
import { isDateInPast, dateToString } from "../../../../Util/DateUtils";

const messages = {
  [ModalKeys.assignLicense]: (
    <FormattedMessage
      id="GPListLicenseModal.assignLicense"
      defaultMessage="Määra tegevusluba"
    />
  ),
  [ModalKeys.changeLicense]: (
    <FormattedMessage
      id="GPListLicenseModal.changeLicense"
      defaultMessage="Muuda tegevusluba"
    />
  )
};

interface Props {
  id: ModalKeys.assignLicense | ModalKeys.changeLicense;
  onClose: () => void;
}

const defaultAppendValue = {
  number: "",
  contacts: [defaultAppendAddressValue], // { locationId: "" }
  activationDate: new Date()
};

interface ActivityLicenseOption extends Option {
  locations?: Option[];
  status?: ActivityLicenseRowHeaderStatusEnum;
}

export default function License({ id: modalId, onClose }: Props) {
  const { id } = useParams<{ id: string }>();
  const title = useMemo(() => messages[modalId], [modalId]);
  const form = useForm<GeneralPractitionerFormValues>({
    mode: "onChange",
    defaultValues: {
      licenses: [defaultAppendValue as Record<string, any>]
    }
  });
  const { control, formState, setValue, getValues, watch, handleSubmit } = form;
  const { isSubmitting, isValid } = formState;
  const fieldArray = useFieldArray({
    name: "licenses",
    keyName: "_id",
    control
  });

  const [isLoading, setLoading] = useState(true);
  const [activityLicenseMap, setActivityLicenseMap] = useState<
    Map<string, ActivityLicenseOption>
  >(new Map());
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    Promise.all([
      officialFamilyPhysicianListAPI(
        undefined,
        getBaseUrl(),
        API
      ).getActivityLicense1(id, { withCredentials: true }),
      officialFamilyPhysicianListAPI(
        undefined,
        getBaseUrl(),
        API
      ).getLicensesModification(id, { withCredentials: true })
    ])
      .then(([{ data: activityLicenses }, { data: modificationLicenses }]) => {
        setActivityLicenseMap(
          new Map<any, any>(
            activityLicenses.map((license: ActivityLicense) => [
              license.number,
              {
                value: license.number,
                label: license.number,
                locations: license.locations!.map((location) => ({
                  value: location.id!,
                  label: location.fullAddress!
                })),
                status: license.status
              }
            ])
          )
        );

        if (!!modificationLicenses.length) {
          const updatedLicenses = modificationLicenses.map((license) => {
            const foundLicense = activityLicenses.find(
              (activityLicene) => activityLicene.number === license.number
            );
            return {
              ...license,
              contacts: foundLicense?.locations
            };
          });

          setValue("licenses", updatedLicenses);
        }
      })
      .finally(() => setLoading(false));
  }, [id, setValue]);

  const usedLicenses = watch("licenses").map((license) => license.number);

  const getLicenseOptions = useCallback(
    (currentValue: string): ActivityLicenseOption[] => {
      const currentLicenseOptions = usedLicenses.filter(
        (licenseNumber) => licenseNumber !== currentValue
      );
      return Array.from(activityLicenseMap.values()).filter(
        ({ value }) => !currentLicenseOptions.includes(value)
      );
    },
    [usedLicenses, activityLicenseMap]
  );

  const getLicenseFilterOptions = useCallback(
    ({ data }: { data: ActivityLicenseOption }) =>
      data.status === ActivityLicenseRowHeaderStatusEnum.Valid,
    []
  );

  const getLicenseInValid = useCallback(
    (currentValue: string) =>
      Boolean(
        currentValue &&
          (
            getLicenseOptions(currentValue).find(
              ({ value }) => value === currentValue
            ) ?? {}
          ).status !== ActivityLicenseRowHeaderStatusEnum.Valid
      ),
    [getLicenseOptions]
  );

  const getLicenseContactOptions = useCallback(
    (index) => {
      return activityLicenseMap.get(usedLicenses[index])?.locations ?? [];
    },
    [activityLicenseMap, usedLicenses]
  );

  const handleChangeLicenseNumber = useCallback(
    (onChange, index) => (option: ActivityLicenseOption) => {
      const { activationDate } = getValues().licenses[index];
      const modifiedActivationDate = isDateInPast(activationDate)
        ? moment().format("YYYY-MM-DD")
        : activationDate;

      setValue(
        `licenses[${index}]`,
        {
          number: option.value,
          contacts: [{ id: "" }],
          activationDate: modifiedActivationDate
        },
        { shouldValidate: true }
      );
    },
    [setValue, getValues]
  );

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(moment.utc(date).format("YYYY-MM-DD"));
    },
    []
  );

  /**
   * default useFieldArray.append is not working here,
   * got the implementation from the hook-forms example
   *
   * @see https://codesandbox.io/embed/react-hook-form-usefieldarray-nested-arrays-x7btr
   */
  const handleAppendLicense = useCallback(() => {
    setValue("licenses", [...getValues().licenses, defaultAppendValue]);
  }, [setValue, getValues]);

  const { fetchGPList } = useGeneralPractitionerList();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ licenses }: GeneralPractitionerFormValues) => {
      setSaving(true);
      try {
        const { data: licensesData } = await officialFamilyPhysicianListAPI(
          undefined,
          getBaseUrl(),
          API
        ).updateLicensesModification(id, licenses, {
          withCredentials: true
        });
        dispatch(generalPractitionerListActions.setLicenses(licensesData));

        await fetchGPList(id!);

        onClose();
      } catch (error) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      } finally {
        setSaving(false);
      }
    },
    [fetchGPList, id, onClose, dispatch]
  );

  return (
    <Modal isOpen={true} onExit={onClose} className="gp-lists-base-modal">
      {(isLoading || isSaving) && <Loader backdrop={true} />}
      <ModalHeader>{title}</ModalHeader>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FieldArray
              array={fieldArray}
              appendValue={defaultAppendValue}
              appendText={
                <FormattedMessage
                  id="GPListLicenseModal.appendLicenseButton"
                  defaultMessage="Lisa tegevusluba"
                />
              }
              render={(fields, { appendButton, removeButton }) => (
                <>
                  {fields.map((field, index) => (
                    <div
                      key={field._id}
                      className={index !== 0 ? "mt-4" : undefined}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="mb-0 font-weight-normal">
                          <FormattedMessage
                            id="GPListContacts.licenses"
                            defaultMessage="Tegevusluba"
                          />
                        </h5>
                        {usedLicenses.length > 1 && removeButton(index)}
                      </div>
                      <Card>
                        <CardHeader className="pt-4 pr-4 pb-2 pl-2 border-0 position-relative">
                          <FormGroup row={true}>
                            <Label
                              htmlFor={`gpListLicenseModalLicenseNumber_${index}`}
                              sm={4}
                              className="text-right"
                            >
                              <FormattedMessage
                                id="GPListContacts.licenses"
                                defaultMessage="Tegevusluba"
                              />
                            </Label>
                            <Col sm={8} className="flex-grow-1">
                              <Controller
                                name={`licenses[${index}].number`}
                                control={control}
                                defaultValue={field.number}
                                rules={{ required: true }}
                                render={({
                                  value,
                                  onChange
                                }: ControllerRenderProps<
                                  Record<string, any>
                                >) => (
                                  <SingleSelect
                                    inputId={`gpListLicenseModalLicenseNumber_${index}`}
                                    options={getLicenseOptions(value)}
                                    filterOptions={getLicenseFilterOptions}
                                    value={value}
                                    handleOptionChange={handleChangeLicenseNumber(
                                      onChange,
                                      index
                                    )}
                                    isInValid={getLicenseInValid(value)}
                                  />
                                )}
                              />
                            </Col>
                          </FormGroup>
                          <LicenseAddress
                            index={index}
                            control={control}
                            options={getLicenseContactOptions(index)}
                          />

                          <FormGroup row={true}>
                            <Label
                              htmlFor={`gpListLicenseModalLicenseActivationDate_${index}`}
                              sm={4}
                              className="text-right"
                            >
                              <FormattedMessage
                                id="GPListModal.assignDate"
                                defaultMessage="Määra kuupäev"
                              />
                            </Label>
                            <Col sm={8} className="flex-grow-1">
                              <Controller
                                name={`licenses[${index}].activationDate`}
                                control={control}
                                defaultValue={field.activationDate}
                                rules={{
                                  required: true,
                                  validate: (value) => value === dateToString()
                                }}
                                render={({
                                  onChange,
                                  value
                                }: ControllerRenderProps<
                                  Record<string, any>
                                >) => (
                                  <DatePickerComponent
                                    id={`gpListLicenseModalLicenseActivationDate_${index}`}
                                    onDateChange={handleChangeDate({
                                      onChange
                                    })}
                                    selectedDate={isLoading ? "" : value}
                                    disablePast={true}
                                    disableFuture={true}
                                    placeholder={"pp.kk.aaaa"}
                                    invalid={
                                      !isLoading && value !== dateToString()
                                    }
                                    valid={value === dateToString()}
                                  />
                                )}
                              />
                            </Col>
                          </FormGroup>
                        </CardHeader>
                      </Card>
                    </div>
                  ))}
                  {usedLicenses.length < activityLicenseMap.size &&
                    cloneElement(appendButton, {
                      onClick: handleAppendLicense
                    })}
                </>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <SecondaryFormattedButton
              id="cancel"
              type="button"
              onClick={onClose}
              disabled={isSubmitting}
            />

            <Button
              type="submit"
              color="primary"
              className="m-2 btn-primary"
              disabled={!isValid || isSubmitting}
            >
              {title}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
}
