import React, { FC, useMemo } from "react";
import { ServiceArea } from "../../../../api_client/medre_api";

interface Props {
  serviceArea?: ServiceArea;
  specificServiceAreas?: string[];
  className?: string;
}

const GeneralPractitionerListServiceArea: FC<Props> = ({
  serviceArea,
  specificServiceAreas,
  className
}) => {
  const serviceAreaValues = useMemo(
    () =>
      (serviceArea?.values ? serviceArea?.values : [])
        .concat(specificServiceAreas || [])
        .join("; "),
    [serviceArea, specificServiceAreas]
  );

  if (!serviceAreaValues) {
    return null;
  }

  return <span className={className}>{serviceAreaValues}</span>;
};

export default GeneralPractitionerListServiceArea;
