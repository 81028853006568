import React, {useEffect, useState} from "react";
import {PrimaryFormattedButton} from "../../../Component/Button/PrimaryFormattedButton";
import {SuccessBadge} from "../../../Component/Badge/SuccessBadge";
import {DangerBadge} from "../../../Component/Badge/DangerBadge";
import {SecondaryFormattedButton} from "../../../Component/Button/SecondaryFormattedButton";
import {FormattedMessage} from "react-intl";
import {AdminTeenusApiFactory as adminTeenusApi} from "../../../../api_client/medre_api";
import {API, getBaseUrl} from "../../../api";
import {displayAlert} from "../../../Util/AlertUtil";
import {AlertType} from "../../../Dto/Alert/AlertItem";
import {useDispatch} from "react-redux";
import {Card} from "reactstrap";
import {Torta2CheckRequestForm} from "./Torta2CheckRequestForm";
import {Torta2DownloadProcessForm} from "./Torta2DownloadProcessForm";
import {TortaExcludedCompanies} from "./TortaExcludedCompanies";
import {Torta2ManualEmploymentInfoProcessForm} from "./Torta2ManualEmploymentInfoProcessForm";

export const TortaView = () => {
  const dispatch = useDispatch();
  const [isTortaCheckEnabled, setIsTortaCheckEnabled] = useState<boolean | null>(null);
  
  useEffect(() => {
    adminTeenusApi(undefined, getBaseUrl(), API)
      .getFeatureFlags({withCredentials: true})
      .then((res) => {
        const tortaCheckEnabledFeatureFlag = res.data.find((flag) => flag.id === "TORTA_CHECK_ENABLED");
        if (tortaCheckEnabledFeatureFlag?.isEnabled !== undefined) {
          setIsTortaCheckEnabled(tortaCheckEnabledFeatureFlag?.isEnabled);
        }
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  }, []);
  
  const handleTortaCheckFeatureFlag = (value: boolean) => {
    adminTeenusApi(undefined, getBaseUrl(), API)
      .updateFeatureFlag({id: "TORTA_CHECK_ENABLED", isEnabled: value}, {withCredentials: true})
      .then((res) => {
        const tortaCheckEnabledFeatureFlag = res.data;
        if (tortaCheckEnabledFeatureFlag?.isEnabled !== undefined) {
          setIsTortaCheckEnabled(tortaCheckEnabledFeatureFlag?.isEnabled);
        }
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  };
  
  return (
    <>
      <Card className="system-administration-card">
        <div className="d-flex">
          <h3 className="system-administration-card-header fw-normal">
            <FormattedMessage
              id="systemAdministration.tab.torta.tortaCheck"
              defaultMessage="TORTA kontrolli sisse- ja väljalülitamine"
            />
          </h3>
          {isTortaCheckEnabled !== null ? (<div className="ml-5">
            {isTortaCheckEnabled ? <SuccessBadge id="tortaCheckEnabled" message="tortaCheckEnabled"/> :
              <DangerBadge id="tortaCheckDisabled" message="tortaCheckDisabled"/>}
          </div>) : null}
        </div>
        {isTortaCheckEnabled !== null ? (<div>
          <i className="mr-5">TORTA_CHECK_ENABLED</i>
          <b className="mr-5">{isTortaCheckEnabled ? "TRUE" : "FALSE"}</b>
          {isTortaCheckEnabled ?
            <SecondaryFormattedButton id="toggleOff"
                                      onClick={() => handleTortaCheckFeatureFlag(!isTortaCheckEnabled)}/> :
            <PrimaryFormattedButton id="toggleOn" onClick={() => handleTortaCheckFeatureFlag(!isTortaCheckEnabled)}/>}
        </div>) : null}
      </Card>
      <TortaExcludedCompanies/>
      <Card className="system-administration-card mt-4">
        <h3 className="system-administration-card-header fw-normal">
          TORTA2 töötamiste lõpetamise päringu käivitamine
        </h3>
        <div>TORTA2 töötamiste lõpetamise päringu teostamine toimib kahes osas:</div>
        <ol>
          <li>
            Tehakse soovitud registrikoodide ja perioodiga TORTA2 päring
          </li>
          <li>
            Päritakse soovitud download_id päringutulemused ja rakendatakse sealsed töötamiste lõpetamised
          </li>
        </ol>
        <Torta2CheckRequestForm/>
        <Torta2DownloadProcessForm/>
        <Torta2ManualEmploymentInfoProcessForm/>
      </Card>
    </>
  );
}
