import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosPromise } from "axios";

import {
  DetailedActivityLicense,
  DetailedActivityLicenseGPListApplication,
  DetailedActivityLicenseInvalidation,
  DetailedLicenseAcquisition
} from "../../../../../api_client/medre_api";

// tslint:disable-next-line:no-any
export const useDetailedApplication = <
  TApplication extends
    | DetailedActivityLicense
    | DetailedActivityLicenseGPListApplication
    | DetailedActivityLicenseInvalidation
    | DetailedLicenseAcquisition
>(
  endPoint: (
    id: string,
    options?: any
  ) => AxiosPromise<
    | TApplication
    | DetailedActivityLicense
    | DetailedActivityLicenseGPListApplication
    | DetailedActivityLicenseInvalidation
    | DetailedLicenseAcquisition
  >,
  id: string
) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [details, setDetails] = useState<TApplication>();

  const history = useHistory();

  const fetchDetailedApplication = useCallback(() => {
    setIsLoading(true);
    endPoint(id, {
      withCredentials: true
    })
      .then((res) => setDetails(res.data as any))
      .catch((error) => {
        if (error.response.status !== 400) {
          history.push("/");
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDetailedApplication();
  }, [fetchDetailedApplication]);

  return {
    details,
    setDetails,
    isLoading,
    setIsLoading,
    refresh: fetchDetailedApplication
  };
};
