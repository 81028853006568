import * as React from "react";

import { getAuthor, HistoryCard } from "../../History/HistoryCard";
import {
  ProceedingStatusStatusEnum,
  AuditLogItem,
  DetailedApplication,
  AuditLogItemActionTypeEnum
} from "../../../../../api_client/medre_api";
import { utcToLocal } from "../../../../Util/DateUtils";

interface Props {
  application: DetailedApplication;
}

export const ApplicationDetailsHistory = ({ application }: Props) => {
  const translateStatus = (oldStatusName: string) => {
    switch (oldStatusName) {
      case ProceedingStatusStatusEnum.Finished:
        return "Lõpetatud";
      case ProceedingStatusStatusEnum.Submitted:
        return "Esitatud";
      case ProceedingStatusStatusEnum.InProceeding:
        return "Menetluses";
      case ProceedingStatusStatusEnum.Paused:
        return "Peatatud";
      case ProceedingStatusStatusEnum.PausedDueToComplianceExam:
        return "Vastavuseksam";
      case ProceedingStatusStatusEnum.PausedDueToAptitudeTest:
        return "Sobivustest";
      case ProceedingStatusStatusEnum.UploadAcceptedDecision:
      case ProceedingStatusStatusEnum.UploadDeclinedDecision:
      case ProceedingStatusStatusEnum.UploadAptitudeTestDecision:
      case ProceedingStatusStatusEnum.UploadAptitudeTestResult:
      case ProceedingStatusStatusEnum.UploadComplianceExamDecision:
      case ProceedingStatusStatusEnum.UploadComplianceExamResult:
        return "Otsuse koostamise ootel";
      case ProceedingStatusStatusEnum.SignAcceptedDecision:
      case ProceedingStatusStatusEnum.SignComplianceExamDecision:
      case ProceedingStatusStatusEnum.SignAptitudeTestDecision:
      case ProceedingStatusStatusEnum.SignDeclinedDecision:
      case ProceedingStatusStatusEnum.SignAptitudeTestResult:
      case ProceedingStatusStatusEnum.SignComplianceExamResult:
        return "Allkirjastamise ootel";
      case ProceedingStatusStatusEnum.Accepted:
        return "Rahuldatud";
      case ProceedingStatusStatusEnum.Declined:
        return "Keeldutud";
      default:
        return "Salvestatud";
    }
  };

  const createActionContent = (item: AuditLogItem, oldStatusName: string) => {
    if (item.actionType === AuditLogItemActionTypeEnum.ChangeStatus) {
      return (
        <>
          <span>Taotluse staatus muutus </span>
          <span className="d-inline-block">
            {`${translateStatus(oldStatusName)} -> ${translateStatus(
              item.dataObjectValue!
            )}`}
          </span>
        </>
      );
    }
    return <span>Taotluse hariduse infot uuendati</span>;
  };

  const createHistoryRow = (
    item: AuditLogItem,
    oldStatusName: string,
    i: number
  ) => (
    <tr key={i}>
      <td>{utcToLocal(item.createdAt).format("DD.MM.YYYY HH:mm")}</td>
      <td>{createActionContent(item, oldStatusName)}</td>
      <td>{getAuthor(item)}</td>
    </tr>
  );

  return (
    <HistoryCard
      createHistoryRow={createHistoryRow}
      auditLogItems={application.auditLogItems}
    />
  );
};
