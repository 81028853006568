import React from "react";
import { NavLink } from "react-router-dom";

import "../../Style/SideMenu.scss";
import { SideMenuItem } from "../../SideMenuItem/SideMenuItem";
import home_icon from "../../../../assets/images/home.svg";
import page_icon from "../../../../assets/images/page.svg";
import phone_icon from "../../../../assets/images/phone.svg";
import gplist_icon from "../../../../assets/images/gplist.svg";
import activity_licenses_icon from "../../../../assets/images/activity_licenses.svg";
import company_icon from "../../../../assets/images/company.svg";
import { LanguageDropdown } from "../../Header/LanguageDropdown";
import { LogoutButton } from "../../../Security/LogoutButton";
import { RoleDropDown } from "../../Header/RoleDropDown";
import { ProtectedComponent } from "../../../Security/ProtectedComponent";
import { OfficialUserPrivilegesEnum } from "../../../../api_client/medre_api";
import Icon from "../../../Component/Icon/Icon";
import { GetActivityLicenses } from "../ActivityLicense/getActivityLicenses";

interface Props {
  isSideMenuCollapsed: boolean;
  roles?: (OfficialUserPrivilegesEnum | string)[];
}

export const UserSideMenu = ({ isSideMenuCollapsed, roles }: Props) => {
  const isPerson = roles?.includes(OfficialUserPrivilegesEnum.Person);
  const pharmacyBusinessArea = "Apteegi tegevusala";
  const activityLicenses = GetActivityLicenses();

  const isAllPharmacyBusinessAreas = activityLicenses.length > 0
    ? activityLicenses.every((license) => license.businessAreaName === pharmacyBusinessArea)
    : false;

  return (
    <aside id="global-sidemenu" className="global-sidemenu">
      <ul className="global-sidemenu-list">
        <li>
          <NavLink exact to={"/"} activeClassName="item-active">
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={"Avaleht"}
              icon={home_icon}
            />
          </NavLink>
        </li>
        { !isAllPharmacyBusinessAreas &&
            <ProtectedComponent
              directRepresentativeOnly
              allowedRoles={[
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
                OfficialUserPrivilegesEnum.Person
              ]}
              matchAnyCondition
            >
              <li>
                <NavLink to="/applications" activeClassName="item-active">
                  <SideMenuItem
                    isSideMenuCollapsed={isSideMenuCollapsed}
                    title={"Minu taotlused"}
                    icon={page_icon}
                  />
                </NavLink>
              </li>
            </ProtectedComponent>
        }
        { !isPerson && (
          <>
            <li>
              <NavLink to={"/licenses"} activeClassName="item-active">
                <SideMenuItem
                  isSideMenuCollapsed={isSideMenuCollapsed}
                  title={"Minu tegevusload"}
                  icon={activity_licenses_icon}
                />
              </NavLink>
            </li>
            { !isAllPharmacyBusinessAreas &&
                <li>
                  <NavLink
                    to={"/general-practitioner-lists"}
                    activeClassName="item-active"
                  >
                    <SideMenuItem
                      isSideMenuCollapsed={isSideMenuCollapsed}
                      title={"Perearsti\r\nnimistud"}
                      icon={gplist_icon}
                    />
                  </NavLink>
                </li>
            }
          </>
        ) }
        { isPerson ? (
          <li>
            <NavLink to={"/contacts"} activeClassName="item-active">
              <SideMenuItem
                isSideMenuCollapsed={isSideMenuCollapsed}
                title={"Minu kontaktandmed"}
                icon={phone_icon}
              />
            </NavLink>
          </li>
        ) : (
          <li>
            <NavLink to={"/company"} activeClassName="item-active">
              <SideMenuItem
                isSideMenuCollapsed={isSideMenuCollapsed}
                title="Ettevõtte andmed"
                icon={company_icon}
              />
            </NavLink>
          </li>
        ) }
        <ProtectedComponent
          directRepresentativeOnly
          allowedRoles={[
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
          ]}
          matchAnyCondition
        >
          <li>
            <NavLink to="/representatives" activeClassName="item-active">
              <SideMenuItem
                isSideMenuCollapsed={isSideMenuCollapsed}
                title={"Esindajad"}
                icon={<Icon icon="Fill-925" size={24} color="#fff"/>}
              />
            </NavLink>
          </li>
        </ProtectedComponent>
      </ul>
      { !isSideMenuCollapsed && (
        <div className="bottom-container">
          <LanguageDropdown />
          <div className="bottom-container-separator order-0" />
          { roles && (
            <>
              <RoleDropDown isSideMenu />
              <div className="bottom-container-separator order-1" />
            </>
          ) }
          <div className="bottom-container-logout-button p-3">
            <LogoutButton isWhite />
          </div>
        </div>
      ) }
    </aside>
  );
};
