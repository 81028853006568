import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";

interface Props {
  email?: string;
  phone?: string;
}

export const ContactInfoCard = ({ email, phone }: Props) => (
  <>
    <h4 className="application-title fw-normal">
      <FormattedMessage
        id="activityLicense.contactInfo"
        defaultMessage="Kontaktandmed"
      />
    </h4>
    <Card className="info-card mb-2">
      {renderOverViewRow("businessContactInfo", "email", email)}
      {renderOverViewRow("businessContactInfo", "phone", phone)}
    </Card>
  </>
);
