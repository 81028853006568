import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError, AxiosPromise } from "axios";

import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import {
  formatDate,
  renderOverViewRow,
  renderRow
} from "../../Application/OverView/ApplicationOverViewUtil";
import {
  isAmbulanceService,
  isGpService,
  serviceNeedsBeds
} from "../../../../Dto/ActivityLicense/Service";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { AddEmployeeModal } from "../Services/AddEmployeeModal";
import { ConfirmAddEmployeesModal } from "./ConfirmAddEmployeesModal";
import { getBaseUrl, API } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { Loader } from "../../../../Component/Loader/Loader";
import { AddSpecialistModal } from "../Services/Specialist/AddSpecialistModal";
import { ConfirmRemoveEmployeeModal } from "./ConfirmRemoveEmployeeModal";
import { ConfirmDiscardPersonnelChangesModal } from "./ConfirmDiscardPersonnelChangesModal";
import useModal from "../../../../Hook/useModal";
import { ActivityLicenseServiceSuspensionModal } from "../Suspension/ActivityLicenseServiceSuspensionModal";
import { ActivityLicenseServiceDiscardModal } from "../Services/Discard/ActivityLicenseServiceDiscardModal";
import { displayAlert } from "../../../../Util/AlertUtil";
import { RootState } from "../../../../rootReducer";
import { WarningBadge } from "../../../../Component/Badge/WarningBadge";
import {
  ActivityLicenseRestorationAcceptanceModal
} from "../../../Official/ActivityLicense/Restoration/ActivityLicenseRestorationAcceptanceModal";
import {
  FileObjectType,
  ActivityLicenseService,
  HealthCareProfessional,
  ActivityLicenseServiceServiceStatusEnum,
  PersonalData,
  AmetnikuTegevuslubadeTeenusteTeenusApiFactory as officialActivityPermitServiceAPI,
  OfficialUserPrivilegesEnum,
  ActivityLicenseApplicationProceedingTypeEnum,
  Service,
  Occupation,
  SpecialistData,
  RemoveSpecialistServiceEmployee,
  SpecialistCode,
  OccupationCode,
  ActivityLicenseStatusEnum, ServiceSuspensionInfo
} from "../../../../../api_client/medre_api";
import { usePortal } from "../../../../Hook/usePortal";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { isSpecialistBusinessAreaId, prettifyBrigadeType } from "../../../../Dto/ActivityLicense/ActivityLicense";
import "./ServiceRow.scss";
import { HEALTHCARE_PROFESSIONALS_PREFIXES, PHARMACISTS_PREFIXES, SPECIALISTS_PREFIXES } from "../../../../Constants";
import { ErrorResponseData } from "../../../../Dto/Error/Error";
import { getDate } from "../../../../Util/DateUtils";

interface Props {
  isOpen: boolean;
  specialistBusinessAreaId?: string;
  requiredOccupationsBusinessArea?: Occupation[];
  isActivityApplicationLicense?: boolean;
  serviceId: string;
  serviceName: string;
  service?: ActivityLicenseService;
  serviceWithRequiredInformation?: Service;
  refresh?: () => void;
  index: number;
  handleRowClick: (index: number) => void;
  isHospital: boolean;
  showTorConfirmation?: boolean;
  addTHTEndpoint?: (
    id: string,
    healthCareProfessional: HealthCareProfessional[],
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  addSpecialistEndpoint?: (
    id: string,
    specialistCodeId: string,
    specialistData: SpecialistData,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  removeEmployeeEndpoint?: (
    id: string,
    employeeId: string,
    options?: any
  ) => AxiosPromise<string>;
  removeThtServiceEmploymentEndpoint?: (
    id: string,
    employeeId: string,
    options?: any
  ) => AxiosPromise<string>;
  removeSpecialistServiceEmploymentEndpoint?: (
    id: string,
    employeeId: string,
    removeSpecialistServiceEmployee: RemoveSpecialistServiceEmployee,
    options?: any
  ) => AxiosPromise<string>;
  loadServiceEndpoint: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  isAddTHTEnabled?: boolean;
  isAddSpecialistEnabled?: boolean;
  isRemoveEmployeeEnabled?: boolean;
  isDiscardServiceEnabled?: boolean;
  servicesToSuspend: ActivityLicenseService[];
  servicesToDiscard: ActivityLicenseService[];
  isServiceSuspendable?: boolean;
  isPharmacyLicense?: boolean;
  isEditable?: boolean;
  isMobileActivityLocation?: boolean;
  isValidLicense?: boolean;
  activityLicenseStatus?: ActivityLicenseStatusEnum;
  serviceSuspensionInfo?: ServiceSuspensionInfo
  onServiceSuspensionSubmit?: () => void;
}

export const ServiceRow = ({
  isOpen,
  specialistBusinessAreaId,
  requiredOccupationsBusinessArea,
  index,
  serviceId,
  serviceName,
  loadServiceEndpoint,
  handleRowClick,
  isHospital,
  showTorConfirmation,
  addTHTEndpoint,
  addSpecialistEndpoint,
  removeThtServiceEmploymentEndpoint,
  removeSpecialistServiceEmploymentEndpoint,
  removeEmployeeEndpoint,
  isServiceSuspendable,
  isDiscardServiceEnabled,
  refresh,
  servicesToSuspend,
  servicesToDiscard,
  isPharmacyLicense = false,
  isMobileActivityLocation,
  service,
  isValidLicense,
  activityLicenseStatus,
  serviceSuspensionInfo,
  onServiceSuspensionSubmit
}: Props) => {
  const isPortal = usePortal();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [isThtModalOpen, setIsThtModalOpen] = useState<boolean>(false);
  const [isSpecialistModalOpen, setIsSpecialistModalOpen] =
    useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
  const [
    isRestorationAcceptanceModalOpen,
    setIsRestorationAcceptanceModalOpen
  ] = useState(false);
  const [employeeWhichEmploymentToBeRemoved, setEmployeeWhichEmploymentToBeRemoved] = useState<
  HealthCareProfessional | undefined
  >();
  const [specialistCodeToBeRemoved, setSpecialistCodeToBeRemoved] = useState<
  string | undefined
  >();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [licenseService, setLicenseService] = useState<
  ActivityLicenseService | undefined
  >(service);
  const [hasLoadedService, setHasLoadedService] = useState<boolean>(false);
  const [isTortaCheckRequired, setIsTortaCheckRequired] = useState<boolean>(true);
  const [newTHTs, setNewTHTs] = useState<HealthCareProfessional[]>([]);
  const [newSpecialist, setNewSpecialist] = useState<{
    specialist: PersonalData;
    specialistCodeId: string;
  }>();
  const servicesToBeDiscarded = useSelector(
    (state: RootState) => state.activityLicenseTable.servicesToDiscard
  );
  const isServiceToBeDiscarded = servicesToBeDiscarded.includes("" + serviceId);
  const dispatch = useDispatch();
  const suspensionModal = useModal();
  const serviceDiscardModal = useModal();
  const maxSuspensionDuration = useSelector(
    (state: RootState) => state.config.maxSuspensionDuration
  );

  const requiredSpecialistOccupation: Occupation | undefined = specialistBusinessAreaId
    ? requiredOccupationsBusinessArea?.at(0)
    : undefined;
  const requiredOccupationPrefix = requiredSpecialistOccupation?.prefix ?? null;

  let className = isOpen ? "expanded" : "";
  className += isServiceToBeDiscarded ? " danger" : "";
  const widthSize = useWindowWidthSize();

  useEffect(() => {
    if (isOpen && !hasLoadedService && loadServiceEndpoint) {
      setIsLoading(true);
      loadServiceEndpoint(serviceId, {
        withCredentials: true
      })
        .then((response) => setLicenseService(response.data))
        .finally(() => {
          setIsLoading(false);
          setHasLoadedService(true);
        });
    }
  }, [isOpen, serviceId, hasLoadedService, loadServiceEndpoint]);

  useEffect(() => {
    if (!showTorConfirmation && newTHTs?.length) {
      handleSaveNewEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTHTs, showTorConfirmation]);

  const DeleteThtEmployeeButton = ({ employee }) => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="delete"
          className="pl-0 danger"
          onClick={(): void => setEmployeeWhichEmploymentToBeRemoved(employee)}
        />
      </ProtectedComponent>
    );
  };

  const DeleteSpecialistEmployeeButton = ({ employee, specialist }) => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="delete"
          className="pl-0 danger"
          onClick={(): void => {
            setEmployeeWhichEmploymentToBeRemoved(employee);
            setSpecialistCodeToBeRemoved(specialist.code);
          }}
        />
      </ProtectedComponent>
    );
  };

  const DeleteEmployeeButton = ({ employee }) => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="delete"
          className="pl-0 danger"
          onClick={(): void => setEmployeeWhichEmploymentToBeRemoved(employee)}
        />
      </ProtectedComponent>
    );
  };

  const AddThtButton = () => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="addTHT"
          className="pl-0"
          onClick={(): void => setIsThtModalOpen(true)}
        />
      </ProtectedComponent>
    );
  };

  const AddSpecialistButton = () => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull,
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="addSpecialist"
          className="pl-0"
          onClick={(): void => setIsSpecialistModalOpen(true)}
        />
      </ProtectedComponent>
    );
  };

  const SuspendServiceButton = () => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="suspendService"
          onClick={suspensionModal.handleOpen}
          className="pl-0"
        />
      </ProtectedComponent>
    );
  };

  const DiscardServiceButton = () => {
    return (
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
        ]}
        officialsOnly
        matchAnyCondition
      >
        <LinkButton
          id="discardService"
          className="pl-0 danger"
          onClick={serviceDiscardModal.handleOpen}
        />
      </ProtectedComponent>
    );
  };

  const RestoreServiceButton = () => {
    return (
      <LinkButton
        id="acceptActivityLicenseServiceRestoration"
        onClick={(): void => setIsRestorationAcceptanceModalOpen(true)}
      />
    );
  };

  const handleAddTht = (thtsToBeAdded: HealthCareProfessional[]): void => {
    setIsThtModalOpen(false);
    if (showTorConfirmation) {
      setIsConfirmationModalOpen(true);
    }
    setNewTHTs(thtsToBeAdded);
  };

  const handleAddSpecialist = (
    personalData: PersonalData,
    specialistCodeId: string,
    isTortaCheckRequired: boolean): void => {
    setIsSpecialistModalOpen(false);
    setNewSpecialist({
      specialist: personalData,
      specialistCodeId: specialistCodeId,
    });
    setIsTortaCheckRequired(isTortaCheckRequired);
    if (showTorConfirmation) {
      setIsConfirmationModalOpen(true);
    } else {
      handleSaveNewEmployees();
    }
  };

  const handleSaveNewEmployees = (): void => {
    setIsConfirmationModalOpen(false);
    setIsLoading(true);

    const promise = newSpecialist
      ? addSpecialistEndpoint!(
        serviceId,
        newSpecialist.specialistCodeId,
        {
          personalData: newSpecialist.specialist,
          isSpecialistRequired: !!requiredSpecialistOccupation
        } as SpecialistData,
        {
          withCredentials: true
        }
      )
      : addTHTEndpoint!(serviceId, newTHTs, {
        withCredentials: true
      });

    promise
      .then(() => {
        displayAlert("employeesAdded", AlertType.Success, dispatch);
        if (refresh) {
          refresh();
        }
      })
      .catch((error: AxiosError) => {
        const responseData = error.response?.data as ErrorResponseData;
        if (responseData.error === "TortaException") {
          displayAlert("tortaFailure", AlertType.Danger, dispatch);
        } else {
          displayAlert("requestFailed", AlertType.Danger, dispatch);
        }
      })
      .finally(() => {
        setNewTHTs([]);
        setNewSpecialist(undefined);
        setIsLoading(false);
      });
  };

  const handleRemoveThtServiceEmployee = (): void => {
    if (removeThtServiceEmploymentEndpoint) {
      setIsLoading(true);
      removeThtServiceEmploymentEndpoint(serviceId, employeeWhichEmploymentToBeRemoved?.id!, {
        withCredentials: true
      })
        .then(() => {
          displayAlert("employeeDeleteSuccess", AlertType.Success, dispatch);

          setLicenseService((previousState) => {
            if (previousState) {
              const filteredServiceEmployees = previousState.serviceEmployees!.filter(
                (e) => !(e.employeeId === employeeWhichEmploymentToBeRemoved?.id && e.specialistCode === null)
              );
              
              const isThereAnyServiceEmployeeLeft = filteredServiceEmployees.some(
                (e) => e.employeeId === employeeWhichEmploymentToBeRemoved?.id
              );

              const filteredEmployees = isThereAnyServiceEmployeeLeft
                ? previousState.employees
                : previousState.employees!.filter(
                  (e) => e.id !== employeeWhichEmploymentToBeRemoved?.id
                );

              return {
                ...previousState,
                serviceEmployees: filteredServiceEmployees,
                employees: filteredEmployees,
              };
            }
            return undefined;
          });
        })
        .catch((error: AxiosError) => {
          const responseData = error.response?.data as ErrorResponseData;
          if (
            responseData.error === "MedreRuntimeException" &&
            responseData.message.startsWith(
              "Healthcare professionals validation failed."
            )
          ) {
            displayAlert(
              "employeeDeleteNotAllowed",
              AlertType.Danger,
              dispatch
            );
          } else {
            displayAlert("requestFailed", AlertType.Danger, dispatch);
          }
        })
        .finally(() => {
          setEmployeeWhichEmploymentToBeRemoved(undefined);
          setIsLoading(false);
        });
    }
  };

  const handleRemoveEmployee = (): void => {
    if (removeEmployeeEndpoint) {
      setIsLoading(true);
      removeEmployeeEndpoint(serviceId, employeeWhichEmploymentToBeRemoved?.id!, {
        withCredentials: true
      })
        .then(() => {
          displayAlert("employeeDeleteSuccess", AlertType.Success, dispatch);

          setLicenseService((previousState) => {
            if (previousState) {
              const filteredServiceEmployees = previousState.serviceEmployees!.filter(
                (e) => !(e.employeeId === employeeWhichEmploymentToBeRemoved?.id)
              );

              const filteredEmployees = previousState.employees!.filter(
                (e) => e.id !== employeeWhichEmploymentToBeRemoved?.id
              );

              return {
                ...previousState,
                serviceEmployees: filteredServiceEmployees,
                employees: filteredEmployees,
              };
            }
            return undefined;
          });
        })
        .catch((error: AxiosError) => {
          const responseData = error.response?.data as ErrorResponseData;
          if (
            responseData.error === "MedreRuntimeException" &&
            responseData.message.startsWith(
              "Healthcare professionals validation failed."
            )
          ) {
            displayAlert(
              "employeeDeleteNotAllowed",
              AlertType.Danger,
              dispatch
            );
          } else {
            displayAlert("requestFailed", AlertType.Danger, dispatch);
          }
        })
        .finally(() => {
          setEmployeeWhichEmploymentToBeRemoved(undefined);
          setIsLoading(false);
        });
    }
  };

  const handleRemoveSpecialistServiceEmployee = (): void => {
    if (removeSpecialistServiceEmploymentEndpoint) {
      setIsLoading(true);
      removeSpecialistServiceEmploymentEndpoint(serviceId, employeeWhichEmploymentToBeRemoved?.id!,
        { specialistCode: specialistCodeToBeRemoved! }, {
          withCredentials: true
        })
        .then(() => {
          displayAlert("employeeDeleteSuccess", AlertType.Success, dispatch);

          setLicenseService((previousState) => {
            if (previousState) {
              const filteredServiceEmployees = previousState.serviceEmployees!.filter(
                (e) => !(e.employeeId === employeeWhichEmploymentToBeRemoved?.id
                    && e.specialistCode?.code === specialistCodeToBeRemoved!)
              );

              const isThereAnyServiceEmployeeLeft = filteredServiceEmployees.some(
                (e) => e.employeeId === employeeWhichEmploymentToBeRemoved?.id
              );

              const filteredEmployees = isThereAnyServiceEmployeeLeft
                ? previousState.employees
                : previousState.employees!.filter(
                  (e) => e.id !== employeeWhichEmploymentToBeRemoved?.id
                );

              return {
                ...previousState,
                serviceEmployees: filteredServiceEmployees,
                employees: filteredEmployees,
              };
            }
            return undefined;
          });
        })
        .catch((error: AxiosError) => {
          const responseData = error.response?.data as ErrorResponseData;
          if (
            responseData.error === "MedreRuntimeException" &&
            responseData.message.startsWith(
              "Healthcare professionals validation failed."
            )
          ) {
            displayAlert(
              "employeeDeleteNotAllowed",
              AlertType.Danger,
              dispatch
            );
          } else {
            displayAlert("requestFailed", AlertType.Danger, dispatch);
          }
        })
        .finally(() => {
          setEmployeeWhichEmploymentToBeRemoved(undefined);
          setSpecialistCodeToBeRemoved(undefined);
          setIsLoading(false);
        });
    }
  };

  const renderHeaderRow = (icon: JSX.Element) => {
    return renderAccordionHeaderRow(
      widthSize,
      icon,
      "Teenus: " + (serviceName || "")
    );
  };

  const renderRooms = useMemo(() =>
    licenseService?.rooms?.map((serviceRoom) =>
      renderRow(serviceRoom?.room?.name, serviceRoom.number)
    ),
  [licenseService]
  );

  const renderBrigades = useMemo(() =>
    licenseService?.brigades?.map((serviceBrigade) =>
      serviceBrigade?.amount && serviceBrigade?.amount > 0 ? (
        renderOverViewRow("activityLicense", prettifyBrigadeType(serviceBrigade?.brigadeType), serviceBrigade.amount.toString())
      ) : null
    ),
  [licenseService]
  );

  const isSpecialistWithMatchingOccupation = (employee: HealthCareProfessional): boolean => {
    return employee?.occupationCodes?.some(e => e.prefix === requiredOccupationPrefix) || false;
  };

  const findEmployeeOccupationCodeByPrefix = (employee: HealthCareProfessional, prefix: string): OccupationCode | undefined => {
    return employee?.occupationCodes?.find(e => e.prefix === prefix);
  };

  const renderSpecialistInfo = (employee: HealthCareProfessional) => {
    return isSpecialistWithMatchingOccupation(employee)
      ?
      <div className="d-flex justify-content-between my-4">
        <div className="col px-0 text-left fw-normal text-body">
          { requiredOccupationPrefix && findEmployeeOccupationCodeByPrefix(employee, requiredOccupationPrefix)?.code || "" }
          { ", " + requiredSpecialistOccupation?.name}
        </div>
        <div className="col">
          { activityLicenseStatus !== ActivityLicenseStatusEnum.Invalid && removeEmployeeEndpoint &&
              <DeleteEmployeeButton employee={employee} /> }
        </div>
      </div>
      : <div className="d-flex justify-content-between my-4">
        <div className="col px-0 text-left fw-normal text-body">
          { service?.service?.requiredOccupations?.[0]?.name || "" }
        </div>
        <div className="col">
          { activityLicenseStatus !== ActivityLicenseStatusEnum.Invalid && removeEmployeeEndpoint &&
              <DeleteEmployeeButton employee={employee} /> }
        </div>
      </div>;
  };

  const doesHaveAnyThtServiceEmployeeMatch = (employeeId: string): boolean => {
    return licenseService?.serviceEmployees?.some(serviceEmployee =>
      serviceEmployee.employeeId === employeeId && serviceEmployee.specialistCode == null) || false;
  };

  const doesHaveAnySpecialistServiceEmployeeMatch = (employeeId: string): boolean => {
    return licenseService?.serviceEmployees?.some(serviceEmployee =>
      serviceEmployee.employeeId === employeeId && serviceEmployee.specialistCode != null) || false;
  };

  const findSpecialistServiceEmployeeMatches = (employeeId: string): SpecialistCode[] => {
    const matchingServiceEmployees = licenseService?.serviceEmployees?.filter(serviceEmployee =>
      serviceEmployee.employeeId === employeeId && serviceEmployee.specialistCode != null)!;

    return matchingServiceEmployees?.map(employee => employee.specialistCode!) ?? [];
  };

  const getEmployeeThtOccupationCodes = (employee: HealthCareProfessional) => {
    return employee.occupationCodes?.filter(occupationCode =>
      HEALTHCARE_PROFESSIONALS_PREFIXES.includes(occupationCode.prefix) ||
      PHARMACISTS_PREFIXES.includes(occupationCode.prefix));
  };

  const getEmployeeGhtOccupationCodes = (employee: HealthCareProfessional): OccupationCode[] => {
    return employee.occupationCodes?.filter(occupationCode =>
      SPECIALISTS_PREFIXES.includes(occupationCode.prefix)) || [];
  };

  const findIfExistsMatchingGhtOccupationCodeFromSpecialistCode = (employee: HealthCareProfessional, specialist: SpecialistCode): OccupationCode | undefined => {
    return getEmployeeGhtOccupationCodes(employee).find(occupationCode =>
      occupationCode.name.toLowerCase() === specialist.name.toLowerCase());
  };

  const renderSpecialistMatches = (employee, removeEndpoint) => {
    if (!doesHaveAnySpecialistServiceEmployeeMatch(employee.id!)) {
      return null;
    }

    const matches = findSpecialistServiceEmployeeMatches(employee.id!);
    return matches.map((specialist: SpecialistCode, index: number) => {
      const matchingOccupationCode: OccupationCode | undefined = findIfExistsMatchingGhtOccupationCodeFromSpecialistCode(employee, specialist);

      return (
        <>
          {(index > 0 && (getEmployeeThtOccupationCodes(employee)?.length ?? 0) >= 0) && <hr/>}

          <div key={specialist.code}
            className="d-flex justify-content-between align-items-center">
            <div style={{ flex: 1 }}>
              {matchingOccupationCode &&
                    <div>{matchingOccupationCode?.code + ", " + matchingOccupationCode?.name}</div>}
              <div>{specialist.code + ", " + specialist.name}</div>
            </div>
            <div style={{ width: "50%", textAlign: "left" }}>
              {
                removeEndpoint && removeEndpoint(employee, specialist)
              }
            </div>

          </div>
        </>
      );
    });
  };

  const renderOccupationInfo = (employee: HealthCareProfessional) => {
    return (<div>
      <div className="my-3">
        {(getEmployeeThtOccupationCodes(employee)!.length > 0) && doesHaveAnyThtServiceEmployeeMatch(employee.id!) &&
                <div className="row mb-0 border-bottom-0 minimum-height-0">
                  <div className="col px-0">
                    {getEmployeeThtOccupationCodes(employee)?.map((occupation) => (
                      <div className="d-flex justify-content-between row mb-0 border-bottom-0 minimum-height-0">
                        <div className="col px-0 text-left fw-normal text-body" key={occupation.code}>
                          { occupation.code + ", " + occupation.name }
                        </div>
                      </div>
                    )) }
                  </div>
                  <div className="col">
                    { removeThtServiceEmploymentEndpoint &&
                    activityLicenseStatus !== ActivityLicenseStatusEnum.Invalid && (
                      <DeleteThtEmployeeButton employee={employee} />
                    ) }
                  </div>
                </div> }

        { doesHaveAnyThtServiceEmployeeMatch(employee.id!) && employee.specialities?.map((speciality) => (
          <div key={speciality.code}>
            { speciality.code + ", " + speciality.name }
          </div>
        )) }

        { employee.specialistCodes?.length! > 0 && employee.occupationCodes?.length !== 0 &&
            doesHaveAnyThtServiceEmployeeMatch(employee.id!) && doesHaveAnySpecialistServiceEmployeeMatch(employee.id!) && <hr/> }

        { renderSpecialistMatches(employee, (employee, specialist) =>
          activityLicenseStatus !== ActivityLicenseStatusEnum.Invalid && removeSpecialistServiceEmploymentEndpoint &&
            <DeleteSpecialistEmployeeButton employee={employee} specialist={specialist} />) }
      </div>
    </div>
    );
  };

  const checkIsPortalAndIsActivityLicenseStatusValid = (): boolean | undefined =>
    isPortal && activityLicenseStatus === ActivityLicenseStatusEnum.Valid;

  const checkIsNotPortalAndIsNotActivityLicenseStatusInvalidOrPaused = (): boolean =>
    !isPortal &&
    activityLicenseStatus !== ActivityLicenseStatusEnum.Paused &&
    activityLicenseStatus !== ActivityLicenseStatusEnum.Invalid;

  const renderEmployees = useMemo(
    () =>
      licenseService?.employees?.map((employee) => {
        return (
          <Row key={employee.id}>
            <Col xs={12} md={4} className="col py-3">
              <div>{ employee.firstName + " " + employee.lastName }</div>
              <div>({ employee.idCode || employee.foreignIdCode || "-" })</div>
            </Col>
            <Col>
              { specialistBusinessAreaId && isPortal && userInfo
                ? renderSpecialistInfo(employee) : renderOccupationInfo(employee) }
            </Col>
          </Row>
        );
      }),
    [licenseService, removeThtServiceEmploymentEndpoint, removeSpecialistServiceEmploymentEndpoint]
  );

  const renderLinks = useMemo(
    () => (
      <>
        { !specialistBusinessAreaId && addTHTEndpoint && (
          ((!isPortal) || checkIsPortalAndIsActivityLicenseStatusValid()) && (
            <AddThtButton />
          )) }
        { addSpecialistEndpoint && (
          ((!isPortal) || checkIsPortalAndIsActivityLicenseStatusValid()) && (
            <AddSpecialistButton />
          )) }
        { isServiceSuspendable &&
          licenseService?.serviceStatus !==
          ActivityLicenseServiceServiceStatusEnum.Discarded &&
            (
          serviceSuspensionInfo?.serviceSuspensionInProceeding === false &&
                serviceSuspensionInfo?.serviceSuspendedDays < maxSuspensionDuration! &&
          (checkIsNotPortalAndIsNotActivityLicenseStatusInvalidOrPaused() ||
              checkIsPortalAndIsActivityLicenseStatusValid()) && (
            <SuspendServiceButton />
          )) }
        { isDiscardServiceEnabled && !licenseService?.toBeDiscarded && (
          (checkIsNotPortalAndIsNotActivityLicenseStatusInvalidOrPaused() ||
            checkIsPortalAndIsActivityLicenseStatusValid()) && (
            <DiscardServiceButton />
          )) }
        { !isPortal &&
          licenseService?.serviceStatus ===
          ActivityLicenseServiceServiceStatusEnum.Suspended && (
          <RestoreServiceButton />
        ) }
      </>
    ),
    [
      addTHTEndpoint,
      addSpecialistEndpoint,
      isServiceSuspendable,
      isDiscardServiceEnabled,
      suspensionModal.handleOpen,
      serviceDiscardModal.handleOpen,
      licenseService,
      maxSuspensionDuration,
      serviceSuspensionInfo
    ]
  );

  const handleSuspensionModalClose = (suspensionType?: ActivityLicenseApplicationProceedingTypeEnum): void => {
    if (suspensionType === ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension) {
      setHasLoadedService(false);
    }
    suspensionModal.handleClose();
  };

  const renderPausedBadge = () => {
    if (!licenseService?.suspended) return null;

    if (!userInfo) {
      return (
        <WarningBadge
          id={`paused-${ index }`}
          className="ml-4"
          message="paused"
        />
      )
    }

    const lastServiceSuspensionEndDate = getDate(new Date(serviceSuspensionInfo?.lastServiceSuspensionEndDate!));
    const today = getDate(new Date());

    const isSuspendedUntil = serviceSuspensionInfo?.serviceStatus === "SUSPENDED" && lastServiceSuspensionEndDate >= today;

    return  (
      <WarningBadge
        id={isSuspendedUntil? `pausedUntil-${ index }` : `paused-${ index }`}
        className="ml-4"
        message={isSuspendedUntil ? "pausedUntil" : "paused" }
        values={isSuspendedUntil ? {date: formatDate(lastServiceSuspensionEndDate)} : undefined}
      />
    )
  }

  if (isOpen && isPharmacyLicense) {
    return (
      <>
        { isLoading && <Loader backdrop /> }
        <div
          className={`${ className } cursor-pointer accordion-header service`}
          onClick={(): void => handleRowClick(index)}
        >
          { renderHeaderRow(<MinusIcon />) }
          { renderPausedBadge() }
        </div>
        { renderEmployees }
        <ConfirmRemoveEmployeeModal
          isOpen={!!employeeWhichEmploymentToBeRemoved}
          onClose={(): void => {
            setEmployeeWhichEmploymentToBeRemoved(undefined);
            setSpecialistCodeToBeRemoved(undefined);
          }}
          onSave={
            specialistCodeToBeRemoved
              ? handleRemoveSpecialistServiceEmployee
              : specialistBusinessAreaId
                ? handleRemoveEmployee
                : handleRemoveThtServiceEmployee
          }

          employee={employeeWhichEmploymentToBeRemoved}
          serviceName={serviceName}
        />
      </>
    );
  }

  if (isOpen) {
    return (
      <>
        { isLoading && <Loader backdrop /> }
        <div
          className={`${ className } cursor-pointer accordion-header service`}
          onClick={(): void => handleRowClick(index)}
        >
          { renderHeaderRow(<MinusIcon />) }
          { renderPausedBadge() }
        </div>
        { userInfo && (
          (checkIsNotPortalAndIsNotActivityLicenseStatusInvalidOrPaused() ||
            checkIsPortalAndIsActivityLicenseStatusValid()) &&
          (renderOverViewRow("general", "activities", renderLinks))
        ) }
        { isMobileActivityLocation && isSpecialistBusinessAreaId(specialistBusinessAreaId)
          ? renderRow(
            "Registrinumber",
            licenseService?.mobileActivityLocationRegistryNumber,
            undefined,
            licenseService?.service?.id
          )
          : renderRooms }
        { serviceNeedsBeds(isHospital, licenseService?.service?.type) &&
          renderRow("Voodikohtade arv", licenseService?.beds) }
        { isAmbulanceService(licenseService?.service?.code) &&
        licenseService?.ambulanceBrigades && licenseService?.ambulanceBrigades > 0 ?
          renderOverViewRow("activityLicense", "numberOfAmbulanceBrigades", licenseService?.ambulanceBrigades.toString())
          : renderBrigades
        }
        { isGpService(licenseService?.service?.code) &&
          renderRow("Nimistute arv", licenseService?.numberOfGpLists) }
        { renderEmployees }
        <AddEmployeeModal
          isOpen={isThtModalOpen}
          defaultEmployees={newTHTs}
          requiredOccupations={undefined}
          requiredSpecialities={undefined}
          excemptEmployees={licenseService?.employees}
          onClose={(): void => setIsDiscardModalOpen(true)}
          serviceEmployees={licenseService?.serviceEmployees}
          onSave={handleAddTht}
          title={
            <FormattedMessage
              id="addEmployeeModal.headerTHT"
              defaultMessage="Lisa THT"
            />
          }
          subtitle={
            <FormattedMessage
              id="addEmployeeModal.subtitle"
              defaultMessage="Otsi tervishoiu töötajaid"
            />
          }
          occupationCodeLabel={
            <FormattedMessage
              id="addEmployeeModal.occupationCode"
              defaultMessage="Kutse kood"
            />
          }
          closeButtonId="cancel"
          addButtonId="addEmployeesToService"
          serviceCode={isValidLicense ? licenseService?.service?.code : ""}
          serviceStatus={licenseService?.service?.status}
        />
        <AddSpecialistModal
          isOpen={isSpecialistModalOpen}
          preselectedSpecialistOccupation={requiredSpecialistOccupation}
          onClose={(): void => setIsDiscardModalOpen(true)}
          onSave={handleAddSpecialist}
          serviceEmployees={licenseService?.serviceEmployees || []}
        />
        <ConfirmAddEmployeesModal
          isOpen={isConfirmationModalOpen}
          onClose={(): void => {
            setIsConfirmationModalOpen(false);
            newSpecialist
              ? setIsSpecialistModalOpen(true)
              : setIsThtModalOpen(true);
            setIsTortaCheckRequired(true);
          }}
          onSave={handleSaveNewEmployees}
          isTortaCheckRequired={isTortaCheckRequired}
        />
        <ConfirmRemoveEmployeeModal
          isOpen={!!employeeWhichEmploymentToBeRemoved}
          onClose={(): void => {
            setEmployeeWhichEmploymentToBeRemoved(undefined);
            setSpecialistCodeToBeRemoved(undefined);
          }}
          onSave={
            specialistCodeToBeRemoved
              ? handleRemoveSpecialistServiceEmployee
              : specialistBusinessAreaId
                ? handleRemoveEmployee
                : handleRemoveThtServiceEmployee
          }
          employee={employeeWhichEmploymentToBeRemoved}
          serviceName={serviceName}
        />
        <ConfirmDiscardPersonnelChangesModal
          isOpen={isDiscardModalOpen}
          onClose={(): void => setIsDiscardModalOpen(false)}
          onSave={(): void => {
            setIsSpecialistModalOpen(false);
            setIsThtModalOpen(false);
            setIsDiscardModalOpen(false);
          }}
        />
        { licenseService?.service && (
          <>
            <ActivityLicenseServiceSuspensionModal
              isOpen={suspensionModal.isOpen}
              onClose={handleSuspensionModalClose}
              serviceId={serviceId}
              service={licenseService?.service}
              servicesToSuspend={servicesToSuspend}
              serviceSuspensionInfo={serviceSuspensionInfo}
              onServiceSuspensionSubmit={onServiceSuspensionSubmit}
            />
            <ActivityLicenseServiceDiscardModal
              isOpen={serviceDiscardModal.isOpen}
              onClose={serviceDiscardModal.handleClose}
              serviceId={serviceId}
              service={licenseService?.service}
              servicesToDiscard={servicesToDiscard}
            />
          </>
        ) }
        <ActivityLicenseRestorationAcceptanceModal
          isOpen={isRestorationAcceptanceModalOpen}
          onClose={(): void => setIsRestorationAcceptanceModalOpen(false)}
          objectId={serviceId}
          type={FileObjectType.ServiceRestorationOnSiteInspectionProtocol}
          modalTitle="Taasta teenus"
          endpoint={
            officialActivityPermitServiceAPI(undefined, getBaseUrl(), API)
              .initiateActivityLicenseRestoration1
          }
        />
      </>
    );
  }

  return (
    <div
      className={`${ className } cursor-pointer accordion-header service`}
      onClick={(): void => handleRowClick(index)}
    >
      { renderHeaderRow(<PlusIcon />) }
      { renderPausedBadge() }
    </div>
  );
};
