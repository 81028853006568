import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";
import React from "react";

interface Props {
    isAddEducationModal?: boolean;
}

export const EhisEducationNotFoundCard = ({isAddEducationModal}: Props) => {
   const addEducationModalSyles = () => {
       return isAddEducationModal ? "mt-4 mb-4 pt-4 pb-4" : "";
   }

  return (
    <Card className={`mt-2 p-3 w-100 d-flex align-items-center justify-content-center info-card ${addEducationModalSyles()}`}>
        {isAddEducationModal
            ? <FormattedMessage
                id="EhisEducationNotFoundCard.modalText"
                defaultMessage="EHIS-es ei ole isikul tingimustele vastavaid haridusi."/>
            : <FormattedMessage
                id="EhisEducationNotFoundCard.text"
                defaultMessage="EHIS ei leidnud hariduse kohta vastet."/>}
    </Card>
  );
};
