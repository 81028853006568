import { ReactTableColumn } from "../../../Component/Table/ReactTable";
import { SearchInsuranceContract } from "../Search/Filters/FilterTypes";
import { FormattedMessage } from "react-intl";
import { InsuranceContractState } from "./InsuranceContractState";
import React from "react";
import { formatDate } from "../Application/OverView/ApplicationOverViewUtil";
import { formatInsuranceSum } from "../../../Util/FormatUtils";
import { utcToLocal } from "../../../Util/DateUtils";

const insuranceContractColumnsObj: Record<string, ReactTableColumn<SearchInsuranceContract>> = {
  policyHolder: {
    id: "policyHolder",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.policyHolder"
        defaultMessage="Kindlustusvõtja"
      />
    ),
    accessor: "policyHolderName",
    disableSortBy: true
  },
  insurer: {
    id: "insurer",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.insurer"
        defaultMessage="Kindlustusandja"
      />
    ),
    accessor: "insurerName",
    disableSortBy: true
  },
  contractNumber: {
    id: "contractNumber",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.contractNumber"
        defaultMessage="Lepingu nr"
      />
    ),
    accessor: "number",
    disableSortBy: true
  },
  versionNumber: {
    id: "versionNumber",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.versionNumber"
        defaultMessage="Versioon"
      />
    ),
    accessor: "versionNumber",
    disableSortBy: true,
  },
  issueDate: {
    id: "issueDate",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.issueDate"
        defaultMessage="Lepingu sõlmimise või muutmise kuupäev"
      />
    ),
    accessor: "issueDate",
    Cell: ({ value }) => formatDate(utcToLocal(value))
  },
  periodStartDate: {
    id: "periodStartDate",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.periodStartDate"
        defaultMessage="Kindlustuskaitse algus"
      />
    ),
    accessor: "periodStartDate",
    disableSortBy: true,
    Cell: ({ value }) => formatDate(utcToLocal(value))
  },
  periodEndDate: {
    id: "periodEndDate",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.periodEndDate"
        defaultMessage="Kindlustuskaitse lõpp"
      />
    ),
    accessor: (rowData) => rowData.periodNewEndDate === null ? rowData.periodEndDate : rowData.periodNewEndDate,
    disableSortBy: true,
    Cell: ({ value }) => formatDate(utcToLocal(value))
  },
  insuranceSum: {
    id: "insuranceSum",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.insuranceSum"
        defaultMessage="Kindlustussumma"
      />
    ),
    accessor: "insuranceSum",
    disableSortBy: true,
    Cell: ({ value }) => formatInsuranceSum(value)
  },
  status: {
    id: "status",
    Header: (
      <FormattedMessage
        id="insuranceContractSearchTableHeader.status"
        defaultMessage="Staatus"
      />
    ),
    accessor: "status",
    disableSortBy: true,
    Cell: ({ value }) => (
      <InsuranceContractState currentStatus={value} />
    )
  }
};

export const InsuranceContractListColumns: ReactTableColumn<SearchInsuranceContract>[] = [
  insuranceContractColumnsObj.policyHolder,
  insuranceContractColumnsObj.insurer,
  insuranceContractColumnsObj.contractNumber,
  insuranceContractColumnsObj.versionNumber,
  insuranceContractColumnsObj.issueDate,
  insuranceContractColumnsObj.periodStartDate,
  insuranceContractColumnsObj.periodEndDate,
  insuranceContractColumnsObj.insuranceSum,
  insuranceContractColumnsObj.status
];