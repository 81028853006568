import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import moment from "moment";

import { StateFeeFM } from "../../../Messages/StateFeeFM";
import { getBaseUrl, API } from "../../../api";
import {
  Bank,
  PankadeAndmeteTeenusApiFactory as bankDataAPI
} from "../../../../api_client/medre_api";
import "./StateFeeInstructions.scss";

interface Props {
  deadline?: Date | string;
  referenceNumber?: string;
  stateFeeValue?: number;
}

export const StateFeeInstructions = ({
  deadline,
  referenceNumber,
  stateFeeValue
}: Props) => {
  const [banks, setBanks] = useState<Bank[]>([]);

  useEffect(() => {
    bankDataAPI(undefined, getBaseUrl(), API)
      .getBanks({
        withCredentials: true
      })
      .then((result) => setBanks(result.data));
  }, []);

  const renderRow = (id: string, value?: string | JSX.Element) => {
    return (
      <Row>
        <Col xs={12} md={4} className="col">
          <StateFeeFM id={id} />
        </Col>
        <Col>{value || "-"}</Col>
      </Row>
    );
  };

  const renderSumRow = (id: string, value?: number) => {
    let valueString = value ? value.toFixed(2) + "€" : "-";
    return renderRow(id, valueString);
  };

  const renderBankRow = (key: number, bank: Bank) => {
    return (
      <Row key={key}>
        <Col xs={12} md={4} className="col">
          {bank.name}
        </Col>
        <Col md={8} className="col bank-column">
          <div>{bank.accountNumber} </div>
          <div className="fw-normal">(BIC/SWIFT: {bank.bic})</div>
        </Col>
      </Row>
    );
  };

  const getFormattedDeadline = () => moment(deadline).format("DD.MM");

  return (
    <>
      <p className="mb-3">
        <StateFeeFM
          id="stateFeeInstruction"
          values={{
            deadline: <span className="fw-500">{getFormattedDeadline()}</span>
          }}
        />
      </p>
      <Card className="info-card">
        {renderRow("receiverName", <StateFeeFM id="ministryOfFinance" />)}
        {banks.map((bank, index) => renderBankRow(index, bank))}
        {renderRow("referenceNumber", referenceNumber)}
        {renderSumRow("sum", stateFeeValue)}
      </Card>
    </>
  );
};
