import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { createServiceName } from "../../../../../Dto/ActivityLicense/Service";
import { ConfirmationModal } from "../../../../../Component/Modal/ConfirmationModal";
import { activityLicenseTableActions } from "../../../../../ActivityLicense/activityLicenseTableActions";
import { RootState } from "../../../../../rootReducer";
import {
  Service,
  ActivityLicenseService,
  OfficialUserPrivilegesEnum
} from "../../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  serviceId: string;
  service: Service;
  servicesToDiscard: ActivityLicenseService[];
}

export const ActivityLicenseServiceDiscardModal = ({
  isOpen,
  onClose,
  service,
  servicesToDiscard
}: Props) => {
  const dispatch = useDispatch();
  const userRoles = useSelector(
    (state: RootState) => state.user.userInfo?.roles
  );

  const handleDiscardConfirm = () => {
    const serviceIds = servicesToDiscard.map((s) => s.id!) || [];
    dispatch(activityLicenseTableActions.setServicesToDiscard(serviceIds));
    onClose();
  };

  const renderServicesToDiscard = (isTitle: boolean) => {
    const additionalServicesToDiscard = servicesToDiscard.filter(
      (rs) => rs.service?.id !== service.id
    );
    if (additionalServicesToDiscard.length > 0) {
      return (
        <div className="text">
          <FormattedMessage
            id="discardActivityLicenseModal.requiredServicesRemovalText"
            defaultMessage="Teenusest loobumisel loobud ka:"
          />
          {!isTitle &&
            additionalServicesToDiscard.map((rs) => {
              return (
                <div key={rs.service?.code} className="d-block">
                  <b>{rs.service?.name}</b>
                </div>
              );
            })}
        </div>
      );
    }
  };

  const createTitle = () => (
    <>
      <FormattedMessage
        id="discardActivityLicenseModal.serviceHeader"
        defaultMessage="Kas olete kindel, et soovite {service} teenusest loobuda?"
        values={{ service: createServiceName(service, true) }}
      />
      <>{renderServicesToDiscard(true)}</>
    </>
  );

  return (
    <ConfirmationModal
      closeButtonId="cancel"
      saveButtonId="discardService"
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleDiscardConfirm}
      title={createTitle()}
    >
      <>
        {renderServicesToDiscard(false)}
        {userRoles?.includes(OfficialUserPrivilegesEnum.Official) ? (
          <FormattedMessage
            id="discardActivityLicenseModal.officialSubtext"
            defaultMessage="Teenusest loobumisel esitatakse taotlus."
          />
        ) : (
          <FormattedMessage
            id="discardActivityLicenseModal.subtext"
            defaultMessage="Teenusest loobumisel esitatakse taotlus Terviseametile menetlemiseks."
          />
        )}
      </>
    </ConfirmationModal>
  );
};
