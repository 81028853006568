import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import "./SubmissionTimeLine.scss";
import you_icon from "../../../../../assets/images/you.svg";
import house_icon from "../../../../../assets/images/house.svg";
import { SubmissionTimeLineEvent } from "./SubmissionTimeLineEvent";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { RootState } from "../../../../rootReducer";
import {
  DetailedApplicationTypeEnum,
  KlassifikaatoriteTeenusApiFactory as classifierServiceAPI,
  CompanyApplication,
  Application
} from "../../../../../api_client/medre_api";

interface Props {
  educationCountryCode?: string;
  automaticProceeding?: boolean;
  isPaid?: boolean;
}

interface HistoryState {
  stateFeeMessageId?: string;
}

export const SubmissionTimeLine = ({
  educationCountryCode,
  automaticProceeding,
  isPaid
}: Props) => {
  const applicationType = useSelector(
    (state: RootState) => (state.application as Application).type
  );
  const stateFeeDeadline = useSelector(
    (state: RootState) =>
      (state.application as CompanyApplication).stateFeeDeadline ??
      state.config.stateFeeDeadline
  );
  const [loading, setLoading] = useState(Boolean(educationCountryCode));
  const [proceedingDeadlineDays, setProceedingDeadlineDays] =
    useState<number>();

  let stateFeeMessage = automaticProceeding
    ? "onStateFeeReceive"
    : "upToXWorkingDays";
  const decisionMessage = automaticProceeding
    ? "applicationDecisionAutomatic"
    : "applicationDecision";
  const history = useHistory<HistoryState>();
  if (history.location.state && history.location.state.stateFeeMessageId) {
    stateFeeMessage = history.location.state.stateFeeMessageId;
  }

  useEffect(() => {
    const fetchProceedingDeadlineDays = async () => {
      if (
        applicationType === DetailedApplicationTypeEnum.ApplicationCertificate
      ) {
        return (
          await classifierServiceAPI(
            undefined,
            getBaseUrl(),
            API
          ).getApplicationCertificateProceedingDeadline({
            withCredentials: true
          })
        ).data;
      } else if (educationCountryCode) {
        return (
          await classifierServiceAPI(
            undefined,
            getBaseUrl(),
            API
          ).getProceedingDeadline(educationCountryCode, {
            withCredentials: true
          })
        ).data;
      }
    };
    fetchProceedingDeadlineDays()
      .then((days) => setProceedingDeadlineDays(days))
      .finally(() => setLoading(false));
  }, [applicationType, educationCountryCode]);

  return loading ? (
    <Loader />
  ) : (
    <div className="timeline">
      <SubmissionTimeLineEvent
        first={true}
        icon={you_icon}
        userText="you"
        status="done"
        comment="applicationSubmitted"
        days={stateFeeDeadline}
        info="xDays"
      />
      <SubmissionTimeLineEvent
        icon={you_icon}
        userText="you"
        status={isPaid ? "done" : "in-progress"}
        comment="payingStateFee"
        info={stateFeeMessage}
        days={proceedingDeadlineDays}
      />
      <SubmissionTimeLineEvent
        icon={house_icon}
        userText="healthAgency"
        status={isPaid ? "in-progress" : undefined}
        comment={decisionMessage}
        last={true}
      />
    </div>
  );
};
