import React, { useCallback, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import "./ApplicantCertificateDeletionModal.scss";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ApplicantCertificateDeletionModal = ({
  isOpen,
  onClose,
  onConfirm
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleConfirm = useCallback(async () => {
    setIsDeleting(true);
    try {
      await onConfirm();
    } catch (e) {
      setIsDeleting(false);
    }
  }, [onConfirm]);

  return (
    <Modal isOpen={isOpen} className="applicant-certificate-deletion-modal">
      <ModalBody>Kas olete kindel, et soovite tõendi kustutada?</ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="backToPerson"
          disabled={isDeleting}
          onClick={onClose}
        />
        <PrimaryFormattedButton
          id="deleteCertificate"
          disabled={isDeleting}
          onClick={handleConfirm}
        />
      </ModalFooter>
    </Modal>
  );
};
