import React from "react";

import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { renderServiceRow } from "../../../Portal/ActivityLicense/Table/ServiceRowUtil";
import {
  createServiceName,
  isAmbulanceService,
  isGpService,
  serviceNeedsBeds
} from "../../../../Dto/ActivityLicense/Service";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { ActivityLicenseService, HealthCareProfessional } from "../../../../../api_client/medre_api";
import { isSpecialistBusinessAreaId } from "../../../../Dto/ActivityLicense/ActivityLicense";

interface Props {
  service: ActivityLicenseService;
  specialistBusinessAreaId?: string;
  isOpen?: boolean;
  isHospital: boolean;
  index: number;
  handleRowClick: (index: number) => void;
  isMobileActivityLocation?: boolean;
}

export const ServiceDetailsRow = React.memo(
  ({
    isOpen,
    isHospital,
    index,
    handleRowClick,
    specialistBusinessAreaId,
    service,
    isMobileActivityLocation
  }: Props) => {
    const className = isOpen ? "expanded" : "";
    const widthSize = useWindowWidthSize();

    const requiredOccupationPrefix = service?.service?.requiredOccupations?.[0]?.prefix ?? null;

    const isSpecialistWithMatchingOccupation = (employee: HealthCareProfessional): boolean => {

      return employee?.occupationCodes?.some(e => e.prefix === requiredOccupationPrefix) || false;
    };

    const renderRooms = () =>
      service.rooms?.map((serviceRoom) => (
        <tr key={serviceRoom.room?.id}>
          <td colSpan={2}>
            <div className="service-table-content-row">
              <div className="text-right">{serviceRoom.room?.name}</div>
              <div>{serviceRoom.number}</div>
            </div>
          </td>
        </tr>
      ));

    const renderSpecialistInfo = (employee: HealthCareProfessional) => {
      return isSpecialistWithMatchingOccupation(employee)
        ? <div>{employee.occupationCodes
          ?.filter(occupation => occupation.prefix === requiredOccupationPrefix)
          .map((occupation) => (
            <div key={occupation.code}>
              {occupation.code + ", " + occupation.name}
            </div>
          ))}
        </div>
        : <div>
          {service.service?.requiredOccupations?.[0]?.name || ""}
        </div>;
    };

    const renderOccupationInfo = (employee: HealthCareProfessional) => {
      return (<div>
        {employee.occupationCodes?.map((occupation) => (
          <div key={occupation.code}>
            {occupation.code + ", " + occupation.name}
          </div>
        ))}
        {employee.specialities?.map((speciality) => (
          <div key={speciality.code}>
            {speciality.code + ", " + speciality.name}
          </div>
        ))}
        {employee.specialistCodes?.map((specialist) => (
          <div key={specialist.code}>
            {specialist.code + ", " + specialist.name}
          </div>
        ))}
      </div>);
    };

    const renderEmployees = () =>
      service.employees?.map((employee) => (
        <tr key={employee.id}>
          <td colSpan={2}>
            <div className="service-table-content-row">
              <div className="text-right">
                {employee.firstName + " " + employee.lastName}
                <div>({employee.idCode || employee.foreignIdCode || "-"})</div>
              </div>
              {specialistBusinessAreaId ? renderSpecialistInfo(employee) : renderOccupationInfo(employee)}
            </div>
          </td>
        </tr>
      ));

    const renderRowContent = (icon: JSX.Element) => {
      return renderServiceRow(
        widthSize,
        icon,
        "Teenus: " + createServiceName(service.service!, true)
      );
    };
    
    return isOpen ? (
      <>
        <tr
          className={`${className} cursor-pointer service-details-row`}
          onClick={(): void => handleRowClick(index)}
        >
          {renderRowContent(<MinusIcon />)}
        </tr>
        {isMobileActivityLocation && isSpecialistBusinessAreaId(specialistBusinessAreaId)
          ? (
            <tr>
              <td colSpan={2}>
                <div className="service-table-content-row">
                  <div className="text-right">Registrinumber</div>
                  <div>{service.mobileActivityLocationRegistryNumber}</div>
                </div>
              </td>
            </tr>
          ) : (
            renderRooms()
          )}
        {renderEmployees()}
        {serviceNeedsBeds(isHospital, service.service?.type) && (
          <tr>
            <td colSpan={2}>
              <div className="service-table-content-row">
                <div className="text-right">Voodikohtade arv</div>
                <div>{service.beds}</div>
              </div>
            </td>
          </tr>
        )}
        {isAmbulanceService(service.service?.code) && (
          <tr>
            <td colSpan={2}>
              <div className="service-table-content-row">
                <div className="text-right">Kiirabibrigaadide arv</div>
                <div>{service.ambulanceBrigades}</div>
              </div>
            </td>
          </tr>
        )}
        {isGpService(service.service?.code) && (
          <tr>
            <td colSpan={2}>
              <div className="service-table-content-row">
                <div className="text-right">Nimistute arv</div>
                <div>{service.numberOfGpLists}</div>
              </div>
            </td>
          </tr>
        )}
      </>
    ) : (
      <tr
        className={`${className} cursor-pointer`}
        onClick={(): void => handleRowClick(index)}
      >
        {renderRowContent(<PlusIcon />)}
      </tr>
    );
  }
);
