import React, { FC, ChangeEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import { ActiveFilters } from "../../../Shared/Search/Filters/FilterTypes";
import { DecisionTHTFilters } from "./DecisionTHTFilters";
import { DecisionLicenseFilters } from "./DecisionLicenseFilters";
import { RadioButtonElement, RadioGroup } from "../../../../Component/Radio/RadioGroup";

interface DecisionFiltersProps {
  handleFilterTypeChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const DecisionFilters: FC<DecisionFiltersProps> = ({ handleFilterTypeChange }) => {
  const filterType = useSelector(filterSelectors.selectFilterType);

  const radioButtons = useMemo<RadioButtonElement[]>(
    () => [
      {
        id: "decisionTHT",
        value: ActiveFilters.DecisionTHT,
        labelText: "THT",
      },
      {
        id: "decisionLicense",
        value: ActiveFilters.DecisionLicense,
        labelText: "Tegevusluba",
      },
    ],
    []
  );

  return (
    <>
      <RadioGroup
        options={radioButtons}
        value={filterType}
        name="filterTypes"
        className="mt-3 mb-4 d-flex"
        onChange={handleFilterTypeChange}
      />

      {filterType === ActiveFilters.DecisionTHT && <DecisionTHTFilters />}
      {filterType === ActiveFilters.DecisionLicense && <DecisionLicenseFilters />}
    </>
  );
};