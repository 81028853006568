import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import { ApplicationDeleteConfirmationModal } from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { OfficialUserPrivilegesEnum } from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";

interface Props {
  isDraft: boolean;
  applicationId: string;
  applicationNumber: number;
  deleteApplication: (applicationId: string) => void;
  continueDraft: (applicationId: string) => void;
  officialId?: string;
}

export const DraftActions = ({
  applicationId,
  applicationNumber,
  deleteApplication,
  isDraft,
  continueDraft,
  officialId
}: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return isDraft ? (
    <>
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
        allowedUserId={officialId}
      >
        <PrimaryFormattedButton
          id="continueWithSavedDraft"
          className={ActionButtonClasses}
          onClick={continueDraft}
        />
      </ProtectedFieldWrapper>
      <ProtectedComponent allowedRoles={[
        OfficialUserPrivilegesEnum.Sign,
        OfficialUserPrivilegesEnum.Proceede
      ]}>
        <DangerFormattedButton
          id="deleteApplication"
          className={ActionButtonClasses}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </ProtectedComponent>
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => {
          deleteApplication(applicationId);
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  ) : (
    <></>
  );
};
