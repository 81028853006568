import React from "react";

import {
  RadioButtonElement,
  RadioGroup
} from "../../../../Component/Radio/RadioGroup";
import { ApplicationChannelTypeEnum } from "../../../../../api_client/medre_api";
import "./ApplicationArrivalChannel.scss";

interface Props {
  channelType?: ApplicationChannelTypeEnum;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selfServiceDisplayed?: boolean;
}

export const ArrivalChannelContainer = ({
  channelType,
  handleChange,
  selfServiceDisplayed
}: Props) => {
  const buttons: RadioButtonElement[] = [
    {
      id: "arrivalChannelEmail",
      value: ApplicationChannelTypeEnum.EMail,
      labelText: "E-mail"
    },
    {
      id: "arrivalChannelPost",
      value: ApplicationChannelTypeEnum.Mail,
      labelText: "Tavapost"
    },
    {
      id: "arrivalChannelDelivered",
      value: ApplicationChannelTypeEnum.Delivered,
      labelText: "Toodi kohapeale"
    }
  ];

  if (selfServiceDisplayed) {
    buttons.push({
      id: "arrivalChannelSelfService",
      value: ApplicationChannelTypeEnum.SelfService,
      labelText: "Iseteenindus"
    });
  }

  return (
    <>
      <h3 className="application-title fw-normal">Taotluse saabumise kanal</h3>
      <p className="m-0">
        Vali kuidas taotleja taotluse terviseametisse toimetas.
      </p>
      <div className="arrival-channel-wrapper mt-4">
        <RadioGroup
          options={buttons}
          value={channelType}
          name="arrivalChannel"
          onChange={handleChange}
          className="arrival-channel-select"
        />
      </div>
    </>
  );
};
