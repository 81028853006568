import { RootAction } from "../../../rootReducer";
import * as actions from "./searchResultsActions";
import {
  UnionFiltersType,
  SearchApplicant,
  SearchApplication,
  SearchActivityLicenseApplication,
  SearchApplicationCertificate,
  ActiveFilters,
  SearchCompany,
  SearchDecisionTHT,
  SearchDecisionLicense,
  SearchInsuranceContract
} from "./Filters/FilterTypes";
import {
  HealthCareProfessional,
  GeneralPractitionerList,
  ActivityLicense,
  PowerOfAttorney
} from "../../../../api_client/medre_api";

export class SearchResultsState {
  data: {
    [ActiveFilters.Application]: SearchApplication[];
    [ActiveFilters.ActivityLicenseApplications]: SearchActivityLicenseApplication[];
    [ActiveFilters.Applicant]: SearchApplicant[];
    [ActiveFilters.ApplicationCertificate]: SearchApplicationCertificate[];
    [ActiveFilters.GeneralPractitionerList]: GeneralPractitionerList[];
    [ActiveFilters.ActivityLicenses]: ActivityLicense[];
    [ActiveFilters.PublicGeneralPractitionerList]: GeneralPractitionerList[];
    [ActiveFilters.PublicActivityLicenses]: ActivityLicense[];
    [ActiveFilters.PublicHealthcareEmployees]: HealthCareProfessional[];
    [ActiveFilters.Company]: SearchCompany[];
    [ActiveFilters.DecisionTHT]: SearchDecisionTHT[];
    [ActiveFilters.DecisionLicense]: SearchDecisionLicense[];
    [ActiveFilters.RepresentativeList]: PowerOfAttorney[];
    [ActiveFilters.InsuranceContract]: SearchInsuranceContract[];
  };
  pageIndex: number;
  pageSize: number;
  rowCount: number;
  pageCount: number;
  filters: UnionFiltersType | null;
  sort: string[] | null;

  constructor() {
    this.data = {
      [ActiveFilters.Application]: [],
      [ActiveFilters.ActivityLicenseApplications]: [],
      [ActiveFilters.Applicant]: [],
      [ActiveFilters.ApplicationCertificate]: [],
      [ActiveFilters.GeneralPractitionerList]: [],
      [ActiveFilters.ActivityLicenses]: [],
      [ActiveFilters.PublicGeneralPractitionerList]: [],
      [ActiveFilters.PublicActivityLicenses]: [],
      [ActiveFilters.PublicHealthcareEmployees]: [],
      [ActiveFilters.Company]: [],
      [ActiveFilters.DecisionTHT]: [],
      [ActiveFilters.DecisionLicense]: [],
      [ActiveFilters.RepresentativeList]: [],
      [ActiveFilters.InsuranceContract]: []
    };
    this.pageIndex = 0;
    this.pageSize = 10;
    this.rowCount = 0;
    this.pageCount = 0;
    this.filters = null;
    this.sort = null;
  }
}

export const searchResultsReducer = (
  state = new SearchResultsState(),
  action: RootAction
) => {
  switch (action.type) {
    case actions.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.filterType]: action.payload.list || []
        }
      };
    case actions.SET_INDEX:
      return {
        ...state,
        pageIndex: action.payload
      };
    case actions.SET_SIZE:
      return {
        ...state,
        pageSize: action.payload,
        pageIndex: 0
      };
    case actions.SET_ROWCOUNT:
      return {
        ...state,
        rowCount: action.payload
      };
    case actions.SET_PAGECOUNT:
      return {
        ...state,
        pageCount: action.payload
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case actions.SET_SORT_BY:
      return {
        ...state,
        sort: action.payload,
        pageIndex: 0
      };
    default:
      return state;
  }
};
