import React, { useMemo, useState } from "react";
import "./SystemAdministration.scss";
import { FormattedMessage } from "react-intl";
import { TortaView } from "./TortaView";
import { SystemAdministrationNav } from "./SystemAdministrationNav";
import { QueriesView } from "./QueriesView";
import { OfficialUserPrivilegesEnum } from "../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../Security/ProtectedComponent";
import { FeatureFlagsView } from "./FeatureFlagsView";

export const SystemAdministration = () => {
  const [activeTab, setActiveTab] = useState("TORTA");

  const tabs = useMemo<{id: string}[]>(
    () => [
      { id: "TORTA" },
      { id: "RUN_QUERIES" },
      { id: "FEATURE_FLAGS" }
    ], []
  );

  return (
    <>
      <ProtectedComponent
        directRepresentativeOnly
        allowedRoles={[
          OfficialUserPrivilegesEnum.Admin
        ]}
        matchAnyCondition
      >
        <h1 className="mt-5 ml-3 system-administration-header fw-300">
          <FormattedMessage
            id="systemAdministration.header"
            defaultMessage="Administratiivsed tegevused"
          />
        </h1>
        <div className="system-administration-container mx-3 my-4">
          <SystemAdministrationNav
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          { activeTab === "TORTA" && <TortaView /> }
          { activeTab === "RUN_QUERIES" && <QueriesView /> }
          { activeTab === "FEATURE_FLAGS" && <FeatureFlagsView /> }
        </div>
      </ProtectedComponent>
    </>
  );
};
