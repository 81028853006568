import "./NoResults.scss";
import React from "react";
import empty from "../../../../assets/images/empty.svg";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

interface Props {
  children?: React.ReactElement;
}

const defaultMessage = (
  <FormattedMessage
    id="noApplications.noApplicationsFound"
    defaultMessage="Taotlused puuduvad"
  />
);

export const NoResults = ({ children = defaultMessage }: Props) => {
  return (
    <Card className="no-results-card">
      <div className="no-results-content">
        <img className="empty" src={empty} alt="Taotlused puuduvad"/>
        <br />
        {children}
      </div>
    </Card>
  );
};
