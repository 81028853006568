import { action } from "typesafe-actions";
import { Option } from "../../../../Component/Select/SelectTypes";
import {
  ActiveFilters,
  CountryListItem,
  SpecialityListItem
} from "./FilterTypes";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  GeneralPractitionerListStatusEnum,
  ActivityLicenseRowHeaderStatusEnum,
  ActivityLicenseApplicationStatus,
  DecisionStatusEnum, DateOperator, InsuranceContractStatus
} from "../../../../../api_client/medre_api";
import { activityLicenseInsuranceContractStatuses } from "./FilterUtils";

export type FilterItemData =
  | Option
  | null
  | string
  | Date
  | Option[]
  | boolean
  | undefined
  | number;

interface FilterItem {
  key: string;
  data: FilterItemData;
}

export interface ApplicantFilterState {
  personStatus: Option | null;
  occupationCode: string;
  occupations: Option[] | null;
  specialities: Option[] | null;
  name: string;
  idCodeCountry: Option | null;
  idCode: string;
  dateOfBirth?: Date;
  sex: Option | null;
  citizenship: Option | null;
  countryGroup: Option | null;
  countries: Option[] | null;
}

export const applicantResetValues: ApplicantFilterState = {
  personStatus: null,
  occupationCode: "",
  occupations: [],
  specialities: [],
  name: "",
  idCodeCountry: null,
  idCode: "",
  dateOfBirth: undefined,
  sex: null,
  citizenship: null,
  countryGroup: null,
  countries: []
};

export interface ApplicationFilterState {
  currentOccupations?: Option[];
  currentSpecialities?: Option[];
  currentCountries?: Option[];
  currentStatuses?: Option[];
  currentApplicationNumber?: number | null;
  timePeriod?: Option | null;
  currentStartDate?: Date;
  currentEndDate?: Date;
  stateFee?: Option | null;
  currentCountryGroup?: Option | null;
  currentDecisionNumber?: number;
  acceptedAutomatically?: boolean;
  onlyApplicationCertificate?: boolean;
  decisionStatuses?: Option[];
}

export const applicationResetValues: ApplicationFilterState = {
  currentOccupations: [],
  currentSpecialities: [],
  currentCountries: [],
  currentStatuses: [],
  currentApplicationNumber: null,
  timePeriod: null,
  currentStartDate: undefined,
  currentEndDate: undefined,
  stateFee: null,
  currentCountryGroup: null
};

export interface ActivityLicenseApplicationFilterState {
  businessName: string;
  businessAreaIds?: Option[] | null;
  applicationStatus?: ActivityLicenseApplicationStatus;
  proceedingOfficial: string;
  applicationNumber?: number | null;
  hospitalTypeIds?: Option[] | null;
  currentStartDate?: Date;
  currentEndDate?: Date;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
}

export const activityLicenseApplicationResetValues: ActivityLicenseApplicationFilterState =
  {
    businessName: "",
    businessAreaIds: null,
    applicationStatus: undefined,
    proceedingOfficial: "",
    applicationNumber: null,
    hospitalTypeIds: null,
    currentStartDate: undefined,
    currentEndDate: undefined
  };

export interface ApplicationCertificateFilterState {
  timePeriod?: Option | null;
  currentStartDate?: Date;
  currentEndDate?: Date;
}

export const applicationCertificateResetValues: ApplicationCertificateFilterState =
  {
    timePeriod: null,
    currentStartDate: undefined,
    currentEndDate: undefined
  };

export interface CompanyFilterState {
  companyName: string;
  registryCode: string;
  representativeName: string;
}

export const companyResetValues: CompanyFilterState = {
  companyName: "",
  registryCode: "",
  representativeName: ""
};

export interface InsuranceContractFilterState {
  policyHolder: string;
  insurer: string;
  contractNumber: string;
  status: Option[] | [];
  periodStartDate?: Date;
  periodEndDate?: Date;
  insuranceSumFrom: number | "";
  insuranceSumTo: number | "";
  periodStartDateOperator: DateOperator;
  periodEndDateOperator: DateOperator;
}

export const insuranceContractResetValues: InsuranceContractFilterState = {
  policyHolder: "",
  insurer: "",
  contractNumber: "",
  status: [],
  periodStartDate: undefined,
  periodEndDate: undefined,
  insuranceSumFrom: "",
  insuranceSumTo: "",
  periodStartDateOperator: DateOperator.Equals,
  periodEndDateOperator: DateOperator.Equals
};

export interface GeneralPractitionerListFilterState {
  code: string;
  companyName: string;
  activationDate?: Date;
  generalPractitioner?: (Option & {occupationCode: string}) | null;
  substitute?: (Option & {occupationCode: string}) | null;
  serviceArea?: string;
  status?: Option & {value: GeneralPractitionerListStatusEnum};
}

export const generalPractitionerListResetValues: GeneralPractitionerListFilterState =
  {
    code: "",
    companyName: "",
    activationDate: undefined,
    generalPractitioner: null,
    substitute: null,
    serviceArea: "",
    status: undefined
  };

export interface PublicGeneralPractitionerListFilterState {
  generalPractitionerOrSubstitute?: string;
  companyName?: string;
  serviceArea?: string;
  onlyWithVacancies?: boolean;
  code?: string;
}

export const publicGeneralPractitionerListResetValues: PublicGeneralPractitionerListFilterState =
  {
    generalPractitionerOrSubstitute: "",
    companyName: "",
    serviceArea: "",
    onlyWithVacancies: false,
    code: ""
  };

export interface ActivityLicensesFilterState {
  code?: string; // license number
  companyName?: string; // Teenuse osutaja
  hospitalId?: string; // 'Haigla liik' (Type of hospital) drop-down field
  countyId?: string; // 'Maakond' (Region) drop-down field
  status?: ActivityLicenseRowHeaderStatusEnum; // Kehtivus
  serviceId?: string; // Teenus, UUID
  serviceIds?: Option[]; // Teenus, UUID[]
  businessAreaId?: string; // UUID;
  businessAreaIds?: Option[]; // UUID[];
  insuranceContractStatuses?: InsuranceContractStatus | string;
}

export const activityLicenseResetValues: ActivityLicensesFilterState = {
  code: "",
  companyName: "",
  insuranceContractStatuses: InsuranceContractStatus.Valid
};

export interface OfficialActivityLicensesFilterState {
  code?: string;
  companyName?: string;
  hospitalId?: string;
  countyId?: string;
  status?: ActivityLicenseRowHeaderStatusEnum;
  serviceId?: string;
  serviceIds?: Option[];
  businessAreaId?: string;
  businessAreaIds?: Option[];
  insuranceContractStatuses?: Option[];
}
export const officialActivityLicenseResetValues: OfficialActivityLicensesFilterState = {
  code: "",
  companyName: "",
  insuranceContractStatuses: activityLicenseInsuranceContractStatuses
};

export interface PublicHealthcareEmployeesFilterState {
  occupationCode?: string;
  firstName?: string;
  lastName?: string;
  occupation?: string;
  speciality?: string;
}

export const publicHealthcareEmployeesResetValues: PublicHealthcareEmployeesFilterState =
  {
    occupationCode: "",
    firstName: "",
    lastName: ""
  };

interface DecisionFilterState {
  decisionNumber?: string;
  decisionSearchStatuses: Array<Option & {value: DecisionStatusEnum}>;
}

export interface DecisionTHTFilterState extends DecisionFilterState {
  personName?: string;
  countryCode?: string;
  personIdCode?: string;
  acceptedAutomatically?: boolean;
}

export const decisionTHTResetValues: DecisionTHTFilterState = {
  decisionNumber: "",
  personName: "",
  countryCode: "EST",
  personIdCode: "",
  acceptedAutomatically: false,
  decisionSearchStatuses: []
};

export interface DecisionLicenseFilterState extends DecisionFilterState {
  companyName?: string;
}

export const decisionLicenseResetValues: DecisionLicenseFilterState = {
  decisionNumber: "",
  companyName: "",
  decisionSearchStatuses: []
};

export const SET_OCCUPATIONS = "SET_OCCUPATIONS";
export const SET_SPECIALITIES = "SET_SPECIALITIES";
export const SET_FILTERED_SPECIALITIES = "SET_FILTERED_SPECIALITIES";
export const SET_FILTER_COUNTRIES = "SET_FILTER_COUNTRIES";
export const SET_FILTERED_COUNTRIES = "SET_FILTERED_COUNTRIES";
export const SET_APPLICANT_FILTER = "SET_APPLICANT_FILTER";
export const SET_ALL_APPLICANT_FILTERS = "SET_ALL_APPLICANT_FILTERS";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";
export const SET_APPLICATION_FILTER = "SET_APPLICATION_FILTER";
export const SET_ALL_APPLICATION_FILTERS = "SET_ALL_APPLICATION_FILTERS";
export const SET_ACTIVITY_LICENSE_APPLICATION_FILTER =
  "SET_ACTIVITY_LICENSE_APPLICATION_FILTER";
export const SET_ALL_ACTIVITY_LICENSE_APPLICATION_FILTERS =
  "SET_ALL_ACTIVITY_LICENSE_APPLICATION_FILTERS";
export const SET_APPLICATION_CERTIFICATE_FILTER =
  "SET_APPLICATION_CERTIFICATE_FILTER";
export const SET_ALL_APPLICATION_CERTIFICATE_FILTERS =
  "SET_ALL_APPLICATION_CERTIFICATE_FILTERS";
export const SET_COMPANY_FILTER = "SET_COMPANY_FILTER";
export const SET_ALL_COMPANY_FILTERS = "SET_ALL_COMPANY_FILTERS";
export const SET_GENERAL_PRACTITIONER_LIST_FILTER =
  "SET_GENERAL_PRACTITIONER_LIST_FILTER";
export const SET_ALL_GENERAL_PRACTITIONER_LIST_FILTERS =
  "SET_ALL_GENERAL_PRACTITIONER_LIST_FILTERS";
export const SET_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTER =
  "SET_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTER";
export const SET_ALL_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTERS =
  "SET_ALL_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTERS";
export const SET_ACTIVITY_LICENSE_FILTER = "SET_ACTIVITY_LICENSE_FILTER";
export const SET_ALL_ACTIVITY_LICENSE_FILTERS =
  "SET_ALL_ACTIVITY_LICENSE_FILTERS";
export const SET_OFFICIAL_ACTIVITY_LICENSE_FILTER = "SET_OFFICIAL_ACTIVITY_LICENSE_FILTER";
export const SET_ALL_OFFICIAL_ACTIVITY_LICENSE_FILTERS = "SET_ALL_OFFICIAL_ACTIVITY_LICENSE_FILTERS";
export const SET_PUBLIC_HEALTHCARE_EMPLOYEES_FILTER =
  "SET_PUBLIC_HEALTHCARE_EMPLOYEES_FILTER";
export const SET_ALL_PUBLIC_HEALTHCARE_EMPLOYEES_FILTERS =
  "SET_ALL_PUBLIC_HEALTHCARE_EMPLOYEES_FILTERS";

export const SET_DECISION_THT_FILTER = "SET_DECISION_THT_FILTER";
export const SET_ALL_DECISION_THT_FILTER = "SET_ALL_DECISION_THT_FILTER";
export const SET_DECISION_LICENSE_FILTER = "SET_DECISION_LICENSE_FILTER";
export const SET_ALL_DECISION_LICENSE_FILTER =
  "SET_ALL_DECISION_LICENSE_FILTER";
export const SET_INSURANCE_CONTRACT_FILTER = "SET_INSURANCE_CONTRACT_FILTER";
export const SET_ALL_INSURANCE_CONTRACT_FILTERS = "SET_ALL_INSURANCE_CONTRACT_FILTERS";

export const filterActions = {
  setOccupations: (occupations: Option[]) =>
    action(SET_OCCUPATIONS, occupations),

  setSpecialities: (specialities: SpecialityListItem[]) =>
    action(SET_SPECIALITIES, specialities),

  setFilteredSpecialities: (specialities: Option[]) =>
    action(SET_FILTERED_SPECIALITIES, specialities),

  setCountries: (countries: CountryListItem[]) =>
    action(SET_FILTER_COUNTRIES, countries),

  setFilteredCountries: (countries: Option[]) =>
    action(SET_FILTERED_COUNTRIES, countries),

  setFilterType: (type: ActiveFilters) => action(SET_FILTER_TYPE, type),

  setApplicantFilter: (filterItem: FilterItem) =>
    action(SET_APPLICANT_FILTER, filterItem),
  resetAllApplicantFilters: () =>
    action(SET_ALL_APPLICANT_FILTERS, applicantResetValues),

  setApplicationFilter: (filterItem: FilterItem) =>
    action(SET_APPLICATION_FILTER, filterItem),
  resetAllApplicationFilters: () =>
    action(SET_ALL_APPLICATION_FILTERS, applicationResetValues),

  setActivityLicenseApplicationFilter: (filterItem: FilterItem) =>
    action(SET_ACTIVITY_LICENSE_APPLICATION_FILTER, filterItem),
  resetAllActivityLicenseApplicationFilters: () =>
    action(
      SET_ALL_ACTIVITY_LICENSE_APPLICATION_FILTERS,
      activityLicenseApplicationResetValues
    ),

  setApplicationCertificateFilter: (filterItem: FilterItem) =>
    action(SET_APPLICATION_CERTIFICATE_FILTER, filterItem),
  resetAllApplicationCertificateFilters: () =>
    action(
      SET_ALL_APPLICATION_CERTIFICATE_FILTERS,
      applicationCertificateResetValues
    ),
  setCompanyFilter: (filterItem: FilterItem) =>
    action(SET_COMPANY_FILTER, filterItem),
  resetAllCompanyFilters: () =>
    action(SET_ALL_COMPANY_FILTERS, companyResetValues),

  setGeneralPractitionerListFilter: (filterItem: FilterItem) =>
    action(SET_GENERAL_PRACTITIONER_LIST_FILTER, filterItem),
  resetAllGeneralPractitionerListFilters: () =>
    action(
      SET_ALL_GENERAL_PRACTITIONER_LIST_FILTERS,
      generalPractitionerListResetValues
    ),

  setPublicGeneralPractitionerListFilter: (filterItem: FilterItem) =>
    action(SET_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTER, filterItem),
  resetAllPublicGeneralPractitionerListFilters: () =>
    action(
      SET_ALL_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTERS,
      publicGeneralPractitionerListResetValues
    ),

  setPublicActivityLicensesFilter: (filterItem: FilterItem) =>
    action(SET_ACTIVITY_LICENSE_FILTER, filterItem),
  resetAllActivityLicenseFilters: () =>
    action(SET_ALL_ACTIVITY_LICENSE_FILTERS, activityLicenseResetValues),

  setOfficialActivityLicensesFilter: (filterItem: FilterItem) =>
    action(SET_OFFICIAL_ACTIVITY_LICENSE_FILTER, filterItem),
  resetAllOfficialActivityLicenseFilters: () =>
    action(SET_ALL_OFFICIAL_ACTIVITY_LICENSE_FILTERS, officialActivityLicenseResetValues),

  setPublicHealthcareEmployeesFilter: (filterItem: FilterItem) =>
    action(SET_PUBLIC_HEALTHCARE_EMPLOYEES_FILTER, filterItem),
  resetAllHealthcareEmployeesFilters: () =>
    action(
      SET_ALL_PUBLIC_HEALTHCARE_EMPLOYEES_FILTERS,
      publicHealthcareEmployeesResetValues
    ),

  setDecisionTHTFilter: (filterItem: FilterItem) =>
    action(SET_DECISION_THT_FILTER, filterItem),
  resetAllDecisionTHTFilter: () =>
    action(SET_ALL_DECISION_THT_FILTER, decisionTHTResetValues),
  setDecisionLicenseFilter: (filterItem: FilterItem) =>
    action(SET_DECISION_LICENSE_FILTER, filterItem),
  resetAllDecisionLicenseFilter: () =>
    action(SET_ALL_DECISION_LICENSE_FILTER, decisionLicenseResetValues),

  setInsuranceContractFilter: (filterItem: FilterItem) =>
    action(SET_INSURANCE_CONTRACT_FILTER, filterItem),
  resetAllInsuranceContractFilters: () =>
    action(SET_ALL_INSURANCE_CONTRACT_FILTERS, insuranceContractResetValues),
};
