import React from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";

interface Props {
  mobile?: string;
  idCode?: string;
  challenge?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MobileSigning = ({
  mobile,
  idCode,
  challenge,
  handleChange
}: Props) => {
  const renderChallengeInfo = () => <div>Kontrollkood: {challenge}</div>;

  const renderInputFields = () => (
    <div className="mobile-signing-wrapper">
      <Label>Mobiil</Label>
      <InputGroup>
        <InputGroupAddon className="w-10" addonType="prepend">
          <InputGroupText>+372</InputGroupText>
        </InputGroupAddon>
        <Input name="mobile" value={mobile || ""} onChange={handleChange} />
      </InputGroup>
      <Label>Isikukood</Label>
      <InputGroup>
        <InputGroupAddon className="w-10" addonType="prepend">
          <InputGroupText>EE</InputGroupText>
        </InputGroupAddon>
        <Input name="idCode" value={idCode || ""} onChange={handleChange} />
      </InputGroup>
    </div>
  );

  return challenge ? renderChallengeInfo() : renderInputFields();
};
