import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import "./MyApplications.scss";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ApplicationTable } from "./ApplicationTable";
import { Loader } from "../../../../Component/Loader/Loader";
import { NoResults } from "../../Table/NoResults";
import { ApplicationDraft } from "../../../../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import {
  FileReference,
  Application,
  TaotluseAndmeteTeenusApiFactory as officialAppDataAPI
} from "../../../../../api_client/medre_api";
import { useApplications } from "../../../Shared/Application/ApplicationsContext";
import { getBaseUrl, API } from "../../../../api";

export const MyApplications = () => {
  const history = useHistory();

  const { loading, applications, setApplications, fetchApplications } =
    useApplications(
      officialAppDataAPI(undefined, getBaseUrl(), API).getApplication2
    );

  const { savedApplications, submittedApplications } = applications;
  const handleDraftDelete = useCallback(
    (index) => {
      setApplications((prevState) => {
        const nextSavedApplications = [
          ...(prevState.savedApplications as ApplicationDraft[])
        ];
        nextSavedApplications.splice(index, 1);
        return {
          ...prevState,
          savedApplications: nextSavedApplications
        };
      });
    },
    [setApplications]
  );

  const handleApplicationFileUpdate = useCallback(
    (rowIndex: number, fileReferences: FileReference[]) => {
      setApplications((prevState) => {
        const applicationListIndex =
          rowIndex - (prevState.savedApplications as ApplicationDraft[]).length;
        const nextSubmittedApplications = [
          ...(prevState.submittedApplications as Application[])
        ];
        nextSubmittedApplications?.splice(applicationListIndex, 1, {
          ...nextSubmittedApplications[applicationListIndex],
          fileReferences
        });
        return {
          ...prevState,
          submittedApplications: nextSubmittedApplications
        };
      });
    },
    [setApplications]
  );

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    } else if (savedApplications?.length || submittedApplications?.length) {
      return (
        <ApplicationTable
          reloadApplications={fetchApplications}
          handleDraftDelete={handleDraftDelete}
          savedApplications={
            savedApplications as (
              | ApplicationDraft
              | ApplicationCertificateDraft
            )[]
          }
          submittedApplications={submittedApplications}
          handleApplicationFileUpdate={handleApplicationFileUpdate}
        />
      );
    } else {
      return <NoResults />;
    }
  };

  return (
    <div className="my-applications-container">
      <Row>
        <h1 className="fw-300">
          <FormattedMessage
            id="myApplications.title"
            defaultMessage="Minu taotlused"
          />
        </h1>
        <div className="button-container">
          <PrimaryFormattedButton
            id="newApplication"
            onClick={() => history.push("/new-application")}
          />
        </div>
      </Row>
      {renderContent()}
    </div>
  );
};
