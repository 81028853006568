import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../rootReducer";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { EducationOverViewCard } from "../../../Shared/Application/OverView/EducationOverviewCard";
import { SpecialityOverViewCard } from "../../../Shared/Application/OverView/SpecialityOverViewCard";
import { OccupationOverViewCard } from "../../../Shared/Application/OverView/OccupationOverViewCard";
import { ContactInfoOverViewCard } from "../../../Shared/Application/OverView/ContactInfoOverViewCard";
import { PersonalDataOverViewCard } from "../../../Shared/Application/OverView/PersonalDataOverViewCard";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { DetailedApplicationTypeEnum } from "../../../../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { ArrivalChannelOverViewCard } from "./ArrivalChannelOverViewCard";

export const OfficialApplicationOverView = () => {
  const dispatch = useDispatch();
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft as ApplicationCertificateDraft
  );
  const personalData = applicationDraft.personalData;
  const contactInfo =
    applicationDraft.contactInfo || applicationDraft.personalData?.contactInfo;

  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft("saveAllowed", true)
    );
  }, [dispatch]);

  return (
    <>
      <h3 className="application-title fw-normal">
        <ApplicationFM id="submitApplication" />
      </h3>
      <p>
        <FormattedMessage
          id="applicationOverView.Instruction"
          defaultMessage={`Kontrolli taotluse info üle ja seejärel klõpsa nuppu “Esita taotlus”.
           Nupu klõpsamise järel muutub taotluse staatus “Esitatud” ning taotlejale 
           saadetakse riigilõivu maksmise juhend valitud kanalile.`}
        />
      </p>
      <div className="application-overview-card-container">
        <PersonalDataOverViewCard personalData={personalData} />
        <ContactInfoOverViewCard contactInfo={contactInfo} />
        {applicationDraft.type === DetailedApplicationTypeEnum.Occupation ? (
          <OccupationOverViewCard
            occupation={applicationDraft.occupation?.name}
            additionalOccupation={applicationDraft.additionalOccupation?.name}
          />
        ) : (
          <SpecialityOverViewCard
            occupation={applicationDraft.occupation?.name}
            speciality={applicationDraft.speciality?.name}
          />
        )}
        <EducationOverViewCard
          educationInfo={applicationDraft.educationInfo}
          files={applicationDraft.fileReferences}
        />
        <ArrivalChannelOverViewCard
          channelType={applicationDraft.channelType}
        />
      </div>
    </>
  );
};
