import React from "react";
import { FormattedMessage } from "react-intl";
import terms from "../../../../../../assets/images/termsLink.svg";

export const PortalLoginPageHeaderContent =
  <
    FormattedMessage id="portalLoginPage.guide" defaultMessage="Kasutusjuhendid"
  />;

export const PortalLoginPageTextContent =
  <>
    <FormattedMessage
      id="portalLoginPage.guideText"
      defaultMessage="Juhendid leiate <a>siit</a>"
      values={{
        a: (msg: string) => (
          <a
            href="https://www.terviseamet.ee/et/tervishoid/tervishoiutootajale/iseteeninduse-juhendid"
            rel="noopener noreferrer"
            target={"_blank"}
          >
            {msg}
          </a>
        )
      }}
    />
    <a
      href="https://www.terviseamet.ee/et/tervishoid/tervishoiutootajale/iseteeninduse-juhendid"
      rel="noopener noreferrer"
      target={"_blank"}
    >
      <img src={terms} alt="väline link" className="ml-2"/>
    </a>
  </>;

export const getQueryBoxHeaderContent = (contactInfo: string | undefined) => {
  return contactInfo ? (
    <FormattedMessage
      id="queryBox.populationRegistryData"
      defaultMessage="Andmed rahvastikuregistris"
    />
  ) : (
    <FormattedMessage
      id="queryBox.populationRegistryDataMissing"
      defaultMessage="Andmed rahvastikuregistris: puuduvad"
    />
  );
};

export const getQueryBoxTextContent = (contactInfo: string | undefined) => {
  return contactInfo ? contactInfo : "";
};
