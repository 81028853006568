import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { AxiosPromise } from "axios";

import { ActivityLocationRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationRow";
import { InvalidationReasonModal } from "../Invalidation/InvalidationReasonModal";
import useModal from "../../../../Hook/useModal";
import { doNothing } from "../../../../Util/MethodUtils";
import {
  FileObjectType,
  ActivityLicense,
  ActivityLocation,
  ActivityLicenseRowHeaderStatusEnum,
  TegevuslubadeTeenusApiFactory as activityPermitAPI,
  ActivityLicenseService,
  AmetnikuTegevuskohtadeKehtetuksTunnistamiseTeenusApiFactory as officialPlacesActivityAPI, LocationWithSuspensionInfo
} from "../../../../../api_client/medre_api";
import { RootState } from "../../../../rootReducer";
import { getBaseUrl, API } from "../../../../api";

interface Props {
  activityLocations: ActivityLocation[];
  isHospital: boolean;
  isEditable: boolean;
  refresh?: () => void;
  activityLicense?: ActivityLicense;
  serviceLocationEndpoint?: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseService>;
  locationLoadingEndpoint?: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLocation>;
  locationsSuspensionInfo?: Array<LocationWithSuspensionInfo>;
  onLocationSuspensionSubmit?: () => void;
  onServiceSuspensionSubmit?: () => void;
}

export const LocationAccordion = ({
  activityLocations,
  isHospital,
  isEditable,
  refresh,
  activityLicense,
  serviceLocationEndpoint,
  locationLoadingEndpoint,
  locationsSuspensionInfo,
  onLocationSuspensionSubmit,
  onServiceSuspensionSubmit,
}: Props) => {
  const [expandedRowNumber, setExpandedRowNumber] = useState<number>();
  const invalidationModal = useModal();
  useSelector(
      (state: RootState) => state.config.maxSuspensionDuration
  );
  const isSuspendable = isEditable;

  const isThereMoreThanOneValidLocation = activityLocations.filter(
      (l) => l.status === ActivityLicenseRowHeaderStatusEnum.Valid
  ).length > 1;

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      setExpandedRowNumber(rowNumber);
    } else {
      setExpandedRowNumber(undefined);
    }
  };

  const getLocationSuspensionInfoFromList = (locationId: string): LocationWithSuspensionInfo => {
    return locationsSuspensionInfo?.find(suspensionInfo => suspensionInfo.locationID === locationId) || {};
  }

  return (
    <>
      {activityLocations.map((location, i) => {
        const canInvalidate =
          isEditable &&
          activityLocations.filter(
            (l) => l.status !== ActivityLicenseRowHeaderStatusEnum.Invalid
          ).length > 1 &&
          !location.invalidationApplicationId &&
          (location.status === ActivityLicenseRowHeaderStatusEnum.Valid ||
            location.status === ActivityLicenseRowHeaderStatusEnum.Paused);

        const isExpanded = expandedRowNumber === i;

        return (
          <Fragment key={i}>
            <ActivityLocationRow
              location={location}
              isOpen={isExpanded}
              index={i}
              handleRowClick={handleRowClick}
              isHospital={isHospital}
              isEditable={isEditable}
              handleInvalidationButtonClick={
                canInvalidate && invalidationModal.handleOpen
              }
              activityLicense={activityLicense}
              locationLoadingEndpoint={locationLoadingEndpoint}
              loadServiceEndpoint={
                serviceLocationEndpoint ??
                activityPermitAPI(undefined, getBaseUrl(), API).getService1
              }
              isSuspendable={
                isSuspendable &&
                activityLocations &&
                activityLocations.length > 1 &&
                location.status !== ActivityLicenseRowHeaderStatusEnum.Invalid
              }
              isThereMoreThanOneValidLocation={isThereMoreThanOneValidLocation}
              specialistBusinessAreaId={activityLicense?.businessArea?.id}
              locationSuspensionInfo={getLocationSuspensionInfoFromList(location.id!)}
              refresh={refresh || doNothing}
              onLocationSuspensionSubmit={onLocationSuspensionSubmit}
              onServiceSuspensionSubmit={onServiceSuspensionSubmit}
            />
            {canInvalidate && (
              <InvalidationReasonModal
                isOpen={invalidationModal.isOpen && isExpanded}
                title={`Olete kindel, et soovite tegevuskoha ${location.fullAddress!} kehtetuks tunnistada?`}
                objectId={location?.id!}
                endpoint={
                  officialPlacesActivityAPI(undefined, getBaseUrl(), API)
                    .createActivityLicenseInvalidation
                }
                fileObjectType={
                  FileObjectType.ActivityLocationInvalidationDocument
                }
                onClose={invalidationModal.handleClose}
                onSubmitSuccess={refresh || doNothing}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
