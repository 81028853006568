import { FormattedMessage } from "react-intl";
import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";

import { getBaseUrl, API } from "../../../../../api";
import "./AddInitialSpecialistModal.scss";
import { ErrorMessage } from "../../../../../Component/ErrorField/ErrorMessage";
import { SecondaryFormattedButton } from "../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { SingleSelect } from "../../../../../Component/Select/SingleSelect";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import {
  SpecialistCode,
  PersonalData,
  TegevuslubadeTeenusApiFactory as activityPermitServiceAPI,
  KasutajaAndmeteTeenusApiFactory as userOfficialDataAPI,
  Occupation,
  HealthCareProfessional,
} from "../../../../../../api_client/medre_api";
import { MOBILE_MAX_WIDTH } from "../../../../../Constants";

interface Props {
  requiredOccupations: Occupation[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (specialist: any, specializationId: string) => void;
}

export const AddInitialSpecialistModal = ({
  requiredOccupations,
  isOpen,
  onClose,
  onSave,
}: Props) => {
  const [specialistCodes, setSpecialistCodes] = useState<SpecialistCode[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [personalCode, setPersonalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<JSX.Element | undefined>();
  const [specialist, setSpecialist] = useState<PersonalData | HealthCareProfessional | undefined>();

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const options = specialistCodes.map((code) => ({
    value: code.id,
    label: code.name + " - " + code.code,
  }));
  const requiredOccupation = requiredOccupations[0];

  useEffect(() => {
    activityPermitServiceAPI(undefined, getBaseUrl())
      .getActiveSpecialistCodes({
        withCredentials: true,
      })
      .then((res) => setSpecialistCodes(res.data));
  }, []);

  useEffect(() => {
    if (isOpen) {
      setSelectedOption(undefined);
      setPersonalCode("");
      setErrorMessage(undefined);
      setSpecialist(undefined);
    }
  }, [isOpen]);

  const renderRequirement = useCallback((entities?: Occupation[]) => {
    if (!entities) {
      return;
    }
    return (
      <div className="subtitle fw-normal my-0">
        <p>
          <FormattedMessage
            id="addSpecialistModal.specialistCode"
            defaultMessage="Teenusele on kohustuslik lisada alloleva spetsialiseerumisega töötaja(d):"
          />
        </p>
        <div className="occupation-name-wrapper">
          {requiredOccupation ? requiredOccupation.name : ''}
        </div>
      </div>
    );
  }, []);

  const handlePersonalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage(undefined);
    }
    setPersonalCode(e.target.value);
  };

  const retrieveSpecialist = () => {
    userOfficialDataAPI(undefined, getBaseUrl(), API).getByPersonalCode(personalCode, {
      withCredentials: true
    })
    .then((res) => {
      const data = res.data as HealthCareProfessional;
      setSpecialist(data)
    })
    .catch((res) => {
      let responseData = res.response?.data;
      if (responseData.error === "InvalidPersonalCodeException") {
        setErrorMessage(
          <FormattedMessage
            id="addSpecialistModal.wrongIdCode"
            defaultMessage="Isikukoodi formaat on vale"
          />
        );
      } else if (responseData.error === "PersonNotFoundException") {
        setErrorMessage(
          <FormattedMessage
            id="addSpecialistModal.populationRegistryException"
            defaultMessage="Isikukoodiga isikut ei ole Rahvastikuregistris"
          />
        );
      } else {
        setErrorMessage(
          <FormattedMessage
            id="addSpecialistModal.generalException"
            defaultMessage="Viga isiku kontrollimisel"
          />
        );
      }
      setSpecialist(undefined);
      setSelectedOption(undefined);
    });
  };

  return (
    <Modal id="add-specialist-modal" isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="addSpecialistModal.header"
          defaultMessage="Lisa spetsialist"
        />
      </ModalHeader>
      <ModalBody>
        {!!requiredOccupations && renderRequirement(requiredOccupations)}
        <p className="subtitle fw-normal my-0">
          <FormattedMessage
            id="addSpecialistModal.searchLabel"
            defaultMessage="Otsi spetsialisti"
          />
        </p>
        <div className="personal-code-search-box">
          <div className="input-container">
            <p className="m-0">
              <FormattedMessage
                id="addSpecialistModal.personalCode"
                defaultMessage="Isikukood"
              />
            </p>
            <div>
              <Input
                className="personal-code-input"
                name="personalCode"
                value={personalCode}
                onChange={handlePersonalCodeChange}
                invalid={!!errorMessage}
              />
              {errorMessage && (
                <div className="d-block personal-code-error">
                  <ErrorMessage message={errorMessage} />
                </div>
              )}
            </div>
            <Button
              color="primary"
              className={"btn-primary search-personal-data"}
              onClick={retrieveSpecialist}
              disabled={!personalCode}
            >
              <FormattedMessage
                id="addSpecialistModal.validateData"
                defaultMessage="Kontrolli andmeid"
              />
            </Button>
          </div>
          {specialist && (
            <>
              <p className="subtitle ml-0 mb-2 mt-4">
                {specialist.firstName} {specialist.lastName} (
                {specialist.idCode})
              </p>
              {!requiredOccupations && (
                <div className="input-container">
                  <FormattedMessage
                    id="addSpecialistModal.selectSpecialization"
                    defaultMessage="Vali spetsialiseerumine"
                  />
                  <SingleSelect
                    value={selectedOption}
                    className="speciality-select"
                    options={options}
                    hideSearchIcon={true}
                    handleOptionChange={setSelectedOption}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter
        className={
          isMobile ? "justify-content-center" : "justify-content-right"
        }
      >
        <SecondaryFormattedButton id="cancel" onClick={onClose} />
        <PrimaryFormattedButton
          id="addEmployeeToService"
          disabled={!(selectedOption || (specialist))}
          onClick={() => onSave(specialist!, selectedOption?.value)}
        />
      </ModalFooter>
    </Modal>
  );
};
