import React from "react";
import { Card } from "reactstrap";

import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { GPListAppointmentTimesTable } from "./GPListAppointmentTimesTable";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";

export const GPListAppointmentTimes = () => {
  const {
    list: { contacts }
  } = useGeneralPractitionerList();

  return (
    <Card className="my-4 pb-1 p-0 p-md-4">
      <div
        id={GENERAL_PRACTITIONERS_DETAILS_SECTIONS.HOURS.id}
        className="h2 d-flex justify-content-between pt-3 pr-3 pl-3 p-md-0 m-0"
      >
        <h2>{GENERAL_PRACTITIONERS_DETAILS_SECTIONS.HOURS.title}</h2>
      </div>

      {contacts.map((contact) => (
        <GPListAppointmentTimesTable
          key={contact.locationId}
          contact={contact}
        />
      ))}
    </Card>
  );
};
