import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";
import { AxiosError, AxiosPromise } from "axios";
import { useDispatch, useSelector } from "react-redux";

import { getBaseUrl, API } from "../../../../../api";
import { LinkButton } from "../../../../../Component/Button/LinkButton";
import { AlertMessage } from "../../../../../Alert/AlertMessage";
import { AlertType } from "../../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../../Alert/alertActions";
import { EhisEducationNotFoundCard } from "./EhisEducationNotFoundCard";
import { Loader } from "../../../../../Component/Loader/Loader";
import { EhisQueryConfirmationModal } from "./EhisQueryConfirmationModal";
import { EhisEducationViewCard } from "./EhisEducationViewCard";
import { SwitchToManualEducationViewModal } from "../SwitchToManualEducationViewModal";
import { RootState } from "../../../../../rootReducer";
import {
  EducationInfo,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI,
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI, FileReference, FileObjectType
} from "../../../../../../api_client/medre_api";
import { applicationDraftActions } from "../../../../../Application/applicationDraftActions";
import { ErrorResponseData } from "../../../../../Dto/Error/Error";

interface Props {
  occupationCode?: string;
  personId: string;
  toggleAutomaticEducationLoad: () => void;
  setIsSaveAllowed: (isAllowed: boolean) => void;
  isManualViewButtonHidden?: boolean;
  educationInfo?: EducationInfo;
  updateEducationInfo: (educationInfo: EducationInfo) => void;
  isRenewalModalView?: boolean;
  onEhisEducationStateChange?: (state: boolean) => void;
  fileReferences?: FileReference[];
  applicationId?: string;
  saveFiles?: (files: File[], fileType: FileObjectType) => void;
  deleteFile?: (fileName: string, fileType: FileObjectType) => void;
}

export const AutomaticEducationView = ({
  occupationCode,
  toggleAutomaticEducationLoad,
  setIsSaveAllowed,
  isManualViewButtonHidden,
  personId,
  educationInfo,
  updateEducationInfo,
  isRenewalModalView = false,
  onEhisEducationStateChange = (state: boolean) => {},
  fileReferences,
  applicationId,
  saveFiles,
  deleteFile
}: Props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noEducationFoundFromEHIS, setNoEducationFoundFromEHIS] =
    useState<boolean>();
  const [showReQueryConfirmationModal, setShowReQueryConfirmationModal] =
    useState<boolean>(false);
  const [showSwitchConfirmationModal, setShowSwitchConfirmationModal] =
    useState<boolean>(false);
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const applicationDraft = useSelector((state: RootState) => state.applicationDraft);

  useEffect(() => {
    if (
      !educationInfo ||
      !educationInfo.diplomaNumber ||
      educationInfo.fromEHIS === false
    ) {
      loadDataFromEhis();
    } else if (isLoading || noEducationFoundFromEHIS) {
      markEducationFromEhisAlreadyReceived();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStudiesData = (isPortal?: boolean): AxiosPromise<EducationInfo> =>
    isPortal
      ? userDataAPI(undefined, getBaseUrl(), API).getEhisStudies(
        occupationCode as string,
        {
          withCredentials: true
        }
      )
      : officialUserDataAPI(undefined, getBaseUrl(), API).getEhisStudies1(
        personId,
        occupationCode as string,
        {
          withCredentials: true
        }
      );

  const loadDataFromEhis = useCallback(() => {
    setIsLoading(true);
    getStudiesData(isPortal)
      .then((result) => {
        updateEducationInfo(result.data);
        setNoEducationFoundFromEHIS(false);
        onEhisEducationStateChange(false);
        setIsSaveAllowed(true);
      })
      .catch((error: AxiosError) => {
        let responseData = error.response?.data as ErrorResponseData;
        let alertMessage;
        setNoEducationFoundFromEHIS(true);
        onEhisEducationStateChange(true);
        if (
          responseData.error !== "EducationNotFoundFromEhisException" &&
          responseData.message !== "Isiku kohta andmeid ei leitud."
        ) {
          alertMessage = <AlertMessage id="ehisRequestFailed" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        }
      })
      .finally(() => setIsLoading(false));
  }, [
    dispatch,
    occupationCode,
    setIsSaveAllowed,
    isPortal,
    personId,
    updateEducationInfo
  ]);

  const markEducationFromEhisAlreadyReceived = useCallback(() => {
    setIsLoading(false);
    setNoEducationFoundFromEHIS(false);
    onEhisEducationStateChange(false);
    setIsSaveAllowed(true);
  }, [setIsSaveAllowed, onEhisEducationStateChange]);

  const reloadDataFromEhis = (): void => {
    if (!educationInfo) {
      loadDataFromEhis();
    } else {
      setShowReQueryConfirmationModal(true);
    }
  };

  const handleReQueryConfirmation = (): void => {
    setShowReQueryConfirmationModal(false);
    loadDataFromEhis();
  };

  const handleEducationTypeSwitchConfirmation = (): void => {
    setShowSwitchConfirmationModal(false);
    toggleAutomaticEducationLoad();
    if (applicationDraft.fileReferences && applicationDraft.fileReferences.length > 0) {
      fileUploadAPI(undefined, getBaseUrl(), API)
        .deleteMultiple(applicationDraft.id as string, { withCredentials: true })
        .then(() =>
          dispatch(applicationDraftActions.updateApplicationDraft("fileReferences", []))
        );
    }
  };

  const switchToManualEducation = (): void => {
    if (noEducationFoundFromEHIS) {
      toggleAutomaticEducationLoad();
    } else {
      setShowSwitchConfirmationModal(true);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="education-content">
      <Row className={`ml-0 ${isPortal && "mt-3"}`}>
        <h5 className="fw-normal">
          <FormattedMessage
            id="AutomaticEducationView.estonianEducation"
            defaultMessage="Eestis omandatud haridus "
          />
        </h5>
        <LinkButton
          id="newQuery"
          className="p-0 pl-1 mb-2 align-baseline"
          onClick={(): void => reloadDataFromEhis()}
        />
      </Row>
      { noEducationFoundFromEHIS ? (
        <EhisEducationNotFoundCard />
      ) : (
        <EhisEducationViewCard
          educationInfo={educationInfo!}
          isRenewalModalView={isRenewalModalView}
          fileReferences={fileReferences}
          applicationId={applicationId}
          saveFiles={saveFiles}
          deleteFile={deleteFile}
        />
      ) }

      { !isManualViewButtonHidden && (
        <div className="mt-4">
          <FormattedMessage
            id="AutomaticEducationView.addEducationManually"
            defaultMessage="Kui omandatud haridus on välismaal või ei kajastu nimekirjas, siis <button>märkige see käsitsi</button>"
            values={{
              button: (msg: string) => (
                <LinkButton
                  id="setManually"
                  className="p-0 align-baseline"
                  onClick={(): void => switchToManualEducation()}
                />
              )
            }}
          />
        </div>
      ) }
      <EhisQueryConfirmationModal
        isOpen={showReQueryConfirmationModal}
        onClose={(): void => setShowReQueryConfirmationModal(false)}
        onSave={(): void => handleReQueryConfirmation()}
      />
      <SwitchToManualEducationViewModal
        isOpen={showSwitchConfirmationModal}
        onClose={(): void => setShowSwitchConfirmationModal(false)}
        onSave={handleEducationTypeSwitchConfirmation}
      />
    </div>
  );
};
