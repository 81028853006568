import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from "react";
import { FormattedMessage } from "react-intl";
import { Input, Popover, PopoverBody } from "reactstrap";
import { useDispatch } from "react-redux";

import "./FileDropzone.scss";
import { AlertType } from "../../Dto/Alert/AlertItem";
import { PrimaryFormattedButton } from "../Button/PrimaryFormattedButton";
import { displayAlert, displayAlertWithValues } from "../../Util/AlertUtil";
import { areFileNamesUnique } from "../../Util/FileUtils";
import { FileObjectType, FileReference } from "../../../api_client/medre_api";

interface Props {
  container?: React.RefObject<HTMLElement>;
  toggleButton?: React.RefObject<HTMLButtonElement>;
  type: FileObjectType;
  saveFiles: (files: File[]) => void;
  fileReferences: FileReference[];
  maxFilesCount: number;
  allowedFileTypes?: string[];
  togglePopOver?: (value: boolean) => void;
}

export const FileDropZone = ({
  container,
  toggleButton,
  type,
  saveFiles,
  fileReferences,
  maxFilesCount,
  allowedFileTypes,
  togglePopOver
}: Props) => {
  const dispatch = useDispatch();
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);
  const [dragEventCounter, setDragEventCounter] = useState(0);

  const toggle = useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
    togglePopOver && togglePopOver(isPopoverOpen);
  }, [setIsPopoverOpen, isPopoverOpen]);

  useEffect(() => {
    if (toggleButton && toggleButton.current && !isPopoverOpen) {
      toggleButton.current.focus();
    }
  }, [toggleButton, isPopoverOpen]);

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    const files = Array.from(e.dataTransfer.files || []);
    validateFiles(files);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
    setDragEventCounter(dragEventCounter + 1);
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    setDragEventCounter(dragEventCounter - 1);
    e.preventDefault();
  };

  const handleDragOver = (e: React.DragEvent<HTMLElement>) =>
    e.preventDefault();

  const validateFiles = (files: File[]) => {
    if (!files || files.length === 0) {
      return;
    }
    if (isMaxFileCountInvalid(files.length)) {
      displayAlertWithValues(
        "maxFilesUploadNumberExceeded",
        { maxFilesCount: maxFilesCount },
        AlertType.Danger,
        dispatch
      );
      return;
    }
    if (fileReferences && !areFileNamesUnique(files, fileReferences)) {
      displayAlert("fileNameNotUnique", AlertType.Danger, dispatch);
      return;
    }

    files = validateFileSizes(files);
    if (!areFileTypesValid(files)) {
      displayAlert("fileExtensionNotAllowed", AlertType.Danger, dispatch);
      return;
    }

    if (files.length > 0) {
      saveFiles(files);
      closePopUp();
    }
  };

  const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = Array.from(e.target.files || []);
    validateFiles(files);
  };

  const validateFileSizes = (files: File[]) => {
    const FILE_UPLOAD_MAX_SIZE = process.env.REACT_APP_FILE_UPLOAD_MAX_SIZE ? parseInt(process.env.REACT_APP_FILE_UPLOAD_MAX_SIZE) : 31457280;
    return files.filter((f: File, i) => {
      if (f.size <= FILE_UPLOAD_MAX_SIZE) {
        return true;
      }
      displayAlert("maxFileUploadSizeExceeded", AlertType.Danger, dispatch);
      return false;
    });
  };

  const isMaxFileCountInvalid = (uploadedFilesCount: number) =>
    fileReferences
      ? fileReferences.length + uploadedFilesCount > maxFilesCount
      : uploadedFilesCount > maxFilesCount;

  const areFileTypesValid = useCallback(
    (files: File[]) => {
      if (!allowedFileTypes) {
        return true;
      }
      return files.every((f: File, i) => {
        const nameParts = f.name.split(".");
        return allowedFileTypes?.includes(nameParts[nameParts.length - 1]);
      });
    },
    [allowedFileTypes]
  );

  const popoverTarget = () => "POPOVER_" + type;

  const getDropZoneClassName = () =>
    dragEventCounter !== 0 ? "dragging" : "initial";

  const closePopUp = () => {
    setIsPopoverOpen(false);
    setDragEventCounter(0);
  };

  const popoverRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    setTimeout(() => {
      if (popoverRef.current) {
        popoverRef.current.focus();
      }
    }, 0);
  }, [popoverRef]);

  const renderPopoverBody = () => {
    if (dragEventCounter !== 0) {
      return (
        <div className="text-top dragging-text">
          <h2>
            <FormattedMessage
              id="fileUpload.uploadFile"
              defaultMessage="Lae fail üles"
            />
          </h2>
        </div>
      );
    }

    return (
      <div className="text-top">
        <h2>
          <FormattedMessage
            id="fileUpload.dragFileHere"
            defaultMessage="Lohista fail siia"
          />
        </h2>
        <div className="upload-btn-wrapper">
          <FormattedMessage
            id="fileUpload.fileManualSearch"
            defaultMessage="või otsi arvutist"
          />
          <PrimaryFormattedButton
            tag="span"
            id="choose"
            className="ml-1"
            size="sm"
          />
          <Input
            title=""
            type="file"
            onChange={onFileChangeHandler}
            multiple={true}
          />
        </div>
      </div>
    );
  };

  return (
    <Popover
      innerRef={popoverRef}
      trigger="legacy"
      placement="top-start"
      target={popoverTarget()}
      isOpen={isPopoverOpen}
      toggle={toggle}
      className="popover-container mb-2"
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      {...(container && {
        container: container
      })}
    >
      <PopoverBody className={"dropzone-inner " + getDropZoneClassName()}>
        {renderPopoverBody()}
        <div className="text-bottom">
          <FormattedMessage
            id="fileUpload.multipleFiles"
            defaultMessage="Mitme faili üleslaadimiseks hoidke all CTRL/CMD klahvi"
          />
        </div>
      </PopoverBody>
    </Popover>
  );
};
