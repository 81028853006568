import { useCallback, useEffect, useState } from "react";
import { getBaseUrl, API } from "../../../../api";
import {
  PdevusteLisamineIsikuAndmeteleAmetnikuportaalisApiFactory as competencePersonalDataAPI,
  Competence
} from "../../../../../api_client/medre_api";

export const useCompetencies = (personId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [competencies, setCompetencies] = useState<Competence[]>([]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    competencePersonalDataAPI(undefined, getBaseUrl(), API)
      .getAllCompetenciesForPerson1(personId, { withCredentials: true })
      .then((result) => {
        if (isMounted) {
          setCompetencies(result.data);
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [personId]);

  const handleCreate = useCallback(
    (body: Competence, callback?: () => void) => {
      competencePersonalDataAPI(undefined, getBaseUrl(), API)
        .createCompetence(personId, body, { withCredentials: true })
        .then(({ data }) => {
          setCompetencies((current) => [...current, data]);

          if (callback) {
            callback();
          }
        });
    },
    [personId]
  );

  const handleUpdate = useCallback(
    (body: Competence, callback?: () => void) => {
      competencePersonalDataAPI(undefined, getBaseUrl(), API)
        .updateCompetence(personId, body.id, body, { withCredentials: true })
        .then(({ data }) => {
          setCompetencies((current) =>
            current.map((competence) =>
              competence.id === data.id ? data : competence
            )
          );

          if (callback) {
            callback();
          }
        });
    },
    [personId]
  );

  const handleDelete = useCallback(
    (competenceId: string, callback?: () => void) => {
      setDeleting(true);

      competencePersonalDataAPI(undefined, getBaseUrl(), API)
        .deleteCompetence(personId, competenceId, { withCredentials: true })
        .then(() => {
          setCompetencies((current) =>
            current.filter((competence) => competence.id !== competenceId)
          );
          setDeleting(false);

          if (callback) {
            callback();
          }
        });
    },
    [personId]
  );

  return {
    competencies,
    loading,
    deleting,
    handleCreate,
    handleUpdate,
    handleDelete
  };
};
