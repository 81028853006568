import { useCallback, useMemo, useState } from "react";
import { ModalProps } from "reactstrap";

export default function useModal(
  { isOpen = false, ...props }: Partial<ModalProps> = { open: false }
) {
  const [open, setOpen] = useState<boolean>(isOpen);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return useMemo(
    () => ({
      ...props,
      handleOpen,
      handleClose,
      isOpen: open
    }),
    [handleClose, handleOpen, open, props]
  );
}
