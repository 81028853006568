import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";

import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { ProtectedFieldWrapper } from "../../../Security/ProtectedFieldWrapper";
import {
  OfficialUserPrivilegesEnum,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI
} from "../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../api";

export const GeneralPractitionerListCreation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const handleCreateNewList = useCallback(() => {
    setLoading(true);
    officialFamilyListAPI(undefined, getBaseUrl(), API)
      .createGeneralPractitionerList({
        withCredentials: true
      })
      .then((res) => {
        const { id } = res.data;
        if (id) {
          history.push(`/general-practitioner-lists/${id}`);
        }
      })
      .finally(() => setLoading(false));
  }, [history]);

  return (
    <ProtectedFieldWrapper
      allowedRoles={[
        OfficialUserPrivilegesEnum.Proceede,
        OfficialUserPrivilegesEnum.Sign
      ]}
    >
      <PrimaryFormattedButton
        id="newGeneralPractitionerList"
        onClick={handleCreateNewList}
        disabled={loading}
      />
    </ProtectedFieldWrapper>
  );
};
