import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { AxiosPromise } from "axios";
import NavigationPrompt from "react-router-navigation-prompt";
import { FormattedMessage } from "react-intl";

import { RootState } from "../../../rootReducer";
import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import { ActivityLicenseStep, OfficialActivityLicenseStep } from "./ActivityLicenseStep";
import { LinkButton } from "../../../Component/Button/LinkButton";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { API, getBaseUrl } from "../../../api";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import { ApplicationDiscardConfirmationModal } from "../Application/Footer/ApplicationDiscardConfirmationModal";
import { ApplicationSaveConfirmationModal } from "../Application/Footer/ApplicationSaveConfirmationModal";
import {
  ActivityLicenseApplication,
  AmetnikuTegevuslubadeTaastamiseTeenusApiFactory as officialLicenseRestoreAppAPI,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityLicenseAppAPI,
  AmetnikuTegevuslubadeTeenusteTaotlusteTeenusApiFactory as officialServiceAppAPI,
  AmetnikuTegevuslubadeTegevuskohtadeTaotlusteTeenusApiFactory as officialLocationAppAPI,
  LicenseAcquisition,
  RestorationCreation,
  TegevuslubadeLevtmisteTeenusApiFactory as licenseAcquisitionAPIFactory,
  TegevuslubadeTaotlusteTeenusApiFactory as activityLicenseAppAPI,
  TegevuslubadeTeenusteTaotlusteTeenusApiFactory as activityServiceAppAPI,
  TegevuslubadeTegevuskohtadeTaotlusteTeenusApiFactory as locationAppAPI
} from "../../../../api_client/medre_api";
import { ActivityLicenseServiceStep, OfficialActivityLicenseServiceStep } from "./Services/ActivityLicenseServiceStep";
import { displayAlert } from "../../../Util/AlertUtil";

interface FooterUrls {
  routes: {
    portal: string;
    official: string;
  };
}

interface Props {
  currentStep: number;
  steps: JSX.Element[];
  urls: FooterUrls;
  isService?: boolean;
}

const locationAPI = locationAppAPI(undefined, getBaseUrl(), API);
const officialLocationAPI = officialLocationAppAPI(undefined, getBaseUrl(), API);
const serviceAPI = activityServiceAppAPI(undefined, getBaseUrl(), API);
const officialServiceAPI = officialServiceAppAPI(undefined, getBaseUrl(), API);
const licenseApplicationAPI = activityLicenseAppAPI(undefined, getBaseUrl(), API);
const officialLicenseApplicationAPI = officialActivityLicenseAppAPI(undefined, getBaseUrl(), API);
const officialLicenseRestoreAPI = officialLicenseRestoreAppAPI(undefined, getBaseUrl(), API);
const licenseAcquisitionAPI = licenseAcquisitionAPIFactory(undefined, getBaseUrl(), API);

const withCredentialsOptions = {
  withCredentials: true
};
export const ActivityLicenseFooter = ({
  currentStep,
  urls,
  isService,
  steps
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const activityLicense = useSelector(
    (state: RootState) =>
      state.activityLicenseApplication as ActivityLicenseApplication
  );
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [shouldShowNavigationPrompt, setShouldShowNavigationPrompt] =
    useState(true);

  const navigate = (changeStep: number) => {
    let shouldValidate = true;
    switch (currentStep) {
      case ActivityLicenseStep.BUSINESS_DATA:
        if (changeStep === -1) {
          dispatch(
            activityLicenseApplicationActions.updateActivityLicense(
              "company",
              undefined
            )
          );
          history.push("/");
          break;
        }
        handleNavigation(currentStep + changeStep, shouldValidate, false);
        break;
      case isPortal
        ? ActivityLicenseStep.OVERVIEW
        : OfficialActivityLicenseStep.OVERVIEW:
        if (changeStep === 1) {
          if (isService) {
            serviceAPI.submitPortalActivityLicenseServiceApplication1(activityLicense.id!, {
              withCredentials: true
            }).then((response) => {
              setShouldShowNavigationPrompt(false);
              dispatch(
                activityLicenseApplicationActions.setActivityLicense(
                  response.data
                )
              );
              if (isPortal) {
                history.push(urls.routes.portal);
              } else {
                history.push(`${urls.routes.official}/${response.data.id}`);
              }
            });
          } else {
            const submitPromise = isPortal
              ? licenseApplicationAPI.submitPortalActivityLicenseApplication1(activityLicense.id!, withCredentialsOptions)
              : officialServiceAPI.submitPortalActivityLicenseServiceApplication(activityLicense.id!, withCredentialsOptions);

            submitPromise
              .then((response) => {
                setShouldShowNavigationPrompt(false);

                if (response.data.hasOwnProperty("activityLicense")) {
                  delete response.data.activityLicense?.locations;
                }

                dispatch(
                  activityLicenseApplicationActions.setActivityLicense(
                    response.data
                  )
                );

                if (isPortal) {
                  history.push(urls.routes.portal);
                } else {
                  history.push(`${urls.routes.official}/${response.data.id}`);
                }
              }).catch(()=>{
                displayAlert("activityLicenseSubmitFailed", AlertType.Danger, dispatch);
              });
          }
        } else {
          if (changeStep === -1) {
            shouldValidate = false;
          }
          handleNavigation(currentStep + changeStep, shouldValidate, false);
        }
        break;
      default:
        if (changeStep === -1) {
          shouldValidate = false;
        }
        handleNavigation(currentStep + changeStep, shouldValidate, false);
    }
  };

  const serviceNavigation = (changeStep: number) => {
    let shouldValidate = true;
    switch (currentStep) {
      case isPortal
        ? ActivityLicenseServiceStep.OVERVIEW
        : OfficialActivityLicenseServiceStep.OVERVIEW:
        if (changeStep === 1) {
          const submitPromise = isPortal
            ? serviceAPI.submitPortalActivityLicenseServiceApplication1(activityLicense.id!, withCredentialsOptions)
            : officialServiceAPI.submitPortalActivityLicenseServiceApplication(activityLicense.id!, withCredentialsOptions);

          submitPromise.then((response) => {
            setShouldShowNavigationPrompt(false);
            dispatch(
              activityLicenseApplicationActions.setActivityLicense(
                response.data
              )
            );
            if (isPortal) {
              history.push(urls.routes.portal);
            } else {
              history.push(`${urls.routes.official}/${response.data.id}`);
            }
          }
          );
        } else {
          if (changeStep === -1) {
            shouldValidate = false;
          }
          handleNavigation(currentStep + changeStep, shouldValidate, false);
        }
        break;
      default:
        if (changeStep === -1) {
          shouldValidate = false;
        }
        handleNavigation(currentStep + changeStep, shouldValidate, false);
    }
  };

  const handleNavigation = (newStep: number, shouldValidate: boolean, isSave: boolean) => {
    const request = activityLicense.id
      ? updateRequest(shouldValidate)
      : createRequest();
    requestExecuteAndCallback(request, isSave, newStep);
  };

  const requestExecuteAndCallback = (request: AxiosPromise, isSave: boolean, newStep: number) => {
    request
      .then((response) => {
        if (isSave) {
          return handleLicenseSaveSuccess();
        }
        setShouldShowNavigationPrompt(false);
        const license: ActivityLicenseApplication = response.data;
        license.currentStep = newStep || 0;
        license.company = activityLicense.company;

        dispatch(activityLicenseApplicationActions.setActivityLicense(license));
      })
      .catch(() => {
        displayAlert("applicationSaveFailed", AlertType.Danger, dispatch);
      })
      .finally(() => {
        if (isSave) {
          setConfirmationModalIsOpen(false);
        }
      });
  };

  const handleLicenseSaveSuccess = () => {
    displayAlert("activityLicenseSaveSuccess", AlertType.Success, dispatch);
    setShouldShowNavigationPrompt(false);
    history.push(isPortal ? "/applications" : "/search");
  };

  const createRequest = (): AxiosPromise => {

    let axiosPromise: AxiosPromise;

    switch (activityLicense.proceedingType) {
      case "ACTIVITY_LICENSE":
        axiosPromise = isPortal
          ? licenseApplicationAPI.savePortalActivityLicenseApplication1(activityLicense)
          : officialLicenseApplicationAPI.saveActivityLicense(activityLicense);
        break;
      case "ACTIVITY_LICENSE_RESTORATION":
        if (isPortal) {
          throw new Error("ACTIVITY_LICENSE_RESTORATION");
        }
        axiosPromise = officialLicenseRestoreAPI.initiateActivityLicenseRestoration(activityLicense.id!, {} as RestorationCreation, []);
        break;
      case "SERVICE":
        axiosPromise = isPortal
          ? serviceAPI.savePortalActivityLicenseServiceApplication(activityLicense)
          : officialServiceAPI.saveActivityLicenseServiceApplication(activityLicense, withCredentialsOptions);
        break;
      case "ACTIVITY_LOCATION":
        axiosPromise = isPortal
          ? locationAPI.savePortalActivityLocationApplication(activityLicense)
          : officialLocationAPI.saveActivityLocationApplication(activityLicense, withCredentialsOptions);
        break;
      case "LICENSE_ACQUISITION":

        if (!isPortal) {
          throw new Error("LICENSE_ACQUISITION");
        }

        axiosPromise = licenseAcquisitionAPI.saveApplication({} as LicenseAcquisition);
        break;

      default:
        axiosPromise = isPortal
          ? licenseApplicationAPI.savePortalActivityLicenseApplication1(activityLicense)
          : officialLicenseApplicationAPI.saveActivityLicense(activityLicense);
        break;
    }

    return axiosPromise;
  };

  const updateRequest = (shouldValidate: boolean): AxiosPromise => {

    let axiosPromise: AxiosPromise;

    switch (activityLicense.proceedingType) {
      case "ACTIVITY_LICENSE":
        axiosPromise = isPortal
          ? shouldValidate
            ? licenseApplicationAPI.savePortalActivityLicenseApplication(activityLicense.id!, activityLicense)
            : licenseApplicationAPI.savePortalActivityLicenseApplicationWithoutValidations(activityLicense.id!, activityLicense)
          : shouldValidate
            ? officialLicenseApplicationAPI.updateActivityLicense(activityLicense.id!, activityLicense)
            : officialLicenseApplicationAPI.saveActivityLicenseWithoutValidations(activityLicense.id!, activityLicense);
        break;
      case "SERVICE":
        axiosPromise = isPortal
          ? shouldValidate
            ? serviceAPI.updatePortalActivityLicenseApplication1(activityLicense.id!, activityLicense, withCredentialsOptions)
            : serviceAPI.updateWithoutValidation2(activityLicense.id!, activityLicense, withCredentialsOptions)
          : shouldValidate
            ? officialServiceAPI.updatePortalActivityLicenseApplication(activityLicense.id!, activityLicense, withCredentialsOptions)
            : officialServiceAPI.updateWithoutValidation(activityLicense.id!, activityLicense, withCredentialsOptions);
        break;
      case "ACTIVITY_LOCATION":
        axiosPromise = isPortal
          ? shouldValidate
            ? locationAPI.updatePortalActivityLocationApplication(activityLicense.id!, activityLicense)
            : locationAPI.savePortalActivityLocationApplicationWithoutValidations(activityLicense.id!, activityLicense)
          : shouldValidate
            ? officialLocationAPI.updateActivityLocationApplication(activityLicense.id!, activityLicense, withCredentialsOptions)
            : officialLocationAPI.saveApplicationWithoutValidations(activityLicense.id!, activityLicense, withCredentialsOptions);
        break;
      case "LICENSE_ACQUISITION":

        if (!isPortal) {
          throw new Error("LICENSE_ACQUISITION");
        }

        axiosPromise = shouldValidate
          ? licenseAcquisitionAPI.updateApplication({} as LicenseAcquisition)
          : licenseAcquisitionAPI.updateWithoutValidation1({} as LicenseAcquisition);
        break;

      default:
        throw new Error("unmapped type " + activityLicense.proceedingType);
    }

    return axiosPromise!;
  };

  const handleDiscardModalOpenChange = (isOpen: boolean) => {
    setDiscardModalIsOpen(isOpen);
    setShouldShowNavigationPrompt(!isOpen);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalIsOpen(!confirmationModalIsOpen);
  };

  const handleSave = () => {
    handleNavigation(currentStep, false, true);
  };

  const getModalMessage = () => {
    return (
      <FormattedMessage
        id="activityLicenseSaveConfirmationModal.bodyOfficial"
        defaultMessage="Taotluse seis salvestatakse."
      />
    );
  };

  const renderBackwardButton = () => {
    if (currentStep === ActivityLicenseStep.BUSINESS_DATA) {
      return (
        <SecondaryFormattedButton
          id="discard"
          onClick={() => handleDiscardModalOpenChange(true)}
        />
      );
    }
    return (
      <SecondaryFormattedButton
        id="back"
        onClick={() => (isService ? serviceNavigation(-1) : navigate(-1))}
      />
    );
  };

  return (
    <>
      <Row className="ml-0 mr-0 application-footer-buttons">
        {renderBackwardButton()}
        {!isService && (
          <LinkButton
            id="saveDraft"
            onClick={() => setConfirmationModalIsOpen(true)}
          />
        )}
        <PrimaryFormattedButton
          className={`ml-4 ${isService ? "service" : ""}`}
          id="forward"
          disabled={!(activityLicense as Record<string, any>).saveAllowed}
          onClick={() => (isService ? serviceNavigation(1) : navigate(1))}
          {...(currentStep === steps.length - 1 && {
            id: "sendApplication"
          })}
        />
        <ApplicationDiscardConfirmationModal
          isOpen={discardModalIsOpen}
          onClose={() => handleDiscardModalOpenChange(!discardModalIsOpen)}
          onDiscard={() => history.push("/")}
        />
        <ApplicationSaveConfirmationModal
          isOpen={confirmationModalIsOpen}
          onClose={toggleConfirmationModal}
          onSave={handleSave}
          modalMessage={getModalMessage()}
        />
        <NavigationPrompt when={shouldShowNavigationPrompt}>
          {({ onConfirm, onCancel }) => (
            <ApplicationDiscardConfirmationModal
              isOpen
              onClose={onCancel}
              onDiscard={onConfirm}
            />
          )}
        </NavigationPrompt>
      </Row>
    </>
  );
};
