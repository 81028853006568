import { GeneralPractitionerListState } from "../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { RootAction } from "../rootReducer";
import {
  SET_GP_LIST,
  CLEAR_GP_LIST,
  SET_SERVICE_AREA,
  SET_ALLOWED_SERVICE_AREAS,
  SET_GENERAL_PRACTITIONER,
  SET_GP_LIST_DELETIONS,
  SET_LICENSES,
  CLEAR_GP_LIST_DELETIONS,
  SET_GP_LIST_MODIFICATIONS,
} from "./generalPractitionerListActions";
import { Reducer } from "redux";

const initialDeletionsState = {
  employees: [],
  appointments: [],
  consultations: [],
  substituteDoctors: [],
  substituteDoctorPeriods: [],
};

const initialState: GeneralPractitionerListState = {
  list: undefined,
  licenses: undefined,
  serviceArea: undefined,
  allowedServiceAreas: undefined,
  generalPractitioner: undefined,
  deletions: initialDeletionsState,
  gplModifications: undefined
};

export const generalPractitionerListReducer: Reducer<GeneralPractitionerListState> = (
  state = initialState,
  action: RootAction
) => {
  switch (action.type) {
    case SET_GP_LIST:
      return { ...state, list: action.payload };
    case CLEAR_GP_LIST:
      return initialState;
    case SET_LICENSES:
      return { ...state, licenses: action.payload };
    case SET_SERVICE_AREA:
      return { ...state, serviceArea: action.payload };
    case SET_ALLOWED_SERVICE_AREAS:
      return { ...state, allowedServiceAreas: action.payload };
    case SET_GENERAL_PRACTITIONER:
      return { ...state, generalPractitioner: action.payload };
    case SET_GP_LIST_DELETIONS:
      const candidateKeys = Object.keys(action.payload) as Array<
        keyof typeof action.payload
      >;
      return {
        ...state,
        deletions: candidateKeys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: action.payload[key] ?? [],
          }),
          state.deletions
        ),
      };
    case CLEAR_GP_LIST_DELETIONS:
      return { ...state, deletions: initialDeletionsState };
    case SET_GP_LIST_MODIFICATIONS:
      return { ...state, gplModifications: action.payload };
    default:
      return state;
  }
};
