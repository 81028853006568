import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AttachmentContainer } from "./AttachmentContainer";
import { displayAlert } from "../../../../../../Util/AlertUtil";
import { AlertType } from "../../../../../../Dto/Alert/AlertItem";
import { areFileNamesUnique } from "../../../../../../Util/FileUtils";
import {
  FileObjectType,
  FileReference
} from "../../../../../../../api_client/medre_api";

interface Props {
  updateAreFilesValid: (isValid: boolean) => void;
  objectId: string;
  fileReferences: () => FileReference[];
  refreshFlag: boolean;
  saveFiles: (files: File[], fileType: FileObjectType) => void;
  handleFileDelete: (fileName: string, fileType: FileObjectType) => void;
}

export const PersonAttachmentFields = ({
                                   updateAreFilesValid,
                                   fileReferences,
                                   saveFiles,
                                   handleFileDelete,
                                   refreshFlag
                                 }: Props) => {
  const [activeAttachmentType, setActiveAttachmentType] =
    useState<FileObjectType | null>(null);
  const [isPersonDiplomaPresent, setIsPersonDiplomaPresent] =
    useState(false);
  const [savedFileReferences, setSavedFileReferences] =
    useState<FileReference[]>([]);
  const [shouldRefresh, setShouldRefresh] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    updateAreFilesValid(isPersonDiplomaPresent);
  }, [updateAreFilesValid, isPersonDiplomaPresent]);

  useEffect(() => {
    setSavedFileReferences(fileReferences());
  }, [refreshFlag]);

  const validateAndSave = (files: File[], objectType: FileObjectType) => {
    if (fileReferences() && !areFileNamesUnique(files, fileReferences())) {
      displayAlert("fileNameNotUnique", AlertType.Danger, dispatch);
      return;
    }
    saveFiles(files, objectType);
    setShouldRefresh(!shouldRefresh);
  };

  const filterAndDelete = (fileName: string, fileType: FileObjectType) => {
    handleFileDelete(fileName, fileType);
    const filteredFileReferences = savedFileReferences.filter(
      (file) => file.fileName !== fileName && file.fileObjectType !== fileType
    );
    setSavedFileReferences(filteredFileReferences);
  }


  return (
    <div className="application-fields">
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonDiploma)
        }
        isActive={activeAttachmentType === FileObjectType.PersonDiploma}
        type={FileObjectType.PersonDiploma}
        fileReferences={savedFileReferences.filter(
          (fileReference) => fileReference.fileObjectType === FileObjectType.PersonDiploma
        )}
        updateAreFilesValid={setIsPersonDiplomaPresent}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.PersonDiploma)
        }
        handleFileDelete={(fileName) =>
          filterAndDelete(fileName, FileObjectType.PersonDiploma)
        }
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonAcademicReport)
        }
        isActive={
          activeAttachmentType === FileObjectType.PersonAcademicReport
        }
        type={FileObjectType.PersonAcademicReport}
        fileReferences={savedFileReferences.filter(
          (fileReference) => fileReference.fileObjectType === FileObjectType.PersonAcademicReport
        )}
        maxFilesCount={50}
        saveFiles={(files) =>{
          validateAndSave(files, FileObjectType.PersonAcademicReport)
          fileReferences()
        }

        }
        handleFileDelete={(fileName) =>
          filterAndDelete(fileName, FileObjectType.PersonAcademicReport)
        }
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonAdditionalDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.PersonAdditionalDocument
        }
        type={FileObjectType.PersonAdditionalDocument}
        fileReferences={savedFileReferences.filter(
          (fileReference) => fileReference.fileObjectType === FileObjectType.PersonAdditionalDocument
        )}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.PersonAdditionalDocument)
        }
        handleFileDelete={(fileName) =>
          filterAndDelete(
            fileName,
            FileObjectType.PersonAdditionalDocument
          )
        }
      />
      <AttachmentContainer
        onClick={() =>
          setActiveAttachmentType(FileObjectType.PersonNameChangeDocument)
        }
        isActive={
          activeAttachmentType === FileObjectType.PersonNameChangeDocument
        }
        type={FileObjectType.PersonNameChangeDocument}
        fileReferences={savedFileReferences.filter(
          (fileReference) => fileReference.fileObjectType === FileObjectType.PersonNameChangeDocument
        )}
        maxFilesCount={50}
        saveFiles={(files) =>
          validateAndSave(files, FileObjectType.PersonNameChangeDocument)
        }
        handleFileDelete={(fileName) =>
          filterAndDelete(
            fileName,
            FileObjectType.PersonNameChangeDocument
          )
        }
      />
    </div>
  );
};
