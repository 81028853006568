import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const CancelDeleteModificationGPModal = ({ isOpen, onClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader tag="h4">
        <FormattedMessage
          id="cancelDeleteModificationGPModal.header"
          defaultMessage="Kas olete kindel, et soovite perearstile planeeritud töötamise lõpetamise eemaldada?"
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="cancelDeleteModificationGPModal.description"
          defaultMessage={ `Teostatava muudatusega tehakse järgnevad toimingud:{lineBreak}1. Eemaldatakse kehtiva perearstile planeeritud töötamise lõpetamine{lineBreak}2. Eemaldatakse tulevikus tööle hakkava perearsti töötamine (selle olemasolul)` }
          values={{lineBreak: <br />}}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={ onClose }
        />
        <PrimaryFormattedButton
          id="confirm"
          onClick={ onConfirm }
        />
      </ModalFooter>
    </Modal>
  )
}