import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {displayAlert} from "../../../Util/AlertUtil";
import {AlertType} from "../../../Dto/Alert/AlertItem";
import {AdminTeenusApiFactory as adminTeenusApi} from "../../../../api_client/medre_api";
import {API, getBaseUrl} from "../../../api";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {PrimaryFormattedButton} from "../../../Component/Button/PrimaryFormattedButton";
import {Loader} from "../../../Component/Loader/Loader";

export const Torta2ManualEmploymentInfoProcessForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<string>();
  const [responseMessage, setResponseMessage] = useState<string>();

  const applyQueryResults = () => {
    setIsLoading(true);
    adminTeenusApi(undefined, getBaseUrl(), API)
      .performManualTorta2EmploymentInfoProcessing({withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        setResponseCode(res.data.responseCode);
        setResponseMessage(res.data.responseMessage);
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  };

  return (
    <>
      <h4>
        Käsitsi andmebaasi sisestatud torta töötamiste info töötlemine
      </h4>
      <Form className="system-administration-form">
        <Row>
          <Col md={7} lg={6} xl={5} className="ml-5">
            <FormGroup>
              <div className="form-inline justify-content-end">
                <PrimaryFormattedButton id="runQuery" onClick={() => applyQueryResults()}/>
              </div>
              {responseCode ? <div>Response code: <b>{responseCode}</b></div> : null}
              {responseMessage ? <div>Response message: {responseMessage}</div> : null}
            </FormGroup>
          </Col>
          <Col>
            {isLoading ? <Loader/> : null}
          </Col>
        </Row>
      </Form>
    </>
  );
};