import React from "react";
import { useParams } from "react-router-dom";

import { AmetnikuTegevuslubadeTeenusteTeenusApiFactory as officialActivityPermitAPI } from "../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../api";
import { RestorationDetails } from "./RestorationDetails";

export const ActivityLicenseServiceRestorationDetails = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <RestorationDetails
      endpoint={
        officialActivityPermitAPI(undefined, getBaseUrl(), API)
          .getActivityLicenseRestoration1
      }
      id={id}
    />
  );
};
