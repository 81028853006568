import React from "react";
import { Card } from "reactstrap";

import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";
import { ActivityLocation } from "../../../../../api_client/medre_api";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";

interface Props {
  activityLocations: ActivityLocation[] | undefined;
}

export const ActivityLocationsCard = ({ activityLocations }: Props) => (
  <>
    <h4 className="application-title fw-normal">
      <ActivityLicenseFM id={"activityLocations"} />
    </h4>
    <Card className="info-card mb-2">
      {activityLocations?.map((location) =>
        renderOverViewRow(
          "activityLicense",
          "activityLocation",
          location.fullAddress
        )
      )}
    </Card>
  </>
);
