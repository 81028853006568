import { LoginInfo } from "../Dto/Security/UserInfo";
import { OfficialUserPrivilegesEnum } from "../../api_client/medre_api";
import { action } from "typesafe-actions";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_TOKEN_EXPIRY_TIME = "UPDATE_TOKEN_EXPIRY_TIME";
export const UPDATE_USER_ROLES = "UPDATE_USER_ROLES";
export const INCREMENT_AUTHENTICATION_FAILURE =
  "INCREMENT_AUTHENTICATION_FAILURE";
export const INCREMENT_AUTHORIZATION_FAILURE =
  "INCREMENT_AUTHORIZATION_FAILURE";

export const userActions = {
  login: (config: LoginInfo) => action(LOG_IN, config),
  logout: () => action(LOG_OUT),
  updateUserRoles: (roles: (OfficialUserPrivilegesEnum | string)[]) =>
    action(UPDATE_USER_ROLES, roles),
  updateTokenExpiryTime: (tokenExpiresAt: Date | undefined) =>
    action(UPDATE_TOKEN_EXPIRY_TIME, tokenExpiresAt),
  incrementAuthenticationFailure: () =>
    action(INCREMENT_AUTHENTICATION_FAILURE, {}),
  incrementAuthorizationFailure: () =>
    action(INCREMENT_AUTHORIZATION_FAILURE, {})
};
