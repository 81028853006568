import { useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import { useMemo } from "react";
import { createCountryOption } from "../Util/OptionUtil";
import { ServiceArea, Country, Parish } from "../../api_client/medre_api";

export const useCountryOptions = () => {
  const locale = useSelector((state: RootState) => state.locale);
  const countries = useSelector<RootState, Country[]>(
    (state) => state.address.countries
  );
  return useMemo(
    () => countries.map((country) => createCountryOption(country.code)),
    // eslint-disable-next-line
    [locale, countries]
  );
};

export const useParishOptions = () => {
  const parishes = useSelector<RootState, Parish[]>(
    (state) => state.address.parishes
  );
  return useMemo(
    () =>
      parishes.map((parish) => ({
        value: parish.id!,
        label: parish.name!
      })),
    [parishes]
  );
};

export const useParishLabelsByServiceAreaIds = (
  serviceAreaIds?: ServiceArea["ids"]
) => {
  const parishOptions = useParishOptions();

  return useMemo(() => {
    if (!serviceAreaIds || parishOptions.length === 0) {
      return "";
    }

    return serviceAreaIds
      .map((id) => parishOptions.find(({ value }) => value === id)?.label)
      .join(", ");
  }, [parishOptions, serviceAreaIds]);
};
