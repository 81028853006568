import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { DetailedApplicationTypeEnum } from "../../../../api_client/medre_api";
import { ApplicationTypeSelection } from "../../Shared/Application/ApplicationTypeSelection";

export const OfficialApplicationTypeSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNewOccupationApplication = () => {
    dispatch(
      applicationDraftActions.updateApplicationDraft(
        "type",
        DetailedApplicationTypeEnum.Occupation
      )
    );
    dispatch(applicationDraftActions.updateApplicationDraft("currentStep", 0));
    history.push("/new-application/occupation");
  };

  const handleNewSpecialityApplication = () => {
    dispatch(
      applicationDraftActions.updateApplicationDraft(
        "type",
        DetailedApplicationTypeEnum.Specialization
      )
    );
    dispatch(applicationDraftActions.updateApplicationDraft("currentStep", 0));
    history.push("/new-application/speciality");
  };

  const handleNewActivityLicense = () => {
    history.push("/new-activity-license");
  };

  const pageTitle = {
    id: "proceedingTypeSelection.newOfficialProceedingTitle",
    defaultMessage: "Taotluse sisestamine"
  };

  const occupationDescription = {
    id: "proceedingTypeSelection.officialOccupationParagraph",
    defaultMessage:
      "Taotlus, et registreerida tulevast arsti, farmatseuti, hambaarsti, proviisorit, õde või ämmaemandat."
  };

  const specialityDescription = {
    id: "proceedingTypeSelection.officialSpecialityParagraph",
    defaultMessage:
      "Taotlus, et registeerida tulevast eriala hambaarsti, arsti või õe kutsele."
  };

  const activityLicenseDescription = {
    id: "proceedingTypeSelection.officialActivityLicenseParagraph",
    defaultMessage: "Taotlus, et registeerida ettevõtte tegevusluba."
  };

  return (
    <ApplicationTypeSelection
      pageTitle={pageTitle}
      specialityDescription={specialityDescription}
      occupationDescription={occupationDescription}
      activityLicenseDescription={activityLicenseDescription}
      handleNewSpecialityApplication={handleNewSpecialityApplication}
      handleNewOccupationApplication={handleNewOccupationApplication}
      handleNewActivityLicense={handleNewActivityLicense}
    />
  );
};
