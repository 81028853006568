import { AlertItem } from "../Dto/Alert/AlertItem";
import React from "react";
import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "./alertActions";
import { RootState } from "../rootReducer";
import { getAlerts } from "./alertReducer";
import "./AlertContainer.scss";
import { FormattedMessage } from "react-intl";

export const AlertContainer = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state: RootState) => getAlerts(state));

  const renderAlertItem = (alert: AlertItem) => {
    setTimeout(
      () => dispatch(alertActions.removeAlert(alert)),
      1000 * 5);
    return (
      <Alert
        className="alert-background"
        key={alert.id}
        color={alert.type}
        toggle={() => {
          dispatch(alertActions.removeAlert(alert));
        }}
      >
        <h5 className="alert-heading fw-normal">
          <FormattedMessage id={"alertContainer.notice"} defaultMessage={"Teade"}/>
        </h5>
        <p className="alert-content">{alert.message}</p>
      </Alert>
    );
  };

  return (
    <div className="alert-container">
      {alerts.map(alert => renderAlertItem(alert))}
    </div>
  );
};
