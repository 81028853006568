import React, { useState } from "react";
import DetailsSection from "../../../../../Component/DetailsSection/DetailsSection";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../../../../Shared/GeneralPractitioners/GeneralPractitionersDetailedNavigationList";
import GpListHistoryTabs from "./GPListHistoryTabs";
import GpListHistoryTabContents from "./GPListHistoryTabContents";
import { GpListHistoryTypes } from "./GpListHistoryTypes";

export const GPListHistory = () => {
  const [activeTab, setActiveTab] = useState(
    GpListHistoryTypes.ChangeFamilyDoctor
  );

  return (
    <DetailsSection {...GENERAL_PRACTITIONERS_DETAILS_SECTIONS.HISTORY}>
      <GpListHistoryTabs onChange={setActiveTab} activeTab={activeTab} />
      <GpListHistoryTabContents activeTab={activeTab} />
    </DetailsSection>
  );
};
