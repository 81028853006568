import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { createServiceName } from "../../../../Dto/ActivityLicense/Service";
import { Service } from "../../../../../api_client/medre_api";
import "./ServiceDeletionConfirmationModal.scss";

interface Props {
  isOpen: boolean;
  service: Service;
  onClose: () => void;
  onDelete: () => void;
}

export const ServiceDeletionConfirmationModal = ({
  isOpen,
  service,
  onClose,
  onDelete
}: Props) => (
  <Modal isOpen={isOpen} id="service-deletion-confirmation-modal">
    <ModalBody>
      <h3>
        <FormattedMessage
          id="serviceDeletionConfirmationModal.body"
          defaultMessage="Oled sa kindel, et soovid teenust “{name}” kustutada?"
          values={{ name: createServiceName(service, true) }}
        />
      </h3>
    </ModalBody>
    <ModalFooter className="justify-content-center">
      <SecondaryFormattedButton id="backToApplication" onClick={onClose} />
      <PrimaryFormattedButton id="deleteService" onClick={onDelete} />
    </ModalFooter>
  </Modal>
);
