import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import React, { useEffect, useState } from "react";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";
import { FormattedMessage } from "react-intl";
import { DocumentsCard } from "../Overview/DocumentsCard";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { SubmittingPersonCard } from "../Overview/SubmittingPersonCard";
import { BusinessRegistryInfoCard } from "../BusinessData/BusinessRegistryInfoCard";
import { ContactInfoCard } from "../Overview/ContactInfoCard";
import { ActivityLicenseCard } from "../Overview/ActivityLicenseCard";

export const LicenseAcquisitionOverview = () => {
  const dispatch = useDispatch();
  const application = useSelector((state: RootState) => state.licenseAcquisitionApplication);
  const [isIntegrationConfirmed, setIsIntegrationConfirmed] = useState(false);
  const {company} = application;

  useEffect(() => {
    dispatch(licenseAcquisitionActions.updateApplicationDraft("saveAllowed", isIntegrationConfirmed));
  }, [isIntegrationConfirmed, dispatch]);

  return (
    <>
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.overview"
          defaultMessage="Esita taotlus"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicense.overviewInfo"
          defaultMessage={`Kontrolli taotluses edastav info üle ja seejärel klõpsa nuppu “Esita taotlus”.
           Nupu klõpsamise järel saate juhendi riigilõivu maksmiseks.`}
        />
      </p>
      <div className="main-column">
        <h4 className="application-title fw-normal">
          <FormattedMessage
            id="activityLicense.businessRegisterData"
            defaultMessage="Äriregistri andmed"
          />
        </h4>
        <BusinessRegistryInfoCard
          name={company?.name}
          address={company?.address}
          registryCode={company?.registryCode}
        />
        <h4 className="application-title fw-normal">
          <FormattedMessage
            id="activityLicense.submittingPerson"
            defaultMessage="Esitav isik"
          />
        </h4>
        <SubmittingPersonCard
          personName={application.submittingPersonFirstName + " " + application.submittingPersonLastName}
          personIdCode={application.submittingPersonIdCode}
        />
        <ContactInfoCard
          email={company?.email}
          phone={company?.phone}
        />
        <ActivityLicenseCard
          licenseNumber={application.license?.number}
        />
        <DocumentsCard
          documents={application.fileReferences || []}
        />
        <Checkbox
          id="integration-with-system-exists"
          isChecked={isIntegrationConfirmed}
          labelText="Kinnitan, et liidestus Tervise Infosüsteemiga on olemas"
          onChange={() => setIsIntegrationConfirmed(!isIntegrationConfirmed)}
        />
      </div>
    </>

  );
};