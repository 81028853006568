import React, { useState } from "react";

import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { OnSiteInspectionModal } from "./OnSiteInspectionModal";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PauseProceedingModal } from "./PauseProceedingModal";
import {
  ActivityLicenseApplicationStatus, OfficialUser,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import {
  ApplicationDeleteConfirmationModal
} from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";
import { OfficialForwardingModal } from "./OfficialForwardingModal";
import { AcceptanceActions } from "../Acceptance/AcceptanceActions";

interface Props {
  applicationId?: string;
  applicationNumber: number | undefined;
  canAcceptOrDecline?: boolean;
  canPause?: boolean;
  changeStatusTo: (newStatus: ActivityLicenseApplicationStatus) => void;
  deleteApplication?: (applicationId: string) => void;
  handleForwardToOfficial: (official: OfficialUser) => void;
  newAcceptanceStatus?: ActivityLicenseApplicationStatus;
  newDeclinationStatus?: ActivityLicenseApplicationStatus;
  newStatus: ActivityLicenseApplicationStatus;
  pauseProceeding?: (notes: string) => Promise<void>;
}

export const InProceedingActions = ({
  applicationId,
  applicationNumber,
  canAcceptOrDecline,
  canPause = true,
  changeStatusTo,
  deleteApplication,
  handleForwardToOfficial,
  newAcceptanceStatus,
  newDeclinationStatus,
  newStatus,
  pauseProceeding
}: Props) => {
  const [isPauseProceedingModalOpen, setIsPauseProceedingModalOpen] =
    useState(false);
  const [isOnSiteInspectionModalOpen, setIsOnSiteInspectionModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);

  const handleAppDelete = () => {
    deleteApplication!(applicationId!);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="onSiteInspection"
          className={ActionButtonClasses}
          onClick={() => setIsOnSiteInspectionModalOpen(true)}
        />
        <OnSiteInspectionModal
          applicationNumber={applicationNumber}
          isOpen={isOnSiteInspectionModalOpen}
          onClose={() => setIsOnSiteInspectionModalOpen(false)}
          onClick={() => changeStatusTo(newStatus)}
        />
        {canPause && pauseProceeding && (
          <>
            <SecondaryFormattedButton
              id="pauseProceeding"
              className={ActionButtonClasses}
              onClick={() => setIsPauseProceedingModalOpen(true)}
            />
            <PauseProceedingModal
              isOpen={isPauseProceedingModalOpen}
              onClose={() => setIsPauseProceedingModalOpen(false)}
              onSubmit={(notes) => {
                setIsPauseProceedingModalOpen(false);
                return pauseProceeding(notes);
              }}
            />
          </>
        )}
      </ProtectedFieldWrapper>
      {canAcceptOrDecline && (
        <AcceptanceActions
          applicationNumber={applicationNumber}
          changeStatusTo={changeStatusTo}
          newAcceptanceStatus={newAcceptanceStatus!}
          newDeclinationStatus={newDeclinationStatus!}
          applicationId={applicationId}
        />
      )}
      {deleteApplication && applicationId && (
        <ProtectedComponent allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}>
          <DangerFormattedButton
            id="deleteApplication"
            className={ActionButtonClasses}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </ProtectedComponent>
      )}
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}>
        <SecondaryFormattedButton
          id={"forwardToOfficial"}
          className={ActionButtonClasses}
          onClick={() => setIsOfficialForwardingModalOpen(true)}
        />
      </ProtectedComponent>
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </>
  );
};
