import React from "react";
import { Card } from "reactstrap";

import { EducationFM } from "../../../../Messages/EducationFM";
import { formatDate, renderOverViewRow } from "./ApplicationOverViewUtil";
import { renderFiles } from "../../../Portal/Application/Table/Rows/ApplicationTableRowUtil";
import {
  FileObjectType,
  FileReference,
  EducationInfo
} from "../../../../../api_client/medre_api";
import { getFormattedCountryMessage } from "../../../../Component/Translate/CountryTranslator";

interface Props {
  educationInfo?: EducationInfo;
  files?: FileReference[];
}

export const EducationOverViewCard = ({ educationInfo, files }: Props) => {
  const diplomas = files?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingDiploma
  );
  const academicReports = files?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingAcademicReport
  );
  const additionalDocuments = files?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingAdditionalDocument
  );
  const nameChangeDocuments = files?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingNameChangeDocument
  );

  const renderFileRows = () => (
    <>
      {!!diplomas?.length &&
        renderOverViewRow("files", "diploma", renderFiles(diplomas))}
      {!!academicReports?.length &&
        renderOverViewRow(
          "files",
          "academicReport",
          renderFiles(academicReports)
        )}
      {!!additionalDocuments?.length &&
        renderOverViewRow(
          "files",
          "additionalDocument",
          renderFiles(additionalDocuments)
        )}
      {!!nameChangeDocuments?.length &&
        renderOverViewRow(
          "files",
          "nameChangeDocument",
          renderFiles(nameChangeDocuments)
        )}
    </>
  );

  return (
    <>
      <h5 className="fw-normal">
        <EducationFM id="educationInfo" />
      </h5>
      <Card className="info-card">
        {renderOverViewRow(
          "education",
          "schoolName",
          educationInfo?.schoolName
        )}
        {renderOverViewRow(
          "education",
          "country",
          getFormattedCountryMessage(educationInfo?.countryCode)
        )}
        {renderOverViewRow(
          "education",
          "curriculumName",
          educationInfo?.curriculumName
        )}
        {renderOverViewRow(
          "education",
          "diplomaDate",
          formatDate(educationInfo?.diplomaDate)
        )}
        {renderOverViewRow(
          "education",
          "diplomaNumber",
          educationInfo?.diplomaNumber
        )}
        {renderFileRows()}
      </Card>
    </>
  );
};
