import React from "react";
import { useSelector } from "react-redux";

import public_logo from "../../../assets/images/Sotsiaalkindlustusamet-avalik.svg";
import portal_logo from "../../../assets/images/Sotsiaalkindlustusamet-iseteenindus.svg";
import public_logo_white from "../../../assets/images/Terviseamet-avalik-white.svg";
import portal_logo_white from "../../../assets/images/Terviseamet-iseteenindus-white.svg";
import { RootState } from "../../rootReducer";
import "./Header.scss";

interface Props {
  isWhite?: boolean;
  isSideMenuCollapsed: boolean;
  isOfficialLogin?: boolean;
}

export const Logo = ({
  isSideMenuCollapsed,
  isWhite,
  isOfficialLogin
}: Props) => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const expandedLogoName = isSideMenuCollapsed ? "" : "logo-expanded";
  const logoClassName = isOfficialLogin
    ? "logo official-logo"
    : "logo " + expandedLogoName;

  const usePortalLogo = () => !!userInfo && isPortal;

  if (usePortalLogo()) {
    return isWhite ? (
      <img
        className={logoClassName}
        src={portal_logo_white}
        alt="Terviseamet-Registrid-Iseteenindus"
      />
    ) : (
      <img
        className={logoClassName}
        src={portal_logo}
        alt="Terviseamet-Registrid-Iseteenindus"
      />
    );
  } else {
    return isWhite ? (
      <img
        className={logoClassName}
        src={public_logo_white}
        alt="Terviseamet-Registrid"
      />
    ) : (
      <img
        className={logoClassName}
        src={public_logo}
        alt="Terviseamet-Registrid"
      />
    );
  }
};
