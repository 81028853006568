import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  applicationNumber?: number;
}

export const OnSiteInspectionModal = ({
  isOpen,
  onClose,
  onClick,
  applicationNumber
}: Props) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>
      <FormattedMessage
        id="activityLicenseApplicationOnSiteInspectionModal.header"
        defaultMessage="Kas oled kindel, et soovid taotluse {id} paikvaatlusele suunata?"
        values={{ id: applicationNumber }}
      />
    </ModalHeader>
    <ModalFooter>
      <SecondaryFormattedButton id="cancel" onClick={onClose} />
      <PrimaryFormattedButton id="onSiteInspectionConfirm" onClick={onClick} />
    </ModalFooter>
  </Modal>
);
