import React from "react";
import { useSelector } from "react-redux";

import { OfficialUserPrivilegesEnum } from "../../api_client/medre_api";
import { RootState } from "../rootReducer";

// tslint:disable-next-line:no-any
interface Props extends React.ComponentProps<any> {
  allowedRoles: OfficialUserPrivilegesEnum[];
  allowedUserId?: string;
}

export const ProtectedFieldWrapper = ({
  allowedRoles,
  allowedUserId,
  children
}: Props) => {
  const allowed = useSelector((state: RootState) => {
    let isRoleAllowed = allowedRoles?.some((role: OfficialUserPrivilegesEnum) =>
      state.user.userInfo?.roles?.includes(role)
    );
    if (allowedUserId) {
      return isRoleAllowed && allowedUserId === state.user.userInfo?.userId;
    }
    return isRoleAllowed;
  });

  return !children || !allowed ? null : children;
};
