import React, { useCallback, useEffect, useState, ReactElement } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import InputMask from "react-input-mask";

import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";
import {
  isInsertedEmailValid,
  validateOptionalFieldLength
} from "../../Validation/fieldValidator";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { getQueryBoxType, QueryBox } from "./QueryBox/QueryBox";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { PhoneCodeSelector } from "./PhoneCodeSelector";
import { AdsAddressInput } from "./Address/AdsAddressInput";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import {
  Address,
  ApplicationCertificateDeliveryTypeEnum,
  ContactInfo
} from "../../../../../api_client/medre_api";
import {
  RadioButtonElement,
  RadioGroup
} from "../../../../Component/Radio/RadioGroup";
import { usePortal } from "../../../../Hook/usePortal";
import { getQueryBoxHeaderContent, getQueryBoxTextContent } from "./QueryBox/QueryBoxContent";

export enum ContactInfoFieldType {
  email = "email",
  phone = "phone",
  address = "address"
}

interface Props {
  formInfo: ContactInfoForm;
  updateContactInfo: (fieldName: keyof ContactInfoForm, value?: string) => void;
  setIsFormValid: (value: boolean) => void;
  isForceValidation?: boolean;
  hasEstonianPersonalCode: boolean;
  addressSwitch?: AddressSwitch | boolean;
  personIdCode?: string;
  contactInfoFromRR?: ContactInfo;
}

export type AddressSwitch = {
  title: JSX.Element;
  name: string;
  defaultValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showAddressOption: RadioButtonElement;
  hideSecondAddressOption: RadioButtonElement;
  hideThirdAddressOption: RadioButtonElement;
  component?: ReactElement;
};

export const ContactInfoEditFormFields = ({
  hasEstonianPersonalCode,
  addressSwitch,
  personIdCode,
  setIsFormValid,
  isForceValidation,
  updateContactInfo,
  formInfo,
  contactInfoFromRR
}: Props) => {
  const isPortal = usePortal();

  const { email, dialCode, phone, fullAddress } = formInfo;

  const [isAddressVisible, setAddressVisible] = useState(
    typeof addressSwitch !== "boolean"
      ? addressSwitch?.showAddressOption?.value === addressSwitch?.defaultValue
      : true
  );
  const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(
    isInsertedEmailValid(email) || undefined
  );
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>();

  useEffect(() => {
    setIsFormValid(Boolean(isEmailValid && isPhoneValid !== false));
  }, [isEmailValid, isPhoneValid, setIsFormValid]);

  useEffect(() => {
    if (isForceValidation || email !== undefined) {
      setIsEmailValid(isInsertedEmailValid(email));
    }
  }, [isForceValidation, email]);

  useEffect(() => {
    setIsPhoneValid(validateOptionalFieldLength(phone, { min: 6, max: 15 }));
  }, [phone]);

  const handleToggleAddressVisible = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof addressSwitch !== "boolean") {
        const isVisible =
          event.target.value === addressSwitch?.showAddressOption?.value;
        setAddressVisible(isVisible);
        addressSwitch?.onChange(event);
      }
    },
    [addressSwitch, setAddressVisible]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      let value = e.target.value;
      let fieldName = e.target.name;
      updateContactInfo(fieldName as keyof ContactInfoForm, value);
    }
  };

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const updateField = (fieldName: keyof ContactInfoForm, value: string) => {
    updateContactInfo(fieldName, value);
  };

  const updateAddressFromRR = (address: Address) => {
    updateContactInfo("fullAddress", address.fullAddress);
    updateContactInfo("countryCode", address.country?.code);
    updateContactInfo("countyName", address.county?.name);
    updateContactInfo("ehakmk", address.county?.ehakmk);
    updateContactInfo("parishName", address.parish?.name);
    updateContactInfo("ehakov", address.parish?.ehakov);
    updateContactInfo("settlementName", address.settlement?.name);
    updateContactInfo("ehak", address.settlement?.ehak);
    updateContactInfo("adsAdrID", address.adsAdrID);
    updateContactInfo("adsOid", address.adsOid);
    updateContactInfo("zipCode", address.zipCode);
  };

  function renderQueryBox(type: ContactInfoFieldType) {
    if (!hasEstonianPersonalCode || !contactInfoFromRR) {
      return;
    }
    switch (type) {
      case ContactInfoFieldType.email:
        return (
          <QueryBox
            type={getQueryBoxType(email, contactInfoFromRR.email)}
            value={contactInfoFromRR?.email}
            useValue={() => updateField("email", contactInfoFromRR.email)}
            headerContent={getQueryBoxHeaderContent(contactInfoFromRR?.email)}
            textContent={getQueryBoxTextContent(contactInfoFromRR?.email)}
          />
        );
      case ContactInfoFieldType.phone:
        return (
          <QueryBox
            type={getQueryBoxType(phone, contactInfoFromRR.phone)}
            value={contactInfoFromRR?.phone}
            useValue={() => updateField("phone", contactInfoFromRR.phone)}
            headerContent={getQueryBoxHeaderContent(contactInfoFromRR?.phone)}
            textContent={getQueryBoxTextContent(contactInfoFromRR?.phone)}
          />
        );
      case ContactInfoFieldType.address:
        return (
          <QueryBox
            type={getQueryBoxType(fullAddress, contactInfoFromRR?.address?.fullAddress!)}
            value={contactInfoFromRR?.address?.fullAddress}
            useValue={() => updateAddressFromRR(contactInfoFromRR?.address!)}
            headerContent={getQueryBoxHeaderContent(contactInfoFromRR?.address?.fullAddress)}
            textContent={getQueryBoxTextContent(contactInfoFromRR?.address?.fullAddress)}
          />
        );
      default:
        return;
    }
  }

  return (
    <div className="main-column ml-3 mr-3">
      <FormGroup
        className={
          "required w-100 flex-nowrap " + (isMobile ? "" : " form-inline")
        }
      >
        <Label
          htmlFor="contactInfoEditFormFieldsEmail"
          className="control-label"
        >
          <ContactInfoFM id="email" />
        </Label>
        <div className="input-wrapper">
          <Input
            id="contactInfoEditFormFieldsemail"
            type="email"
            name="email"
            value={email || ""}
            valid={isEmailValid}
            invalid={isEmailValid !== undefined && !isEmailValid}
            onChange={(e) => handleChange(inputRegex(e))}
            className="input"
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </FormGroup>
      {renderQueryBox(ContactInfoFieldType.email)}
      <FormGroup
        className={"w-100 flex-nowrap " + (isMobile ? "" : "form-inline")}
      >
        <Label htmlFor="contactInfoEditFormFieldsPhone">
          <ContactInfoFM id="phone" />
        </Label>
        <div className="d-flex phone-container">
          <PhoneCodeSelector
            dialCode={dialCode}
            updateDialCode={(newDialCode) =>
              updateField("dialCode", newDialCode)
            }
          />
          <div className="input-wrapper">
            <InputMask
              id="contactInfoEditFormFieldsPhone"
              type="tel"
              name="phone"
              value={phone || ""}
              mask={"9".repeat(15)}
              maskPlaceholder=""
              onChange={handleChange}
            >
              <Input
                valid={isPhoneValid}
                invalid={isPhoneValid !== undefined && !isPhoneValid}
              />
            </InputMask>
            <FormFeedback>
              <FormattedErrorMessage id="phone" />
            </FormFeedback>
          </div>
        </div>
      </FormGroup>
      {renderQueryBox(ContactInfoFieldType.phone)}
      {addressSwitch && typeof addressSwitch !== "boolean" && (
        <>
          <h4>{addressSwitch.title}</h4>
          <FormGroup className="w-100 d-flex flex-column flex-md-row mb-0">
            <span className="label" />
            <RadioGroup
              name="addressSwitch"
              className="input-wrapper"
              options={[
                addressSwitch.showAddressOption,
                addressSwitch.hideSecondAddressOption,
                addressSwitch.hideThirdAddressOption
              ]}
              value={addressSwitch.defaultValue}
              onChange={handleToggleAddressVisible}
            />
          </FormGroup>
          {addressSwitch.defaultValue ===
            ApplicationCertificateDeliveryTypeEnum.Post &&
            addressSwitch.component}
        </>
      )}
      {!isPortal &&
        isAddressVisible &&
        contactInfoFromRR?.address?.fullAddress && (
          <AdsAddressInput
            fullAddress={contactInfoFromRR?.address?.fullAddress}
            adsAdrID={formInfo.adsAdrID}
            zipCode={contactInfoFromRR?.address?.zipCode}
            RRAddressText={contactInfoFromRR?.address?.fullAddress}
            updateContactInfo={updateContactInfo}
            setIsAddressValid={() => {}}
            isForceValidation={false}
            disabled={true}
          />
        )}
    </div>
  );
};
