import React, { FC } from "react";
import { TabContent, TabPane } from "reactstrap";
import { GpListHistoryTypes } from "./GpListHistoryTypes";
import GPListHistoryTable from "./GPListHistoryTable";
import {
  GeneralPractitionerListHistoryEmployees,
  GeneralPractitionerListHistoryFamilyDoctor,
  GeneralPractitionerListHistoryLicense,
  GeneralPractitionerListHistoryLicenseAddress,
  GeneralPractitionerListHistoryMaxPatientsSize,
  GeneralPractitionerListHistoryServiceArea,
  GeneralPractitionerListHistorySubstitute,
  GeneralPractitionerListHistorySubstitutes,
} from "../../../../../Dto/GeneralPractitionerList/GeneralPractitionerListHistoryLog";

interface Props {
  activeTab: GpListHistoryTypes;
}

const HistoryTabPane: FC<{
  tabId: GpListHistoryTypes;
  activeTab: GpListHistoryTypes;
}> = ({ tabId, activeTab, children }) => {
  return (
    <TabPane tabId={tabId}>{tabId === activeTab ? children : null}</TabPane>
  );
};

const GpListHistoryTabContents: FC<Props> = ({ activeTab }) => {
  return (
    <TabContent activeTab={activeTab}>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeFamilyDoctor}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistoryFamilyDoctor>
          type={GpListHistoryTypes.ChangeFamilyDoctor}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeSubstituteDoctor}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistorySubstitute>
          type={GpListHistoryTypes.ChangeSubstituteDoctor}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeSubstitute}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistorySubstitutes>
          type={GpListHistoryTypes.ChangeSubstitute}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeLicense}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistoryLicense>
          type={GpListHistoryTypes.ChangeLicense}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeMaxPatientsSize}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistoryMaxPatientsSize>
          type={GpListHistoryTypes.ChangeMaxPatientsSize}
        />
      </HistoryTabPane>
      <HistoryTabPane tabId={GpListHistoryTypes.ChangeEmployees} activeTab={activeTab}>
        <GPListHistoryTable<GeneralPractitionerListHistoryEmployees>
          type={GpListHistoryTypes.ChangeEmployees}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeServiceArea}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistoryServiceArea>
          type={GpListHistoryTypes.ChangeServiceArea}
        />
      </HistoryTabPane>
      <HistoryTabPane
        tabId={GpListHistoryTypes.ChangeLicenseAddress}
        activeTab={activeTab}
      >
        <GPListHistoryTable<GeneralPractitionerListHistoryLicenseAddress>
          type={GpListHistoryTypes.ChangeLicenseAddress}
        />
      </HistoryTabPane>
    </TabContent>
  );
};

export default GpListHistoryTabContents;
