import { action } from "typesafe-actions";
import {
  ActivityLicense,
  LicenseAcquisition,
  Company,
  StateFee,
  FileReference
} from "../../api_client/medre_api";

export const SET_LICENSE_ACQUISITION_APPLICATION =
  "SET_LICENSE_ACQUISITION_APPLICATION";
export const UPDATE_LICENSE_ACQUISITION_APPLICATION_LICENSE =
  "UPDATE_LICENSE_ACQUISITION_APPLICATION_LICENSE";
export const UPDATE_LICENSE_ACQUISITION_APPLICATION =
  "UPDATE_LICENSE_ACQUISITION_APPLICATION";

export const REMOVE_ACQUISITION_FILE_REFERENCE =
  "REMOVE_ACQUISITION_FILE_REFERENCE";
export const ADD_ACQUISITION_FILE_REFERENCE = "ADD_ACQUISITION_FILE_REFERENCE";

type applicationFieldType =
  | boolean
  | Company
  | string
  | undefined
  | number
  | StateFee
  | Date
  | FileReference[];

export const licenseAcquisitionActions = {
  setLicenseAcquisitionApplication: (application: LicenseAcquisition) =>
    action(SET_LICENSE_ACQUISITION_APPLICATION, application),
  updateApplicationDraftLicense: (value: ActivityLicense) =>
    action(UPDATE_LICENSE_ACQUISITION_APPLICATION_LICENSE, { value }),
  updateApplicationDraft: (
    fieldName: keyof LicenseAcquisition,
    field: applicationFieldType
  ) =>
    action(UPDATE_LICENSE_ACQUISITION_APPLICATION, {
      fieldName,
      field
    }),
  addFileReferences: (files: FileReference[]) =>
    action(ADD_ACQUISITION_FILE_REFERENCE, { files }),
  removeFileReference: (minioFileName: string) =>
    action(REMOVE_ACQUISITION_FILE_REFERENCE, { minioFileName })
};
