import { Col, Row } from "reactstrap";
import React from "react";
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../../../../Constants";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import { ApplicationFM } from "../../../../../Messages/ApplicationFM";
import { AttachmentFileRow } from "../../../../Shared/Application/Education/Manual/Attachment/AttachmentFileRow";
import { PersonalDataFM } from "../../../../../Messages/PersonalDataFM";
import {
  getFormattedCountryMessage,
  translateCountry
} from "../../../../../Component/Translate/CountryTranslator";
import {
  formatDate,
  renderOverViewRow,
  renderDeliveryByPost
} from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import {
  FileObjectType,
  Person,
  PersonGenderEnum,
  ApplicationCertificateDeliveryTypeEnum,
  FileReference,
  EducationInfo
} from "../../../../../../api_client/medre_api";
import { ContactInfoFM } from "../../../../../Messages/ContactInfoFM";

export const renderApplicationRow = (
  widthSize: number,
  icon: JSX.Element,
  name: string,
  status: JSX.Element,
  arrivalTime?: string,
  deadline?: Date,
  applicationNumber?: number
) => {
  if (widthSize > TABLET_MAX_WIDTH) {
    return (
      <>
        <td style={{ minWidth: 40 }} className="text-center">
          {icon}
        </td>
        <td style={{ minWidth: 100 }}>{arrivalTime}</td>
        <td>{name}</td>
        <td style={{ minWidth: 200 }}>{status}</td>
        <td style={{ minWidth: 158 }}>
          {deadline && (
            <GeneralFM
              id="deadline"
              values={{ deadline: formatDate(deadline) }}
            />
          )}
        </td>
        <td style={{ minWidth: 65 }}>{applicationNumber}</td>
      </>
    );
  } else if (widthSize > MOBILE_MAX_WIDTH) {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" md={1}>
              {icon}
            </Col>
            <Col md={3}>
              <Row className="fs-14 lh-150">
                <GeneralFM id="date" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{arrivalTime}</Row>
            </Col>
            <Col md={4}>
              <Row className="fs-14 lh-150">
                <ApplicationFM id="applicationOccupationOrSpeciality" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{name}</Row>
            </Col>
            <Col md={4}>
              <Row>{status}</Row>
              <Row className="fs-16 lh-131">
                {deadline && (
                  <GeneralFM
                    id="deadline"
                    values={{ deadline: formatDate(deadline) }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </td>
      </>
    );
  } else {
    return (
      <>
        <td colSpan={100}>
          <Row className="m-0">
            <Col className="icon-col" xs="1">
              {icon}
            </Col>
            <Col>
              <Row className="fs-14 lh-150">
                <ApplicationFM id="applicationOccupationOrSpeciality" />
              </Row>
              <Row className="fw-500 fs-16 lh-150">{name}</Row>
              <Row>{status}</Row>
              <Row className="fs-16 lh-131">
                {deadline && (
                  <GeneralFM
                    id="deadline"
                    values={{ deadline: formatDate(deadline) }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </td>
      </>
    );
  }
};

export const renderApplicantCol = (
  person: Person,
  educationInfo?: EducationInfo,
  education?: string,
  deliveryType?: ApplicationCertificateDeliveryTypeEnum,
  fileReferences?: FileReference[],
  deliveryAddress?: string,
  applicationType?: string,
) => {
  const genderString =
    person.gender === PersonGenderEnum.Male ? "male" : "female";

  const diplomas = fileReferences?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingDiploma
  );
  const academicReports = fileReferences?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingAcademicReport
  );
  const additionalDocuments = fileReferences?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingAdditionalDocument
  );
  const nameChangeDocuments = fileReferences?.filter(
    (ref) => ref.fileObjectType === FileObjectType.ProceedingNameChangeDocument
  );

  return (
    <Col className="detail-col pl-0 pr-0" xs={12} md={6}>
      <Row className="col-header">
        <GeneralFM id="yourData" />
      </Row>
      {renderOverViewRow("personalData", "firstName", person.firstName)}
      {renderOverViewRow("personalData", "lastName", person.lastName)}
      {renderOverViewRow("personalData", "personalCode", person.idCode)}
      {renderOverViewRow(
        "personalData",
        "dateOfBirth",
        formatDate(person.dateOfBirth)
      )}
      {renderOverViewRow(
        "personalData",
        "gender",
        <PersonalDataFM id={genderString} />
      )}
      {renderOverViewRow(
        "personalData",
        "citizenship",
        translateCountry(person.citizenship)
      )}
      {renderOverViewRow("contactInfo", "email", person.contactInfo?.email)}
      {renderOverViewRow(
        "contactInfo",
        "phone",
        `${person.contactInfo?.dialCode || ""} ${
          person.contactInfo?.phone || ""
        }`
      )}
      {applicationType === "certificate" && renderOverViewRow("education", "education", education)}
      {deliveryType === ApplicationCertificateDeliveryTypeEnum.TakeMyself &&
        renderOverViewRow(
          "contactInfo",
          "certificateDeliveryType",
          <ContactInfoFM id="takeCertificateCompanyAddress" />
        )}
      {deliveryType === ApplicationCertificateDeliveryTypeEnum.Post &&
        renderDeliveryByPost(deliveryAddress)}
      {deliveryType === ApplicationCertificateDeliveryTypeEnum.Electronic &&
        renderOverViewRow(
          "contactInfo",
          "certificateDeliveryType",
          <ContactInfoFM id="certificateIssuedElectronically" />
        )}
      {renderOverViewRow(
        "education",
        "schoolName",
        educationInfo?.schoolName,
        false,
        true
      )}
      {renderOverViewRow(
        "education",
        "country",
        getFormattedCountryMessage(educationInfo?.countryCode),
        false,
        true
      )}
      {renderOverViewRow(
        "education",
        "curriculumName",
        educationInfo?.curriculumName,
        false,
        true
      )}
      {renderOverViewRow(
        "education",
        "diplomaDate",
        formatDate(educationInfo?.diplomaDate),
        false,
        true
      )}
      {renderOverViewRow(
        "education",
        "diplomaNumber",
        educationInfo?.diplomaNumber,
        false,
        true
      )}
      {renderOverViewRow(
        "files",
        "diploma",
        renderFiles(diplomas),
        false,
        true
      )}
      {renderOverViewRow(
        "files",
        "academicReport",
        renderFiles(academicReports),
        false,
        true
      )}
      {renderOverViewRow(
        "files",
        "additionalDocument",
        renderFiles(additionalDocuments),
        false,
        true
      )}
      {renderOverViewRow(
        "files",
        "nameChangeDocument",
        renderFiles(nameChangeDocuments),
        false,
        true
      )}
    </Col>
  );
};

export const renderFiles = (files?: FileReference[]) => {
  return files?.length ? (
    <>
      {files?.map((file) => (
        <div key={file.fileName}>
          <AttachmentFileRow
            fileName={file.fileName}
            minioFileName={file.minioFileName}
            isDeletingPossible={false}
          />
        </div>
      ))}
    </>
  ) : (
    ""
  );
};
