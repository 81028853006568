import { action } from "typesafe-actions";
import {
  ActivityLicenseApplicationChannelTypeEnum,
  ApplicationDraft,
  ContactInfo,
  DetailedApplicationTypeEnum,
  EducationInfo,
  FileReference,
  Occupation,
  PersonalData
} from "../../api_client/medre_api";
import { ApplicationCertificateDraft } from "../Dto/ApplicationCertificate/ApplicationCertificate";

export const SET_APPLICATION_DRAFT = "SET_APPLICATION_DRAFT";
export const UPDATE_APPLICATION_DRAFT = "UPDATE_APPLICATION_DRAFT";

export const REMOVE_FILE_REFERENCE = "REMOVE_FILE_REFERENCE";

export const applicationDraftActions = {
  setApplicationDraft: <
    TApplicationDraft extends ApplicationDraft | ApplicationCertificateDraft
  >(
    applicationDraft: TApplicationDraft
  ) => action(SET_APPLICATION_DRAFT, applicationDraft),
  updateApplicationDraft: <
    TApplicationDraft extends ApplicationDraft | ApplicationCertificateDraft
  >(
    fieldName:
      | keyof TApplicationDraft
      | "saveAllowed"
      | "contactInfo"
      | "personalData",
    field:
      | Occupation
      | boolean
      | ContactInfo
      | ActivityLicenseApplicationChannelTypeEnum
      | PersonalData
      | EducationInfo
      | FileReference[]
      | number
      | DetailedApplicationTypeEnum
      | undefined
      | string
  ) =>
    action(UPDATE_APPLICATION_DRAFT, {
      fieldName,
      field
    }),
  removeFileReference: (minioFileName: string) =>
    action(REMOVE_FILE_REFERENCE, { minioFileName })
};
