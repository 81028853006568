import React, { MouseEvent, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { SideMenuItem } from "../../SideMenuItem/SideMenuItem";
import home_icon from "../../../../assets/images/home.svg";
import page_icon from "../../../../assets/images/page.svg";
import gplist_icon from "../../../../assets/images/gplist.svg";
import { LanguageDropdown } from "../../Header/LanguageDropdown";
import "../../Style/SideMenu.scss";
import * as Types from "../../Shared/Search/Filters/FilterTypes";
import { filterActions } from "../../Shared/Search/Filters/filterActions";
import { useDispatch } from "react-redux";
import {
  publicSearchEmployeesUrl,
  publicSearchGPListsUrl,
  publicSearchLicensesUrl,
} from "../Public/PublicSearch";
import { searchResultsActions } from "../../Shared/Search/searchResultsActions";

interface Props {
  isSideMenuCollapsed: boolean;
}

export const PublicSideMenu = ({ isSideMenuCollapsed }: Props) => {
  const dispatch = useDispatch();
  const handleNavToPublicSearch = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      const { type } = event.currentTarget.dataset as {
        type: Types.ActiveFilters;
      };
      dispatch(filterActions.setFilterType(type));
      dispatch(searchResultsActions.setFilters(null));
    },
    [dispatch]
  );

  return (
    <aside id="global-sidemenu" className="global-sidemenu">
      <ul className="global-sidemenu-list">
        <li>
          <NavLink to="/home" activeClassName="item-active">
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.homePage"
                  defaultMessage="Avaleht"
                />
              }
              icon={home_icon}
            />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/search/${publicSearchEmployeesUrl}`}
            activeClassName="item-active"
            onClick={handleNavToPublicSearch}
            data-type={Types.ActiveFilters.PublicHealthcareEmployees}
          >
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.medicalWorkers"
                  defaultMessage="Tervishoiutöötajad"
                />
              }
              icon={page_icon}
            />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/search/${publicSearchGPListsUrl}`}
            activeClassName="item-active"
            onClick={handleNavToPublicSearch}
            data-type={Types.ActiveFilters.PublicGeneralPractitionerList}
          >
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.gpLists"
                  defaultMessage="Perearsti nimistud"
                />
              }
              icon={gplist_icon}
            />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/search/${publicSearchLicensesUrl}`}
            activeClassName="item-active"
            onClick={handleNavToPublicSearch}
            data-type={Types.ActiveFilters.PublicActivityLicenses}
          >
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.licenseRegister"
                  defaultMessage="Tegevusload"
                />
              }
              icon={page_icon}
              isActive={false}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="/open-data" activeClassName="item-active">
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.openData"
                  defaultMessage="Avaandmed"
                />
              }
              icon={page_icon}
              isActive={false}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="/" exact={true} activeClassName="item-active">
            <SideMenuItem
              isSideMenuCollapsed={isSideMenuCollapsed}
              title={
                <FormattedMessage
                  id="sideMenuItem.healthcareSelfService"
                  defaultMessage="Tervishoiutöötaja iseteenindus"
                />
              }
              icon={page_icon}
              isActive={true}
            />
          </NavLink>
        </li>
      </ul>
      {!isSideMenuCollapsed && (
        <div className="bottom-container">
          <LanguageDropdown />
        </div>
      )}
    </aside>
  );
};
