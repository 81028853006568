import React, { ReactChild, ReactNode, ReactText } from "react";
import classNames from "classnames";
import { Collapse } from "reactstrap";

import { MinusIcon } from "../Icon/MinusIcon";
import { PlusIcon } from "../Icon/PlusIcon";

interface AccordionProps {
  title: JSX.Element | string;
  titleClassName?: string;
  children: ReactChild | Array<ReactChild> | ReactText | ReactNode;
}

export const Accordion = ({
  title,
  titleClassName,
  children
}: AccordionProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const toggle = () => setExpanded(!expanded);

  const icon = expanded ? <MinusIcon /> : <PlusIcon />;

  return (
    <>
      <div
        className={classNames(
          "cursor-pointer accordion-header",
          titleClassName,
          {
            expanded: expanded
          }
        )}
        role="button"
        onClick={toggle}
      >
        {renderAccordionHeaderRow(0, icon, title)}
      </div>
      <Collapse isOpen={expanded}>{children}</Collapse>
    </>
  );
};

export const renderAccordionHeaderRow = (
  widthSize: number,
  icon: JSX.Element,
  title: JSX.Element | string
) => {
  return (
    <div className="d-flex">
      <button
        style={{ minWidth: 20 }}
        className="btn-unstyled d-inline-flex align-items-center"
      >
        {icon}
      </button>
      <span className="title" style={{ minWidth: 100 }}>
        {title}
      </span>
    </div>
  );
};
