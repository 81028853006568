import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";

import { ApplicantCertificateDeletionModal } from "./ApplicantCertificateDeletionModal";
import { RemoveButton } from "../../../../Component/Button/RemoveButton";
import { getBaseUrl, API } from "../../../../api";
import {
  Person,
  PersonCertificate,
  AmetnikuSertifikaadiAndmeteenusApiFactory as officialCertificateDataAPI
} from "../../../../../api_client/medre_api";
import { translateCountries } from "../../../../Component/Translate/CountryTranslator";
import { getFormattedDate } from "../../../../Util/DateUtils";

interface Props {
  person: Person;
  certificate: PersonCertificate;
  onOpenCertificateModal: (
    certificate: PersonCertificate
  ) => (event: React.SyntheticEvent) => void;
  afterDelete: () => void;
}

export const ApplicantCertificatesTableRow = ({
  person,
  certificate,
  onOpenCertificateModal,
  afterDelete
}: Props) => {
  const [isOpenCertificateDeletion, setOpenCertificateDeletion] =
    useState(false);

  const handleOpenCertificateDeletionModal = useCallback(
    (event?: React.SyntheticEvent) => {
      event?.preventDefault();
      setOpenCertificateDeletion(true);
    },
    [setOpenCertificateDeletion]
  );

  const handleCloseCertificateDeletionModal = useCallback(() => {
    setOpenCertificateDeletion(false);
  }, [setOpenCertificateDeletion]);

  const handleConfirmCertificateDeletionModal = useCallback(async () => {
    await officialCertificateDataAPI(
      undefined,
      getBaseUrl(),
      API
    ).deletePersonCertificate(certificate.id as string, person.id as string, {
      withCredentials: true
    });

    await afterDelete();
  }, [person, certificate, afterDelete]);

  return (
    <>
      <tr key={certificate.id}>
        <td className="normal-column text-wrap">
          {certificate.occupationCodes
            ?.map((o) => `${o.name} (${o.code})`)
            .join(", ")}
        </td>
        <td className="normal-column text-wrap">
          {certificate.speciality?.name ?? "-"}
        </td>
        <td className="normal-column text-wrap">
          {translateCountries(certificate.countries!)}
        </td>
        <td className="normal-column text-wrap">
          {translateCountries(certificate.educationCountries!)}
        </td>
        <td className="normal-column text-wrap">
          {getFormattedDate(certificate.issueDate!)}
        </td>
        <td className="normal-column no-wrap text-center">
          <>
            <NavLink
              to="/"
              onClick={onOpenCertificateModal(certificate)}
              activeClassName="item-active"
            >
              Muuda tõendit
            </NavLink>
            &nbsp;|&nbsp;
            <RemoveButton
              handleDelete={handleOpenCertificateDeletionModal}
              hideText={true}
            />
          </>
        </td>
      </tr>

      {isOpenCertificateDeletion && (
        <ApplicantCertificateDeletionModal
          isOpen={true}
          onClose={handleCloseCertificateDeletionModal}
          onConfirm={handleConfirmCertificateDeletionModal}
        />
      )}
    </>
  );
};
