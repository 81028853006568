import React from "react";
import AsyncSelect from "react-select/async";
import { ValueType } from "react-select";
import { FormattedMessage } from "react-intl";
import { AxiosResponse } from "axios";

import { TypedOption } from "./SelectTypes";
import { load, NoOptionsMessage } from "./SelectUtils";

interface Props<T> {
  inputId?: string;
  toOption: (object: T) => TypedOption<T>;
  loadMethod: (inputValue: string) => Promise<AxiosResponse>;
  onSelect: (option: TypedOption<T>) => void;
  filterOption: (object: T) => boolean;
  cacheOptions?: boolean;
  value?: TypedOption<T>;
  defaultOptions?: TypedOption<T>[];
  placeholder?: React.ReactNode;
}

export const AsyncCustomSelect = <T extends object>({
  toOption,
  loadMethod,
  onSelect,
  cacheOptions,
  filterOption,
  defaultOptions,
  placeholder,
  inputId,
  ...props
}: Props<T>) => {
  const { value = null } = props;

  const onChange = (newValue: ValueType<TypedOption<T>, boolean>) => {
    onSelect(newValue as TypedOption<T>);
  };

  return (
    <AsyncSelect
      inputId={inputId}
      value={value}
      placeholder={
        placeholder || (
          <FormattedMessage
            id="asyncCustomSelect.search"
            defaultMessage="Otsi"
          />
        )
      }
      onChange={onChange}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      components={{ NoOptionsMessage: NoOptionsMessage }}
      loadOptions={load(loadMethod, filterOption, toOption)}
    />
  );
};
