import {
  ActivityLicenseRowHeaderStatusEnum,
} from "../../../../../../api_client/medre_api";
import { ActivityLicenseStatusFM } from "../../../../../Messages/ActivityLicenseFM";
import React from "react";
import { singleSelectProps } from "../FilterUtils";
import { GeneralFM } from "../../../../../Messages/GeneralFM";

export const statuses = [
  {
    value: ActivityLicenseRowHeaderStatusEnum.Valid,
    label: (
      <ActivityLicenseStatusFM id={ActivityLicenseRowHeaderStatusEnum.Valid} />
    )
  },
  {
    value: ActivityLicenseRowHeaderStatusEnum.Invalid,
    label: (
      <ActivityLicenseStatusFM id={ActivityLicenseRowHeaderStatusEnum.Invalid} />
    )
  },
  {
    value: ActivityLicenseRowHeaderStatusEnum.Paused,
    label: (
      <ActivityLicenseStatusFM id={ActivityLicenseRowHeaderStatusEnum.Paused} />
    )
  }
];

export const selectProps = {
  ...singleSelectProps,
  placeholder: <GeneralFM id="selectPlaceholderAll"/>
};

export type Item = {id: string; name: string};
export const makeOptions = (items: Array<Item>) =>
  items.map(({ id: value, name: label }) => ({
    value,
    label
  }));
