export enum GpListHistoryTypes {
    ChangeMaxPatientsSize = "UPDATE_GP_LIST_PATIENTS_SIZE",
    ChangeSubstituteDoctor = "UPDATE_GP_LIST_SUBSTITUTE_DOCTOR",
    ChangeSubstitute = "UPDATE_GP_LIST_SUBSTITUTE",
    ChangeLicense = "UPDATE_GP_LIST_LICENSE",
    ChangeServiceArea = "UPDATE_GP_LIST_SERVICE_AREA",
    ChangeEmployees = "UPDATE_GP_LIST_EMPLOYEE",
    ChangeFamilyDoctor = "UPDATE_GP_LIST_GENERAL_PRACTITIONER",
    ChangeLicenseAddress = "UPDATE_GP_LIST_LICENSE_ADDRESS",
}
