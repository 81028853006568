import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import Button from "reactstrap/lib/Button";
import Spinner from "reactstrap/lib/Spinner";

import { useWindowWidthSize } from "../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../Constants";
import { BusinessInfoFM } from "../../../Messages/BusinessInfoFM";
import { validateNumber } from "../../Shared/Validation/fieldValidator";
import { getBaseUrl, API } from "../../../api";
import {
  activityLicenseApplicationActions,
  activityLicenseFieldType
} from "../../../ActivityLicense/activityLicenseApplicationActions";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import {
  FirmadeAndmeteTeenusApiFactory as companyAPI,
  CompanyStatusEnum
} from "../../../../api_client/medre_api";

interface Props {
  registryCode?: string;
  isDisabled: boolean;
}

export const SearchBusiness = ({ registryCode, isDisabled }: Props) => {
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [currentRegistryCode, setCurrentRegistryCode] = useState(registryCode);
  const [isRegistryCodeValid, setIsRegistryCodeValid] = useState<boolean>();
  const [status, setStatus] = useState<CompanyStatusEnum>();

  const handleRegistryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCurrentRegistryCode(e.target.value);

  useEffect(() => {
    if (currentRegistryCode !== undefined) {
      setIsRegistryCodeValid(validateNumber(currentRegistryCode));
    }
  }, [currentRegistryCode]);

  const getBusiness = () => {
    if (!isRegistryCodeValid) {
      return;
    }
    setIsLoading(true);
    companyAPI(undefined, getBaseUrl(), API)
      .getDetailedBusinessInfo(currentRegistryCode as string, {
        withCredentials: true
      })
      .then((result) => {
        const businessStatus = result.data.businessStatus;
        setStatus(businessStatus as CompanyStatusEnum);
        if (businessStatus === CompanyStatusEnum.Ok) {
          dispatch(
            activityLicenseApplicationActions.updateActivityLicense(
              "company",
              result.data.company as activityLicenseFieldType
            )
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        const alertMessage = (
          <AlertMessage id="fetchDetailedBusinessInfoFailed" />
        );
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        setIsLoading(false);
      });
  };

  const renderButtonText = () =>
    isLoading ? <Spinner size={"sm"} color={"white"} /> : "Päri ettevõtte info";

  const renderNotification = () => {
    switch (status) {
      case CompanyStatusEnum.StatusNotOk:
      case CompanyStatusEnum.Deleted:
        return "Ettevõtte staatus mitte sobilik";
      case CompanyStatusEnum.NotFound:
        return "Ettevõtet pole olemas";
      default:
        break;
    }
  };

  return (
    <>
      <h4>
        <FormattedMessage
          id="activityLicense.searchBusiness"
          defaultMessage="Otsi Äriregistrist ettevõtet"
        />
      </h4>
      <FormGroup
        className={"w-100 flex-nowrap " + (isMobile ? "" : "form-inline")}
      >
        <Label>
          <BusinessInfoFM id="registryCode" />
        </Label>
        <div className="input-wrapper wrapper-sm">
          <Input
            type="number"
            name="registryCode"
            value={currentRegistryCode || ""}
            onChange={handleRegistryCodeChange}
            disabled={isDisabled}
          />
        </div>
        <Button
          color="primary"
          className={"ml-4 btn-primary search-personal-data"}
          onClick={getBusiness}
          disabled={isDisabled}
        >
          {renderButtonText()}
        </Button>
      </FormGroup>
      <div className="business-notification">{renderNotification()}</div>
    </>
  );
};
