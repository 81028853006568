import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { DecisionStatusEnum } from "../../../../../api_client/medre_api";
import { MultiSelect } from "../../../../Component/Select/MultiSelect";
import { Option, Props } from "../../../../Component/Select/SelectTypes";
import { DecisionStatusFM } from "../../../../Messages/DecisionFM";

export const statuses: Record<DecisionStatusEnum, Option> = {
  [DecisionStatusEnum.Accepted]: {
    value: DecisionStatusEnum.Accepted,
    label: <DecisionStatusFM id={DecisionStatusEnum.Accepted} />
  },
  [DecisionStatusEnum.Declined]: {
    value: DecisionStatusEnum.Declined,
    label: <DecisionStatusFM id={DecisionStatusEnum.Declined} />
  },
  [DecisionStatusEnum.ComplianceExam]: {
    value: DecisionStatusEnum.ComplianceExam,
    label: <DecisionStatusFM id={DecisionStatusEnum.ComplianceExam} />
  },
  [DecisionStatusEnum.AptitudeTest]: {
    value: DecisionStatusEnum.AptitudeTest,
    label: <DecisionStatusFM id={DecisionStatusEnum.AptitudeTest} />
  },
  [DecisionStatusEnum.AcceptedAfterComplianceExam]: {
    value: DecisionStatusEnum.AcceptedAfterComplianceExam,
    label: (
      <DecisionStatusFM id={DecisionStatusEnum.AcceptedAfterComplianceExam} />
    )
  },
  [DecisionStatusEnum.AcceptedAfterAptitudeTest]: {
    value: DecisionStatusEnum.AcceptedAfterAptitudeTest,
    label: (
      <DecisionStatusFM id={DecisionStatusEnum.AcceptedAfterAptitudeTest} />
    )
  },
  [DecisionStatusEnum.WaitingForSignature]: {
    value: DecisionStatusEnum.WaitingForSignature,
    label: <DecisionStatusFM id={DecisionStatusEnum.WaitingForSignature} />
  }
};

export const decisionTHTStatuses = [
  statuses[DecisionStatusEnum.Accepted],
  statuses[DecisionStatusEnum.Declined],
  statuses[DecisionStatusEnum.ComplianceExam],
  statuses[DecisionStatusEnum.AptitudeTest],
  statuses[DecisionStatusEnum.AcceptedAfterComplianceExam],
  statuses[DecisionStatusEnum.AcceptedAfterAptitudeTest],
  statuses[DecisionStatusEnum.WaitingForSignature]
];

export const decisionLicenseStatuses = [
  statuses[DecisionStatusEnum.Accepted],
  statuses[DecisionStatusEnum.Declined],
  statuses[DecisionStatusEnum.WaitingForSignature]
];

export const DecisionStatusFilter: FC<Props> = (props) => (
  <MultiSelect
    name="decisionSearchStatuses"
    placeholder={
      <FormattedMessage
        id="filters.applicationTypePlaceholder"
        defaultMessage="Vali otsuse tüüp"
      />
    }
    {...props}
    isSearchable={false}
    alwaysClearable={false}
  />
);
