import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../../Component/Table/Table.scss";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { ActivityLicenseRow } from "./ActivityLicenseRow";
import { RootState } from "../../../../rootReducer";
import { ActivityLicenseRowHeader } from "../../../../../api_client/medre_api";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";

interface Props {
  activityLicenses: ActivityLicenseRowHeader[];
  reload: () => void;
}

export const ActivityLicenseTable = ({ activityLicenses, reload }: Props) => {
  const expandedRowNumber = useSelector(
    (state: RootState) => state.activityLicenseTable.expandedActivityLicenseRow
  );
  const dispatch = useDispatch();

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      dispatch(
        activityLicenseTableActions.setExpandedActivityLicenseRow(rowNumber)
      );
    } else {
      dispatch(activityLicenseTableActions.setExpandedActivityLicenseRow(null));
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>
            <GeneralFM id="date" />
          </th>
          <th>
            <ActivityLicenseFM id="businessArea" />
          </th>
          <th>
            <ActivityLicenseFM id="state" />
          </th>
          <th>
            <ActivityLicenseFM id="licenseNumber" />
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {activityLicenses?.map((license, i) => (
          <ActivityLicenseRow
            key={i}
            header={license}
            isOpen={expandedRowNumber === i}
            index={i}
            handleRowClick={handleRowClick}
            reload={reload}
          />
        ))}
      </tbody>
      <tfoot>
        <tr />
      </tfoot>
    </table>
  );
};
