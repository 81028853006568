import React from "react";
import { Col, Row } from "reactstrap";

import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";

interface Props {
  id: string;
  value?: string | JSX.Element;
  className?: string;
}

export const ContactInfoRow = ({ id, value, className }: Props) => (
  <Row className={"m-2 " + className}>
    <Col className="contact-info-left-column text-md-right">
      <ContactInfoFM id={id} />
    </Col>
    <Col md={8} xs={12}>
      {value}
    </Col>
  </Row>
);
