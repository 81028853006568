import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useAuthorized } from "../../../Hook/usePortal";

export const LinkToPublicPortal: FC<{ to?: string }> = ({ to, children }) => {
  const isAuthorized = useAuthorized();
  if (!children) {
    return null;
  }
  if (!to || isAuthorized) {
    return <>{children}</>;
  }
  return (
    <Link to={to} className="btn link-button p-0">{children}</Link>
  );
};