import { useMemo } from "react";
import {
  Meta,
  TableState,
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import { ReactTableColumn } from "../../../Component/Table/ReactTable";

interface GPListTableProps<D extends Record<string, string>> {
  data: D[];
  columns: ReactTableColumn<D>[];
  useControlledState?: (state: TableState<D>, meta: Meta<D>) => TableState<D>;
}

export function useGPListTable<D extends { id: string }>({
  data,
  columns,
  useControlledState
}: GPListTableProps<D>) {
  const getRowId = useMemo(() => (row: D) => row.id, []);

  return useTable<D>(
    {
      columns,
      data,
      getRowId,
      useControlledState,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false
    },
    useSortBy,
    useExpanded,
    useRowSelect
  );
}
