import { Option, SingleSelect } from "./SingleSelect";
import React, { useEffect, useState } from "react";

import { getBaseUrl, API } from "../../api";
import { KlassifikaatoriteTeenusApiFactory as classifierAPI } from "../../../api_client/medre_api";
import {AxiosPromise} from "axios";

interface Props {
  inputId?: string;
  occupationCode?: string;
  defaultSelectedValue?: string;
  handleOptionChange: (option: Option) => void;
}

export const SchoolSelect = ({
  inputId,
  occupationCode,
  handleOptionChange,
  defaultSelectedValue
}: Props) => {
  const defaultOption: Option = { value: "", label: "" };

  const [options, setOptions] = useState<Option[]>([]);
  const [defaultSelectedOptionValue] = useState(defaultSelectedValue);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    retrieveSchools()
      .then((res) => {
        let schools: string[] = res.data;
        setOptions(schools.map((school) => ({ value: school, label: school })));
        if (defaultSelectedOptionValue) {
          const school = schools.filter(
            (s) => s === defaultSelectedOptionValue
          )[0];
          setSelectedOption({ value: school, label: school } as Option);
        } else if (schools.length === 1) {
          let schoolOption = { value: schools[0], label: schools[0] };
          setSelectedOption(schoolOption);
          handleOptionChange(schoolOption);
        }
      });
  }, [occupationCode, defaultSelectedOptionValue, handleOptionChange]);

  const retrieveSchools = (): AxiosPromise<Array<string>> => {
    return occupationCode ? classifierAPI(undefined, getBaseUrl(), API).getSchoolNames(occupationCode, { withCredentials: true }) :
      classifierAPI(undefined, getBaseUrl(), API).getAllSchoolNames({ withCredentials: true })
  }

  const handleChange = (option: Option) => {
    setSelectedOption(option);
    handleOptionChange(option);
  };

  return (
    <SingleSelect
      inputId={inputId}
      isValid={!!selectedOption.value}
      disabled={options.length === 1}
      value={selectedOption}
      className=""
      options={options}
      hideSearchIcon={true}
      handleOptionChange={handleChange}
    />
  );
};
