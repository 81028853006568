import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { Loader } from "../../../../Component/Loader/Loader";
import { MultiSelect } from "../../../../Component/Select/MultiSelect";
import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import { singleSelectProps } from "../../../Shared/Search/Filters/FilterUtils";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import {
  filterActions,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import { getBaseUrl, API } from "../../../../api";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { TimePeriodFilter } from "./TimePeriodFilter";
import { getFullName } from "../../../../Util/PersonUtils";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import {
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI,
  AmetnikeTeenusAmetnikeleApiFactory as officialServiceAPI,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import {
  statuses,
  proceedingTypes
} from "../../../../Util/ActivityLicenseUtils";

const selectProps = {
  ...singleSelectProps,
  placeholder: <GeneralFM id="selectPlaceholderAll" />
};

export const ActivityLicenseApplicationFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    filterSelectors.selectActivityLicenseApplicationFilters,
    shallowEqual
  );

  const [loading, setLoading] = useState(true);
  const [businessAreaOptions, setBusinessAreaOptions] = useState<Array<Option>>(
    []
  );
  const [hospitalAreaOptions, setHospitalAreaOptions] = useState<Array<Option>>(
    []
  );
  const [proceedingOfficialOptions, setProceedingOfficialOptions] = useState<
  Array<Option>
  >([]);
  const HAS_ONLY_VISIBLE_OFFICIALS = false;

  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(
        filterActions.setActivityLicenseApplicationFilter({ key, data })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    Promise.all([
      publicActivityPermitAPI(
        undefined,
        getBaseUrl(),
        API
      ).getBusinessAreaClassifiers({
        withCredentials: true
      }),
      officialServiceAPI(undefined, getBaseUrl()).getOfficialsWithPrivilege(
        OfficialUserPrivilegesEnum.Proceede, HAS_ONLY_VISIBLE_OFFICIALS,
        { withCredentials: true }
      )
    ])
      .then(
        ([
          {
            data: { regularAreas, hospitalTypeAreas }
          },
          { data: officials }
        ]) => {
          setBusinessAreaOptions(
            regularAreas!.map(
              ({ id: value, name: label }: Record<string, any>) => ({
                value,
                label
              })
            )
          );
          setHospitalAreaOptions(
            hospitalTypeAreas!.map(
              ({ id: value, name: label }: Record<string, any>) => ({
                value,
                label
              })
            )
          );
          setProceedingOfficialOptions(
            officials.map(
              ({ id: value, firstName, lastName }: Record<string, any>) => ({
                value,
                label: getFullName({ firstName, lastName })
              })
            )
          );
        }
      )
      .finally(() => setLoading(false));
  }, []);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );
  const handleApplicationNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let applicationNumber = value === "" ? null : +value;
      set(name, applicationNumber);
    },
    [set]
  );
  const handleChangeMultiSelect = useCallback(
    (options: Option[], name?: string) => {
      set(name!, options);
    },
    [set]
  );
  const handleChangeSelect = useCallback(
    (option: Option, name: string) => {
      const value = option?.value ?? null;
      set(name, value);
    },
    [set]
  );

  return (
    <FilterContainer>
      { loading && <Loader absolute /> }
      <FilterColumn>
        <FilterField
          id="activityLicenseApplicationFiltersBusinessName"
          label={
            <FormattedMessage
              id="filters.businessNameLabel"
              defaultMessage="Teenuse osutaja"
            />
          }
          field={
            <Input
              id="activityLicenseApplicationFiltersBusinessName"
              name="businessName"
              value={state.businessName}
              onChange={handleChangeInput}
              placeholder="Nimi"
            />
          }
        />

        <FilterField
          id="activityLicenseApplicationFiltersBusinessAreaIds"
          label={
            <FormattedMessage
              id="filters.businessAreasLabel"
              defaultMessage="Tegevusala"
            />
          }
          field={
            <MultiSelect
              inputId="activityLicenseApplicationFiltersBusinessAreaIds"
              name="businessAreaIds"
              value={state.businessAreaIds || []}
              options={businessAreaOptions}
              handleOptionsChange={handleChangeMultiSelect}
              {...selectProps}
            />
          }
        />

        <FilterField
          id="activityLicenseApplicationFiltersApplicationStatus"
          label={
            <FormattedMessage
              id="filters.applicationStatusLabel"
              defaultMessage="Taotluse staatus"
            />
          }
          field={
            <SingleSelect
              inputId="activityLicenseApplicationFiltersApplicationStatus"
              name="applicationStatus"
              value={state.applicationStatus}
              options={statuses}
              handleOptionChange={handleChangeSelect}
              placeholder={
                <FormattedMessage
                  id="filters.applicationStatusPlaceholder"
                  defaultMessage="Vali staatus"
                />
              }
              {...singleSelectProps}
              isSearchable
            />
          }
        />

        <FilterField
          id="activityLicenseApplicationFiltersProceedingOfficial"
          label={
            <FormattedMessage
              id="filters.proceedingOfficialLabel"
              defaultMessage="Menetleja nimi"
            />
          }
          field={
            <SingleSelect
              inputId="activityLicenseApplicationFiltersProceedingOfficial"
              name="proceedingOfficial"
              value={state.proceedingOfficial}
              options={proceedingOfficialOptions}
              handleOptionChange={handleChangeSelect}
              placeholder={
                <FormattedMessage
                  id="filters.proceedingOfficialPlaceholder"
                  defaultMessage="Menetleja nimi"
                />
              }
              {...singleSelectProps}
              isSearchable
            />
          }
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="activityLicenseApplicationFiltersApplicationNumber"
          label={
            <FormattedMessage
              id="filters.applicationNumberLabel"
              defaultMessage="TL taotluse number"
            />
          }
          field={
            <Input
              id="activityLicenseApplicationFiltersApplicationNumber"
              name="applicationNumber"
              value={state.applicationNumber || ""}
              onChange={handleApplicationNumberChange}
              placeholder="Taotluse number"
            />
          }
        />

        <FilterField
          id="activityLicenseApplicationFiltersHospitalTypeIds"
          label={
            <FormattedMessage
              id="filters.hospitalTypeLabel"
              defaultMessage="Haigla liik"
            />
          }
          field={
            <MultiSelect
              inputId="activityLicenseApplicationFiltersHospitalTypeIds"
              name="hospitalTypeIds"
              value={state.hospitalTypeIds || []}
              options={hospitalAreaOptions}
              handleOptionsChange={handleChangeMultiSelect}
              {...selectProps}
            />
          }
        />

        <TimePeriodFilter
          id="activityLicenseApplicationFilters"
          set={set}
          state={state}
        />

        <FilterField
          id="activityLicenseFiltersProceedingType"
          label={<ActivityLicenseFM id="proceedingType" />}
          field={
            <SingleSelect
              inputId="activityLicenseFiltersValidity"
              name="proceedingType"
              options={proceedingTypes}
              handleOptionChange={handleChangeSelect}
              value={state.proceedingType}
              {...selectProps}
              isSearchable
            />
          }
        />
      </FilterColumn>

      <FilterColumn />
    </FilterContainer>
  );
};
