import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./MyActivityLicenses.scss";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { NoResults } from "../../Table/NoResults";
import { ActivityLicenseTable } from "./ActivityLicenseTable";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";
import {
  ActivityLicenseRowHeader,
  ActivityLicenseRowHeaderStatusEnum,
  OfficialUserPrivilegesEnum,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { RootState } from "../../../../rootReducer";

export const MyActivityLicenses = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activityLicenses, setActivityLicenses] = useState<
  ActivityLicenseRowHeader[]
  >([]);
  const userRoles = useSelector(
    (state: RootState) => state.user.userInfo?.roles
  );
  const pharmacyBusinessArea = "Apteegi tegevusala";

  const loadActivityLicenses = useCallback(() => {
    setIsLoading(true);
    activityPermitAPI(undefined, getBaseUrl(), API)
      .getRowHeaders({
        withCredentials: true
      })
      .then((res) => {
        if (
          userRoles?.includes(
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull
          ) ||
          userRoles?.includes(
            OfficialUserPrivilegesEnum.PowerOfAttorneyTypeLimited
          ) ||
          userRoles?.includes(OfficialUserPrivilegesEnum.PowerOfAttorneyTypeGpl)
        ) {
          setActivityLicenses(
            res.data.filter(
              (license) =>
                license.status === ActivityLicenseRowHeaderStatusEnum.Valid
            )
          );
        } else {
          setActivityLicenses(res.data);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    loadActivityLicenses();
    dispatch(activityLicenseTableActions.resetExpandedRows());
  }, [loadActivityLicenses, dispatch]);

  const isAllPharmacyBusinessAreas = activityLicenses.length > 0
    ? activityLicenses.every((license) => license.businessAreaName === pharmacyBusinessArea)
    : false;

  const createNewApplication = () => {
    dispatch(
      activityLicenseApplicationActions.setActivityLicense({
        currentStep: 0,
        locations: [{}]
      })
    );
    history.push("/new-activity-license");
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    } else if (activityLicenses?.length) {
      return (
        <ActivityLicenseTable
          reload={loadActivityLicenses}
          activityLicenses={activityLicenses}
        />
      );
    } else {
      return (
        <NoResults>
          <FormattedMessage
            id="activityLicensesTable.noResults"
            defaultMessage="Tegevusload puuduvad"
          />
        </NoResults>
      );
    }
  };

  return (
    <div id="my-applications-container">
      <Row className="mx-0 mb-3 my-activity-licenses-row">
        <h1 className="fw-300">
          <FormattedMessage
            id="myActivityLicenses.title"
            defaultMessage="Minu tegevusload"
          />
        </h1>
        <div className="button-container">
          { !isAllPharmacyBusinessAreas &&
              <ProtectedComponent directRepresentativeOnly>
                <PrimaryFormattedButton
                  id="newApplication"
                  onClick={createNewApplication}
                />
              </ProtectedComponent>
          }
        </div>
      </Row>
      { renderContent() }
    </div>
  );
};
