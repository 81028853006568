import React, { ReactElement } from "react";

import "./SideMenuItem.scss";

interface Props {
  isSideMenuCollapsed: boolean;
  isActive?: boolean;
  title: ReactElement | string;
  icon: string | ReactElement;
}

export const SideMenuItem = ({
  isSideMenuCollapsed,
  isActive,
  title,
  icon
}: Props) => {
  const itemActiveName = isActive ? "item item-active " : "item ";
  return (
    <div className={itemActiveName + " justify-content-center text-white"}>
      <div className="w-100">
        {isSideMenuCollapsed ? (
          <>
            <div className="item-content item-text">
              {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
              <div>{title}</div>
            </div>
          </>
        ) : (
          <div className="item-content item-text">
            {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
            <span className="item-span">{title}</span>
          </div>
        )}
      </div>
    </div>
  );
};
