import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../rootReducer";
import { OfficialUserPrivilegesEnum, PowerOfAttorneyTypeEnum } from "../../api_client/medre_api";
import { POWER_OF_ATTORNEY_TYPE_PREFIX, REPRESENTATIVE_PREFIX } from "../Constants";

// tslint:disable-next-line:no-any
interface Props extends React.ComponentProps<any> {
  allowedRoles?: OfficialUserPrivilegesEnum[];
  licenseTypeAllowed?: OfficialUserPrivilegesEnum[];
  directRepresentativeOnly?: boolean;
  officialsOnly?: boolean;
  matchAnyCondition?: boolean;
}

export const isUserBusinessOwner = function (
  grantedRoles: (OfficialUserPrivilegesEnum | string)[] | undefined
) {
  if (typeof grantedRoles === "undefined") {
    return false;
  }
  return (
    grantedRoles.some((role) => role.startsWith(REPRESENTATIVE_PREFIX)) &&
    !grantedRoles.some((role) => role.startsWith(POWER_OF_ATTORNEY_TYPE_PREFIX))
  );
};
export const isUserBusinessOwnerOrPowerOfAttorneyTypeUltimate = function (
  grantedRoles: (OfficialUserPrivilegesEnum | string)[] | undefined
) {
  if (typeof grantedRoles === "undefined") {
    return false;
  }
  return (
    grantedRoles.some((role) => role.startsWith(REPRESENTATIVE_PREFIX) || PowerOfAttorneyTypeEnum.Ultimate === role)
  );
};

export const isUserOfficial = function (
  grantedRoles: (OfficialUserPrivilegesEnum | string)[] | undefined
) {
  if (typeof grantedRoles === "undefined") {
    return false;
  }
  return grantedRoles.some((role) => role === OfficialUserPrivilegesEnum.Sign ||
    role === OfficialUserPrivilegesEnum.Proceede);
};

export const ProtectedComponent = ({
  allowedRoles,
  licenseTypeAllowed,
  directRepresentativeOnly,
  officialsOnly,
  children,
  matchAnyCondition
}: Props) => {
  const isMatchAnyCondition = matchAnyCondition ?? false;

  if (
    typeof allowedRoles === "undefined" &&
    typeof licenseTypeAllowed === "undefined" &&
    typeof directRepresentativeOnly === "undefined" &&
    typeof officialsOnly === "undefined"
  ) {
    console.error("unmapped usage of component");
  }
  const rolesToCheck = [
    typeof allowedRoles !== "undefined",
    typeof licenseTypeAllowed !== "undefined",
    typeof directRepresentativeOnly !== "undefined",
    typeof officialsOnly !== "undefined"
  ];

  const actualGrantedRoles: boolean[] = [false, false, false, false];

  const allowed = useSelector((state: RootState) => {
    let accessGranted = false;
    const grantedRoles = state.user.userInfo?.roles ?? [];
    if (allowedRoles) {
      actualGrantedRoles[0] = allowedRoles.some((role) =>
        grantedRoles?.includes(role)
      );
    }
    if (licenseTypeAllowed) {
      actualGrantedRoles[1] = licenseTypeAllowed.some((role) =>
        grantedRoles?.includes(role)
      );
    }
    if (directRepresentativeOnly) {
      actualGrantedRoles[2] = isUserBusinessOwner(grantedRoles);
    }
    if (officialsOnly) {
      actualGrantedRoles[3] = isUserOfficial(grantedRoles);
    }

    accessGranted = isMatchAnyCondition
      ? rolesToCheck.some(
          (roleValue, index) => roleValue && actualGrantedRoles[index]
        )
      : rolesToCheck.reduce(
          (prevGrantedRoleCheck, shouldGrantedRoleBeChecked, currentIndex) => {
            return currentIndex === 0
              ? !shouldGrantedRoleBeChecked || actualGrantedRoles[currentIndex]
              : prevGrantedRoleCheck &&
                  (!shouldGrantedRoleBeChecked ||
                    actualGrantedRoles[currentIndex]);
          },
          false
        );

    return accessGranted;
  });

  return !allowed ? null : children;
};
