import React, { useCallback, useState } from "react";
import { FilterColumn, FilterContainer, FilterField } from "../../../../Component/Filter/Filter";
import { FormattedMessage } from "react-intl";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { MultiSelect } from "../../../../Component/Select/MultiSelect";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import { filterActions, FilterItemData } from "../../../Shared/Search/Filters/filterActions";
import {
  InsuranceContractStatus,
} from "../../../../../api_client/medre_api";
import "./InsuranceContractFilters.scss";
import { dateOperatorOptions, dateOperators } from "../../../../Util/RepresentativeUtils";

const STATUSES = [
  {
    value: InsuranceContractStatus.Valid,
    label: "Kehtiv"
  },
  {
    value: InsuranceContractStatus.Expired,
    label: "Lõppenud"
  },
  {
    value: InsuranceContractStatus.Future,
    label: "Tulevikus"
  }
];

export const InsuranceContractFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(filterSelectors.selectInsuranceContractFilters, shallowEqual);

  const [dropdownMenu, setDropdownMenu] = useState<Record<string, any>>({
    periodStartDateOperator: { isOpen: false },
    periodEndDateOperator: { isOpen: false }
  });

  const set = useCallback((key: string, data: FilterItemData) => {
    dispatch(filterActions.setInsuranceContractFilter({ key, data }));
  }, [dispatch]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    set(name, value);
  }, [set]);

  const handleDropdownToggle = (dropdownId: string) =>
    setDropdownMenu((prevState: Record<string, any>) => ({
      ...prevState,
      [dropdownId]: { isOpen: !prevState[dropdownId].isOpen }
    }));

  const handleDateOperatorSelect = useCallback((name, value) => {
    set(name, value);
  }, [set]);

  return (
    <FilterContainer>
      <FilterColumn xl={4}>
        <FilterField
          id="insuranceContractFiltersPolicyHolder"
          label={
            <FormattedMessage
              id="filters.policyHolderLabel"
              defaultMessage="Kindlustusvõtja"
            />
          }
          field={
            <Input
              id="insuranceContractFiltersPolicyHolder"
              className="w-100"
              placeholder="Ettevõtte nimi või registrikood"
              name="policyHolder"
              onChange={handleInputChange}
              value={state.policyHolder}
            />
          }
        />
        <FilterField
          id="insuranceContractFiltersInsurer"
          label={
            <FormattedMessage
              id="filters.insurerLabel"
              defaultMessage="Kindlustusandja"
            />
          }
          field={
            <Input
              id="insuranceContractFiltersInsurer"
              className="w-100"
              placeholder="Ettevõtte nimi või registrikood"
              name="insurer"
              onChange={handleInputChange}
              value={state.insurer}
            />
          }
        />
        <FilterField
          id="insuranceContractFiltersContractNumber"
          label={
            <FormattedMessage
              id="filters.contractNumberLabel"
              defaultMessage="Lepingu number"
            />
          }
          field={
            <Input
              id="insuranceContractFiltersContractNumber"
              className="w-100"
              name="contractNumber"
              onChange={handleInputChange}
              value={state.contractNumber}
            />
          }
        />
        <FilterField
          id="insuranceContractFiltersStatus"
          label={
            <FormattedMessage
              id="filters.statusLabel"
              defaultMessage="Staatus"
            />
          }
          field={
            <MultiSelect
              inputId="insuranceContractFiltersStatus"
              name="status"
              options={STATUSES}
              placeholder={<GeneralFM id="selectPlaceholderAll" />}
              value={state.status}
              handleOptionsChange={(e) => set("status", e)}
            />
          }
        />
      </FilterColumn>
      <FilterColumn xl={4}>
        <FilterField
          id="insuranceContractFiltersPeriodStartDate"
          label={
            <FormattedMessage
              id="filters.periodStartDate"
              defaultMessage="Kehtivuse alguskuupäev"
            />
          }
          field={
            <div className="d-flex date-container">
              <Dropdown
                id="periodStartDateOperator"
                isOpen={dropdownMenu.periodStartDateOperator.isOpen}
                toggle={(): void => handleDropdownToggle("periodStartDateOperator")}
              >
                <DropdownToggle caret size="lg">
                  { dateOperators[state.periodStartDateOperator] }
                </DropdownToggle>
                <DropdownMenu>
                  { dateOperatorOptions.map((operator) => (
                    <DropdownItem
                      key={operator.value}
                      onClick={(): void => handleDateOperatorSelect("periodStartDateOperator", operator.value)}>
                      { operator.label }
                    </DropdownItem>
                  )) }
                </DropdownMenu>
              </Dropdown>
              <DatePickerComponent
                id="insuranceContractFiltersPeriodStartDate"
                onDateChange={(e: Date) => set("periodStartDate", e)}
                selectedDate={state.periodStartDate}
                placeholder="pp.kk.aaaa"
                inputWrapperClass="insurance-search-date"
              />
            </div>
          }
        />
        <FilterField
          id="insuranceContractFiltersPeriodEndtDate"
          label={
            <FormattedMessage
              id="filters.periodEndDate"
              defaultMessage="Kehtivuse lõpukuupäev"
            />
          }
          field={
            <div className="d-flex date-container">
              <Dropdown
                id="periodEndDateOperator"
                isOpen={dropdownMenu.periodEndDateOperator.isOpen}
                toggle={(): void => handleDropdownToggle("periodEndDateOperator")}
              >
                <DropdownToggle caret>
                  { dateOperators[state.periodEndDateOperator] }
                </DropdownToggle>
                <DropdownMenu>
                  { dateOperatorOptions.map((operator) => (
                    <DropdownItem
                      key={operator.value}
                      onClick={(): void => handleDateOperatorSelect("periodEndDateOperator", operator.value)}>
                      { operator.label }
                    </DropdownItem>
                  )) }
                </DropdownMenu>
              </Dropdown>
              <DatePickerComponent
                id="insuranceContractFiltersPeriodEndtDate"
                onDateChange={(e) => set("periodEndDate", e)}
                selectedDate={state.periodEndDate}
                placeholder="pp.kk.aaaa"
                inputWrapperClass="insurance-search-date"
              />
            </div>
          }
        />
        <FilterField
          id="insuranceContractFiltersInsuranceSumFrom"
          label={
            <FormattedMessage
              id="filters.insuranceSumFromLabel"
              defaultMessage="Kindlustussumma alates"
            />
          }
          field={
            <Input
              id="insuranceContractFiltersInsuranceSumFrom"
              className="w-100"
              name="insuranceSumFrom"
              onChange={handleInputChange}
              value={state.insuranceSumFrom}
            />
          }
        />
        <FilterField
          id="insuranceContractFiltersInsuranceSumTo"
          label={
            <FormattedMessage
              id="filters.insuranceSumToLabel"
              defaultMessage="Kindlustussumma kuni"
            />
          }
          field={
            <Input
              id="insuranceContractFiltersInsuranceSumTo"
              className="w-100"
              name="insuranceSumTo"
              onChange={handleInputChange}
              value={state.insuranceSumTo}
            />
          }
        />
      </FilterColumn>
    </FilterContainer>
  );
};