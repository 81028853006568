import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { NoResults } from "../../../Portal/Table/NoResults";
import { ProceedingTable } from "./ProceedingTable";
import {
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityApplicationAPI,
  ActivityLicenseProceeding,
  OfficialUserOfficialTypeEnum,
  OfficialApplication,
} from "../../../../../api_client/medre_api";
import { ProceedingTab } from "./ProceedingTab";
import { ThtProceedingTable } from "./ThtProceedingTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { Option } from "../../../../Component/Select/SelectTypes";

export const MyProceedings = () => {
  const userOfficialType = useSelector((state: RootState) => state.user.userInfo?.officialType);

  const defaultSelectedApplicationStatuses = userOfficialType === OfficialUserOfficialTypeEnum.Tht
    ? [
      { value: "SUBMITTED", label: "Esitatud" },
      { value: "IN_PROCEEDING", label: "Menetluses" },
      { value: "PAUSED", label: "Menetlus peatatud" },
      { value: "REGISTERING", label: "Registreerimisel" },
      { value: "SAVED", label: "Salvestatud" },
      { value: "PAUSED_DUE_TO_APTITUDE_TEST", label: "Sobivustest" },
      { value: "PAUSED_DUE_TO_COMPLIANCE_EXAM", label: "Vastavuseksam" }
    ]
    : [
      { value: "IN_PROCEEDING", label: "Menetluses" },
      { value: "PAUSED_DUE_TO_APTITUDE_TEST", label: "Sobivustest" },
      { value: "PAUSED_DUE_TO_COMPLIANCE_EXAM", label: "Vastavuseksam" }
    ];

  const fromOptionsToStringArray = (options: Option[]): string[] => {
    return options !== null ? options.map((option) => option.value) : [];
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applications, setApplications] = useState<ActivityLicenseProceeding[]>(
    []
  );
  const [currentStatuses, setCurrentStatuses]
    = useState<Option[]>(defaultSelectedApplicationStatuses);
  const [currentStatusesAPI, setCurrentStatusesAPI]
    = useState<string[]>(fromOptionsToStringArray(defaultSelectedApplicationStatuses));
  const [thtApplications, setTHTApplications]
    = useState<OfficialApplication[]>([]);
  const activityLicenseApplicationsTab = "activityLicenseApplications";
  const applicationsTab = "applications";

  const tabs = useMemo<{id: string; value: string; label: string}[]>
  (
    () => [
      {
        id: "activityLicenseApplications",
        value: activityLicenseApplicationsTab,
        label: "Tegevuslubade taotlused"
      },
      {
        id: "applications",
        value: applicationsTab,
        label: "THT taotlused"
      }
    ],
    []
  );

  const [activeTab, setActiveTab] =
    useState(userOfficialType === OfficialUserOfficialTypeEnum.Tht ? tabs[1].value : tabs[0].value);

  const loadProceedings = useCallback((): void => {
    setIsLoading(true);
    officialActivityApplicationAPI(undefined, getBaseUrl(), API)
      .getActivityLicenseProceedings({ withCredentials: true })
      .then((res) => setApplications(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  const loadTHTProceedings = useCallback((): void => {
    setIsLoading(true);
    officialActivityApplicationAPI(undefined, getBaseUrl(), API)
      .getOfficialApplicationProceedings(currentStatusesAPI as string[], { withCredentials: true })
      .then((res) => setTHTApplications(res.data))
      .finally(() => setIsLoading(false));
  }, [currentStatusesAPI]);

  useEffect(() => {
    activeTab === activityLicenseApplicationsTab ? loadProceedings() : loadTHTProceedings();
  }, [activeTab, loadProceedings, loadTHTProceedings, tabs]);

  const renderContent = () => {
    return isLoading ? (
      <Loader />
    ) : (
      <>
        { activeTab === activityLicenseApplicationsTab && (
          !applications ? (
            <NoResults />
          ) : (
            <ProceedingTable data={applications} refresh={loadProceedings} />
          )
        ) }
        { activeTab === applicationsTab && (
          !thtApplications ? (
            <NoResults />
          ) : (
            <ThtProceedingTable data={thtApplications} />
          )
        ) }
      </>
    );
  };

  const handleTabChange = (event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.value;
    setActiveTab(value);
  };
  const handleSearchButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setCurrentStatusesAPI(fromOptionsToStringArray(currentStatuses));
  };

  const handleClearButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setCurrentStatuses(defaultSelectedApplicationStatuses);
  };

  return (
    <div id="my-applications-container">
      <div>
        <h1 className="fw-300">
          <FormattedMessage
            id="myProceedings.title"
            defaultMessage="Minu menetlused"
          />
        </h1>
      </div>
      <ProceedingTab activeTab={activeTab}
        tabs={tabs}
        handleTabChange={handleTabChange}
        handleSearchButton={handleSearchButton}
        handleClearButton={handleClearButton}
        currentStatuses={currentStatuses} setCurrentStatuses={setCurrentStatuses}/>
      { renderContent() }
    </div>
  );
};
