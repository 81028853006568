import React, { useCallback, useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

import { CountrySelect } from "../../../../../Component/Select/CountrySelect";
import { DatePickerComponent } from "../../../../../Component/DatePicker/DatePickerComponent";
import { Option } from "../../../../../Component/Select/SingleSelect";
import { EducationFM } from "../../../../../Messages/EducationFM";
import { SchoolSelect } from "../../../../../Component/Select/SchoolSelect";
import { EducationInfo } from "../../../../../../api_client/medre_api";
import { inputRegex } from "../../../../../Util/inputRegexValidation";
import { dateToString } from "../../../../../Util/DateUtils";
import moment from "moment/moment";

interface Props {
  occupationCode?: string;
  educationInfo?: EducationInfo;
  updateEducationInfo: (educationInfo: EducationInfo) => void;
  updateIsFormValid: (isValid: boolean) => void;
  isCountrySelectDisabled?: boolean;
  isTHTDetailsPage: boolean;
}

class FormValidation {
  isCountryValid?: boolean;
  isSchoolNameValid?: boolean;
  isCurriculumValid?: boolean;
  isDiplomaValid?: boolean;
  isDiplomaDateValid?: boolean;

  constructor(
    isCountryValid?: boolean,
    isSchoolNameValid?: boolean,
    isCurriculumValid?: boolean,
    isDiplomaValid?: boolean,
    isDiplomaDateValid?: boolean
  ) {
    this.isCountryValid = isCountryValid;
    this.isSchoolNameValid = isSchoolNameValid;
    this.isCurriculumValid = isCurriculumValid;
    this.isDiplomaValid = isDiplomaValid;
    this.isDiplomaDateValid = isDiplomaDateValid;
  }
}

export const EducationEditFields = ({
  occupationCode,
  updateEducationInfo,
  updateIsFormValid,
  isCountrySelectDisabled,
  educationInfo,
  isTHTDetailsPage
}: Props) => {
  let educationInfoFromProps = educationInfo;
  const [currentEducationInfo, setCurrentEducationInfo] =
    useState<EducationInfo>(educationInfoFromProps || {});

  let {
    countryCode,
    schoolName,
    curriculumName,
    diplomaNumber,
    diplomaDate
  } = currentEducationInfo;

  const [isFormValid, setIsFormValid] = useState<FormValidation>(
    new FormValidation(
      !!countryCode || undefined,
      !!schoolName || undefined,
      !!curriculumName || undefined,
      !!diplomaNumber || undefined,
      !!diplomaDate || undefined
    )
  );

  const today = new Date();
  const validateDiplomaDate = (diplomaDate): boolean => {
    if(isTHTDetailsPage) {
      return moment(diplomaDate, "YYYY.MM.DD").isBefore(today);
    }
    return !!diplomaDate;
  }

  useEffect(() => {
    const countryCodeValid = countryCode ? !!countryCode.length : undefined;
    const schoolNameValid = schoolName ? !!schoolName.length : undefined;
    const curriculumNameValid = curriculumName
      ? !!curriculumName.length
      : undefined;
    const diplomaNumberValid = diplomaNumber
      ? !!diplomaNumber.length
      : undefined;
    const diplomaDateValid = diplomaDate ? validateDiplomaDate(diplomaDate) : undefined;

    setIsFormValid(
      new FormValidation(
        countryCodeValid,
        schoolNameValid,
        curriculumNameValid,
        diplomaNumberValid,
        diplomaDateValid
      )
    );
  }, [countryCode, schoolName, curriculumName, diplomaNumber, diplomaDate]);

  useEffect(() => {
    updateEducationInfo(currentEducationInfo);
  }, [currentEducationInfo, updateEducationInfo]);

  useEffect(() => {
    const allFieldsValid = Object.values(isFormValid).every(
      (validity) => validity
    );
    updateIsFormValid(allFieldsValid);
  }, [isFormValid, updateIsFormValid]);

  const handleCountryChange = (option: Option) => {
    setCurrentEducationInfo((previousEducation) => {
      return {
        ...previousEducation,
        countryCode: option.value,
        schoolName: undefined
      };
    });
  };

  const handleSchoolChange = useCallback((option: Option) => {
    setCurrentEducationInfo((previousEducation) => {
      return { ...previousEducation, schoolName: option.value };
    });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setCurrentEducationInfo((prevState) => {
      return { ...prevState, [fieldName]: fieldValue };
    });
  };

  const handleEndDateChange = (newDate: Date | undefined) => {
    setCurrentEducationInfo((previousEducation) => {
      return { ...previousEducation, diplomaDate: dateToString(newDate) };
    });
  };

  const renderSchoolSelection = () =>
    currentEducationInfo.countryCode === "EST" ? (
      <SchoolSelect
        inputId="educationEditFieldsSchoolName"
        occupationCode={occupationCode}
        handleOptionChange={handleSchoolChange}
        defaultSelectedValue={schoolName}
      />
    ) : (
      <Input
        id="educationEditFieldsSchoolName"
        name="schoolName"
        value={schoolName || ""}
        onChange={(e) => handleInputChange(inputRegex(e))}
        valid={isFormValid.isSchoolNameValid}
        invalid={isFormValid.isSchoolNameValid === false}
      />
    );

  return (
    <>
      <FormGroup className="form-inline">
        <Label htmlFor="educationEditFieldsCountry">
          <EducationFM id="country" />
        </Label>
        <CountrySelect
          key={`country_code_${countryCode}`}
          inputId="educationEditFieldsCountry"
          disabled={isCountrySelectDisabled}
          defaultSelectedValue={countryCode}
          hideSearchIcon={true}
          handleOptionChange={handleCountryChange}
        />
      </FormGroup>
      <FormGroup className="form-inline">
        <Label htmlFor="educationEditFieldsSchoolName">
          <EducationFM id="schoolName" />
        </Label>
        {renderSchoolSelection()}
      </FormGroup>
      <FormGroup className="form-inline">
        <Label htmlFor="educationEditFieldsCurriculumName">
          <EducationFM id="curriculumName" />
        </Label>
        <Input
          id="educationEditFieldsCurriculumName"
          name="curriculumName"
          value={curriculumName || ""}
          onChange={(e) => handleInputChange(inputRegex(e))}
          valid={isFormValid.isCurriculumValid}
          invalid={isFormValid.isCurriculumValid === false}
        />
      </FormGroup>
      <FormGroup className="form-inline">
        <Label htmlFor="educationEditFieldsDiplomaNumber">
          <EducationFM id="diplomaNumber" />
        </Label>
        <Input
          id="educationEditFieldsDiplomaNumber"
          name="diplomaNumber"
          value={diplomaNumber || ""}
          onChange={(e) => handleInputChange(inputRegex(e))}
          valid={isFormValid.isDiplomaValid}
          invalid={isFormValid.isDiplomaValid === false}
        />
      </FormGroup>
      <FormGroup className="form-inline">
        <Label htmlFor="educationEditFieldsDiplomaDate">
          <EducationFM id="diplomaDate" />
        </Label>
        <DatePickerComponent
          id="educationEditFieldsDiplomaDate"
          selectedDate={diplomaDate}
          onDateChange={handleEndDateChange}
          disableFuture={isTHTDetailsPage}
          valid={isFormValid.isDiplomaDateValid}
          invalid={isFormValid.isDiplomaDateValid === false}
        />
      </FormGroup>
    </>
  );
};
