import React, { SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";

import { LinkButton } from "../../../Component/Button/LinkButton";
import { getBaseUrl, API } from "../../../api";
import { displayAlert } from "../../../Util/AlertUtil";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI } from "../../../../api_client/medre_api";
import { EllipsisLoader } from "../../../Component/Loader/EllipsisLoader";

interface Props {
  id: string;
}

export const ActivityLicenseDownloadButton = ({ id }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const downloadActivityLicense = (event?: SyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    setLoading(true);
    publicActivityPermitAPI(undefined, getBaseUrl(), API)
      .download(id, {
        withCredentials: true,
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => displayAlert("downloadFailed", AlertType.Danger, dispatch))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <EllipsisLoader />;
  }
  return (
    <LinkButton
      id="downloadActivityLicense"
      onClick={downloadActivityLicense}
    />
  );
};
