import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import "../../../Shared/Application/Application.scss";
import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { canAddLocation } from "../../../../Dto/ActivityLicense/ActivityLicense";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { ActivityLicenseState } from "../../../Shared/ActivityLicense/ActivityLicenseState";
import { ActivityLicenseDetailsActions } from "./ActivityLicenseDetailsActions";
import { DetailsCard } from "./DetailsCard";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { DangerFormattedButton } from "../../../../Component/Button/DangerFormattedButton";
import { QuickLinks } from "../../../../Component/QuickLinks/QuickLinks";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { UserInfo } from "../../../../Dto/Security/UserInfo";
import { ApplicationDecisions } from "../../Application/Details/ApplicationDecisions";
import { LocationAccordion } from "./LocationAccordion";
import { ActivityLicenseApplications } from "./ActivityLicenseApplications";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";
import useModal from "../../../../Hook/useModal";
import { InvalidationReasonModal } from "../Invalidation/InvalidationReasonModal";
import { useDetailedApplication } from "./ActivityLicenseApplicationDetailsHook";
import { ConfirmationStickyFooter } from "../../../Footer/ConfirmationStickyFooter";
import { activityLicenseTableActions } from "../../../../ActivityLicense/activityLicenseTableActions";
import { RootState } from "../../../../rootReducer";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ExternalFiles } from "./ExternalFiles";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import {
  TegevuslubadeTeenusApiFactory as activityLicenseAPI,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityAPI,
  ActivityLicenseApplicationDTO,
  FileObjectType,
  DetailedActivityLicense,
  ActivityLicenseRowHeaderStatusEnum,
  OfficialUserPrivilegesEnum,
  GeneralPractitionerListLite,
  GeneralPractitionerList,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI,
  GeneralPractitionerListStatusEnum,
  ActivityLicenseApplicationDTOProceedingTypeEnum,
  VastutuskindlustusteTeenusApiFactory as insuranceContractAPI,
  InsuranceContract,
  InsuranceContractStatus,
  DetailedActivityLicenseStatusEnum,
  LocationWithSuspensionInfo, ActivityLicenseSuspensionInfo
} from "../../../../../api_client/medre_api";
import ActivityLicenseGPList from "../GPList/ActivityLicenseGPList";
import { InsuranceDetailsCard } from "../InsuranceContract/InsuranceDetailsCard";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { getDate } from "../../../../Util/DateUtils";

export const ActivityLicenseDetails = () => {
  const { id } = useParams<{id: string}>();
  const history = useHistory();
  const dispatch = useDispatch();

  const invalidationModal = useModal();

  const { details, setDetails, isLoading, setIsLoading, refresh } =
    useDetailedApplication<DetailedActivityLicense>(
      officialActivityAPI(undefined, getBaseUrl(), API).getActivityLicense2,
      id
    );

  const [locationsSuspensionInfo, setLocationsSuspensionInfo]  = useState<Array<LocationWithSuspensionInfo>>([]);
  const [licenseSuspensionInfo, setLicenseSuspensionInfo]  = useState<ActivityLicenseSuspensionInfo | undefined>(undefined);

  const [applications, setApplications] = useState<
  ActivityLicenseApplicationDTO[]
  >([]);
  const [gpLiteList, setGpLiteList] = useState<
  Array<GeneralPractitionerListLite>
  >([]);
  const [validInsurances, setValidInsurances] = useState<InsuranceContract[]>([]);
  const servicesToBeDiscarded = useSelector(
    (state: RootState) => state.activityLicenseTable.servicesToDiscard
  );
  const { roles } = useSelector(
    (state: RootState) => Object(state.user.userInfo) as UserInfo
  );
  const canEditActivityLicense =
    (roles?.includes(OfficialUserPrivilegesEnum.Proceede) ||
      roles?.includes(OfficialUserPrivilegesEnum.Sign)) ??
    false;

  const quickLinks = useMemo(
    () => {
      const links = [
        {
          id: "actions",
          title: "Tegevused"
        },
        {
          id: "details",
          title: "Tegevusloa detailid"
        },
        {
          id: "insurance",
          title: "Vastutuskindlustus"
        },
        {
          id: "services",
          title: "Tegevuskohad ja teenused"
        },
        {
          id: "applications",
          title: "Taotlused"
        },
        {
          id: "decisions",
          title: "Otsused"
        },
        {
          id: "invalidate",
          title: "Tegevusloa kehtetuks tunnistamine"
        }
      ];

      return links;
    },
    []
  );

  const fetchActivityLicenseSuspensionInfo = (async (licenseId) => {
    const { data } = await officialActivityAPI(
        undefined,
        getBaseUrl(),
        API
    ).getActivityLicenseInDepthSuspensionInfo(licenseId);
    setLicenseSuspensionInfo(data.activityLicenseSuspensionsInfo);
    setLocationsSuspensionInfo([...data.locationsWithSuspensionInfo]);
  });

  const fetchRelatedGpLiteList = useCallback(async (licenseCode) => {
    const { data } = await officialFamilyListAPI(
      undefined,
      getBaseUrl(),
      API
    ).getAllByLicenseCode(licenseCode, GeneralPractitionerListStatusEnum.Register);
    setGpLiteList(data);
  }, []);

  const getActivityLicenseApplication = (): void => {
    if (!details) {
      return;
    }

    const activityLicenseApplication = details!.applications?.find(application =>
      application.proceedingType === ActivityLicenseApplicationDTOProceedingTypeEnum.ActivityLicense);

    if (!activityLicenseApplication) {
      return;
    }

    setApplications((prevApplications) => {
      const application: ActivityLicenseApplicationDTO = {
        id: activityLicenseApplication.id,
        arrivalTime: activityLicenseApplication.arrivalTime,
        applicationNumber: activityLicenseApplication.applicationNumber,
        proceedingType: activityLicenseApplication.proceedingType,
        status: activityLicenseApplication.currentStatus ? activityLicenseApplication.currentStatus.status : undefined,
        stateFeeIsPaid: activityLicenseApplication.stateFee ? activityLicenseApplication.stateFee.paid : undefined,
      };

      return [...prevApplications, application];
    });
  };

  const getValidInsurances = (): void => {
    if (!details || details.status === DetailedActivityLicenseStatusEnum.Invalid) {
      return;
    }

    insuranceContractAPI(undefined, getBaseUrl(), API)
      .searchInsuranceContracts(
        {
          policyHolderId: details.company?.id,
          status: [InsuranceContractStatus.Valid],
          size: 10,
        },
        {
          withCredentials: true
        }).then((r) => {
        setValidInsurances(r.data.content ?? []);
      });
  };

  useEffect(() => {
    if (details && !isLoading) {
      getRelatedApplications();
      fetchRelatedGpLiteList(details.number);
      getActivityLicenseApplication();
      getValidInsurances();
      fetchActivityLicenseSuspensionInfo(details.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, isLoading]);

  const submitDiscardApplication = (): void => {
    setIsLoading(true);
    activityLicenseAPI(undefined, getBaseUrl(), API)
      .submitDiscardServices(servicesToBeDiscarded, { withCredentials: true })
      .catch((error) => {
        const responseData = error.response?.data;
        if (
          responseData.error === "MedreRuntimeException" &&
          responseData.message.startsWith(
            "Cannot discard all services from location"
          )
        ) {
          displayAlert("cannotDiscardAllServices", AlertType.Danger, dispatch);
        } else {
          displayAlert("requestFailed", AlertType.Danger, dispatch);
        }
      })
      .finally(() => {
        dispatch(activityLicenseTableActions.setServicesToDiscard([]));
        setIsLoading(false);
      });
  };

  const handleAddLocation = (): void => {
    dispatch(
      activityLicenseApplicationActions.setInitialActivityLocationApplication(
        details!
      )
    );
    history.push("/new-activity-location");
  };

  const sortByArrivalTimeDescending = (a, b): number =>
    new Date(b.arrivalTime).getTime() - new Date(a.arrivalTime).getTime();

  const getRelatedApplications = (): void => {
    if (!details) {
      return;
    }

    officialActivityAPI(undefined, getBaseUrl(), API)
      .getActivityLicenseApplicationDTOs(id, { withCredentials: true })
      .then((r) => {
        if (r.data.length === 0) {
          return;
        }

        setApplications((prevApplications) => {
          prevApplications = [...prevApplications, ...r.data];
          prevApplications.sort(sortByArrivalTimeDescending);

          return prevApplications;
        });
      });
  };

  if (!details) {
    return <Loader />;
  }
  if (isLoading) {
    return <Loader backdrop />;
  }

  const isPausedAndValid = licenseSuspensionInfo?.licenseStatus === "PAUSED" &&
    getDate(new Date(licenseSuspensionInfo.lastLicenseSuspensionEndDate)) >= getDate(new Date());

  return (
    <div className="application-container activity-license-details-container">
      <div className="d-flex align-items-center mx-3 mb-1">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ActivityLicenseDetails.header"
            defaultMessage="Tegevusloa {number} detailvaade"
            values={{ number: details.number }}
          />
        </h1>
        { licenseSuspensionInfo
          ? <ActivityLicenseState
              status={isPausedAndValid ? "PAUSED" : details.status}
              values={isPausedAndValid ? {date: formatDate(licenseSuspensionInfo.lastLicenseSuspensionEndDate)} : undefined}
            />
          : <ActivityLicenseState status={details.status} />
        }
      </div>
      <p className="mx-3 mb-4">
        <span className="mr-3">
          Väljaandmise kuupäev:{ " " }
          { details.createdAt
            ? moment(details.createdAt).format("DD.MM.YYYY")
            : "-" }
        </span>
        <span>
          Kehtivusaeg:{ " " }
          { details.validUntil
            ? moment(details.validUntil).format("DD.MM.YYYY")
            : "Tähtajatu" }
        </span>
      </p>
      <div className="ml-3 activity-license-quick-links">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <ActivityLicenseDetailsActions license={details} activityLicenseSuspensionInfo={licenseSuspensionInfo} />
      </Card>
      <DetailsCard details={details} />
      <InsuranceDetailsCard id="insurance" insurances={validInsurances} company={details.company} />
      <Card className="mx-3 my-4 p-4">
        <h2 className="mb-4 location-services-title">
          <span id="services">Tegevuskohad ja teenused</span>
          { canAddLocation(details) && (
            <ProtectedComponent
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              <SecondaryFormattedButton
                id="addLocation"
                onClick={handleAddLocation}
              />
            </ProtectedComponent>
          ) }
        </h2>
        <div className="service-details-table-container">
          <LocationAccordion
            activityLocations={details.locations || []}
            locationsSuspensionInfo={locationsSuspensionInfo}
            isHospital={details?.businessArea?.isHospital!}
            isEditable={
              details.status !== ActivityLicenseRowHeaderStatusEnum.Invalid &&
              canEditActivityLicense
            }
            refresh={refresh}
            activityLicense={details}
            onLocationSuspensionSubmit={() => fetchActivityLicenseSuspensionInfo(details?.id)}
            onServiceSuspensionSubmit={() => fetchActivityLicenseSuspensionInfo(details?.id)}
          />
          { servicesToBeDiscarded && servicesToBeDiscarded.length > 0 && (
            <ConfirmationStickyFooter
              handleConfirm={submitDiscardApplication}
              handleDiscard={() =>
                dispatch(activityLicenseTableActions.setServicesToDiscard([]))
              }
              saveMessageId="sendChangesToProceeding"
            />
          ) }
        </div>
      </Card>
      { details.businessArea?.id === "d8d7a6b4-08b8-4ed6-b7f8-52618165023f" && (
        <Card className="mx-3 my-4 p-4">
          <h2>
            <FormattedMessage
              id="activityLicense.GPList.title"
              defaultMessage="Nimistud"
            />
          </h2>
          { !!gpLiteList.length && (
            <ActivityLicenseGPList
              data={gpLiteList as GeneralPractitionerList[]}
            />
          ) }
        </Card>
      ) }
      <Card className="mx-3 my-4 p-4">
        <h2 className="mb-4">
          <span id="applications" />
          Taotlused
        </h2>
        { applications ? (
          <ActivityLicenseApplications applications={applications} />
        ) : (
          <Loader />
        ) }
      </Card>
      <Card className="mx-3 my-4 p-4">
        <h2 className="mb-4">
          <span id="decisions" />
          Otsused
        </h2>
        <ApplicationDecisions decisions={details.decisions || []} />
      </Card>
      <Card className="mx-3 my-4 p-4">
        <ExternalFiles
          files={details.externalFiles || []}
          licenseId={details.id as string}
          setLicense={setDetails}
        />
      </Card>
      <Card className="mx-3 my-4 p-4">
        <h2 className="mb-4">
          <span id="invalidate" />
          <FormattedMessage
            id="activityLicense.submitted.invalidation.title"
            defaultMessage="Tegevusloa kehtetuks tunnistamine"
          />
        </h2>
        { details.status === ActivityLicenseRowHeaderStatusEnum.Valid || details.status === ActivityLicenseRowHeaderStatusEnum.Paused ? (
          !details?.invalidationApplicationId ? (
            <ProtectedFieldWrapper
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <DangerFormattedButton
                id="invalidateActivityLicense"
                className="invalidate-button"
                onClick={invalidationModal.handleOpen}
              />
              <InvalidationReasonModal
                isOpen={invalidationModal.isOpen}
                title={`Olete kindel, et soovite tegevusloa ${ details.number! } kehtetuks tunnistada?`}
                objectId={details?.id!}
                endpoint={
                  officialActivityAPI(undefined, getBaseUrl(), API)
                    .createActivityLicenseInvalidation1
                }
                fileObjectType={
                  FileObjectType.ActivityLicenseInvalidationDocument
                }
                onClose={invalidationModal.handleClose}
                onSubmitSuccess={refresh}
              />
            </ProtectedFieldWrapper>
          ) : (
            <FormattedMessage
              id="activityLicense.submitted.invalidation.text"
              defaultMessage="Tegevusloale on kehtetuks tunnistamise taotlus esitatud."
            />
          )
        ) : null }
      </Card>
    </div>
  );
};
