import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import React from "react";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import "./InsuranceContractsAccordion.scss";
import { InsuranceContractsTable } from "./InsuranceContractsTable";
import { FormattedToolTip } from "../../../../Component/ToolTip/FormattedToolTip";
import info_icon from "../../../../../assets/images/information.svg";
import { FormattedMessage } from "react-intl";
import { SearchInsuranceContract } from "../../../Shared/Search/Filters/FilterTypes";

interface Props {
  isOpen: boolean;
  handleClick: () => void;
  data: SearchInsuranceContract[];
  pageCount: number;
  pageIndex: number;
}

export const InsuranceContractsAccordion = ({
  isOpen,
  handleClick,
  data,
  pageCount,
  pageIndex,
}: Props) => {
  const className = isOpen ? "expanded" : "";
  const widthSize = useWindowWidthSize();
  const renderRow = (icon: JSX.Element) => {
    return renderAccordionHeaderRow(
      widthSize,
      icon,
      <div style={{ display: "flex" }}>
        <GeneralFM id={"insuranceContractData"} />
        <FormattedToolTip
          placement={"top-start"}
          target={"insurance-info-icon"}
          message={
            <FormattedMessage
              id="insuranceContractInfo.tooltip-content"
              defaultMessage="Siin on kuvatud antud asutuse kehtivad, tulevikus kehtima hakkavad ja lõppenud kindlustuslepingud"
            />
          }
          style={{
            backgroundColor: "white",
            border: "1px solid #D2D3D8",
            borderRadius: "0",
            color: "black",
            maxWidth: "300px",
            padding: "10px 10px",
            textAlign: "left"
          }}
        />
        <img
          alt="info-ikoon"
          src={info_icon}
          id="insurance-info-icon"
          className="insurance-info-icon"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <InsuranceContractsTable
        data={data}
        pageCount={pageCount}
        pageIndex={pageIndex}
      />
    );
  };

  return isOpen ? (
    <>
      <div
        className={`${ className } cursor-pointer insurance-table-header`}
        onClick={(): void => handleClick()}
      >
        { renderRow(<MinusIcon />) }
      </div>
      { renderContent() }
    </>
  ) : (
    <div
      className={`${ className } cursor-pointer insurance-table-header`}
      onClick={(): void => handleClick()}
    >
      { renderRow(<PlusIcon />) }
    </div>
  );
};