import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./Security.scss";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { userActions } from "./userActions";
import { keycloakLogout } from "./TokenHelpers";
import { RootState } from "../rootReducer";
import { useHistory } from "react-router-dom";
import { SecondaryFormattedButton } from "../Component/Button/SecondaryFormattedButton";

export const SessionExpiredModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const clientId = useSelector((state: RootState) => state.config.clientId);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.logout());
    keycloakLogout(loading, "" + clientId, setLoading, dispatch, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader>
        <FormattedMessage
          id="sessionExpiredModal.header"
          defaultMessage="Sessioon on aegunud"
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="sessionExpiredModal.body"
          defaultMessage="Sinu sessioon on aegunud ja oled automaatselt välja logitud."
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="close"
          onClick={handleModalClose}
        />
      </ModalFooter>
    </Modal>
  );
};
