import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "./ApplicationDetailsCardTable.scss";
import { useDetailedApplication } from "./ApplicationDetailsHook";
import { applicationChannelTypes } from "../../../../Dto/Application/Application";
import { Loader } from "../../../../Component/Loader/Loader";
import { ApplicationDetailsActions } from "./ApplicationDetailsActions";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { AttachmentFileRow } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentFileRow";
import { API, getBaseUrl } from "../../../../api";
import { ApplicationDetailsPayment } from "./ApplicationDetailsPayment";
import { ApplicationDecisions } from "./ApplicationDecisions";
import { ApplicationNotes } from "./ApplicationNotes";
import { ApplicationDetailsHistory } from "./ApplicationDetailsHistory";
import { ApplicationDetailsHeader } from "./ApplicationDetailsHeader";
import { QuickLinks } from "../../../../Component/QuickLinks/QuickLinks";
import {
  Application,
  DetailedApplication,
  ApplicationDraft,
  DetailedApplicationTypeEnum,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  KasutajaAndmeteTeenusApiFactory as userDataAPI, OfficialUserPrivilegesEnum, FileObjectType
} from "../../../../../api_client/medre_api";
import { getFormattedCountryMessage } from "../../../../Component/Translate/CountryTranslator";
import { RootState } from "../../../../rootReducer";
import { personActions } from "../../../../Person/personActions";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { ClickableInfoValue } from "../../../../Component/QuickLinks/ClickableInfoValue";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { AdditionalDocumentsModal } from "./AdditionalDocumentsModal";

export const ApplicationDetails = () => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const isDraft = pathname.includes("drafts");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdditionalDocumentsModalOpen, setIsAdditionalDocumentsModalOpen] = useState(false);

  const { details, setDetails, refreshApplication } = useDetailedApplication<
  Application | DetailedApplication
  >(
    isDraft
      ? officialAppDataAPI(undefined, undefined, API).getApplicationDraft
      : officialAppDataAPI(undefined, undefined, API).getApplication,
    id
  );
  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      ...(!isDraft
        ? [
          {
            id: "history",
            title: "Menetluse ajalugu"
          },
          {
            id: "resolutions",
            title: "Otsused"
          }
        ]
        : [])
    ],
    [isDraft]
  );
  const person = useSelector((state: RootState) => state.person, shallowEqual);

  useEffect(() => {
    if (details?.personId) {
      userDataAPI(undefined, getBaseUrl(), API)
        .getPerson1(details.personId, {
          withCredentials: true
        })
        .then((res) => {
          dispatch(personActions.setPerson(res.data));
          if (isDraft) {
            dispatch(
              applicationDraftActions.updateApplicationDraft(
                "personalData",
                res.data
              )
            );
          }
        });
    }
  }, [details?.personId]);

  const handleInfoClick = () => {
    history.push(`/persons/${person.id}`);
  };

  if (!details || !person) {
    return <Loader />;
  }

  const displayAdditionalDocumentsCard = () =>
    (details.type === DetailedApplicationTypeEnum.Occupation || details.type === DetailedApplicationTypeEnum.Specialization) &&
    (details.currentStatus?.status === "ACCEPTED" || details.currentStatus?.status === "FINISHED");

  return (
    <div className="application-container application-detail">
      <ApplicationDetailsHeader application={details} isDraft={isDraft} />
      <div className="ml-3">
        <QuickLinks links={quickLinks} />
      </div>

      {isLoading && <Loader backdrop />}
      <Card className="mx-3 my-4 p-4">
        <ApplicationDetailsActions
          updateApplication={setDetails}
          setIsLoading={setIsLoading}
          isDraft={isDraft}
          isStateFeePaid={
            isDraft ? false : (details as Application).stateFee!.paid
          }
          applicationId={details.id!}
          applicationNumber={details.applicationNumber!}
          applicationDraft={isDraft ? (details as ApplicationDraft) : undefined}
          application={!isDraft ? (details as Application) : undefined}
          status={
            ("currentStatus" in details && details.currentStatus?.status) ||
            undefined
          }
          personId={person.id}
          refreshApplication={refreshApplication}
        />
      </Card>

      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="details" /> Taotluse detailid
        </h2>

        <ApplicationDetailsInfoCard
          title="Isikuinfo"
          content={[
            {
              key: "Eesnimi",
              valueClassName: "text-capitalize",
              value: person.firstName?.toLowerCase()
            },
            {
              key: "Perenimi",
              valueClassName: "text-capitalize",
              value: person.lastName?.toLowerCase()
            },
            {
              key: "Isikukood",
              value: (
                <ClickableInfoValue
                  name={person.idCode || ""}
                  onClick={handleInfoClick}
                />
              )
            },
            {
              key: "Sünniaeg",
              value: person.dateOfBirth
                ? moment(person.dateOfBirth).format("DD.MM.YYYY")
                : "-"
            },
            {
              key: "Sugu",
              value: person.gender
                ? person.gender === "MALE"
                  ? "Mees"
                  : "Naine"
                : "-"
            },
            {
              key: "Kodakondsus",
              value: person.citizenship ? (
                <FormattedMessage id={`country.${person.citizenship}`} />
              ) : (
                "-"
              )
            }
          ]}
        />
        <ApplicationDetailsInfoCard
          title="Kontaktinfo"
          content={[
            { key: "E-mail", value: person.contactInfo?.email || "-" },
            {
              key: "Telefon",
              value: person.contactInfo?.phone
                ? `${person.contactInfo?.dialCode} ${person.contactInfo.phone}`
                : "-"
            },
            {
              key: "Aadress",
              value: person.contactInfo?.address?.fullAddress || "-"
            }
          ]}
        />
        {details.type === DetailedApplicationTypeEnum.Occupation && (
          <ApplicationDetailsInfoCard
            title="Kutse"
            content={[
              { key: "Kutse nimetus", value: details.occupation?.name ?? "-" }
            ]}
          />
        )}
        {details.type === DetailedApplicationTypeEnum.Specialization && (
          <ApplicationDetailsInfoCard
            title="Eriala"
            content={[
              { key: "Eriala nimetus", value: details.speciality?.name ?? "-" }
            ]}
          />
        )}
        <ApplicationDetailsInfoCard
          title="Hariduse info"
          content={[
            {
              key: "Õppeasutus",
              value: details.educationInfo?.schoolName || "-"
            },
            {
              key: "Õppeasutuse riik",
              value:
                getFormattedCountryMessage(
                  details.educationInfo?.countryCode
                ) || "-"
            },
            {
              key: "Õppekava",
              value: details.educationInfo?.curriculumName || "-"
            },
            {
              key: "Õppimise lõpp",
              value: details.educationInfo?.diplomaDate
                ? moment(details.educationInfo.diplomaDate).format("DD.MM.YYYY")
                : "-"
            },
            {
              key: "Tunnistuse/diplomi number",
              value: details.educationInfo?.diplomaNumber || "-"
            },
            {
              key: "Lisatud failid",
              value: details.fileReferences?.filter(f =>
                f.fileObjectType !== FileObjectType.AdditionalDocument).map((file, i) => {
                  return (
                    <div key={i}>
                      <AttachmentFileRow {...file} isDeletingPossible={false} />
                    </div>
                  );
                })
            }
          ]}
        />
        {displayAdditionalDocumentsCard() &&
            <ProtectedComponent
                allowedRoles={[
                  OfficialUserPrivilegesEnum.Proceede,
                  OfficialUserPrivilegesEnum.Sign,
                  OfficialUserPrivilegesEnum.Observe
                ]}
            >
                <ApplicationDetailsInfoCard
                    title={
                      <>
                        <span>Täiendavad dokumendid</span>
                        <ProtectedComponent
                          allowedRoles={[
                            OfficialUserPrivilegesEnum.Proceede,
                            OfficialUserPrivilegesEnum.Sign
                          ]}
                        >
                          <LinkButton
                            id="addOrEditDocuments"
                            onClick={() => setIsAdditionalDocumentsModalOpen(true)}
                            className='pr-0'
                          />
                        </ProtectedComponent>
                      </>
                    }
                    content={[{
                      key: "Dokumendid",
                      value: details.fileReferences?.filter(f =>
                        f.fileObjectType === FileObjectType.AdditionalDocument).map((file, i) => {
                        return (
                          <div key={i}>
                            <AttachmentFileRow {...file} isDeletingPossible={false}/>
                          </div>
                        );
                      })
                    }]}
                />
                <AdditionalDocumentsModal
                    isOpen={isAdditionalDocumentsModalOpen}
                    onClose={() => setIsAdditionalDocumentsModalOpen(false)}
                    fileReferences={details.fileReferences ? details.fileReferences : []}
                    applicationId={details.id!}
                    refreshApplication={refreshApplication}
                />
            </ProtectedComponent>}
        <ApplicationDetailsInfoCard
          title="Taotluse saabumise kanal"
          content={[
            {
              key: "Kanal",
              value: details.channelType ? (
                <FormattedMessage
                  {...applicationChannelTypes[details.channelType]}
                />
              ) : (
                "-"
              )
            }
          ]}
        />

        <ApplicationDetailsPayment
          application={details}
          manualPaymentApiRequest={() =>
            officialAppDataAPI(undefined, undefined, API).markAsManuallyPaid1(
              details.id!,
              {
                withCredentials: true
              }
            )
          }
          updateApplication={setDetails}
        />
      </Card>

      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="resolutions" /> Otsused
          {!isDraft && (
            <ApplicationDecisions decisions={details.decisions || []} />
          )}
        </h2>
      </Card>

      {!isDraft && (
        <>
          <ApplicationNotes<DetailedApplication>
            notes={!!details.notes ? details.notes : ""}
            setDetails={setDetails}
            endpoint={
              officialAppDataAPI(undefined, undefined, API)
                .updateApplicationNote1
            }
            detailsId={details.id}
          />
          <ApplicationDetailsHistory
            application={details as DetailedApplication}
          />
        </>
      )}
    </div>
  );
};
