import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { RootState } from "../../../../rootReducer";
import { ActivityLicenseRestorationAcceptanceModal } from "../Restoration/ActivityLicenseRestorationAcceptanceModal";
import { ActivityLicenseGPListApplicationModal } from "../GPList/ActivityLicenseGPListApplicationModal";
import { ActivityLicenseSuspensionInfo, FileObjectType } from "../../../../../api_client/medre_api";
import {
  ActivityLicense,
  ActivityLicenseRowHeaderStatusEnum,
  OfficialUserPrivilegesEnum,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitAppAPI,
  AmetnikuTegevuslubadeTaastamiseTeenusApiFactory as officialActivityLicenseAPI
} from "../../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { getBaseUrl, API } from "../../../../api";

interface Props {
  license: ActivityLicense;
  activityLicenseSuspensionInfo?: ActivityLicenseSuspensionInfo;
}

const GENERAL_PRACTITIONER_BUSINESS_AREA_ID =
  "d8d7a6b4-08b8-4ed6-b7f8-52618165023f";

export const ActivityLicenseDetailsActions = ({ license /*, activityLicenseSuspensionInfo */ }: Props) => {
  // const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);
  const [
    isRestorationAcceptanceModalOpen,
    setIsRestorationAcceptanceModalOpen
  ] = useState(false);
  const [isChangeGPListNumberModalOpen, setIsChangeGPListNumberModalOpen] =
    useState(false);
  useSelector(
      (state: RootState) => state.config.maxSuspensionDuration
  );
  const buttonClasses = "mr-4 mt-3";

  const isGeneralPractitioner = useMemo(
    () => license.businessArea?.id === GENERAL_PRACTITIONER_BUSINESS_AREA_ID,
    [license.businessArea?.id]
  );

  const renderActionButtons = () => {
    let numberOfGpLists;
    if (
      license?.locations &&
      license.locations.length > 0 &&
      license.locations[0].services &&
      license.locations[0].services.length > 0
    ) {
      numberOfGpLists = license.locations[0].services[0].numberOfGpLists;
    }

    return (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        {/* Pause activity license should not be available on official side. Decision was made to comment button out */}
        {/*{(*/}
        {/*    activityLicenseSuspensionInfo?.licenseSuspensionInProceeding !== true &&*/}
        {/*  <ProtectedComponent directRepresentativeOnly>*/}
        {/*    <SecondaryFormattedButton*/}
        {/*      id="suspendActivityLicense"*/}
        {/*      className={buttonClasses}*/}
        {/*      onClick={() => setIsSuspensionModalOpen(true)}*/}
        {/*    />*/}
        {/*  </ProtectedComponent>*/}
        {/*) }*/}
        { license.status === ActivityLicenseRowHeaderStatusEnum.Paused && (
          <>
            <ProtectedComponent directRepresentativeOnly>
              <SecondaryFormattedButton
                id="acceptActivityLicenseRestoration"
                className={buttonClasses}
                onClick={() => setIsRestorationAcceptanceModalOpen(true)}
              />
            </ProtectedComponent>
          </>
        ) }
        { isGeneralPractitioner && license.status !== ActivityLicenseRowHeaderStatusEnum.Paused && (
          <>
            <SecondaryFormattedButton
              id="changeGPListNumber"
              className={buttonClasses}
              onClick={() => setIsChangeGPListNumberModalOpen(true)}
            />
          </>
        ) }
        {/* Pause activity license should not be available on official side. Decision was made to comment button out */}
        {/*<ActivityLicenseSuspensionModal*/}
        {/*  isOpen={isSuspensionModalOpen}*/}
        {/*  onClose={() => setIsSuspensionModalOpen(false)}*/}
        {/*  activityLicense={license}*/}
        {/*  activityLicenseSuspensionInfo={activityLicenseSuspensionInfo}*/}
        {/*/>*/}
        <ActivityLicenseRestorationAcceptanceModal
          isOpen={isRestorationAcceptanceModalOpen}
          onClose={() => setIsRestorationAcceptanceModalOpen(false)}
          objectId={license.id as string}
          type={FileObjectType.ActivityLicenseOnSiteInspectionProtocol}
          modalTitle="Taasta tegevusluba"
          endpoint={
            officialActivityLicenseAPI(undefined, getBaseUrl(), API)
              .initiateActivityLicenseRestoration
          }
        />
        <ActivityLicenseGPListApplicationModal
          isOpen={isChangeGPListNumberModalOpen}
          onClose={() => setIsChangeGPListNumberModalOpen(false)}
          licenseId={license.id as string}
          numberOfGpLists={numberOfGpLists}
          endpoint={
            officialActivityPermitAppAPI(undefined, getBaseUrl(), API)
              .createGeneralPractitionerListApplication
          }
        />
      </ProtectedFieldWrapper>
    );
  };

  return (
    <>
      <div className="mt-1 mb-4">
        { license.status !== "INVALID" && renderActionButtons() }
      </div>
    </>
  );
};
