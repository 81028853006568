import React, { useState } from "react";
import { Header } from "../Header/Header";
import "../MainContainer.scss";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { OfficialLoginHeader } from "./Header/OfficialLoginHeader";
import { OfficialContentContainer } from "./ContentContainer/OfficialContentContainer";
import { Footer } from "../Footer/Footer";
import { OfficialSideMenu } from "./SideMenu/OfficialSideMenu";
import { RootState } from "../../rootReducer";

interface Props {
    headerColor: string;
}

export const OfficialMainContainer = ({
   headerColor
}: Props) => {
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(true);

    const toggleSideMenuCollapse = () => setIsSideMenuCollapsed(!isSideMenuCollapsed);

    const sideMenuClass = isSideMenuCollapsed ? " collapsed" : " expanded";
    const hideSideMenuInMobileView = isSideMenuCollapsed ? " hide-1200" : "";
    const hideElementsAndShowOnlyMenu = isSideMenuCollapsed ? "" : " hide-1100";
    const expandMiddleRow = isSideMenuCollapsed ? "" : " expand-middle-row";

    return (
        <div className={"main-container"}>
            <Row className={"header-row m-0"}>
                {userInfo
                    ? (
                        <Header
                            onClick={toggleSideMenuCollapse}
                            isSideMenuCollapsed={isSideMenuCollapsed}
                            hasLanguageDropdown={false}
                            headerColor={headerColor}
                        />
                    )
                    : <OfficialLoginHeader
                        headerColor={headerColor}
                    />}
            </Row>
            <Row className={"middle-row m-0" + expandMiddleRow}>
                {userInfo && (
                    <div className={"side-menu-column p-0" + hideSideMenuInMobileView + sideMenuClass}>
                        <OfficialSideMenu isSideMenuCollapsed={isSideMenuCollapsed}/>
                    </div>
                )}
                <Col className={"content-column p-0" + hideElementsAndShowOnlyMenu}>
                    <OfficialContentContainer/>
                </Col>
            </Row>
            <Row className={"footer-row m-0" + hideElementsAndShowOnlyMenu}>
                <Footer/>
            </Row>
        </div>
    );
};
