import {
  Application,
  ApplicationTypeEnum,
  GeneralPractitioner,
  Occupation,
  OccupationCode,
  Person,
  PersonalData,
  PersonalDataGenderEnum,
  PersonalDataStatusEnum,
  Speciality,
  ProceedingStatusStatusEnum,
  ApplicationCertificateDeliveryTypeEnum,
  ActivityLocation
} from "../../api_client/medre_api";
import { Option } from "../Component/Select/SingleSelect";

interface PersonFullNameProps {
  firstName?: string;
  lastName?: string;
}
export const getFullName = ({
  firstName,
  lastName
}: PersonFullNameProps | GeneralPractitioner) =>
  firstName || lastName ? `${firstName} ${lastName}` : "";

const getControlNumber = (code: string) => {
  const multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
  const multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

  let mod = 0;
  let total = 0;

  for (let i = 0; i < 10; ++i) {
    total += Number(code.charAt(i)) * multiplier1[i];
  }

  mod = total % 11;
  total = 0;

  if (mod === 10) {
    for (let i = 0; i < 10; ++i) {
      total += Number(code.charAt(i)) * multiplier2[i];
    }
    mod = total % 11;

    if (mod === 10) {
      mod = 0;
    }
  }

  return mod;
};

const getBirthday = (code: string) => {
  let year = parseInt(code.substring(1, 3));
  const month = parseInt(code.substring(3, 5).replace(/^0/, "")) - 1;
  const day = parseInt(code.substring(5, 7).replace(/^0/, ""));
  const firstNumber = code.charAt(0);

  if (firstNumber === "1" || firstNumber === "2") {
    year += 1800;
  } else if (firstNumber === "3" || firstNumber === "4") {
    year += 1900;
  } else if (firstNumber === "5" || firstNumber === "6") {
    year += 2e3;
  } else if (firstNumber === "7" || firstNumber === "8") {
    year += 2100;
  }

  return new Date(year, month, day);
};

export const validateCode = (code: string) => {
  if (!code) {
    return false;
  }

  if (code.charAt(0) === "0") {
    return false;
  }

  if (code.length !== 11) {
    return false;
  }

  const control = getControlNumber(code);

  if (control !== parseInt(code.charAt(10))) {
    return false;
  }

  const year = Number(code.substring(1, 3));
  const month = Number(code.substring(3, 5));
  const day = Number(code.substring(5, 7));
  const birthDate = getBirthday(code);

  return (
    year === birthDate.getFullYear() % 100 &&
    birthDate.getMonth() + 1 === month &&
    day === birthDate.getDate()
  );
};

const personalData: PersonalDataType = {
  idCode: "",
  foreignIdCode: "",
  foreignIdCodeCountryId: "",
  firstName: "",
  lastName: "",
  foreigner: false,
  citizenship: "",
  gender: PersonalDataGenderEnum.Male,
  dateOfBirth: "",
  status: PersonalDataStatusEnum.Registered
};

interface PersonType extends Person {
  [key: string]: any;
}

interface PersonalDataType extends PersonalData {
  [key: string]: any;
}

export const getPersonalData = (person: PersonType) => {
  const newPersonalData = { ...personalData };
  for (const key in newPersonalData) {
    newPersonalData[key] = person[key];
  }
  return newPersonalData;
};

export const getFilteredOccupations = (occupations: Occupation[]) =>
  occupations.filter(
    (occupation) => occupation.code !== "P100" && occupation.code !== "F100"
  );

export const getCompetencieSpecialities = (
  filteredOccupations: Occupation[],
  personOccupations: OccupationCode[] = []
) => {
  const competencieSpecialities: Option[] = [];

  personOccupations?.forEach((occupation) => {
    const allowedOcupation = filteredOccupations.find(
      (filteredOccupation) => filteredOccupation.name === occupation.name
    );

    if (allowedOcupation) {
      competencieSpecialities.push({
        label: allowedOcupation.name,
        value: allowedOcupation.code
      });
    }
  });

  return competencieSpecialities;
};

export const getPersonSpecialitiesByNameAndCode = (
  specialities?: Speciality[]
) => {
  return specialities?.map((speciality) => ({
    label: speciality.name,
    value: speciality.code
  }));
};

export const getPersonAdditionalSpecialities = (
  submittedApplications?: Application[]
) => {
  const additionalSpecialities: Option[] = [];
  submittedApplications?.forEach((application) => {
    if (personAdditionalSpecialityExists(application)) {
      additionalSpecialities.push({
        label: application.speciality?.name as string,
        value: application.speciality?.code as string
      });
    }
  });
  return additionalSpecialities;
};

const personAdditionalSpecialityExists = (application?: Application) =>
  application?.type === ApplicationTypeEnum.Specialization &&
  (application.currentStatus?.status ===
    ProceedingStatusStatusEnum.InProceeding ||
    application.currentStatus?.status === ProceedingStatusStatusEnum.Paused ||
    application.currentStatus?.status ===
      ProceedingStatusStatusEnum.UploadAcceptedDecision ||
    application.currentStatus?.status ===
      ProceedingStatusStatusEnum.SignAcceptedDecision);

export const CERTIFICATE_DEFAULT_ISSUANCE_ADDRESS =
  "Paldiski mnt 81, 10614 Tallinn";


interface ActivityLocationDto extends ActivityLocation {
  apartmentNumber?: string;
}

export const getCertificateIssuanceAddress = (
  location: ActivityLocationDto,
  deliveryType: ApplicationCertificateDeliveryTypeEnum = ApplicationCertificateDeliveryTypeEnum.Electronic
) => {
  switch (deliveryType) {
    case ApplicationCertificateDeliveryTypeEnum.TakeMyself:
      return CERTIFICATE_DEFAULT_ISSUANCE_ADDRESS;
    case ApplicationCertificateDeliveryTypeEnum.Post:
      return (
        location.fullAddress! +
        (location?.apartmentNumber ? `-${location?.apartmentNumber}` : "") +
        (location.zipCode ? `, ${location.zipCode}` : "")
      );
    default:
      return null;
  }
}

export const isValidNurseCode = (code: string) => /^N\d+$/g.test(code);

export const getCheckedNurseCode = (code: string) => {
  if (isValidNurseCode(code)) {
    return code;
  }

  const pastedCode = code.slice(1);
  return isValidNurseCode(pastedCode) ? pastedCode : "";
};