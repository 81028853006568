import React, { useEffect, useState } from "react";
import "./MainContainer.scss";
import { PortalMainContainer } from "./Portal/PortalMainContainer";
import { OfficialMainContainer } from "./Official/OfficialMainContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import { addressActions } from "../Address/addressActions";
import { API, getBaseUrl } from "../api";
import { AadressideAndmeteTeenusApiFactory as addressAPI } from "../../api_client/medre_api";

export const MainContainer = React.memo(() => {
  const isPortal = useSelector((state: RootState) => state.config.isPortal);
  const [isMounted, setMounted] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const dispatch = useDispatch();

  const headerBackgroundColorForEnvironment = (): string => {
    const currentURL = window.location.href;
    if(currentURL.includes("localhost") || currentURL.includes(".arendus")) {
      return "dev";
    }
    if(currentURL.includes(".test")) {
      return "test"
    }
    return "";
  }

  useEffect(() => {
    if (!isMounted && userInfo) {
      setMounted(true);
      Promise.all([
        addressAPI(undefined, getBaseUrl(), API).getCountries({
          withCredentials: true
        }),
        addressAPI(undefined, getBaseUrl(), API).getParishes({
          withCredentials: true
        })
      ]).then(([{ data: countries }, { data: parishes }]) => {
        dispatch(addressActions.setCountries(countries));
        dispatch(addressActions.setParishes(parishes));
      });
    }
  }, [isMounted, userInfo, setMounted, dispatch]);

  return isPortal
      ? <PortalMainContainer
          headerColor={headerBackgroundColorForEnvironment()}/>
      : <OfficialMainContainer
          headerColor={headerBackgroundColorForEnvironment()}/>;
});
