import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "education"
  | "educationInfo"
  | "country"
  | "schoolName"
  | "curriculum"
  | "curriculumName"
  | "curriculumCode"
  | "diplomaDate"
  | "diplomaNumber";

export const EducationFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    education: {
      id: "educationFM.education",
      defaultMessage: "Haridus"
    },
    educationInfo: {
      id: "educationFM.educationInfo",
      defaultMessage: "Hariduse info"
    },
    country: {
      id: "educationFM.country",
      defaultMessage: "Õppeasutuse riik"
    },
    schoolName: {
      id: "educationFM.schoolName",
      defaultMessage: "Õppeasutus"
    },
    curriculum: {
      id: "educationFM.curriculum",
      defaultMessage: "Õppekava"
    },
    curriculumName: {
      id: "educationFM.curriculumName",
      defaultMessage: "Õppekava nimi"
    },
    curriculumCode: {
      id: "educationFM.curriculumCode",
      defaultMessage: "Õppekava kood"
    },
    diplomaDate: {
      id: "educationFM.diplomaDate",
      defaultMessage: "Õppimise lõpp"
    },
    diplomaNumber: {
      id: "educationFM.diplomaNumber",
      defaultMessage: "Diplomi number"
    },
    documentNumber: {
      id: "educationFM.documentNumber",
      defaultMessage: "Dokumendi number"
    },
    specialization: {
      id: "educationFM.specialization",
      defaultMessage: "Spetsialiseerumine"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
