import { createStore } from "redux";
import { rootReducer, RootState } from "./rootReducer";
import { handleApplicationDraftChange } from "./Application/applicationDraftReducer";
import { handleActivityLicenseChange } from "./ActivityLicense/activityLicenseApplicationReducer";
import { handleLicenseAcquisitionApplicationChange } from "./ActivityLicense/licenseAcquisitionApplicationReducer";

function configureStore(initialState?: RootState) {
  return createStore(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
      trace: process.env.NODE_ENV === "development",
    })
  );
}

const store = configureStore();

store.subscribe(handleApplicationDraftChange);
store.subscribe(handleActivityLicenseChange);
store.subscribe(handleLicenseAcquisitionApplicationChange);

export default store;
