import { RootAction, RootState } from "../rootReducer";
import { AlertItem } from "../Dto/Alert/AlertItem";
import { ADD_ALERT, REMOVE_ALERT } from "./alertActions";

export class AlertState {
  alerts: Map<number, AlertItem>;
  nextId: number;

  constructor() {
    this.alerts = new Map();
    this.nextId = 0;
  }
}

export const alertReducer = (state = new AlertState(), action: RootAction) => {
  switch (action.type) {
    case(ADD_ALERT): {
      let newState = {...state};
      action.payload.id = state.nextId;
      newState.alerts.set(action.payload.id, action.payload);
      newState.nextId = state.nextId + 1;
      return newState;
    }
    case(REMOVE_ALERT): {
      let newState = {...state};
      newState.alerts.delete(action.payload.id);
      return newState;
    }
    default:
      return state;
  }
};

export function getAlerts(state: RootState): AlertItem[] {
  return [...Array.from(state.alert.alerts.values())];
}
