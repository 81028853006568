import React, { useState } from "react";

import { FormattedButton, FormattedButtonProps } from "./FormattedButton";
import "./Button.scss";

export const PrimaryFormattedButton = ({
  onAsyncClick,
  onClick,
  disabled,
  ...props
}: FormattedButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (onAsyncClick) {
      setIsLoading(true);
      onAsyncClick().finally(() => setIsLoading(false));
    } else {
      onClick!();
    }
  };

  const hasOnClick = onClick !== undefined || onAsyncClick !== undefined;
  return (
    <FormattedButton
      {...props}
      color="primary"
      onClick={hasOnClick ? handleClick : undefined}
      disabled={isLoading || disabled}
    />
  );
};
