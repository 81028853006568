import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Label } from "reactstrap";
import { useWindowWidthSize } from "../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../Constants";
import { PersonalDataFM } from "../../../Messages/PersonalDataFM";
import { SingleSelect } from "../../../Component/Select/SingleSelect";
import { Option } from "../../../Component/Select/SelectTypes";
import { useDispatch } from "react-redux";
import { Representative } from "../../../../api_client/medre_api";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";

interface Props {
  representatives?: Representative[];
  submittingPersonIdCode?: string;
}

export const SearchRepresentative = ({
  representatives,
  submittingPersonIdCode
}: Props) => {
  const dispatch = useDispatch();
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const getRepresentative = (idCode?: string) =>
    representatives?.find((representative) => representative.idCode === idCode);

  const [selectedRepresentative, setSelectedRepresentative] = useState(
    getRepresentative(submittingPersonIdCode)
  );

  const createOption = (representative: Representative): Option => ({
    label: representative.firstName + " " + representative.lastName,
    value: representative.idCode as string
  });

  const handleOptionChange = (option: Option) =>
    setSelectedRepresentative(getRepresentative(option.value));

  useEffect(() => {
    if (selectedRepresentative) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "submittingPersonFirstName",
          selectedRepresentative?.firstName
        )
      );
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "submittingPersonLastName",
          selectedRepresentative?.lastName
        )
      );
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "submittingPersonIdCode",
          selectedRepresentative?.idCode
        )
      );
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "submittingPersonRole",
          selectedRepresentative?.personRole
        )
      );
    }
  }, [dispatch, selectedRepresentative]);

  return (
    <>
      <h4>
        <FormattedMessage
          id="activityLicense.searchRepresentative"
          defaultMessage="Määra ettevõtte esindaja"
        />
      </h4>
      <FormGroup
        className={"w-100 flex-nowrap " + (isMobile ? "" : "form-inline")}
      >
        <Label>
          <PersonalDataFM id="name" />
        </Label>
        <div className="input-wrapper wrapper-sm">
          <SingleSelect
            hideSearchIcon={true}
            className=""
            placeholder={
              <FormattedMessage
                id="searchRepresentative.representative"
                defaultMessage="Esindaja"
              />
            }
            alwaysShowPlaceholder={true}
            options={representatives?.map(createOption) || []}
            handleOptionChange={handleOptionChange}
            value={
              selectedRepresentative
                ? createOption(selectedRepresentative)
                : null
            }
          />
        </div>
      </FormGroup>
    </>
  );
};
