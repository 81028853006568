import { Service, ServiceTypeEnum } from "../../../api_client/medre_api";

export const prettifyServiceType = (type?: ServiceTypeEnum): string => {
  switch (type) {
    case ServiceTypeEnum.Ambulatory:
      return "ambulatoorne";
    case ServiceTypeEnum.Inpatient:
      return "statsionaarne";
    case ServiceTypeEnum.Both:
      return "ambulatoorne/statsionaarne";
    default:
      return "";
  }
};

const createServiceType = (type?: ServiceTypeEnum) => {
  if (!type || type === ServiceTypeEnum.NotApplicable) {
    return "";
  }
  return "(" + prettifyServiceType(type) + ")";
};

export const createServiceName = (service: Service, addType?: boolean) => {
  return addType
    ? service.name + " " + createServiceType(service.type)
    : service.name;
};

export const serviceNeedsBeds = (
  isHospital?: boolean,
  type?: ServiceTypeEnum
) => {
  return (
    isHospital &&
    (type === ServiceTypeEnum.Inpatient || type === ServiceTypeEnum.Both)
  );
};

export const isAmbulanceService = (code?: string) => {
  return code === process.env.REACT_APP_SPECIAL_SERVICE_AMBULANCE;
};

export const isGpService = (code?: string) => {
  return code === process.env.REACT_APP_SPECIAL_SERVICE_GP;
};
