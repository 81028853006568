import React from "react";

import { renderDocumentRows } from "../../../../Dto/File/FileReference";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import { renderAccordionHeaderRow } from "../../../../Component/Accordion/AccordionHeaderRowUtil";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { FileReference } from "../../../../../api_client/medre_api";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";

interface Props {
  isOpen: boolean;
  handleClick: () => void;
  documents: FileReference[];
}

export const FilesRow = ({ isOpen, handleClick, documents }: Props) => {
  const className = isOpen ? "expanded" : "";
  const widthSize = useWindowWidthSize();

  const renderRow = (icon: JSX.Element) =>
    renderAccordionHeaderRow(
      widthSize,
      icon,
      <ActivityLicenseFM id="documents" />
    );

  return isOpen ? (
    <>
      <div
        className={`${className} cursor-pointer accordion-header`}
        onClick={() => handleClick()}
      >
        {renderRow(<MinusIcon />)}
      </div>
      <div className="detail-col pl-0 pr-0">
        {renderDocumentRows(documents)}
      </div>
    </>
  ) : (
    <div
      className={`${className} cursor-pointer accordion-header`}
      onClick={() => handleClick()}
    >
      {renderRow(<PlusIcon />)}
    </div>
  );
};
