import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Person,
  KasutajaAndmeteTeenusApiFactory as userDataAPI
} from "../../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../../api";

export const useDetailedPerson = (personId?: string) => {
  const [person, setPerson] = useState<Person>();

  const history = useHistory();

  const fetchPersonData = useCallback(async () => {
    if (personId) {
      const { data: personData } = await userDataAPI(
        undefined,
        getBaseUrl(),
        API
      ).getPerson1(personId, {
        withCredentials: true
      });
      setPerson(personData);
    }
  }, [personId, setPerson]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPersonData().catch((error) => {
      if (!error.response || error.response?.status !== 400) {
        history.push("/");
      }
    });
  }, [fetchPersonData, history, personId]);

  return {
    person,
    setPerson,
    refreshPerson: fetchPersonData
  };
};
