import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import {RootState} from "../../../../rootReducer";
import {Option, SingleSelect} from "../../../../Component/Select/SingleSelect";
import {MultiSelect} from "../../../../Component/Select/MultiSelect";
import {countryGroups, EuCodes} from "../../../Shared/Search/Filters/FilterUtils";
import {filterActions} from "../../../Shared/Search/Filters/filterActions";
import * as Types from "../../../Shared/Search/Filters/FilterTypes";
import {useCountryOptions} from "../../../../Address/addressSelector";
import {FilterField} from "../../../../Component/Filter/Filter";
import {Option as filterOption} from "react-select/src/filters";
import {getCountryOption} from "../../../../Util/CountryFilterUtil";

interface Props {
  id?: string;
  countryGroupValue?: Option | null;
  onCountryGroupChange: (value: Option) => void;
  countryValue: Option[];
  onCountryChange: (options: Option[]) => void;
  disabled?: boolean;
}

const getCountryGroup = (code: string) =>
  code === "EST"
    ? Types.CountryGroups.Estonia
    : EuCodes.includes(code)
    ? Types.CountryGroups.Eca
    : Types.CountryGroups.Third;

export const CountryFilters = ({
  id,
  countryGroupValue,
  onCountryGroupChange,
  disabled,
  countryValue,
  onCountryChange
}: Props) => {
  const dispatch = useDispatch();
  const locale = useSelector((state: RootState) => state.locale);
  const state = useSelector((rootState: RootState) => rootState.filterOptions);
  const countryOptions = useCountryOptions();

  React.useEffect(() => {
    if (state.allCountries.length === 0 && countryOptions.length) {
      const countryList = countryOptions.map((option: Option) => ({
        option,
        group: getCountryGroup(option.value)
      }));
      dispatch(filterActions.setCountries(countryList));
    }
  }, [countryOptions, dispatch, state.allCountries]);

  React.useEffect(() => {
    if (state.allCountries.length > 0) {
      const filteredCountries = state.allCountries
        .filter((item: Types.CountryListItem) => {
          if (countryGroupValue) {
            return item.group === countryGroupValue.value;
          }
          return true;
        })
        .map((listItem) => listItem.option);
      dispatch(filterActions.setFilteredCountries(filteredCountries));
    }
  }, [state.allCountries, dispatch, countryGroupValue]);

  const idPrefix = (Boolean(id) && `${id}_`) ?? "";
  const countryGroupsId = `${idPrefix}countryGroups`;
  const educationCountryId = `${idPrefix}educationCountry`;

  const countryFilterOptions = (option: filterOption, rawInput: string) => {
    return getCountryOption(option, locale).toLowerCase().includes(rawInput.toLowerCase()) ||
      option.data.value.toLowerCase().includes(rawInput.toLowerCase());
  }

  return (
    <>
      <FilterField
        id={countryGroupsId}
        label="Hariduse riigi grupp"
        field={
          <SingleSelect
            inputId={countryGroupsId}
            options={countryGroups}
            value={countryGroupValue}
            handleOptionChange={onCountryGroupChange}
            className="input"
            isClearable={true}
            isSearchable={false}
            hideSearchIcon={true}
            alwaysClearable={false}
            alwaysShowPlaceholder={true}
            placeholder={
              <FormattedMessage
                id="filters.selectCountryGroup"
                defaultMessage="Vali hariduse riigi grupp"
              />
            }
            disabled={disabled}
          />
        }
      />
      <FilterField
        id={educationCountryId}
        label="Hariduse riik"
        field={
          <MultiSelect
            inputId={educationCountryId}
            options={state.filteredCountries}
            value={countryValue}
            handleOptionsChange={onCountryChange}
            placeholder={
              <FormattedMessage
                id="filters.educationCountry"
                defaultMessage="Vali hariduse riik"
              />
            }
            filterOptions={countryFilterOptions}
            disabled={disabled}
          />
        }
      />
    </>
  );
};
