import React from "react";
import { Card } from "reactstrap";

import { renderOverViewRow } from "../../Application/OverView/ApplicationOverViewUtil";

interface Props {
  name?: string;
  address?: string;
  registryCode?: string;
}

export const BusinessRegistryInfoCard = ({
  name,
  address,
  registryCode
}: Props) => (
  <Card className="info-card mb-2">
    {renderOverViewRow("businessRegistryInfo", "businessName", name)}
    {renderOverViewRow("businessRegistryInfo", "businessAddress", address)}
    {renderOverViewRow("businessRegistryInfo", "registryCode", registryCode)}
  </Card>
);
