import React from "react";
import {Card, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";

export const QueriesView = () => (
  <>
    <Card className="system-administration-card">
      <Row>
        <Col>
          <h2 className="system-administration-card-header fw-normal">
            <FormattedMessage
              id="systemAdministration.tab.runQueries.firstQuery"
              defaultMessage="Esimene päring"
            />
          </h2>
        </Col>
      </Row>
    </Card>
  </>
);
