import { ButtonName } from "../../../Component/Button/FormattedButton";

export const getDecisionInfo = (
  signed: boolean | undefined,
  fileName: string,
  minioContainerName: string,
  minioFileName: string
) => {
  let downloadText: ButtonName, name: string, minioId: string;

  if (signed) {
    downloadText = "downloadSignedDecision";
    name = fileName.substr(0, fileName.lastIndexOf(".")) + ".asice";
    minioId = minioContainerName;
  } else {
    downloadText = "downloadDecision";
    name = fileName;
    minioId = minioFileName;
  }
  return { downloadText, name, minioId };
};
