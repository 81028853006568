import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { RepresentativeDeactiveConfirmModalProps } from "./types";
import { EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivityAPI } from "../../../../../api_client/medre_api";

import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { Loader } from "../../../../Component/Loader/Loader";
import { alertActions } from "../../../../Alert/alertActions";
import { isDateInFuture } from "../../../../Util/DateUtils";

const RepresentativeDeactiveConfirmModal = ({
  modal,
  onClose,
  registryCode,
  reFetchAndUpdateData,
  isPortal
}: RepresentativeDeactiveConfirmModalProps) => {
  const { data, isOpen } = modal;
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  const dateInFuture = isDateInFuture(data?.startDate);

  const onDelete = async () => {
    try {
      setLoading(true);
      if (dateInFuture) {
        if (isPortal) {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).deletePowerOfAttorneyById1(registryCode!, data!.id as string, {
            withCredentials: true
          });
        } else {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).deletePowerOfAttorneyById(registryCode!, data!.id as string, {
            withCredentials: true
          });
        }
      } else {
        if (isPortal) {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).deactivatePowerOfAttorneyById1(registryCode!, data!.id as string, {
            withCredentials: true
          });
        } else {
          await corporateDataActivityAPI(
            undefined,
            getBaseUrl(),
            API
          ).deactivatePowerOfAttorneyById(registryCode!, data!.id as string, {
            withCredentials: true
          });
        }
      }
      const successAlertMessage = (
        <AlertMessage
          id={
            dateInFuture
              ? "representativeDeleteSuccess"
              : "representativeSuspendSuccess"
          }
        />
      );
      const successAlert = {
        id: 0,
        type: AlertType.Success,
        message: successAlertMessage
      };
      dispatch(alertActions.addAlert(successAlert));
      reFetchAndUpdateData();
    } catch (e) {
      const alertMessage = (
        <AlertMessage
          id={
            dateInFuture
              ? "representativeDeleteFailed"
              : "representativeSuspendFailed"
          }
        />
      );
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      setLoading(false);
    }
  };

  return (
    <Modal
      id="representative-deactivate-modal"
      isOpen={isOpen}
      centered={true}
      toggle={onClose}
      contentClassName="representative-deactivate-modal"
    >
      {loading ? <Loader absolute={true} /> : null}
      <ModalBody>
        {dateInFuture ? (
          <FormattedMessage
            id="representativeDeletionModal.text"
            defaultMessage="Oled kindel, et soovid {name} tulevikus kehtima hakkava esindamise õiguse eemaldada?"
            values={{
              name: (
                <b>
                  {data?.personFirstName} {data?.personLastName}
                </b>
              )
            }}
          />
        ) : (
          <FormattedMessage
            id="representativeSuspensionModal.text"
            defaultMessage="Oled kindel, et soovid {name} esindamise õigused tänase kuupäeva seisuga ära lõpetada?"
            values={{
              name: (
                <b>
                  {data?.personFirstName} {data?.personLastName}
                </b>
              )
            }}
          />
        )}
      </ModalBody>
      <ModalFooter className="representative-deactivate-modal-footer">
        <SecondaryFormattedButton id="cancel" onClick={onClose} />
        {dateInFuture ? (
          <PrimaryFormattedButton
            id="representativeDeletionModalPrimaryBtn"
            onClick={onDelete}
          />
        ) : (
          <PrimaryFormattedButton
            id="representativeSuspensionModalPrimaryBtn"
            onClick={onDelete}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};

export default RepresentativeDeactiveConfirmModal;
