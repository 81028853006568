import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { StateFeeInstructions } from "./StateFeeInstructions";
import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import { useWindowWidthSize } from "../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../Constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  deadline?: Date | string;
  referenceNumber?: string;
  stateFeeValue?: number;
}

export const StateFeeInfoModal = ({
  isOpen,
  onClose,
  deadline,
  referenceNumber,
  stateFeeValue
}: Props) => {
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <FormattedMessage
          id="stateFeeInfoModal.header"
          defaultMessage="Riigilõivu tasumise juhend"
        />
      </ModalHeader>
      <ModalBody>
        <StateFeeInstructions
          deadline={deadline}
          referenceNumber={referenceNumber}
          stateFeeValue={stateFeeValue}
        />
      </ModalBody>
      <ModalFooter className={isMobile ? "justify-content-center" : ""}>
        <SecondaryFormattedButton id="backToApplication" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};
