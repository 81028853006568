import React from "react";
import { FormattedMessage } from "react-intl";
import { Stepper } from "../../../../Component/ProgressBar/Stepper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import {
  LicenseAcquisitionStep,
  licenseAcquisitionSteps
} from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAquisitionStep";
import { WizardProgressBar } from "../../../../Component/ProgressBar/WizardProgressBar";
import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { Card } from "reactstrap";
import { getSelectedCompanyId } from "../../../../Security/roleHelpers";
import { LicenseAcquisitionFooter } from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAcquisitionFooter";
import {
  LicenseAcquisitionBusinessData
} from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAcquisitionBusinessData";
import {
  LicenseSelectionContainer
} from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseSelectionContainer";
import {
  LicenseAcquisitionDocumentsContainer
} from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAcquisitionDocumentsContainer";
import {
  LicenseAcquisitionOverview
} from "../../../Shared/ActivityLicense/LicenseAcquisition/LicenseAcquisitionOverview";

export const PortalLicenseAcquisitionContainer = () => {
  const currentStep = useSelector((state: RootState) => state.licenseAcquisitionApplication?.currentStep || 0);
  const roles = useSelector((state: RootState) => Object(state.user.userInfo?.roles));
  const steps = licenseAcquisitionSteps;

  const renderContent = () => {
    switch (currentStep) {
      case LicenseAcquisitionStep.BUSINESS_DATA:
        return (
          <LicenseAcquisitionBusinessData
            registryCode={getSelectedCompanyId(roles)!}
          />
        );
      case LicenseAcquisitionStep.LICENSE_SELECTION:
        return <LicenseSelectionContainer/>;
      case LicenseAcquisitionStep.DOCUMENTS:
        return <LicenseAcquisitionDocumentsContainer/>;
      case LicenseAcquisitionStep.OVERVIEW:
        return <LicenseAcquisitionOverview/>;
      default:
        return (
          <LicenseAcquisitionBusinessData
            registryCode={getSelectedCompanyId(roles)!}
          />
        );
    }
  };

  return (
    <div className="activity-license-container">
      <h1 className="ml-3">
        <FormattedMessage
          id="licenseAcquisition.title"
          defaultMessage="Tegevusloa ülevõtmine"
        />
      </h1>

      <Stepper className={"m-3"} currentStep={currentStep || 0} steps={steps}/>
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps}/>
          {renderContent()}
          <hr className="mt-5 application-footer-hr"/>
          <LicenseAcquisitionFooter currentStep={currentStep || 0} steps={steps} />
        </div>
      </Card>
    </div>
  );
};
