import React from "react";
import { useHistory } from "react-router";

import { ApplicationDetailsInfoCard } from "../../../Component/Card/ApplicationDetailsInfoCard";
import { ClickableInfoValue } from "../../../Component/QuickLinks/ClickableInfoValue";

interface Props {
  title?: string;
  id: string | undefined;
  name: string | undefined;
  address: string | undefined;
  registryCode: string | undefined;
}

export const BusinessRegistryInfoCard = ({
  id,
  name,
  address,
  registryCode,
  title = "Äriregistri andmed"
}: Props) => {
  const history = useHistory();

  const onNameClick = () => {
    if (id) {
      history.push(`/companies/${id}`);
    }
  };

  return (
    <ApplicationDetailsInfoCard
      title={title}
      content={[
        {
          key: "Nimi",
          value: <ClickableInfoValue name={name || ""} onClick={onNameClick} />
        },
        { key: "Aadress", value: address || "-" },
        { key: "Registrikood", value: registryCode || "-" }
      ]}
    />
  );
};
