import React, { useCallback, useMemo } from "react";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useGeneralPractitionerSubstituteDoctorOptions } from "../useGeneralPractitionerLists";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { GPListFM } from "../../../../Messages/GPListFM";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { getNextDate, isDateInPast } from "../../../../Util/DateUtils";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  GeneralPractitionerListSubstituteDoctorPeriod,
  PerearstiNimistudTeenusApiFactory as doctorDirectoryAPI
} from "../../../../../api_client/medre_api";
import { EmployeeRoleFM } from "../../../../Messages/EmployeeFM";

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: FormValues;
}

type FormValues = Partial<GeneralPractitionerListSubstituteDoctorPeriod>;

export function PortalSubstituteDoctorPeriodModal({
  defaultValues,
  open,
  onClose
}: Props) {
  const { control, formState, handleSubmit, watch } = useForm<FormValues>({
    mode: "onChange",
    defaultValues
  });
  const { errors, isValid, isSubmitting } = formState;
  const { dateFrom, substituteDoctorId } = watch();
  const minDateTo = useMemo(() => getNextDate(dateFrom), [dateFrom]);
  const defaultDateFrom = defaultValues?.dateFrom ?? new Date();
  const dataFromDisabled = useMemo(
    () => isDateInPast(defaultDateFrom),
    [defaultDateFrom]
  );
  const substituteDoctorOptions =
    useGeneralPractitionerSubstituteDoctorOptions();

  const { id } = useParams<{ id: string }>();
  const {
    fetchGPList,
    list: { substituteDoctors }
  } = useGeneralPractitionerList();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values) => {
      try {
        if (values?.id) {
          await doctorDirectoryAPI(
            undefined,
            getBaseUrl(),
            API
          ).updateSubstituteDoctorsPeriod(id, values, {
            withCredentials: true
          });
        } else {
          await doctorDirectoryAPI(
            undefined,
            getBaseUrl(),
            API
          ).addSubstituteDoctorsPeriod(id, values, {
            withCredentials: true
          });
        }
        await fetchGPList(id!);
        onClose();
      } catch (e) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }
    },
    [id, fetchGPList, onClose, dispatch]
  );

  const substituteDoctor = useMemo(
    () => substituteDoctors.find((doctor) => doctor.id === substituteDoctorId),
    [substituteDoctors, substituteDoctorId]
  );

  return (
    <Modal
      isOpen={open}
      className="substitute-doctor-period-modal"
      unmountOnClose={true}
      contentClassName="pr-2 pl-2"
    >
      <ModalHeader>
        <FormattedMessage
          id="GPListSubstituteDoctorPeriodModal.header"
          defaultMessage="Muuda asenduse perioodi"
        />
      </ModalHeader>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gp-contact-information-form"
      >
        <ModalBody>
          <Controller
            name="id"
            defaultValue=""
            control={control}
            as={<input type="hidden" />}
          />
          <FormGroup row={true} className="required">
            <Label tag="span" className="text-right pl-0" sm={4}>
              <GPListFM id="substitutePeriod" />
            </Label>
            <Col sm={8} className="d-flex align-items-center">
              <div className="flex-grow-1">
                <Controller
                  name="dateFrom"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <DatePickerComponent
                      onDateChange={onChange}
                      selectedDate={value}
                      disablePast={false}
                    />
                  )}
                />
              </div>
              <span className="flex-grow-0">&nbsp;―&nbsp;</span>
              <div className="flex-grow-1">
                <Controller
                  name="dateTo"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({
                    value,
                    onChange
                  }: ControllerRenderProps<Record<string, any>>) => (
                    <DatePickerComponent
                      onDateChange={onChange}
                      selectedDate={value}
                      minDate={minDateTo}
                    />
                  )}
                />
              </div>
            </Col>
          </FormGroup>

          <FormGroup row={true} className="required">
            <Label
              htmlFor="gpListSubstituteDoctorPeriodModalSubstituteDoctorId"
              className="text-right"
              sm={4}
            >
              <GPListFM id="substituteDoctor" />
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                control={control}
                name="substituteDoctorId"
                defaultValue=""
                rules={{ required: true }}
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <SingleSelect
                    inputId="gpListSubstituteDoctorPeriodModalSubstituteDoctorId"
                    options={substituteDoctorOptions as Option[]}
                    value={value}
                    handleOptionChange={(option) => onChange(option.value)}
                    isInValid={!!(errors as any).employeeId}
                  />
                )}
              />
              {substituteDoctor && (
                <EmployeeRoleFM role={substituteDoctor?.role} />
              )}
            </Col>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton
            id="submitSubstituteDoctorPeriod"
            type="submit"
            disabled={!isValid || isSubmitting}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
