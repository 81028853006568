import { action } from "typesafe-actions";
import { Country, Parish } from "../../api_client/medre_api";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PARISHES = "SET_PARISHES";

export const addressActions = {
  setCountries: (countries: Country[]) => action(SET_COUNTRIES, countries),
  setParishes: (parishes: Parish[]) => action(SET_PARISHES, parishes)
};
