import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../../Locale/LocaleMessage";
import { ErrorMessage } from "./ErrorMessage";

interface Props {
  id: string;
  className?: string;
}

type FieldName =
  | "mandatoryField"
  | "phone"
  | "email"
  | "url"
  | "address"
  | "addressNotPreciseEnough"
  | "zipCode";

export const FormattedErrorMessage = ({ id, className }: Props) => {
  const fields: Record<FieldName, LocaleMessage> = defineMessages({
    mandatoryField: {
      id: "errorField.mandatoryField",
      defaultMessage: "Kohustuslik väli"
    },
    phone: {
      id: "errorField.phone",
      defaultMessage: "Vigane telefoninumber"
    },
    address: {
      id: "errorField.address",
      defaultMessage: "Vigane aadress"
    },
    exampleAddress: {
      id: "errorField.exampleAddress",
      defaultMessage: "Sisesta aadress kujul https://www.eesti.ee"
    },
    addressNotPreciseEnough: {
      id: "errorField.addressNotPreciseEnough",
      defaultMessage:
        "Palun täpsustage aadressi. Aadress peab olema majanumbri, talu või postkasti täpsusega."
    },
    zipCode: {
      id: "errorField.zipCode",
      defaultMessage: "Vigane postiindeks"
    },
    email: {
      id: "errorField.email",
      defaultMessage: "Vigane E-post"
    },
    url: {
      id: "errorField.url",
      defaultMessage: "Vigane aadress"
    },
    personalCode: {
      id: "errorField.personalCode",
      defaultMessage: "Isikukoodi formaat on vale."
    },
    representativeEmail: {
      id: "errorField.representative.email",
      defaultMessage: "Emaili formaat on vale."
    }
  });

  return (
    <ErrorMessage
      message={<FormattedMessage {...fields[id as FieldName]} />}
      className={className}
    />
  );
};
