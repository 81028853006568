import { AxiosPromise } from "axios";

import { getBaseUrl, API } from "../api";
import {
  FileReference,
  FailideLeslaadimiseTeenusApiFactory as fileUploadAPI
} from "../../api_client/medre_api";

export async function handleFileDownload(
  fileName?: string,
  minioFileName?: string,
  fileContent?: File,
  endpoint?: (registryCode: string, options?: any) => AxiosPromise<any>,
  registryCode?: string
) {
  let data: Blob;

  if (minioFileName) {
    data = new Blob([await getFileContentFromMinio(minioFileName)]);
  } else if (fileContent) {
    data = fileContent.slice();
  } else if (endpoint) {
    data = new Blob([await getFileBlobFromEndpoint(endpoint, registryCode)]);
  } else {
    return;
  }
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName!);
  document.body.appendChild(link);
  link.click();
}

export const FILENAME_SANITIZE_PATTERN = /[^a-zA-Z0-9.\-]/g;

export const areFileNamesUnique = (
  files: File[],
  fileReferences: FileReference[]
) => {
  const referenceNames = fileReferences.map((reference) => reference.fileName?.replace(FILENAME_SANITIZE_PATTERN, "_"));
  return !files.some((file) => referenceNames.includes(file.name.replace(FILENAME_SANITIZE_PATTERN, "_")));
};

export const isFileNameUnique = (sanitizedFileName: string, fileReferences: FileReference[]) => {
  const referenceNames = fileReferences.map((reference) => reference.fileName?.replace(FILENAME_SANITIZE_PATTERN, "_"));
  return !referenceNames.includes(sanitizedFileName);
}

function getFileContentFromMinio(minioFileName: string) {
  return fileUploadAPI(undefined, getBaseUrl(), API)
    .download2(minioFileName, {
      withCredentials: true,
      responseType: "arraybuffer"
    })
    .then((response) => response.data as Blob);
}

function getFileBlobFromEndpoint(
  endpoint: (registryCode: string, options?: any) => AxiosPromise<any>,
  registryCode?: string
) {
  return endpoint(registryCode!, {
    withCredentials: true
  }).then((response) => response.data);
}
