import React, { FC, useCallback, useMemo } from "react";
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";

import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { getPreviousDate, } from "../../../../Util/DateUtils";
import { API, getBaseUrl } from "../../../../api";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import fetchApplicantOptions, { ApplicantOption } from "../../../Shared/GeneralPractitioners/fetchApplicantOptions";
import {
  useGeneralPractitionerListGeneralPractitionerSelector
} from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI,
  GeneralPractitioner,
  Substitute
} from "../../../../../api_client/medre_api";
import { getFullName } from "../../../../Util/PersonUtils";
import { DOCTOR } from "../../../../Constants";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";

interface Props {
  onClose: () => void;
}

interface FormValues {
  familyDoctor: string | ApplicantOption;
  id: string;
  occupationCode: string;
  activationDate: string;
}

const getVerificationMessage = (person: GeneralPractitioner | Substitute) =>
  `${formatDate((person.deactivationDate))} on viimane kuupäev, millal ‘${getFullName(person)}’ nimistu juures töötab.`;

export const FamilyDoctor: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { fetchGPList, list, fetchGPListModifications } = useGeneralPractitionerList();
  const generalPractitioner = useGeneralPractitionerListGeneralPractitionerSelector();
  const { substitute } = list;
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {},
    shouldUnregister: false
  });
  const { isValid, isSubmitting } = formState;

  const verificationMessage = useMemo(() => {
    if (generalPractitioner.id && generalPractitioner.deactivationDate) {
      return getVerificationMessage(generalPractitioner);
    }

    if (substitute?.id) {
      return getVerificationMessage(substitute);
    }
    return "";
  }, [generalPractitioner, substitute]);

  const loadFamilyDoctorOptions = useCallback(async (occupationCode: string) => {
    const formatedOccupationCode = occupationCode.replace(/\s+/g, '').trim().toUpperCase();

    if (formatedOccupationCode.length !== 6) return [];

    const options = await fetchApplicantOptions({ occupationCode: formatedOccupationCode });

    return options.filter(option =>
      option.specialities?.some(speciality => speciality.code === "E300" &&
      option["occupationName"] === DOCTOR &&
      option["occupationCode"] === formatedOccupationCode));
    }, []
  );

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { familyDoctor, activationDate } = values;
      const { id: personId, occupationCode } = familyDoctor as ApplicantOption;

      await officialFamilyListAPI(undefined, getBaseUrl(), API)
        .updateGeneralPractitionerModification(
          list.id,
          { id: personId,
            occupationCode,
            activationDate },
          { withCredentials: true }
        );

      await fetchGPList(list.id);
      await fetchGPListModifications(list.id);

      displayAlert("familyDoctorSaveSuccess", AlertType.Success, dispatch);
      onClose();
    } catch (error) {
      displayAlert("familyDoctorSaveFailure", AlertType.Danger, dispatch);
      onClose();
    }

  }, [fetchGPList, list.id, dispatch, onClose]);

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <FormattedMessage
            id="GPListAddFamilyDoctorModal.header"
            defaultMessage="Määra nimistu perearst"
          />
        </ModalHeader>
        <ModalBody>
          <FormGroup row={true}>
            <Label
              htmlFor="gpListAddFamilyDoctorModalFamilyDoctor"
              sm={4}
              className="text-right"
            >
              Perearst
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                name="familyDoctor"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ value, onChange }: ControllerRenderProps<Record<string, any>>) => (
                  <AsyncSelect
                    inputId="gpListAddFamilyDoctorModalFamilyDoctor"
                    value={value}
                    placeholder={
                      <FormattedMessage
                        id="asyncCustomSelect.search"
                        defaultMessage="Otsi"
                      />
                    }
                    onChange={onChange}
                    loadOptions={loadFamilyDoctorOptions}
                    cacheOptions={true}
                  />
                )}
              />
              <FormText>peremeditsiin</FormText>
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Label
              htmlFor="gpListAddFamilyDoctorModalActivationDate"
              sm={4}
              className="text-right"
            >
              Muutmise kuupäev
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                name="activationDate"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <DatePickerComponent
                    id="gpListAddFamilyDoctorModalActivationDate"
                    onDateChange={field.onChange}
                    selectedDate={field.value}
                    placeholder="pp.kk.aaaa"
                  />
                )}
              />
              <FormText>{verificationMessage}</FormText>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            <FormattedMessage
              id="GPListAddFamilyDoctorModal.submit"
              defaultMessage="Määra perearst"
            />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
