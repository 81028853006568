import React, { useState } from "react";
import { Tooltip, TooltipProps } from "reactstrap";
import "./ToolTip.scss";

type Props = TooltipProps & {
  message: string | JSX.Element;
};

export const FormattedToolTip = ({ placement, target, message, style }: Props) => {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  return (
    <Tooltip
      placement={placement || "bottom-start"}
      isOpen={isToolTipOpen}
      target={target}
      toggle={() => setIsToolTipOpen((isOpen) => !isOpen)}
      style={style ? style : undefined}
    >
      { message }
    </Tooltip>
  );
};
