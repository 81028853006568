import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import "./OfficialApplication.scss";
import { Stepper } from "../../../Component/ProgressBar/Stepper";
import {
  occupationApplicationSteps,
  OfficialApplicationStep,
  specialityApplicationSteps
} from "./ApplicationStep";
import { WizardProgressBar } from "../../../Component/ProgressBar/WizardProgressBar";
import { RootState } from "../../../rootReducer";
import { PersonalDataView } from "./PersonalData/PersonalDataView";
import { OfficialApplicationFooter } from "./Footer/OfficialApplicationFooter";
import { OccupationSelection } from "../../Shared/Application/Occupation/OccupationSelection";
import { SpecialitySelection } from "../../Shared/Application/Speciality/SpecialitySelection";
import { ApplicationEducation } from "../../Shared/Application/Education/ApplicationEducation";
import { ApplicationArrivalChannel } from "./ArrivalChannel/ApplicationArrivalChannel";
import {
  DetailedApplicationTypeEnum,
  KasutajaAndmeteTeenusApiFactory as userDataAPI
} from "../../../../api_client/medre_api";
import { OfficialApplicationOverView } from "./OverView/OfficialApplicationOverView";
import { API, getBaseUrl } from "../../../api";
import { personActions } from "../../../Person/personActions";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { Loader } from "../../../Component/Loader/Loader";

interface Props {
  type: DetailedApplicationTypeEnum;
}

export const ApplicationContainer = ({ type }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const currentStep = useSelector(
    (state: RootState) => state.applicationDraft.currentStep || 0
  );
  const applicationType = useSelector(
    (state: RootState) => state.applicationDraft.type
  );
  const personId = useSelector(
    (state: RootState) => state.applicationDraft.personId
  );

  const steps =
    applicationType === DetailedApplicationTypeEnum.Occupation
      ? occupationApplicationSteps
      : specialityApplicationSteps;

  useEffect(() => {
    if (personId) {
      setLoading(true);
      userDataAPI(undefined, getBaseUrl(), API)
        .getPerson1(personId, {
          withCredentials: true
        })
        .then((res) => {
          dispatch(personActions.setPerson(res.data));
          dispatch(
            applicationDraftActions.updateApplicationDraft(
              "personalData",
              res.data
            )
          );
        })
        .finally(() => setLoading(false));
    }
  }, [personId]);

  const renderOccupationContent = () => {
    switch (currentStep) {
      case OfficialApplicationStep.PERSONAL_DATA:
        return <PersonalDataView />;
      case OfficialApplicationStep.SPECIALITY_OCCUPATION:
        return <OccupationSelection />;
      case OfficialApplicationStep.EDUCATION_INFO:
        return <ApplicationEducation />;
      case OfficialApplicationStep.APPLICATION_ARRIVAL_CHANNEL:
        return <ApplicationArrivalChannel />;
      case OfficialApplicationStep.OVERVIEW:
        return <OfficialApplicationOverView />;
      default:
        return <PersonalDataView />;
    }
  };

  const renderSpecialityContent = () => {
    switch (currentStep) {
      case OfficialApplicationStep.PERSONAL_DATA:
        return <PersonalDataView />;
      case OfficialApplicationStep.SPECIALITY_OCCUPATION:
        return <SpecialitySelection />;
      case OfficialApplicationStep.EDUCATION_INFO:
        return <ApplicationEducation />;
      case OfficialApplicationStep.APPLICATION_ARRIVAL_CHANNEL:
        return <ApplicationArrivalChannel />;
      case OfficialApplicationStep.OVERVIEW:
        return <OfficialApplicationOverView />;
      default:
        return <PersonalDataView />;
    }
  };

  const renderContent = () =>
    type === DetailedApplicationTypeEnum.Occupation
      ? renderOccupationContent()
      : renderSpecialityContent();

  const renderTitle = () =>
    type === DetailedApplicationTypeEnum.Occupation
      ? "Kutse taotluse esitamine"
      : "Eriala taotluse esitamine";

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="application-container application-official">
      <h1 className="ml-3">{renderTitle()}</h1>
      <Stepper className={"m-3"} currentStep={currentStep} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <OfficialApplicationFooter currentStep={currentStep} steps={steps} />
        </div>
      </Card>
    </div>
  );
};
