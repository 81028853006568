import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { TypedOption } from "../../../../Component/Select/SelectTypes";
import { EmployeeAsyncSelect } from "../../../../Component/Select/EmployeeAsyncSelect";
import { NurseAsyncSelect } from "../../../../Component/Select/NurseAsyncSelect";
import { getBaseUrl, API } from "../../../../api";
import { RemoveButton } from "../../../../Component/Button/RemoveButton";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import {
  HealthCareProfessional,
  OccupationCode,
  Occupation,
  Speciality,
  KasutajaAndmeteTeenusApiFactory as userOfficialDataAPI,
  ServiceStatusEnum, ServiceEmployee, SpecialistCode
} from "../../../../../api_client/medre_api";
import "./AddEmployeeModal.scss";
import { SPECIALISTS_PREFIXES } from "../../../../Constants";

export enum SearchBy {
  All,
  Pharmacists
}

interface Props {
  isOpen: boolean;
  requiredOccupations?: Occupation[];
  requiredSpecialities?: Speciality[];
  optionalOccupations?: Occupation[];
  optionalSpecialities?: Speciality[];
  defaultEmployees: HealthCareProfessional[];
  onClose: () => void;
  onSave: (employees: HealthCareProfessional[]) => void;
  title: React.ReactElement;
  subtitle: React.ReactElement;
  occupationCodeLabel: React.ReactElement;
  searchBy?: SearchBy;
  closeButtonId: ButtonName;
  addButtonId: ButtonName;
  excemptEmployees?: HealthCareProfessional[];
  serviceEmployees?: ServiceEmployee[];
  serviceCode?: string;
  serviceStatus?: ServiceStatusEnum;
}

export const AddEmployeeModal: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  defaultEmployees,
  requiredOccupations,
  requiredSpecialities,
  optionalOccupations,
  optionalSpecialities,
  title,
  subtitle,
  occupationCodeLabel,
  searchBy = SearchBy.All,
  closeButtonId,
  addButtonId,
  excemptEmployees,
  serviceEmployees,
  serviceCode,
  serviceStatus
}: Props) => {
  const [employees, setEmployees] =
    useState<HealthCareProfessional[]>(defaultEmployees);
  const [alreadyAddedThtNotification, setAlreadyAddedThtNotification] = useState<boolean>(false);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean | undefined>(undefined);

  const hasRequirements = requiredOccupations || requiredSpecialities;
  const hasOptionals = Boolean(
    optionalOccupations?.length || optionalSpecialities?.length
  );
  const relevantSpecialistCodes = ["S100", "S110", "S220"];

  useEffect(() => {
    if (isOpen) {
      setEmployees(defaultEmployees);
    }
  }, [isOpen, defaultEmployees]);

  const areRequirementsSatisfied = (): boolean => {
    const requiredOccupationPrefixes =
      requiredOccupations?.map((occupation) => occupation.prefix) || [];
    const requiredSpecialityCodes =
      requiredSpecialities?.map((speciality) => speciality.code) || [];
    const optionalOccupationPrefixes =
      optionalOccupations?.map((occupation) => occupation.prefix) || [];
    const optionalSpecialityCodes =
      optionalSpecialities?.map((speciality) => speciality.code) || [];
    const selectedOccupationPrefixes = employees
      .map((employee: HealthCareProfessional) => employee.occupationCodes)
      .flat(1)
      .map((occupationCode) => occupationCode?.prefix);
    const selectedSpecialityCodes = employees
      .map((employee: HealthCareProfessional) => employee.specialities)
      .flat(1)
      .map((speciality) => speciality?.code);

    const requiredOccupationsArePresent = requiredOccupationPrefixes.every(
      (c) => selectedOccupationPrefixes.includes(c)
    );
    const requiredSpecialitiesArePresent = requiredSpecialityCodes.every((c) =>
      selectedSpecialityCodes.includes(c)
    );
    const optionalOccupationsArePresent = optionalOccupationPrefixes.length
      ? optionalOccupationPrefixes.some((code) =>
        selectedOccupationPrefixes.includes(code)
      )
      : true;
    const optionalSpecialitiesArePresent = optionalSpecialityCodes.length
      ? optionalSpecialityCodes.some((code) =>
        selectedSpecialityCodes.includes(code)
      )
      : true;
    const atLeastOneEmployeeIsAdded = employees.length > 0;
    return (
      atLeastOneEmployeeIsAdded &&
      requiredOccupationsArePresent &&
      requiredSpecialitiesArePresent &&
      optionalOccupationsArePresent &&
      optionalSpecialitiesArePresent
    );
  };

  const isProfessionalOnlySpecialist = (professional: HealthCareProfessional): boolean => {
    // If a professional has at least one occupation code with a prefix not present in SPECIALISTS_PREFIXES,
    // the function considers that professional as valid.
    const occupationCodes = professional.occupationCodes || [];

    return occupationCodes.some(occupation => !SPECIALISTS_PREFIXES.includes(occupation.prefix));
  };

  const handleProfessionalSelection = (
    option: TypedOption<HealthCareProfessional>
  ) => {
    setEmployees((prevEmployees: HealthCareProfessional[]) => {
      if (employees.some((employee: HealthCareProfessional) => employee.id === option.value.id)) {
        return prevEmployees;
      }
      return [...prevEmployees, option.value];
    });
  };

  const checkIfCodeMatchesAnyEmployeeOccupationCode = (code: string): void => {
    setAlreadyAddedThtNotification(false);

    if (code.length !== 6) {
      return; // Ignore not complete code
    }

    const occupationCodeMatch = excemptEmployees?.some((eEmployees: HealthCareProfessional) =>
      eEmployees.occupationCodes?.some(occupationCode =>
        occupationCode.code.toUpperCase() === code.toUpperCase()
      )
    );

    if (occupationCodeMatch) {
      setAlreadyAddedThtNotification(true);
      setMenuIsOpen(false);
      setTimeout(() => setMenuIsOpen(undefined), 3000);
    }
  };

  const createProfessionalOption = (professional: HealthCareProfessional) => {
    const { firstName, lastName, idCode, foreignIdCode, occupationCodes } =
      professional;

    return {
      label: (
        <span>
          <b>
            {firstName} {lastName}
          </b>
          &nbsp;{idCode || foreignIdCode} -{" "}
          {occupationCodes?.map(createOccupationName).join(", ")}
        </span>
      ),
      value: professional
    };
  };

  const loadProfessionals = (inputString: string) =>
    searchBy === SearchBy.All
      ? userOfficialDataAPI(undefined, getBaseUrl(), API).getByCode(
        inputString,
        {
          withCredentials: true
        }
      )
      : userOfficialDataAPI(undefined, getBaseUrl(), API).getPharmacistsByCode(
        inputString,
        {
          withCredentials: true
        }
      );

  const filterOutExistingThtServiceEmployees = (professional: HealthCareProfessional): boolean => {
    return !(excemptEmployees || []).some(
      (employee) => employee.id === professional.id
    );
  };

  const filterProfessionalResponse = (professional: HealthCareProfessional) => {
    return (
      !employees.some((employee: HealthCareProfessional) => employee.id === professional.id) &&
      filterOutExistingThtServiceEmployees(professional) &&
      isProfessionalOnlySpecialist(professional)
    );
  };

  const removeEmployee = (professional: HealthCareProfessional) => {
    setEmployees((prevState: HealthCareProfessional[]) => {
      return prevState.filter((employee) => employee.id !== professional.id);
    });
  };

  const createOccupationName = (occupationCode: OccupationCode) => {
    return occupationCode.name + " (" + occupationCode.code + ")";
  };

  const renderRequirement = useCallback(
    (entities?: (Occupation | Speciality)[]) => {
      if (!entities) {
        return;
      }
      return (
        <div className="occupation-name-wrapper">
          {entities?.map((entity) => {
            return (
              <div key={entity.code} className="occupation-name">
                {entity.name}
              </div>
            );
          })}
        </div>
      );
    },
    []
  );

  const renderEmployee = (employee: HealthCareProfessional, index: number) => {
    const specialistCodes: SpecialistCode[] = getWarningSpecialistCodesForEmployee(employee.id!);
    const employeeClass = index > 0 ? "employee-row mt-3" : "employee-row";
    return (
      <div key={employee.id} className={employeeClass}>
        <RemoveButton
          handleDelete={() => removeEmployee(employee)}
          hideText
        />
        <div className="employee-info ml-2">
          <div>
            <span className="fw-700">
              {employee.firstName} {employee.lastName}
            </span>
            <span>&nbsp;{employee.idCode || employee.foreignIdCode}</span>
          </div>
          <div>
            {employee.occupationCodes?.map(createOccupationName).join(", ")}
            {employee.specialities && !!employee.specialities.length && (
              <>
                &nbsp;-&nbsp;
                {employee.specialities
                  ?.map((speciality) => speciality.name)
                  .join(", ")}
              </>
            )}
          </div>
          {specialistCodes && specialistCodes.length > 0 && specialistCodes.map(specialistCode => (
            <div key={specialistCode?.code} className="text-danger mt-1">
                Isik on tööl spetsialistina {specialistCode!.name} - {specialistCode!.code}. Kui ta enam ei tööta sellena, siis palun eemaldada.
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getWarningSpecialistCodesForEmployee = (employeeId: string): SpecialistCode[] => {
    return serviceEmployees
      ?.filter(serviceEmployee => serviceEmployee.employeeId === employeeId
            && serviceEmployee.specialistCode
            && relevantSpecialistCodes.includes(serviceEmployee.specialistCode.code))
      .map(serviceEmployee => serviceEmployee.specialistCode!) ?? [];
  };

  const nurseServiceSelected = useMemo(
    () => (serviceCode === "T0204" || serviceCode === "T0001" || serviceCode === "T0236") && serviceStatus === ServiceStatusEnum.Active,
    [serviceCode]
  );

  return (
    <Modal isOpen={isOpen} id="add-new-employee-modal">
      <ModalHeader tag="h4">{title}</ModalHeader>

      <ModalBody>
        {hasRequirements && (
          <p>
            <FormattedMessage
              id="addEmployeeModal.requiredInstruction"
              defaultMessage="Teenusele on kohustuslik lisada alloleva kutse/erialaga töötaja(d):"
            />
          </p>
        )}
        {renderRequirement(requiredOccupations)}
        {renderRequirement(requiredSpecialities)}
        {hasOptionals && (
          <p>
            <FormattedMessage
              id="addEmployeeModal.optionalInstruction"
              defaultMessage="Teenusele on kohustuslik lisada vähemalt 1 alloleva kutse/erialaga töötaja:"
            />
          </p>
        )}
        {renderRequirement(optionalOccupations)}
        {renderRequirement(optionalSpecialities)}

        <h5>{subtitle}</h5>

        <Card className="employee-selection">
          <Label>{occupationCodeLabel}</Label>
          {nurseServiceSelected ?
            <NurseAsyncSelect
              toOption={createProfessionalOption}
              loadMethod={loadProfessionals}
              onSelect={handleProfessionalSelection}
              filterOption={filterProfessionalResponse}
              checkIfCodeMatchesAnyEmployeeOccupationCode={checkIfCodeMatchesAnyEmployeeOccupationCode}
              menuIsOpen={menuIsOpen}
            />
            :
            <EmployeeAsyncSelect
              toOption={createProfessionalOption}
              loadMethod={loadProfessionals}
              onSelect={handleProfessionalSelection}
              filterOption={filterProfessionalResponse}
              checkIfCodeMatchesAnyEmployeeOccupationCode={checkIfCodeMatchesAnyEmployeeOccupationCode}
              menuIsOpen={menuIsOpen}
            />
          }
          {nurseServiceSelected && (
            <>
              <Label />
              <div className="speciality-code-hint">
                {
                  <FormattedMessage
                    id="nurseSpecialityCodeHint"
                    defaultMessage="Teenusele saab lisada ainult õe kutsekoodiga ehk N-iga algava koodiga töötajaid"
                  />
                }
              </div>
            </>
          )}
        </Card>

        {alreadyAddedThtNotification && (<div className="text-danger my-2">Isik on juba teenuse juurde lisatud ja uuesti lisada ei saa.</div>)}

        <h5>
          <FormattedMessage
            id="addEmployeeModal.employeesCount"
            defaultMessage="Lisatud töötajad ({count})"
            values={{ count: employees.length }}
          />
        </h5>

        <Card>
          {employees && !!employees.length ? (
            employees.map((employee, index) => renderEmployee(employee, index))
          ) : (
            <span className="opacity05 mw-fit-content m-auto">
              <FormattedMessage
                id="addEmployeeModal.noAddedEmployees"
                defaultMessage="Lisatud töötajaid ei ole"
              />
            </span>
          )}
        </Card>
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <SecondaryFormattedButton id={closeButtonId} onClick={onClose} />
        <PrimaryFormattedButton
          id={addButtonId}
          disabled={!areRequirementsSatisfied()}
          onClick={() => onSave(employees)}
        />
      </ModalFooter>
    </Modal>
  );
};
