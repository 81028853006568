import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";
import {
  ActivityLicenseApplicationStatus,
  OfficialUser,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { PrimaryFormattedButton } from "../../../../../../Component/Button/PrimaryFormattedButton";
import { ActionButtonClasses } from "../../../../../../Component/Button/Button";
import { ProtectedComponent } from "../../../../../../Security/ProtectedComponent";
import { DangerFormattedButton } from "../../../../../../Component/Button/DangerFormattedButton";
import React, { useState } from "react";
import {
  ApplicationDeleteConfirmationModal
} from "../../../../../Portal/Application/Table/ApplicationDeleteConfirmationModal";
import { OfficialForwardingModal } from "./OfficialForwardingModal";
import { SecondaryFormattedButton } from "../../../../../../Component/Button/SecondaryFormattedButton";

interface Props {
  applicationId?: string;
  applicationNumber?: number;
  changeStatusTo: (newStatus: ActivityLicenseApplicationStatus) => void;
  deleteApplication?: (applicationId: string) => void;
  handleForwardToOfficial: (official: OfficialUser) => void;
  initiateProceeding?: () => void;
  isStateFeePaid?: boolean;
  isStateFeeUsed?: boolean;
  newStatus?: ActivityLicenseApplicationStatus;
}

export const SubmittedByOfficialActions = ({
  applicationId,
  applicationNumber,
  changeStatusTo,
  deleteApplication,
  handleForwardToOfficial,
  initiateProceeding,
  isStateFeePaid,
  isStateFeeUsed,
  newStatus
}: Props) => {
  const [isOfficialForwardingModalOpen, setIsOfficialForwardingModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAppDelete = () => {
    deleteApplication!(applicationId!);
    setIsDeleteModalOpen(false);
  };

  const renderIsStateFeeUsedCheck = () => {
    return isStateFeeUsed ? (
      <>
        {isStateFeePaid && (
          <PrimaryFormattedButton
            id="initiateProceeding"
            className={ActionButtonClasses}
            onClick={initiateProceeding}
          />
        )}
        <SecondaryFormattedButton
          id="finishProceeding"
          className={ActionButtonClasses}
          onClick={() => changeStatusTo(newStatus!)}
        />
      </>
    ) : (
      <PrimaryFormattedButton
        id="initiateProceeding"
        className={ActionButtonClasses}
        onClick={
          newStatus !== "IN_PROCEEDING" ? initiateProceeding : () => changeStatusTo(newStatus)
        }
      />
    );
  };

  return (
    <>
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}>
        <PrimaryFormattedButton
          id={"forwardToOfficial"}
          className={ActionButtonClasses}
          onClick={() => setIsOfficialForwardingModalOpen(true)}
        />
      </ProtectedComponent>
      <ProtectedFieldWrapper
        allowedRoles={[OfficialUserPrivilegesEnum.Proceede]}
      >
        {renderIsStateFeeUsedCheck()}
      </ProtectedFieldWrapper>
      <ProtectedComponent
        allowedRoles={[
          OfficialUserPrivilegesEnum.Sign,
          OfficialUserPrivilegesEnum.Proceede
        ]}
      >
        <DangerFormattedButton
          id="deleteApplication"
          className={ActionButtonClasses}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </ProtectedComponent>
      <ApplicationDeleteConfirmationModal
        applicationNumber={applicationNumber || 0}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleAppDelete}
      />
      <OfficialForwardingModal
        isOpen={isOfficialForwardingModalOpen}
        onClose={() => setIsOfficialForwardingModalOpen(false)}
        forwardToOfficial={(official) => {
          handleForwardToOfficial(official);
          setIsOfficialForwardingModalOpen(false);
        }}
      />
    </>
  );
};