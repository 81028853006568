import { ConfirmationModal } from "../../../../Component/Modal/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import React from "react";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  isTortaCheckRequired: boolean;
}

export const ConfirmAddEmployeesModal = ({isOpen, onClose, onSave, isTortaCheckRequired}: Props) => {
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  return (
    <ConfirmationModal
      isOpen={isOpen}
      saveButtonId="addServiceEmployees"
      onSave={onSave}
      onClose={onClose}
      footerClassName={isMobile ? "justify-content-center" : "justify-content-right"}
      title={
        <FormattedMessage
          id="confirmAddEmployeesModal.addNewEmployeesModalTitle"
          defaultMessage="Kas olete kindel, et soovite teenusele lisada töötaja(d)?"
        />
      }
    > {isTortaCheckRequired ? <FormattedMessage
      id="confirmAddEmployeesModal.addNewEmployeesModalSubtitle"
      defaultMessage="Töötajate lisamisel nende töötamist kontrollitakse TÖRist."
    /> : ''}
      
    </ConfirmationModal>
  );
};