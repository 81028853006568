import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import "../../../../Component/Table/Table.scss";
import { ApplicationCertificateDraft } from "../../../../Dto/ApplicationCertificate/ApplicationCertificate";
import { ApplicationTableRow } from "./Rows/ApplicationTableRow";
import { ApplicationDraftTableRow } from "./Rows/ApplicationDraftTableRow";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { RootState } from "../../../../rootReducer";
import {
  Application,
  ApplicationDraft,
  DetailedApplicationTypeEnum,
  ApplicationCertificate,
  FileReference
} from "../../../../../api_client/medre_api";

interface Props {
  reloadApplications: () => void;
  handleDraftDelete: (index: number) => void;
  savedApplications?: (ApplicationDraft | ApplicationCertificateDraft)[];
  submittedApplications?: (Application | ApplicationCertificate)[];
  handleApplicationFileUpdate: (
    applicationIndex: number,
    fileReferences: FileReference[]
  ) => void;
}

export const ApplicationTable = ({
  savedApplications,
  submittedApplications,
  reloadApplications,
  handleApplicationFileUpdate,
  handleDraftDelete
}: Props) => {
  const person = useSelector((state: RootState) => state.person);
  const [expandedRowNumber, setExpandedRowNumber] = useState<number>();

  const handleAppDraftDelete = (index: number) => {
    setExpandedRowNumber(undefined);
    handleDraftDelete(index);
  };

  const handleRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      setExpandedRowNumber(rowNumber);
    } else {
      setExpandedRowNumber(undefined);
    }
  };

  const getName = (
    application: ApplicationCertificateDraft | ApplicationDraft | Application
  ) => {
    switch (application.type) {
      case DetailedApplicationTypeEnum.Occupation:
        if (application.additionalOccupation) {
          return `${application.occupation?.name} ja ${application.additionalOccupation.name}`;
        }
        return application.occupation?.name;
      case DetailedApplicationTypeEnum.Specialization:
        return application.speciality?.name;
      case DetailedApplicationTypeEnum.ApplicationCertificate:
        return (application as ApplicationCertificateDraft).title;
      default:
        return "";
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>
            <GeneralFM id="date" />
          </th>
          <th>
            <ApplicationFM id="applicationOccupationOrSpeciality" />
          </th>
          <th>
            <ApplicationFM id="state" />
          </th>
          <th>
            <FormattedMessage
              id="applicationTable.information"
              defaultMessage="Taotluse lisainformatsioon"
            />
          </th>
          <th>
            <ApplicationFM id="applicationNumber" />
          </th>
        </tr>
      </thead>
      <tbody>
        {savedApplications?.map((draft, i) => (
          <ApplicationDraftTableRow
            key={i}
            index={i}
            handleDraftDelete={() => handleAppDraftDelete(i)}
            reloadApplications={reloadApplications}
            isOpen={expandedRowNumber === i}
            draft={draft}
            person={person}
            name={getName(draft)}
            handleRowClick={handleRowClick}
          />
        ))}
        {submittedApplications?.map((application, i) => (
          <ApplicationTableRow
            key={i}
            index={(savedApplications?.length || 0) + i}
            isOpen={expandedRowNumber === (savedApplications?.length || 0) + i}
            application={application}
            person={person}
            name={getName(application)}
            handleRowClick={handleRowClick}
            handleApplicationFileUpdate={handleApplicationFileUpdate}
            reloadApplications={reloadApplications}
          />
        ))}
      </tbody>
      <tfoot>
        <tr />
      </tfoot>
    </table>
  );
};
