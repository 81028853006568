import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../rootReducer";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { Occupation } from "../../../../../api_client/medre_api";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

interface Props {
  selectedOccupationCode: string;
  availableOccupations: Occupation[];
}

export const AdditionalOccupationSelection = ({
  selectedOccupationCode,
  availableOccupations
}: Props) => {
  const specialOccupationCodes = useSelector(
    (state: RootState) => state.config.specialOccupationCodes
  );
  const isMidwife = selectedOccupationCode === specialOccupationCodes?.midwife;
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const additionalOccupationCode = isMidwife
    ? specialOccupationCodes?.nurse
    : undefined;

  const dispatch = useDispatch();

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  function handleChange() {
    if (applicationDraft.additionalOccupation) {
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "additionalOccupation",
          undefined
        )
      );
    } else {
      const additionalOccupation = availableOccupations.filter(
        (occupation) => occupation.code === additionalOccupationCode
      )[0];
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "additionalOccupation",
          additionalOccupation
        )
      );
    }
  }

  if (!isMidwife) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col className={isMobile ? "" : "offset-1"}>
          <p className={isMobile ? "" : "ml-5"}>
            <FormattedMessage
              id={"additionalOccupationSelection.midwifeDescription"}
              defaultMessage={
                "Ämmaemanda haridusega on võimalik taotleda ka õe kutset"
              }
            />
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="offset-sm-1">
          <FormGroup
            className={isMobile ? "d-flex justify-content-left p-0" : "ml-4"}
            check={true}
          >
            <Checkbox
              isChecked={!!applicationDraft.additionalOccupation}
              id={"additional-occupation-selection"}
              labelText={
                <FormattedMessage
                  id={"additionalOccupationSelection.midwifeMessage"}
                  defaultMessage={"Taotle Õe kutset"}
                />
              }
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
