import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { prettifyPersonStatus } from "../../../Dto/Person/Person";
import { Card } from "./Card";
import { RootState } from "../../../rootReducer";
import ApplicantDetailsSection from "./ApplicantDetailsSection";
import {
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI,
  OfficialUserPrivilegesEnum,
  Person
} from "../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "./ApplicantNavigationList";
import { LinkButton } from "../../../Component/Button/LinkButton";
import { ConfirmationModal } from "../../../Component/Modal/ConfirmationModal";
import { ProtectedFieldWrapper } from "../../../Security/ProtectedFieldWrapper";
import "./HealthCareProfessionalDetails.scss";
import { API, getBaseUrl } from "../../../api";
import { displayAlert } from "../../../Util/AlertUtil";
import { AlertType } from "../../../Dto/Alert/AlertItem";

interface Props {
  person: Person;
  refresh: () => void;
}

export const HealthCareProfessionalDetails = ({ person, refresh }: Props) => {
  const prescriptionPowerCode = useSelector(
    (state: RootState) => state.config.prescriptionPowerSpecialityCode
  );
  const prescriptionRightCode = useSelector(
    (state: RootState) => state.config.prescriptionRightSpecialityCode
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const dispatch = useDispatch();

  const getDateOfRegistration = (): string =>
    person.dateAddedToRegistry
      ? moment(person.dateAddedToRegistry).format("DD.MM.YYYY")
      : "-";

  const getDateOfDeath = (): string =>
    person.dateOfDeath ? moment(person.dateOfDeath).format("DD.MM.YYYY") : "-";

  const handleModalClose = (): void => {
    setIsConfirmationModalOpen(false);
  };

  const handleMarkResidencyCompleted = (): void => {
    officialUserDataAPI(undefined, getBaseUrl(), API)
      .markPersonResidencyCompleted(person.id!, person.residencyInfo?.id!)
      .then(()=>{
        setIsConfirmationModalOpen(false);
        displayAlert("residencyCompletionSuccess", AlertType.Success, dispatch);
        refresh();
      })
      .catch(() => {
        setIsConfirmationModalOpen(false);
        displayAlert("residencyCompletionFailed", AlertType.Danger, dispatch);
      });
  };

  const renderMarkResidencyCompletedButton = () => {
    if (!person.residencyInfo) {
      return;
    }
    if (!person.specialities) {
      return;
    }

    return person.residencyInfo.specialityCode
    && person.specialities?.length !== 0
    && person.specialities.some(speciality => speciality.code === person.residencyInfo?.specialityCode)
      ? (
        <ProtectedFieldWrapper
          allowedRoles={[
            OfficialUserPrivilegesEnum.Proceede,
            OfficialUserPrivilegesEnum.Sign]}
        >
          <LinkButton
            className="p-0"
            id="markResidencyCompleted"
            onClick={(): void => {
              setIsConfirmationModalOpen(true);
            }}/>
        </ProtectedFieldWrapper>
      )
      :
      <></>;
  };

  const renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={
          <FormattedMessage
            id="residency.confirm.modal.title"
            defaultMessage="Oled sa kindel, et soovid isiku residentuuri lõpetada?"
          />
        }
        saveButtonId="markCompleted"
        closeButtonId="cancelHide"
        onSave={handleMarkResidencyCompleted}
        onClose={handleModalClose}
      />
    );
  };

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.HealthcareProfessionalDetails}
    >
      <div className="health-care-professional-details-container">
        <dl className="mt-3 p-3">
          <dt className="text-title">
            <FormattedMessage
              id="healthCareProfessional.statusTHT"
              defaultMessage="THT olek"
            />
          </dt>
          <dd className="text-capitalize">
            { person.status ? prettifyPersonStatus(person.status) : "-" }
          </dd>
          <dt className="text-title">
            <FormattedMessage
              id="healthCareProfessional.registerDateTHT"
              defaultMessage="THT registrisse lisamise kuupäev"
            />
          </dt>
          <dd className="text-capitalize">{ getDateOfRegistration() }</dd>
          <dt className="text-title">
            <FormattedMessage
              id="healthCareProfessional.archivingDateTHT"
              defaultMessage="THT arhiveerimise kuupäev"
            />
          </dt>
          <dd className="text-capitalize">{ getDateOfDeath() }</dd>
          { person.residencyInfo && (
            <>
              <dt className="text-title">
                <FormattedMessage
                  id="healthCareProfessional.residencyInfo"
                  defaultMessage="Residentuuri info"
                />
              </dt>
              <dd className="text-capitalize d-flex align-items-center ddContainer">
                <FormattedMessage
                  id="healthCareProfessional.residencyStudying"
                  defaultMessage="Õpib residentuuris"
                />
                { renderMarkResidencyCompletedButton() }
                { renderConfirmationModal() }
              </dd>
              <dt className="text-title">
                <FormattedMessage
                  id="healthCareProfessional.curriculumName"
                  defaultMessage="Õppekava nimi"
                />
              </dt>
              <dd className="text-capitalize">
                { person.residencyInfo.curriculumName || "-" }
              </dd>
              <dt className="text-title">
                <FormattedMessage
                  id="healthCareProfessional.schoolName"
                  defaultMessage="Kooli nimi"
                />
              </dt>
              <dd className="text-capitalize">
                { person.residencyInfo.schoolName || "-" }
              </dd>
              <dt className="text-title">
                <FormattedMessage
                  id="healthCareProfessional.courseNumber"
                  defaultMessage="Kursuse number"
                />
              </dt>
              <dd className="text-capitalize">
                { person.residencyInfo.studyYear || "-" }
              </dd>
              <dt className="text-title">
                <FormattedMessage
                  id="healthCareProfessional.specialtyName"
                  defaultMessage="Eriala nimi"
                />
              </dt>
              <dd className="text-capitalize">
                { person.residencyInfo.specialityName || "-" }
              </dd>
            </>
          ) }
        </dl>
        <div className="applications p-3">
          <div>
            <h3>
              <FormattedMessage
                id="healthCareProfessional.professions"
                defaultMessage="Kutsed"
              />
            </h3>
            { person.occupationCodes?.map((occupation) => (
              <Card
                key={occupation.code}
                title={occupation.name + " (" + occupation.code + ")"}
                subtitle={
                  "Registeerimise kuupäev: " +
                  moment(occupation.createdAt).format("DD.MM.YYYY")
                }
              />
            )) }
          </div>
          <div>
            <h3>
              <FormattedMessage
                id="healthCareProfessional.specialties"
                defaultMessage="Erialad"
              />
            </h3>
            { person.specialities?.map((speciality) => {
              if (
                speciality.code === prescriptionPowerCode ||
                speciality.code === prescriptionRightCode
              ) {
                return;
              }
              return (
                <Card
                  key={speciality.code}
                  title={speciality.name}
                  subtitle={
                    "Registeerimise kuupäev: " +
                    moment(speciality.createdAt).format("DD.MM.YYYY")
                  }
                />
              );
            }) }
          </div>
        </div>
      </div>
    </ApplicantDetailsSection>
  );
};
