import React from "react";
import { createFilter } from "react-select";
import { useSelector } from "react-redux";

import { Option, SingleSelect } from "./SingleSelect";
import genders_et from "../../../translations/genders/et.json";
import genders_en from "../../../translations/genders/en.json";
import genders_ru from "../../../translations/genders/ru.json";
import { RootState } from "../../rootReducer";
import { createGenderOption } from "../../Util/OptionUtil";
import { PersonGenderEnum } from "../../../api_client/medre_api";

interface Props {
  inputId?: string;
  className?: string;
  disabled?: boolean;
  selectedGender?: PersonGenderEnum;
  handleOptionChange: (option: Option) => void;
}

export const GenderSelect = ({
  inputId,
  disabled,
  className,
  selectedGender,
  handleOptionChange
}: Props) => {
  const locale = useSelector((state: RootState) => state.locale);
  const genders = [
    createGenderOption(PersonGenderEnum.Male),
    createGenderOption(PersonGenderEnum.Female)
  ];

  const getGenderOption = (option: Option) => {
    // tslint:disable-next-line:no-any
    let genderTranslations: any;
    switch (locale) {
      case "en":
        genderTranslations = genders_en;
        break;
      case "ru":
        genderTranslations = genders_ru;
        break;
      case "et":
      default:
        genderTranslations = genders_et;
        break;
    }
    const gender = option.value;
    return genderTranslations["Gender." + gender];
  };

  return (
    <SingleSelect
      inputId={inputId}
      disabled={disabled}
      className={className || "input"}
      value={createGenderOption(selectedGender)}
      options={genders}
      hideSearchIcon={true}
      handleOptionChange={handleOptionChange}
      filterOptions={createFilter({
        ignoreAccents: false,
        stringify: (option) => getGenderOption(option)
      })}
    />
  );
};
