import React from "react";
import { Card } from "reactstrap";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Stepper } from "../../../Component/ProgressBar/Stepper";
import {
  occupationApplicationSteps,
  PortalApplicationStep,
  specialityApplicationSteps
} from "./ApplicationStep";
import { WizardProgressBar } from "../../../Component/ProgressBar/WizardProgressBar";
import { OccupationSelection } from "../../Shared/Application/Occupation/OccupationSelection";
import { SpecialitySelection } from "../../Shared/Application/Speciality/SpecialitySelection";
import { RootState } from "../../../rootReducer";
import { ApplicationPersonalData } from "../../Shared/Application/ApplicationPersonalData";
import { ApplicationContactInfo } from "../../Shared/Application/ApplicationContactInfo";
import { ApplicationEducation } from "../../Shared/Application/Education/ApplicationEducation";
import { PortalApplicationOverView } from "./OverView/PortalApplicationOverView";
import { DetailedApplicationTypeEnum } from "../../../../api_client/medre_api";
import { PortalApplicationFooter } from "./Footer/PortalApplicationFooter";

interface Props {
  type: DetailedApplicationTypeEnum;
}

export const ApplicationContainer = (props: Props) => {
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const { currentStep, type, applicationNumber } = applicationDraft;
  const steps =
    type === DetailedApplicationTypeEnum.Occupation
      ? occupationApplicationSteps
      : specialityApplicationSteps;

  const renderOccupationContent = () => {
    switch (currentStep) {
      case PortalApplicationStep.SPECIALITY_OCCUPATION:
        return <OccupationSelection />;
      case PortalApplicationStep.PERSONAL_DATA:
        return <ApplicationPersonalData />;
      case PortalApplicationStep.CONTACT_INFO:
        return <ApplicationContactInfo />;
      case PortalApplicationStep.EDUCATION_INFO:
        return <ApplicationEducation />;
      case PortalApplicationStep.OVERVIEW:
        return <PortalApplicationOverView />;
      default:
        return <OccupationSelection />;
    }
  };

  const renderSpecialityContent = () => {
    switch (currentStep) {
      case PortalApplicationStep.SPECIALITY_OCCUPATION:
        return <SpecialitySelection />;
      case PortalApplicationStep.PERSONAL_DATA:
        return <ApplicationPersonalData />;
      case PortalApplicationStep.CONTACT_INFO:
        return <ApplicationContactInfo />;
      case PortalApplicationStep.EDUCATION_INFO:
        return <ApplicationEducation />;
      case PortalApplicationStep.OVERVIEW:
        return <PortalApplicationOverView />;
      default:
        return <SpecialitySelection />;
    }
  };

  const renderContent = () =>
    props.type === DetailedApplicationTypeEnum.Occupation
      ? renderOccupationContent()
      : renderSpecialityContent();

  const renderTitle = () => {
    if (props.type === DetailedApplicationTypeEnum.Occupation) {
      if (applicationNumber) {
        return (
          <FormattedMessage
            id="occupationSelection.newProceedingTitleWithNumber"
            defaultMessage="Kutse taotluse {applicationNumber} esitamine"
            values={{ applicationNumber: applicationNumber }}
          />
        );
      }
      return (
        <FormattedMessage
          id="occupationSelection.newProceedingTitle"
          defaultMessage="Kutse taotluse esitamine"
        />
      );
    }
    if (applicationNumber) {
      return (
        <FormattedMessage
          id="specializationSelection.newProceedingTitleWithNumber"
          defaultMessage="Eriala taotluse {applicationNumber} esitamine"
          values={{ applicationNumber: applicationNumber }}
        />
      );
    }
    return (
      <FormattedMessage
        id="specializationSelection.newProceedingTitle"
        defaultMessage="Eriala taotluse esitamine"
      />
    );
  };

  return (
    <div className="application-container">
      <h1 className="ml-3 mt-3">{renderTitle()}</h1>

      <Stepper className={"m-3"} currentStep={currentStep || 0} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <PortalApplicationFooter
            currentStep={currentStep || 0}
            steps={steps}
          />
        </div>
      </Card>
    </div>
  );
};
