import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ContactInfoForm, formInfoToContactInfo } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { ContactInfoEditFormFields } from "../../../Shared/Application/ContactInfo/ContactInfoEditFormFields";
import "./ApplicantContactInfoEditModal.scss";
import { API, getBaseUrl } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  ContactInfo,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as userDataAPI
} from "../../../../../api_client/medre_api";

interface Props {
  personId?: string;
  personIdCode?: string;
  contactInfo?: ContactInfo;
  isOpen: boolean;
  onClose: () => void;
  onSave: (contactInfo: ContactInfo) => void;
}

export const ApplicantContactInfoEditModal = ({
  personId,
  personIdCode,
  contactInfo,
  isOpen,
  onClose,
  onSave
}: Props) => {
  const dispatch = useDispatch();
  const dataAPI = userDataAPI(undefined, getBaseUrl(), API);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isForceValidation, setIsForceValidation] = useState<boolean>(false);
  const [contactInfoFromRR, setContactInfoFromRR] = useState<ContactInfo>();
  const [formInfo, setFormInfo] = useState<ContactInfoForm>(
    new ContactInfoForm(contactInfo || ({} as ContactInfo))
  );

  useEffect(() => {
    if (personIdCode) {
      dataAPI.getContactInfoFromRR1(personIdCode).then((result) => setContactInfoFromRR(result.data));
    }
  }, []);

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      setFormInfo((prevState) => {
        return { ...prevState, [field]: value };
      });
    },
    []
  );

  const handleSave = () => {
    if (isFormValid) {
      let newContactInfo = formInfoToContactInfo(formInfo);
      dataAPI
        .updatePersonContactInfo(personId!, newContactInfo, {
          withCredentials: true
        })
        .then(() => {
          const alertMessage = (
            <FormattedMessage
              id="contactInfoEditFooter.successfulSave"
              defaultMessage="Kontaktandmed salvestatud"
            />
          );
          const alert = {
            id: 0,
            type: AlertType.Success,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          onSave(newContactInfo);
        })
        .catch(() => {
          const alertMessage = (
            <FormattedMessage
              id="contactInfoEditFooter.unsuccessfulSave"
              defaultMessage="Kontaktandmete muutmine ebaõnnestus"
            />
          );
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        });
    } else {
      setIsForceValidation(true);
      setTimeout(() => setIsForceValidation(false), 500);
    }
  };

  return (
    <Modal isOpen={isOpen} className="applicant-contact-info-edit-modal">
      <ModalHeader>Kontaktandmete muutmine</ModalHeader>
      <ModalBody>
        <ContactInfoEditFormFields
          formInfo={formInfo}
          hasEstonianPersonalCode={!!personIdCode}
          setIsFormValid={setIsFormValid}
          updateContactInfo={updateContactInfo}
          isForceValidation={isForceValidation}
          contactInfoFromRR={contactInfoFromRR}
          personIdCode={personIdCode}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="back" onClick={() => onClose()} />
        <PrimaryFormattedButton id="change" onClick={handleSave} />
      </ModalFooter>
    </Modal>
  );
};
