export const USER_INFO = "user_info";
export const SCOPE = "openid";
export const LOCALE = "locale";
export const APPLICATION_DRAFT = "applicationDraft";
export const ACTIVITY_LICENSE = "activityLicense";
export const LICENSE_ACQUISITION_APPLICATION = "licenseAcquisitionApplication";
export const REPRESENTATIVE_PREFIX = "REPRESENTATIVE_";
export const POWER_OF_ATTORNEY_TYPE_PREFIX = "POWER_OF_ATTORNEY_";
export const AUTHENTICATION_FAILURE_COUNT = "authenticationFailureCount";
export const AUTHORIZATION_FAILURE_COUNT = "authorizationFailureCount";
export const SPECIALISTS_PREFIXES = ["G", "H", "T"];
export const HEALTHCARE_PROFESSIONALS_PREFIXES = ["D", "N", "M"];
export const PHARMACISTS_PREFIXES = ["P", "F"];
export const P_PHARMACIST_PREFIX = "P";
export const D_DOCTOR_PREFIX = "D";
export const DOCTOR = "Arst";
export const DENTIST = "Hambaarst";

export const IN_ADS_ADDRESS_URL = "https://inaadress.maaamet.ee/inaadress/gazetteer";

export const TABLET_MAX_WIDTH = 991;
export const MOBILE_MAX_WIDTH = 767;

export const PHYSIOTHERAPIST_ID = "82c6d87c-8a3a-48c5-bf0c-d3d18599159c";
export const PSYCHOLOGIST_ID = "0b213d69-96eb-45ce-b68e-38a069ebc8e8";
export const SPEECH_THERAPIST_ID = "3375282c-0510-4ad4-ba0d-3eba84012c67";
