import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { RootState } from "../../../../rootReducer";
import {
  ApplicationDraft,
  ActivityLicenseApplicationChannelTypeEnum
} from "../../../../../api_client/medre_api";
import { ArrivalChannelContainer } from "./ArrivalChannelContainer";

export const ApplicationArrivalChannel = () => {
  const dispatch = useDispatch();
  const { channelType } = useSelector(
    (state: RootState) =>
      (state.applicationDraft as ApplicationDraft) ||
      ActivityLicenseApplicationChannelTypeEnum.EMail
  );

  useEffect(() => {
    if (!channelType) {
      dispatch(
        applicationDraftActions.updateApplicationDraft(
          "channelType",
          ActivityLicenseApplicationChannelTypeEnum.EMail
        )
      );
    }
    dispatch(
      applicationDraftActions.updateApplicationDraft("saveAllowed", true)
    );
  }, [dispatch, channelType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const channel = e.target.value as ActivityLicenseApplicationChannelTypeEnum;
    dispatch(
      applicationDraftActions.updateApplicationDraft("channelType", channel)
    );
  };

  return (
    <ArrivalChannelContainer
      handleChange={handleChange}
      channelType={channelType}
      selfServiceDisplayed={true}
    />
  );
};
