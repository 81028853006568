import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { localeActions } from "../../Locale/localeActions";
import { RootState } from "../../rootReducer";
import "../../Component/Dropdown/LanguageDropdown.scss";
import { Locale } from "../../Dto/Locale/Locale";
import { FormattedMessage } from "react-intl";

const options: Array<{ value: Locale; label: string }> = [
  {
    value: "et",
    label: "Eesti keeles",
  },
  {
    value: "en",
    label: "In English",
  },
  {
    value: "ru",
    label: "На русском",
  },
];

export const LanguageDropdown = () => {
  const isLocalizationSelectorShowed = useSelector(
    (state: RootState) => state.config.isLocalizationSelectorShowed
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggle = useCallback(() => {
    setDropdownOpen((prevState) => !prevState);
  }, [setDropdownOpen]);

  const dispatch = useDispatch();
  const locale = useSelector((state: RootState) => state.locale);
  const [activeLanguageOption, setActiveLanguageOption] = useState<string>();

  useEffect(() => {
    switch (locale) {
      case "en":
        setActiveLanguageOption("ENG");
        break;
      case "ru":
        setActiveLanguageOption("RUS");
        break;
      default:
        setActiveLanguageOption("EST");
        break;
    }
  }, [locale]);

  const selectLanguage = useCallback(
    (event: React.SyntheticEvent<HTMLElement>) => {
      const { lang } = event.currentTarget.dataset as { lang: Locale };
      dispatch(localeActions.setLocale(lang));
    },
    [dispatch]
  );

  return isLocalizationSelectorShowed ? (
    <div className="language-selection-container px-3">
      <span id="choose-language-text" className="d-block language-text">
        <FormattedMessage id="languageDropDown.header" defaultMessage="Keel:" />
      </span>
      <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
        <DropdownToggle
          id="choose-language-button"
          color="link"
          className="p-0 border-0 text-decoration-none"
          aria-expanded={isDropdownOpen}
          aria-labelledby="choose-language-text choose-language-button"
          aria-haspopup={true}
        >
          <span className="language-selection">
            {activeLanguageOption}
            <FontAwesomeIcon icon={faChevronDown} className="option m-1" />
          </span>
        </DropdownToggle>
        <DropdownMenu right={true} aria-labelledby="choose-language-text">
          {options.map(({ value, label }) => (
            <DropdownItem
              key={value}
              data-lang={value}
              onClick={selectLanguage}
              active={value === locale}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
};
