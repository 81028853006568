import React, { useState } from "react";
import { Footer } from "../Footer/Footer";
import "../MainContainer.scss";
import { Col, Row } from "reactstrap";
import { PortalContentContainer } from "./ContentContainer/PortalContentContainer";
import { Header } from "../Header/Header";
import { useSelector } from "react-redux";
import { UserSideMenu } from "./SideMenu/UserSideMenu";
import { PublicSideMenu } from "./SideMenu/PublicSideMenu";
import { RootState } from "../../rootReducer";

interface Props {
    headerColor: string;
}

export const PortalMainContainer = React.memo(({
    headerColor
}: Props) => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(true);

  const sideMenuClass = isSideMenuCollapsed ? " collapsed" : " expanded";
  const toggleSideMenuCollapse = () => setIsSideMenuCollapsed(!isSideMenuCollapsed);
  const hideSideMenuInMobileView = isSideMenuCollapsed ? " hide-1200" : "";
  const hideElementsAndShowOnlyMenu = isSideMenuCollapsed ? "" : " hide-1100";
  const expandMiddleRow = isSideMenuCollapsed ? "" : " expand-middle-row";

  return (
    <div className="main-container">
      <Row className={"header-row m-0"}>
        <Header headerColor={headerColor}
                onClick={toggleSideMenuCollapse}
                isSideMenuCollapsed={isSideMenuCollapsed}
                hasLanguageDropdown={true}/>
      </Row>
      <Row className={"middle-row m-0" + expandMiddleRow}>
        <div className={"side-menu-column p-0" + hideSideMenuInMobileView + sideMenuClass}>
          {userInfo
            ? <UserSideMenu isSideMenuCollapsed={isSideMenuCollapsed} roles={userInfo?.roles}/>
            : <PublicSideMenu isSideMenuCollapsed={isSideMenuCollapsed}/>
          }
        </div>
        <Col className={"content-column p-0" + hideElementsAndShowOnlyMenu}>
          <PortalContentContainer/>
        </Col>
      </Row>
      <Row className={"footer-row m-0" + hideElementsAndShowOnlyMenu}>
        <Footer/>
      </Row>
    </div>
  );
});
