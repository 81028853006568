import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";
import {
  ActivityLicenseStatusEnum,
  ActivityLicenseApplicationStatus
} from "../../api_client/medre_api";

const ActivityLicenseStatusMessages = defineMessages({
  [ActivityLicenseStatusEnum.Valid]: {
    id: "ActivityLicenseStatusFM.VALID",
    defaultMessage: "Kehtiv"
  },
  [ActivityLicenseStatusEnum.Invalid]: {
    id: "ActivityLicenseStatusFM.INVALID",
    defaultMessage: "Kehtetu"
  },
  [ActivityLicenseStatusEnum.Paused]: {
    id: "ActivityLicenseStatusFM.PAUSED",
    defaultMessage: "Peatatud"
  }
});

export const ActivityLicenseStatusFM = ({
  id
}: {
  id: keyof typeof ActivityLicenseStatusMessages;
}) => <FormattedMessage {...ActivityLicenseStatusMessages[id]} />;

const ActivityLicenseApplicationStatusMessages = defineMessages({
  [ActivityLicenseApplicationStatus.Saved]: {
    id: "ActivityLicenseApplicationStatusFM.SAVED",
    defaultMessage: "Salvestatud"
  },
  [ActivityLicenseApplicationStatus.Registering]: {
    id: "ActivityLicenseApplicationStatusFM.REGISTERING",
    defaultMessage: "Registreerimisel"
  },
  [ActivityLicenseApplicationStatus.Submitted]: {
    id: "ActivityLicenseApplicationStatusFM.SUBMITTED",
    defaultMessage: "Esitatud"
  },
  [ActivityLicenseApplicationStatus.ProceedingPending]: {
    id: "ActivityLicenseApplicationStatusFM.PROCEEDING_PENDING",
    defaultMessage: "Menetluse ootel"
  },
  [ActivityLicenseApplicationStatus.InProceeding]: {
    id: "ActivityLicenseApplicationStatusFM.IN_PROCEEDING",
    defaultMessage: "Menetluses"
  },
  [ActivityLicenseApplicationStatus.Paused]: {
    id: "ActivityLicenseApplicationStatusFM.PAUSED",
    defaultMessage: "Menetlus peatatud"
  },
  [ActivityLicenseApplicationStatus.OnSiteInspectionPending]: {
    id: "ActivityLicenseApplicationStatusFM.ON_SITE_INSPECTION_PENDING",
    defaultMessage: "Paikvaatluse ootel"
  },
  [ActivityLicenseApplicationStatus.ProtocolInPreparation]: {
    id: "ActivityLicenseApplicationStatusFM.PROTOCOL_IN_PREPARATION",
    defaultMessage: "Protokoll koostamisel"
  },
  [ActivityLicenseApplicationStatus.ReadyForDecision]: {
    id: "ActivityLicenseApplicationStatusFM.READY_FOR_DECISION",
    defaultMessage: "Otsuse koostamiseks valmis"
  },
  [ActivityLicenseApplicationStatus.UploadAcceptedDecision]: {
    id: "ActivityLicenseApplicationStatusFM.UPLOAD_ACCEPTED_DECISION",
    defaultMessage: "Otsus koostamisel"
  },
  [ActivityLicenseApplicationStatus.SignAcceptedDecision]: {
    id: "ActivityLicenseApplicationStatusFM.SIGN_ACCEPTED_DECISION",
    defaultMessage: "Rahuldamisele suunatud ja allkirjastamise ootel"
  },
  [ActivityLicenseApplicationStatus.SignDeclinedDecision]: {
    id: "ActivityLicenseApplicationStatusFM.SIGN_DECLINED_DECISION",
    defaultMessage: "Keeldumisele suunatud ja allkirjastamise ootel"
  },
  [ActivityLicenseApplicationStatus.Accepted]: {
    id: "ActivityLicenseApplicationStatusFM.ACCEPTED",
    defaultMessage: "Rahuldatud"
  },
  [ActivityLicenseApplicationStatus.Declined]: {
    id: "ActivityLicenseApplicationStatusFM.DECLINED",
    defaultMessage: "Keeldutud"
  },
  [ActivityLicenseApplicationStatus.Finished]: {
    id: "ActivityLicenseApplicationStatusFM.FINISHED",
    defaultMessage: "Lõpetatud"
  },
  [ActivityLicenseApplicationStatus.SubmittedByOfficial]: {
    id: "ActivityLicenseApplicationStatusFM.SUBMITTED_BY_OFFICIAL",
    defaultMessage: "Esitanud ametnik"
  },
  [ActivityLicenseApplicationStatus.ProceedingReopened]: {
    id: "ActivityLicenseApplicationStatusFM.PROCEEDING_REOPENED",
    defaultMessage: "Menetlus taasavatud"
  }
});

export const ActivityLicenseApplicationStatusFM = ({
  id
}: {
  id: keyof typeof ActivityLicenseApplicationStatusMessages;
}) => <FormattedMessage {...ActivityLicenseApplicationStatusMessages[id]} />;

type Message =
  | "proceedingType"
  | "applicationNumber"
  | "submissionDate"
  | "stateFee"
  | "stateFeeSum"
  | "state"
  | "stateFeeDeadline"
  | "businessArea"
  | "activityLocations"
  | "documents"
  | "licenseNumber"
  | "startDate"
  | "endDate"
  | "decisions"
  | "tisIntegration"
  | "tisIntegrationConfirmation"
  | "activityLocation"
  | "suspensionReason"
  | "suspensionPeriod"
  | "suspensionExplanationNotes"
  | "servicesToDiscard";

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

export const ActivityLicenseFM = (props: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    proceedingType: {
      id: "activityLicenseFM.proceedingType",
      defaultMessage: "Taotluse liik"
    },
    applicationNumber: {
      id: "activityLicenseFM.applicationNumber",
      defaultMessage: "Taotluse number"
    },
    submissionDate: {
      id: "activityLicenseFM.submissionDate",
      defaultMessage: "Esitamise kuupäev"
    },
    stateFee: {
      id: "activityLicenseFM.stateFee",
      defaultMessage: "Riigilõiv"
    },
    stateFeeSum: {
      id: "activityLicenseFM.stateFeeSum",
      defaultMessage: "Riigilõivu summa"
    },
    state: {
      id: "activityLicenseFM.state",
      defaultMessage: "Tegevusloa staatus"
    },
    stateFeeDeadline: {
      id: "activityLicenseFM.stateFeeDeadline",
      defaultMessage: "Riigilõivu tähtaeg"
    },
    businessArea: {
      id: "activityLicenseFM.businessArea",
      defaultMessage: "Tegevusala"
    },
    activityLocations: {
      id: "activityLicenseFM.activityLocations",
      defaultMessage: "Tegevuskohad"
    },
    documents: {
      id: "activityLicenseFM.documents",
      defaultMessage: "Lisatud dokumendid"
    },
    licenseNumber: {
      id: "activityLicenseFM.licenseNumber",
      defaultMessage: "Tegevusloa number"
    },
    companyName: {
      id: "activityLicenseFM.companyName",
      defaultMessage: "Teenuse osutaja"
    },
    hospitalType: {
      id: "activityLicenseFM.hospitalType",
      defaultMessage: "Haigla liik"
    },
    locationServiceName: {
      id: "activityLicenseFM.locationServiceName",
      defaultMessage: "Teenus: {serviceName}"
    },
    receptionRooms: {
      id: "activityLicenseFM.receptionRooms",
      defaultMessage: "Vastuvõturuumid"
    },
    startDate: {
      id: "activityLicenseFM.startDate",
      defaultMessage: "Väljaandmise alguskuupäev"
    },
    endDate: {
      id: "activityLicenseFM.endDate",
      defaultMessage: "Kehtivusaeg"
    },
    decisions: {
      id: "activityLicenseFM.decisions",
      defaultMessage: "Otsused"
    },
    savedDate: {
      id: "activityLicenseFM.savedDate",
      defaultMessage: "Salvestamise kuupäev"
    },
    tisIntegration: {
      id: "activityLicenseFM.tisIntegration",
      defaultMessage: "TIS liidestus"
    },
    tisIntegrationConfirmation: {
      id: "activityLicenseFM.tisIntegrationConfirmation",
      defaultMessage: "Kinnitan, et liidestus Tervise Infosüsteemiga on olemas"
    },
    activityLocation: {
      id: "activityLicenseFM.activityLocation",
      defaultMessage: "Tegevuskoht"
    },
    suspensionReason: {
      id: "activityLicenseFM.suspensionReason",
      defaultMessage: "Peatamise põhjus"
    },
    suspensionPeriod: {
      id: "activityLicenseFM.suspensionPeriod",
      defaultMessage: "Peatamise periood"
    },
    suspensionExplanationNotes: {
      id: "activityLicenseFM.suspensionExplanationNotes",
      defaultMessage: "Peatamise põhjendus"
    },
    servicesToDiscard: {
      id: "activityLicenseFM.servicesToDiscard",
      defaultMessage: "Loobutavad teenused"
    },
    numberOfAmbulanceBrigades: {
      id: "activityLicense.numberOfAmbulanceBrigades",
      defaultMessage: "Kiirabibrigaadide arv"
    },
    numberOfNursingBrigades: {
      id: "activityLicense.numberOfNursingBrigades",
      defaultMessage: "Õebrigaadide arv"
    },
    numberOfMedicalBrigades: {
      id: "activityLicense.numberOfMedicalBrigades",
      defaultMessage: "Arstibrigaadide arv"
    },
    numberOfReanimobileBrigades: {
      id: "activityLicense.numberOfReanimobileBrigades",
      defaultMessage: "Reanimobiilibrigaadide arv"
    },
    numberOfFirstAidBrigades: {
      id: "activityLicense.numberOfFirstAidBrigades",
      defaultMessage: "Esmase abi brigaadide arv"
    }
  });

  return <FormattedMessage {...messages[props.id as Message]} {...props} />;
};
