import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import { Option } from "../../../../Component/Select/SelectTypes";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";
import { AvalikudIsikudLoetlevadTeenustApiFactory as publicEntityAPI } from "../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../api";

export const PublicHealthcareEmployeesFilters = () => {
  const dispatch = useDispatch();
  const [occupationOptions, setOccupationOptions] = useState<Array<Option>>([]);
  const [specialityOptions, setSpecialityOptions] = useState<Array<Option>>([]);

  useEffect(() => {
    Promise.all([
      publicEntityAPI(undefined, getBaseUrl(), API).getOccupations({
        withCredentials: true
      }),
      publicEntityAPI(undefined, getBaseUrl(), API).getAllSpecialities({
        withCredentials: true
      })
    ]).then(([{ data: occupations }, { data: specialities }]) => {
      setOccupationOptions(
        occupations.map(({ name: label }) => ({ value: label, label }))
      );
      setSpecialityOptions(
        specialities.map(
          ({ name: label, code: value }) => ({ value: value, label: label } as Option)
        )
      );
    });
  }, []);

  const state = useSelector(
    filterSelectors.selectPublicHealthcareEmployeesFilters,
    shallowEqual
  );
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setPublicHealthcareEmployeesFilter({ key, data }));
    },
    [dispatch]
  );
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );

  const handleChangeSelect = useCallback(
    ({ value }: Option, name) => {
      set(name, value);
    },
    [set]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <FilterField
          id="healthcareEmployeesFiltersOccupationCode"
          label={
            <FormattedMessage
              id="filters.occupationCodeLabel"
              defaultMessage="THT kood"
            />
          }
          field={
            <Input
              id="healthcareEmployeesFiltersOccupationCode"
              value={state.occupationCode}
              onChange={handleChangeInput}
              name="occupationCode"
            />
          }
        />
        <FilterField
          id="healthcareEmployeesFiltersFirstName"
          label={
            <FormattedMessage
              id="filters.firstNameLabel"
              defaultMessage="Eesnimi"
            />
          }
          field={
            <Input
              id="healthcareEmployeesFiltersFirstName"
              value={state.firstName}
              onChange={handleChangeInput}
              name="firstName"
            />
          }
        />
        <FilterField
          id="healthcareEmployeesFiltersLastName"
          label={
            <FormattedMessage
              id="filters.lastNameLabel"
              defaultMessage="Perenimi"
            />
          }
          field={
            <Input
              id="healthcareEmployeesFiltersLastName"
              value={state.lastName}
              onChange={handleChangeInput}
              name="lastName"
            />
          }
        />
      </FilterColumn>
      <FilterColumn>
        <FilterField
          id="healthcareEmployeesFiltersOccupation"
          label={
            <FormattedMessage
              id="filters.occupationLabel"
              defaultMessage="Kutse"
            />
          }
          field={
            <SingleSelect
              inputId="healthcareEmployeesFiltersOccupation"
              options={occupationOptions}
              value={state.occupation}
              handleOptionChange={handleChangeSelect}
              name="occupation"
              hideSearchIcon={true}
              placeholder={<></>}
            />
          }
        />
        <FilterField
          id="healthcareEmployeesFiltersSpeciality"
          label={
            <FormattedMessage
              id="filters.specialityLabel"
              defaultMessage="Eriala"
            />
          }
          field={
            <SingleSelect
              inputId="healthcareEmployeesFiltersSpeciality"
              value={state.speciality}
              handleOptionChange={handleChangeSelect}
              name="speciality"
              options={specialityOptions}
              hideSearchIcon={true}
              placeholder={<></>}
            />
          }
        />
      </FilterColumn>
      <FilterColumn />
    </FilterContainer>
  );
};
