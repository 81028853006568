import { SET_CONFIG } from "./configActions";
import { ConfigState } from "./ConfigState";
import { RootAction } from "../rootReducer";

const initialState: ConfigState = {
  isPortal: process.env.REACT_APP_IS_PORTAL === "true",
  isLocalizationSelectorShowed:
    process.env.REACT_APP_IS_LOCALIZATION_SELECTOR_SHOWED === "true",
  specialOccupationCodes: {
    nurse: "" + process.env.REACT_APP_SPECIAL_OCCUPATION_NURSE,
    midwife: "" + process.env.REACT_APP_SPECIAL_OCCUPATION_MIDWIFE
  },
  prescriptionPowerSpecialityCode:
    "" + process.env.REACT_APP_PRESCRIPTION_POWER_SPECIALITY,
  occupationPrefixes: {
    nurse: "" + process.env.REACT_APP_SPECIAL_OCCUPATION_PREFIX_NURSE,
    midwife: "" + process.env.REACT_APP_SPECIAL_OCCUPATION_PREFIX_MIDWIFE
  },
  stateFeeDeadline: process.env.REACT_APP_STATE_FEE_DEADLINE
    ? +process.env.REACT_APP_STATE_FEE_DEADLINE
    : undefined,
  activityLicenseStateFeeDeadline: process.env
    .REACT_APP_ACTIVITY_LICENSE_STATE_FEE_DEADLINE
    ? +process.env.REACT_APP_ACTIVITY_LICENSE_STATE_FEE_DEADLINE
    : undefined,
  maxSuspensionDuration: process.env.REACT_APP_MAX_SUSPENSION_DURATION
    ? +process.env.REACT_APP_MAX_SUSPENSION_DURATION
    : undefined,
  maxSuspensionStartDate: process.env.REACT_APP_MAX_SUSPENSION_START_DATE
    ? +process.env.REACT_APP_MAX_SUSPENSION_START_DATE
    : undefined,
  prescriptionRightSpecialityCode:
    "" + process.env.REACT_APP_PRESCRIPTION_RIGHT_SPECIALITY
};

export const configReducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        authConfig: action.payload.authConfig,
        clientId: action.payload.clientId
      };
    default:
      return state;
  }
};
