import React from "react";

export const occupationApplicationSteps = [
  <>Sisesta isikuinfo</>,
  <>Vali kutse</>,
  <>Hariduse info</>,
  <>Taotluse saabumise kanal</>,
  <>Esita taotlus</>
];

export const specialityApplicationSteps = [
  <>Sisesta isikuinfo</>,
  <>Vali eriala</>,
  <>Hariduse info</>,
  <>Taotluse saabumise kanal</>,
  <>Esita taotlus</>
];

export enum OfficialApplicationStep {
  PERSONAL_DATA,
  SPECIALITY_OCCUPATION,
  EDUCATION_INFO,
  APPLICATION_ARRIVAL_CHANNEL,
  OVERVIEW
}