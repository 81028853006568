import React from "react";
import { Card } from "reactstrap";

import { DecisionState } from "../../../../Decision/DecisionState";
import "../../../Shared/Search/ResultsTable/ResultsTable.scss";
import { Decision } from "../../../../../api_client/medre_api";
import { DecisionDownloadButton } from "../../../Shared/Decision/DecisionDownloadButton";

interface Props {
  decisions: Decision[];
}

export const ApplicationDecisions = ({ decisions }: Props) => {
  const getHeaders = () => [
    <th key="h-1" className="normal-column pl-4" role="columnheader">
      Otsuse number
    </th>,
    <th key="h-2" className="normal-column" role="columnheader">
      Otsuse staatus
    </th>,
    <th key="h-3" />
  ];

  const renderContent = () =>
    decisions?.map((decision, index) => renderRow(decision, index));

  const renderRow = (decision: Decision, i: number) => (
    <tr role="row" key={i}>
      <td className="normal-column short-column pl-4">
        {decision.decisionNumber}
      </td>
      <td className="normal-column short-column">
        <DecisionState index={i} isSigned={decision.signed} />
      </td>
      <td className="normal-column text-right">
        <DecisionDownloadButton decision={decision} />
      </td>
    </tr>
  );

  return (
    <Card>
      {!!decisions.length && (
        <table id="application-decision-table">
          <thead>
            <tr role="row">{getHeaders()}</tr>
          </thead>
          <tbody className="table-result">{renderContent()}</tbody>
        </table>
      )}
    </Card>
  );
};
