import { ProtectedComponent } from "../../../Security/ProtectedComponent";
import {
  LicenseAcquisition,
  OfficialUserPrivilegesEnum
} from "../../../../api_client/medre_api";
import { FormattedMessage } from "react-intl";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import React, { Dispatch } from "react";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import { licenseAcquisitionActions } from "../../../ActivityLicense/licenseAcquisitionActions";
import { History } from "history";
import { GetActivityLicenses } from "../ActivityLicense/getActivityLicenses";

interface Props {
  dispatch: Dispatch<any>;
  history: History;
  isPerson?: boolean;
}

export const PortalDashboardButtonsManagement = ({dispatch, history, isPerson}: Props) => {
  const pharmacyBusinessArea = "Apteegi tegevusala";
  const activityLicenses = GetActivityLicenses();

  const isAllPharmacyBusinessAreas = activityLicenses.length > 0
    ? activityLicenses.every((license) => license.businessAreaName === pharmacyBusinessArea)
    : false;

  const navigateToNewProceeding = (): void => {
    dispatch(applicationDraftActions.setApplicationDraft({}));
    dispatch(
      activityLicenseApplicationActions.setActivityLicense({
        currentStep: 0,
        locations: [{}]
      })
    );
    isPerson
      ? history.push("/new-application")
      : history.push("/new-activity-license");
  };

  const navigateToLicenseAcquisition = (): void => {
    dispatch(
      licenseAcquisitionActions.setLicenseAcquisitionApplication({
        currentStep: 0
      } as LicenseAcquisition)
    );
    history.push("/new-license-acquisition");
  };

  return (
    <>
      { !isAllPharmacyBusinessAreas &&
          <>
            <ProtectedComponent
              allowedRoles={[
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
                OfficialUserPrivilegesEnum.Person
              ]}
              directRepresentativeOnly
              matchAnyCondition
            >
              <h2 className="mt-3 ml-3 fw-300">
                <FormattedMessage
                  id="portalDashboard.startNewApplication"
                  defaultMessage="Alusta uut taotlust"
                />
              </h2>
              <PrimaryFormattedButton
                id={"newApplication"}
                className="ml-3 mt-3"
                onClick={navigateToNewProceeding}
              />
            </ProtectedComponent>
            <ProtectedComponent
              allowedRoles={[
                OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate
              ]}
              directRepresentativeOnly
              matchAnyCondition
            >
              <SecondaryFormattedButton
                id={"licenseAcquisitionApplication"}
                className="ml-3 mt-3"
                onClick={navigateToLicenseAcquisition}
              />
            </ProtectedComponent>
          </>
      }
    </>
  );
};