import { Column, HeaderGroup, useSortBy, useTable } from "react-table";
import React, { useMemo } from "react";
import sortActive from "../../../../../assets/images/sort_active.svg";
import sort from "../../../../../assets/images/sort.svg";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import {
  ApplicationStatus,
  OfficialApplication,
} from "../../../../../api_client/medre_api";
import { formatTHTProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";
import { ApplicationState } from "../../../../Application/ApplicationState";

export interface Props {
  data: OfficialApplication[];
}

export const ThtProceedingTable = ({ data }: Props) => {
  const getDetailsLink = (proceedingStatus: ApplicationStatus, id: string): string => {
    switch (proceedingStatus) {
      case ApplicationStatus.Registering:
      case ApplicationStatus.Saved:
        return `/applications/drafts/${ id }`;
      default:
        return `/applications/${ id }`;
    }
  };

  const columns: Column<OfficialApplication>[] = useMemo(
    () => [
      {
        Header: "Esitatud",
        accessor: "arrivalTime",
        Cell: ({ value }) => <div className="ml-2">{ formatDate(value) }</div>,
        minWidth: 100
      },
      {
        Header: "Taotletav kutse või eriala",
        accessor: "title"
      },
      {
        Header: "Taotluse liik",
        accessor: "type",
        Cell: ({ cell }) => (
          <>{ formatTHTProceedingType(cell.row.values.type) }</>
        )
      },
      {
        Header: "Taotluse olek",
        accessor: "status",
        Cell: ({ cell }) => (
          <>
            <ApplicationState
              application={{
                currentStatus: { status: cell.row.original.status },
                stateFee: { paid: cell.row.original.stateFee?.paid }
              }}
            />
          </>
        )
      },
      {
        Header: "Tähtaeg",
        accessor: "proceedingDeadline",
        Cell: ({ value }) => <div>{ formatDate(value) }</div>,
      },
      {
        Header: "Taotleja",
        accessor: "personName"
      },
      {
        Header: "Taotluse number",
        accessor: "applicationNumber"
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <div className="link-column float-right">
            <DetailViewLinkButton
              to={getDetailsLink(cell.row.values.status, cell.row.values.id)}/>
          </div>
        )
      },
    ],
    []
  );

  const generateSortingIndicator = (column: HeaderGroup<OfficialApplication>) => {
    let columnClass = "mr-1 ml-0";

    if (column.id === "arrivalTime") {
      columnClass = "ml-1";
    }

    return column.isSorted ? (
      <img
        className={`${ columnClass } sort-indicator`}
        src={sortActive}
        alt="sort"
      />
    ) : (
      <img className={`${ columnClass } sort-indicator`} src={sort} alt="sort" />
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  }, useSortBy);

  return (
    <div className="table-wrap">
      <div className="scrollable" style={{ marginRight: "0" }}>
        <table {...getTableProps()}>
          <thead style={{ display: "table-header-group" }}>
            { headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                { headerGroup.headers.map((column) => (
                // tslint:disable-next-line:jsx-key
                  <th{...column.getHeaderProps(column.getSortByToggleProps())}>
                    { column.id !== "id" &&
                    generateSortingIndicator(column) }{ " " }
                    { column.render("Header") }
                  </th>
                )) }
              </tr>
            )) }
          </thead>
          <tbody {...getTableBodyProps()}>
            { rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  { row.cells.map((cell) => (
                    <td className="pl-4" {...cell.getCellProps()}>
                      { cell.render("Cell") }
                    </td>
                  )) }
                </tr>
              );
            }) }
          </tbody>
          <tfoot>
            <tr/>
          </tfoot>
        </table>
      </div>
    </div>
  );
};