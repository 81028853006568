import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { getBaseUrl, API } from "../../../../../../api";
import { ActivityLicenseApplicationState } from "../../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { ApplicationNotes } from "../../../../Application/Details/ApplicationNotes";
import { DetailsCard } from "../DetailsCard";
import { Loader } from "../../../../../../Component/Loader/Loader";
import { LocationSuspensionDetailsActions } from "./LocationSuspensionDetailsActions";
import "../../../../../Shared/Application/Application.scss";
import "../../../../../Shared/ActivityLicense/ActivityLicense.scss";
import { ActivityLicenseDetailsHistory } from "../../../Application/Details/ActivityLicenseDetailsHistory";
import {
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityAPI,
  DetailedActivityLocationSuspension
} from "../../../../../../../api_client/medre_api";
import { ApplicationDecisions } from '../../../../Application/Details/ApplicationDecisions';

export const LocationSuspensionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState<DetailedActivityLocationSuspension>();

  const getSuspension = useCallback(() => {
    officialActivityAPI(undefined, getBaseUrl(), API)
      .getDetailedActivityLicenseActivityLocationSuspension(id, {
        withCredentials: true
      })
      .then((res) => setDetails(res.data))
      .catch((error) => {
        if (error?.response?.status !== 400) {
          history.push("/");
        }
      });
  }, [setDetails, id, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSuspension();
  }, [getSuspension]);

  if (!details || isLoading) {
    return <Loader />;
  }

  return (
    <div className="application-container activity-license-details-container">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details?.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details?.currentStatus}
          isDetailed={false}
        />
      </div>
      <div className="ml-3">
        <div className="mb-2 font-size-18">Hüppa kiirelt:</div>
        <ul className="quick-links list-group">
          <li className="d-block my-2">
            <a href="#actions">Tegevused</a>
          </li>
          <li className="d-block my-2">
            <a href="#details">Taotluse detailid</a>
          </li>
          <li className="d-block my-2">
            <a href="#notes">Menetluse märkmed</a>
          </li>
          <li className="d-block my-2">
            <a href="#history">Menetluse ajalugu</a>
          </li>
        </ul>
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <LocationSuspensionDetailsActions
          refresh={getSuspension}
          setDetails={setDetails}
          details={details}
          setIsLoading={setIsLoading}
        />
      </Card>
      <DetailsCard
        details={details!}
        locations={details?.location ? [details?.location] : []}
        title="Tegevuskoha peatamise taotluse detailid"
      />
      <ApplicationNotes
        notes={details?.notes || ""}
        setDetails={setDetails}
        endpoint={
          officialActivityAPI(undefined, getBaseUrl(), API)
            .updateActivityLocationSuspensionNote2
        }
        detailsId={details?.id}
      />
        <Card id="decisions-card" className="mx-3 my-4">
            <h2>
                <span id="resolutions" /> Otsused
                <ApplicationDecisions decisions={details.decisions || []} />
            </h2>
        </Card>
      <ActivityLicenseDetailsHistory
        auditLogItems={details.auditLogItems || []}
      />
    </div>
  );
};
