import React, { FC } from "react";
import { Card } from "../Card";
import "../PreviousNames/PreviousNamesDetails.scss";
import { AttachmentCardTitle } from "../AttachmentCardTitle";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import { FileReference } from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";

interface Props {
  migratedDocuments?: FileReference[];
}

export const MigratedDocuments: FC<Props> = ({ migratedDocuments }) => {
  return migratedDocuments ? (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.MigratedDocuments}
    >
      <div className="detail-card-grid mt-4">
        {migratedDocuments.map((migratedDocument) => {
          return (
            <Card
              classNameOverride="card applicant-details-card-margin p-4"
              key={migratedDocument.id}
              title={
                <AttachmentCardTitle fileReferences={[migratedDocument]} />
              }
            />
          );
        })}
      </div>
    </ApplicantDetailsSection>
  ) : null;
};
