import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";

import {
  OfficialActivityLicenseStep,
  officialActivityLicenseSteps
} from "../../Shared/ActivityLicense/ActivityLicenseStep";
import { ActivityLicenseBusinessAreaStep } from "../../Shared/ActivityLicense/ActivityLicenseBusinessAreaStep";
import { Stepper } from "../../../Component/ProgressBar/Stepper";
import { WizardProgressBar } from "../../../Component/ProgressBar/WizardProgressBar";
import { ActivityLicenseFooter } from "../../Shared/ActivityLicense/ActivityLicenseFooter";
import "../../Shared/ActivityLicense/ActivityLicense.scss";
import { RootState } from "../../../rootReducer";
import { ActivityLicenseOverview } from "../../Shared/ActivityLicense/Overview/ActivityLicenseOverview";
import { NewActivityLicenseServices } from "../../Shared/ActivityLicense/NewActivityLicenseServices";
import { BusinessData } from "./BusinessData";
import { ActivityLicenseDocumentsContainer } from "../../Shared/ActivityLicense/Documents/ActivityLicenseDocumentsContainer";
import { ActivityLicenseArrivalChannel } from "./ActivityLicenseArrivalChannel";

export const OfficialActivityLicenseContainer = () => {
  const currentStep = useSelector(
    (state: RootState) => state.activityLicenseApplication.currentStep || 0
  );
  const steps = officialActivityLicenseSteps;

  const urls = {
    routes: {
      portal: "/activity-license-application",
      official: "/activity-license-applications"
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case OfficialActivityLicenseStep.BUSINESS_DATA:
        return <BusinessData isContactInfoDisabled={false} />;
      case OfficialActivityLicenseStep.BUSINESS_AREA:
        return <ActivityLicenseBusinessAreaStep />;
      case OfficialActivityLicenseStep.SERVICES:
        return <NewActivityLicenseServices />;
      case OfficialActivityLicenseStep.DOCUMENTS:
        return <ActivityLicenseDocumentsContainer />;
      case OfficialActivityLicenseStep.APPLICATION_ARRIVAL_CHANNEL:
        return <ActivityLicenseArrivalChannel />;
      case OfficialActivityLicenseStep.OVERVIEW:
        return <ActivityLicenseOverview />;
      default:
        return <BusinessData isContactInfoDisabled={false} />;
    }
  };

  return (
    <div className="activity-license-container">
      <h1 className="ml-3">
        <FormattedMessage
          id="activityLicense.title"
          defaultMessage="Tegevusloa taotlemine"
        />
      </h1>

      <Stepper className={"m-3"} currentStep={currentStep || 0} steps={steps} />
      <Card className="ml-3 mt-4 mb-5 mr-3">
        <div className="m-4">
          <WizardProgressBar highestStep={currentStep} steps={steps} />
          {renderContent()}
          <hr className="mt-5 application-footer-hr" />
          <ActivityLicenseFooter
            currentStep={currentStep || 0}
            steps={steps}
            urls={urls}
          />
        </div>
      </Card>
    </div>
  );
};
