import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { AxiosPromise } from "axios";
import { FormattedMessage } from "react-intl";

import { GeneralFM } from "../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { CompanyInfoRow } from "../Applications/CompanyInfoRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { Loader } from "../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLocation,
  ActivityLocationSuspension,
  ActivityLocationSuspensionSuspensionReasonEnum,
  ActivityLicenseSuspension,
  ServiceSuspension,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import { DecisionsRow } from "../Applications/DecisionsRow";
import { getBaseUrl, API } from "../../../../api";

type SuspensionType =
  | ActivityLocationSuspension
  | ServiceSuspension
  | ActivityLicenseSuspension;

const getLocations = (suspension?: SuspensionType): ActivityLocation[] => {
  if (suspension) {
    if ("location" in suspension) {
      return [suspension.location!];
    }
    if ("locations" in suspension) {
      return suspension.locations!;
    }
  }
  return [];
};

const getSuspensionReason = (
  suspensionReason?: ActivityLocationSuspensionSuspensionReasonEnum
) => {
  return suspensionReason ===
    ActivityLocationSuspensionSuspensionReasonEnum.Personnel ? (
    <FormattedMessage
      id="suspendActivityLicenseModal.personnel"
      defaultMessage="Personal"
    />
  ) : (
    <FormattedMessage
      id="suspendActivityLicenseModal.rooms"
      defaultMessage="Ruumid/vahendid"
    />
  );
};

interface Props {
  isOpen?: boolean;
  id?: string;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
  suspensionEndpointRef?: (
    id: string,
    options?: any
  ) => AxiosPromise<ActivityLicenseSuspension>;
}

export const SuspensionAccordion = ({
  isOpen,
  id,
  proceedingType,
  suspensionEndpointRef
}: Props) => {
  const [suspension, setSuspension] = useState<SuspensionType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
    useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={suspension?.currentStatus}
      isDetailed={false}
    />
  );

  const loadSuspension = useCallback(() => {
    setIsLoading(true);
    suspensionEndpointRef!(id as string, {
      withCredentials: true
    })
      .then((res) => setSuspension(res.data))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      loadSuspension();
    }
  }, [isOpen, loadSuspension]);

  return isLoading ? (
    <Loader backdrop={true} />
  ) : (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            suspension?.applicationNumber?.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            "licenseNumber",
            suspension?.activityLicenseNumber
          )}
          {renderOverViewRow(
            "activityLicense",
            "submissionDate",
            formatDate(suspension?.createdAt)
          )}
          {renderOverViewRow(
            "activityLicense",
            "suspensionReason",
            getSuspensionReason(suspension?.suspensionReason)
          )}
          {renderOverViewRow(
            "activityLicense",
            "suspensionPeriod",
            formatDate(suspension?.startDate) +
              " - " +
              formatDate(suspension?.endDate)
          )}
          {renderOverViewRow(
            "activityLicense",
            "suspensionExplanationNotes",
            suspension?.explanationNotes
          )}
          {renderOverViewRow("application", "state", state)}
          {renderOverViewRow(
            "activityLicense",
            "businessArea",
            suspension?.businessArea
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={suspension?.company}
          submittingPerson={
            suspension?.submittingPersonFirstName +
            " " +
            suspension?.submittingPersonLastName
          }
          submittingPersonIdCode={suspension?.submittingPersonIdCode}
        />
        <ActivityLocationsRow
          isOpen={isActivityLocationsRowOpen}
          handleClick={() =>
            setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
          }
          activityLocations={getLocations(suspension)}
          loadServiceEndpoint={
            activityPermitAPI(undefined, getBaseUrl(), API).getService1
          }
          isHospital={Boolean(suspension?.isHospital)}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={suspension?.decisions}
        />
      </td>
    </tr>
  );
};
