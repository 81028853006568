import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { ApplicationCertificateContext } from "../../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import { ApplicationCertificateOccupationMessageCard } from "./OccupationsMessageCard";
import { Row, Col } from "reactstrap";
import { OccupationsFormSpeciality } from "../../../Shared/ApplicationCertificate/Occupations/OccupationsFormSpeciality";
import { OccupationsFormCountries } from "../../../Shared/ApplicationCertificate/Occupations/OccupationsFormCountries";
import { OccupationsFormEducation } from "../../../Shared/ApplicationCertificate/Occupations/OccupationsFormEducation";

export const OccupationsSelection = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      applicationDraftActions.updateApplicationDraft("saveAllowed", true)
    );
  }, [dispatch]);

  const { allowedOccupationCodes } = useContext(ApplicationCertificateContext);
  if (allowedOccupationCodes.length === 0) {
    return (
      <ApplicationCertificateOccupationMessageCard messageType="noOccupations" />
    );
  }

  return (
    <>
      <h3 className="card-title font-weight-normal mb-2">
        <FormattedMessage
          id="ApplicationCertificate.occupationTitle"
          defaultMessage="Vali kutse"
        />
      </h3>
      <p>
        <FormattedMessage
          id="ApplicationCertificate.occupationInstruction"
          defaultMessage={`Tee enda omandatud kutse valik ja seejärel klõpsake nuppu “Edasi”.`}
        />
      </p>
      <Row>
        <Col xs={12} lg={11} xl={{ offset: 1, size: 10 }}>
          <OccupationsFormSpeciality />

          <OccupationsFormCountries required={true} name="countries" excludedCountries={['EST']} />

          <OccupationsFormEducation />
        </Col>
      </Row>
    </>
  );
};
