import { translateCountry } from "../../../../../Component/Translate/CountryTranslator";
import React from "react";
import { ContactInfo, Address } from "../../../../../../api_client/medre_api";

export const getFullPhoneNumber = (contactInfo?: ContactInfo) => {
  if (contactInfo && contactInfo?.phone) {
    let dialCode = contactInfo?.dialCode;
    let phone = contactInfo?.phone;
    return dialCode ? dialCode + " " + phone : "" + phone;
  }
};

export const getAddressJSX = (address?: Address) => {
  if (address?.fullAddress) {
    const { fullAddress, zipCode, country } = address;
    const countryName = translateCountry(country?.code);
    return (
      <>
        {fullAddress + ", "}
        {countryName}
        {", " + zipCode}
      </>
    );
  }
};
