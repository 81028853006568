import React from "react";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { FormattedToolTip } from "../../../../../Component/ToolTip/FormattedToolTip";
import info_icon from "../../../../../../assets/images/information.svg";
import { inputRegex } from "../../../../../Util/inputRegexValidation";

interface Props {
  id?: string;
  isDisabled: boolean;
  setRoomNumber: (roomNumber: string) => void;
  currentValue?: string;
}

export const RoomNumberInput = ({
  id,
  isDisabled,
  currentValue,
  setRoomNumber
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomNumber(e.target.value);
  };

  const getTooltipMessage = () => (
    <FormattedMessage
      id="addRoomModal.infoIconTooltip"
      defaultMessage="Komaga eraldatud lühikesed ruumi numbrid"
    />
  );

  return (
    <div className="input-group">
      <Input
        id={id}
        disabled={isDisabled}
        value={currentValue || ""}
        name="roomNumber"
        className="form-control"
        onChange={(e) => handleChange(inputRegex(e))}
      />
      <FormattedToolTip
        placement="top"
        target="room-modal-info-icon"
        message={getTooltipMessage()}
      />
      <img id="room-modal-info-icon" src={info_icon} alt="icon" />
    </div>
  );
};
