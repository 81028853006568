import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavigationPrompt from "react-router-navigation-prompt";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";
import { AxiosError, AxiosPromise } from "axios";

import { RootState } from "../../../../rootReducer";
import { LicenseAcquisitionStep } from "./LicenseAquisitionStep";
import { licenseAcquisitionActions } from "../../../../ActivityLicense/licenseAcquisitionActions";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ApplicationDiscardConfirmationModal } from "../../Application/Footer/ApplicationDiscardConfirmationModal";
import { ApplicationSaveConfirmationModal } from "../../Application/Footer/ApplicationSaveConfirmationModal";
import {
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitAppAPI,
  LicenseAcquisition,
  TegevuslubadeLevtmisteTeenusApiFactory as activityPermitIssuanceAPI
} from "../../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../../api";

interface Props {
  currentStep: number;
  steps: JSX.Element[];
}

export const LicenseAcquisitionFooter = ({ currentStep, steps }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const application = useSelector(
    (state: RootState) =>
      state.licenseAcquisitionApplication as LicenseAcquisition
  );
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [shouldShowNavigationPrompt, setShouldShowNavigationPrompt] =
    useState(true);

  const navigate = (changeStep: number) => {
    let shouldValidate = true;
    switch (currentStep) {
      case LicenseAcquisitionStep.BUSINESS_DATA:
        if (changeStep === -1) {
          dispatch(
            licenseAcquisitionActions.updateApplicationDraft(
              "company",
              undefined
            )
          );
          history.push("/");
          break;
        }
        handleNavigation(currentStep + changeStep, shouldValidate);
        break;
      case LicenseAcquisitionStep.OVERVIEW:
        if (changeStep === 1) {
          activityPermitIssuanceAPI(undefined, getBaseUrl(), API)
            .submitPortalActivityLicenseApplication(application.id!, {
              withCredentials: true
            })
            .then((response) => {
              setShouldShowNavigationPrompt(false);
              dispatch(
                licenseAcquisitionActions.setLicenseAcquisitionApplication(
                  response.data
                )
              );
              if (isPortal) {
                history.push("/license-acquisition-application");
              }
            });
        } else {
          if (changeStep === -1) {
            shouldValidate = false;
          }
          handleNavigation(currentStep + changeStep, shouldValidate);
        }
        break;
      default:
        if (changeStep === -1) {
          shouldValidate = false;
        }
        handleNavigation(currentStep + changeStep, shouldValidate);
    }
  };

  const handleNavigation = (newStep: number, shouldValidate: boolean) => {
    createOrUpdateApplication(newStep, false, shouldValidate);
  };

  const getEndpoint = (shouldValidate: boolean) =>
    application.id
      ? updateLicenseRequest(shouldValidate)
      : createLicenseRequest();

  const createOrUpdateApplication = (
    newStep: number,
    isSave: boolean,
    shouldValidate: boolean
  ) => {
    (getEndpoint(shouldValidate) as AxiosPromise<LicenseAcquisition>)
      .then((response) => {
        if (isSave) {
          dispatch(
            licenseAcquisitionActions.setLicenseAcquisitionApplication({} as LicenseAcquisition)
          );
          return handleApplicationSaveSuccess();
        }
        setShouldShowNavigationPrompt(false);
        const updatedApplication = response.data;
        updatedApplication.currentStep = newStep;
        dispatch(
          licenseAcquisitionActions.setLicenseAcquisitionApplication(
            updatedApplication as LicenseAcquisition
          )
        );
      })
      .catch((error: AxiosError) => {
        handleApplicationSaveError(error);
      })
      .finally(() => {
        if (isSave) {
          setConfirmationModalIsOpen(false);
        }
      });
  };

  const handleApplicationSaveSuccess = () => {
    const alertMessage = <AlertMessage id="applicationSaveSuccess" />;
    const alert = { id: 0, type: AlertType.Success, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
    setShouldShowNavigationPrompt(false);
    history.push(isPortal ? "/applications" : "/search");
  };

  const handleApplicationSaveError = (error: AxiosError) => {
    let alertMessage = <AlertMessage id="applicationSaveFailed" />;
    const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
    dispatch(alertActions.addAlert(alert));
  };

  const updateLicenseRequest = (shouldValidate: boolean) =>
    isPortal
      ? shouldValidate
        ? activityPermitIssuanceAPI(
            undefined,
            getBaseUrl(),
            API
          ).updateApplication(application, {
            withCredentials: true
          })
        : activityPermitIssuanceAPI(
            undefined,
            getBaseUrl(),
            API
          ).updateWithoutValidation1(application, {
            withCredentials: true
          })
      : shouldValidate
      ? officialActivityPermitAppAPI(
          undefined,
          getBaseUrl(),
          API
        ).updateActivityLicense(application.id!, application, {
          withCredentials: true
        })
      : officialActivityPermitAppAPI(
          undefined,
          getBaseUrl(),
          API
        ).saveActivityLicenseWithoutValidations(application.id!, application, {
          withCredentials: true
        });

  const createLicenseRequest = () =>
    isPortal
      ? activityPermitIssuanceAPI(undefined, getBaseUrl(), API).saveApplication(
          application,
          {
            withCredentials: true
          }
        )
      : officialActivityPermitAppAPI(
          undefined,
          getBaseUrl(),
          API
        ).saveActivityLicense(application);

  const handleDiscardModalOpenChange = (isOpen: boolean) => {
    setDiscardModalIsOpen(isOpen);
    setShouldShowNavigationPrompt(!isOpen);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalIsOpen(!confirmationModalIsOpen);
  };

  const handleSave = () => createOrUpdateApplication(currentStep, true, false);

  const getModalMessage = () => (
    <FormattedMessage
      id="activityLicenseSaveConfirmationModal.bodyOfficial"
      defaultMessage="Taotluse seis salvestatakse."
    />
  );

  const renderBackwardButton = () =>
    currentStep === LicenseAcquisitionStep.BUSINESS_DATA ? (
      <SecondaryFormattedButton
        id="discard"
        onClick={() => handleDiscardModalOpenChange(true)}
      />
    ) : (
      <SecondaryFormattedButton id="back" onClick={() => navigate(-1)} />
    );

  return (
    <Row className="ml-0 mr-0 application-footer-buttons">
      {renderBackwardButton()}
      <LinkButton
        id="saveDraft"
        onClick={() => setConfirmationModalIsOpen(true)}
      />
      <PrimaryFormattedButton
        className="ml-4"
        id="forward"
        disabled={!application.saveAllowed}
        onClick={() => navigate(1)}
        {...(currentStep === steps.length - 1 && {
          id: "sendApplication"
        })}
      />
      <ApplicationDiscardConfirmationModal
        isOpen={discardModalIsOpen}
        onClose={() => handleDiscardModalOpenChange(!discardModalIsOpen)}
        onDiscard={() => history.push("/")}
      />
      <ApplicationSaveConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={toggleConfirmationModal}
        onSave={handleSave}
        modalMessage={getModalMessage()}
      />
      <NavigationPrompt when={shouldShowNavigationPrompt}>
        {({ onConfirm, onCancel }) => (
          <ApplicationDiscardConfirmationModal
            isOpen={true}
            onClose={onCancel}
            onDiscard={onConfirm}
          />
        )}
      </NavigationPrompt>
    </Row>
  );
};
