import React from "react";
import { Card } from "reactstrap";

import arrow from "../../../../assets/images/arrow_right_blue.svg";

interface Props {
  name: string;
  code: string;
  onClick: () => void;
}

export const RoleCard = ({ name, code, onClick }: Props) => (
  <Card onClick={onClick}>
    <div className="role-card-content">
      <div className="name-card-wrapper">
        <h5 className="role-card-name">{name}</h5>
        <div className="role-card-code">{code}</div>
      </div>
      <img src={arrow} alt="go" />
    </div>
  </Card>
);
