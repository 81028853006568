import * as React from "react";
import classNames from "classnames";

import "./Loader.scss";

interface Props {
  absolute?: boolean;
  backdrop?: boolean;
  wrapperClassName?: string;
}

export const Loader = React.memo(
  ({ absolute, backdrop, wrapperClassName = "" }: Props) => (
    <div
      className={classNames(
        "loader",
        {
          "position-absolute": absolute,
          "position-fixed": backdrop
        },
        { [wrapperClassName]: wrapperClassName }
      )}
    >
      <div className="loading-animation">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
);
