import React from "react";
import { Button } from "reactstrap";
import { defineMessages, FormattedMessage } from "react-intl";

import { ButtonProps } from "./Button";

const Messages = defineMessages({
  no: {
    id: "button.no",
    defaultMessage: "Ei"
  },
  yes: {
    id: "button.yes",
    defaultMessage: "Jah"
  },
  cancel: {
    id: "button.cancel",
    defaultMessage: "Tühista"
  },
  remove: {
    id: "button.remove",
    defaultMessage: "Eemalda"
  },
  close: {
    id: "button.close",
    defaultMessage: "Sulge"
  },
  logOut: {
    id: "button.logOut",
    defaultMessage: "Logi välja"
  },
  continue: {
    id: "button.continue",
    defaultMessage: "Jätkan kasutamist"
  },
  newOccupation: {
    id: "button.newOccupation",
    defaultMessage: "Uus kutse taotlus"
  },
  newSpeciality: {
    id: "button.newSpeciality",
    defaultMessage: "Uus eriala taotlus"
  },
  newCertificate: {
    id: "button.newCertificate",
    defaultMessage: "Uus tõend"
  },
  newCompetence: {
    id: "button.newCompetence",
    defaultMessage: "Uus pädevus"
  },
  newApplication: {
    id: "button.newApplication",
    defaultMessage: "Uus taotlus"
  },
  newApplicationCertificate: {
    id: "button.newApplicationCertificate",
    defaultMessage: "Uus tõendi taotlus"
  },
  change: {
    id: "button.change",
    defaultMessage: "Muudan"
  },
  use: {
    id: "button.use",
    defaultMessage: "Kasutan"
  },
  save: {
    id: "button.save",
    defaultMessage: "Salvestan"
  },
  saveIt: {
    id: "button.saveIt",
    defaultMessage: "Salvesta"
  },
  discard: {
    id: "button.discard",
    defaultMessage: "Loobun"
  },
  forward: {
    id: "button.forward",
    defaultMessage: "Edasi"
  },
  loginPerson: {
    id: "button.loginPerson",
    defaultMessage: "Sisene iseteenindusse"
  },
  loginOfficial: {
    id: "button.loginOfficial",
    defaultMessage: "Sisene portaali"
  },
  back: {
    id: "button.back",
    defaultMessage: "Tagasi"
  },
  backToPerson: {
    id: "button.backToPerson",
    defaultMessage: "Tagasi isiku detailvaatesse"
  },
  goToBack: {
    id: "button.goToBack",
    defaultMessage: "Tagasi"
  },
  saveDraft: {
    id: "button.saveDraft",
    defaultMessage: "Salvesta ja jätka hiljem"
  },
  saveEducation: {
    id: "button.saveEducation",
    defaultMessage: "Salvesta haridus"
  },
  saveAndEndProcedure: {
    id: "button.saveAndEndProcedure",
    defaultMessage: "Salvesta ja lõpeta menetlus"
  },
  discardDraft: {
    id: "button.discardDraft",
    defaultMessage: "Tühista taotluse täitmine"
  },
  newQuery: {
    id: "button.newQuery",
    defaultMessage: "Tee uus päring"
  },
  addManually: {
    id: "button.addManually",
    defaultMessage: "Lisa käsitsi"
  },
  addAutomatically: {
    id: "button.addAutomatically",
    defaultMessage: "Lisa automaatselt"
  },
  setManually: {
    id: "button.setManually",
    defaultMessage: "märkige see käsitsi"
  },
  changeEducation: {
    id: "person.education.edit",
    defaultMessage: "Muuda haridust"
  },
  setAutomatically: {
    id: "button.setAutomatically",
    defaultMessage: "läbi EHIS-i päringu"
  },
  backToLandingPage: {
    id: "button.backToLandingPage",
    defaultMessage: "Tagasi iseteeninduse avalehele"
  },
  backToApplication: {
    id: "button.backToApplication",
    defaultMessage: "Tagasi taotlusesse"
  },
  backToApplications: {
    id: "button.backToApplications",
    defaultMessage: "Tagasi taotlustesse"
  },
  backToDetailView: {
    id: "button.backToDetailView",
    defaultMessage: "Tagasi detailvaatesse"
  },
  deleteApplication: {
    id: "button.deleteApplication",
    defaultMessage: "Kustuta taotlus"
  },
  deleteService: {
    id: "button.deleteService",
    defaultMessage: "Kustuta teenus"
  },
  deleteCertificate: {
    id: "button.deleteCertificate",
    defaultMessage: "Kustuta tõend"
  },
  deleteCompetence: {
    id: "button.deleteCompetence",
    defaultMessage: "Kustuta pädevus"
  },
  delete: {
    id: "button.delete",
    defaultMessage: "Kustuta"
  },
  deleteEmployee: {
    id: "button.deleteEmployee",
    defaultMessage: "Kustuta töötaja"
  },
  continueWithSavedDraft: {
    id: "button.continueWithSavedDraft",
    defaultMessage: "Jätka taotluse täitmisega"
  },
  choose: {
    id: "button.choose",
    defaultMessage: "Vali"
  },
  saveNote: {
    id: "button.saveNote",
    defaultMessage: "Salvesta märge"
  },
  discardChanges: {
    id: "button.discardChanges",
    defaultMessage: "Tühista muudatused"
  },
  acceptApplication: {
    id: "button.acceptApplication",
    defaultMessage: "Rahulda taotlus"
  },
  decline: {
    id: "button.decline",
    defaultMessage: "Keeldu"
  },
  initiateProceeding: {
    id: "button.initiateProceeding",
    defaultMessage: "Alusta menetlust"
  },
  revertWaitingForSignatureStatus: {
    id: "button.revertWaitingForSignatureStatus",
    defaultMessage: "Võta allkirjastamisele suunamine tagasi"
  },
  signApplicationAcceptanceDecision: {
    id: "button.signApplicationAcceptanceDecision",
    defaultMessage: "Allkirjasta rahuldamise otsus"
  },
  signApplicationDeclinationDecision: {
    id: "button.signApplicationDeclinationDecision",
    defaultMessage: "Allkirjasta keeldumise otsus"
  },
  signComplianceExamDecision: {
    id: "button.signComplianceExamDecision",
    defaultMessage: "Allkirjasta vastavuseksamile suunamise otsus"
  },
  signAptitudeTestDecision: {
    id: "button.signAptitudeTestDecision",
    defaultMessage: "Allkirjasta sobivustestile suunamise otsus"
  },
  signDecision: {
    id: "button.signDecision",
    defaultMessage: "Allkirjasta otsus"
  },
  confirmAndCreateDecision: {
    id: "button.confirmAndCreateDecision",
    defaultMessage: "Kinnita ja koosta otsus"
  },
  finishProceeding: {
    id: "button.finishProceeding",
    defaultMessage: "Lõpeta menetlus"
  },
  continueProceeding: {
    id: "button.continueProceeding",
    defaultMessage: "Jätka menetlust"
  },
  search: {
    id: "button.search",
    defaultMessage: "Otsi"
  },
  clearAllValues: {
    id: "button.clearAllValues",
    defaultMessage: "Tühjenda väljad"
  },
  sendToRenewal: {
    id: "button.sendToRenewal",
    defaultMessage: "Suuna täiendamisele"
  },
  sendToComplianceExam: {
    id: "button.sendToComplianceExam",
    defaultMessage: "Suuna vastavuseksamile"
  },
  sendToAptitudeTest: {
    id: "button.sendToAptitudeTest",
    defaultMessage: "Suuna sobivustestile"
  },
  renewApplication: {
    id: "button.renewApplication",
    defaultMessage: "Täienda taotlust"
  },
  enterComplianceExam: {
    id: "button.enterComplianceExam",
    defaultMessage: "Sisesta vastavuseksami tulemus"
  },
  enterAptitudeTest: {
    id: "button.enterAptitudeTest",
    defaultMessage: "Sisesta sobivustesti tulemus"
  },
  confirmResult: {
    id: "button.confirmResult",
    defaultMessage: "Kinnita tulemus"
  },
  confirm: {
    id: "button.confirm",
    defaultMessage: "Kinnita"
  },
  paymentInstructions: {
    id: "button.paymentInstructions",
    defaultMessage: "Tasumise juhend"
  },
  paymentManuallyConfirmed: {
    id: "button.paymentManuallyConfirmed",
    defaultMessage: "Tasumine käsitsi kontrollitud"
  },
  startSuspension: {
    id: "button.startSuspension",
    defaultMessage: "Alusta tegevusõiguse peatamisega"
  },
  downloadDecision: {
    id: "button.downloadDecision",
    defaultMessage: "Lae otsus alla"
  },
  downloadSignedDecision: {
    id: "button.downloadSignedDecision",
    defaultMessage: "Lae allkirjastatud otsus alla"
  },
  downloadActivityLicense: {
    id: "button.downloadActivityLicense",
    defaultMessage: "Lae tegevusluba alla"
  },
  revertSendForSigning: {
    id: "button.revertSendForSigning",
    defaultMessage: "Võta suunamine tagasi"
  },
  saveAllChanges: {
    id: "button.saveAllChanges",
    defaultMessage: "Salvesta kõik muudatused"
  },
  add: {
    id: "button.add",
    defaultMessage: "Lisa"
  },
  addService: {
    id: "button.addService",
    defaultMessage: "Lisa uus teenus"
  },
  suspendService: {
    id: "button.suspendService",
    defaultMessage: "Peata teenus"
  },
  discardService: {
    id: "button.discardService",
    defaultMessage: "Loobu teenusest"
  },
  addLocation: {
    id: "button.addLocation",
    defaultMessage: "Lisa uus tegevuskoht"
  },
  suspendLocation: {
    id: "button.suspendLocation",
    defaultMessage: "Peata tegevuskoht"
  },
  invalidateLocation: {
    id: "button.invalidateLocation",
    defaultMessage: "Tunnista tegevuskoht kehtetuks"
  },
  addServiceRooms: {
    id: "button.addServiceRooms",
    defaultMessage: "Lisa ruume"
  },
  addServiceEmployee: {
    id: "button.addServiceEmployee",
    defaultMessage: "Lisa töötaja"
  },
  addServiceEmployees: {
    id: "button.addServiceEmployees",
    defaultMessage: "Lisa töötajaid"
  },
  addTHT: {
    id: "button.addTHT",
    defaultMessage: "Lisa THT"
  },
  addSpecialist: {
    id: "button.addSpecialist",
    defaultMessage: "Lisa spetsialist"
  },
  addOrEditServiceEmployees: {
    id: "button.addOrEditServiceEmployees",
    defaultMessage: "Lisa/muuda töötajaid"
  },
  addOrEditServiceRooms: {
    id: "button.addOrEditServiceRooms",
    defaultMessage: "Lisa/muuda ruume"
  },
  addOptionalRoom: {
    id: "button.addOptionalRoom",
    defaultMessage: "Lisa valikuline ruum"
  },
  instructions: {
    id: "button.instructions",
    defaultMessage: "Juhend"
  },
  addEmployeesToService: {
    id: "button.addEmployeesToService",
    defaultMessage: "Lisa töötaja(d) teenusele"
  },
  addEmployeeToService: {
    id: "button.addEmployeeToService",
    defaultMessage: "Lisa töötaja teenusele"
  },
  newActivityLicense: {
    id: "button.newActivityLicense",
    defaultMessage: "Uus tegevusloa taotlus"
  },
  sendApplication: {
    id: "button.sendApplication",
    defaultMessage: "Esita taotlus"
  },
  lookAtDetails: {
    id: "button.lookAtDetails",
    defaultMessage: "Vaata detaile"
  },
  suspendActivityLicense: {
    id: "button.suspendActivityLicense",
    defaultMessage: "Peata tegevusluba"
  },
  forwardToOfficial: {
    id: "button.forwardToOfficial",
    defaultMessage: "Suuna menetlejale"
  },
  declineApplication: {
    id: "button.declineApplication",
    defaultMessage: "Keeldu taotlusest"
  },
  declineApplicationConfirm: {
    id: "button.declineApplicationConfirm",
    defaultMessage: "Keeldu taotlusest"
  },
  pauseProceeding: {
    id: "button.pauseProceeding",
    defaultMessage: "Peata ja suuna täiendamisele"
  },
  pauseProceedingConfirm: {
    id: "button.pauseProceedingConfirm",
    defaultMessage: "Peata menetlus"
  },
  onSiteInspection: {
    id: "button.onSiteInspection",
    defaultMessage: "Suuna paikvaatlusele"
  },
  onSiteInspectionConfirm: {
    id: "button.onSiteInspectionConfirm",
    defaultMessage: "Alusta paikvaatlust"
  },
  addProtocol: {
    id: "button.addProtocol",
    defaultMessage: "Lisa allkirjastatud kontrollakt"
  },
  confirmProtocol: {
    id: "button.confirmProtocol",
    defaultMessage: "Kinnita paikvaatlus"
  },
  createProtocol: {
    id: "button.createProtocol",
    defaultMessage: "Koosta protokoll"
  },
  newGeneralPractitionerList: {
    id: "button.newGeneralPractitionerList",
    defaultMessage: "Uus nimistu"
  },
  activateGeneralPractitionerList: {
    id: "button.activateGeneralPractitionerList",
    defaultMessage: "Aktiveeri nimistu"
  },
  setGPMaxPatientsSize: {
    id: "button.setGPMaxPatientsSize",
    defaultMessage: "Määra nimistu piirsuurus"
  },
  changeGPMaxPatientsSize: {
    id: "button.changeGPMaxPatientsSize",
    defaultMessage: "Muuda nimistu piirsuurus"
  },
  exportToCsv: {
    id: "button.exportToCsv",
    defaultMessage: "Ekspordi CSV-sse"
  },
  updateContactInformation: {
    id: "button.updateContactInformation",
    defaultMessage: "Muuda kontaktandmeid"
  },
  addToEmployeeList: {
    id: "button.addToEmployeeList",
    defaultMessage: "Lisa töötaja nimistusse"
  },
  invalidateActivityLicense: {
    id: "button.invalidateActivityLicense",
    defaultMessage: "Tegevusloa kehtetuks tunnistamine"
  },
  addAppointmentTime: {
    id: "button.addAppointmentTime",
    defaultMessage: "Lisa aeg"
  },
  submitAppointmentTime: {
    id: "button.submitAppointmentTime",
    defaultMessage: "Lisa vastuvõtu aeg"
  },
  licenseAcquisitionApplication: {
    id: "button.licenseAcquisitionApplication",
    defaultMessage: "Tegevusloa ülevõtmise taotlus"
  },
  addConsultationTime: {
    id: "button.addConsultationTime",
    defaultMessage: "Lisa aeg"
  },
  submitConsultationTime: {
    id: "button.submitConsultationTime",
    defaultMessage: "Salvesta muudatused"
  },
  changeGPCell: {
    id: "button.changeGPCell",
    defaultMessage: "Muuda"
  },
  addSubstituteDoctor: {
    id: "button.addSubstituteDoctor",
    defaultMessage: "Lisa asendusarst"
  },
  submitSubstituteDoctor: {
    id: "button.submitSubstituteDoctor",
    defaultMessage: "Lisa asendusarst nimistusse"
  },
  addSubstituteDoctorPeriod: {
    id: "button.addSubstituteDoctorPeriod",
    defaultMessage: "Lisa uus periood"
  },
  submitSubstituteDoctorPeriod: {
    id: "button.submitSubstituteDoctorPeriod",
    defaultMessage: "Lisa uus asenduse periood"
  },
  confirmGPListDeletion: {
    id: "confirmGPListDeletion",
    defaultMessage: "Eemalda nimistust"
  },
  liquidateGPList: {
    id: "button.liquidateGPList",
    defaultMessage: "Likvideeri nimistu"
  },
  invalidate: {
    id: "button.invalidate",
    defaultMessage: "Tunnista kehtetuks"
  },
  healthCareProfessionals: {
    id: "button.healthCareProfessionals",
    defaultMessage: "Tervishoiutöötajad"
  },
  familyMedicine: {
    id: "button.familyMedicine",
    defaultMessage: "Perearstinimistu"
  },
  licences: {
    id: "button.licences",
    defaultMessage: "Tegevusload"
  },
  sendChangesToProceeding: {
    id: "button.sendChangesToProceeding",
    defaultMessage: "Saada muudatused menetlusse"
  },
  addDecisionFile: {
    id: "button.addDecisionFile",
    defaultMessage: "Lisa otsuse fail ja suuna allkirjastamisele"
  },
  downloadDecisionTemplate: {
    id: "button.downloadDecisionTemplate",
    defaultMessage: "Laadi alla otsuse mall"
  },
  forwardToSign: {
    id: "button.forwardToSign",
    defaultMessage: "Suuna allkirjastamisele"
  },
  linkToDescriptionOfOpenData: {
    id: "publicOpenData.linkToDescriptionOfOpenData",
    defaultMessage: "Avaandmete kirjeldus"
  },
  descriptionLink: {
    id: "publicOpenData.descriptionLink",
    defaultMessage: "Kirjeldus"
  },
  acceptActivityLicenseRestoration: {
    id: "button.acceptActivityLicenseRestoration",
    defaultMessage: "Taasta tegevusluba"
  },
  declineActivityLicenseRestoration: {
    id: "button.declineActivityLicenseRestoration",
    defaultMessage: "Keeldu tegevusloa taastamisest"
  },
  addToLicense: {
    id: "button.addTolicense",
    defaultMessage: "Lisa tegevusloale"
  },
  exportThtKraCsv: {
    id: "button.exportThtKraCsv",
    defaultMessage: "THT andmete kogumine"
  },
  exportTtoKraCsv: {
    id: "button.exportTtoKraCsv",
    defaultMessage: "TTO andmete kogumine"
  },
  acceptActivityLocationRestoration: {
    id: "button.acceptActivityLocationRestoration",
    defaultMessage: "Taasta tegevuskoht"
  },
  acceptActivityLicenseServiceRestoration: {
    id: "button.acceptActivityLicenseServiceRestoration",
    defaultMessage: "Taasta teenus"
  },
  declineActivityLocationRestoration: {
    id: "button.declineActivityLocationRestoration",
    defaultMessage: "Keeldu tegevuskoha taastamisest"
  },
  declineActivityLicenseServiceRestoration: {
    id: "button.declineActivityLicenseServiceRestoration",
    defaultMessage: "Keeldu teenuse taastamisest"
  },
  changeGPListNumber: {
    id: "button.changeGPListNumber",
    defaultMessage: "Muuda nimistute arvu"
  },
  downloadPowersOfAttorneyCSV: {
    id: "button.downloadPowersOfAttorneyCSV",
    defaultMessage: "Laadi esindajate faili alla"
  },
  addPerson: {
    id: "button.addPerson",
    defaultMessage: "Lisa volitatud esindaja"
  },
  downloadCSV: {
    id: "button.downloadCSV",
    defaultMessage: "Lae CSV"
  },
  uploadCSV: {
    id: "button.uploadCSV",
    defaultMessage: "Laadi üles CSV"
  },
  rrRequest: {
    id: "button.rrRequest",
    defaultMessage: "Uuenda RRist"
  },
  hide: {
    id: "button.hide",
    defaultMessage: "Peida"
  },
  confirmHide: {
    id: "button.confirmHide",
    defaultMessage: "Peida taotlus"
  },
  cancelHide: {
    id: "button.cancelHide",
    defaultMessage: "Tühista tegevus"
  },
  representativeDeletionModalPrimaryBtn: {
    id: "button.representativeDeletionModalPrimaryBtn",
    defaultMessage: "Eemalda volitus"
  },
  representativeSuspensionModalPrimaryBtn: {
    id: "button.representativeSuspensionModalPrimaryBtn",
    defaultMessage: "Lõpeta volitus"
  },
  updateSize: {
    id: "button.updateSize",
    defaultMessage: "Uuenda suurust"
  },
  documentRequirements: {
    id: "button.documentRequirements",
    defaultMessage: "Nõuded dokumentatsioonile"
  },
  changeCompetence: {
    id: "button.changeCompetence",
    defaultMessage: "Muuda pädevust"
  },
  markItManually: {
    id: "button.markItManually",
    defaultMessage: "märkige see käsitsi"
  },
  useAddressSearch: {
    id: "button.useAddressSearch",
    defaultMessage: "aadressi otsinguga"
  },
  addEducation: {
    id: "button.addEducation",
    defaultMessage: "Lisa uus haridus"
  },
  toggleOn: {
    id: "button.toggleOn",
    defaultMessage: "Lülita sisse"
  },
  toggleOff: {
    id: "button.toggleOff",
    defaultMessage: "Lülita välja"
  },
  runQuery: {
    id: "button.runQuery",
    defaultMessage: "Käivita päring"
  },
  applyQueryResults: {
    id: "button.applyQueryResults",
    defaultMessage: "Rakenda päringu tulemused"
  },
  markResidencyCompleted: {
    id: "button.markResidencyCompleted",
    defaultMessage: "Märgi residentuur lõpetatuks"
  },
  markCompleted: {
    id: "button.markCompleted",
    defaultMessage: "Märgi lõpetatuks"
  },
  acceptAutomatically: {
    id: "button.acceptAutomatically",
    defaultMessage: "Menetle automaatselt"
  },
  proceed: {
    id: "button.proceed",
    defaultMessage: "Menetle"
  },
  addOrEditDocuments: {
    id: "button.addOrEditDocuments",
    defaultMessage: "Lisa/muuda dokumente"
  }
});

export type ButtonName = keyof typeof Messages;

export interface FormattedButtonProps extends Omit<ButtonProps, "id"> {
  id: ButtonName;
  idPrefix?: string | number;
}

export const FormattedButton = ({
  id,
  idPrefix,
  ...props
}: FormattedButtonProps) => (
  <Button id={`${ id }${ idPrefix ? `_${ idPrefix }` : "" }`} {...props}>
    <FormattedMessage {...Messages[id]} />
  </Button>
);
