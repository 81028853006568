import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { useDetailedApplication } from "../../Details/ActivityLicenseApplicationDetailsHook";
import { Loader } from "../../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { QuickLinks } from "../../../../../Component/QuickLinks/QuickLinks";
import { ApplicationNotes } from "../../../Application/Details/ApplicationNotes";
import { ActivityLicenseDetailsHistory } from "../../Application/Details/ActivityLicenseDetailsHistory";
import { ApplicationDecisions } from "../../../Application/Details/ApplicationDecisions";
import { DetailsCard } from "./DetailsCard";
import { DiscardServicesRows } from "../../../../Shared/ActivityLicense/Services/Discard/DiscardServicesRows";
import {
  DetailedDiscardServiceApplication,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI
} from "../../../../../../api_client/medre_api";
import { DiscardServiceApplicationDetailsActions } from "./DiscardServiceApplicationDetailsActions";
import { getBaseUrl, API } from "../../../../../api";

export const DiscardServiceApplicationDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { details, setDetails, setIsLoading, isLoading, refresh } =
    useDetailedApplication<DetailedDiscardServiceApplication>(
      officialActivityPermitAPI(undefined, getBaseUrl(), API)
        .getDetailedDiscardServicesApplication,
      id
    );

  const isSubmitted =
    (details?.currentStatus as ActivityLicenseApplicationStatus) !==
      ActivityLicenseApplicationStatus.Saved &&
    (details?.currentStatus as ActivityLicenseApplicationStatus) !==
      ActivityLicenseApplicationStatus.Registering;

  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      {
        id: "history",
        title: "Menetluse ajalugu"
      },
      {
        id: "decisions",
        title: "Otsused"
      }
    ],
    []
  );

  if (!details || isLoading) {
    return <Loader />;
  }

  return (
    <div className="application-container activity-license-details-container">
      <div className="d-flex align-items-center mx-3 mb-1">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details.currentStatus}
          isStateFeePaid={true}
          isDetailed={true}
        />
      </div>
      <div className="ml-3 activity-license-quick-links">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <DiscardServiceApplicationDetailsActions
          details={details}
          setDetails={setDetails}
          refresh={refresh}
          setIsLoading={setIsLoading}
        />
      </Card>
      <DetailsCard details={details} />
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Loobutavad teenused
        </h2>
        <DiscardServicesRows
          activityLocationName={details.activityLocationName}
          services={details.services}
          refresh={refresh}
        />
      </Card>
      <ApplicationNotes
        notes={details.notes || ""}
        setDetails={setDetails}
        endpoint={
          officialActivityPermitAPI(undefined, getBaseUrl(), API)
            .updateDiscardServiceApplicationNote
        }
        detailsId={details.id}
      />
      {isSubmitted && (
        <ActivityLicenseDetailsHistory
          auditLogItems={details.auditLogItems || []}
        />
      )}
      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="decisions" /> Otsused
          <ApplicationDecisions decisions={details.decisions || []} />
        </h2>
      </Card>
    </div>
  );
};
