import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Col,
  FormGroup
} from "reactstrap";
import {
  Controller,
  FormProvider,
  useForm,
  ControllerRenderProps
} from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import {
  OccupationsFormCountries,
  defaultAppendCountryValue
} from "../../../Shared/ApplicationCertificate/Occupations/OccupationsFormCountries";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { OccupationsFormSpeciality } from "../../../Shared/ApplicationCertificate/Occupations/OccupationsFormSpeciality";
import { getBaseUrl, API } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { Option } from "../../../../Component/Select/SelectTypes";
import { ApplicationsContext } from "../../../Shared/Application/ApplicationsContext";
import { SpecialityCode } from "../../../../Dto/Person/Person";
import {
  ApplicationCertificate,
  ApplicationStatusWrapperStatusEnum,
  Person,
  PersonCertificate,
  Occupation,
  OccupationCode,
  AmetnikuSertifikaadiAndmeteenusApiFactory as officialCertificateDataAPI
} from "../../../../../api_client/medre_api";
import { FormattedButtonProps } from "../../../../Component/Button/FormattedButton";

export interface CertificateData
  extends Omit<PersonCertificate, "occupationCodes" | "speciality"> {
  personFullName: string;
  occupationCodes: Pick<OccupationCode, "occupationId">[];
  speciality?: Pick<SpecialityCode, "specialityId">;
}

const OCCUPATION_CODES = "occupationCodes";
const OCCUPATION_ID = "occupationId";
const SPECIALITY_ID = "specialityId";
const momentUTC = (date: Date | undefined = undefined) =>
  moment.utc(date).startOf("day");

const defaultCertificateCountriesValue = [defaultAppendCountryValue];
export const defaultCertificateValues = {
  personFullName: "",
  id: "",
  applicationCertificateId: "",
  [OCCUPATION_CODES]: [],
  countries: defaultCertificateCountriesValue,
  educationCountries: defaultCertificateCountriesValue,
  issueDate: momentUTC().toISOString()
};

const getCountriesValue = (countries?: CertificateData["countries"]) =>
  Array.isArray(countries) && countries.length
    ? countries
    : defaultCertificateCountriesValue;

const getOccupationCodes = (
  certOccupations: Occupation[]
): Array<{ [OCCUPATION_ID]?: string }> => {
  return certOccupations.map((occupation) => ({
    [OCCUPATION_ID]: occupation.id
  }));
};

export const mapApplicationCertificateToCertificateValues = (
  person: Person,
  certificate?: ApplicationCertificate | PersonCertificate
) => ({
  ...defaultCertificateValues,
  personFullName: `${person.firstName} ${person.lastName}`,
  ...(certificate && {
    applicationCertificateId: certificate?.id as string,
    [OCCUPATION_CODES]: getOccupationCodes(
      (certificate as ApplicationCertificate).occupations!
    ),
    ...(certificate?.speciality && {
      speciality: { [SPECIALITY_ID]: certificate.speciality.id as string }
    }),
    countries: getCountriesValue(certificate.countries),
    educationCountries: getCountriesValue(
      (certificate as PersonCertificate).educationCountries
    )
  })
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  defaultValues?: CertificateData | PersonCertificate;
  person: Person;
  disableApplicationCertificateSelect?: boolean;
}

export const ApplicantCertificatesModal = ({
  isOpen,
  onClose,
  afterSubmit,
  defaultValues,
  person,
  disableApplicationCertificateSelect
}: Props) => {
  const form = useForm<CertificateData | PersonCertificate>({
    mode: "onChange",
    defaultValues,
    shouldUnregister: false
  });
  const { control, reset } = form;

  const { applicationCertificates } =
    useContext(ApplicationsContext).applications;

  const allowedApplicationCertificates = useMemo(
    () =>
      applicationCertificates!.filter(
        ({ currentStatus }) =>
          currentStatus?.status ===
            ApplicationStatusWrapperStatusEnum.InProceeding ||
          currentStatus?.status === ApplicationStatusWrapperStatusEnum.Accepted
      ),
    [applicationCertificates]
  );

  const usedApplicationCertificateIds = useMemo(
    () =>
      (person.certificates ?? [])
        .map(({ applicationCertificateId }) => applicationCertificateId)
        .filter(Boolean),
    [person.certificates]
  );
  const [applicationCertificateOptions] = useState<Option[]>(
    allowedApplicationCertificates
      .filter(
        ({ id }) =>
          defaultValues!.applicationCertificateId === id ||
          !usedApplicationCertificateIds.includes(id)
      )
      .map(({ id, applicationNumber }) => ({
        value: id!,
        label: `Taotlus ${applicationNumber!}`
      }))
  );

  const [disableAppCertificateSelect] = useState(
    Boolean(
      disableApplicationCertificateSelect ||
        !applicationCertificateOptions.length ||
        defaultValues?.id
    )
  );
  const handleChangeApplicationCertificateId = useCallback(
    ({ value: applicationCertificateId }: Option) => {
      const application = applicationCertificates!.find(
        ({ id }) => id === applicationCertificateId
      );
      reset(mapApplicationCertificateToCertificateValues(person, application));
    },
    [applicationCertificates, reset, person]
  );

  const handleChangeDate = useCallback(
    (field) => (date?: Date) => {
      field.onChange(momentUTC(date).toISOString());
    },
    []
  );

  const dispatch = useDispatch();
  const handleSendRequest = useCallback(
    async (request: Promise<AxiosResponse>) => {
      try {
        await request;
        await afterSubmit();
        onClose();
      } catch (error) {
        const alertMessage = <AlertMessage id="applicationSaveFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        return Promise.reject(error as AxiosError);
      }
    },
    [onClose, afterSubmit, dispatch]
  );

  // Edit already created certificate
  const handleEditCertificate = useCallback(
    async (values) => {
      await handleSendRequest(
        officialCertificateDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).updatePersonCertificate(person.id as string, values.id, values, {
          withCredentials: true
        })
      );
    },
    [handleSendRequest, person]
  );
  // Create new certificate
  const handleCreateCertificate = useCallback(
    async (values) => {
      await handleSendRequest(
        officialCertificateDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).createPersonCertificate(person.id as string, values, {
          withCredentials: true
        })
      );
    },
    [handleSendRequest, person]
  );

  const isEdit = useMemo(() => Boolean(defaultValues?.id), [defaultValues]);
  const { isValid, isSubmitting } = form.formState;

  const submitButtonProps: FormattedButtonProps = {
    disabled: !isValid || isSubmitting,
    id: "saveAndEndProcedure",
    onClick: form.handleSubmit(handleCreateCertificate),
    ...(isEdit && {
      onClick: form.handleSubmit(handleEditCertificate)
    })
  };
  return (
    <Modal isOpen={isOpen} size="lg">
      <FormProvider {...form}>
        <ModalHeader>{isEdit ? "Muuda tõendit" : "Lisa uus tõend"}</ModalHeader>
        <ModalBody className="certificate-request-form">
          <FormGroup row={true}>
            <Label sm={4} className="text-left text-sm-right">
              Isik
            </Label>
            <Col sm={7} md={5} className="d-flex align-items-center">
              <Controller
                name="personFullName"
                control={control}
                defaultValue={null}
                render={({
                  value
                }: ControllerRenderProps<Record<string, any>>) => value}
              />
            </Col>
          </FormGroup>

          <FormGroup row={true}>
            <Label sm={4} className="text-left text-sm-right">
              Taotlus
            </Label>
            <Col sm={7} md={5} className="d-flex align-items-center">
              <Controller
                name="applicationCertificateId"
                control={control}
                defaultValue={null}
                render={({
                  value
                }: ControllerRenderProps<Record<string, any>>) => (
                  <SingleSelect
                    className="w-100"
                    options={applicationCertificateOptions}
                    disabled={disableAppCertificateSelect}
                    isMulti={false}
                    hideSearchIcon={true}
                    handleOptionChange={handleChangeApplicationCertificateId}
                    value={value}
                  />
                )}
              />
            </Col>
          </FormGroup>

          <OccupationsFormSpeciality
            occupationsKey={OCCUPATION_CODES}
            occupationsValuesKey={OCCUPATION_ID}
            specialityValueKey={SPECIALITY_ID}
          />

          <OccupationsFormCountries name="countries" required={true} excludedCountries={['EST']} />

          <OccupationsFormCountries
            label="Hariduse omandamise riik"
            name="educationCountries"
          />

          <FormGroup row={true} className="required">
            <Label sm={4} className="text-left text-sm-right">
              Väljastatud
            </Label>
            <Col sm={7} md={5} className="d-flex align-items-center pb-2">
              <Controller
                control={control}
                name="issueDate"
                rules={{ required: true }}
                defaultValue=""
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <DatePickerComponent
                    onDateChange={handleChangeDate(field)}
                    selectedDate={field.value}
                    disableFuture={true}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="discard"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton {...submitButtonProps} />
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
