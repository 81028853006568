import React, { FC, useCallback } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { GpListHistoryTypes } from "./GpListHistoryTypes";

export const TabList = [
  { id: GpListHistoryTypes.ChangeFamilyDoctor, title: "Perearstid" },
  { id: GpListHistoryTypes.ChangeSubstituteDoctor, title: "Asendusarstid" },
  { id: GpListHistoryTypes.ChangeSubstitute, title: "Asendajad"},
  { id: GpListHistoryTypes.ChangeLicense, title: "Nimistu ja tegevuslubade seosed" },
  { id: GpListHistoryTypes.ChangeServiceArea, title: "Teeninduspiirkonnad" },
  { id: GpListHistoryTypes.ChangeEmployees, title: "Töötajad"},
  { id: GpListHistoryTypes.ChangeMaxPatientsSize, title: "Piirsuurus" },
  { id: GpListHistoryTypes.ChangeLicenseAddress, title: "Tegevuskohad" },
];

interface Props {
  onChange: (id: GpListHistoryTypes) => void;
  activeTab: GpListHistoryTypes;
}

const GpListHistoryTabs: FC<Props> = ({ onChange, activeTab }) => {
  const handleClick = useCallback((e) => {
    const { id } = e.currentTarget.dataset;

    onChange(id);
  }, [onChange]);
  return (
    <Nav tabs={true}>
      {TabList.map(({ id, title }) => (
        <NavItem key={id} className="w-auto">
          <NavLink
            className={activeTab === id ? "active" : ""}
            onClick={handleClick}
            data-id={id}
          >
            {title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default GpListHistoryTabs;
