import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";

import { formatProceedingType } from "../../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { getBaseUrl, API } from "../../../../../api";
import { Loader } from "../../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { CompanyInfoRow } from "../../Applications/CompanyInfoRow";
import { DecisionsRow } from "../../Applications/DecisionsRow";
import {
  DiscardServiceApplication,
  ActivityLicenseApplicationProceedingTypeEnum,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../../api_client/medre_api";
import { DiscardServicesRows } from "../../../../Shared/ActivityLicense/Services/Discard/DiscardServicesRows";

interface Props {
  isOpen?: boolean;
  id?: string;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
}

export const DiscardServiceAccordion = ({
  isOpen,
  id,
  proceedingType
}: Props) => {
  const [discardServiceApplication, setDiscardServiceApplication] =
    useState<DiscardServiceApplication>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);

  const loadApplication = useCallback(() => {
    setIsLoading(true);
    activityPermitAPI(undefined, getBaseUrl(), API)
      .getDiscardServicesApplication(id as string, {
        withCredentials: true
      })
      .then((res) => setDiscardServiceApplication(res.data))
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      loadApplication();
    }
  }, [isOpen, loadApplication]);

  if (isLoading) {
    return <Loader backdrop={true} />;
  }

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={discardServiceApplication?.currentStatus}
      isDetailed={false}
    />
  );

  const submittingPerson =
    discardServiceApplication?.submittingPersonFirstName +
    " " +
    discardServiceApplication?.submittingPersonLastName;

  return (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            discardServiceApplication?.applicationNumber?.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            "licenseNumber",
            discardServiceApplication?.licenseNumber
          )}
          {renderOverViewRow(
            "activityLicense",
            "submissionDate",
            formatDate(discardServiceApplication?.createdAt)
          )}
          {renderOverViewRow("application", "state", state)}
          {renderOverViewRow(
            "activityLicense",
            "businessArea",
            discardServiceApplication?.businessAreaName
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={discardServiceApplication?.company}
          submittingPerson={submittingPerson}
          submittingPersonIdCode={
            discardServiceApplication?.submittingPersonIdCode
          }
        />
        <DiscardServicesRows
          activityLocationName={discardServiceApplication?.activityLocationName}
          services={discardServiceApplication?.services}
          refresh={loadApplication}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={discardServiceApplication?.decisions}
        />
      </td>
    </tr>
  );
};
