import React, { useMemo, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";

import "./ApplicationTableRow.scss";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import {
  renderApplicantCol,
  renderApplicationRow
} from "./ApplicationTableRowUtil";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import { ApplicationFM } from "../../../../../Messages/ApplicationFM";
import { ApplicationState } from "../../../../../Application/ApplicationState";
import { StateFeeFM } from "../../../../../Messages/StateFeeFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { PlusIcon } from "../../../../../Component/Icon/PlusIcon";
import { MinusIcon } from "../../../../../Component/Icon/MinusIcon";
import { StateFeeInfoModal } from "../../../../Shared/StateFee/StateFeeInfoModal";
import { RenewalModal } from "../../Renewal/RenewalModal";
import { PrimaryFormattedButton } from "../../../../../Component/Button/PrimaryFormattedButton";
import { handleFileDownload } from "../../../../../Util/FileUtils";
import { getApplicationFinalDecision } from "../../../../../Dto/Decision/Decision";
import { DecisionFM } from "../../../../../Messages/DecisionFM";
import {
  Application,
  ProceedingStatusStatusEnum,
  ApplicationCertificate,
  Person,
  ApplicationCertificateDeliveryTypeEnum,
  DetailedApplicationTypeEnum,
  FileReference
} from "../../../../../../api_client/medre_api";
import { getDecisionInfo } from "../../../../Shared/Decision/DecisionRowUtil";

interface Props {
  application: Application | ApplicationCertificate;
  isOpen?: boolean;
  person: Person;
  index: number;
  name?: string;
  handleRowClick: (index: number) => void;
  handleApplicationFileUpdate: (
    applicationIndex: number,
    fileReferences: FileReference[]
  ) => void;
  reloadApplications: () => void;
}

export const ApplicationTableRow = React.memo(
  ({
    application,
    isOpen,
    person,
    index,
    handleRowClick,
    name,
    handleApplicationFileUpdate,
    reloadApplications
  }: Props) => {
    const {
      type,
      educationInfo,
      stateFee,
      fileReferences,
      currentStatus
    }: Application = application;
    let education, deliveryType, deliveryAddress;
    if ("education" in application) {
      education = application.education;
    }
    if ("deliveryType" in application) {
      deliveryType = application.deliveryType;
      deliveryAddress = application.deliveryAddress;
    }

    const [stateFeeModalIsOpen, setStateFeeModalIsOpen] =
      useState<boolean>(false);
    const [renewalModalIsOpen, setRenewalModalIsOpen] =
      useState<boolean>(false);
    const typeString = useMemo(() => {
      switch (type) {
        case DetailedApplicationTypeEnum.Occupation:
          return "occupation";
        case DetailedApplicationTypeEnum.Specialization:
          return "speciality";
        case DetailedApplicationTypeEnum.ApplicationCertificate:
          return "certificate";
        default:
          return "";
      }
    }, [type]);

    const className = isOpen ? "expanded" : "";
    const widthSize = useWindowWidthSize();
    const applicationState = (
      <ApplicationState application={application} index={index} />
    );
    const deadline = stateFee?.paid ? undefined : stateFee?.stateFeeDeadline;

    const renderRowContent = (icon: JSX.Element) => {
      return renderApplicationRow(
        widthSize,
        icon,
        name!,
        applicationState,
        formatDate(application.arrivalTime),
        deadline ? new Date(deadline) : undefined,
        application.applicationNumber
      );
    };

    const applicationIsInFinishedState = () => {
      return (
        currentStatus?.status === ProceedingStatusStatusEnum.Accepted ||
        currentStatus?.status === ProceedingStatusStatusEnum.Declined
      );
    };

    const getHeaderRow = () => {
      if (!application.stateFee?.paid) {
        return (
          <Row className="justify-content-md-end justify-content-center application-header-row">
            <Button
              className="ml-1"
              onClick={() => setStateFeeModalIsOpen(true)}
            >
              <StateFeeFM id="stateFeeInstructionHeader" />
            </Button>
          </Row>
        );
      } else if (
        application.currentStatus?.status === ProceedingStatusStatusEnum.Paused
      ) {
        return (
          <Row className="application-header-row renewal-row">
            <div className="renewal-notes">
              <div className="renewal-notes-header">
                <FormattedMessage
                  id="RenewalNotesHeader"
                  defaultMessage="Ametniku poolt lisatud puuduliku taotluse põhjus"
                />
              </div>
              <div className="renewal-notes-content">
                {(application as Application).renewalNotes}
              </div>
            </div>
            <div className="renewal-button">
              <PrimaryFormattedButton
                id="renewApplication"
                className="ml-1"
                onClick={() => setRenewalModalIsOpen(true)}
              />
            </div>
          </Row>
        );
      } else if (
        application.currentStatus?.status ===
          ProceedingStatusStatusEnum.Accepted ||
        application.currentStatus?.status ===
          ProceedingStatusStatusEnum.Declined
      ) {
        const finalDecision = getApplicationFinalDecision(application)[0];
        if (!finalDecision) {
          return;
        }
        const { signed, fileName, minioContainerName, minioFileName } =
          finalDecision;
        const { name: containerName, minioId } = getDecisionInfo(
          signed,
          fileName as string,
          minioContainerName as string,
          minioFileName as string
        );
        return (
          <Row className="justify-content-md-end justify-content-center application-header-row">
            <Button
              className="ml-1"
              onClick={() => handleFileDownload(containerName, minioId)}
            >
              <DecisionFM id="download" />
            </Button>
          </Row>
        );
      }
      return <Row className="application-header-row" />;
    };

    const getStateFeeText = () => {
      if (stateFee?.paid) {
        return <StateFeeFM id="paid" />;
      } else {
        return (
          <StateFeeFM
            id="notPaidWithButton"
            values={{
              button: (msg: string) => (
                <Button
                  className="p-0 link-button"
                  color="link"
                  onClick={() => setStateFeeModalIsOpen(true)}
                >
                  {msg}
                </Button>
              )
            }}
          />
        );
      }
    };

    const handleUploadSuccess = (files: FileReference[]) => {
      handleApplicationFileUpdate(index, [
        ...((application as Application).fileReferences || []),
        ...files
      ]);
    };

    const handleDeleteSuccess = (fileName: string) => {
      handleApplicationFileUpdate(
        index,
        (application as Application).fileReferences?.filter(
          (file) => file.minioFileName !== fileName
        ) || []
      );
    };

    const handleDeleteMultipleSuccess = (fileNames: string[]) => {
      handleApplicationFileUpdate(
        index,
        (application as Application).fileReferences?.filter(
          (file) => !fileNames.includes(file.minioFileName || "")
        ) || []
      );
    };

    if (!isOpen) {
      return (
        <tr
          className={`${className} cursor-pointer`}
          onClick={() => handleRowClick(index)}
        >
          {renderRowContent(
            <button className="btn-unstyled d-inline-block">
              <PlusIcon />
            </button>
          )}
        </tr>
      );
    } else {
      return (
        <>
          <tr
            className={`${className} cursor-pointer`}
            onClick={() => handleRowClick(index)}
          >
            {renderRowContent(
              <button className="btn-unstyled d-inline-block">
                <MinusIcon />
              </button>
            )}
          </tr>
          <tr className="detail-info">
            <td colSpan={6}>
              {getHeaderRow()}
              <Row>
                {renderApplicantCol(
                  person,
                  educationInfo,
                  education,
                  deliveryType as ApplicationCertificateDeliveryTypeEnum,
                  fileReferences,
                  deliveryAddress,
                  typeString
                )}
                <Col
                  className="detail-col pl-0 pr-0 order-first order-md-last"
                  xs={12}
                  md={6}
                >
                  <Row className="col-header">
                    <GeneralFM id="applicationData" />
                  </Row>
                  {renderOverViewRow(
                    "application",
                    "applicationType",
                    <ApplicationFM id={typeString} />
                  )}
                  {renderOverViewRow(
                    "application",
                    "applicationNumber",
                    application.applicationNumber?.toString()
                  )}
                  {renderOverViewRow(
                    "application",
                    "submissionDate",
                    formatDate(application.arrivalTime)
                  )}
                  {renderOverViewRow("stateFee", "stateFee", getStateFeeText())}
                  {renderOverViewRow(
                    "stateFee",
                    "stateFeeSum",
                    `${stateFee?.stateFeeValue?.toString()} €`
                  )}
                  {renderOverViewRow("application", "state", applicationState)}
                  {applicationIsInFinishedState() &&
                    renderOverViewRow(
                      "decision",
                      "decisionDate",
                      formatDate(
                        getApplicationFinalDecision(application)[0]?.signedAt ||
                          currentStatus?.createdAt
                      )
                    )}
                </Col>
              </Row>
            </td>
          </tr>
          <StateFeeInfoModal
            isOpen={stateFeeModalIsOpen}
            onClose={() => setStateFeeModalIsOpen(false)}
            deadline={application.stateFee?.stateFeeDeadline}
            referenceNumber={application.stateFee?.referenceNumber}
            stateFeeValue={application.stateFee?.stateFeeValue}
          />
          <RenewalModal
            isOpen={renewalModalIsOpen}
            onClose={() => setRenewalModalIsOpen(false)}
            applicationId={application.id!}
            notes={(application as Application).renewalNotes!}
            educationInfo={(application as Application).educationInfo!}
            fileReferences={(application as Application).fileReferences || []}
            occupationCode={(application as Application).occupation?.code}
            onUploadSuccess={handleUploadSuccess}
            onDeleteSuccess={handleDeleteSuccess}
            onDeleteMultipleSuccess={handleDeleteMultipleSuccess}
            reloadApplications={reloadApplications}
            personId={application.personId!}
          />
        </>
      );
    }
  }
);
