import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import "./DocumentsInfoModal.scss";
import info_et from "../../../../../translations/document_instructions/et.json";
import info_en from "../../../../../translations/document_instructions/en.json";
import info_ru from "../../../../../translations/document_instructions/ru.json";
import { FileObjectType } from "../../../../../api_client/medre_api";
import { RootState } from "../../../../rootReducer";

interface Props {
  isOpen: boolean;
  type: FileObjectType | string;
  fileUrl: string;
  onClose: () => void;
}

export const DocumentsInfoModal = ({
  isOpen,
  type,
  fileUrl,
  onClose
}: Props) => {
  const HEADER = "header";
  const BODY = "body";
  const LINK = "link";
  const locale = useSelector((state: RootState) => state.locale);

  // tslint:disable-next-line:no-any
  let info: any;
  switch (locale) {
    case "en":
      info = info_en;
      break;
    case "ru":
      info = info_ru;
      break;
    case "et":
    default:
      info = info_et;
      break;
  }

  return (
    <Modal isOpen={isOpen} id="documents-info-modal">
      <ModalHeader tag="h4">{info[type][HEADER]}</ModalHeader>
      <ModalBody className="my-2 py-0">
        <p>{info[type][BODY]}</p>
        <a
          href={fileUrl ? fileUrl : info[type][LINK]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {info[type][LINK]}
        </a>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <SecondaryFormattedButton id="backToApplication" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};
