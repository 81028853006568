import { Card, Nav, NavItem } from "reactstrap";
import classNames from "classnames";
import React, {ChangeEvent, Fragment, MouseEvent, useState} from "react";
import "./ProceedingTab.scss";
import {FilterColumn, FilterContainer, FilterField} from "../../../../Component/Filter/Filter";
import {useDispatch} from "react-redux";
import {Option} from "../../../../Component/Select/SelectTypes";
import {MultiSelect} from "../../../../Component/Select/MultiSelect";
import {FormattedMessage} from "react-intl";
import {applicationStatuses} from "../../../Shared/Search/Filters/FilterUtils";
import {SecondaryFormattedButton} from "../../../../Component/Button/SecondaryFormattedButton";
import {PrimaryFormattedButton} from "../../../../Component/Button/PrimaryFormattedButton";

interface Props {
  activeTab: string;
  currentStatuses: Option[];
  setCurrentStatuses: React.Dispatch<React.SetStateAction<Option[]>>;
  handleSearchButton: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClearButton: (event: MouseEvent<HTMLButtonElement>) => void;
  handleTabChange: (event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>) => void;
  tabs: {id: string; value: string; label: string}[];
}

export const ProceedingTab = ({ activeTab, handleTabChange, tabs,
                                  handleSearchButton,
                                  handleClearButton,
                                  currentStatuses,
                                  setCurrentStatuses, }: Props) => {
  const activityLicenseApplicationsTab = "activityLicenseApplications";
  const activityLicenseApplicationsTitle = "Tegevuslubade taotluste menetlemine";
  const applicationsTitle = "THT taotluste menetlemine";

    const dispatch = useDispatch();

    const filterByApplicationStatus = () => {
        return (
            <div className="mt-4">
                <FilterContainer>
                    <FilterColumn>
                        <FilterField
                            id="applicationFiltersCurrentStatuses"
                            label="Taotluse staatus"
                            field={
                                <MultiSelect
                                    inputId="applicationFiltersCurrentStatuses"
                                    options={applicationStatuses}
                                    handleOptionsChange={(e) => setCurrentStatuses(e)}
                                    value={currentStatuses}
                                    placeholder={
                                        <FormattedMessage
                                            id="filters.selectStatus"
                                            defaultMessage="Vali taotluse staatus"
                                        />
                                    }
                                />
                            }
                        />
                    </FilterColumn>
                </FilterContainer>
            </div>
        )
    }

  return (
    <div className="tabs-container">
      <Nav tabs>
        { tabs.map(({ id, value, label }) => {
          const isActive = value === activeTab;
          return (
            <NavItem key={id} className="w-auto">
              <button
                className={classNames("nav-link", {
                  active: isActive,
                  "bg-transparent": !isActive,
                  "bg-white": isActive
                })}
                onClick={handleTabChange}
                value={value}
              >
                { label }
              </button>
            </NavItem>
          );
        }) }
      </Nav>
      <Card className="p-4">
        <h3 className="fw-normal mb-0">
          { activeTab === activityLicenseApplicationsTab ? activityLicenseApplicationsTitle : applicationsTitle }
        </h3>
          {
              activeTab !== activityLicenseApplicationsTab &&
              <Fragment>
                  {filterByApplicationStatus()}
                  <div className="mt-3">
                      <PrimaryFormattedButton className="mr-1" id="search" onClick={handleSearchButton}/>
                      <SecondaryFormattedButton className="ml-1" id="clearAllValues" onClick={handleClearButton}/>
                  </div>
              </Fragment>
          }
      </Card>
    </div>
  );
};