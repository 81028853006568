import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import phoneCountryCodes from "../../../../../assets/phone-country-code/phone-country-codes.json";
import "flag-icon-css/css/flag-icon.css";

interface Props {
  dialCode: string;
  updateDialCode: (dialCode: string) => void;
}

export const PhoneCodeSelector = ({ dialCode, updateDialCode }: Props) => {
  const [notInitialLogin] = useState(dialCode);
  const [initialDialCodeUpdateDone, setInitialDialCodeUpdateDone] =
    useState(false);
  const createIconLabel = (code: string, labelString: string) => (
    <>
      <div className={`flag-icon flag-icon-${code}`} /> {labelString}
    </>
  );

  const getInitialPhoneCountryCodeOption = () => {
    let value: string, label: JSX.Element;
    if (notInitialLogin) {
      const initialPhoneCountryObject = phoneCountryCodes.filter(
        (country) => country.dial_code === dialCode
      )[0] || { dial_code: "+372", code: "ee" };
      value = initialPhoneCountryObject.dial_code;
      label = createIconLabel(
        initialPhoneCountryObject.code,
        initialPhoneCountryObject.dial_code
      );
    } else {
      value = "+372";
      label = createIconLabel("ee", "+372");
    }
    if (!initialDialCodeUpdateDone) {
      updateDialCode(value);
      setInitialDialCodeUpdateDone(true);
    }

    return { value, label };
  };

  const [selectedOption, setSelectedOption] = useState<Option>(
    getInitialPhoneCountryCodeOption()
  );

  const selectOptions = phoneCountryCodes.map((data) => {
    const label = createIconLabel(data.code, data.dial_code);
    return { value: data.dial_code, label: label };
  });

  const selectBoxPlaceholder = (
    <FormattedMessage
      id="phoneCodeSelection.placeholder"
      defaultMessage="Otsi nr"
    />
  );

  const handlePhoneCodeChange = (option: Option) => {
    setSelectedOption(option);
    updateDialCode(option.value);
  };

  return (
    <SingleSelect
      value={selectedOption}
      options={selectOptions}
      placeholder={selectBoxPlaceholder}
      handleOptionChange={handlePhoneCodeChange}
      className="phone-code-selector"
      hideSearchIcon={true}
    />
  );
};
