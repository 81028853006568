import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
}

type Message =
  | "contacts"
  | "contactsCertificate"
  | "certificateIssuance"
  | "certificateIssuanceAddress"
  | "email"
  | "phone"
  | "country"
  | "companyAddress"
  | "certificateDeliveryType"
  | "takeCertificateCompanyAddress"
  | "address"
  | "website"
  | "workingDays"
  | "workingHours"
  | "representative"
  | "representativeIdCode"
  | "certificateIssuedElectronically"
  | "certificateDeliveryByPost"
  | "certificateDeliveryAddressTitle"
  | "digitalPlatform";

export const ContactInfoFM = ({ id }: Props) => {
  const messages: Record<Message, LocaleMessage> = defineMessages({
    contacts: {
      id: "contactInfoFM.contacts",
      defaultMessage: "Kontaktandmed"
    },
    contactsCertificate: {
      id: "contactInfoFM.contactsCertificate",
      defaultMessage: "Taotluse kontaktandmed ja tõendi väljastamine"
    },
    certificateIssuance: {
      id: "contactInfoFM.certificateDeliveryType",
      defaultMessage: "Tõendi kättesaamise viis"
    },
    certificateIssuanceAddress: {
      id: "contactInfoFM.certificateIssuanceAddress",
      defaultMessage: "Tõend saadetakse aadressile"
    },
    email: {
      id: "contactInfoFM.email",
      defaultMessage: "E-post"
    },
    phone: {
      id: "contactInfoFM.phone",
      defaultMessage: "Telefon"
    },
    country: {
      id: "contactInfoFM.country",
      defaultMessage: "Riik"
    },
    companyAddress: {
      id: "contactInfoFM.companyAddress",
      defaultMessage: "Paldiski mnt 81, 10614 Tallinn"
    },
    certificateDeliveryType: {
      id: "contactInfoFM.certificateDeliveryType",
      defaultMessage: "Tõendi kättesaamise viis"
    },
    takeCertificateCompanyAddress: {
      id: "contactInfoFM.takeCertificateCompanyAddress",
      defaultMessage:
        "Tulen tõendile Terviseametisse järele ({address}) ametnikuga kokkulepitud ajal",
      values: {
        address: <ContactInfoFM id="companyAddress" />
      }
    },
    address: {
      id: "contactInfoFM.address",
      defaultMessage: "Aadress"
    },
    website: {
      id: "contactInfoFM.website",
      defaultMessage: "Koduleht"
    },
    workingDays: {
      id: "contactInfoFM.workingDays",
      defaultMessage: "Lahtiolekupäevad"
    },
    workingHours: {
      id: "contactInfoFM.workingHours",
      defaultMessage: "Lahtiolekuajad"
    },
    representative: {
      id: "contactInfoFM.representative",
      defaultMessage: "Kontaktisik"
    },
    representativeIdCode: {
      id: "contactInfoFM.representativeIdCode",
      defaultMessage: "Kontaktisiku isikukood"
    },
    certificateIssuedElectronically: {
      id: "ApplicationCertificateContactInfo.electronicIssued",
      defaultMessage: "Tõend väljastatakse elektrooniliselt (.PDF)"
    },
    certificateDeliveryByPost: {
      id: "ApplicationCertificateContactInfo.certificateDeliveryByPost",
      defaultMessage: "Postiga valitud aadressile"
    },
    certificateDeliveryAddressTitle: {
      id: "ApplicationCertificateContactInfo.certificateDeliveryAddressTitle",
      defaultMessage: " "
    },
    digitalPlatform: {
      id: "contactInfoFm.digitalPlatform",
      defaultMessage: "Digiteenindusplatvorm"
    }
  });

  return <FormattedMessage {...messages[id as Message]} />;
};
