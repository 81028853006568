import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./Security.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { RootState } from "../rootReducer";
import { keycloakLogout } from "./TokenHelpers";
import { PrimaryFormattedButton } from "../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../Component/Button/SecondaryFormattedButton";

interface Props {
  toggleLogoutModal: () => void;
  isModalOpen: boolean;
}

export const LogoutModal = ({toggleLogoutModal, isModalOpen}: Props) => {
  const clientId = useSelector((state: RootState) => state.config.clientId);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal keyboard={true} toggle={toggleLogoutModal} isOpen={isModalOpen}>
      <ModalHeader>
        <FormattedMessage
          id="logoutModal.header"
          defaultMessage="Iseteenindusest välja logimine"
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          id="logoutModal.body"
          defaultMessage="Kas olete kindel, et soovite süsteemist väljuda?"
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="no"
          onClick={toggleLogoutModal}
        />
        <PrimaryFormattedButton
          id="yes"
          onClick={() => keycloakLogout(
            loading, "" + clientId, setLoading, dispatch, history)}
        />
      </ModalFooter>
    </Modal>
  );
};
