import React from "react";
import { Dispatch } from "react";
import { History } from "history";
import {
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityAppAPI,
  DetailedActivityLicenseApplicationProceedingTypeEnum
} from "../../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";

export const DeleteApplicationUtil = (
  applicationId: string,
  dispatch: Dispatch<any>,
  history: History<any>,
  proceedingType: DetailedActivityLicenseApplicationProceedingTypeEnum,
  onSuccessId: string,
  onFailureId: string
) => {
  officialActivityAppAPI(undefined, getBaseUrl(), API)
    .deleteApplicationByProceedingType(
      applicationId,
      proceedingType,
      { withCredentials: true }
    )
    .then(() => {
      const alertMessage = (
        <AlertMessage id={onSuccessId} />
      );
      const alert = { id: 0, type: AlertType.Success, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
      history.push("/my-proceedings");
    })
    .catch(() => {
      const alertMessage = (
        <AlertMessage id={onFailureId} />
      );
      const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      dispatch(alertActions.addAlert(alert));
    });
};