import React, { FC, ReactElement } from "react";
import classNames from "classnames";
import { Card } from "reactstrap";

import "./DetailsSection.scss";

interface Props {
  id: string;
  title?: ReactElement | string;
  button?: ReactElement | null;
  className?: string;
}

const DetailsSection: FC<Props> = ({
  id,
  title,
  button,
  className = "",
  children,
}) => {
  return (
    <Card className={classNames("my-3 p-3 my-md-4 p-md-4", className)} id={id}>
      <div className="d-flex justify-content-between mb-2">
        {title && (
          <div className="h2 details-section__title">
            <h2>{title}</h2>
          </div>
        )}
        {button}
      </div>
      {children}
    </Card>
  );
};

export default DetailsSection;
