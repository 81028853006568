import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";
import { AdsAddressInput } from "../../Application/ContactInfo/Address/AdsAddressInput";
import {
  isInsertedEmailValid,
  validateLength,
  validateNumber
} from "../../Validation/fieldValidator";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import { inputRegex } from "../../../../Util/inputRegexValidation";
import { FormattedErrorMessage } from "../../../../Component/ErrorField/FormattedErrorMessage";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import { Checkbox } from "../../../../Component/Checkbox/Checkbox";
import { ActivityLocation } from "../../../../../api_client/medre_api";
import { activityLicenseApplicationActions } from "../../../../ActivityLicense/activityLicenseApplicationActions";

interface Props {
  email?: string;
  phone?: string;
  location: ActivityLocation;
  setIsFormValid: (value: boolean) => void;
  updateAddress: (
    fieldName: keyof ContactInfoForm,
    value?: string | boolean
  ) => void;
  updateContactInfo: (fieldName: keyof ContactInfoForm, value?: string) => void;
  isContactInfoDisabled: boolean;
  isActivityLocationApplication?: boolean;
}

export const ActivityLicenseContactInfoForm = ({
  email,
  setIsFormValid,
  location,
  updateAddress,
  updateContactInfo,
  isContactInfoDisabled,
  isActivityLocationApplication,
  phone
}: Props) => {
  const dispatch = useDispatch();

  const currentPhone = phone ? phone?.replace(" ", "") : undefined;
  const fullAddress = location?.fullAddress;
  const adsAdrID = location?.adsAdrID;
  const zipCode = location?.zipCode;

  const [isMobileActivityLocation, setIsMobileActivityLocation] = useState(
    location.adsAdrID === "mobile" || false
  );
  const [isAddressValid, setIsAddressValid] = useState<boolean | undefined>(
    validateLength(fullAddress)
  );
  const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(
    isInsertedEmailValid(email) || undefined
  );
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>();
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  useEffect(() => {
    setIsFormValid(
      Boolean(
        ((isEmailValid && isPhoneValid) || isContactInfoDisabled) &&
          (isAddressValid || isMobileActivityLocation)
      )
    );
  }, [
    isEmailValid,
    isPhoneValid,
    isAddressValid,
    setIsFormValid,
    isContactInfoDisabled,
    isMobileActivityLocation
  ]);

  useEffect(() => {
    if (email !== undefined) {
      setIsEmailValid(isInsertedEmailValid(email));
    }
  }, [email]);

  useEffect(() => {
    if (currentPhone !== undefined) {
      setIsPhoneValid(
        validateLength(currentPhone) && validateNumber(currentPhone)
      );
    }
  }, [currentPhone]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      let value = e.target.value;
      let fieldName = e.target.name;
      updateContactInfo(fieldName as keyof ContactInfoForm, value);
    }
  };

  const handleMobileActivityLocationCheckboxChange = () => {
    if (!isMobileActivityLocation) {
      dispatch(activityLicenseApplicationActions.setMobileActivityLocation());
    }
    setIsMobileActivityLocation(!isMobileActivityLocation);
  };

  const className = classNames("w-100", "flex-nowrap", {
    "form-inline": !isMobile
  });
  return (
    <>
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.activityLocation"
          defaultMessage="Tegevuskoht"
        />
      </h4>
      <AdsAddressInput
        setIsAddressValid={setIsAddressValid}
        updateContactInfo={updateAddress}
        fullAddress={fullAddress}
        adsAdrID={adsAdrID}
        zipCode={zipCode}
        isMandatory={true}
        disabled={isMobileActivityLocation}
      />
      {!isActivityLocationApplication && (
        <FormGroup className="column-checkbox">
          <Checkbox
            id="is-mobile-activity-location"
            isChecked={isMobileActivityLocation}
            labelText="Mobiilne tegevuskoht"
            onChange={() => handleMobileActivityLocationCheckboxChange()}
          />
        </FormGroup>
      )}
      <h4 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.contactInfo"
          defaultMessage="Kontaktandmed"
        />
      </h4>
      <FormGroup className={className}>
        <Label
          htmlFor="activityLicenseContactInfoFormEmail"
          className="control-label"
        >
          <ContactInfoFM id="email" />
        </Label>
        <div className="input-wrapper">
          <Input
            id="activityLicenseContactInfoFormEmail"
            type="email"
            name="email"
            value={email || ""}
            valid={isEmailValid}
            invalid={
              isEmailValid !== undefined &&
              !isEmailValid &&
              !isContactInfoDisabled
            }
            onChange={(e) => handleChange(inputRegex(e))}
            disabled={isContactInfoDisabled}
            className="input"
          />
          <FormFeedback className="pt-2">
            <FormattedErrorMessage id="mandatoryField" />
          </FormFeedback>
        </div>
      </FormGroup>
      <FormGroup className={className}>
        <Label htmlFor="activityLicenseContactInfoFormPhone">
          <ContactInfoFM id="phone" />
        </Label>
        <div className="input-wrapper">
          <Input
            id="activityLicenseContactInfoFormPhone"
            type="tel"
            name="phone"
            value={currentPhone || ""}
            valid={isPhoneValid}
            invalid={
              isPhoneValid !== undefined &&
              !isPhoneValid &&
              !isContactInfoDisabled
            }
            disabled={isContactInfoDisabled}
            onChange={(e) => handleChange(inputRegex(e))}
          />
          <FormFeedback>
            <FormattedErrorMessage id="phone" />
          </FormFeedback>
        </div>
      </FormGroup>
    </>
  );
};
