import React from "react";

import { LinkButton } from "../Button/LinkButton";

interface Props {
  onClick: () => void;
  name: string;
}

export const ClickableInfoValue = ({ onClick, name }: Props) => (
  <>
    <span className="mr-2">{name || "-"}</span>
    <LinkButton id="lookAtDetails" className="p-0" onClick={onClick} />
  </>
);
