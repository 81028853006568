import React, { ReactNode } from "react";

import { FormattedButton } from "../../../../../Component/Button/FormattedButton";
import "./QueryBox.scss";
import info_icon from "../../../../../../assets/images/information.svg";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../../Constants";

interface Props {
  value?: string;
  useValue: () => void;
  className?: string;
  headerContent: ReactNode;
  textContent: ReactNode;
}

export const QueryBoxInfo = ({ value, useValue, className, headerContent, textContent }: Props) => {
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const buttonClassName =
    value && isMobile && value?.length > 39 ? "pt-1" : "pt-3";

  return (
    <div className={`query-box query-box-info d-flex ${className}`}>
      <div className="info-container">
        <div className="d-inline-flex pt-3 pb-1 pl-3">
          <img src={info_icon} alt="icon" className="with-padding"/>
          <div className="pl-1 query-box-header-text">
            {headerContent}
          </div>
        </div>
        <div className="query-box-text pt-0 pr-2 pb-2 pl-3 mt-1">
          {textContent}
        </div>
      </div>
      <div className={"use-button pr-3 pb-3 pl-3 " + buttonClassName}>
        {value && (
          <FormattedButton
            id="use"
            className="query-box-secondary-button"
            onClick={useValue}
          />
        )}
      </div>
    </div>
  );
};
