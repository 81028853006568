import React, { useCallback } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { applicationDraftActions } from "../../../../Application/applicationDraftActions";
import { RootState } from "../../../../rootReducer";
import { SpecialitySelectionMessageCard } from "./SpecialitySelectionMessageCard";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { Speciality } from "../../../../../api_client/medre_api";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";

interface Props {
  draftId?: string;
  availableSpecialities: Speciality[];
  possessedSpecialities: Speciality[];
  savedSpecialities: Speciality[];
  appliedSpecialities: Speciality[];
}

export const SpecialitySelectionBox = ({
  availableSpecialities,
  possessedSpecialities,
  savedSpecialities,
  appliedSpecialities,
  draftId
}: Props) => {
  const applicationDraft = useSelector(
    (state: RootState) => state.applicationDraft
  );
  const dispatch = useDispatch();
  const selectedSpeciality = applicationDraft.speciality;
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  const specialitySelectBoxPlaceholder = (
    <FormattedMessage
      id="specialitySelectionBox.specialityPlaceholder"
      defaultMessage="Otsi Eriala"
    />
  );

  const specialitySelectOptions = availableSpecialities.map((speciality) => ({
    value: speciality.code as string,
    label: speciality.name as string
  }));

  const getSelectedSpecialityValue = () =>
    selectedSpeciality
      ? { value: selectedSpeciality.code, label: selectedSpeciality.name }
      : undefined;

  const specialityListContainsSpeciality = useCallback(
    (specialityList: Speciality[], speciality?: Speciality) => {
      if (!speciality || !specialityList.length) {
        return false;
      }
      return (
        specialityList.filter((s) => {
          let isNotCurrentDraft =
            s.applicationDraftId === undefined ||
            s.applicationDraftId !== draftId;
          return s.code === speciality.code && isNotCurrentDraft;
        }).length > 0
      );
    },
    [draftId]
  );

  const handleSpecialityChange = (option: Option) => {
    let modifiedDraft = { ...applicationDraft };
    let speciality = availableSpecialities.filter(
      (s) => s.code === option.value
    )[0];
    modifiedDraft.speciality = speciality;
    modifiedDraft.saveAllowed =
      !specialityListContainsSpeciality(possessedSpecialities, speciality) &&
      !specialityListContainsSpeciality(savedSpecialities, speciality) &&
      !specialityListContainsSpeciality(appliedSpecialities, speciality);
    dispatch(applicationDraftActions.setApplicationDraft(modifiedDraft));
  };

  return (
    <>
      <Row>
        <Col className="offset-lg-1 offset-md-1" lg={5} md={11} xs={12}>
          <FormGroup
            className={isMobile ? "" : "form-inline justify-content-end"}
          >
            <Label for="specialitySelectionBoxSpeciality" className="mr-2">
              <ApplicationFM id="speciality" />
            </Label>
            <SingleSelect
              inputId="specialitySelectionBoxSpeciality"
              className={isMobile ? "w-100" : "w-75"}
              placeholder={specialitySelectBoxPlaceholder}
              value={getSelectedSpecialityValue()}
              options={specialitySelectOptions}
              handleOptionChange={handleSpecialityChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {specialityListContainsSpeciality(
        possessedSpecialities,
        selectedSpeciality
      ) && <SpecialitySelectionMessageCard messageType="possessed" />}
      {isPortal &&
        specialityListContainsSpeciality(
          savedSpecialities,
          selectedSpeciality
        ) && <SpecialitySelectionMessageCard messageType="saved" />}
      {!isPortal &&
        specialityListContainsSpeciality(
          savedSpecialities,
          selectedSpeciality
        ) && <SpecialitySelectionMessageCard messageType="savedOfficial" />}
      {isPortal &&
        specialityListContainsSpeciality(
          appliedSpecialities,
          selectedSpeciality
        ) && <SpecialitySelectionMessageCard messageType="applied" />}
      {!isPortal &&
        specialityListContainsSpeciality(
          appliedSpecialities,
          selectedSpeciality
        ) && <SpecialitySelectionMessageCard messageType="appliedOfficial" />}
    </>
  );
};
