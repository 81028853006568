import { useEffect, useState } from "react";

import { GpListHistoryTypes } from "./GpListHistoryTypes";
import { getBaseUrl, API } from "../../../../../api";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyPhysicianList,
  AuditLogItem
} from "../../../../../../api_client/medre_api";
import { useGeneralPractitionerListSelector } from "../../../../../GeneralPractitionerLists/generalPractitionerListSelector";

export const useFetchGPListHistory = <T>(type: GpListHistoryTypes) => {
  const [history, setHistory] = useState<T[] | AuditLogItem[]>([]);
  const [loading, setLoading] = useState(false);

  const list = useGeneralPractitionerListSelector();
  useEffect(() => {
    if (!list.id) {
      return;
    }

    setLoading(true);
    officialFamilyPhysicianList(undefined, getBaseUrl(), API)
      .getAuditLogItems(list.id, type, {
        withCredentials: true
      })
      .then(({ data }) => setHistory(data))
      .finally(() => setLoading(false));
  }, [list, type]);

  return { data: history, loading };
};
