import React from "react";

import { BadgeName, FormattedBadge } from "./FormattedBadge";
import icon from "../../../assets/images/badges/success_check.svg";
import iconGhost from "../../../assets/images/badges/success_check_ghost.svg";

interface Props {
  id: string;
  message: BadgeName;
  isGhost?: boolean;
}

export const SuccessBadge = ({ id, message, isGhost }: Props) => (
  <FormattedBadge
    id={id}
    message={message}
    isGhost={isGhost}
    icon={icon}
    iconGhost={iconGhost}
    color="success"
  />
);
