import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ActiveFilters } from "../../Shared/Search/Filters/FilterTypes";
import { Search } from "../../Shared/Search/SearchApplication";
import { filterActions } from "../../Shared/Search/Filters/filterActions";
import { searchResultsActions } from "../../Shared/Search/searchResultsActions";
import { GeneralPractitionerListCreation } from "./GeneralPractitionerListCreation";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { RootState } from "../../../rootReducer";
import { OfficialUserPrivilegesEnum } from "../../../../api_client/medre_api";
import { ProtectedComponent } from "../../../Security/ProtectedComponent";

const GeneralPractitionerLists = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      searchResultsActions.setData([], ActiveFilters.GeneralPractitionerList)
    );
    dispatch(searchResultsActions.setRowCount(0));
    dispatch(searchResultsActions.setPageCount(0));
    dispatch(
      filterActions.setFilterType(ActiveFilters.GeneralPractitionerList)
    );
  }, [dispatch]);

  const { filters } = useSelector(
    (rootState: RootState) => rootState.searchResults
  );

  return (
    <Search
      title={
        <FormattedMessage
          id="GPListTableContainer.title"
          defaultMessage="Perearsti nimistud"
        />
      }
      buttons={(props) => (
        <div>
          <ProtectedComponent
            allowedRoles={[
              OfficialUserPrivilegesEnum.Proceede,
              OfficialUserPrivilegesEnum.Sign
            ]}
          >
            <PrimaryFormattedButton
              id="exportToCsv"
              onClick={props.handleExport}
              className="mr-3"
              type="button"
              disabled={Boolean(!filters)}
            />
            <GeneralPractitionerListCreation />
          </ProtectedComponent>
        </div>
      )}
      hideFilterSelector={true}
    />
  );
};

export default GeneralPractitionerLists;
