import React from "react";

import { SuccessBadge } from "../../../Component/Badge/SuccessBadge";
import { DangerBadge } from "../../../Component/Badge/DangerBadge";
import { ActivityLicenseRowHeaderStatusEnum } from "../../../../api_client/medre_api";
import { WarningBadge } from "../../../Component/Badge/WarningBadge";

interface Props {
  status?: ActivityLicenseRowHeaderStatusEnum;
  index?: number;
  isDetailed?: boolean;
  values?: Record<string, any>;
}

export const ActivityLicenseState = ({ status, index, values }: Props) => {
  switch (status) {
    case ActivityLicenseRowHeaderStatusEnum.Valid:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
    case ActivityLicenseRowHeaderStatusEnum.Paused:
      return values
        ? <WarningBadge id={`pausedUntil-${index}`} message="pausedUntil" values={values}/>
        : <WarningBadge id={`paused-${index}`} message="paused" />;
    case ActivityLicenseRowHeaderStatusEnum.Invalid:
      return <DangerBadge id={`invalid-${index}`} message="invalid" />;
    default:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
  }
};
