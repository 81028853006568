import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SingleSelect } from "../../../../Component/Select/SingleSelect";
import { CountrySelect } from "../../../../Component/Select/CountrySelect";
import { OccupationSpecialityFilter } from "./OccupationSpecialityFilter";
import { CountryFilters } from "./CountryFilter";
import {
  personStatuses,
  sexes,
  singleSelectProps
} from "../../../Shared/Search/Filters/FilterUtils";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData
} from "../../../Shared/Search/Filters/filterActions";
import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";

export const ApplicantFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    filterSelectors.selectApplicantFilters,
    shallowEqual
  );
  const set = React.useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setApplicantFilter({ key, data }));
    },
    [dispatch]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <FilterField
          id="applicantFiltersPersonStatus"
          label="THT olek"
          field={
            <SingleSelect
              inputId="applicantFiltersPersonStatus"
              options={personStatuses}
              handleOptionChange={(e) => set("personStatus", e)}
              value={state.personStatus}
              placeholder={
                <FormattedMessage
                  id="filters.selectApplicantStatus"
                  defaultMessage="Vali THT olek"
                />
              }
              {...singleSelectProps}
            />
          }
        />
        <FilterField
          id="applicantFiltersOccupationCode"
          label="THT kood"
          field={
            <Input
              id="applicantFiltersOccupationCode"
              value={state.occupationCode}
              onChange={(e) => set("occupationCode", e.target.value)}
              placeholder="THT kood"
            />
          }
        />
        <OccupationSpecialityFilter
          id="applicantFilters"
          occupationValue={state.occupations!}
          onOccupationChange={(e) => set("occupations", e)}
          specialityKey="specialities"
          specialityValue={state.specialities || []}
          onSpecialityChange={(e) => set("specialities", e)}
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="applicantFiltersOccupationName"
          label="Nimi"
          field={
            <Input
              id="applicantFiltersOccupationName"
              value={state.name}
              onChange={(e) => set("name", e.target.value)}
              placeholder="Nimi"
            />
          }
        />
        <FilterField
          id="applicantFiltersIdCodeCountry"
          label="Isikukoodi riik"
          field={
            <CountrySelect
              inputId="applicantFiltersIdCodeCountry"
              handleOptionChange={(e) => set("idCodeCountry", e)}
              value={state.idCodeCountry}
              placeholder={
                <FormattedMessage
                  id="filters.selectIdCodeCountry"
                  defaultMessage="Vali isikukoodi riik"
                />
              }
              {...singleSelectProps}
              withNotSpecified
            />
          }
        />
        <FilterField
          id="applicantFiltersIdCode"
          label="Isikukood"
          field={
            <Input
              id="applicantFiltersIdCode"
              value={state.idCode}
              onChange={(e) => set("idCode", e.target.value)}
              placeholder="Isikukood"
            />
          }
        />
        <FilterField
          id="applicantFiltersDateOfBirth"
          label="Sünniaeg"
          field={
            <DatePickerComponent
              id="applicantFiltersDateOfBirth"
              onDateChange={(e: Date) => set("dateOfBirth", e)}
              selectedDate={state.dateOfBirth}
              placeholder="pp.kk.aaaa"
            />
          }
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="applicantFiltersGender"
          label="Sugu"
          field={
            <SingleSelect
              inputId="applicantFiltersGender"
              options={sexes}
              handleOptionChange={(e) => set("sex", e)}
              value={state.sex}
              placeholder={
                <FormattedMessage
                  id="filters.selectSex"
                  defaultMessage="Vali sugu"
                />
              }
              {...singleSelectProps}
            />
          }
        />
        <FilterField
          id="applicantFiltersCitizenship"
          label="Kodakondsus"
          field={
            <CountrySelect
              inputId="applicantFiltersCitizenship"
              handleOptionChange={(e) => set("citizenship", e)}
              value={state.citizenship}
              placeholder={
                <FormattedMessage
                  id="filters.citizenship"
                  defaultMessage="Vali kodakondsus"
                />
              }
              {...singleSelectProps}
              withNotSpecified
            />
          }
        />
        <CountryFilters
          countryValue={state.countries || []}
          countryGroupValue={state.countryGroup}
          onCountryChange={(e) => set("countries", e)}
          onCountryGroupChange={(e) => set("countryGroup", e)}
        />
      </FilterColumn>
    </FilterContainer>
  );
};
