import React, {useCallback, useContext, useMemo, useState} from "react";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import {
  ApplicantCertificatesModal,
  CertificateData,
  defaultCertificateValues
} from "./ApplicantCertificatesModal";
import { ApplicantCertificatesTableRow } from "./ApplicantCertificatesTableRow";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import {
  PersonCertificate,
  Person,
  OfficialUserPrivilegesEnum
} from "../../../../../api_client/medre_api";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";
import {ApplicationCertificateContext} from "../../../Shared/ApplicationCertificate/ApplicationCertificateContext";

interface Props {
  person: Person;
  certificates: PersonCertificate[];
  afterCertificateUpdate: () => void;
  allowAddNewCertificate?: boolean;
}

export const ApplicantCertificates = ({
  person,
  certificates,
  afterCertificateUpdate,
  allowAddNewCertificate = true
}: Props): React.JSX.Element => {
  const { allowedHealthcareOccupationCodes } = useContext(
    ApplicationCertificateContext
  );

  const [certificateModalData, setCertificateModalData] = useState<
  CertificateData | PersonCertificate | undefined
  >(undefined);

  const handleOpenCertificateModal = useCallback(
    (certificate) => (event?: React.SyntheticEvent): void => {
      event?.preventDefault();
      setCertificateModalData({
        ...certificate,
        personFullName: `${person.firstName} ${person.lastName}`
      });
    },
    [setCertificateModalData, person]
  );
  const handleCloseCertificateModal = useCallback(() => {
    setCertificateModalData(undefined);
  }, [setCertificateModalData]);

  const hasHealthcareProfessionalOccupation = (): boolean => {
    return allowedHealthcareOccupationCodes.length > 0;
  };

  const button = useMemo(
    () => (
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <PrimaryFormattedButton
          id="newCertificate"
          onClick={handleOpenCertificateModal(defaultCertificateValues)}
          disabled={!hasHealthcareProfessionalOccupation()}
        />
      </ProtectedFieldWrapper>
    ),
    [handleOpenCertificateModal, hasHealthcareProfessionalOccupation]
  );

  return (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.Certificates}
      button={allowAddNewCertificate ? button : null}
    >
      <div className="search-application mb-5">
        <div className="table-wrap">
          <table
            className="s-table user-applications-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                {/* Occupation */}
                <th className="normal-column text-wrap">Kutse</th>
                {/* Specialty */}
                <th className="normal-column text-wrap">Eriala</th>
                {/* Country of destination */}
                <th className="normal-column text-wrap">Sihtriik</th>
                {/* The country of education */}
                <th className="normal-column text-wrap">
                  Hariduse omandamise riik
                </th>
                {/* Issued - date */}
                <th className="normal-column text-wrap">Väljastatud</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {certificates.map((certificate) => (
                <ApplicantCertificatesTableRow
                  key={certificate.id}
                  person={person}
                  certificate={certificate}
                  onOpenCertificateModal={handleOpenCertificateModal}
                  afterDelete={afterCertificateUpdate}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {Boolean(certificateModalData) && (
        <ApplicantCertificatesModal
          isOpen
          onClose={handleCloseCertificateModal}
          defaultValues={certificateModalData}
          person={person}
          afterSubmit={afterCertificateUpdate}
        />
      )}
    </ApplicantDetailsSection>
  );
};
