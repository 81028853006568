import React, { FC, useState } from "react";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";

import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { dateToString } from "../../../../Util/DateUtils";
import { GeneralPractitionerList } from "../../../../../api_client/medre_api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";

interface Props {
  onClose: () => void;
}

const AddActivationDate: FC<Props> = ({ onClose }) => {
  const [activationDate, setActivationDate] = useState<Date>();
  const { loading, updateGPList } = useGeneralPractitionerList();

  const handleSubmit = () => {
    updateGPList(
      {
        activationDate: dateToString(activationDate)
      } as GeneralPractitionerList,
      onClose
    );
  };

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      <ModalHeader>
        <FormattedMessage
          id="GPListAddActivationDateModal.header"
          defaultMessage="Nimistu aktiveerimine"
        />
      </ModalHeader>
      <ModalBody>
        <div className="p-2 mt-2">
          <FormGroup row={true}>
            <Label sm={5} htmlFor="gpListAddActivationDateModalActivationDate">
              Aktiveerimise kuupäev
            </Label>
            <Col sm={7}>
              <DatePickerComponent
                id="gpListAddActivationDateModalActivationDate"
                onDateChange={setActivationDate}
                selectedDate={activationDate}
                placeholder="pp.kk.aaaa"
                disablePast={true}
              />
            </Col>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton
          id="cancel"
          onClick={onClose}
          disabled={loading}
        />
        <PrimaryFormattedButton
          id="activateGeneralPractitionerList"
          onClick={handleSubmit}
          disabled={loading || !activationDate}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddActivationDate;
