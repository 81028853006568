import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGeneralPractitionerLists from "./useGeneralPractitionerLists";
import { FormattedMessage } from "react-intl";
import {
  GeneralPractitionerListDoctor,
  getGPDoctorLabel
} from "../../Shared/GeneralPractitioners/GeneralPractitionerListDoctor";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { SingleSelect } from "../../../Component/Select/SingleSelect";
import { Option } from "../../../Component/Select/SelectTypes";
import { GeneralPractitionerList } from "../../../../api_client/medre_api";
import { GeneralPractitioner } from "../../../../api_client/medre_api";

const hasGeneralPractitioner = (generalPractitioner: GeneralPractitioner) =>
  !!generalPractitioner.id;

const getOptionLabel = (list: GeneralPractitionerList) => {
  if (!!list.generalPractitioner?.id) {
    return getGPDoctorLabel(list.generalPractitioner, list.code);
  }

  return (
    <FormattedMessage
      id="PortalGPListManagement.DoctorList"
      defaultMessage="{code} - Perearstita nimistu"
      values={{ code: list.code }}
    />
  );
};

const PortalGpListManagement = () => {
  const { loading, list } = useGeneralPractitionerLists();
  const [currentId, setCurrentId] = useState("");

  const options = useMemo(
    () =>
      list.map((item) => ({
        value: item.id,
        label: getOptionLabel(item)
      })),
    [list]
  );

  const handleOptionChange = useCallback((option: Option) => {
    setCurrentId(option.value);
  }, []);

  useEffect(() => {
    setCurrentId(options[0]?.value);
  }, [options]);

  if (loading || options.length === 0) {
    return null;
  }

  const isSingle = options.length === 1;
  const [{ generalPractitioner, substitute, code }] = list;
  return (
    <div className="mt-4 p-3">
      <h2 className="fw-300">
        {isSingle ? (
          <>
            <FormattedMessage
              id="portalGpListManagement.singleHeader"
              defaultMessage="Perearsti nimistu"
            />{" "}
            <GeneralPractitionerListDoctor
              code={code}
              isSubstitute={
                !hasGeneralPractitioner(
                  generalPractitioner as GeneralPractitioner
                )
              }
              data={
                hasGeneralPractitioner(
                  generalPractitioner as GeneralPractitioner
                )
                  ? generalPractitioner
                  : substitute
              }
            />
          </>
        ) : (
          <FormattedMessage
            id="portalGpListManagement.mainHeader"
            defaultMessage="Perearsti nimistud"
          />
        )}
      </h2>
      <div className="d-flex align-items-center pt-3">
        {isSingle ? null : (
          <>
            <span className="mr-3">
              <FormattedMessage
                id="portalGpListManagement.selectPrefix"
                defaultMessage="Vali nimistu"
              />
            </span>
            <SingleSelect
              className="w-50 mr-3"
              options={options}
              value={currentId}
              handleOptionChange={handleOptionChange}
            />
          </>
        )}
        <Button
          to={`/general-practitioner-lists/${currentId}`}
          tag={Link}
          color="primary"
        >
          <FormattedMessage
            id="portalGpListManagement.button"
            defaultMessage="Halda nimistut"
          />
        </Button>
      </div>
    </div>
  );
};

export default PortalGpListManagement;
