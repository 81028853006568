import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { ServicesList } from "./Services/ServicesList";
import { convertServiceToActivityLicenseService } from "../../../Dto/ActivityLicense/ActivityLicense";
import { getBaseUrl, API } from "../../../api";
import { RootState } from "../../../rootReducer";
import { AddNewServiceModal } from "./Services/AddNewServiceModal";
import { activityLicenseApplicationActions } from "../../../ActivityLicense/activityLicenseApplicationActions";
import { isServiceValid, isSpecialistBusinessAreaServiceValid } from "./ActivityLicenseServiceValidator";
import {
  Service,
  ServiceStatusEnum,
  ActivityLicenseService,
  TegevuslubadeTeenusApiFactory as activityPermitServiceAPI,
  ActivityLicenseApplication, ActivityLicenseBusinessAreaBusinessAreaTypeEnum, ActivityLocation
} from "../../../../api_client/medre_api";
import { MIDWIFERY_CARE_BUSINESS_AREA_ID, NURSING_CARE_BUSINESS_AREA_ID } from "../../../Util/ActivityLicenseUtils";
import {Loader} from "../../../Component/Loader/Loader";

interface Props {
  locationInfoIndex: number;
  services: ActivityLicenseService[];
  isUpdateActivityLicenseService?: boolean;
  location?: ActivityLocation;
}

export const ActivityLicenseServices = ({
  locationInfoIndex,
  services,
  isUpdateActivityLicenseService,
  location
}: Props) => {
  const dispatch = useDispatch();
  const businessArea = useSelector(
    (state: RootState) => state.activityLicenseApplication.businessArea
  );
  const saveAllowed = useSelector(
    (state: RootState) => state.activityLicenseApplication.saveAllowed
  );
  const [baOptionalServices, setBaOptionalServices] = useState<Service[]>([]);
  const [baRequiredServices, setBaRequiredServices] = useState<Service[]>([]);
  const [availableServices, setAvailableServices] = useState<Service[]>([]);
  const [isAddNewServiceModalOpen, setIsAddNewServiceModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSpecialistActivityLicense: boolean = useSelector((state: RootState) => {
    return (
      state.activityLicenseApplication.businessArea?.businessAreaType ===
        ActivityLicenseBusinessAreaBusinessAreaTypeEnum.Specialist
    );
  });

  const getBusinessAreaId = () => {
    if (
      businessArea?.status === ServiceStatusEnum.Inactive &&
      businessArea.actualBusinessArea
    ) {
      return businessArea.actualBusinessArea.id;
    }
    return businessArea?.id;
  };

  useEffect(() => {
    setIsLoading(true);

    activityPermitServiceAPI(undefined, getBaseUrl(), API)
      .getBusinessAreaRequirements(getBusinessAreaId() as string, {
        withCredentials: true
      })
      .then((res) => {
        setBaOptionalServices(res.data.optionalServices);
        setBaRequiredServices(res.data.requiredServices);
        if (!services || !services.length) {
          const requiredServices: Service[] = res.data.requiredServices;
          const requiredActivityLicenseServices = requiredServices.map(
            (service) => convertServiceToActivityLicenseService(service, true)
          );
          dispatch(
            activityLicenseApplicationActions.updateLocationInfo(
              locationInfoIndex,
              "services",
              requiredActivityLicenseServices
            )
          );
        }
      }).finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessArea, dispatch]);

  useEffect(() => {
    const serviceIds = services?.map((s) => s.service?.id);
    setAvailableServices(
      baOptionalServices.filter((os) => !serviceIds?.includes(os.id))
    );
  }, [services, baOptionalServices]);

  useEffect(() => {
    if (!services) {
      return;
    }
    let isValid = !!services.length;
    const serviceCodes = services
      .filter((service) => service.service?.code)
      .map((service) => service.service?.code!);
    for (let service of services) {
      if (!isSpecialistActivityLicense ? !isServiceValid(service, serviceCodes) : !isSpecialistBusinessAreaServiceValid(service, serviceCodes, location)) {
        isValid = false;
        break;
      }
    }

    if (saveAllowed !== isValid) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          isValid
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, dispatch]);

  const removeService = (serviceId?: string) => {
    const serviceToRemove = services?.find((s) => s.service?.id === serviceId);
    const requiredServiceIds = serviceToRemove?.service?.requiredServices?.map(
      (rs: any) => rs.id
    );
    const requiredVirtualServiceIds = services
      ?.filter((s) => s.service?.id !== serviceId)
      .map((s) => s.service?.requiredServices)
      .flat()
      .filter((rs) => rs?.status === ServiceStatusEnum.Virtual)
      .map((rs) => rs?.id);

    const requiredServicesToRemove = requiredServiceIds?.filter(
      (id: any) => !requiredVirtualServiceIds?.includes(id)
    );

    dispatch(
      activityLicenseApplicationActions.removeActivityLicenseService(
        locationInfoIndex,
        serviceId
      )
    );
    if (requiredServicesToRemove) {
      for (let s of requiredServicesToRemove) {
        dispatch(
          activityLicenseApplicationActions.removeActivityLicenseService(
            locationInfoIndex,
            s
          )
        );
      }
    }
  };

  const handleNewService = (service: Service) => {
    dispatch(
      activityLicenseApplicationActions.updateLocationInfo(
        locationInfoIndex,
        "services",
        [
          ...(services || []),
          convertServiceToActivityLicenseService(service, false)
        ]
      )
    );
    setIsAddNewServiceModalOpen(false);
  };

  const toggleAddNewServiceModal = () => {
    setIsAddNewServiceModalOpen(!isAddNewServiceModalOpen);
  };

  const renderAddNewServiceButton = () => {
    return (
      ((availableServices &&
        !!availableServices.length &&
        businessArea?.id !== NURSING_CARE_BUSINESS_AREA_ID &&
        businessArea?.id !== MIDWIFERY_CARE_BUSINESS_AREA_ID) ||
        ((businessArea?.id === NURSING_CARE_BUSINESS_AREA_ID ||
          businessArea?.id === MIDWIFERY_CARE_BUSINESS_AREA_ID) && !services.length)) && (
            <PrimaryFormattedButton
              className="mt-4"
              id="addService"
              onClick={toggleAddNewServiceModal}
            />
      )
    );
  };

  const contentTextMessage = () => {
    return isUpdateActivityLicenseService ? updateActivityLicenseServiceTextMessage() : defaultTextMessage();
  };

  const updateActivityLicenseServiceTextMessage = () => {
    return (
      <FormattedMessage
        id="activityLicense.updateServicesInfo"
        defaultMessage="Muuda teenuseid ja teenuste andmeid ning klõpsa “Salvesta muudatused”."
      />
    );
  };

  const defaultTextMessage = () => {
    return (
      <FormattedMessage
        id="activityLicense.chooseServicesInfo"
        defaultMessage="Vali tegevusloa teenused, lisa ruumid ja töötajad ning klõpsa “Edasi”."
      />
    );
  };

  return (
    <div className="main-column">
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.services"
          defaultMessage="Tegevusloa teenused"
        />
      </h3>
      <p>
        {contentTextMessage()}
      </p>
      { isLoading ? <Loader absolute backdrop /> : <ServicesList
        locationInfoIndex={locationInfoIndex}
        services={services}
        addService={handleNewService}
        removeService={removeService}
        baOptionalServices={baOptionalServices}
        baRequiredServices={baRequiredServices}
      />}
      {renderAddNewServiceButton()}
      <AddNewServiceModal
        isOpen={isAddNewServiceModalOpen}
        services={availableServices}
        onClose={toggleAddNewServiceModal}
        onSave={handleNewService}
      />
    </div>
  );
};
