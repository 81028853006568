import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../api";
import "./RoleSelection.scss";
import { RoleCard } from "./RoleCard";
import { RootState } from "../../../rootReducer";
import { Loader } from "../../../Component/Loader/Loader";
import { CompanySelection } from "./CompanySelection";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import {
  BusinessInfo,
  FirmadeAndmeteTeenusApiFactory as compantDataAPI
} from "../../../../api_client/medre_api";
import { selectPersonRole } from "./RoleHelpers";

export const RoleSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { firstName, lastName, idCode, status } = useSelector(
    (state: RootState) => state.person
  );
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<BusinessInfo[]>();

  useEffect(() => {
    let isMounted = true;
    compantDataAPI(undefined, getBaseUrl(), API)
      .getUserCompanies({
        withCredentials: true
      })
      .then((result) => {
        if (isMounted) {
          if (result.data?.length) {
            setCompanies(result.data);
          } else {
            enterAsPerson();
          }
        }
      })
      .catch(() => {
        const alertMessage = <AlertMessage id="representationQueryFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        enterAsPerson();
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const enterAsPerson = () => {
    selectPersonRole(status, dispatch);
    history.push("/");
  };

  return (
    <div className="role-selection-wrapper">
      {loading ? (
        <Loader backdrop={true} />
      ) : (
        <>
          <h1>
            <FormattedMessage
              id="roleSelection.chooseRole"
              defaultMessage="Vali enda roll"
            />
          </h1>
          <RoleCard
            name={firstName + " " + lastName}
            code={idCode!}
            onClick={enterAsPerson}
          />
          <CompanySelection companies={companies} />
        </>
      )}
    </div>
  );
};
