import React from "react";
import "../../Header/Header.scss";
import { Col, Row } from "reactstrap";
import { Logo } from "../../Header/Logo";

interface Props {
    headerColor: string;
}

export const OfficialLoginHeader = ({
    headerColor
}: Props) => {
    return (
        <div className={"main-header " + headerColor}>
            <Row className="m-0 align-items-center h-100">
                <Col lg={4} md={12} className="ml-0 d-flex justify-content-lg-start justify-content-md-center">
                    <Logo isSideMenuCollapsed={true} isOfficialLogin={true}/>
                </Col>
            </Row>
        </div>
    );
};
