import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Controller,
  useFormContext,
  ControllerRenderProps
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Col, FormGroup, Label } from "reactstrap";

import {
  SingleSelect,
  Option
} from "../../../../Component/Select/SingleSelect";
import {
  CheckboxGroup,
  Values as CheckboxGroupValues
} from "../../../../Component/Checkbox/CheckboxGroup";
import { ApplicationCertificateContext } from "../ApplicationCertificateContext";
import {
  OccupationCode
} from "../../../../../api_client/medre_api";

type OccupationKeys = Pick<OccupationCode, "id" | "occupationId">;

interface Props {
  occupationsKey?: "occupations" | "occupationCodes";
  occupationsValuesKey?: keyof OccupationKeys;
  specialityValueKey?: "id" | "specialityId";
}

export const OccupationsFormSpeciality = ({
  occupationsKey = "occupations",
  occupationsValuesKey = "id",
  specialityValueKey = "id"
}: Props) => {
  const {allowedSpecialities, allowedHealthcareOccupationCodes } = useContext(
    ApplicationCertificateContext
  );

  const { control, watch, setValue, trigger } = useFormContext();
  const { [occupationsKey]: occupationValues } = watch();
  // If the user has only one valid profession, it is preselected

  useEffect(() => {
    if (allowedHealthcareOccupationCodes.length === 1) {
      setValue(occupationsKey, [
        { [occupationsValuesKey]: allowedHealthcareOccupationCodes[0].occupationId }
      ]);
      trigger(occupationsKey);
    }
  }, [
    setValue,
    occupationsKey,
    occupationsValuesKey,
    trigger,
    allowedHealthcareOccupationCodes
  ]);

  const occupationOptions = useMemo(
    () =>
      allowedHealthcareOccupationCodes.map((o) => ({
        value: o.occupationId,
        label: o.name
      })),
    [allowedHealthcareOccupationCodes]
  );
  const specialityOptions = useMemo(() => {
    let selectedOccupations = occupationValues.map(
      (o: OccupationKeys) => o[occupationsValuesKey]
    );
    if (!selectedOccupations.length) {
      return [];
    }

    return allowedSpecialities
      .filter(({ occupationId }) => selectedOccupations.includes(occupationId))
      .map((s) => ({
        value: s.specialityId,
        label: s.name
      }));
  }, [occupationValues, occupationsValuesKey, allowedSpecialities]);

  const handleChangeOccupation = useCallback(
    (props) => (values: CheckboxGroupValues) => {
      props.onChange(
        values.map((value) => ({ [occupationsValuesKey]: value }))
      );
      setValue("speciality", null);
    },
    [setValue, occupationsValuesKey]
  );

  const handleChangeSpeciality = useCallback(
    (speciality: Option) => {
      setValue("speciality", { [specialityValueKey]: speciality.value });
    },
    [setValue, specialityValueKey]
  );
  const getSelectedSpecialityValue = useCallback(
    (speciality) => {
      if (speciality && allowedSpecialities.length) {
        const { code, name } = allowedSpecialities.find(
          (s) => s.specialityId === speciality[specialityValueKey]
        )!;
        return { value: code, label: name };
      }
      return undefined;
    },
    [allowedSpecialities, specialityValueKey]
  );

  const validateOccupations = useCallback((value) => Boolean(value.length), []);

  return (
    <>
      <FormGroup row className="required">
        <Label tag="span" sm={4} className="text-left text-sm-right">
          <FormattedMessage
            id="ApplicationCertificate.occupationField"
            defaultMessage="Kutse"
          />
        </Label>
        <Col sm={7} md={5} className="d-flex flex-wrap align-items-center">
          <Controller
            name={occupationsKey}
            control={control}
            rules={{ validate: validateOccupations }}
            render={(field: ControllerRenderProps<Record<string, any>>) => (
              <CheckboxGroup
                name={field.name}
                options={occupationOptions as Option[]}
                onChange={handleChangeOccupation(field)}
                value={field.value}
                valuesKey={occupationsValuesKey}
                inline
              />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label
          htmlFor="occupationsFormSpeciality"
          className="text-left text-sm-right"
          sm={4}
        >
          <FormattedMessage
            id="ApplicationCertificate.specialityField"
            defaultMessage="Eriala"
          />
        </Label>
        <Col sm={7} md={5} className="d-flex align-items-center">
          <Controller
            name="speciality"
            control={control}
            defaultValue={null}
            render={(field: ControllerRenderProps<Record<string, any>>) => (
              <SingleSelect
                inputId="occupationsFormSpeciality"
                className="w-100"
                options={specialityOptions as Option[]}
                disabled={!specialityOptions.length}
                isMulti={false}
                hideSearchIcon
                handleOptionChange={handleChangeSpeciality}
                value={getSelectedSpecialityValue(field.value)}
              />
            )}
          />
        </Col>
      </FormGroup>
    </>
  );
};
