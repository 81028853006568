import React from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row as TableRow,
  usePagination,
  useTable
} from "react-table";
import moment from "moment";

import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { SearchApplication } from "../../../Shared/Search/Filters/FilterTypes";
import { ApplicationState } from "../../../../Application/ApplicationState";
import { translateCountry } from "../../../../Component/Translate/CountryTranslator";
import "../../../Shared/Search/ResultsTable/ResultsTable.scss";
import { DetailedApplicationTypeEnum } from "../../../../../api_client/medre_api";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";

interface Props {
  data: Array<SearchApplication>;
  pageCount: number;
  pageIndex: number;
}

const COLUMNS: Column<SearchApplication>[] = [
  {
    Header: "Kuupäev",
    accessor: "arrivalTime",
    Cell: ({ cell }) => (
      <div className="date-column">
        {cell.row.values.arrivalTime
          ? moment(cell.row.values.arrivalTime).format("DD.MM.YYYY")
          : ""}
      </div>
    )
  },
  {
    Header: "Taotluse number",
    accessor: "applicationNumber"
  },
  {
    Header: "Eesnimi",
    accessor: "firstName"
  },
  {
    Header: "Perenimi",
    accessor: "lastName"
  },
  {
    Header: "Staatus",
    accessor: "currentStatus",
    Cell: ({ cell }) => (
      <div className="status-column">
        <ApplicationState
          application={{
            currentStatus: { status: cell.row.original.currentStatus },
            stateFee: { paid: cell.row.original.stateFeePaid }
          }}
        />
      </div>
    )
  },
  {
    Header: "Kutse nimi",
    accessor: "occupationName",
    Cell: ({ cell }) => {
      const { occupationName, additionalOccupationName } = cell.row.values;
      return occupationName
        ? `${occupationName}  ${
            additionalOccupationName
              ? "ja " + additionalOccupationName.toLowerCase()
              : ""
          }`
        : "";
    }
  },
  {
    Header: "Eriala nimi",
    accessor: "specialityName"
  },
  {
    Header: "Kutse lisa",
    accessor: "additionalOccupationName",
    id: "additionalOccupationName"
  },
  {
    Header: "Hariduse riik",
    accessor: "countryCode",
    Cell: ({ cell }) => translateCountry(cell.row.values.countryCode)
  },
  {
    Header: "",
    accessor: "id",
    Cell: ({ cell }) => {
      let link = "/applications/";
      if (["SAVED", "REGISTERING"].includes(cell.row.values.currentStatus)) {
        link = "/applications/drafts/";
      }
      if (
        cell.row.original.type ===
        DetailedApplicationTypeEnum.ApplicationCertificate
      ) {
        link = "/applications/certificate/";
      }
      return (
        <div className="link-column">
          <DetailViewLinkButton to={`${link}${cell.row.values.id}`} />
        </div>
      );
    }
  }
];

export const ApplicationResults = ({ data, pageCount, pageIndex }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: {
        pageIndex: pageIndex,
        hiddenColumns: ["additionalOccupationName", "stateFeePaid"]
      },
      manualPagination: true,
      pageCount
    },
    usePagination
  );
  let stickyColumn1 = 0;
  let stickyColumn2 = 8;
  let normalColumnClass = "normal-column";
  let stickyFirstColumnClass = "sticky-first-column";
  let stickyLastColumnClass = "sticky-last-column";
  let columnClass = "";
  return (
    <div className="table-wrap">
      <div className="table-scroll">
        <table {...getTableProps()} className="s-table">
          <thead>
            {headerGroups.map(
              (headerGroup: HeaderGroup<SearchApplication>, i: number) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: ColumnInstance<SearchApplication>, j: number) => {
                      if (j === stickyColumn1) {
                        columnClass = stickyFirstColumnClass;
                      } else if (j === stickyColumn2) {
                        columnClass = stickyLastColumnClass;
                      } else {
                        columnClass = normalColumnClass;
                      }
                      return (
                        <th
                          className={columnClass}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    }
                  )}
                </tr>
              )
            )}
          </thead>
          <tbody {...getTableBodyProps()} className="table-result">
            {page.map((row: TableRow<SearchApplication>, i: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell<SearchApplication>, j: number) => {
                    if (j === stickyColumn1) {
                      columnClass = stickyFirstColumnClass;
                    } else if (j === stickyColumn2) {
                      columnClass = stickyLastColumnClass;
                    } else {
                      columnClass = normalColumnClass;
                    }
                    return (
                      <td className={columnClass} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
