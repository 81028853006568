import React, {useState} from "react";
import {PrimaryFormattedButton} from "../../../Component/Button/PrimaryFormattedButton";
import {useDispatch} from "react-redux";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {DatePickerComponent} from "../../../Component/DatePicker/DatePickerComponent";
import {AdminTeenusApiFactory as adminTeenusApi} from "../../../../api_client/medre_api";
import {API, getBaseUrl} from "../../../api";
import {displayAlert} from "../../../Util/AlertUtil";
import {AlertType} from "../../../Dto/Alert/AlertItem";
import {Loader} from "../../../Component/Loader/Loader";

export const Torta2CheckRequestForm = () => {
  const dispatch = useDispatch();
  const [registryCodes, setRegistryCodes] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [downloadId, setDownloadId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseCode, setResponseCode] = useState<string>();
  
  const handleRegistryCodesChange = (e: React.ChangeEvent<HTMLInputElement>) => setRegistryCodes(e.target.value.split(','));
  const handleStartDateChange = (date: Date) => setStartDate(date);
  const handleEndDateChange = (date: Date) => setEndDate(date);
  
  const runQuery = () => {
    if (startDate === undefined || endDate === undefined) {
      displayAlert("requiredFieldsEmpty", AlertType.Danger, dispatch);
      return;
    }
    
    setIsLoading(true);
    adminTeenusApi(undefined, getBaseUrl(), API)
      .torta2check({
        registryCodes: registryCodes,
        startTime: startDate?.toISOString()!,
        endTime: endDate?.toISOString()!
      }, {withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        setResponseCode(res.data.responseCode);
        if (res.data.responseCode === "SUCCESS") {
          setDownloadId(res.data.responseBody?.downloadRequestId);
        }
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  };
  
  return (
    <>
      <h4>
        TORTA2 päringute käivitamine
      </h4>
      <Form className="system-administration-form">
        <Row>
          <Col md={7} lg={6} xl={5} className="ml-5">
            <FormGroup className="form-inline justify-content-end">
              <Label htmlFor="torta2QueryRequestRegistryCodes">
                Registrikoodid
              </Label>
              <Input
                rules={{required: true}}
                placeholder="Registrikoodid (komaga eraldatud list)"
                id="torta2QueryRequestRegistryCodes"
                name="registryCodes"
                className="w-75"
                onChange={(e) => handleRegistryCodesChange(e)}
              />
            </FormGroup>
            <FormGroup className="form-inline justify-content-end required">
              <Label htmlFor="torta2QueryRequestStartDate">
                Alguskuupäev
              </Label>
              <span className="w-75 dp-input">
                  <DatePickerComponent
                    id="torta2QueryRequestStartDate"
                    name="startDate"
                    placeholder="pp.kk.aaaa"
                    onDateChange={handleStartDateChange}
                    selectedDate={startDate}
                  />
                </span>
            </FormGroup>
            <FormGroup className="form-inline justify-content-end required">
              <Label htmlFor="torta2QueryRequestEndDate">
                Lõpukuupäev
              </Label>
              <span className="w-75 dp-input">
                  <DatePickerComponent
                    id="torta2QueryRequestEndDate"
                    name="endDate"
                    placeholder="pp.kk.aaaa"
                    onDateChange={handleEndDateChange}
                    selectedDate={endDate}
                  />
                </span>
            </FormGroup>
            <FormGroup>
              <div className="form-inline d-flex justify-content-end">
                <PrimaryFormattedButton id="runQuery" onClick={() => runQuery()}/>
              </div>
              {responseCode ? <div>Response code: <b>{responseCode}</b></div> : null}
              {downloadId ? <div>download_id: {downloadId}</div> : null}
            </FormGroup>
          </Col>
          <Col>
            {isLoading ? <Loader/> : null}
          </Col>
        </Row>
      </Form>
    </>
  );
}
