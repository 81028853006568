import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";

export const getBaseUrl = (): string => {
  return process.env.REACT_APP_IS_LOCAL === "true" && process.env.REACT_APP_COMMON_API_URL
    ? process.env.REACT_APP_COMMON_API_URL
    : `/api-common`;
};

export const getTokenExchangeUrl = () => {
  return !!process.env.REACT_APP_TOKEN_EXCHANGE_URL
    ? process.env.REACT_APP_TOKEN_EXCHANGE_URL
    : window.location.origin.concat("/api-common/public/auth/exchange");
};

export const API = axios.create({
  withCredentials: true
});

export const UNINTERCEPTED_API = axios.create({ baseURL: getBaseUrl() });
export const UNINTERCEPTED_API_WITH_CREDENTIALS = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true
});

export const setAxiosResponseInterceptor = (
  dispatch: Dispatch,
  clientId: string,
  setExpiredModal: (isExpired: boolean) => void
) => {
  API.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return UNINTERCEPTED_API_WITH_CREDENTIALS.post(
          `/public/auth/refresh?client_id=${clientId}`
        )
          .then((result) => {
            if (result.status === 200) {
              originalRequest.baseURL = originalRequest.url;
              originalRequest.url = "";
              return API.request(originalRequest);
            }
          })
          .catch((errorResponse) => {
            return Promise.reject(errorResponse);
          });
      } else if (originalRequest._retry) {
        setExpiredModal(true);
      }
      return Promise.reject(error);
    }
  );
};
