import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

type RepresentativeMessage = "VALID" | "SCHEDULED" | "EXPIRED";

export const RepresentativeMessage = ({ id, ...props }: Props) => {
  const messages: Record<RepresentativeMessage, LocaleMessage> = defineMessages(
    {
      VALID: {
        id: "representativeList.status.VALID",
        defaultMessage: "Kehtiv"
      },
      SCHEDULED: {
        id: "representativeList.status.SCHEDULED",
        defaultMessage: "Planeeritud"
      },
      EXPIRED: {
        id: "representativeList.status.EXPIRED",
        defaultMessage: "Aegunud"
      }
    }
  );
  return (
    <FormattedMessage {...messages[id as RepresentativeMessage]} {...props} />
  );
};
