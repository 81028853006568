import React, { ReactElement } from "react";
import { FormattedButton } from "../../../../Component/Button/FormattedButton";
import useModal from "../../../../Hook/useModal";

interface GPListModifyRowButtonProps {
  children: (modal: ReturnType<typeof useModal>) => ReactElement;
  index?: number;
}
export const GPListModifyRowButton = ({ index, children }: GPListModifyRowButtonProps) => {
  const modal = useModal();
  return (
    <>
      <FormattedButton
        className="p-0 mr-2"
        onClick={modal.handleOpen}
        id="changeGPCell"
        idPrefix={index}
        color="link"
      />
      {modal.isOpen && children(modal)}
    </>
  );
};
