import React, { FC } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ButtonName } from "../Button/FormattedButton";
import { SecondaryFormattedButton } from "../Button/SecondaryFormattedButton";
import { AlertItem } from "../../Dto/Alert/AlertItem";
import { PrimaryFormattedButton } from "../Button/PrimaryFormattedButton";

interface Props {
  modalId?: string;
  isOpen: boolean;
  title: React.ReactElement | string;
  closeButtonId?: ButtonName;
  saveButtonId?: ButtonName;
  onClose: () => void;
  onSave?: () => void;
  onAsyncSave?: () => Promise<void | {
    type: "ADD_ALERT";
    payload: AlertItem;
  }>;
  footerClassName?: string;
}

export const ConfirmationModal: FC<Props> = ({
  isOpen,
  title,
  children,
  closeButtonId = "back",
  saveButtonId = "saveDraft",
  onClose,
  onSave,
  onAsyncSave,
  modalId,
  footerClassName
}) => (
  <Modal isOpen={isOpen} id={modalId}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter className={footerClassName}>
      <SecondaryFormattedButton id={closeButtonId} onClick={onClose} />
      {onSave && <PrimaryFormattedButton id={saveButtonId} onClick={onSave} />}
      {onAsyncSave && !onSave && (
        <PrimaryFormattedButton id={saveButtonId} onAsyncClick={onAsyncSave} />
      )}
    </ModalFooter>
  </Modal>
);
