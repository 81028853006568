import {
  renderOverViewRow,
  renderOverViewRowWithoutMessage
} from "../../MainContainer/Shared/Application/OverView/ApplicationOverViewUtil";
import { renderFiles } from "../../MainContainer/Portal/Application/Table/Rows/ApplicationTableRowUtil";
import { groupBy } from "../../Util/ArrayUtil";
import { FileObjectType, FileReference } from "../../../api_client/medre_api";

export const fileObjectTypeToKey = (objectType: FileObjectType) => {
  switch (objectType) {
    case FileObjectType.ActivityLicenseMedicalTechnologyProject:
      return "medicalTechnologyProject";
    case FileObjectType.ActivityLicenseRightOfUseForRooms:
      return "rightOfUserForRooms";
    case FileObjectType.ActivityLicenseListOfDevices:
      return "listOfDevices";
    case FileObjectType.ActivityLicensePrimaryCarePhysicianInfo:
      return "primaryCarePhysicianInfo";
    case FileObjectType.ActivityLicenseSchoolNurseImmunisation:
      return "schoolNurseImmunisation";
    case FileObjectType.ActivityLicenseNurseTrainings:
      return "nurseTrainings";
    case FileObjectType.ActivityLicenseMidwifeHomeBirth:
      return "midwifeHomeBirth";
    case FileObjectType.ActivityLicenseOther:
      return "otherDocuments";
    case FileObjectType.ActivityLicensePersonnelAgreement:
      return "personnelAgreement";
    case FileObjectType.ActivityLicenseAmbulance:
      return "ambulance";
    case FileObjectType.LicenseAcquisitionOnSiteInspectionProtocol:
    case FileObjectType.ActivityLicenseOnSiteInspectionProtocol:
    case FileObjectType.LicenseRestorationOnSiteInspectionProtocol:
    case FileObjectType.LocationRestorationOnSiteInspectionProtocol:
    case FileObjectType.ServiceRestorationOnSiteInspectionProtocol:
      return "onSiteInspectionProtocol";
    case FileObjectType.LicenseAcquisitionEconomicActivityConfirmation:
      return "economicActivityConfirmation";
    case FileObjectType.LicenseAcquisitionPersonnelList:
      return "personnelList";
    case FileObjectType.LicenseAcquisitionHealthcareProvidingConfirmation:
      return "healthcareProvidingConfirmation";
    case FileObjectType.LicenseAcquisitionPreviousOwnerAgreement:
      return "previousOwnerAgreement";
    case FileObjectType.LicenseAcquisitionPropertyTransitionConfirmation:
      return "propertyTransitionConfirmation";
    case FileObjectType.ActivityLicenseFilledApplication:
      return "filledApplication";
    case FileObjectType.ActivityLicenseRequirementsTable:
      return "requirementsTable";
    case FileObjectType.ActivityLicenseEmployeeQualifications:
      return "employeeQualifications";
    case FileObjectType.ProceedingNameChangeDocument:
    case FileObjectType.ProceedingAdditionalDocument:
    case FileObjectType.ProceedingAcademicReport:
    case FileObjectType.ProceedingDiploma:
    case FileObjectType.PersonNameChangeDocument:
    case FileObjectType.PersonAdditionalDocument:
    case FileObjectType.PersonAcademicReport:
    case FileObjectType.PersonDiploma:
      return "proceedingDocuments";
    default:
      return "otherDocuments";
  }
};

export const renderDocumentRows = (documents: FileReference[]) => {
  const groupedDocuments = groupBy(documents, "fileObjectType");
  return Object.entries(groupedDocuments).map(([key, value]) =>
    renderFileRow(key as FileObjectType, value)
  );
};

const renderFileRow = (type: FileObjectType, documents?: FileReference[]) => {
  switch (type) {
    case FileObjectType.ActivityLicenseMedicalTechnologyProject:
      return renderOverViewRow(
        "files",
        "medicalTechnologyProject",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseRightOfUseForRooms:
      return renderOverViewRow(
        "files",
        "rightOfUserForRooms",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseListOfDevices:
      return renderOverViewRow(
        "files",
        "listOfDevices",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicensePrimaryCarePhysicianInfo:
      return renderOverViewRow(
        "files",
        "primaryCarePhysicianInfo",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseSchoolNurseImmunisation:
      return renderOverViewRow(
        "files",
        "schoolNurseImmunisation",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseNurseTrainings:
      return renderOverViewRow(
        "files",
        "nurseTrainings",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseMidwifeHomeBirth:
      return renderOverViewRow(
        "files",
        "midwifeHomeBirth",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseOther:
      return renderOverViewRow(
        "files",
        "otherDocuments",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicensePersonnelAgreement:
      return renderOverViewRow(
        "files",
        "personnelAgreement",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseAmbulance:
      return renderOverViewRow("files", "ambulance", renderFiles(documents));
    case FileObjectType.ActivityLicenseOnSiteInspectionProtocol:
    case FileObjectType.LicenseAcquisitionOnSiteInspectionProtocol:
      return renderOverViewRow(
        "files",
        "onSiteInspectionProtocol",
        renderFiles(documents)
      );
    case FileObjectType.LicenseAcquisitionPersonnelList:
      return renderOverViewRow(
        "files",
        "personnelList",
        renderFiles(documents)
      );
    case FileObjectType.LicenseAcquisitionPropertyTransitionConfirmation:
      return renderOverViewRow(
        "files",
        "propertyTransitionConfirmation",
        renderFiles(documents)
      );
    case FileObjectType.LicenseAcquisitionPreviousOwnerAgreement:
      return renderOverViewRow(
        "files",
        "previousOwnerAgreement",
        renderFiles(documents)
      );
    case FileObjectType.LicenseAcquisitionEconomicActivityConfirmation:
      return renderOverViewRow(
        "files",
        "economicActivityConfirmation",
        renderFiles(documents)
      );
    case FileObjectType.LicenseAcquisitionHealthcareProvidingConfirmation:
      return renderOverViewRow(
        "files",
        "healthcareProvidingConfirmation",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseInvalidationDocument:
    case FileObjectType.ActivityLocationInvalidationDocument:
      return renderOverViewRow("files", "addedFiles", renderFiles(documents));
    case FileObjectType.ActivityLicenseFilledApplication:
      return renderOverViewRow(
        "files",
        "filledApplication",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseRequirementsTable:
      return renderOverViewRow(
        "files",
        "requirementsTable",
        renderFiles(documents)
      );
    case FileObjectType.ActivityLicenseEmployeeQualifications:
      return renderOverViewRow(
        "files",
        "employeeQualifications",
        renderFiles(documents)
      );
    case FileObjectType.PersonNameChangeDocument:
    case FileObjectType.PersonAdditionalDocument:
    case FileObjectType.PersonAcademicReport:
    case FileObjectType.PersonDiploma:
      return renderOverViewRowWithoutMessage(
          renderFiles(documents)
      );
    default:
      break;
  }
};

export const fileToRef = (file: File, fileType: FileObjectType) => {
  return {
    file,
    fileObjectType: fileType,
    fileName: file.name
  };
};

export interface FileReferenceDto extends FileReference {
  file?: File;
}
