import { CompanyStatusEnum } from "../../../api_client/medre_api";

export const prettifyBusinessStatus = (status?: CompanyStatusEnum): string => {
  switch (status) {
    case CompanyStatusEnum.NotFound:
      return "Ei leitud";
    case CompanyStatusEnum.Deleted:
      return "Kustutatud";
    case CompanyStatusEnum.Ok:
      return "Registris";
    case CompanyStatusEnum.StatusNotOk:
    default:
      return "-";
  }
};

export const prettifyRoleName = (roleName: string) => {
  switch (roleName) {
    case "AJPH":
      return "Ajutine pankrotihaldur likvideerija ülesannetes";
    case "ASES":
      return "Asutuse esindusõiguslik isik";
    case "ERIH":
      return "Erirežiimihaldur";
    case "ESIS":
      return "Esindama õigustatud isik";
    case "ETTEV":
      return "ettevõtja";
    case "EUSOS":
      return "Esindama volitatud usaldusosanik";
    case "EUSOS2":
      return "Esindama volitatud usaldusosanik";
    case "FIE":
      return "Füüsilisest isikust ettevõtja";
    case "HNKL":
      return "Haldusnõukogu liige";
    case "JPNKR":
      return "Järelevalveõiguslik pankrotihaldur";
    case "JUHA":
      return "juhatuse ainuliige";
    case "JUHE":
      return "juhatuse esimees";
    case "JUHJ":
      return "juhatuse liige (juhataja)";
    case "JUHL":
      return "Juhatuse liige";
    case "LIKV":
      return "Likvideerija";
    case "LIKVJ":
      return "Juhatuse liikmest likvideerija";
    case "MORAH":
      return "Moratooriumihaldur";
    case "PANKR":
      return "Pankrotihaldur";
    case "TOSAN":
      return "Täisosanik";
    case "VALIT":
      return "Valitseja";
    case "VFILJ":
      return "Filiaali juhataja";
    default:
      return "";
  }
};
