import { RootAction } from "../rootReducer";
import {
  RESET_EXPANDED_ROWS,
  SET_EXPANDED_ACTIVITY_LICENSE_ROW,
  SET_EXPANDED_LOCATION_ROW,
  SET_EXPANDED_LOCATIONS_ROW,
  SET_SERVICES_TO_DISCARD
} from "./activityLicenseTableActions";

export type ActivityLicenseTableState = {
  expandedActivityLicenseRow: number | null;
  expandedLocationsRow: number | null;
  expandedLocationRow: number | null;
  servicesToDiscard: string[];
};

const initial = {
  expandedActivityLicenseRow: null,
  expandedLocationsRow: null,
  expandedLocationRow: null,
  servicesToDiscard: [],
};

export const activityLicenseTableReducer = (state: ActivityLicenseTableState = initial,
                                            action: RootAction) => {
  switch (action.type) {
    case SET_EXPANDED_ACTIVITY_LICENSE_ROW:
      return {
        ...state,
        expandedActivityLicenseRow: action.payload
      };
    case SET_EXPANDED_LOCATIONS_ROW:
      return {
        ...state,
        expandedLocationsRow: action.payload
      };
    case SET_EXPANDED_LOCATION_ROW:
      return {
        ...state,
        expandedLocationRow: action.payload
      };
    case SET_SERVICES_TO_DISCARD:
      return {
        ...state,
        servicesToDiscard: action.payload
      };
    case RESET_EXPANDED_ROWS:
      return initial;
    default:
      return state;
  }
};