import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import arrowBack from "../../../assets/images/arrow_back_blue.svg";
import { LinkButton } from "./LinkButton";
import "./Button.scss";

export const BackButton = () => {
  const history = useHistory();
  const { key } = useLocation();

  const handleHistoryBack = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      history.goBack();
    },
    [history]
  );

  if (!key) {
    return null;
  }

  return (
    <button className="btn-unstyled" onClick={handleHistoryBack}>
      <img src={arrowBack} alt="back" />
      <LinkButton id="goToBack" tag="span" />
    </button>
  );
};
