import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  cloneElement
} from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  ControllerRenderProps
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Col, FormGroup, Label, Fade } from "reactstrap";
import classNames from "classnames";

import { Option } from "../../../../Component/Select/SingleSelect";
import {
  CountriesContext,
  CountriesSelectProvider,
  CountrySelect
} from "../../../../Component/Select/CountrySelect";
import { createCountryOption } from "../../../../Util/OptionUtil";
import { FieldArray } from "../../../../Component/HookForm/FieldArray";
import { Country } from "../../../../../api_client/medre_api";

interface Props {
  label?: JSX.Element | string;
  name: string;
  required?: boolean;
  excludedCountries?: string[];
}

export const defaultAppendCountryValue = { code: "" };

export const OccupationsFormCountries = ({ label, name, required, excludedCountries }: Props) => {
  const { watch, control } = useFormContext();

  const countryValues: Country[] = watch()[name] ?? [];
  const countries = useContext(CountriesContext);
  const fieldArray = useFieldArray({
    control,
    name: name,
    keyName: "_id"
  });
  const { fields: countryFields } = fieldArray;

  const filteredCountries = useMemo(
    () =>
      excludedCountries
        ? countries.filter(
            (country) => !excludedCountries.includes(country.value)
          )
        : countries,
    [excludedCountries, countries]
  );
  
  const usedCountryCodes = countryValues.map((country) => country.code);
  const countryOptions = useMemo(() => {
    return countryFields.reduce((acc: Option[][], field, index: number) => {
      const value = usedCountryCodes[index];
      const currentUsedCountries = usedCountryCodes.filter(
        (code) => code !== value
      );
      acc[index] = filteredCountries.filter(
        (c) => !currentUsedCountries.includes(c.value)
      );
      return acc;
    }, []);
  }, [countryFields, usedCountryCodes, filteredCountries]);

  const handleChangeCountry = useCallback(
    (props) => (country: Option) => {
      props.onChange(country.value);
    },
    []
  );

  const validate = useCallback(
    (value) => (required ? Boolean(value) : true),
    [required]
  );

  return (
    <FormGroup
      row={true}
      className={classNames({
        required: required
      })}
    >
      <Label
        sm={4}
        className="text-left text-sm-right"
        htmlFor={`occupationsFormCountries_${name}_0_code`}
      >
        {label ?? (
          <FormattedMessage
            id="ApplicationCertificate.certificateCountryField"
            defaultMessage="Välisriik, kuhu tõend esitatakse"
          />
        )}
      </Label>
      <FieldArray
        array={fieldArray}
        appendValue={defaultAppendCountryValue}
        appendText={
          <FormattedMessage
            id="ApplicationCertificate.certificateCountryAddLink"
            defaultMessage="Lisa riik"
          />
        }
        render={(fields, buttons) => (
          <>
            {fields.map((field, index) => (
              <Fragment key={field._id}>
                {index !== 0 && <Col xs={12} className="pt-3" />}
                <Col
                  xs={{ size: 11 }}
                  sm={{ offset: index === 0 ? 0 : 4, size: 7 }}
                  md={5}
                  className="d-flex align-items-center"
                >
                  <Controller
                    name={`${name}[${index}].code`}
                    control={control}
                    defaultValue={field.code}
                    rules={{ validate }}
                    render={(
                      field: ControllerRenderProps<Record<string, any>>
                    ) => (
                      <CountriesSelectProvider value={countryOptions[index]}>
                        <CountrySelect
                          inputId={`occupationsFormCountries_${name}_0_code`}
                          className="w-100"
                          value={createCountryOption(field.value)}
                          handleOptionChange={handleChangeCountry(field)}
                        />
                      </CountriesSelectProvider>
                    )}
                  />
                </Col>
                {index !== 0 && (
                  <>
                    <Col
                      xs={1}
                      md="auto"
                      className="d-flex align-items-center p-0"
                    >
                      <Fade in={true} className="d-block d-md-none">
                        {cloneElement(buttons.removeButton(index), {
                          hideText: true
                        })}
                      </Fade>
                      <Fade in={true} className="d-none d-md-block">
                        {buttons.removeButton(index)}
                      </Fade>
                    </Col>
                  </>
                )}
                {index === fields.length - 1 && index < 2 && (
                  <>
                    <Col xs={12} className="pt-3" />
                    <Col
                      sm={{ size: 8, offset: 4 }}
                      className="d-flex align-items-center mt-n3"
                    >
                      {buttons.appendButton}
                    </Col>
                  </>
                )}
              </Fragment>
            ))}
          </>
        )}
      />
    </FormGroup>
  );
};
