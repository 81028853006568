import React, { Fragment, ReactElement } from "react";

import "./Card.scss";
import { isSpecialistBusinessAreaId } from "../../Dto/ActivityLicense/ActivityLicense";

export type InfoCardContent = {
  key: string | JSX.Element;
  keyClassName?: string;
  value: string | number | JSX.Element | JSX.Element[] | undefined;
  valueClassName?: string;
  hidden?: boolean;
  isMobileLocation?: boolean;
  deliveryAddress?: string;
};

interface Props {
  title?: string | JSX.Element;
  content: InfoCardContent[];
  businessAreaId?: string;
}

export const ApplicationDetailsInfoCard = ({ title, content, businessAreaId }: Props) => {
  const getMobileLocationDescription = (): string =>
    !businessAreaId || isSpecialistBusinessAreaId(businessAreaId) ?
      "Mobiilne asukoht (Eesti Vabariik)" :
      "Teenuse osutamine patsiendi igapäevases keskkonnas";

  const renderContentRow = (detail: InfoCardContent, index: number): null | ReactElement =>
    detail.hidden ? null : (
      <Fragment key={index}>
        <dt className={detail.keyClassName}>{ detail.key }</dt>
        <dd className={detail.valueClassName}>
          { detail.isMobileLocation
            ? getMobileLocationDescription()
            : detail.value }
        </dd>
        { detail.deliveryAddress && (
          <>
            <dt/>
            <dd>{ detail.deliveryAddress }</dd>
          </>
        ) }
      </Fragment>
    );

  return (
    <>
      { title && (
        <h3 className="mt-4 d-flex justify-content-between">{ title }</h3>
      ) }
      <dl className="card mt-3 p-3 border rounded">
        { content.map(renderContentRow) }
      </dl>
    </>
  );
};
