import React from "react";
import { Card, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { LoginButton } from "../LoginButton";
import "./OfficialLoginPage.scss";

interface Props {
  setIsLoading: (value: boolean) => void;
}

export const OfficialLoginPage = ({ setIsLoading }: Props) => (
  <div className="officials-login-page-banner">
    <Row className="m-0">
      <Col className="offset-md-2 mt-5" lg={4} md={8}>
        <h2 className="officials-banner-text fw-300">
          <FormattedMessage
            id="officialLoginPage.welcomeText"
            defaultMessage="Tere tulemast Terviseameti registrite haldusportaali"
          />
        </h2>
        <Row>
          <Col lg={10}>
            <Card className="banner-cta-card">
              <Row>
                <Col className="m-3 mt-4">
                  <h3 className="fw-normal">
                    <FormattedMessage
                      id="officialLoginPage.loginText"
                      defaultMessage="Palun logi sisse, et jätkata"
                    />
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className="ml-3 mb-4">
                  <LoginButton setIsLoading={setIsLoading} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
