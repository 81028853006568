import { RootAction } from "../rootReducer";
import { LICENSE_ACQUISITION_APPLICATION } from "../Constants";
import store from "../store";
import { LicenseAcquisition } from "../../api_client/medre_api";
import {
  ADD_ACQUISITION_FILE_REFERENCE,
  REMOVE_ACQUISITION_FILE_REFERENCE,
  SET_LICENSE_ACQUISITION_APPLICATION,
  UPDATE_LICENSE_ACQUISITION_APPLICATION,
  UPDATE_LICENSE_ACQUISITION_APPLICATION_LICENSE
} from "./licenseAcquisitionActions";

const initial: LicenseAcquisition =
  sessionStorage.getItem(LICENSE_ACQUISITION_APPLICATION) !== null
    ? JSON.parse(
        sessionStorage.getItem(LICENSE_ACQUISITION_APPLICATION) as string
      )
    : {};

let currentValue: LicenseAcquisition;

export function handleLicenseAcquisitionApplicationChange() {
  let previousValue = currentValue;
  currentValue = store.getState().licenseAcquisitionApplication;

  if (previousValue !== currentValue) {
    sessionStorage.setItem(
      LICENSE_ACQUISITION_APPLICATION,
      JSON.stringify(currentValue)
    );
  }
}

export const licenseAcquisitionApplicationReducer = (
  state: LicenseAcquisition = initial,
  action: RootAction
) => {
  switch (action.type) {
    case SET_LICENSE_ACQUISITION_APPLICATION:
      return action.payload;
    case UPDATE_LICENSE_ACQUISITION_APPLICATION_LICENSE:
      return {
        ...state,
        license: action.payload.value
      };
    case UPDATE_LICENSE_ACQUISITION_APPLICATION:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.field
      };
    case ADD_ACQUISITION_FILE_REFERENCE:
      const newReferences = state.fileReferences
        ? [...state.fileReferences, ...action.payload.files]
        : action.payload.files;
      return {
        ...state,
        fileReferences: newReferences
      };
    case REMOVE_ACQUISITION_FILE_REFERENCE:
      const references = state.fileReferences?.filter(
        (ref) => ref.minioFileName !== action.payload.minioFileName
      );
      return {
        ...state,
        fileReferences: references
      };
    default:
      return state;
  }
};
