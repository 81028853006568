import { isAmbulanceService } from "../../../Dto/ActivityLicense/Service";
import {
  ServiceTypeEnum,
  ActivityLicenseService, ActivityLocation
} from "../../../../api_client/medre_api";

export const isServiceValid = (
  service: ActivityLicenseService,
  serviceCodes: string[]
): boolean => {
  return (
    areBedsValid(service) &&
    areEmployeesPresent(service) &&
    areRoomsValid(service) &&
    areAmbulanceBrigadesValid(service) &&
    areAllRequiredServicesPresent(service, serviceCodes)
  );
};

export const isSpecialistBusinessAreaServiceValid = (
  service: ActivityLicenseService,
  serviceCodes: string[],
  location: ActivityLocation | undefined
): boolean => {
  return (
    areBedsValid(service) &&
    areEmployeesPresent(service) &&
    areAmbulanceBrigadesValid(service) &&
    areAllRequiredServicesPresent(service, serviceCodes) &&
    areSpecialistRoomsValid(service, location)
  );
};

const areBedsValid = (service: ActivityLicenseService): boolean => {
  const type = service.service?.type;
  if (type === ServiceTypeEnum.Inpatient || type === ServiceTypeEnum.Both) {
    return !!service.beds;
  }
  return true;
};

const areEmployeesPresent = (service: ActivityLicenseService): boolean => {
  return Boolean(service.employees?.length);
};

const areRoomsValid = (service: ActivityLicenseService): boolean => {
  if (
    isAmbulanceService(service.service?.code) ||
    service.mobileActivityLocationRegistryNumber
  ) {
    return true;
  }
  return Boolean(service.rooms && service.rooms.every((room) => room.number));
};

const areSpecialistRoomsValid = (service: ActivityLicenseService, location: ActivityLocation | undefined): boolean => {
  if (!location) return false;
  if (location.adsAdrID === "mobile") return true;
  return Boolean(service.rooms && service.rooms.length > 0 && service.rooms.every((room) => room.number));
}

const areAmbulanceBrigadesValid = (
  service: ActivityLicenseService
): boolean => {
  const ambulanceBrigadeCode = process.env.REACT_APP_SPECIAL_SERVICE_AMBULANCE;
  if (service.service?.code === ambulanceBrigadeCode) {
    return !!service.ambulanceBrigades;
  }
  return true;
};

const areAllRequiredServicesPresent = (
  service: ActivityLicenseService,
  serviceCodes: string[]
): boolean => {
  if (service.service?.requiredServices) {
    return service.service.requiredServices
      .map((rs) => rs.code)
      .every((requiredService) =>
        serviceCodes.includes(requiredService as string)
      );
  }
  return true;
};
