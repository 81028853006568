import { usePagination, useTable } from "react-table";
import { InsuranceContractAccordionListColumns } from "./InsuranceContractAccordionListColumns";
import { Card } from "reactstrap";
import { ReactTable } from "../../../../Component/Table/ReactTable";
import React from "react";
import { SearchInsuranceContract } from "../../../Shared/Search/Filters/FilterTypes";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";

interface Props {
  data: SearchInsuranceContract[];
  pageCount: number;
  pageIndex: number;
}

export const InsuranceContractsTable = ({
  data,
  pageCount,
  pageIndex,
}: Props) => {
  const table = useTable<SearchInsuranceContract>(
    {
      columns: InsuranceContractAccordionListColumns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
  } = table;

  return (
    <Card>
      <div className="table-wrap">
        <ReactTable<SearchInsuranceContract>
          table={table}
          rows={table.rows}
          className="insurance-table"
        />
        <div className="border-bottom" />
        { data.length !== 0
          ? <TableFooter
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            goToPage={gotoPage}
          />
          : <p className="no-data-text">Vastutuskindlustust ei leitud</p>
        }
      </div>
    </Card>
  );
};