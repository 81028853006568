import React from "react";
import { Nav, NavItem } from "reactstrap";
import classNames from "classnames";
import { defineMessages, FormattedMessage } from "react-intl";
import { LocaleMessage } from "../../../Locale/LocaleMessage";

interface Props {
  tabs: {id: string}[];
  activeTab: string;
  setActiveTab: (id: string) => void;
}

export const SystemAdministrationNav = ({ tabs, activeTab, setActiveTab }: Props) => {

  const navTabHeaders: Record<string, LocaleMessage> = defineMessages({
    TORTA: {
      id: "systemAdministration.tab.torta",
      defaultMessage: "TORTA"
    },
    RUN_QUERIES: {
      id: "systemAdministration.tab.runQueries",
      defaultMessage: "Päringute käivitamine"
    },
    FEATURE_FLAGS: {
      id: "systemAdministration.tab.featureFlags",
      defaultMessage: "Funktsioonilipud"
    }
  });

  return (
    <Nav tabs>
      { tabs.map(({ id }) => {
        const isActive = id === activeTab;
        return (
          <NavItem key={id} className="w-auto">
            <button
              className={classNames("nav-link", {
                active: isActive,
                "bg-transparent": !isActive,
                "bg-white": isActive
              })}
              onClick={() => setActiveTab(id)}
            >
              <FormattedMessage {...navTabHeaders[id]} />
            </button>
          </NavItem>
        );
      }) }
    </Nav>
  );
};