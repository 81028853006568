import React from "react";
import { Card, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { PersonalDataFM } from "../../../../Messages/PersonalDataFM";
import { translateCountry } from "../../../../Component/Translate/CountryTranslator";
import {
  PersonGenderEnum,
  PersonalData,
  Person
} from "../../../../../api_client/medre_api";
import { formatDate, renderOverViewRow } from "./ApplicationOverViewUtil";

interface Props {
  personalData?: PersonalData;
}

export const PersonalDataOverViewCard = ({ personalData }: Props) => {
  const renderGenderRow = (gender?: PersonGenderEnum) => {
    let id;
    if (gender) {
      id = gender === PersonGenderEnum.Male ? "male" : "female";
    }
    return (
      <Row>
        <Col xs={12} md={4} className="col">
          <PersonalDataFM id="gender" />
        </Col>
        <Col>{id ? <PersonalDataFM id={id} /> : "-"}</Col>
      </Row>
    );
  };

  const idCode = personalData?.foreigner
    ? (personalData as Person).foreignIdCode
    : personalData?.idCode;

  return (
    <>
      <h5 className="fw-normal">
        <FormattedMessage
          id="personalDataOverViewCard.title"
          defaultMessage="Isikuandmed"
        />
      </h5>
      <Card className="info-card">
        {renderOverViewRow(
          "personalData",
          "firstName",
          personalData?.firstName
        )}
        {renderOverViewRow("personalData", "lastName", personalData?.lastName)}
        {renderOverViewRow("personalData", "personalCode", idCode)}
        {renderOverViewRow(
          "personalData",
          "dateOfBirth",
          formatDate(personalData?.dateOfBirth)
        )}
        {renderGenderRow(personalData?.gender)}
        {renderOverViewRow(
          "personalData",
          "citizenship",
          translateCountry(personalData?.citizenship)
        )}
      </Card>
    </>
  );
};
