import { action } from "typesafe-actions";
import { AlertItem } from "../Dto/Alert/AlertItem";

export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const alertActions = {
  addAlert: (alert: AlertItem) =>
    action(ADD_ALERT, alert),

  removeAlert: (alert: AlertItem) =>
    action(REMOVE_ALERT, alert)
};
