import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";

import { PortalLoginPage } from "../../../Security/Portal/PortalLoginPage";
import { RootState } from "../../../rootReducer";
import { Loader } from "../../../Component/Loader/Loader";
import { API, getBaseUrl } from "../../../api";
import { personActions } from "../../../Person/personActions";
import {
  getBusinessRouting,
  getPersonRouting,
  getRoleSelectionRouting,
  getPublicRouting
} from "./Routing";
import { CountriesSelectProvider } from "../../../Component/Select/CountrySelect";
import {
  Person,
  OfficialUserPrivilegesEnum,
  KasutajaAndmeteTeenusApiFactory as userDataAPI
} from "../../../../api_client/medre_api";
import "../../Style/ContentContainer.scss";

export const PortalContentContainer = withRouter(
  (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const userInfoId = useSelector(
      (state: RootState) => state.user.userInfo?.userId,
      shallowEqual
    );
    const roles = useSelector(
      (state: RootState) => state.user.userInfo?.roles,
      shallowEqual
    );
    const email = useSelector(
      (state: RootState) => state.person.contactInfo?.email,
      shallowEqual
    );
    const selectedRoles = useSelector(
      (state: RootState) => state.user.userInfo?.selectedRoles,
      shallowEqual
    );

    const [isLoading, setIsLoading] = useState(false);
    const [person, setPerson] = useState<Person>();
    useEffect(() => {
      let isMounted = true;
      if (userInfoId && !person) {
        userDataAPI(undefined, getBaseUrl(), API)
          .getPerson1(userInfoId, {
            withCredentials: true
          })
          .then((res) => {
            if (isMounted) {
              dispatch(personActions.setPerson(res.data));
              setPerson(res.data);
            }
          });
      }
      return () => {
        isMounted = false;
      };
    }, [dispatch, userInfoId, person]);

    const getLoggedInUserRouting = () => {
      if (!person) {
        return null;
      }
      if (!roles?.length || !selectedRoles?.length) {
        return getRoleSelectionRouting();
      }
      if (!roles?.includes(OfficialUserPrivilegesEnum.Person)) {
        return getBusinessRouting();
      }
      return getPersonRouting(roles, person?.contactInfo?.email || email);
    };

    return userInfoId ? (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <CountriesSelectProvider>
            {getLoggedInUserRouting()}
          </CountriesSelectProvider>
        )}
      </>
    ) : (
      <div className="content-container content-container-w-sidemenu">
        <Switch>
          {getPublicRouting()}
          <Route
            path="/"
            render={() => <PortalLoginPage setIsLoading={setIsLoading} />}
          />
        </Switch>
      </div>
    );
  }
);
