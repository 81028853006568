import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./Security.scss";
import { LogoutModal } from "./LogoutModal";

interface Props {
  isWhite?: boolean;
}

export const LogoutButton = ({ isWhite }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function showLogoutModal(e: React.MouseEvent) {
    e.preventDefault();
    setIsModalOpen(true);
  }

  const toggleLogoutModal = () => setIsModalOpen(!isModalOpen);
  const className = isWhite ? "logout-button-white" : "logout-button";

  return (
    <div className="d-flex align-items-start logout-button-container px-3">
      <a className={className} href={"/"} onClick={(e) => showLogoutModal(e)}>
        <FormattedMessage id="button.logOut" defaultMessage="Logi välja" />
      </a>
      <LogoutModal
        toggleLogoutModal={toggleLogoutModal}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};
