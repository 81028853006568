import {Option, SingleSelect} from "./SingleSelect";
import React, {createContext, ReactElement, useContext, useEffect, useState} from "react";
import {createFilter} from "react-select";
import {getCountryOption} from "../../Util/CountryFilterUtil";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {useCountryOptions} from "../../Address/addressSelector";
import {Props as SelectProps} from "./SelectTypes";

type CountriesContextType = Option[];
interface CountriesProviderProps {
  children?: ReactElement | ReactElement[] | null;
  value?: Option[];
}

interface Props extends Partial<SelectProps> {
  defaultSelectedValue?: string;
  handleOptionChange: (value: Option, name: string) => void;
  withNotSpecified?: boolean;
  withoutElements?: string[];
}

export const CountriesContext = createContext<CountriesContextType>([]);
export const CountriesSelectProvider = ({
  children,
  value
}: CountriesProviderProps) => {
  const options = value ?? useCountryOptions();
  return typeof options === "undefined" ? null : (
    <CountriesContext.Provider value={options}>
      {children}
    </CountriesContext.Provider>
  );
};

export const CountrySelect: React.FC<Props> = (props) => {
  const locale = useSelector((state: RootState) => state.locale);

  const { value, handleOptionChange, withoutElements } = props;
  const [defaultSelectedValue] = useState(props.defaultSelectedValue);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  let countryOptions = useContext(CountriesContext);
  let filteredValues = new Array<string>();
  if (!props.withNotSpecified) {
    filteredValues.push("XXX");
  }
  if (withoutElements) {
    filteredValues = filteredValues.concat(withoutElements);
  }

  countryOptions = countryOptions.filter(
    (c) => filteredValues.indexOf(c.value) < 0
  );

  useEffect(() => {
    if (countryOptions.length && defaultSelectedValue) {
      const [country] = countryOptions.filter(
        (c) => c.value === defaultSelectedValue
      );
      setSelectedOption(country);
    }
  }, [countryOptions, defaultSelectedValue]);

  const handleChange = (option: Option, name: string) => {
    setSelectedOption(option);
    handleOptionChange(option, name);
  };

  return (
    <SingleSelect
      {...props}
      value={value || selectedOption}
      options={countryOptions}
      handleOptionChange={handleChange}
      filterOptions={createFilter({
        ignoreAccents: false,
        stringify: (option) => getCountryOption(option, locale)
      })}
    />
  );
};
