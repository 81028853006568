import { action } from "typesafe-actions";
import {
  ApplicationFilters,
  ApplicantFilters,
  ApplicationCertificateFilters,
  SearchApplication,
  ActivityLicenseApplicationFilters,
  SearchActivityLicenseApplication,
  SearchApplicant,
  GeneralPractitionerListFilters,
  PublicGeneralPractitionerListFilters,
  ActiveFilters,
  CompanyFilters,
  SearchCompany,
  PublicHealthcareEmployeesFilters,
  DecisionTHTFilters,
  DecisionLicenseFilters,
  InsuranceContractFilters, SearchInsuranceContract
} from "./Filters/FilterTypes";
import {
  GeneralPractitionerList,
  PowerOfAttorney,
  PowersOfAttorneySearchFilter
} from "../../../../api_client/medre_api";
import { SortingRule } from "react-table";

export const SET_DATA = "SET_DATA";
export const SET_INDEX = "SET_INDEX";
export const SET_SIZE = "SET_SIZE";
export const SET_ROWCOUNT = "SET_ROWCOUNT";
export const SET_PAGECOUNT = "SET_PAGECOUNT";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SORT_BY = "SET_SORT_BY";

export const searchResultsActions = {
  setData: (
    data:
    | SearchApplication[]
    | SearchActivityLicenseApplication[]
    | SearchApplicant[]
    | GeneralPractitionerList[]
    | SearchCompany[]
    | PowerOfAttorney[]
    | SearchInsuranceContract[],
    filterType: ActiveFilters
  ) => action(SET_DATA, { list: data, filterType }),
  setIndex: (index: number) => action(SET_INDEX, index),
  setSize: (size: number) => action(SET_SIZE, size),
  setRowCount: (rowCount: number) => action(SET_ROWCOUNT, rowCount),
  setPageCount: (pageCount: number) => action(SET_PAGECOUNT, pageCount),
  setFilters: (
    filters:
    | ApplicationFilters
    | ActivityLicenseApplicationFilters
    | ApplicantFilters
    | ApplicationCertificateFilters
    | GeneralPractitionerListFilters
    | PublicGeneralPractitionerListFilters
    | PublicHealthcareEmployeesFilters
    | CompanyFilters
    | DecisionTHTFilters
    | DecisionLicenseFilters
    | PowersOfAttorneySearchFilter
    | InsuranceContractFilters
    | null
  ) => action(SET_FILTERS, filters),
  setSortBy: <T extends {}>([sortingRule]: SortingRule<T>[]) =>
    action(
      SET_SORT_BY,
      sortingRule?.id
        ? [`${ sortingRule.id }:${ sortingRule.desc ? "desc" : "asc" }`]
        : null
    )
};
