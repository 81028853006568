import React, { FC, useMemo } from "react";
import { usePagination, useTable } from "react-table";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";

import {
  ReactTable,
  ReactTableColumn
} from "../../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";
import { getFullName } from "../../../../Util/PersonUtils";
import {
  HealthCareProfessional,
  OccupationCode
} from "../../../../../api_client/medre_api";
import { useMobile } from "../../../../Hook/useMobile";

interface Props {
  data: HealthCareProfessional[];
  pageCount: number;
  pageIndex: number;
}

type HealthcareEmployeeColumnKey =
  | "occupationCodes"
  | "occupationNames"
  | "detailsLink";
type HealthcareEmployeeColumnsObj = Record<
  HealthcareEmployeeColumnKey,
  ReactTableColumn<HealthCareProfessional>
>;

const mapOccupationCodes = (
  codes: OccupationCode[],
  key: keyof OccupationCode
) => codes.map((occupation) => occupation[key]).join(", ");

const healthcareEmployeeColumnsObj: HealthcareEmployeeColumnsObj = {
  occupationCodes: {
    id: "occupationCodes",
    sticky: true,
    Header: (
      <div className="text-left">
        <FormattedMessage
          id="HealthcareEmployeeSearchTableHeader.code"
          defaultMessage="Kood"
        />
      </div>
    ),
    accessor: "occupationCodes",
    width: 100,
    Cell: ({ value }) => (
      <div className="text-left">{mapOccupationCodes(value!, "code")}</div>
    )
  },
  occupationNames: {
    Header: (
      <FormattedMessage
        id="HealthcareEmployeeSearchTableHeader.occupation"
        defaultMessage="Kutse"
      />
    ),
    accessor: "occupationCodes",
    id: "occupationNames",
    width: 0,
    Cell: ({ value }) => mapOccupationCodes(value!, "name")
  },
  detailsLink: {
    Header: "",
    accessor: "id",
    id: "detailsLink",
    sticky: true,
    disableSortBy: true,
    Cell: ({ value: id }) => (
      <DetailViewLinkButton id="lookCloserLink" to={`/employees/${id}`} />
    )
  }
};

const healthcareEmployeeColumns: Array<
  ReactTableColumn<HealthCareProfessional>
> = [
  healthcareEmployeeColumnsObj.occupationCodes,
  {
    id: "firstName",
    Header: (
      <FormattedMessage
        id="HealthcareEmployeeSearchTableHeader.firstName"
        defaultMessage="Eesnimi"
      />
    ),
    accessor: "firstName",
    width: 0
  },
  {
    id: "lastName",
    Header: (
      <FormattedMessage
        id="HealthcareEmployeeSearchTableHeader.lastName"
        defaultMessage="Perenimi"
      />
    ),
    accessor: "lastName",
    width: 0
  },
  healthcareEmployeeColumnsObj.occupationNames,
  healthcareEmployeeColumnsObj.detailsLink
];

const healthcareEmployeeMobileColumns: Array<
  ReactTableColumn<HealthCareProfessional>
> = [
  {
    id: "fullName",
    accessor: (employee) => getFullName(employee)
  },
  {
    id: "occupationNames",
    accessor: "occupationCodes",
    Cell: ({ value }) => (
      <span className="font-weight-500">
        {mapOccupationCodes(value!, "name")}
      </span>
    )
  },
  {
    id: "occupationCodes",
    accessor: "occupationCodes",
    Cell: ({ value }) => (
      <>
        <div className="font-size-14">
          {healthcareEmployeeColumnsObj.occupationCodes.Header}
        </div>
        <span className="font-weight-500">
          {mapOccupationCodes(value!, "code")}
        </span>
      </>
    )
  },
  healthcareEmployeeColumnsObj.detailsLink
];

export const HealthcareEmployeesResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex
}) => {
  const isMobile = useMobile();
  const columns = useMemo(
    () =>
      isMobile ? healthcareEmployeeMobileColumns : healthcareEmployeeColumns,
    [isMobile]
  );
  const table = useTable<HealthCareProfessional>(
    {
      columns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false
    },
    usePagination
  );

  const { previousPage, nextPage, gotoPage, canPreviousPage, canNextPage } =
    table;

  return (
    <div className="table-wrap">
      <ReactTable<HealthCareProfessional>
        table={table}
        rows={table.rows}
        className="border-0"
        square={true}
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
