import { useMemo } from "react";

export const useInvalidationQuickLinks = () => {

  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused",
      },
      {
        id: "details",
        title: "Taotluse detailid",
      },
      {
        id: "notes",
        title: "Menetluse märkmed",
      },
      {
        id: "history",
        title: "Menetluse ajalugu",
      },
      {
        id: "resolutions",
        title: "Otsused",
      }
    ],
    []
  );

  return { quickLinks };
};