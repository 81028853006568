import React, {useCallback, useMemo, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import "../../Application/Details/ApplicationDetailsCardTable.scss";
import { useDetailedApplication } from "../../Application/Details/ApplicationDetailsHook";
import { Loader } from "../../../../Component/Loader/Loader";
import { ApplicationCertificateDetailsActions } from "./ApplicationCertificateDetailsActions";
import { API, getBaseUrl } from "../../../../api";
import { ApplicationDetailsPayment } from "../../Application/Details/ApplicationDetailsPayment";
import { translateCountries } from "../../../../Component/Translate/CountryTranslator";
import { ApplicantCertificates } from "../../Applicant/Certificates/ApplicantCertificates";
import {
  useApplications,
  ApplicationsContext
} from "../../../Shared/Application/ApplicationsContext";
import { ApplicationNotes } from "../../Application/Details/ApplicationNotes";
import { ApplicationDetailsHistory } from "../../Application/Details/ApplicationDetailsHistory";
import { ApplicationFM } from "../../../../Messages/ApplicationFM";
import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import { getFullPhoneNumber } from "../../../Shared/Application/ContactInfo/Address/AddressUtil";
import { ContactInfoFM } from "../../../../Messages/ContactInfoFM";
import {
  ApplicationCertificateProvider,
  useApplicationCertificateState
} from "../../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import { ApplicationDetailsHeader } from "../../Application/Details/ApplicationDetailsHeader";
import {
  ProceedingStatusStatusEnum,
  ApplicationCertificate,
  Occupation,
  Country,
  ApplicationCertificateDeliveryTypeEnum,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  AmetnikuRakendussertifikaadiAndmeteenusApiFactory as officialAppCertificateDataAPI,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  AmetnikuSertifikaadiAndmeteenusApiFactory as officialCertificateDataAPI
} from "../../../../../api_client/medre_api";
import { QuickLinks } from "../../../../Component/QuickLinks/QuickLinks";
import { useDetailedPerson } from "../../Applicant/ApplicantPersonalDetails/PersonDetailsHook";
import { ClickableInfoValue } from "../../../../Component/QuickLinks/ClickableInfoValue";

export const ApplicationCertificateDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { details, setDetails, refreshApplication } =
    useDetailedApplication<ApplicationCertificate>(
      officialAppCertificateDataAPI(undefined, getBaseUrl(), API)
        .getApplication1,
      id
    );

  const { person, setPerson, refreshPerson } = useDetailedPerson(
    details?.personId
  );

  const applicationsState = useApplications(
    person?.id
      ? officialAppDataAPI(undefined, getBaseUrl(), API).getPersonApplications
      : undefined,
    person?.id
  );

  const certificateRequestState = useApplicationCertificateState(
    person?.id
      ? officialCertificateDataAPI(undefined, getBaseUrl(), API)
          .getApplicationCertificateMetadata
      : undefined,
    person?.id
  );

  const history = useHistory();
  const setPersonData = useCallback(async () => {
    try {
      const personData = (
        await userDataAPI(undefined, getBaseUrl(), API).getPerson1(
          person!.id as string,
          {
            withCredentials: true
          }
        )
      ).data;
      setPerson(personData);
    } catch (error) {
      if ((error as any).response.status !== 400) {
        history.push("/");
      }
    }
  }, [person, setPerson, history]);

  const certificates = useMemo(
    () =>
      person?.certificates?.filter(
        (certificate) => certificate.applicationCertificateId === id
      ) ?? [],
    [person, id]
  );

  const isDraft =
    details?.currentStatus?.status === ProceedingStatusStatusEnum.Saved;
  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      ...(!isDraft
        ? [
            {
              id: "history",
              title: "Menetluse ajalugu"
            },
            {
              id: "certificate",
              title: "Tõend"
            }
          ]
        : [])
    ],
    [isDraft]
  );

  const getApplicationDetailCardContentValue = (
    detailsOccupations: Occupation[]
  ) => {
    const occupationNames: string[] = [];
    detailsOccupations.forEach((occupation) =>
      occupationNames.push(occupation.name!)
    );

    return occupationNames.join(", ");
  };

  const getCountriesArray = (countries: Country[]) => {
    const countriesArray: Country[] = [];
    countries.forEach((country) => countriesArray.push(country));
    return countriesArray;
  };

  const refreshApplicationAndPersonData = () => {
    return refreshApplication().then(() => refreshPerson());
  };

  const getDeliveryType = (
    deliveryType?: ApplicationCertificateDeliveryTypeEnum
  ) => {
    switch (deliveryType) {
      case ApplicationCertificateDeliveryTypeEnum.Electronic:
        return <ContactInfoFM id="certificateIssuedElectronically" />;
      case ApplicationCertificateDeliveryTypeEnum.Post:
        return <ContactInfoFM id="certificateDeliveryByPost" />;
      case ApplicationCertificateDeliveryTypeEnum.TakeMyself:
        return <ContactInfoFM id="takeCertificateCompanyAddress" />;
      default:
        return "-";
    }
  };

  const handleInfoClick = () => {
    history.push(`/persons/${person?.id}`);
  };

  if (!details || !person || applicationsState.loading) {
    return <Loader />;
  }

  return (
    <div className="application-container application-detail">
      <ApplicationDetailsHeader application={details} isDraft={isDraft} />
      <div className="ml-3">
        <QuickLinks links={quickLinks} />
      </div>
      <ApplicationsContext.Provider value={applicationsState}>
        <ApplicationCertificateProvider value={certificateRequestState}>
          <Card className="mx-3 my-4 p-4">
            {isLoading && <Loader backdrop />}
            <ApplicationCertificateDetailsActions
              person={person}
              application={details}
              setIsLoading={setIsLoading}
              refreshApplication={refreshApplicationAndPersonData}
            />
          </Card>

          <Card className="mx-3 my-4 p-4 d-block">
            <h2>
              <span id="details" /> Taotluse detailid
            </h2>

            <ApplicationDetailsInfoCard
              title={<ApplicationFM id="occupation" />}
              content={[
                {
                  key: "Kutse",
                  value: getApplicationDetailCardContentValue(
                    details.occupations!
                  )
                },
                {
                  key: "Eriala",
                  value: details.speciality?.name ?? "-"
                },
                {
                  key: "Riik",
                  value: (
                    <>
                      {translateCountries(
                        getCountriesArray(details.countries!)
                      )}
                    </>
                  )
                },
                {
                  key: "Haridus",
                  value: details.education
                }
              ]}
            />

            <div className="mt-4" />
            <ApplicationDetailsInfoCard
              title="Isikuandmed"
              content={[
                {
                  key: "Eesnimi",
                  valueClassName: "text-capitalize",
                  value: person.firstName?.toLowerCase()
                },
                {
                  key: "Perenimi",
                  valueClassName: "text-capitalize",
                  value: person.lastName?.toLowerCase()
                },
                {
                  key: "Isikukood",
                  value: (
                    <ClickableInfoValue
                      name={person.idCode || ""}
                      onClick={handleInfoClick}
                    />
                  )
                },
                {
                  key: "Sünniaeg",
                  value: person.dateOfBirth
                    ? moment(person.dateOfBirth).format("DD.MM.YYYY")
                    : "-"
                },
                {
                  key: "Sugu",
                  value: person.gender
                    ? person.gender === "MALE"
                      ? "Mees"
                      : "Naine"
                    : "-"
                },
                {
                  key: "Kodakondsus",
                  value: person.citizenship ? (
                    <FormattedMessage id={`country.${person.citizenship}`} />
                  ) : (
                    "-"
                  )
                }
              ]}
            />

            <div className="mt-4" />

            <ApplicationDetailsInfoCard
              title="Taotluse kontaktandmed ja tõendi väljastamine"
              content={[
                {
                  key: "E-post",
                  value: person.contactInfo?.email ?? "-"
                },
                {
                  key: "Telefon",
                  value: getFullPhoneNumber(person.contactInfo)
                },
                {
                  key: "Tõendi kättesaamise viis",
                  value: getDeliveryType(details.deliveryType),
                  deliveryAddress:
                    details.deliveryType ===
                    ApplicationCertificateDeliveryTypeEnum.Post
                      ? details.deliveryAddress
                      : ""
                }
              ]}
            />
            <ApplicationDetailsPayment
              application={details}
              manualPaymentApiRequest={() =>
                officialAppCertificateDataAPI(
                  undefined,
                  getBaseUrl(),
                  API
                ).paidManuallyApplicationCertificate(details.id!, {
                  withCredentials: true
                })
              }
              updateApplication={setDetails}
            />
          </Card>

          {!isDraft && (
            <>
              <ApplicationNotes<ApplicationCertificate>
                notes={!!details.notes ? details.notes : ""}
                setDetails={setDetails}
                endpoint={
                  officialAppCertificateDataAPI(undefined, getBaseUrl(), API)
                    .updateApplicationCertificateNote
                }
                detailsId={details.id}
              />
              <ApplicationDetailsHistory
                application={details as ApplicationCertificate}
              />
            </>
          )}

          <ApplicantCertificates
            person={person}
            certificates={certificates}
            afterCertificateUpdate={setPersonData}
            allowAddNewCertificate={false}
          />
        </ApplicationCertificateProvider>
      </ApplicationsContext.Provider>
    </div>
  );
};
