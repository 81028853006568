import { action } from "typesafe-actions";
import {
  ContactInfo,
  Person,
  PersonGenderEnum
} from "../../api_client/medre_api";

export const SET_PERSON = "SET_PERSON";
export const UPDATE_PERSON = "UPDATE_PERSON";

export const personActions = {
  setPerson: (person: Person) => action(SET_PERSON, person),
  updatePerson: (
    fieldKey: keyof Person,
    fieldValue: string | PersonGenderEnum | boolean | Date | ContactInfo
  ) => action(UPDATE_PERSON, { fieldKey, fieldValue })
};
