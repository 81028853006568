import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Col, FormGroup, Input, Label } from "reactstrap";

export const OccupationsFormEducation = () => {
  const { control } = useFormContext();

  return (
    <FormGroup row={true} className="required">
      <Label
        htmlFor="occupationsFormEducation"
        className="text-left text-sm-right"
        sm={4}
      >
        <FormattedMessage
          id="ApplicationCertificate.educationField"
          defaultMessage="Tunnustatav haridus (õppeasutus, kutse/eriala, lõpetamise aeg)"
        />
      </Label>
      <Col sm={7} md={5} className="d-flex align-items-center pb-2">
        <Controller
          id="occupationsFormEducation"
          name="education"
          control={control}
          as={Input}
          type="textarea"
          defaultValue=""
          rules={{ required: true }}
        />
      </Col>
    </FormGroup>
  );
};
