import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { usePagination, useSortBy, useTable } from "react-table";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";
import {
  ReactTable,
  ReactTableColumn
} from "../../../../Component/Table/ReactTable";
import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { SearchActivityLicenseApplication } from "../../../Shared/Search/Filters/FilterTypes";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { ActivityLicenseApplicationProceedingTypeEnum } from "../../../../../api_client/medre_api";
import { ActivityLicenseFM } from "../../../../Messages/ActivityLicenseFM";
import { getApplicationRowClassName } from "../../../../Util/ActivityLicenseUtils";

interface Props {
  data: SearchActivityLicenseApplication[];
  pageCount: number;
  pageIndex: number;
}

const getDetailViewRouteByProceedingType = (
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum
) => {
  switch (proceedingType) {
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense:
      return "/activity-license-applications";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation:
      return "/activity-license-invalidations";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation:
      return "/activity-license-invalidations/location";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
      return "/activity-license-suspensions/location";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
      return "/activity-license-suspensions";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
      return "/activity-license-suspensions/service";
    case ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition:
      return "/license-acquisitions";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard:
      return "/discard-services";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration:
      return "/license-restorations";
    case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration:
      return "/location-restorations";
    case ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration:
      return "/service-restorations";
    case ActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication:
      return "/general-practitioner-application";
    default:
      return "/activity-license-applications";
  }
};

const activityLicenseApplicationColumns: ReactTableColumn<SearchActivityLicenseApplication>[] =
  [
    {
      accessor: "arrivalTime",
      Header: <GeneralFM id="date" />,
      Cell: (cell) => formatDate(cell.value),
      width: 100
    },
    {
      accessor: "applicationNumber",
      Header: (
        <FormattedMessage
          id="filters.applicationNumber"
          defaultMessage="Number"
        />
      ),
      width: 100
    },
    {
      accessor: "businessName",
      Header: (
        <FormattedMessage
          id="filters.businessName"
          defaultMessage="Teenuse osutaja"
        />
      ),
      width: 0
    },
    {
      accessor: "applicationStatus",
      Header: (
        <FormattedMessage
          id="filters.applicationStatus"
          defaultMessage="Staatus"
        />
      ),
      Cell: ({ cell }) => (
        <ActivityLicenseApplicationState
          currentStatus={cell.row.values.applicationStatus}
          isStateFeePaid={cell.row.original.stateFeeIsPaid}
          inTable={true}
        />
      ),
      width: 0
    },
    {
      accessor: "proceedingType",
      Header: <ActivityLicenseFM id="proceedingType" />,
      Cell: (cell) => formatProceedingType(cell.value!),
      width: 0
    },
    {
      accessor: "businessArea",
      Header: (
        <FormattedMessage
          id="filters.actualBusinessAreaName"
          defaultMessage="Tegevusala"
        />
      ),
      width: 0
    },
    {
      Header: "",
      accessor: "id",
      sticky: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <DetailViewLinkButton
          to={`${getDetailViewRouteByProceedingType(
            cell.row.original.proceedingType
          )}/${cell.value}`}
        />
      ),
      width: 0
    }
  ];

export const ActivityLicenseApplicationResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex
}) => {
  const dispatch = useDispatch();
  const table = useTable<SearchActivityLicenseApplication>(
    {
      columns: activityLicenseApplicationColumns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { sortBy }
  } = table;

  useEffect(() => {
    gotoPage(0);
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, gotoPage, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<SearchActivityLicenseApplication>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) =>
          column.getHeaderProps(column.getSortByToggleProps())
        }
        getRowProps={(row) => {
          return {
            ...row.getRowProps(),
            className: getApplicationRowClassName(
              row.original.applicationStatus,
              row.original.stateFeeIsPaid
            )
          };
        }}
        className="border-0"
        square={true}
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
