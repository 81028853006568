import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Country } from "../../../api_client/medre_api";
import { joinJSX } from "../../Util/ArrayUtil";

export const getFormattedCountryMessage = (codeAlpha3?: string) =>
  codeAlpha3 && <FormattedMessage id={`country.${codeAlpha3}`} />;

export const translateCountry = (codeAlpha3?: string): JSX.Element => {
  const message = getFormattedCountryMessage(codeAlpha3);
  if (!message) {
    return <Fragment>-</Fragment>;
  }
  return message;
};

export const translateCountries = (
  countries: Country[],
  separator: JSX.Element = <>,&nbsp;</>
): JSX.Element[] =>
  joinJSX(
    countries.map<[string, JSX.Element]>((country) => [
      country.code!,
      translateCountry(country.code)
    ]),
    separator
  );
