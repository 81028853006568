import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePagination, useSortBy, useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";

import "./RepresentativeList.scss";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { ReactTable, ReactTableColumn } from "../../../../Component/Table/ReactTable";
import { RepresentativeListProps, RepresentativeModalMode, RepresentativeModalType } from "./types";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { isStatusNotExpired, showDeleteIcon, showEditIcon } from "../../../../Util/RepresentativeUtils";
import { Loader } from "../../../../Component/Loader/Loader";
import editIcon from "../../../../../assets/images/edit_blue.svg";
import RepresentativeModal from "../RepresentativeModal/RepresentativeModal";
import {
  OfficialUserPrivilegesEnum,
  PowerOfAttorney,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import RepresentativeDeactiveConfirmModal
  from "../RepresentativeDeactiveConfirmModal/RepresentativeDeactiveConfirmModal";
import viewIcon from "../../../../../assets/images/view_green.svg";
import RepresentativeViewModal from "../RepresentativeViewModal/RepresentativeViewModal";
import { RootState } from "../../../../rootReducer";
import deleteIcon from "../../../../../assets/images/delete_red.svg";
import { getFormattedDate } from "../../../../Util/DateUtils";
import { RepresentativeMessage } from "../../../../Messages/RepresentativeMessage";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { API, getBaseUrl } from "../../../../api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { searchResultsActions } from "../../../Shared/Search/searchResultsActions";
import { GetActivityLicenses } from "../../ActivityLicense/getActivityLicenses";

const RepresentativeList = ({
  repData,
  pageIndex,
  pageCount,
  loading,
  registryCode,
  reFetchAndUpdateData,
  title,
  containerClass,
  addBtnClass,
  isPortal
}: RepresentativeListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userRoles = useSelector(
    (state: RootState) => state.user.userInfo?.roles
  );

  const [validLicenseExist, setValidLicenseExist] = useState(false);

  const dispatch = useDispatch();

  const activityLicenses = GetActivityLicenses();

  const representativeColumns: ReactTableColumn<PowerOfAttorney>[] = useMemo(
    () => [
      {
        accessor: "personCode",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.personalCode"
            defaultMessage="Isikukood"
          />
        ),
        Cell: ({ cell }) => cell.value,
        width: 150
      },
      {
        accessor: "personFirstName",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.name"
            defaultMessage="Eesnimi"
          />
        ),
        Cell: ({ cell }) => <>{cell.value}</>,
        width: 160
      },
      {
        accessor: "personLastName",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.lastName"
            defaultMessage="Perenimi"
          />
        ),
        Cell: ({ cell }) => <>{cell.value}</>,
        width: 160
      },
      {
        accessor: "personEmailAddress",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.email"
            defaultMessage="E-post"
          />
        ),
        Cell: ({ cell }) => cell.value,
        width: 255
      },
      {
        accessor: "type",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.type"
            defaultMessage="Volituse tüüp"
          />
        ),
        Cell: ({ cell }) => cell.value,
        width: 165
      },
      {
        accessor: "startDate",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.startDate"
            defaultMessage="Alates"
          />
        ),
        Cell: ({ cell }) => getFormattedDate(cell.value),
        width: 135
      },
      {
        accessor: "endDate",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.endDate"
            defaultMessage="Kuni"
          />
        ),
        Cell: ({ cell }) => cell.value && getFormattedDate(cell.value),
        width: 135
      },
      {
        accessor: "status",
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.status"
            defaultMessage="Olek"
          />
        ),
        Cell: ({ cell }) => <RepresentativeMessage id={cell.value as string} />,
        width: 130
      },
      {
        Header: (
          <FormattedMessage
            id="representativeListTableHeader.remainingDays"
            defaultMessage="Järelejäänud päevade arv"
          />
        ),
        accessor: "daysLeft",
        Cell: ({ cell }) => {
          const daysLeft = cell.row.original.daysLeft;
          return (
            <>
              {daysLeft ? (
                <FormattedMessage
                  id="representativeList.validDays"
                  defaultMessage="Lõpeb {number} päeva pärast"
                  values={{ number: daysLeft }}
                />
              ) : null}
            </>
          );
        },
        width: 230
      },
      {
        Header: "",
        accessor: "id",
        sticky: true,
        disableSortBy: true,
        width: 0,
        Cell: ({ cell }) => (
          <div className="represent-table-action">
            <img
              src={viewIcon}
              onClick={() => handleRecordView(cell.row.original)}
              alt="view"
            />
            {isPortal
              ? showEditIcon(cell.row.original, userRoles) && validLicenseExist && (
                <img
                  src={editIcon}
                  onClick={() => handleRecordEdit(cell.row.original)}
                  alt="edit"
                />
              )
              : isStatusNotExpired(cell.row.original.status) && (
                <ProtectedComponent
                  allowedRoles={[
                    OfficialUserPrivilegesEnum.Sign,
                    OfficialUserPrivilegesEnum.Proceede
                  ]}
                >
                  <img
                    src={editIcon}
                    onClick={() => handleRecordEdit(cell.row.original)}
                    alt="edit"
                  />
                </ProtectedComponent>
              )}
            {isPortal
              ? showDeleteIcon(cell.row.original, userRoles) && (
                <img
                  src={deleteIcon}
                  onClick={() => handleRecordDeactivate(cell.row.original)}
                  alt="delete"
                />
              )
              : isStatusNotExpired(cell.row.original.status) && (
                <ProtectedComponent
                  allowedRoles={[
                    OfficialUserPrivilegesEnum.Sign,
                    OfficialUserPrivilegesEnum.Proceede
                  ]}
                >
                  <img
                    src={deleteIcon}
                    onClick={() => handleRecordDeactivate(cell.row.original)}
                    alt="delete"
                  />
                </ProtectedComponent>
              )}
          </div>
        )
      }
    ],
    [validLicenseExist]
  );

  const table = useTable<PowerOfAttorney>(
    {
      columns: representativeColumns,
      data: repData,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { sortBy }
  } = table;

  useEffect(() => {
    gotoPage(0);
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, gotoPage, dispatch]);

  const [representativeModal, setRepresentativeModal] =
    useState<RepresentativeModalType>({
      isOpen: false,
      mode: RepresentativeModalMode.Edit
    });

  const [representativeDeactivateModal, setRepresentativeDeactivateModal] =
    useState<RepresentativeModalType>({
      isOpen: false
    });

  const [representativeViewModal, setRepresentativeViewModal] =
    useState<RepresentativeModalType>({
      isOpen: false
    });


  useEffect(() => {
    if (isPortal && registryCode) {
      setIsLoading(true);
      activityPermitAPI(undefined, getBaseUrl(), API)
        .getAllByCompanyRegistryCode(registryCode, {
          withCredentials: true
        })
        .then((res) => {
          setValidLicenseExist(!!res.data.length);
        })
        .catch(() => {
          const alertMessage = <AlertMessage id="requestFailed" />;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
        })
        .finally(() => setIsLoading(false));
    }
  }, [registryCode]);

  const handleRepModalClose = () =>
    setRepresentativeModal({
      ...(representativeModal as RepresentativeModalType),
      isOpen: false
    });

  const handleRecordEdit = (record: PowerOfAttorney) =>
    setRepresentativeModal({
      isOpen: true,
      mode: RepresentativeModalMode.Edit,
      data: record
    });

  const handleUpdateData = () => {
    handleRepModalClose();
    handleDeactiveModalClose();
    reFetchAndUpdateData();
  };

  const handleRecordAdd = () =>
    setRepresentativeModal({
      isOpen: true,
      mode: RepresentativeModalMode.Add
    });

  const handleRecordDeactivate = (record: PowerOfAttorney) =>
    setRepresentativeDeactivateModal({
      isOpen: true,
      data: record
    });

  const handleDeactiveModalClose = () =>
    setRepresentativeDeactivateModal({
      isOpen: false
    });

  const handleRecordView = (record: PowerOfAttorney) =>
    setRepresentativeViewModal({
      isOpen: true,
      data: record
    });

  const handleViewModalClose = () =>
    setRepresentativeViewModal({
      isOpen: false
    });

  const addAuthorityBtn = (
    <PrimaryFormattedButton
      id="addPerson"
      type="button"
      className={`mr-4 mb-2 ${addBtnClass}`}
      onClick={handleRecordAdd}
    />
  );

  return loading ? (
    <Loader absolute backdrop />
  ) : (
    <>
      <div className={`d-flex flex-column my-4 ${containerClass}`}>
        <div className="d-flex repr-list-header-container">
          <div>{title}</div>
          {!isPortal ? (
            <ProtectedComponent
              allowedRoles={[
                OfficialUserPrivilegesEnum.Sign,
                OfficialUserPrivilegesEnum.Proceede
              ]}
            >
              {addAuthorityBtn}
            </ProtectedComponent>
          ) : loading || isLoading ? (
            <Loader absolute backdrop />
          ) : (
            validLicenseExist && (
              <ProtectedComponent
                directRepresentativeOnly
                allowedRoles={[
                  OfficialUserPrivilegesEnum.PowerOfAttorneyTypeUltimate,
                  OfficialUserPrivilegesEnum.PowerOfAttorneyTypeFull
                ]}
                matchAnyCondition
              >
                {addAuthorityBtn}
              </ProtectedComponent>
            )
          )}
        </div>
      </div>
      <>
        {!!repData.length ? (
          <div className="table-wrap representative-list-table">
            <ReactTable<PowerOfAttorney>
              table={table}
              rows={table.rows}
              getHeaderProps={(column) =>
                column.getHeaderProps(column.getSortByToggleProps())
              }
              className="border-0"
              square
            />
            <div className="border-bottom" />
            <TableFooter
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              nextPage={nextPage}
              goToPage={gotoPage}
            />
          </div>
        ) : (
          <FormattedMessage
            id="representativeList.empty.text"
            defaultMessage="Ettevõtte volitatud esindajad"
          />
        )}
      </>
      {representativeModal.isOpen && (
        <RepresentativeModal
          modal={representativeModal}
          onClose={handleRepModalClose}
          registryCode={registryCode}
          reFetchAndUpdateData={handleUpdateData}
          isPortal={isPortal}
          activityLicenses={activityLicenses}
        />
      )}
      {representativeDeactivateModal.isOpen && (
        <RepresentativeDeactiveConfirmModal
          modal={representativeDeactivateModal}
          onClose={handleDeactiveModalClose}
          registryCode={registryCode}
          reFetchAndUpdateData={handleUpdateData}
          isPortal={isPortal}
        />
      )}
      {representativeViewModal.isOpen && (
        <RepresentativeViewModal
          modal={representativeViewModal}
          onClose={handleViewModalClose}
        />
      )}
    </>
  );
};

export default RepresentativeList;
