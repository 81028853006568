import { createContext, useCallback, useEffect, useState } from "react";
import { AxiosPromise } from "axios";

import {
  ProceedingStatusStatusEnum,
  UserApplications
} from "../../../../api_client/medre_api";

interface ApplicationsContext {
  loading: boolean;
  applications: UserApplications;
  fetchApplications: () => Promise<void>;
}

type EndpointWithId = (
  id: string,
  options?: any
) => AxiosPromise<UserApplications>;
type Endpoint = (options?: any) => AxiosPromise<UserApplications>;

const defaultContextValue: ApplicationsContext = {
  loading: true,
  applications: {
    savedApplications: [],
    submittedApplications: [],
    applicationCertificates: []
  },
  fetchApplications: async () => undefined
};

export const ApplicationsContext =
  createContext<ApplicationsContext>(defaultContextValue);

const mapApplicationCertificates = ({
  savedApplications = [],
  submittedApplications = [],
  applicationCertificates = []
}: UserApplications) =>
  [...applicationCertificates]
    .sort(
      (a, b) =>
        (b.applicationNumber as number) - (a.applicationNumber as number)
    )
    .reverse()
    .reduce(
      (acc, app) => {
        acc[
          app.currentStatus?.status === ProceedingStatusStatusEnum.Saved
            ? "savedApplications"
            : "submittedApplications"
        ].unshift(app as any);
        return acc;
      },
      {
        savedApplications,
        submittedApplications,
        applicationCertificates
      }
    );

export const useApplications = (
  endpoint?: EndpointWithId | Endpoint,
  id?: string
) => {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState<UserApplications>(
    defaultContextValue.applications
  );
  const fetchApplications = useCallback(async () => {
    if (endpoint) {
      setLoading(true);
      try {
        const { data } = id
          ? await endpoint(id, {
              withCredentials: true
            })
          : await (endpoint as Endpoint)({
              withCredentials: true
            });
        setApplications(mapApplicationCertificates(data));
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications, id]);

  return { loading, applications, setApplications, fetchApplications };
};
