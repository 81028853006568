import React, { FC } from "react";
import { Column, usePagination, useTable } from "react-table";
import { TableFooter } from "../../../Shared/Search/ResultsTable/TableFooter";
import { SearchCompany } from "../../../Shared/Search/Filters/FilterTypes";
import { DetailViewLinkButton } from "../../../../Component/Button/LinkButton";

const COLUMNS: Column<SearchCompany>[] = [
  {
    Header: "Ettevõtte nimi",
    accessor: "name",
    width: 100,
  },
  {
    Header: "Ettevõtte registrikood",
    accessor: "registryCode",
  },
  {
    Header: "Ettevõtte esindajad",
    accessor: "representatives",
    Cell: ({ value }) => (
      <>{value.map((r) => r.firstName + " " + r.lastName).join(", ")}</>
    ),
  },
  {
    Header: "Seotud tegevusload",
    accessor: "activityLicenseNumbers",
    Cell: ({ value }) => <>{value.join(", ")}</>,
  },
  {
    Header: "",
    accessor: "id",
    Cell: ({ value }) => (
      <div className="link-column">
        <DetailViewLinkButton to={`/companies/${value}`} />
      </div>
    ),
  },
];

interface Props {
  data: SearchCompany[];
  pageCount: number;
  pageIndex: number;
}

export const CompanyResults: FC<Props> = ({ data, pageCount, pageIndex }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
  } = useTable<SearchCompany>(
    {
      columns: COLUMNS,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  return (
    <div className="table-wrap">
      <div className="scrollable">
        <table {...getTableProps()} className="s-table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, j) => (
                  <th
                    className="normal-column"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-result">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, j) => (
                    <td
                      className="normal-column"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
