import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch } from "react-redux";
import { AxiosPromise } from "axios";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import {
  RadioButtonElement,
  RadioGroup
} from "../../../../Component/Radio/RadioGroup";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import { AttachmentContainer } from "../../../Shared/Application/Education/Manual/Attachment/AttachmentContainer";
import {
  FileReferenceDto,
  fileToRef
} from "../../../../Dto/File/FileReference";
import "./InvalidationReasonModal.scss";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { displayAlert } from "../../../../Util/AlertUtil";
import {
  FileObjectType,
  FileReference,
  InvalidationCreationReasonEnum,
  InvalidationCreation
} from "../../../../../api_client/medre_api";

interface Props {
  isOpen: boolean;
  title: string;
  endpoint: (
    id: string,
    invalidation: InvalidationCreation,
    file: any[],
    options?: any
  ) => AxiosPromise<string>;
  objectId: string;
  onClose: () => void;
  fileObjectType: FileObjectType;
  onSubmitSuccess: (id: string) => void;
}

export const InvalidationReasonModal: FC<Props> = ({
  objectId,
  isOpen,
  title,
  endpoint,
  onClose,
  fileObjectType,
  onSubmitSuccess
}) => {
  const dispatch = useDispatch();

  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [fileReferences, setFileReferences] = useState<FileReferenceDto[]>([]);

  const defaultValues: InvalidationCreation = {
    reason: InvalidationCreationReasonEnum.Application
  };

  const { handleSubmit, watch, errors, control, formState } =
    useForm<InvalidationCreation>({
      defaultValues,
      mode: "onChange"
    });
  const { touched, isSubmitted, isSubmitSuccessful, isValid } = formState;
  const watchReason = watch("reason");
  const radioButtons = useMemo<RadioButtonElement[]>(
    () => [
      {
        id: "application",
        value: InvalidationCreationReasonEnum.Application,
        labelText: "Sooviavaldus"
      },
      {
        id: "courtDecision",
        value: InvalidationCreationReasonEnum.CourtDecision,
        labelText: "Kohtu otsus"
      },
      {
        id: "businessRegistry",
        value: InvalidationCreationReasonEnum.BusinessRegistry,
        labelText: "Äriregistri andmete alusel"
      }
    ],
    []
  );

  const onSubmit = useCallback(
    (values: InvalidationCreation) => {
      endpoint(
        objectId!,
        values,
        fileReferences.map((fileReference) => fileReference.file),
        {
          withCredentials: true
        }
      )
        .then((res) => {
          displayAlert("applicationSubmitted", AlertType.Success, dispatch);
          onClose();
          onSubmitSuccess(res.data);
        })
        .catch((_) => {
          displayAlert(
            "activityLicenseSubmitFailed",
            AlertType.Danger,
            dispatch
          );
        });
    },
    [fileReferences, endpoint, onClose, onSubmitSuccess, dispatch]
  );

  const handleFileAdd = (files: File[]) => {
    setFileReferences((prevState) => [
      ...prevState,
      ...files.map((f) => fileToRef(f, fileObjectType))
    ]);
  };

  const handleFileDelete = (fileName: string) => {
    setFileReferences((prevState: FileReference[]) =>
      prevState.filter((f) => f.fileName !== fileName)
    );
  };

  const requiredFilesPresent = () => fileReferences.length > 0;

  const validateCourtFields = (value: string) =>
    watchReason === InvalidationCreationReasonEnum.CourtDecision
      ? Boolean(value)
      : true;

  return (
    <Modal isOpen={isOpen} id="invalidation-reason-modal">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader tag="h4" className="p-0 mb-4">
          {title}
        </ModalHeader>
        <ModalBody className="p-0">
          <dl>
            <dt>
              <Label>Põhjus</Label>
            </dt>
            <dd>
              <Controller
                name="reason"
                control={control}
                defaultValue={defaultValues.reason}
                rules={{ required: true }}
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <RadioGroup
                    className="m-0"
                    options={radioButtons}
                    name="reason"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </dd>
            {watchReason === InvalidationCreationReasonEnum.CourtDecision && (
              <>
                <dt>
                  <Label>Kohtu nimi*</Label>
                </dt>
                <dd>
                  <Controller
                    name="courtName"
                    control={control}
                    defaultValue={""}
                    rules={{ validate: validateCourtFields }}
                    render={({
                      value,
                      onChange
                    }: ControllerRenderProps<Record<string, any>>) => (
                      <Input
                        name="courtName"
                        value={value}
                        onChange={onChange}
                        valid={
                          ((isSubmitted && !isSubmitSuccessful) ||
                            touched.courtName) &&
                          !errors.courtName
                        }
                        invalid={!!errors.courtName}
                      />
                    )}
                  />
                </dd>
                <dt>
                  <Label>Kohtuotsuse number*</Label>
                </dt>
                <dd>
                  <Controller
                    name="courtDecisionNumber"
                    control={control}
                    defaultValue={""}
                    rules={{ validate: validateCourtFields }}
                    render={({
                      value,
                      onChange
                    }: ControllerRenderProps<Record<string, any>>) => (
                      <Input
                        name="courtDecisionNumber"
                        value={value}
                        onChange={onChange}
                        valid={
                          ((isSubmitted && !isSubmitSuccessful) ||
                            touched.courtDecisionNumber) &&
                          !errors.courtDecisionNumber
                        }
                        invalid={!!errors.courtDecisionNumber}
                      />
                    )}
                  />
                </dd>
              </>
            )}
          </dl>
          <AttachmentContainer
            type={FileObjectType.ActivityLicenseInvalidationDocument}
            isActive={isPopOverOpen}
            onClick={() => setIsPopOverOpen(!isPopOverOpen)}
            fileReferences={fileReferences}
            maxFilesCount={5}
            saveFiles={handleFileAdd}
            handleFileDelete={handleFileDelete}
          />
        </ModalBody>
        <ModalFooter className="p-0">
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
          />
          <PrimaryFormattedButton
            id="invalidate"
            disabled={!requiredFilesPresent() || !isValid}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
