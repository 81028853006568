import { useCallback, useState } from "react";

export const useFieldFocused = () => {
    const [isFocused, setFocused] = useState(false);

  const handleFocus = useCallback(() => {
      setFocused(true);
  }, [setFocused]);

  const handleBlur = useCallback(() => {
      setFocused(false);
  }, [setFocused]);

  return { isFocused, handleFocus, handleBlur };
};