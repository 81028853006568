import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import "../../../Shared/Application/Application.scss";
import "../../../Shared/ActivityLicense/ActivityLicense.scss";
import { Loader } from "../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { ApplicationNotes } from "../../Application/Details/ApplicationNotes";
import { ActivityLicenseDetailsHistory } from "../Application/Details/ActivityLicenseDetailsHistory";
import { ApplicationDecisions } from "../../Application/Details/ApplicationDecisions";
import { ActivityLicenseInvalidationDetailsActions } from "./ActivityLicenseInvalidationDetailsActions";
import { useDetailedApplication } from "../Details/ActivityLicenseApplicationDetailsHook";
import { QuickLinks } from "../../../../Component/QuickLinks/QuickLinks";
import { DetailsCard } from "./DetailsCard";
import { useInvalidationQuickLinks } from "./InvalidationQuickLinks";
import {
  DetailedActivityLicenseInvalidation,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI
} from "../../../../../api_client/medre_api";
import { LocationAccordion } from "../Details/LocationAccordion";
import { getBaseUrl, API } from "../../../../api";

export const ActivityLicenseInvalidationDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, setIsLoading, details, setDetails, refresh } =
    useDetailedApplication<DetailedActivityLicenseInvalidation>(
      officialActivityPermitAPI(undefined, getBaseUrl(), API)
        .getActivityLicenseInvalidation3,
      id
    );
  const { quickLinks } = useInvalidationQuickLinks();

  if (!details || isLoading) {
    return <Loader />;
  }

  return (
    <div className="application-container application-detail activity-license-details-container">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details.status}
          isStateFeePaid={true}
          isDetailed={true}
        />
      </div>
      <div className="ml-3 activity-license-quick-links">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <ActivityLicenseInvalidationDetailsActions
          details={details}
          setDetails={setDetails}
          refresh={refresh}
          setIsLoading={setIsLoading}
        />
      </Card>
      <DetailsCard
        company={details.company}
        submittingPersonFirstName={details.submittingPersonFirstName}
        submittingPersonLastName={details.submittingPersonLastName}
        submittingPersonIdCode={details.submittingPersonIdCode}
        licenseNumber={details.licenseNumber || ""}
        licenseId={details.licenseId || ""}
        businessAreaName={details.businessAreaName}
        locations={details.locations}
        isHospital={details.isHospital}
        fileReferences={details.fileReferences || []}
        isLicenseInvalidation={true}
      />
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="locations" /> Tegevuskohad ja teenused
        </h2>
        <LocationAccordion
          activityLocations={details.locations || []}
          isHospital={false}
          isEditable={false}
        />
      </Card>
      <ApplicationNotes
        notes={details.notes || ""}
        setDetails={setDetails}
        endpoint={
          officialActivityPermitAPI(undefined, getBaseUrl(), API)
            .updateActivityLocationSuspensionNote1
        }
        detailsId={details.id}
      />
      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="resolutions" /> Otsused
          <ApplicationDecisions decisions={details.decisions || []} />
        </h2>
      </Card>
      <ActivityLicenseDetailsHistory
        auditLogItems={details.auditLogItems || []}
      />
    </div>
  );
};
