import React, { FC } from "react";
import { ApplicationDetailsInfoCard } from "../../../Component/Card/ApplicationDetailsInfoCard";
import { ClickableInfoValue } from "../../../Component/QuickLinks/ClickableInfoValue";
import { useHistory } from "react-router";

interface Props {
  licenseNumber: string;
  licenseId: string;
}

export const ClickableLicenseNumberCard: FC<Props> = ({licenseNumber, licenseId}) => {
  const history = useHistory();
  const onLicenseClick = () => {
    if (licenseId) {
      history.push(`/activity-licenses/${licenseId}`);
    }
  };

  return (
    <ApplicationDetailsInfoCard
      title="Tegevusluba"
      content={
        [
          {key: "Tegevusluba", value: <ClickableInfoValue name={licenseNumber || ""} onClick={onLicenseClick}/>},
        ]
      }
    />
  );
};