import React, {
  useCallback,
  useState,
  SyntheticEvent,
  useMemo,
  useEffect
} from "react";
import { CustomInput } from "reactstrap";

export interface Option {
  value: string;
  label: string | JSX.Element;
}

export type Options = Option[];
export type ObjectValue = { [key: string]: string };
export type StringValue = string;
export type Value = ObjectValue | StringValue;
export type Values = Value[];

export interface Props {
  name: string;
  options: Options;
  onChange?: (values: Values) => void;
  defaultValue?: Values;
  value?: Values;
  valuesKey?: string;
  inline?: boolean;
  invalid?: boolean;
  className?: string;
}

const mapValues = (values: Values, valuesKey?: string) =>
  values.map((value: Value) =>
    typeof value !== "string" && typeof valuesKey !== "undefined"
      ? value[valuesKey]
      : value
  );

export function CheckboxGroup({
  name,
  options,
  onChange,
  defaultValue,
  value,
  valuesKey,
  inline,
  invalid,
  className
}: Props) {
  const [values, setValues] = useState<Values>((defaultValue || value) ?? []);

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [value]);

  const mappedValues = useMemo(
    () => mapValues(values, valuesKey),
    [values, valuesKey]
  );

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      const { checked, value } = event.target as HTMLInputElement;
      const nextValues = mappedValues.filter(
        (prevValue) => prevValue !== value
      );
      if (checked) {
        nextValues.push(value);
      }
      setValues(nextValues);
      if (onChange) {
        onChange(nextValues);
      }
    },
    [mappedValues, onChange]
  );

  return (
    <>
      {options.map((option: Option) => (
        <CustomInput
          key={option.value}
          id={`${name}_${option.value}`}
          value={option.value}
          label={option.label}
          type="checkbox"
          onChange={handleChange}
          checked={mappedValues.includes(option.value)}
          inline={inline ?? false}
          invalid={invalid}
          className={className}
        />
      ))}
    </>
  );
}
