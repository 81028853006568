import React, { FC, useCallback } from "react";
import { getDecisionInfo } from "./DecisionRowUtil";
import { handleFileDownload } from "../../../Util/FileUtils";
import { Decision } from "../../../../api_client/medre_api";
import { LinkButton } from "../../../Component/Button/LinkButton";

export const DecisionDownloadButton: FC<{ decision: Decision }> = ({
  decision
}) => {
  const { signed, fileName, minioFileName, minioContainerName } = decision;

  let { downloadText, name, minioId } = getDecisionInfo(
    signed,
    fileName as string,
    minioContainerName as string,
    minioFileName as string
  );

  const handleClick = useCallback(async () => {
    await handleFileDownload(name, minioId);
  }, [minioId, name]);

  return <LinkButton id={downloadText} className="p-0" onClick={handleClick} />;
};
