import { ReactElement } from "react";

import { PowerOfAttorney } from "../../../../../api_client/medre_api";

export interface RepresentativeData {
  personCode?: string;
  personFirstName?: string;
  personEmailAddress?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
  id?: string;
  sticky?: string;
  personLastName?: string;
  createdBy?: string;
}

export interface RepresentativeListProps {
  repData: PowerOfAttorney[];
  pageIndex: number;
  pageCount: number;
  rowCount: number;
  loading: boolean;
  registryCode?: string;
  reFetchAndUpdateData: () => void;
  title?: ReactElement | string;
  containerClass?: string;
  addBtnClass?: string;
  isPortal?: boolean;
}

export enum RepresentativeModalMode {
  Add = "ADD",
  Edit = "EDIT"
}

export interface RepresentativeModalType {
  data?: PowerOfAttorney;
  isOpen: boolean;
  mode?: RepresentativeModalMode;
}
