import {
  DetailedActivityLicenseApplicationProceedingTypeEnum,
  DecisionType
} from "../../api_client/medre_api";

export const getDecisionTypeFromProceedingType = (
  proceedingType:
    | DetailedActivityLicenseApplicationProceedingTypeEnum
    | undefined
): DecisionType => {
  if (!proceedingType) {
    return DecisionType.ActivityLicenseApplication;
  }
  switch (proceedingType) {
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLicense:
      return DecisionType.ActivityLicense;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
      return DecisionType.ActivityLicenseSuspension;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration:
      return DecisionType.ActivityLicenseRestoration;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation:
      return DecisionType.ActivityLicenseInvalidation;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocation:
      return DecisionType.AddLocation;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
      return DecisionType.ActivityLocationSuspension;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration:
      return DecisionType.ActivityLicenseRestoration;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation:
      return DecisionType.ActivityLocationInvalidation;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.Service:
      return DecisionType.AddService;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
      return DecisionType.ServiceSuspension;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration:
      return DecisionType.ServiceRestoration;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard:
      return DecisionType.DiscardService;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition:
      return DecisionType.LicenseAcquisition;
    case DetailedActivityLicenseApplicationProceedingTypeEnum.GeneralPractitionerApplication:
      return DecisionType.GeneralPractitionerListApplication;
    default:
      return DecisionType.ActivityLicenseApplication;
  }
};
