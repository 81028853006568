import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { AttachmentButton } from "./AttachmentButton";
import { AttachmentFileList } from "./AttachmentFileList";
import { FileDropZone } from "../../../../../../Component/FileDropzone/FileDropZone";
import "./AttachmentContainer.scss";
import {
  FileObjectType,
  FileReference,
  OfficialUserPrivilegesEnum
} from "../../../../../../../api_client/medre_api";
import { ProtectedFieldWrapper } from "../../../../../../Security/ProtectedFieldWrapper";

interface Props {
  type: FileObjectType;
  isActive: boolean;
  onClick: (fileType: FileObjectType) => void;
  updateAreFilesValid?: (isValid: boolean) => void;
  fileReferences: FileReference[];
  maxFilesCount: number;
  saveFiles: (files: File[]) => void;
  handleFileDelete: (fileName: string) => void;
  allowedRoles?: OfficialUserPrivilegesEnum[];
  allowedFileTypes?: string[];
  handlePopOvertoggle?: (value: boolean) => void;
}

export const AttachmentContainer = ({
  type,
  updateAreFilesValid,
  fileReferences,
  isActive,
  onClick,
  maxFilesCount,
  saveFiles,
  allowedFileTypes,
  allowedRoles,
  handleFileDelete,
  handlePopOvertoggle
}: Props) => {
  const filesExist = useMemo(
    () => fileReferences && fileReferences.length > 0,
    [fileReferences]
  );

  const [mandatoryFilesIncluded, setMandatoryFilesIncluded] =
    useState<boolean>(filesExist);

  useEffect(() => {
    setMandatoryFilesIncluded(fileReferences && fileReferences.length > 0);
  }, [fileReferences]);

  useEffect(() => {
    if (!!updateAreFilesValid) {
      updateAreFilesValid(mandatoryFilesIncluded);
    }
  }, [updateAreFilesValid, mandatoryFilesIncluded]);

  const handleChangeActiveAttachmentType = useCallback(() => {
    onClick(type);
  }, [type, onClick]);

  const attachmentButtonDisabled = useMemo(() => {
    return filesExist && fileReferences?.length === maxFilesCount;
  }, [filesExist, fileReferences, maxFilesCount]);

  const attachmentButtonRef = useRef<HTMLButtonElement>(null);
  const attachmentButton = useMemo(
    () => (
      <AttachmentButton
        ref={attachmentButtonRef}
        changeActiveAttachmentType={handleChangeActiveAttachmentType}
        type={type}
        disabled={attachmentButtonDisabled}
      />
    ),
    [
      attachmentButtonRef,
      handleChangeActiveAttachmentType,
      type,
      attachmentButtonDisabled
    ]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className="d-block" ref={containerRef}>
      {isActive && (
        <FileDropZone
          container={containerRef}
          toggleButton={attachmentButtonRef}
          type={type}
          fileReferences={fileReferences}
          maxFilesCount={maxFilesCount}
          saveFiles={saveFiles}
          allowedFileTypes={allowedFileTypes}
          togglePopOver={handlePopOvertoggle}
        />
      )}
      {allowedRoles ? (
        <ProtectedFieldWrapper allowedRoles={allowedRoles}>
          {attachmentButton}
        </ProtectedFieldWrapper>
      ) : (
        attachmentButton
      )}
      {filesExist && (
        <AttachmentFileList
          files={fileReferences}
          isDeletingPossible={true}
          handleFileDelete={handleFileDelete}
        />
      )}
    </div>
  );
};
