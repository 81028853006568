import React from "react";
import { Card, Col, Row } from "reactstrap";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../../../../Locale/LocaleMessage";

type SpecialitySelectionMessageCardType =
  | "noOccupations"
  | "noSpecialities"
  | "possessed"
  | "applied"
  | "appliedOfficial"
  | "saved"
  | "savedOfficial";

interface Props {
  messageType: SpecialitySelectionMessageCardType;
}

export const SpecialitySelectionMessageCard = ({ messageType }: Props) => {
  const messages: Record<SpecialitySelectionMessageCardType, LocaleMessage> =
    defineMessages({
      noOccupations: {
        id: "specialitySelectionMessageCard.noOccupations",
        defaultMessage: "Puuduvad kutsed, et eriala taotleda."
      },
      possessed: {
        id: "specialitySelectionMessageCard.possessed",
        defaultMessage: "Valitud eriala on juba omandatud."
      },
      applied: {
        id: "specialityMessageCard.applied",
        defaultMessage:
          'Valitud eriala taotlus on Teil esitatud. Eriala taotluse hetkeseisu saate jälgida "Minu taotlused" lehelt.'
      },
      appliedOfficial: {
        id: "specialityMessageCard.appliedOfficial",
        defaultMessage: "Valitud eriala taotlus on esitatud."
      },
      saved: {
        id: "specialityMessageCard.saved",
        defaultMessage:
          'Valitud eriala taotlus on Teil pooleli. Poolelioleva eriala taotluse leiate "Minu taotlused" lehelt.'
      },
      savedOfficial: {
        id: "specialityMessageCard.savedOfficial",
        defaultMessage: "Valitud eriala taotlus on pooleli."
      },
      noSpecialities: {
        id: "specialitySelectionMessageCard.midwife",
        defaultMessage: "Valitud kutsel puuduvad erialad."
      }
    });

  return (
    <Row>
      <Col className="offset-md-1 offset-lg-2" lg={7} md={9} xs={12}>
        <Card className="p-3 w-100 d-flex align-items-center justify-content-center info-card">
          <FormattedMessage {...messages[messageType]} />
        </Card>
      </Col>
    </Row>
  );
};
