import * as React from "react";
import { getAuthor, HistoryCard } from "../../../History/HistoryCard";
import { utcToLocal } from "../../../../../Util/DateUtils";
import {
  AuditLogItem,
  ActivityLicenseApplicationStatus,
  AuditLogItemActionTypeEnum
} from "../../../../../../api_client/medre_api";

interface Props {
  auditLogItems: AuditLogItem[];
}

export const ActivityLicenseDetailsHistory = ({ auditLogItems }: Props) => {
  const translateStatus = (oldStatusName: string) => {
    switch (oldStatusName) {
      case ActivityLicenseApplicationStatus.Submitted:
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return "Esitatud";
      case ActivityLicenseApplicationStatus.Accepted:
        return "Rahuldatud";
      case ActivityLicenseApplicationStatus.ProceedingPending:
        return "Menetluse ootel";
      case ActivityLicenseApplicationStatus.InProceeding:
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
      case ActivityLicenseApplicationStatus.SignDeclinedDecision:
      case ActivityLicenseApplicationStatus.SignReopened:
      case ActivityLicenseApplicationStatus.ProceedingReopened:
        return "Menetluses";
      case ActivityLicenseApplicationStatus.Paused:
        return "Menetlus peatatud";
      case ActivityLicenseApplicationStatus.OnSiteInspectionPending:
        return "Paikvaatluse ootel";
      case ActivityLicenseApplicationStatus.ProtocolInPreparation:
        return "Protokoll koostamisel";
      case ActivityLicenseApplicationStatus.ReadyForDecision:
        return "Otsuse koostamiseks valmis";
      case ActivityLicenseApplicationStatus.Declined:
        return "Keeldutud";
      case ActivityLicenseApplicationStatus.Finished:
        return "Lõpetatud";
      case ActivityLicenseApplicationStatus.Registering:
        return "Registreerimisel";
      case ActivityLicenseApplicationStatus.Saved:
        return "Salvestatud";
      default:
        return "Menetluse ootel";
    }
  };

  const createActionContent = (item: AuditLogItem, oldStatusName: string) => {
    if (item.actionType === AuditLogItemActionTypeEnum.ChangeStatus) {
      return (
        <>
          <span>Taotluse staatus muutus </span>
          <span className="d-inline-block">
            {`${translateStatus(oldStatusName)} -> ${translateStatus(
              item.dataObjectValue!
            )}`}
          </span>
        </>
      );
    } else if (
      item.actionType === AuditLogItemActionTypeEnum.ForwardToOfficial
    ) {
      return (
        <>
          <span>Taotlus suunati menetlejale {item.dataObjectValue}</span>
        </>
      );
    }
  };

  const createHistoryRow = (
    item: AuditLogItem,
    oldStatusName: string,
    i: number
  ) => {
    return (
      <tr key={i}>
        <td>{utcToLocal(item.createdAt).format("DD.MM.YYYY HH:mm")}</td>
        <td>{createActionContent(item, oldStatusName)}</td>
        <td>{getAuthor(item)}</td>
      </tr>
    );
  };

  return (
    <HistoryCard
      createHistoryRow={createHistoryRow}
      auditLogItems={auditLogItems}
    />
  );
};
