import React, { FC } from "react";
import "../PreviousNames/PreviousNamesDetails.scss";
import ApplicantDetailsSection from "../ApplicantDetailsSection";
import { ApplicantDetailsSectionKeys } from "../ApplicantNavigationList";
import { MigratedDecree } from "../../../../../api_client/medre_api";

interface Props {
  migratedDecrees?: MigratedDecree[];
}

export const MigratedDecrees: FC<Props> = ({ migratedDecrees }) => {
  return migratedDecrees ? (
    <ApplicantDetailsSection
      section={ApplicantDetailsSectionKeys.MigratedDecrees}
    >
      <div className="mt-4">
        {migratedDecrees.map((decree) => (
          <div key={decree.id}>{decree.fileName}</div>
        ))}
      </div>
    </ApplicantDetailsSection>
  ) : null;
};
