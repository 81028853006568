import * as React from "react";
import * as Types from "../../../Shared/Search/Filters/FilterTypes";
import { TimePeriodFilter } from "./TimePeriodFilter";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterSelectors } from "../../../Shared/Search/Filters/filterStore";
import {
  filterActions,
  FilterItemData,
} from "../../../Shared/Search/Filters/filterActions";
import {
  FilterContainer,
  FilterColumn,
} from "../../../../Component/Filter/Filter";

const { CurrentYear } = Types.RelativeTimes;
export const ApplicationCertificateFilters = () => {
  const initialValue = useMemo(
    () => ({ value: CurrentYear, label: CurrentYear }),
    []
  );

  const state = useSelector(
    filterSelectors.selectApplicationCertificateFilters
  );
  const dispatch = useDispatch();
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setApplicationCertificateFilter({ key, data }));
    },
    [dispatch]
  );

  return (
    <FilterContainer>
      <FilterColumn>
        <TimePeriodFilter
          id="applicationCertificateFilters"
          set={set}
          state={state}
          initialValue={initialValue}
        />
      </FilterColumn>
      <FilterColumn />
      <FilterColumn />
    </FilterContainer>
  );
};
