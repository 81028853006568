import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MinusIcon } from "../../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../../Component/Icon/PlusIcon";
import { ServiceRow } from "../../Locations/ServiceRow";
import { activityLicenseTableActions } from "../../../../../ActivityLicense/activityLicenseTableActions";
import { renderAccordionHeaderRow } from "../../../../../Component/Accordion/AccordionHeaderRowUtil";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import { RootState } from "../../../../../rootReducer";
import {
  ActivityLicenseService,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../../api";

interface Props {
  activityLocationName: string | undefined;
  services: ActivityLicenseService[] | undefined;
  refresh: () => void;
}

export const DiscardServicesRows = ({
  activityLocationName,
  services,
  refresh
}: Props) => {
  const widthSize = useWindowWidthSize();
  const dispatch = useDispatch();

  const [isServicesRowOpen, setIsServicesRowOpen] = useState(false);

  const expandedRowNumber = useSelector(
    (rootState: RootState) => rootState.activityLicenseTable.expandedLocationRow
  );

  const handleServiceRowClick = (rowNumber: number) => {
    if (expandedRowNumber !== rowNumber) {
      dispatch(activityLicenseTableActions.setExpandedLocationRow(rowNumber));
    } else {
      dispatch(activityLicenseTableActions.setExpandedLocationRow(null));
    }
  };

  const renderRow = (icon: JSX.Element) =>
    renderAccordionHeaderRow(
      widthSize,
      icon,
      <span>{activityLocationName}</span>
    );

  const renderServicesRow = () => (
    <div className="detail-col pl-0 pr-0">
      {services?.map((service, i) => (
        <ServiceRow
          serviceId={service.id!}
          serviceName={service.service?.name || "-"}
          key={i}
          isOpen={expandedRowNumber === i}
          index={i}
          handleRowClick={handleServiceRowClick}
          loadServiceEndpoint={
            activityPermitAPI(undefined, getBaseUrl(), API).getService1
          }
          isDiscardServiceEnabled={false}
          isAddSpecialistEnabled={false}
          isRemoveEmployeeEnabled={false}
          isAddTHTEnabled={false}
          isServiceSuspendable={false}
          refresh={refresh}
          isHospital={false}
          servicesToDiscard={[]}
          servicesToSuspend={[]}
        />
      ))}
    </div>
  );

  return (
    <>
      <div
        className={`cursor-pointer accordion-header`}
        onClick={() => setIsServicesRowOpen(!isServicesRowOpen)}
      >
        {isServicesRowOpen ? renderRow(<MinusIcon />) : renderRow(<PlusIcon />)}
      </div>
      {isServicesRowOpen && renderServicesRow()}
    </>
  );
};
