import { FormattedMessage } from "react-intl";
import { Card, Row } from "reactstrap";
import { WizardProgressBar } from "../../../../Component/ProgressBar/WizardProgressBar";
import { activityLicenseSteps } from "../../../Shared/ActivityLicense/ActivityLicenseStep";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { ActivityLicenseSubmissionTimeLine } from "./ActiviyLicenseSubmissionTimeLine";
import { StateFeeFM } from "../../../../Messages/StateFeeFM";
import { StateFeeInstructions } from "../../../Shared/StateFee/StateFeeInstructions";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import React from "react";
import { useHistory } from "react-router-dom";
import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { MOBILE_MAX_WIDTH } from "../../../../Constants";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  StateFee
} from "../../../../../api_client/medre_api";
import { activityLicenseServiceSteps } from "../../../Shared/ActivityLicense/Services/ActivityLicenseServiceStep";

interface Props {
  applicationNumber: string | number | undefined;
  stateFee: StateFee | undefined;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
}

export const SubmittedApplication = ({
  applicationNumber,
  stateFee,
  proceedingType
}: Props) => {
  const history = useHistory();
  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  const steps =
    proceedingType === ActivityLicenseApplicationProceedingTypeEnum.Service
      ? activityLicenseServiceSteps
      : activityLicenseSteps;

  return (
    <div className="application-container">
      <h1 className="ml-3 mt-3">
        <FormattedMessage
          id="submittedApplication.title"
          defaultMessage="Sinu taotlus {applicationNumber} jõudis meieni"
          values={{ applicationNumber: applicationNumber }}
        />
      </h1>

      <Card>
        <div className="m-4">
          <WizardProgressBar locked={true} formComplete={true} steps={steps} />
          <div className="submission-info-container">
            <h5 className="fw-normal sea-green-10">
              <GeneralFM id="thankYou" />!
            </h5>
            <p>
              <FormattedMessage
                id="submittedApplication.applicationReceived"
                defaultMessage="Sinu taotlus jõudis meieni!"
              />
            </p>
            <p>
              <FormattedMessage
                id="submittedApplication.informationText"
                defaultMessage="  "
              />
            </p>
            <ActivityLicenseSubmissionTimeLine isPaid={stateFee?.paid} />
            <h5 className="fw-normal mt-4">
              <StateFeeFM id="stateFeeInstructionHeader" />
            </h5>
            <StateFeeInstructions
              deadline={stateFee?.stateFeeDeadline}
              referenceNumber={stateFee?.referenceNumber}
              stateFeeValue={stateFee?.stateFeeValue}
            />
          </div>
          <hr className="mt-5 application-footer-hr" />
          <Row
            className={isMobile ? "justify-content-center" : "m-0 float-right"}
          >
            <PrimaryFormattedButton
              id="backToLandingPage"
              onClick={() => history.push("/")}
            />
          </Row>
        </div>
      </Card>
    </div>
  );
};
