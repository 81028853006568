import React from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";

import { EducationFM } from "../../../../../Messages/EducationFM";

interface Props {
  id: string;
  value?: string | Date;
  className?: string;
  valueClassName?: string;
}

export const EducationInfoViewRow = ({ id, value, className, valueClassName }: Props) => (
  <Row className={`${className} education-info-view-row`}>
    <Col className="text-right" xs={6}>
      <EducationFM id={id} />
    </Col>
    <Col xs={6} className={valueClassName}>
      {value instanceof Date ? moment(value).format("DD.MM.YYYY") : value}
    </Col>
  </Row>
);
