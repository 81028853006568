import validator from "validator";

export const isInsertedEmailValid = (email?: string) => {
  let regExp = /^([\w.%+-]{1,64})@([\w-]{1,254}\.)+([\w]{2,})$/i;
  return email !== null && regExp.test(email!);
};

export const validateOptionalFieldLengthEqual = (
  length: number,
  field?: string
) => {
  let isLengthOptions = { min: length, max: length };
  return field
    ? validator.isLength(field, isLengthOptions) && field.trim() !== ""
    : undefined;
};

export const validateLength = (
  field?: string,
  options?: validator.IsLengthOptions
) =>
  field
    ? validator.isLength(field, {
        min: options?.min ?? 1,
        max: options?.max ?? 255,
      }) && field.trim() !== ""
    : false;

export const validateOptionalFieldLength = (
  field?: string,
  options?: validator.IsLengthOptions
) => field ? validateLength(field, options) : undefined;

export const validateOptionalFieldNumber = (field?: string) => {
  return field ? validator.isNumeric(field) : undefined;
};

export const validateNumber = (field?: string) => {
  return field ? validator.isNumeric(field) : false;
};

export const validateURL = (field?: string) => {
  return field ? validator.isURL(field) : false;
};

// tslint:disable-next-line:no-any
export const validateArray = (array?: any[]) =>
  Boolean(Array.isArray(array) && array.length);
