import { defineMessages } from "react-intl";

import {
  Application,
  ProceedingStatusStatusEnum,
  ActivityLicenseApplicationChannelTypeEnum,
  ApplicationDraft
} from "../../../api_client/medre_api";

export const applicationChannelTypes = defineMessages({
  SELF_SERVICE: {
    id: "ApplicationChannelType.SELF_SERVICE",
    defaultMessage: "Iseteenindus"
  },
  E_MAIL: {
    id: "ApplicationChannelType.E_MAIL",
    defaultMessage: "E-mail"
  },
  MAIL: {
    id: "ApplicationChannelType.MAIL",
    defaultMessage: "Tavapost"
  },
  DELIVERED: {
    id: "ApplicationChannelType.DELIVERED",
    defaultMessage: "Kohale toimetatud"
  }
});

export const getCurrentStatus = (
  application: ApplicationDraft | Application
) => {
  if ("currentStatus" in application) {
    return application.currentStatus;
  }
  return application.channelType ===
    ActivityLicenseApplicationChannelTypeEnum.SelfService
    ? { status: ProceedingStatusStatusEnum.Saved }
    : { status: ProceedingStatusStatusEnum.Registering };
};
