import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBaseUrl, API } from "../../../../api";
import { displayAlert } from "../../../../Util/AlertUtil";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { ButtonName } from "../../../../Component/Button/FormattedButton";
import { SignActions } from "../Application/Details/Sign/SignActions";
import { AcceptanceActions } from "../Application/Details/Acceptance/AcceptanceActions";
import { DecisionActions } from "../Application/Details/Decision/DecisionActions";
import {
  FileObjectType,
  ActivityLicenseApplicationStatus,
  DetailedActivityLicenseRestoration,
  ActivityLicenseRestorationTypeEnum,
  AmetnikuTegevuskohtadeTaastamiseTeenusApiFactory as officialActivityPlaceAPI,
  AmetnikuTegevuslubadeTeenusteTeenusApiFactory as officialActivityPermitAPI,
  AmetnikuTegevuslubadeTaastamiseTeenusApiFactory as officialActivityLicenseAPI,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityAppAPI,
  OtsusteTeenusApiFactory as decisionServiceAPI,
  DecisionType,
  RestorationStatus
} from "../../../../../api_client/medre_api";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { alertActions } from "../../../../Alert/alertActions";

interface Props {
  details?: DetailedActivityLicenseRestoration;
  setDetails: (restoration: DetailedActivityLicenseRestoration) => void;
  refresh: () => void;
  setIsLoading: (value: boolean) => void;
}

export const RestorationDetailsActions = ({
  details,
  setDetails,
  refresh,
  setIsLoading
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getDecisionType = () => {
    switch (details?.type) {
      case ActivityLicenseRestorationTypeEnum.ActivityLocation:
        return "ACTIVITY_LOCATION_RESTORATION";
      case ActivityLicenseRestorationTypeEnum.Service:
        return "SERVICE_RESTORATION";
      case ActivityLicenseRestorationTypeEnum.ActivityLicense:
      default:
        return "ACTIVITY_LICENSE_RESTORATION";
    }
  };

  const changeStatus = (
    newStatus: ActivityLicenseApplicationStatus | RestorationStatus
  ) => {
    switch (details?.type) {
      case ActivityLicenseRestorationTypeEnum.ActivityLocation:
        return officialActivityPlaceAPI(
          undefined,
          getBaseUrl(),
          API
        ).changeLocationRestorationStatus(
          details!.id!,
          newStatus as RestorationStatus,
          {
            withCredentials: true
          }
        );
      case ActivityLicenseRestorationTypeEnum.Service:
        return officialActivityPermitAPI(
          undefined,
          getBaseUrl(),
          API
        ).changeLicenseRestorationStatus1(
          details!.id!,
          newStatus as RestorationStatus,
          {
            withCredentials: true
          }
        );
      case ActivityLicenseRestorationTypeEnum.ActivityLicense:
      default:
        return officialActivityLicenseAPI(
          undefined,
          getBaseUrl(),
          API
        ).changeLicenseRestorationStatus(
          details!.id!,
          newStatus as RestorationStatus,
          {
            withCredentials: true
          }
        );
    }
  };

  const changeStatusTo = (newStatus: ActivityLicenseApplicationStatus) => {
    setIsLoading(true);
    changeStatus(newStatus)
      .then((res) => {
        setDetails(res.data);
        displayAlert("statusUpdateSuccess", AlertType.Success, dispatch);
      })
      .catch(() =>
        displayAlert("statusUpdateFailure", AlertType.Danger, dispatch)
      )
      .finally(() => setIsLoading(false));
  };

  const renderSignButtons = (signButtonId: ButtonName) => {
    return (
      <SignActions
        signButtonId={signButtonId}
        refresh={refresh}
        changeStatusTo={changeStatusTo}
        decisionId={details?.decisions?.find((d) => !d.signed)?.id!}
      />
    );
  };

  const deleteApplication = (applicationId: string) => {
    officialActivityAppAPI(undefined, getBaseUrl(), API)
      .deleteApplicationByProceedingType(
        applicationId,
        details?.proceedingType!,
        {
          withCredentials: true
        }
      )
      .then(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteSuccess" />
        );
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
        history.push("/my-proceedings");
      })
      .catch(() => {
        const alertMessage = (
          <AlertMessage id="applicationDraftDeleteFailure" />
        );
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      });
  };

  const renderContent = () => {
    if (!details) {
      return;
    }
    switch (details?.status) {
      case ActivityLicenseApplicationStatus.SubmittedByOfficial:
        return (
          <AcceptanceActions
            applicationNumber={details.applicationNumber}
            changeStatusTo={changeStatusTo}
            newAcceptanceStatus={
              ActivityLicenseApplicationStatus.UploadAcceptedDecision
            }
            canDecline={false}
            applicationId={details.id}
            deleteApplication={deleteApplication}
          />
        );
      case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
        return (
          <DecisionActions
            fileObjectType={FileObjectType.ActivityLicenseDecision}
            decisionUploadEndpoint={
              decisionServiceAPI(undefined, getBaseUrl(), API)
                .assignDecisionToSigner
            }
            decision={{
              id: details.id!,
              type: getDecisionType() as DecisionType
            }}
            updateApplication={refresh}
          />
        );
      case ActivityLicenseApplicationStatus.SignAcceptedDecision:
        return renderSignButtons("signApplicationAcceptanceDecision");
      default:
        return;
    }
  };

  return (
    <>
      <div className="mt-1 mb-4">{renderContent()}</div>
    </>
  );
};
