import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import "./ContactInfoEdit.scss";
import { ContactInfoEditFooter } from "./ContactInfoEditFooter";
import { RootState } from "../../../../rootReducer";
import { InitialContactInfoEditCard } from "./InitialContactInfoEditCard";
import { ContactInfoEditForm } from "./ContactInfoEditForm";
import { ContactInfo } from "../../../../../api_client/medre_api";
import { ContactInfoForm } from "../../../../Dto/ContactInfo/ContactInfoForm";

interface Props {
  setIsEditActive: (isActive: boolean) => void;
}

export const ContactInfoEdit = ({ setIsEditActive }: Props) => {
  const contactInfo = useSelector(
    (state: RootState) => state.person.contactInfo || ({} as ContactInfo)
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isForceValidation, setIsForceValidation] = useState<boolean>(false);
  const [isInitialEdit] = useState(!contactInfo.email);
  const [formInfo, setFormInfo] = useState(new ContactInfoForm(contactInfo));

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      setFormInfo((prevState) => {
        return { ...prevState, [field]: value };
      });
    },
    []
  );

  return (
    <div className="form-modal">
      {isInitialEdit && <InitialContactInfoEditCard />}
      <ContactInfoEditForm
        formInfo={formInfo}
        updateContactInfo={updateContactInfo}
        setIsFormValid={setIsFormValid}
        isForceValidation={isForceValidation}
      />
      <hr className="separator mt-5 ml-5 mr-5 mb-4" />
      <ContactInfoEditFooter
        formInfo={formInfo}
        isInitialEdit={isInitialEdit}
        isFormValid={isFormValid}
        setIsForceValidation={setIsForceValidation}
        setIsEditActive={setIsEditActive}
      />
    </div>
  );
};
