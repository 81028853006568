import React from "react";
import { useSelector } from "react-redux";
import "../../Style/Dashboard.scss";
import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../../rootReducer";

export const OfficialDashboard = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  return (
    <>
      <div className="dashboard-banner justify-content-center">
          <span className="banner-text">
            {userInfo && (
              <FormattedMessage
                id="officialDashboard.welcomeMessage"
                defaultMessage={`Tere tulemast Terviseameti registrite portaali, {userName}!`}
                values={{userName: userInfo.name}}
              />
            )}
          </span>
      </div>
      <Card className="create-proceeding-card"/>
    </>
  );
};
