import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./ApplicantDetails.scss";
import { ApplicantPersonalDetails } from "./ApplicantPersonalDetails/ApplicantPersonalDetails";
import { API, getBaseUrl } from "../../../api";
import { HealthCareProfessionalDetails } from "./HealthCareProfessionalDetails";
import { Loader } from "../../../Component/Loader/Loader";
import { ApplicantContactInfo } from "./ContactInfo/ApplicantContactInfo";
import { ApplicantApplications } from "./ApplicantApplication";
import { PreviousNamesDetails } from "./PreviousNames/PreviousNamesDetails";
import { ExternalDecisions } from "./ExternalDecision/ExternalDecisions";
import { PrescriptionPowerDetails } from "./PrescriptionPower/PrescriptionPowerDetails";
import { ConfirmationStickyFooter } from "../../Footer/ConfirmationStickyFooter";
import {
  ApplicantChanges,
  SpecialNurseChanges
} from "../../../Dto/Applicant/ApplicantChanges";
import { SuspensionDetails } from "./Suspension/SuspensionDetails";
import { PrescriptivePowerSpeciality } from "../../../Dto/Application/Speciality";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import { MigratedDocuments } from "./Migration/MigratedDocuments";
import { Educations } from "./Migration/Educations";
import { applicationDraftActions } from "../../../Application/applicationDraftActions";
import { personActions } from "../../../Person/personActions";
import { ApplicantCertificates } from "./Certificates/ApplicantCertificates";
import ApplicantCompetencies from "./Competencies/ApplicantCompetencies";
import {
  useApplications,
  ApplicationsContext
} from "../../Shared/Application/ApplicationsContext";
import {
  ApplicationCertificateProvider,
  useApplicationCertificateState
} from "../../Shared/ApplicationCertificate/ApplicationCertificateContext";
import { QuickLinks } from "../../../Component/QuickLinks/QuickLinks";
import { APPLICANT_DETAILS_SECTIONS_LIST } from "./ApplicantNavigationList";
import {
  Person,
  PersonSuspension,
  ContactInfo,
  PreviousName,
  ExternalDecision,
  KasutajaAndmeteTeenusApiFactory as userDataAPI,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as userOfficialDataAPI,
  PersonalData,
  Speciality,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  AmetnikuSertifikaadiAndmeteenusApiFactory as officialCertificateDataAPI,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataServiceAPI,
  TaotluseAndmeteTeenusApiFactory as appDataAPI,
  PersonStatusEnum
} from "../../../../api_client/medre_api";
import { MigratedDecrees } from "./Migration/MigratedDecrees";
import { displayAlertWithValues } from "../../../Util/AlertUtil";
import { RootState } from "../../../rootReducer";
import { utc, useToday } from "../../../Util/DateUtils";
import { Option } from "../../../Component/Select/SingleSelect";
import {
  getCompetencieSpecialities,
  getFilteredOccupations,
  getPersonSpecialitiesByNameAndCode
} from "../../../Util/PersonUtils";

export const ApplicantDetails = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [person, setPerson] = useState<Person | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [personCompetenceSpecialities, setPersonCompetenceSpecialities] =
    useState<Option[]>([]);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const applicationsState = useApplications(
    officialAppDataAPI(undefined, getBaseUrl(), API).getPersonApplications,
    id
  );

  const { fetchApplications } = applicationsState;
  const certificateRequestState = useApplicationCertificateState(
    officialCertificateDataAPI(undefined, getBaseUrl(), API)
      .getApplicationCertificateMetadata,
    id
  );
  const [isForceValidation, setIsForceValidation] = useState<boolean>(false);
  const [applicantChanges, setApplicantChanges] = useState<ApplicantChanges>(
    {}
  );
  const [specialNurseChanges, setSpecialNurseChanges] =
    useState<SpecialNurseChanges>({});

  const today = useToday();

  const specialNurseReadyForSave = useCallback(
    () =>
      moment(
        specialNurseChanges.prescriptivePowerSpeciality?.prescriptionPowerDate
      ).isSame(today) ||
      (specialNurseChanges.prescriptivePowerSpeciality?.prescriptionPowerDate &&
        specialNurseChanges.prescriptivePowerSpeciality
          ?.prescriptionPowerNumber &&
        specialNurseChanges.prescriptivePowerSpeciality
          ?.prescriptionPowerDocument),
    [specialNurseChanges]
  );

  const hasChanges =
    applicantChanges.previousName ||
    applicantChanges.personalData ||
    applicantChanges.externalDecision ||
    applicantChanges.prescriptivePowerSpeciality ||
    specialNurseReadyForSave();

  const setPersonData = useCallback(async () => {
    try {
      const personData = (
        await userDataAPI(undefined, getBaseUrl(), API).getPerson1(id, {
          withCredentials: true
        })
      ).data;
      setPerson(personData);
      return Promise.resolve(personData);
    } catch (error) {
      if ((error as Record<string, any>).response.status !== 400) {
        history.push("/");
      }
    }
  }, [id, history]);

  const handleCertificateUpdate = useCallback(async () => {
    await setPersonData();
    await fetchApplications();
  }, [setPersonData, fetchApplications]);

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([
      setPersonData(),
      appDataAPI(undefined, getBaseUrl(), API).getOccupations1(undefined, {
        withCredentials: true
      })
    ]).then(([personData, occupations]) => {
      const filteredOccupations = occupations?.data
        ? getFilteredOccupations(occupations.data)
        : [];
      let competencieSpecialities = getCompetencieSpecialities(
        filteredOccupations,
        personData?.occupationCodes
      );

      if (personData?.status === PersonStatusEnum.Registered) {
        const personSpecialitiesByNameAndCode =
          getPersonSpecialitiesByNameAndCode(personData?.specialities);

        competencieSpecialities = !!personSpecialitiesByNameAndCode?.length
          ? competencieSpecialities.concat(
            personSpecialitiesByNameAndCode as Option[]
          )
          : competencieSpecialities;
      }

      setPersonCompetenceSpecialities(competencieSpecialities);
    });
  }, [setPersonData]);

  const savePersonalData = (personalData: PersonalData) => {
    userOfficialDataAPI(undefined, getBaseUrl(), API)
      .updatePerson2(id, personalData, {
        withCredentials: true
      })
      .then((response) => {
        setPerson(response.data);
        setApplicantChanges((prevState) => ({
          ...prevState,
          personalData: undefined
        }));
        const alertMessage = <AlertMessage id="personalDataChangeSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      });
  };

  const savePreviousName = (previousName: PreviousName) => {
    userOfficialDataAPI(undefined, getBaseUrl(), API)
      .addPreviousName(person!.id as string, previousName, {
        withCredentials: true
      })
      .then((response) => {
        setPerson(response.data);
        setApplicantChanges((prevState) => ({
          ...prevState,
          previousName: undefined
        }));
        const alertMessage = <AlertMessage id="previousNameChangeSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      });
  };

  const saveExternalDecision = (externalDecision: ExternalDecision) => {
    userOfficialDataAPI(undefined, getBaseUrl(), API)
      .addExternalDecision(
        person!.id as string,
        { ...externalDecision, createdAt: utc().format() },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setPerson(response.data);
        setApplicantChanges((prevState) => ({
          ...prevState,
          externalDecision: undefined
        }));
        const alertMessage = <AlertMessage id="externalDecisionAddSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      });
  };

  function savePrescriptivePower(
    prescriptivePowerSpeciality: PrescriptivePowerSpeciality
  ) {
    userOfficialDataAPI(undefined, getBaseUrl(), API)
      .addPrescriptivePower(
        person!.id as string,
        prescriptivePowerSpeciality as Speciality,
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setPerson(response.data);
        setApplicantChanges((prevState) => ({
          ...prevState,
          prescriptivePowerSpeciality: undefined
        }));
        const alertMessage = <AlertMessage id="prescriptivePowerAddSuccess" />;
        const alert = { id: 0, type: AlertType.Success, message: alertMessage };
        return dispatch(alertActions.addAlert(alert));
      });
  }

  const makeRrRequest = async () => {
    try {
      setLoading(true);
      const { data } = await officialUserDataServiceAPI(
        undefined,
        getBaseUrl(),
        API
      ).getPersonInfoFromPersonalCode(
        person?.foreigner ? person.foreignIdCode! : person?.idCode!,
        person?.foreigner ? person?.foreignIdCodeCountryId! : "EST",
        {
          withCredentials: true
        }
      );
      const fetchedPersonData = data.person;
      setPerson(fetchedPersonData);
      await officialUserDataServiceAPI(
        undefined,
        getBaseUrl(),
        API
      ).updatePerson2(
        fetchedPersonData?.id!,
        fetchedPersonData as PersonalData,
        {
          withCredentials: true
        }
      );
    } catch (error) {
      displayAlertWithValues(
        "rrRequestFailed",
        { id: userInfo?.userId },
        AlertType.Danger,
        dispatch
      );
    } finally {
      setLoading(false);
    }
  };

  const saveSpecialPrescriptivePower = () => {
    let alertMessage = (
      <AlertMessage id="specialNursePrescriptionAddedSuccessfully" />
    );
    let alert = { id: 0, type: AlertType.Success, message: alertMessage };
    setLoading(true);
    userOfficialDataAPI(undefined, getBaseUrl(), API)
      .addSpecialPrescriptivePower(
        person!.id!,
        specialNurseChanges.prescriptivePowerSpeciality as Speciality,
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setPerson(response.data);
        setSpecialNurseChanges({});
      })
      .catch(() => {
        alertMessage = (
          <AlertMessage id="specialNursePrescriptionAddedFailed" />
        );
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      })
      .finally(() => {
        dispatch(alertActions.addAlert(alert));
        setLoading(false);
      });
  };

  const handleSave = () => {
    const {
      personalData,
      previousName,
      externalDecision,
      prescriptivePowerSpeciality
    } = applicantChanges;
    if (personalData) {
      const isValid =
        personalData.firstName &&
        personalData.lastName &&
        personalData.gender &&
        personalData.citizenship &&
        personalData.dateOfBirth;
      if (isValid) {
        savePersonalData(personalData);
      } else {
        setIsForceValidation(true);
        setTimeout(() => setIsForceValidation(false), 500);
      }
    }
    if (previousName) {
      const isValidPreviousName =
        previousName.firstName &&
        previousName.lastName &&
        previousName.fileReferences &&
        previousName.fileReferences.length > 0;
      if (isValidPreviousName) {
        savePreviousName(previousName);
      } else {
        setIsForceValidation(true);
        setTimeout(() => setIsForceValidation(false), 500);
      }
    }
    if (externalDecision) {
      saveExternalDecision(externalDecision);
    }
    if (prescriptivePowerSpeciality) {
      const isValidPrescriptivePower =
        prescriptivePowerSpeciality.prescriptionPowerDate &&
        prescriptivePowerSpeciality.prescriptionPowerDocument &&
        prescriptivePowerSpeciality.prescriptionPowerNumber;

      if (isValidPrescriptivePower) {
        savePrescriptivePower(prescriptivePowerSpeciality);
      } else {
        setIsForceValidation(true);
        setTimeout(() => setIsForceValidation(false), 500);
      }
    }
    if (specialNurseChanges.prescriptivePowerSpeciality) {
      saveSpecialPrescriptivePower();
    }
  };

  const updateContactInfo = (contactInfo: ContactInfo) => {
    setPerson((prevState) => {
      return {
        ...prevState,
        contactInfo
      };
    });
  };

  const updateSuspensions = (suspensions: PersonSuspension[]) => {
    setPerson((prevState) => ({
      ...prevState,
      suspensions
    }));
  };

  const handleNewApplication = useCallback(() => {
    let applicationDraft: Record<string, any> = {};
    let personalData: Record<string, any> = { ...person } as PersonalData;
    personalData.id = person?.id;
    (applicationDraft.personalData as PersonalData) = personalData;
    applicationDraft.personId = person?.id;
    dispatch(applicationDraftActions.setApplicationDraft(applicationDraft));
    dispatch(personActions.setPerson(person!));
    history.push("/new-application");
  }, [history, dispatch, person]);

  const refreshPersonData = async () => {
    setLoading(true);
    await setPersonData();
    setLoading(false);
  };

  const handleConfirmationDiscard = () => {
    setApplicantChanges({});
    setSpecialNurseChanges({});
  };

  if (!person || loading) {
    return <Loader absolute backdrop />;
  }

  return (
    <ApplicationsContext.Provider value={applicationsState}>
      <div className="applicant-container">
        <div className="d-flex align-items-center mx-3 my-4">
          <h1 className="mb-0 mr-3">
            {`${person?.firstName} ${person?.lastName}`} (
            {person?.idCode ?? person?.foreignIdCode})
          </h1>
        </div>
        <div className="ml-3">
          <QuickLinks links={APPLICANT_DETAILS_SECTIONS_LIST} />
        </div>
        <ApplicantPersonalDetails
          loading={loading}
          person={person}
          applicantChanges={applicantChanges}
          setApplicantChanges={setApplicantChanges}
          forceValidation={isForceValidation}
          reFetchAndUpdatePersonData={refreshPersonData}
          handleRrRequest={makeRrRequest}
        />
        <HealthCareProfessionalDetails person={person} refresh={refreshPersonData}/>
        <ApplicantApplications onCreateNewApplication={handleNewApplication} />
        <ApplicantContactInfo
          personId={person.id}
          personIdCode={person.idCode}
          contactInfo={person.contactInfo}
          handleSave={updateContactInfo}
        />
        <PreviousNamesDetails
          person={person}
          applicantChanges={applicantChanges}
          setApplicantChanges={setApplicantChanges}
          forceValidation={isForceValidation}
        />
        <ExternalDecisions
          person={person}
          applicantChanges={applicantChanges}
          setApplicantChanges={setApplicantChanges}
        />
        <PrescriptionPowerDetails
          person={person}
          applicantChanges={applicantChanges}
          setApplicantChanges={setApplicantChanges}
          forceValidation={isForceValidation}
          specialNurseChanges={specialNurseChanges}
          setSpecialNurseChanges={setSpecialNurseChanges}
        />
        <Educations person={person} refreshEducations={setPersonData}/>
        <MigratedDocuments migratedDocuments={person.migratedDocuments} />
        <MigratedDecrees migratedDecrees={person.migratedDecrees} />
        <ApplicationCertificateProvider value={certificateRequestState}>
          <ApplicantCertificates
            person={person}
            certificates={person.certificates ?? []}
            afterCertificateUpdate={handleCertificateUpdate}
          />
        </ApplicationCertificateProvider>
        {person.id ? (
          <ApplicantCompetencies
            personId={person.id}
            personCompetenceSpecialities={personCompetenceSpecialities}
          />
        ) : null}
        <SuspensionDetails
          personId={person.id!}
          updateSuspensions={updateSuspensions}
          updatePerson={setPerson}
          personStatus={person.status!}
          suspensions={person.suspensions}
          refresh={setPersonData}
        />
        {hasChanges && (
          <ConfirmationStickyFooter
            handleConfirm={handleSave}
            handleDiscard={handleConfirmationDiscard}
          />
        )}
      </div>
    </ApplicationsContext.Provider>
  );
};
