import React from "react";

import { useWindowWidthSize } from "../../../../Hook/useWindowsSize";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { CompanyApplicationRowUtil } from "./CompanyApplicationRowUtil";
import { MinusIcon } from "../../../../Component/Icon/MinusIcon";
import { PlusIcon } from "../../../../Component/Icon/PlusIcon";
import "./CompanyApplicationRow.scss";
import { ActivityLicenseApplicationAccordion } from "./ActivityLicenseApplicationAccordion";
import { LicenseAcquisitionAccordion } from "../LicenseAcquisition/LicenseAcqusitionAccordion";
import { SuspensionAccordion } from "../Suspension/SuspensionAccordion";
import { InvalidationAccordion } from "../Invalidation/InvalidationAccordion";
import { DiscardServiceAccordion } from "../Service/Discard/DiscardServiceAccordion";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLicenseApplicationStatus,
  CompanyApplication,
  TeenusteTaastamisteTaotlusteTeenusApiFactory as serviceRestorationRequestAPI,
  TegevuskohtadeTaastamisteTaotlusteTeenusApiFactory as businessRestorationRequestAPI,
  TegevuslubadeTaastamisteTaotlusteTeenusApiFactory as renewalActivityApplicationServiceApi,
  TegevuslubadePeatamisteTaotlusteTeenusApiFactory as suspensionActivityApplicationServiceApi,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import { RestorationAccordion } from "../Restoration/RestorationAccordion";
import { getBaseUrl, API } from "../../../../api";

interface Props {
  application: CompanyApplication;
  isOpen: boolean;
  index: number;
  handleRowClick: (index: number) => void;
  handleDelete: (rowNumber: number) => void;
  reloadApplications: () => void;
}

export const CompanyApplicationRow = ({
  application,
  isOpen,
  index,
  handleRowClick,
  handleDelete,
  reloadApplications
}: Props) => {
  const {
    arrivalTime,
    proceedingType,
    applicationStatus,
    applicationNumber,
    proceedingDeadline,
    businessArea,
    stateFeeDeadline,
    stateFeePaid
  } = application;

  const className = isOpen ? "expanded" : "";
  const widthSize = useWindowWidthSize();
  const arrivalTimeString =
    applicationStatus === ActivityLicenseApplicationStatus.Saved
      ? "Salvestatud"
      : formatDate(arrivalTime);
  const state = (
    <ActivityLicenseApplicationState
      currentStatus={applicationStatus as ActivityLicenseApplicationStatus}
      index={index}
      isDetailed={true}
      isStateFeePaid={stateFeePaid}
    />
  );

  const renderRowContent = (icon: JSX.Element) => {
    return CompanyApplicationRowUtil(
      widthSize,
      icon,
      state,
      arrivalTimeString,
      proceedingType,
      proceedingDeadline ? new Date(proceedingDeadline) : undefined,
      stateFeeDeadline ? new Date(stateFeeDeadline) : undefined,
      applicationNumber,
      businessArea,
      applicationStatus
    );
  };

  const renderContent = () => {
    switch (application.proceedingType) {
      case ActivityLicenseApplicationProceedingTypeEnum.LicenseAcquisition:
        return (
          <LicenseAcquisitionAccordion
            isOpen={isOpen}
            id={application.id}
            handleDelete={() => handleDelete(index)}
            proceedingType={application.proceedingType}
            reloadApplications={reloadApplications}
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseSuspension:
        return (
          <SuspensionAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
            suspensionEndpointRef={
              suspensionActivityApplicationServiceApi(
                undefined,
                getBaseUrl(),
                API
              ).getLicenseSuspension1
            }
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationSuspension:
        return (
          <SuspensionAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
            suspensionEndpointRef={
              activityPermitAPI(undefined, getBaseUrl(), API)
                .getLocationSuspension
            }
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceSuspension:
        return (
          <SuspensionAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
            suspensionEndpointRef={
              activityPermitAPI(undefined, getBaseUrl(), API)
                .getServiceSuspension
            }
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseInvalidation:
        return (
          <InvalidationAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationInvalidation:
        return (
          <InvalidationAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceDiscard:
        return (
          <DiscardServiceAccordion
            isOpen={isOpen}
            id={application.id}
            proceedingType={application.proceedingType}
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicense:
      default:
        return (
          <ActivityLicenseApplicationAccordion
            isOpen={isOpen}
            applicationId={application.id}
            proceedingType={application.proceedingType}
            handleDelete={() => handleDelete(index)}
            reloadApplications={reloadApplications}
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLicenseRestoration:
        return (
          <RestorationAccordion
            isOpen={isOpen}
            id={application.id!}
            proceedingType={application.proceedingType!}
            restorationEndpointRef={
              renewalActivityApplicationServiceApi(undefined, getBaseUrl(), API)
                .getLicenseSuspension
            }
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ActivityLocationRestoration:
        return (
          <RestorationAccordion
            isOpen={isOpen}
            id={application.id!}
            proceedingType={application.proceedingType!}
            restorationEndpointRef={
              businessRestorationRequestAPI(undefined, getBaseUrl(), API)
                .getLocationRestoration
            }
          />
        );
      case ActivityLicenseApplicationProceedingTypeEnum.ServiceRestoration:
        return (
          <RestorationAccordion
            isOpen={isOpen}
            id={application.id!}
            proceedingType={application.proceedingType!}
            restorationEndpointRef={
              serviceRestorationRequestAPI(undefined, getBaseUrl(), API)
                .getServiceRestoration
            }
          />
        );
    }
  };

  if (isOpen) {
    return (
      <>
        <tr
          className={`${className} cursor-pointer`}
          onClick={() => handleRowClick(index)}
        >
          {renderRowContent(
            <button className="btn-unstyled d-inline-block">
              <MinusIcon />
            </button>
          )}
        </tr>
        {renderContent()}
      </>
    );
  }

  return (
    <tr
      className={`${className} cursor-pointer`}
      onClick={() => handleRowClick(index)}
    >
      {renderRowContent(
        <button className="btn-unstyled d-inline-block">
          <PlusIcon />
        </button>
      )}
    </tr>
  );
};
