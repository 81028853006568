import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card } from "reactstrap";

import { Loader } from "../../../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationState } from "../../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import { DetailsCard } from "../DetailsCard";
import { ApplicationNotes } from "../../../../Application/Details/ApplicationNotes";
import { LocationAccordion } from "../../../Details/LocationAccordion";
import { ActivityLicenseDetailsHistory } from "../../../Application/Details/ActivityLicenseDetailsHistory";
import { ServiceSuspensionDetailsActions } from "./ServiceSuspensionDetailsActions";
import { QuickLinks } from "../../../../../../Component/QuickLinks/QuickLinks";
import {
  DetailedServiceSuspension,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI
} from "../../../../../../../api_client/medre_api";
import { useDetailedApplication } from "../../../Details/ActivityLicenseApplicationDetailsHook";
import { getBaseUrl, API } from "../../../../../../api";
import { ApplicationDecisions } from '../../../../Application/Details/ApplicationDecisions';

export const ServiceSuspensionDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, setIsLoading, details, setDetails, refresh } =
    useDetailedApplication<DetailedServiceSuspension>(
      officialActivityPermitAPI(undefined, getBaseUrl(), API)
        .getDetailedActivityLicenseServiceSuspension,
      id
    );

  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      {
        id: "history",
        title: "Menetluse ajalugu"
      }
    ],
    []
  );

  if (!details || isLoading) {
    return <Loader />;
  }

  return (
    <div className="application-container activity-license-details-container">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details?.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details?.currentStatus}
          isDetailed={false}
        />
      </div>
      <div className="ml-3">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <ServiceSuspensionDetailsActions
          refresh={refresh}
          setDetails={setDetails}
          details={details}
          setIsLoading={setIsLoading}
        />
      </Card>
      <DetailsCard
        details={details!}
        locations={details?.location ? [details?.location] : []}
        title="Teenuse peatamise taotluse detailid"
      />
      <ApplicationNotes
        notes={details?.notes || ""}
        setDetails={setDetails}
        endpoint={
          officialActivityPermitAPI(undefined, getBaseUrl(), API)
            .updateServiceSuspensionNote
        }
        detailsId={details?.id}
      />
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="locations" /> Tegevuskohad ja teenused
        </h2>
        <div className="service-details-table-container">
          <LocationAccordion
            activityLocations={[details?.location!]}
            isHospital={details.isHospital!}
            isEditable={false}
          />
        </div>
      </Card>
        <Card id="decisions-card" className="mx-3 my-4">
            <h2>
                <span id="resolutions" /> Otsused
                <ApplicationDecisions decisions={details.decisions || []} />
            </h2>
        </Card>
      <ActivityLicenseDetailsHistory auditLogItems={details?.auditLogItems!} />
    </div>
  );
};
