import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";

import { formatProceedingType } from "../../../../Dto/ActivityLicense/ActivityLicenseProceeding";
import { getBaseUrl, API } from "../../../../api";
import { Loader } from "../../../../Component/Loader/Loader";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import {
  formatDate,
  renderOverViewRow
} from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { CompanyInfoRow } from "../Applications/CompanyInfoRow";
import { ActivityLocationsRow } from "../../../Shared/ActivityLicense/Locations/ActivityLocationsRow";
import { FilesRow } from "../Applications/FilesRow";
import { DecisionsRow } from "../Applications/DecisionsRow";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLocation,
  ActivityLicenseInvalidation,
  ActivityLocationInvalidation,
  TegevuslubadeKehtetuksTunnistamiseTeenusIseteenindusesApiFactory as revocationActivityPermitAPI,
  TegevuslubadeTeenusApiFactory as activityPermitAPI
} from "../../../../../api_client/medre_api";
import { ActivityLicenseApplicationState } from "../../../Shared/ActivityLicense/ActivityLicenseApplicationState";

interface Props {
  isOpen?: boolean;
  id?: string;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
}

export const InvalidationAccordion = ({
  isOpen,
  id,
  proceedingType
}: Props) => {
  const [invalidation, setInvalidation] = useState<
    ActivityLicenseInvalidation | ActivityLocationInvalidation
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isApplicantInfoRowOpen, setIsApplicantInfoRowOpen] = useState(false);
  const [isActivityLocationsRowOpen, setIsActivityLocationsRowOpen] =
    useState(false);
  const [isFilesRowOpen, setIsFilesRowOpen] = useState(false);
  const [isDecisionsRowOpen, setIsDecisionsRowOpen] = useState(false);

  const getLocations = () => {
    let locations: ActivityLocation[] = [];

    if (invalidation && "location" in invalidation) {
      locations = [invalidation.location!];
    }
    if (invalidation && "locations" in invalidation) {
      locations = invalidation.locations!;
    }
    return locations;
  };
  const loadInvalidation = useCallback(() => {
    setIsLoading(true);
    revocationActivityPermitAPI(undefined, getBaseUrl(), API)
      .getActivityLicenseInvalidation1(id as string, {
        withCredentials: true
      })
      .then((res) => setInvalidation(res.data))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      loadInvalidation();
    }
  }, [isOpen, loadInvalidation]);

  if (isLoading) {
    return <Loader backdrop={true} />;
  }

  const state = (
    <ActivityLicenseApplicationState
      currentStatus={invalidation?.status}
      isDetailed={false}
    />
  );

  return (
    <tr className="detail-info">
      <td colSpan={7} className="pt-0 pb-0">
        <div className="detail-col pl-0 pr-0">
          <Row className="col-header">
            <GeneralFM id="applicationData" />
          </Row>
          {renderOverViewRow(
            "activityLicense",
            "proceedingType",
            formatProceedingType(proceedingType!)
          )}
          {renderOverViewRow(
            "activityLicense",
            "applicationNumber",
            invalidation?.applicationNumber?.toString()
          )}
          {renderOverViewRow(
            "activityLicense",
            "licenseNumber",
            invalidation?.licenseNumber
          )}
          {renderOverViewRow(
            "activityLicense",
            "submissionDate",
            formatDate(invalidation?.createdAt)
          )}
          {renderOverViewRow("application", "state", state)}
          {renderOverViewRow(
            "activityLicense",
            "businessArea",
            invalidation?.businessAreaName
          )}
        </div>
        <CompanyInfoRow
          isOpen={isApplicantInfoRowOpen}
          handleClick={() => setIsApplicantInfoRowOpen(!isApplicantInfoRowOpen)}
          company={invalidation?.company}
          submittingPerson={
            invalidation?.submittingPersonFirstName +
            " " +
            invalidation?.submittingPersonLastName
          }
          submittingPersonIdCode={invalidation?.submittingPersonIdCode}
        />
        <ActivityLocationsRow
          isOpen={isActivityLocationsRowOpen}
          handleClick={() =>
            setIsActivityLocationsRowOpen(!isActivityLocationsRowOpen)
          }
          activityLocations={getLocations()}
          isHospital={Boolean(invalidation?.isHospital)}
          loadServiceEndpoint={
            activityPermitAPI(undefined, getBaseUrl(), API).getService1
          }
          isApplicationView
        />
        <FilesRow
          isOpen={isFilesRowOpen}
          handleClick={() => setIsFilesRowOpen(!isFilesRowOpen)}
          documents={invalidation?.fileReferences || []}
        />
        <DecisionsRow
          isOpen={isDecisionsRowOpen}
          handleClick={() => setIsDecisionsRowOpen(!isDecisionsRowOpen)}
          decisions={invalidation?.decisions}
        />
      </td>
    </tr>
  );
};
