import React from "react";
import { useSelector } from "react-redux";

import { PrimaryBadge } from "../Component/Badge/PrimaryBadge";
import { WarningBadge } from "../Component/Badge/WarningBadge";
import { SuccessBadge } from "../Component/Badge/SuccessBadge";
import { DangerBadge } from "../Component/Badge/DangerBadge";
import {
  Application,
  ProceedingStatusStatusEnum
} from "../../api_client/medre_api";
import { RootState } from "../rootReducer";

interface Props {
  application?: Application;
  index?: number;
  isDetailed?: boolean;
}

export const ApplicationState = ({ application, index, isDetailed }: Props) => {
  const isPortal = useSelector((state: RootState) => state.config.isPortal);

  switch (application?.currentStatus?.status) {
    case ProceedingStatusStatusEnum.Submitted:
      if (application?.stateFee?.paid) {
        return <PrimaryBadge id={`submitted-${index}`} message="submitted" />;
      }
      return (
        <WarningBadge
          id={`stateFeeNotPaid-${index}`}
          message="stateFeeNotPaid"
        />
      );
    case ProceedingStatusStatusEnum.InProceeding:
      return (
        <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
      );
    case ProceedingStatusStatusEnum.UploadAcceptedDecision:
    case ProceedingStatusStatusEnum.UploadDeclinedDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="decisionPending"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.UploadAptitudeTestDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingAptitudeDecisionSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="waitingForAptitudeDecisionSignature"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.UploadAptitudeTestResult:
      return (
        <>
          <WarningBadge
            id={`directedToAptitudeTest-${index}`}
            message="directedToAptitudeTest"
          />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="decisionPending"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.UploadComplianceExamDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForComplianceDecisionSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="waitingForComplianceDecisionSignature"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.UploadComplianceExamResult:
      return (
        <>
          <WarningBadge
            id={`directedToComplianceExam-${index}`}
            message="directedToComplianceExam"
          />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className={isDetailed ? "ml-4" : ""}
              message="decisionPending"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.SignAcceptedDecision:
    case ProceedingStatusStatusEnum.SignDeclinedDecision:
    case ProceedingStatusStatusEnum.SignAptitudeTestDecision:
    case ProceedingStatusStatusEnum.SignComplianceExamDecision:
      return (
        <>
          <WarningBadge id={`inProceeding-${index}`} message="inProceeding" />
          {isDetailed && (
            <WarningBadge
              id={`waitingForSignature-${index}`}
              className="ml-4"
              message="waitingForSignature"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.SignComplianceExamResult:
      return (
        <>
          <WarningBadge
            id={`directedToComplianceExam-${index}`}
            message="directedToComplianceExam"
          />
          {isDetailed && !isPortal && (
            <WarningBadge
              id={`accept-${index}`}
              className="ml-4"
              message="accept"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.SignAptitudeTestResult:
      return (
        <>
          <WarningBadge
            id={`directedToAptitudeTest-${index}`}
            message="directedToAptitudeTest"
          />
          {isDetailed && (
            <WarningBadge
              id={`accept-${index}`}
              className="ml-4"
              message="accept"
            />
          )}
        </>
      );
    case ProceedingStatusStatusEnum.Paused:
      return <WarningBadge id={`paused-${index}`} message="paused" />;
    case ProceedingStatusStatusEnum.PausedDueToComplianceExam:
      return (
        <WarningBadge
          id={`directedToComplianceExam-${index}`}
          message="directedToComplianceExam"
        />
      );
    case ProceedingStatusStatusEnum.PausedDueToAptitudeTest:
      return (
        <WarningBadge
          id={`directedToAptitudeTest-${index}`}
          message="directedToAptitudeTest"
        />
      );
    case ProceedingStatusStatusEnum.Accepted:
      return <SuccessBadge id={`accepted-${index}`} message="accepted" />;
    case ProceedingStatusStatusEnum.Declined:
      return <DangerBadge id={`declined-${index}`} message="declined" />;
    case ProceedingStatusStatusEnum.Deleted:
      return <DangerBadge id={`deleted-${index}`} message="deleted" />;
    case ProceedingStatusStatusEnum.Finished:
      return <DangerBadge id={`finished-${index}`} message="finished" />;
    case ProceedingStatusStatusEnum.Registering:
      return <PrimaryBadge id={`registering-${index}`} message="registering" />;

    default:
      return <PrimaryBadge id={`saved-${index}`} message="saved" />;
  }
};
