import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {SecondaryFormattedButton} from "../../../../Component/Button/SecondaryFormattedButton";
import {PrimaryFormattedButton} from "../../../../Component/Button/PrimaryFormattedButton";
import "./AddEducationModal.scss";
import {LinkButton} from "../../../../Component/Button/LinkButton";
import {EhisEducationViewCard} from "./Automatic/EhisEducationViewCard";
import {
  Education,
  EducationInfo,
  EducationPrescriptionResultEnum,
  EducationSourceEnum,
  EHISEHaridusteabeToomineMisPoleEestiKoodigaIsikuJaoksVeelSalvestatudApiFactory as ehisEducationInfoApi,
  HariduseSalvestamineIsikuandmeteJaoksApiFactory as saveEhisEducationAPI,
  KustutabAntudIdGaHariduseApiFactory as deleteEducationAPI, Person,
} from "../../../../../api_client/medre_api";
import {CustomRadioButton} from "../../../../Component/Radio/CustomRadioButton";
import {API, getBaseUrl} from "../../../../api";
import {Loader} from "../../../../Component/Loader/Loader";
import {EhisEducationNotFoundCard} from "./Automatic/EhisEducationNotFoundCard";
import {useDispatch} from "react-redux";
import {ManualEducationModal} from "./Manual/ManualEducationModal";
import {displayAlert} from "../../../../Util/AlertUtil";
import {AlertType} from "../../../../Dto/Alert/AlertItem";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  person: Person
  editEducation?: Education;
}

export const AddEducationModal = ({
                                    isOpen,
                                    onClose,
                                    person,
                                    editEducation
                                  }: Props) => {
  const dispatch = useDispatch();
  const [ehisEducationList, setEhisEducationList] =
    useState<Array<EducationInfo>>([]);
  const [education, setEducation] = useState<Education>();
  const [loading, setLoading] = useState(false);
  const [selectedEducationDiplomaNo, setSelectedEducationDiplomaNo] = useState<string>();
  const [manualEducationModalOpen, setManualEducationModalOpen] = useState(false);
  const [saveAllowed, setSaveAllowed] = useState(false);
  const [blankEducationCreated, setBlankEducationCreated] = useState(false);


  useEffect(() => {
    if (isOpen && editEducation && editEducation.id) {
      setEducation(editEducation);
      setManualEducationModalOpen(true)
    } else if (isOpen) {
      setLoading(true)
      setManualEducationModalOpen(false)
      setEducation(undefined)
      loadEhisEducation();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedEducationDiplomaNo) {
      setSaveAllowed(true);
    } else {
      setSaveAllowed(false);
    }
  }, [selectedEducationDiplomaNo]);

  useEffect(() => {
    if (ehisEducationList.length === 1) {
      setSelectedEducationDiplomaNo(ehisEducationList[0].diplomaNumber);
    }
  }, [ehisEducationList]);

  const fromEducationInfoToEducation = (educationInfo: EducationInfo): Education => {
    return {
      countryCode: educationInfo.countryCode,
      facilityName: educationInfo.schoolName,
      acquiredDate: educationInfo.diplomaDate,
      documentType: "", // You need to define the appropriate property for documentType
      curriculumName: educationInfo.curriculumName,
      source: EducationSourceEnum.EhisAddedManually, // Set an appropriate value or enum
      specialization: educationInfo.specialization,
      curriculumCode: educationInfo.curriculumCode,
      documentNumber: educationInfo.diplomaNumber,
      fileReferences: [], // Set an appropriate default value
    };
  }

  const onSaveEhisEducation = (educationInfo: EducationInfo) => {
    if (person.id && educationInfo) {
      saveEhisEducationAPI(undefined, getBaseUrl(), API)
        .createEducation(person.id, fromEducationInfoToEducation(educationInfo))
        .then((response) => {
          displayAlert(
            getSaveEducationIdAlertMessage(response.data),
            AlertType.Success,
            dispatch);
          setSelectedEducationDiplomaNo(undefined)
          setEducation(undefined);
          setManualEducationModalOpen(false);
          onClose();
        })
        .catch((error) => displayAlert(
          "saveEducationFailure",
          AlertType.Danger,
          dispatch
        ))
    }
  }

  const getSaveEducationIdAlertMessage = (education: Education): string => {
    if (education.prescriptionResult === EducationPrescriptionResultEnum.PrescriptionRightsGranted) {
      return "saveEducationSuccessWithPrescriptionPower";
    }
    if (education.prescriptionResult === EducationPrescriptionResultEnum.PrescriptionRightsNotGranted) {
      return "saveEducationSuccess";
    }
    if (education.prescriptionResult === EducationPrescriptionResultEnum.FailedPrescriptionCheck) {
      return "saveEducationSuccessWithFailedPrescriptionCheck";
    }
    return "saveEducationSuccess";
  }

  const onSaveManualEducation = (education: Education) => {
    if (person.id && education) {
      saveEhisEducationAPI(undefined, getBaseUrl(), API)
        .updateEducation(person.id, education)
        .then((response) => {
          displayAlert(
            "saveEducationSuccess",
            AlertType.Success,
            dispatch);
          setSelectedEducationDiplomaNo(undefined)
          setEducation(undefined);
          setManualEducationModalOpen(false);
          onClose();
        })
        .catch((error) => displayAlert(
          "saveEducationFailure",
          AlertType.Danger,
          dispatch
        ))
    }
  }

  const setIsSaveAllowed = useCallback(
    (isAllowed: boolean) => {
      setSaveAllowed(isAllowed);
    },
    [dispatch]
  );


  const loadEhisEducation = () => {
    if (person.idCode && !person.foreigner) {
      ehisEducationInfoApi(undefined, getBaseUrl(), API)
        .getEhisEducationNotSavedInDatabase(person.idCode, {
          withCredentials: true
        })
        .then((educationInfoList) => {
          setEhisEducationList(educationInfoList.data);
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false)
        });
    } else {
      switchToManualEducation();
    }
  }

  const handleSave = () => {
    education ?
      onSaveManualEducation(education!) :
      onSaveEhisEducation(ehisEducationList.find(education => education.diplomaNumber === selectedEducationDiplomaNo) || {});
    onClose();
  }

  const resetStateAfterCancel = () => {
    setEducation(undefined);
    setSelectedEducationDiplomaNo(undefined);
    setManualEducationModalOpen(false);
  }

  const handleCancel = () => {
    resetStateAfterCancel()
    onClose();
  }

  const handleRadioChange = (event) => {
    const selectedEducation = event.target.value
    setSelectedEducationDiplomaNo(selectedEducation);
  }

  const switchToManualEducation = () => {
    setSelectedEducationDiplomaNo(undefined);
    setEducation(undefined);
    setManualEducationModalOpen(true);
  }

  const switchToEhisEducation = () => {
    setSelectedEducationDiplomaNo(undefined);
    setEducation(undefined);
    setManualEducationModalOpen(false);
    setLoading(true);
    loadEhisEducation();
  }

  const educationCard = (education: EducationInfo): ReactNode =>
    (<EhisEducationViewCard
      educationInfo={education}
      valueClassName={"font-weight-500"}
      isAddEducationModalView={true}/>)

  const customEhisRadioButton = (education: EducationInfo, index: number) => {
    return <CustomRadioButton
      key={`radio-box-${index}`}
      content={educationCard(education)}
      checked={education.diplomaNumber === selectedEducationDiplomaNo}
      groupName={"ehisEducation"}
      index={index}
      value={education.diplomaNumber}
      onChange={handleRadioChange}/>;
  }

  const newRequestButton = (): ReactNode => {
    return <LinkButton
      id="newQuery"
      className="p-0 new-query-link"
      onClick={() => loadEhisEducation()}/>
  }

  const switchToManualEducationButton = (): ReactNode => {
    return <LinkButton
      id="setManually"
      className="p-0 align-baseline"
      onClick={() => switchToManualEducation()}/>
  }

  const switchToEhisEducationButton = (): ReactNode => {
    return <LinkButton
      id="setAutomatically"
      className="p-0 align-baseline"
      onClick={() => switchToEhisEducation()}/>
  }

  const showEhisCards = () => {
    return ehisEducationList.map((education, index) => customEhisRadioButton(education, index));
  }


  return (
    <Modal isOpen={isOpen} className="add-education-info mw-600">
      <ModalHeader>
        {(editEducation && editEducation.id) ?
          <FormattedMessage id="editEducationModal.header"
                            defaultMessage="Hariduse muutmine"/> :
          <FormattedMessage id="addEducationModal.header"
                            defaultMessage="Uue hariduse lisamine"/>}
      </ModalHeader>
      <ModalBody>

        {!(editEducation && editEducation.id) && <FormattedMessage id="addEducationModal.motivation"
                                                                   defaultMessage="Vali sobiv EHIS-e poolt leitud lõpetatud haridus või sisesta hariduse andmed käsitsi"/>
        }
        {!manualEducationModalOpen && <>
            <section className="add-education-section">
                <div className="add-education-subtitle">
                    <FormattedMessage id="addEducationModal.acquiredEducation"
                                      defaultMessage="Eestis omandatud haridus"/>
                </div>
                <FormattedMessage id="button.newQueryWithMarkup"
                                  defaultMessage="<button>Tee uus päring</button>"
                                  values={{button: (msg: string) => newRequestButton()}}/>
            </section>

          {loading
            ? <Loader/>
            : ehisEducationList.length > 0
              ? showEhisCards()
              : <EhisEducationNotFoundCard isAddEducationModal={true}/>}
            <FormattedMessage id="AutomaticEducationView.addEducationManually"
                              defaultMessage="Kui omandatud haridus on välismaal või ei kajastu nimekirjas, siis
                              <button>märkige see käsitsi</button>"
                              values={{button: (msg: string) => switchToManualEducationButton()}}/>
        </>

        }
        {manualEducationModalOpen && <>
        {!person.foreigner &&
            <>
              <section className="add-education-section">
                <div className="add-education-subtitle">
                  <FormattedMessage id="addEducationModal.manualSubtitle"
                                    defaultMessage="Välismaal omandatud või EHIS-es puuduv haridus"/>
                </div>
              </section>
            </>
        }
            <ManualEducationModal
                education={education}
                setIsSaveAllowed={setIsSaveAllowed}
                onBlankEducationCreated={setBlankEducationCreated}
                toggleAutomaticEducationLoad={() => switchToEhisEducationButton()}
                setEducation={setEducation}
                personId={person.id}></ManualEducationModal>
          {(!(education && education?.id && education?.countryCode)) &&
              <FormattedMessage id="manualEducationView.addEducationAutomatically"
                                defaultMessage="Eestis omandatud hariduse info saate <button>läbi EHIS-i päringu.</button>"
                                values={{button: (msg: string) => switchToEhisEducationButton()}}/>}
        </>}
      </ModalBody>
      <ModalFooter>
        <SecondaryFormattedButton id="cancel" onClick={handleCancel}/>
        <PrimaryFormattedButton id="saveEducation" disabled={!saveAllowed} onClick={handleSave}/>
      </ModalFooter>
    </Modal>
  )
};