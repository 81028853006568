import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { Loader } from "../../../../../Component/Loader/Loader";
import { ActivityLicenseApplicationDetailsActions } from "./ActivityLicenseApplicationDetailsActions";
import { ActivityLicenseApplicationState } from "../../../../Shared/ActivityLicense/ActivityLicenseApplicationState";
import "../../../../Shared/Application/Application.scss";
import "../../../../Shared/ActivityLicense/ActivityLicense.scss";
import { DetailsCard } from "./DetailsCard";
import { ApplicationNotes } from "../../../Application/Details/ApplicationNotes";
import { ActivityLicenseDetailsHistory } from "./ActivityLicenseDetailsHistory";
import { ApplicationDecisions } from "../../../Application/Details/ApplicationDecisions";
import { QuickLinks } from "../../../../../Component/QuickLinks/QuickLinks";
import { useDetailedApplication } from "../../Details/ActivityLicenseApplicationDetailsHook";
import {
  DetailedActivityLicenseApplication,
  ActivityLicenseApplicationStatus,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitAppAPI
} from "../../../../../../api_client/medre_api";
import { getBaseUrl, API } from "../../../../../api";

export const ActivityLicenseApplicationDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { details, setDetails, refresh } =
    useDetailedApplication<DetailedActivityLicenseApplication>(
      officialActivityPermitAppAPI(undefined, getBaseUrl(), API)
        .getDetailedActivityLicenseApplication,
      id
    );

  const isSubmitted =
    details?.currentStatus?.status !== ActivityLicenseApplicationStatus.Saved &&
    details?.currentStatus?.status !==
      ActivityLicenseApplicationStatus.Registering;

  const quickLinks = useMemo(
    () => [
      {
        id: "actions",
        title: "Tegevused"
      },
      {
        id: "details",
        title: "Taotluse detailid"
      },
      {
        id: "notes",
        title: "Menetluse märkmed"
      },
      {
        id: "history",
        title: "Menetluse ajalugu"
      },
      {
        id: "resolutions",
        title: "Otsused"
      },
      {
        id: "fee",
        title: "Riigilõiv"
      }
    ],
    []
  );

  if (!details) {
    return <Loader />;
  }

  return (
    <div className="application-container application-detail">
      <div className="d-flex align-items-center mx-3 my-4">
        <h1 className="mb-0 mr-3">
          <FormattedMessage
            id="ApplicationDetails.header"
            defaultMessage="Taotluse {id} detailvaade"
            values={{ id: details.applicationNumber }}
          />
        </h1>
        <ActivityLicenseApplicationState
          currentStatus={details.currentStatus?.status}
          isStateFeePaid={details.stateFee?.paid}
          isDetailed={true}
        />
      </div>
      <div className="ml-3 activity-license-quick-links">
        <QuickLinks links={quickLinks} />
      </div>
      <Card className="mx-3 my-4 p-4">
        <h2>
          <span id="actions" /> Tegevused
        </h2>
        <ActivityLicenseApplicationDetailsActions
          details={details}
          setDetails={setDetails}
          refresh={refresh}
        />
      </Card>
      <DetailsCard details={details} setDetails={setDetails} />
      {isSubmitted && (
        <ApplicationNotes
          notes={details.notes || ""}
          setDetails={setDetails}
          endpoint={
            officialActivityPermitAppAPI(undefined, getBaseUrl(), API)
              .updateApplicationNote3
          }
          detailsId={details.id}
        />
      )}
      <Card id="decisions-card" className="mx-3 my-4">
        <h2>
          <span id="resolutions" /> Otsused
          <ApplicationDecisions decisions={details.decisions || []} />
        </h2>
      </Card>
      {details?.currentStatus?.status !==
        ActivityLicenseApplicationStatus.Saved && (
        <ActivityLicenseDetailsHistory
          auditLogItems={details.auditLogItems || []}
        />
      )}
    </div>
  );
};
